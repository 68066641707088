import { ReactNode } from 'react';
import { ProblemRentElementField } from '../../../../../server';
import { Icon, Modal } from 'antd';
import { IconExclamationSolid } from '../../../../icons';
import { Link } from 'react-router-dom';
import { getBusinessAccountId } from '../../../../../..';

interface Props {
    fields: ProblemRentElementField[];
    title: ReactNode;
    description?: string;
    onClose: () => void;
}

const ProblemRentElementModal = ({ title, description, fields, onClose }: Props) => {
    return (
        <Modal
            visible={fields.length > 0}
            onCancel={onClose}
            width={560}
            title={
                <>
                    <Icon
                        style={{ marginRight: 4, fontSize: 32, color: '#f8516c', verticalAlign: 'middle' }}
                        component={IconExclamationSolid}
                    />
                    {title}
                </>
            }
            okButtonProps={{ style: { display: 'none' } }}
            cancelText="Oтмена"
            cancelButtonProps={{ type: 'primary' }}
            centered
        >
            {description && <div style={{ fontWeight: 700, lineHeight: 1.1, fontSize: 18, marginBottom: 16 }}>{description}</div>}
            {fields.map((field, i) => {
                const { kitShortName, kitId, productShortName, productId, variantShortName, countInstance } = field;
                return (
                    <div style={{ marginBottom: 12 }}>
                        <Link className="rr-link" to={`/${getBusinessAccountId()}/inventory/kits/${kitId}?tab=description`}>
                            <b>{kitShortName}</b>
                        </Link>
                        <Link
                            className="rr-link"
                            style={{ display: 'block', marginLeft: 18 }}
                            to={`/${getBusinessAccountId()}/inventory/products/${productId}?tab=description`}
                        >
                            {countInstance ? `${countInstance} x ` : ''}
                            <b>{productShortName}</b>
                            {variantShortName ? `: ${variantShortName}` : ''}
                        </Link>
                    </div>
                );
            })}
        </Modal>
    );
};
export default ProblemRentElementModal;
