import React, { RefObject, UIEvent } from 'react';
import { Select } from 'antd';
import debounce from 'lodash/debounce';
import { checkServerMethodExist, NomenclatureRecord, serverApi } from '../../server';
import { CustomSelect } from './CustomSelect';
import { LabeledValue, SelectValue } from 'antd/lib/select';
import { getBusinessAccountId } from '../../../index';
import Spin from '../spin/spin';
import { LocalizationEnum, localize, localizeIntl } from '../../localization';
import { AxiosPromise } from 'axios';
import { connect } from 'react-redux';
import { IRootState } from '../../shared/reducers';
import { showNotification } from '../notification/showNotification';
import { IntlShape } from 'react-intl';
import { IDetailedSelectColumn } from '../v2/detailedSelect/DetailedSelect';

export interface EntityRemoteSelectProps extends Partial<StateProps> {
    notLoadWhenLabeledValue?: boolean;
    onChange?: any;
    value?: SelectValue;
    showSearch?: boolean;
    className?: string;
    placeholder?: string | React.ReactNode;
    operationName?:
        | 'listUsersSimple'
        | 'listRenters'
        | 'listProjects'
        | 'listProducts'
        | 'listKits'
        | 'listCustomFields'
        | 'listProductVariants'
        | 'listProductInstances'
        | 'listOperations'
        | 'listNomenclature'
        | 'listSubrents'
        | 'listTemplates'
        | 'listDocumentTemplatesForReportChartVisualization'
        | 'listPricingSchemes'
        | 'listDocumentTemplates'
        | 'listLocations'
        | 'listContacts'
        | 'listVehicles'
        | 'listProfessions'; // TODO Эти поля еще динамически указываются в форме создания !!!
    nameField?: 'title' | 'shortName' | 'name' | 'mnemoKey' | 'nameOrCode' | 'productShortName'; // TODO Эти поля еще динамически указываются в форме создания !!!
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
    style?: React.CSSProperties;
    dropdownStyle?: React.CSSProperties;
    filters?: string[];
    getEntityOperationName?:
        | 'getRenterById'
        | 'getProjectById'
        | 'getUserById'
        | 'getProductById'
        | 'getKitById'
        | 'getOperationById'
        | 'getProductVariantById'
        | 'getProductInstanceById'
        | 'getSubrentById'
        | 'getInstanceById'
        | 'getTemplateById';
    getEntityOperationNameFieldName?: string;
    disabled?: boolean;
    renderer?: (data, index, value) => React.ReactNode;
    productId?: number;
    sortOrder?: 'ASC' | 'DESC';
    sortBy?: string;
    customChangeData?: (data: Array<NomenclatureRecord>) => Array<NomenclatureRecord>;
    multiple?: boolean;
    maxTagCount?: number;
    presetedData?: LabeledValue[];
    allowClear?: boolean;
    customParams?: any;
    onEntityDataLoad?: (data: any) => void;
    withExtraOption?: boolean;
    loadDataOnMount?: boolean;
    fetchEntityErrorCallback?: (entityId: number) => void;
    formLabel?: string;
    intl?: IntlShape;
    _ref?: RefObject<Select>;
    columns?: IDetailedSelectColumn<any>[];
}

export class _EntityRemoteSelect extends React.PureComponent<EntityRemoteSelectProps, any> {
    private _gettingName;
    private lastFetchId;
    private limit = 100;

    static defaultProps: Pick<EntityRemoteSelectProps, 'nameField' | 'allowClear'> = {
        nameField: 'shortName',
        allowClear: true,
    };

    static getDerivedStateFromProps(nextProps, state) {
        if (state.value && !nextProps.value) {
            return {
                ...state,
                value: undefined,
            };
        } else if (state.value && nextProps.value && nextProps.value.key && state.value.key !== nextProps.value.key) {
            return {
                ...state,
                value: nextProps.value,
            };
        } else {
            return state;
        }
    }

    constructor(props: EntityRemoteSelectProps) {
        super(props);
        this.lastFetchId = 0;
        this.fetchUser = debounce(this.fetchUser, 500);

        this.state = {
            data: this.getPresetedData() as LabeledValue[],
            value: undefined,
            fetching: false,
            total: 0,
            searchString: '',
            page: 0,
            fetchingError: false,
            prevValue: props.value ?? [],
        };
    }

    getPresetedData = () => {
        let data: any[] = [];
        if (this.props.presetedData) {
            this.props.presetedData.forEach((value) => {
                data.push({
                    id: value.key,
                    name: value.label,
                });
            });
        }
        return data;
    };

    componentDidMount() {
        this.componentDidUpdate();
        if (this.props.loadDataOnMount) {
            this.loadDataFromServer(undefined, this.setLoadedDataCallback);
        }
    }

    componentDidUpdate() {
        // Ссылка на метод получения отдельной сущности
        let operationName = this.props.getEntityOperationName as string;
        const propsValueIsLabeled = this.props.value && this.props.value.hasOwnProperty('key') && this.props.value.hasOwnProperty('label');

        if (propsValueIsLabeled && this.props.notLoadWhenLabeledValue) return;

        if (
            this.props.value &&
            ((propsValueIsLabeled &&
                ((this.props.value['key'] && !this.props.value['label'] && !this.state.value) ||
                    (!this.props.value['key'] && !this.props.value['label']))) ||
                !this.state.value) &&
            !this._gettingName &&
            operationName &&
            checkServerMethodExist(operationName)
        ) {
            this._gettingName = true;
            let params: Number[] = [];

            const keyCheck = (value) => (typeof value === 'object' && 'key' in value ? value['key'] || value : value);

            let key = keyCheck(this.props.value);
            if (key === -1) {
                this.setState({
                    value: {
                        key: '-1',
                        label: this.props.businessAccountShortName,
                        data: {},
                    },
                });
                return;
            }

            const keyParam = Array.isArray(key) && key[0] != null ? keyCheck(key[0]) : key;

            if (this.props.productId) {
                params = [getBusinessAccountId(), this.props.productId, keyParam];
            } else {
                params = [getBusinessAccountId(), keyParam];
            }

            serverApi[operationName](...params)
                .then((body) => {
                    const value = {
                        key: body.data.id,
                        label: body.data[this.props ? this.props.getEntityOperationNameFieldName || this.props.nameField : body.data.id],
                    };
                    this.setState({ value });
                    if (this.props.onEntityDataLoad) this.props.onEntityDataLoad(body.data);
                })
                .catch(() => {
                    if (this.props.fetchEntityErrorCallback) {
                        showNotification(
                            'error',
                            <span>
                                Не удалось установить фильтр{' '}
                                {this.props.formLabel && this.props.intl ? (
                                    <span>"{localizeIntl(this.props.intl, this.props.formLabel as LocalizationEnum)}" </span>
                                ) : (
                                    ''
                                )}
                                - сущность не найдена по идентификатору
                            </span>
                        );

                        this.props.fetchEntityErrorCallback(key);
                    }
                })
                .finally(() => {
                    this._gettingName = false;
                });
        }
    }

    setLoadedDataCallback = (err, data) => {
        if (!err) {
            if (data) {
                this.setState({ data: [...this.state.data, ...data], fetching: false });
            } else {
                this.setState({ data: [...this.state.data], fetching: false });
            }
        } else {
            this.setState({ data: [...this.state.data], fetching: false, fetchingError: true });
        }
    };

    fetchUser = (value) => {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState(
            {
                data: this.getPresetedData(),
                fetching: true,
                searchString: value,
                page: 0,
                fetchingError: false,
            },
            () => {
                this.loadDataFromServer(value, (err, data) => {
                    if (fetchId !== this.lastFetchId) {
                        // for fetch callback order
                        return;
                    }
                    this.setLoadedDataCallback(err, data);
                });
            }
        );
    };

    loadDataFromServer = (search, cb) => {
        if (!search) search = undefined;

        let operationName = this.props.operationName as string;
        if (operationName && checkServerMethodExist(operationName)) {
            let request: AxiosPromise;

            if (operationName === 'listCustomFields') {
                request = serverApi[operationName](
                    getBusinessAccountId(),
                    search,
                    this.limit,
                    this.state.page * this.limit,
                    this.props.sortBy,
                    this.props.sortOrder,
                    undefined,
                    undefined,
                    undefined,
                    this.props.filters ? { query: { filters: this.props.filters } } : undefined /*, [], 'shortName', 'ASC', search*/
                );
            } else if (operationName === 'listProductVariants') {
                request = serverApi[operationName](
                    getBusinessAccountId(),
                    this.props.productId || 0,
                    this.limit,
                    this.state.page * this.limit,
                    undefined,
                    this.props.sortBy,
                    this.props.sortOrder,
                    search,
                    this.props.filters ? { query: { filters: this.props.filters } } : undefined /*, [], 'shortName', 'ASC', search*/
                );
            } else if (operationName === 'listProductInstances') {
                request = serverApi[operationName](
                    getBusinessAccountId(),
                    this.props.productId || 0,
                    this.limit,
                    this.state.page * this.limit,
                    undefined,
                    this.props.sortBy,
                    this.props.sortOrder,
                    search,
                    this.props.filters ? { query: { filters: this.props.filters } } : undefined /*, [], 'shortName', 'ASC', search*/
                );
            } else if (operationName === 'listDocumentTemplates') {
                request = serverApi[operationName](
                    getBusinessAccountId(),
                    this.limit,
                    this.state.page * this.limit,
                    undefined,
                    this.props.sortBy || 'lastUpdateDate',
                    this.props.sortOrder || 'DESC',
                    search,
                    false,
                    { query: { filters: this.props.filters } }
                );
            } else if (operationName === 'listDocumentTemplatesForReportChartVisualization') {
                let reportInstanceId =
                    this.props.customParams && this.props.customParams.reportInstanceId ? this.props.customParams.reportInstanceId : -1;
                request = serverApi[operationName](
                    getBusinessAccountId(),
                    reportInstanceId,
                    search,
                    this.limit,
                    this.state.page * this.limit,
                    undefined,
                    this.props.sortBy || 'lastUpdateDate',
                    this.props.sortOrder || 'DESC'
                );
            } else if (operationName === 'listPricingSchemes') {
                request = serverApi[operationName](
                    getBusinessAccountId(),
                    search,
                    this.limit,
                    this.state.page * this.limit,
                    this.props.sortBy || 'lastUpdateDate',
                    this.props.sortOrder || 'DESC'
                );
            } else if (operationName === 'listLocations') {
                request = serverApi[operationName](
                    getBusinessAccountId(),
                    search,
                    this.limit,
                    this.state.page * this.limit,
                    this.props.sortBy,
                    this.props.sortOrder,
                    this.props.filters
                );
            } else if (operationName === 'listVehicles') {
                request = serverApi[operationName](
                    getBusinessAccountId(),
                    search,
                    this.limit,
                    this.state.page * this.limit,
                    this.props.sortBy,
                    this.props.sortOrder,
                    this.props.filters
                );
            } else if (operationName === 'listContacts') {
                request = serverApi[operationName](
                    getBusinessAccountId(),
                    search,
                    this.limit,
                    this.state.page * this.limit,
                    this.props.sortBy,
                    this.props.sortOrder,
                    this.props.filters
                );
            } else if (operationName === 'listProfessions') {
                request = serverApi[operationName](
                    getBusinessAccountId(),
                    this.limit,
                    this.state.page * this.limit,
                    this.props.filters,
                    this.props.sortBy,
                    this.props.sortOrder,
                    search
                );
            } else {
                request = serverApi[operationName](
                    getBusinessAccountId(),
                    this.limit,
                    this.state.page * this.limit,
                    undefined,
                    this.props.sortBy,
                    this.props.sortOrder,
                    search,
                    this.props.filters ? { query: { filters: this.props.filters } } : undefined
                );
            }

            if (request) {
                request
                    .then((body) => {
                        if (body.data && body.data['records']) {
                            let dataFromServer = body.data['records'];
                            if (this.props.customChangeData) {
                                dataFromServer = this.props.customChangeData(dataFromServer);
                            }

                            if (this.props.onEntityDataLoad) {
                                this.props.onEntityDataLoad(dataFromServer);
                            }

                            const data = dataFromServer.map((item) => ({
                                name: this.props.nameField ? item[this.props.nameField] : item.id,
                                id: item.id,
                                data: item,
                            }));
                            if (cb) cb(null, data);
                        } else {
                            if (cb) cb(null, []);
                        }
                    })
                    .catch((error) => {
                        if (cb) cb({}, null);
                    });
            }
        }
    };

    onSearch = (search: string) => {
        this.fetchUser(search);
    };

    handleChange = (value, option) => {
        let newValue;
        if (Array.isArray(value)) {
            newValue = [...value];
        } else if (value) {
            newValue = { ...value };
        }

        let prevValue;

        if (newValue) {
            if (Array.isArray(newValue)) {
                newValue = newValue.map((i) => {
                    let obj = this.state.data.find((item) => '' + item['id'] === i.key);
                    i.data = obj ? obj['data'] : {};

                    return i;
                });
            } else {
                let obj = this.state.data.find((item) => '' + item['id'] === value.key);
                newValue.data = obj ? obj['data'] : {};
            }

            if (Array.isArray(value)) {
                let selectedKeys = newValue.map((item) => '' + item.key);
                prevValue = [...this.state.prevValue];
                let prevKeys = prevValue.map((item) => '' + item.key);
                prevValue = prevValue.filter((item) => selectedKeys.includes('' + item.key));

                newValue.forEach((item) => {
                    if (!prevKeys.includes('' + item.key)) {
                        let obj = this.state.data.find((i) => '' + i['id'] === item.key);
                        if (obj) {
                            prevValue.push({
                                key: item.key,
                                label: obj['name'],
                                data: obj['data'],
                            });
                        }
                    }
                });
            } else {
                let obj = this.state.data.find((item) => '' + item['id'] === value.key);
                prevValue = { ...this.state.prevValue };
                if (this.props.withExtraOption && this.props.businessAccountShortName) {
                    prevValue = {
                        key: value.key,
                        label: '',
                        data: {},
                    };
                } else if (obj) {
                    prevValue = {
                        key: value.key,
                        label: obj['name'],
                        data: obj['data'],
                    };
                }
            }

            this.setState({
                value: newValue,
                data: this.props.multiple ? this.state.data : this.getPresetedData(),
                fetching: false,
                fetchingError: false,
                prevValue,
            });
        }

        if (this.props.onChange) {
            this.props.onChange(prevValue, option);
        }
    };

    onPopupScroll = (event: UIEvent) => {
        event.persist();
        let { target } = event;

        if (target['scrollTop'] + target['offsetHeight'] === target['scrollHeight']) {
            this.setState(
                {
                    page: this.state.page + 1,
                    fetching: true,
                    fetchingError: false,
                },
                () => {
                    this.loadDataFromServer(undefined, this.setLoadedDataCallback);
                }
            );
        }
    };

    onDropdownVisibleChange = (visible: boolean) => {
        if (visible) {
            this.setState({ data: this.getPresetedData(), fetching: true, fetchingError: false }, () => {
                this.loadDataFromServer(undefined, this.setLoadedDataCallback);
            });
        } else {
            this.setState({ fetching: false, page: 0, total: 0 });
        }
    };

    render() {
        const { fetching, fetchingError, data } = this.state;

        const value = this.state.value || this.props.value;
        if (value?.key === -1) {
            value.label = this.props.businessAccountShortName;
        }

        return (
            <CustomSelect
                _ref={this.props._ref}
                mode={this.props.multiple ? 'multiple' : undefined}
                maxTagCount={this.props.maxTagCount}
                style={this.props.style}
                getPopupContainer={this.props.getPopupContainer}
                className={this.props.className}
                defaultActiveFirstOption={false}
                labelInValue
                value={value}
                placeholder={this.props.placeholder}
                notFoundContent={
                    (this.props.multiple && data?.length === 0 && fetching) || fetching || fetchingError ? (
                        <></>
                    ) : (
                        localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_NOT_FOUND)
                    )
                }
                filterOption={false}
                onSearch={this.onSearch}
                autoClearSearchValue={false}
                onChange={this.handleChange}
                dropdownMatchSelectWidth={false}
                showSearch
                allowClear={this.props.allowClear}
                showArrow={!this.props.multiple}
                disabled={this.props.disabled}
                onSelect={(a, b) => {
                    this.setState({ searchString: (a as LabeledValue).label });
                }}
                onPopupScroll={this.onPopupScroll}
                onDropdownVisibleChange={this.onDropdownVisibleChange}
                getInputElement={() => <input maxLength={255} />}
                dropdownStyle={this.props.dropdownStyle}
                dropdownRender={(menu) => (
                    <>
                        {this.props.multiple && data?.length === 0 && fetching ? undefined : menu}
                        {fetching && !fetchingError ? (
                            <div className={'rr-custom-select-loading-block'}>
                                <Spin size={'small'} delay={0} />
                                <span>{localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_LOADING)}</span>
                            </div>
                        ) : null}
                        {!fetching && fetchingError ? (
                            <div className={'rr-custom-select-error-block'}>
                                {localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_LOADING_ERROR)}
                            </div>
                        ) : null}
                    </>
                )}
            >
                {this.props.withExtraOption && this.props.businessAccountShortName ? (
                    <Select.Option
                        key={-1}
                        style={{
                            borderBottom: '1px solid #e2e2e2',
                        }}
                    >
                        {this.props.businessAccountShortName}
                    </Select.Option>
                ) : undefined}
                {data.map((d, index) =>
                    this.props.renderer ? this.props.renderer(d, index, value) : <Select.Option key={d['id']}>{d['name']}</Select.Option>
                )}
            </CustomSelect>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    businessAccountShortName: storeState.businessAccountContacts.entity?.shortName || undefined,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export const EntityRemoteSelect = connect(mapStateToProps)(_EntityRemoteSelect);
