import React, {FC} from 'react';
import {Col, Row} from "antd";
import {
    AttachmentParentEntityTypeCodeEnum,
    ContactLinkObjRead,
    SubrentInfoRead,
    SubrentInfoUpdate
} from "../../../../server";
import {useAppDispatch} from "../../../../store/hooks";
import {ContactsBlock} from "../../../../components/contactsBlock/contactsBlock";
import {deleteContactFromShipping} from "./reducers/subrent.reducer";

interface SubrentShippingPageDescriptionContactsBlockNew {
    data: SubrentInfoRead;
}

export const SubrentShippingPageDescriptionContactsBlock: FC<SubrentShippingPageDescriptionContactsBlockNew> = ({data}) => {
    const contactLinks: ContactLinkObjRead[] = data ? (data.hasOwnContacts ? data.contactLinks || [] : data.supplierContactLinks || []) : [];
    const dispatch = useAppDispatch();
    const preventDeleteContact = !data.hasOwnContacts;

    return (
        <Row>
            <Col style={{marginLeft: -8, marginRight: -8, borderBottom: '1px solid #eaeaea'}}>
                <ContactsBlock
                    data={contactLinks}
                    parentEntityId={data.id}
                    parentEntityTypeCode={AttachmentParentEntityTypeCodeEnum.SUBRENT}
                    preventDeleteRemainingContact={true}
                    preventDeleteContact={preventDeleteContact}
                    deleteContact={async (contactId) => {
                        await dispatch(deleteContactFromShipping(data.businessAccountId, contactId, data as SubrentInfoUpdate));
                    }}
                    contactsFromEntityLabel={!data.hasOwnContacts ? 'по поставщику' : undefined}
                />
            </Col>
        </Row>
    );
};
