import { useCallback, useEffect, useRef } from 'react';
import DynamicFilters from '../../../../../../components/dynamicFilters/DynamicFilters';
import { LogisticsPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import { locationsFilters } from '../filters/locationsFilters';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { useHistory } from 'react-router-dom';
import { getStateFromPath2 } from '../../../../../../shared/util/utils';
import { GridStorageData } from '../../../../../../components/grid/utils';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { IRootState } from '../../../../../../shared/reducers';
import { updateGridSettings } from '../../../../../../shared/reducers/userSettings/userSettings.reducer';

export type LocationFilters = { contactId?: number; search?: string; hideArchive?: boolean };

const EMPTY_FILTERS: LocationFilters = { search: undefined, contactId: undefined, hideArchive: undefined };

interface Props {
    currentFilters: LocationFilters;
    onFiltersChange: (newFilters: LocationFilters) => void;
}
const MapFilters = ({ currentFilters, onFiltersChange }: Props) => {
    const filtersRef = useRef<any>(null);
    const history = useHistory();
    const gridData: GridStorageData | undefined = useAppSelector(
        (state: IRootState) => state.userSettings.gridSettings[LogisticsPageTabsEnum.LOCATIONS]
    );
    const dispatch = useAppDispatch();

    const getFiltersForm = useCallback(() => {
        return filtersRef?.current?.props?.form as WrappedFormUtils | null | undefined;
    }, []);

    const filtersChangeHandler = useCallback(
        (data: LocationFilters) => {
            const newFilters: Record<string, string> = {};
            if (data.contactId) {
                newFilters.contactId = data.contactId.toString();
            }

            if (data.search) {
                newFilters.search = data.search;
            }

            if (data.hideArchive) {
                newFilters.hideArchive = 'true';
            }

            history.replace({ search: new URLSearchParams({ ...newFilters, view: 'map' }).toString() });
            const newData: GridStorageData = {
                ...(gridData ?? {}),
                columnsOrder: gridData?.columnsOrder ?? [],
                filters: { ...newFilters },
            };
            dispatch(updateGridSettings({ [LogisticsPageTabsEnum.LOCATIONS]: newData }));
        },
        [dispatch, gridData, history]
    );

    const filtersResetHandler = useCallback(() => {
        getFiltersForm()?.setFieldsValue(EMPTY_FILTERS);
        filtersChangeHandler({});
    }, [filtersChangeHandler, getFiltersForm]);

    useEffect(() => {
        const filters = getStateFromPath2(history.location.search);
        const mapFilters: LocationFilters = {};
        if ('contactId' in filters) {
            mapFilters.contactId = filters.contactId;
        }
        if ('search' in filters) {
            mapFilters.search = filters.search;
        }
        if ('hideArchive' in filters) {
            mapFilters.hideArchive = filters.hideArchive;
        }

        if (Object.keys(filters).length) {
            onFiltersChange(mapFilters);
        }
    }, [history.location.search, onFiltersChange]);

    return (
        <DynamicFilters
            key={locationsFilters[1]?.fields.length}
            gridName={LogisticsPageTabsEnum.LOCATIONS}
            currentValues={currentFilters}
            defaultValues={EMPTY_FILTERS}
            wrappedComponentRef={filtersRef}
            onChange={filtersChangeHandler}
            data={locationsFilters}
            initialValues={currentFilters}
            canResetFilters={true}
            resetFiltersCb={filtersResetHandler}
        />
    );
};

export default MapFilters;
