import { MutableRefObject, useCallback } from 'react';
import { AccessibilityMapFilters } from '../filters/accessibility/accessibilityMapCalendarFilters.types';
import { WrappedFormUtils } from 'antd/es/form/Form';

export const useManuallyParams = (filtersFormRef: MutableRefObject<WrappedFormUtils | undefined>) => {
    return useCallback(
        (params: AccessibilityMapFilters): Partial<AccessibilityMapFilters> => {
            // if (params.productId == null)
            //     return {
            //         productInstanceTrackingTypeCode: undefined,
            //     };

            return {
                productInstanceTrackingTypeCode: filtersFormRef.current?.getFieldValue('productInstanceTrackingTypeCode'),
            };
        },
        [filtersFormRef]
    );
};
