import { FormFieldsGroup, FormItemType } from '../../../../../../components/dynamicForm/DynamicForm';
import { FormUtils } from '../../../../../../core/utils/formUtils';
import { ListVehiclesParams } from '../api/vehicles.api.types';
import { LocalizationEnum } from '../../../../../../localization';
import { LoadingTypeCodeEnum, VehicleTypeCodeEnum } from '../../../../../../server';
import { vehicleTypeMap } from '../utils/enumMappers';
import { SliderRangeWithManualInput } from '../../../../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import { InlineNumberInputColorTheme } from '../../../../../../components/inlineInputs/InlineNumberInput/soft/InlineNumberInput';
import { ContactSelect } from '../../../../../../components/v2/select/contactSelect/contactSelect';

export const vehiclesFilters: FormFieldsGroup[] = FormUtils.typedFormFields<ListVehiclesParams>([
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: 'Грузоподъемность',
                id: 'loadCapacity',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 180 },
                className: 'rr-slider-gray',
                componentProps: {
                    formatStyle: 'tons',
                    min: 0,
                    max: 100,
                    step: 1,
                    theme: InlineNumberInputColorTheme.DARK,
                },
            },
            {
                label: 'Объем',
                id: 'volume',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 180 },
                className: 'rr-slider-gray',
                componentProps: {
                    formatStyle: 'tons',
                    min: 0,
                    max: 100,
                    step: 1,
                    theme: InlineNumberInputColorTheme.DARK,
                },
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__HIDE_ARCHIVE,
                id: 'hideArchive',
                type: FormItemType.Switch,
            },
        ],
    },
    {
        fields: [
            {
                label: 'Тип',
                id: 'type',
                type: FormItemType.Select,
                style: { minWidth: 150 },
                placeholder: 'Любой',
                values: [
                    {
                        name: vehicleTypeMap[VehicleTypeCodeEnum.TRACTORTRUCK],
                        value: VehicleTypeCodeEnum.TRACTORTRUCK,
                    },
                    {
                        name: vehicleTypeMap[VehicleTypeCodeEnum.STRAIGHTTRUCK],
                        value: VehicleTypeCodeEnum.STRAIGHTTRUCK,
                    },
                    {
                        name: vehicleTypeMap[VehicleTypeCodeEnum.MINIBUS],
                        value: VehicleTypeCodeEnum.MINIBUS,
                    },
                    {
                        name: vehicleTypeMap[VehicleTypeCodeEnum.TRAILER],
                        value: VehicleTypeCodeEnum.TRAILER,
                    },
                    {
                        name: vehicleTypeMap[VehicleTypeCodeEnum.SEMITRAILER],
                        value: VehicleTypeCodeEnum.SEMITRAILER,
                    },
                ],
            },
            {
                label: 'Погрузка',
                id: 'loadingTypes',
                type: FormItemType.MultiSelect,
                style: { minWidth: 150 },
                placeholder: 'Любой',
                values: [
                    {
                        name: 'Задняя',
                        value: LoadingTypeCodeEnum.REAR,
                    },
                    {
                        name: 'Задняя гидроборт',
                        value: LoadingTypeCodeEnum.REARHYDROBOARD,
                    },
                    {
                        name: 'Боковая',
                        value: LoadingTypeCodeEnum.SIDE,
                    },
                    {
                        name: 'Верхняя',
                        value: LoadingTypeCodeEnum.TOP,
                    },
                ],
            },
            {
                label: 'Длина',
                id: 'length',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 180 },
                componentProps: {
                    formatStyle: 'meters',
                    min: 0,
                    max: 20,
                    step: 1,
                },
            },
            {
                label: 'Ширина',
                id: 'width',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 180 },
                componentProps: {
                    formatStyle: 'meters',
                    min: 0,
                    max: 20,
                    step: 1,
                },
            },
            {
                label: 'Высота',
                id: 'height',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 180 },
                componentProps: {
                    formatStyle: 'meters',
                    min: 0,
                    max: 50,
                    step: 1,
                },
            },
            {
                label: 'Контакт',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'contactId',
                type: FormItemType.Component,
                component: ContactSelect,
                style: { minWidth: 220 },
            },
        ],
    },
]);
