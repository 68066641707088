import { ProjectStateCodeEnum, ProjectTypeCodeEnum } from '../../../../../server';
import { getAllStatusesExceptSelected } from '../../../../../shared/util/utils4';
import { getStringServerProblem } from '../../../../../shared/util/utils';
import { MoneyUtils } from '../../../../../core/utils/moneyUtils';
import { ProjectsHideState, projectsInitialParamsState } from '../reducers/projects.reducer';

export const getProjectsFilters = (params: typeof projectsInitialParamsState) => {
    let filters: string[] = [];
    filters.push(`projectType;EQ;${ProjectTypeCodeEnum.BASE}`);
    if (params.status) filters.push('stateCode;IN;' + params.status.split(',').join(';'));
    if (params.hide != null) {
        switch (params.hide) {
            case ProjectsHideState.Archive: {
                filters.push('archive;EQ;false');
                break;
            }
            case ProjectsHideState.Cancelled: {
                filters.push(`stateCode;IN;${getAllStatusesExceptSelected(ProjectStateCodeEnum, ProjectStateCodeEnum.CANCELED).join(';')}`);
                break;
            }
            case ProjectsHideState.ArchiveCancelled: {
                filters.push('archive;EQ;false');
                filters.push(`stateCode;IN;${getAllStatusesExceptSelected(ProjectStateCodeEnum, ProjectStateCodeEnum.CANCELED).join(';')}`);
                break;
            }
        }
    }
    if (params.sourceCode) filters.push('sourceCode;EQ;' + params.sourceCode);

    if (params.problem) filters.push(getStringServerProblem(params.problem));

    if (params.renterId !== undefined) filters.push('renterId;EQ;' + params.renterId);

    if (params.locationId !== undefined) filters.push('locationId;EQ;' + params.locationId);

    if (params.assigneeId !== undefined) filters.push('assigneeId;EQ;' + params.assigneeId);

    if (params.startDate !== undefined && params.startDate[0] !== undefined) filters.push('startDate;GE;' + params.startDate[0]);
    if (params.startDate !== undefined && params.startDate[1] !== undefined) filters.push('startDate;LE;' + params.startDate[1]);

    if (params.tags !== undefined) filters.push('tags;IN;' + params.tags.join(';'));

    if (params.debtSum) {
        if (params.debtSum[0] != null) filters.push('debtSum;GE;' + MoneyUtils.toCents(params.debtSum[0]));
        if (params.debtSum[1] != null) filters.push('debtSum;LE;' + MoneyUtils.toCents(params.debtSum[1]));
    }

    if (params.contactId) filters.push('contactIds;EQ;' + params.contactId);

    filters.push('isSimpleOrder;EQ;false');

    return filters;
};
