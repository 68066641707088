import { ColumnTypes, TableColumn } from '../../../../../../components/grid/Table';
import { ContactRecord } from '../../../../../../server';
import { GridColumnCreator } from '../../../../../../components/grid/gridColumnCreator';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import { IconCheck, IconEnvelope, IconPhoneSolid } from '../../../../../../components/icons';
import { TableCellAvatarRenderer } from '../../../../../../components/grid/renderers/tableCellAvatarRenderer';
import './ContactsListColumns.less';
import { CrewMemberUtils } from '../../../../crew/tabs/crewMembers/utils/helpers';
import classNames from 'classnames';

const sizes = GridColumnCreator.getColumnSizes(30);
const personShortNameSizes = GridColumnCreator.getColumnSizes(60);

export const contactsListColumns: TableColumn<ContactRecord>[] = [
    GridColumnCreator.createImageColumn<ContactRecord>({
        dataIndex: 'mainImage',
        type: ColumnTypes.CustomRender,
        render: (value, rowData: ContactRecord) => {
            const rendererOpts = rowData.isBusinessAccountRRUser
                ? {
                      highlight: true,
                  }
                : undefined;
            return <TableCellAvatarRenderer value={value} rowData={rowData} opts={rendererOpts} />;
        },
    }),
    GridColumnCreator.createShortNameColumn<ContactRecord>(
        'location',
        (row) => ({
            baId: row.businessAccountId,
            id: row.id,
            name: row.personFullName,
        }),
        {
            title: 'ФИО',
            dataIndex: 'personFullName',
            minWidth: personShortNameSizes.min,
            width: personShortNameSizes.width,
            maxWidth: personShortNameSizes.max,
            resizable: true,
            dontHide: true,
        }
    ),
    {
        title: 'Проектный работник',
        dataIndex: 'crewMemberId',
        type: ColumnTypes.CustomRender,
        minWidth: 160,
        width: 160,
        maxWidth: 160,
        resizable: false,
        render: (crewMemberId: number | undefined, contact: ContactRecord) => {
            if (crewMemberId == null) return 'Нет';

            const linkToCrewMemberCard = CrewMemberUtils.getCardPath(contact.businessAccountId, crewMemberId);

            return (
                <div className={'crew-member-container'}>
                    <Icon component={IconCheck} className={'crew-member-icon'} />
                    <div className={classNames('rr-grid-text-cell-left', 'crew-member-link')}>
                        <Link
                            to={linkToCrewMemberCard}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            Смотреть
                        </Link>
                    </div>
                </div>
            );
        },
    },
    {
        title: 'Основная должность',
        dataIndex: 'primaryPosition',
        type: ColumnTypes.CustomRender,
        minWidth: sizes.min,
        width: sizes.width,
        maxWidth: sizes.max,
        resizable: true,
        render: (value, row: ContactRecord) => {
            return (
                <div className={'rr-grid-text-cell-left'} title={row.primaryPosition}>
                    {row.primaryPosition}
                </div>
            );
        },
    },
    {
        title: 'Телефон',
        dataIndex: 'phoneNumber',
        type: ColumnTypes.CustomRender,
        minWidth: sizes.min,
        width: sizes.width,
        maxWidth: sizes.max,
        resizable: true,
        render: (value: any, row: ContactRecord) => {
            return value ? (
                <a
                    style={{
                        fontWeight: 400,
                        textAlign: 'left',
                        color: row.archive ? undefined : '#343f9a',
                    }}
                    className={'rr-grid-email-cell rr-grid-text-cell-left'}
                    href={`tel:${value}`}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <span
                        style={{
                            textAlign: 'left',
                            paddingLeft: 0,
                        }}
                    >
                        {value}
                    </span>
                    <Icon component={IconPhoneSolid} />
                </a>
            ) : undefined;
        },
    },
    {
        title: 'E-Mail',
        dataIndex: 'email',
        type: ColumnTypes.CustomRender,
        minWidth: sizes.min,
        width: sizes.width,
        maxWidth: sizes.max,
        resizable: true,
        render: (value: any, row: ContactRecord) => {
            const { email } = row;
            return email ? (
                <div
                    style={{
                        fontWeight: 400,
                    }}
                    className={'rr-grid-email-cell rr-grid-text-cell-left'}
                    title={email}
                >
                    <a
                        href={`mailto:${email}`}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {email}
                    </a>{' '}
                    <Icon component={IconEnvelope} />
                </div>
            ) : null;
        },
    },
    GridColumnCreator.createAttachmentsCountColumn<ContactRecord>({
        title: 'Число вложений',
        dataIndex: 'attachmentCount',
    }),
    GridColumnCreator.createReferenceCountColumn<ContactRecord>({
        title: 'Число ссылок',
        dataIndex: 'referenceCount',
    }),
    GridColumnCreator.createUserColumn<ContactRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.lastUpdateAuthorId,
            name: row.lastUpdateAuthorShortName,
        }),
        {
            title: 'Автор изменения',
            dataIndex: 'lastUpdateAuthorShortName',
        }
    ),
    GridColumnCreator.createLastUpdateDateColumn<ContactRecord>({
        title: 'Дата изменения',
        dataIndex: 'lastUpdateDate',
    }),
    GridColumnCreator.createActionsColumn<ContactRecord>({ id: 'actions' }),
];
