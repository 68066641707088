import { Icon } from 'antd';
import { RoundButton } from '../../../../../../components';
import IconLocationArrow from '../../../../../../components/icons/IconLocationArrow';
import { LatLngTuple } from 'leaflet';
import { showNotification } from '../../../../../../components/notification/showNotification';
import { useEffect, useState } from 'react';

interface Props {
    onSuccess?: (coords: LatLngTuple) => void;
    className?: string;
}

const LocateButton = ({ onSuccess, className }: Props) => {
    const [locationDisabled, setLocationDisabled] = useState(false);

    useEffect(() => {
        navigator.permissions.query({ name: 'geolocation' }).then((res) => {
            if (res.state === 'denied') {
                setLocationDisabled(true);
            }
        });
    }, []);

    const locateClickHandler = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                onSuccess?.([position.coords.latitude, position.coords.longitude]);
            });
        } else {
            showNotification('error', 'Доступ к геолокации запрещен');
        }
    };

    return (
        <RoundButton colorScheme="roundIcon" size="default" className={className} onClick={locateClickHandler} disabled={locationDisabled}>
            <Icon style={{ fontSize: 19 }} component={IconLocationArrow} />
        </RoundButton>
    );
};
export default LocateButton;
