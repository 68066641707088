import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import moment, {Moment} from "moment";

interface TimeInputProps {
    defaultValue?: Moment;
    value?: Moment;
    onChange?: (value: Moment | undefined) => void;
    onFocus?: () => void;
    style?: CSSProperties;
    disabled?: boolean;
}

export const TimeInput = (props: TimeInputProps) => {
    const ref = useRef<HTMLInputElement | null>(null);
    const [focus, setFocus] = useState(false);
    const [value, setValue] = useState<Moment | undefined>(props.defaultValue || props.value); // Тут дата с которой работаем
    const [displayValue, setDisplayValue] = useState<string | undefined>(value?.format('HH:mm')); // Тут то что отображается

    useEffect(() => {
        setDisplayValue(props.value ? moment(props.value).format('HH:mm') : undefined);
        setValue(props.value);
    }, [props.value])

    // (ref) => {if(!this.inputEndRef) this.inputEndRef = ref;}
    return <input
        ref={ref}
        placeholder="Время"
        className="ant-calendar-range-picker-input"
        style={{fontSize: 16, fontWeight: 500, width: '100%', ...props.style}}
        value={displayValue || ''}
        disabled={props.disabled}
        onFocus={(e) => {
            let t = e.currentTarget;
            setTimeout(() => {
                t.setSelectionRange(0, 5);
            }, 100);
            setFocus(true);
        }}
        onBlurCapture={(e) => {
            let s = window.getSelection();
            if (s) {
                for (let i = 0; i < s.rangeCount; i++) {
                    s.removeRange(s.getRangeAt(i));
                }
            }
        }}
        onBlur={() => {
            setFocus(false);
            setDisplayValue(value ? moment(value).format('HH:mm') : undefined)
        }}
        onChange={async (e) => {
            let str = e.target.value;
            let t = e.currentTarget;

            if (str === '') {
                setDisplayValue(str);
            } else if (str.match(/^[а-яf-z0-9]$/)) {
                setDisplayValue(str);
            } else if (str.match(/^[а-яf-z0-9]{2}$/)) {
                if (displayValue && displayValue[displayValue?.length - 1] === ':') {
                    setDisplayValue(str.substr(0, str.length - 1));
                } else {
                    setDisplayValue(str + ':')
                }
            } else if (str.match(/^[а-яf-z0-9]{2}:[а-яf-z0-9]{0,1}$/)) {
                // Когда XX: или XX:X
                setDisplayValue(str);
            } else if (str.match(/^[а-яf-z0-9]{2}:[а-яf-z0-9]{2}$/)) {
                let res = str.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/);
                if (res) {
                    // Когда подходит под время
                    let endDate = value || moment();
                    let h = str.split(':')[0];
                    let m = str.split(':')[1];
                    let newDate = endDate.clone();
                    newDate.hours(+h).minutes(+m);
                    setDisplayValue(str);
                    setValue(newDate);
                    if (props.onChange) props.onChange(newDate);
                } else {
                    // Тут не подходит время, меняем время на сохраненное
                    await setDisplayValue(moment(value).format('HH:mm'));
                    t.setSelectionRange(0, 5);
                }
                t.setSelectionRange(0, 5);
            }
        }}
    />
};
