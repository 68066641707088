import React, { ReactNode } from 'react';
import { Card } from 'antd';
import { clearElements, initialParamsState } from './reducers/elements.reducer';
import {
    createOperation as createEditOperation,
    fullResetOperation,
    removeConcurrentOperation,
    resetOperation,
    resetSelectedIds,
    setElementsListParams,
    setOperationElementsFilters,
} from '../operationForm/reducers/operationForm.reducer';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { push } from 'connected-react-router';
import { Spin } from '../../../components';
import { getPathFromState, getStateFromPath, isDefined, roundMaxFilterValue, roundMinFilterValue } from '../../../shared/util/utils';
import { OperationTypeCodeEnum, ProjectInfoRead } from '../../../server';
import { Location } from 'history';
import { filters } from './elements-list-filters';
import debounce from 'lodash/debounce';
import { localize } from '../../../localization/localizationUtils';
import { LocalizationEnum } from '../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Grid } from '../../../components/grid/Grid';
import { setGridCategoriesStorageData, setGridStorageDataFilters } from '../../../components/grid/utils';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { canViewFinancialData } from '../../../shared/util/permissionUtils';
import { GridName } from '../../../components/grid/utils/types';
import { IDownloadDocumentParamsItem } from '../../../components/exportPopovers/downloadDocument/DownloadDocumentPopover';
import { OperationBottomBlock } from '../operationForm/operationModule/operationFormBottomBlock/OperationBottomBlock';
import FormValue from '../../../components/formValue/FormValue';
import { formatMoney } from '../../../shared/util/formatMoney';
import { getOperationFormElementsListGridProperties } from '../operationForm/operationModule/elementsList/OperationFormElementsList';
import { getOperationFormElementsListColumns } from '../operationForm/operationModule/elementsList/OperationFormElementsListColumns';
import { getIntl } from '../../../App';
import { ErrorItem, OperationProblemModal, OperationProblemModalType } from '../operationForm/operationModule/OperationProblemModal';
import { getOperationProblems } from '../operationForm/operationModule/OperationModule';
import { sortByForElements } from '../operationForm/reducers/operationForm.reducer.utils';
import { elementsListFiltersPrepareData } from './elements-list.utils';
import { SelectedElementsActionButtonsBlock } from '../operationForm/operationModule/components/selectedElementsActionButtonsBlock/selectedElementsActionButtonsBlock';
import { OperationEditSaveBlock } from '../../../components/blocks/operationEditSaveBlock/operationEditSaveBlock';
import { SelectedIdsFilter } from './components/selectedIdsFilter/selectedIdsFilter';
import { SetOperationShiftCountEnabledPopover } from '../dev/components/setOperationShiftCountEnabledPopover/setOperationShiftCountEnabledPopover';
import {ToggleScanSoundButton} from "../../../components/buttons/toggleScanSoundButton/toggleScanSoundButton";

interface IState {
    loading: boolean;
    problemModalIsShowing: boolean;
    selectedIds: number[];
}

export interface ElementsListProps extends StateProps, DispatchProps, WrappedComponentProps {
    location: Location;
    parentId: number;
    parentType: 'project' | 'operation' | 'projectTemplate' | 'shipping';
    operationEntityType?: 'project' | 'projectTemplate' | 'shipping';
    visible?: boolean;
    gridName: GridName;
    excludeColumns?: string[];
    excludeFields?: string[];
    parentProjectEntity?: ProjectInfoRead;
    exportActOptions?: IDownloadDocumentParamsItem[];
    exportEstimateOptions?: IDownloadDocumentParamsItem[];
    updateParentEntity: () => void;
}

class Component extends React.Component<ElementsListProps, IState> {
    private grid;
    private filtersForm: WrappedFormUtils | undefined;
    private initialValues;
    private availableFilters;

    constructor(props: ElementsListProps) {
        super(props);

        this.state = {
            loading: false,
            problemModalIsShowing: false,
            selectedIds: [],
        };
        this.updateFilters = debounce(this.updateFilters, 250);
        this.componentDidMount1();
    }

    getDefaultFilters = () => {
        const values: any = {};
        if (this.props.operationFormElementsData.availableFilters && this.props.operationFormElementsData.filters) {
            if (
                !this.props.operationFormElementsData.filters.discount &&
                this.props.operationFormElementsData.availableFilters['rentTerms.discount']
            ) {
                values.discount = this.props.operationFormElementsData.availableFilters['rentTerms.discount'];
            }

            if (
                !this.props.operationFormElementsData.filters.finalTotalPrice &&
                this.props.operationFormElementsData.availableFilters['finalTotalPrice']
            ) {
                values.finalTotalPrice = [
                    roundMinFilterValue(this.props.operationFormElementsData.availableFilters['finalTotalPrice'][0] / 100),
                    roundMaxFilterValue(this.props.operationFormElementsData.availableFilters['finalTotalPrice'][1] / 100),
                ]; //this.props.operationFormElementsData.availableFilters['finalTotalPrice'];
            }

            if (
                !this.props.operationFormElementsData.filters.shiftCount &&
                this.props.operationFormElementsData.availableFilters['rentTerms.shifts.shiftCount']
            ) {
                values.shiftCount = this.props.operationFormElementsData.availableFilters['rentTerms.shifts.shiftCount'];
            }
        }
        return values;
    };

    componentDidMount1 = () => {
        let rentOrSubrent: 'rent' | 'subrent' | 'all' = 'all';
        let parentEntityType;

        if (this.props.parentType === 'project') {
            rentOrSubrent = 'rent';
            parentEntityType = 'project';
        } else if (this.props.parentType === 'shipping') {
            parentEntityType = 'shipping';
        } else if (this.props.parentType === 'projectTemplate') {
            parentEntityType = 'projectTemplate';
        } else if (
            this.props.operation?.typeCode === OperationTypeCodeEnum.DRAFT ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.ORDER ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.BOOK ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.RENT ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.PROLONG ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.RETURN ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.RETURNBROKEN ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.LOSTNORETURN ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.CANCEL
        ) {
            rentOrSubrent = 'rent';
            parentEntityType = 'project';
        } else if (
            this.props.operation?.typeCode === OperationTypeCodeEnum.SUBRENTDRAFT ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER ||
            this.props.operation?.typeCode === OperationTypeCodeEnum.SUBRENTCANCEL
        ) {
            rentOrSubrent = 'subrent';
            parentEntityType = 'shipping';
        }

        if (this.props.operationEntityType) parentEntityType = this.props.operationEntityType;

        const defaultFilters = this.getDefaultFilters();

        this.initialValues = {
            ...this.props.operationFormElementsData.filters,
            startDate: !this.props.operationFormElementsData.filters?.startDate
                ? [undefined, undefined]
                : this.props.operationFormElementsData.filters?.startDate,
            endDate: !this.props.operationFormElementsData.filters?.endDate
                ? [undefined, undefined]
                : this.props.operationFormElementsData.filters?.endDate,
            rentOrSubrent,
            parentEntityType,
            ...defaultFilters,
        };

        this.availableFilters = this.props.operationFormElementsData.availableFilters;
    };

    componentDidUpdate = (prevProps: ElementsListProps) => {
        if (prevProps.operationFormElementsData.filters !== this.props.operationFormElementsData.filters) {
            setTimeout(() => {
                const defaultFilters = this.getDefaultFilters();
                const fields = { ...(this.props.operationFormElementsData.filters as any), ...defaultFilters };
                this.filtersForm?.setFieldsValue(fields);
            }, 200);
        }
    };

    clearSelection = () => {
        if (this.grid) this.grid.clearSelection();
    };

    onPageSizeChanged = (size) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { limit: size, page: 1 }));
        this.props.setElementsListParams({ limit: size, page: 1 });
        this.clearSelection();
    };

    onSortedChange = (id: string, desc: boolean) => {
        const sortBy = sortByForElements(id as any);
        const sortOrder = desc ? 'DESC' : 'ASC';
        this.props.push(
            getPathFromState(this.props.location.pathname, this.props.location.search, {
                sortBy,
                sortOrder,
            })
        );
        this.props.setElementsListParams({
            sortBy: id,
            sortOrder,
        });
        this.clearSelection();
    };

    onPageChanged = (page: number) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { page: page }));
        this.props.setElementsListParams({
            page: page,
        });
        this.clearSelection();
    };

    onSelectionChanged = (data) => {
        this.setState({ selectedIds: data.map((item) => +item) });
    };

    getActionButtons = () => {
        return [
            <SelectedElementsActionButtonsBlock
                selectedIds={this.state.selectedIds}
                onApply={() => {
                    this.clearSelection();
                }}
                hidePriceButtons={!this.props.canViewFinancialData}
            />,
        ];
    };

    onFiltersChange = (data) => {
        this.updateFilters(data);
    };

    updateFilters = (data) => {
        data = elementsListFiltersPrepareData(data, this.availableFilters);
        data.page = 1;
        const dataForElements = {
            ...data,
            selectedIds: undefined,
            search: this.props.elementsPageParams.search,
            categoryIds: this.props.elementsPageParams.categoryIds,
            problem: this.props.elementsPageParams.problem,
        };
        setGridStorageDataFilters(this.props.gridName, dataForElements);
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, dataForElements));
        this.props.setOperationElementsFilters({ ...data });
        this.clearSelection();
    };

    getFiltersForm = (ref) => {
        return (this.filtersForm = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    resetFilters = () => {
        let data = {
            search: this.props.elementsPageParams.search,
            typeCode: undefined,
            hideInactive: undefined,
            hide: undefined,
            problem: this.props.elementsPageParams.problem,
            categoryIds: this.props.elementsPageParams.categoryIds,

            startDate: undefined,
            endDate: undefined,

            discount: undefined,
            shiftCount: undefined,
            finalTotalPrice: undefined,
            tags: undefined,
            selectedIds: undefined,
        };

        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        setGridStorageDataFilters(this.props.gridName, data);
        setGridCategoriesStorageData(this.props.gridName, { selectedIds: undefined });
        this.props.setOperationElementsFilters(data);
        this.filtersForm?.setFieldsValue({ selectedIds: undefined });
    };

    // onCategoriesTreeChanged = (selectedKeys) => {
    //     const { locationPathname, locationSearchParams } = this.props;
    //     const params = {
    //         ...Object.fromEntries(new URLSearchParams(locationSearchParams).entries()),
    //         categoryIds: selectedKeys.join(';'),
    //         page: 1,
    //     };
    //     this.props.push(getPathFromState(locationPathname, '', params));
    //     setGridStorageDataFilters(this.props.gridName, params);
    //     this.clearSelection();
    // };

    renderFiltersRightExtraBlock = () => {
        const { finalTotalPrice, finalTotalPriceWithTaxes } = this.props;
        const totalPrice = isDefined(finalTotalPriceWithTaxes) ? finalTotalPriceWithTaxes : finalTotalPrice;
        const selectedIds = this.props.operationFormElementsData.selectedIds;
        return (
            <div style={{ display: 'flex' }}>
                {selectedIds && (
                    <SelectedIdsFilter
                        style={{ marginLeft: 38 }}
                        value={selectedIds}
                        onChange={(value) => {
                            //this.updateFilters({...this.props.elementsFilters, selectedIds: undefined});
                            this.props.resetSelectedIds();
                        }}
                    />
                )}
                <div style={{ marginLeft: 38 }}>
                    <div>{localize(LocalizationEnum.ASPECT__GLOBAL__IN_TOTAL)}</div>
                    <div className={'rr-value'}>
                        <FormValue value={formatMoney(totalPrice)} originalValue={totalPrice} />
                    </div>
                </div>
            </div>
        );
    };

    // renderSaveButton = (buttonDisabled: boolean) => {
    //     return (
    //         <Button disabled={buttonDisabled} type={'primary'} style={{borderWidth: 1, marginLeft: 10}} onClick={()=>{
    //             void this.createOperation();
    //         }}>Сохранить</Button>
    //     );
    // }

    createOperation = async () => {
        this.setState({ loading: true });
        await this.props.createEditOperation(getIntl());
        this.props.updateParentEntity();
        this.props.fullResetOperation();
        this.setState({ loading: false });
    };

    renderSaveOperationButton = (
        errType: OperationProblemModalType | undefined,
        problems: ErrorItem[],
        operationWarningMessage: ReactNode,
        showCancelButton: boolean
    ) => {
        return (
            <OperationEditSaveBlock
                createOperation={() => void this.createOperation()}
                operationWarningMessage={operationWarningMessage}
                problemType={errType}
                onProblemIconClick={() => this.setState({ problemModalIsShowing: true })}
                problems={problems}
                onCancel={
                    showCancelButton
                        ? () => {
                              this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { page: 1 }));
                              this.props.fullResetOperation();
                              this.props.clearElements();
                          }
                        : undefined
                }
            />
        );

        // const buttonDisabled = Boolean(operationWarningMessage) || errType === OperationProblemModalType.ERROR;
        //
        // return (
        //     <div style={{display: 'inline-flex', gap: 10, alignItems: 'center'}}>
        //
        //         {/* Предупреждение */}
        //         {errType === OperationProblemModalType.WARNING && <WarningIcon onClick={() => this.setState({ problemModalIsShowing: true })} />}
        //
        //         {/* Ошибка */}
        //         {errType === OperationProblemModalType.ERROR && problems.length > 0 && <ProblemIcon onClick={() => this.setState({ problemModalIsShowing: true })} />}
        //
        //         {operationWarningMessage ? (
        //             <Popover
        //                 placement="bottomRight"
        //                 arrowPointAtCenter={true}
        //                 autoAdjustOverflow={true}
        //                 content={operationWarningMessage}
        //                 overlayStyle={{ maxWidth: 400 }}
        //             >
        //                 {this.renderSaveButton(buttonDisabled)}
        //             </Popover>
        //         ) : (
        //             this.renderSaveButton(buttonDisabled)
        //         )}
        //         {showCancelButton && <EditOperationResetButton forHeader={false} entityId={this.props.projectId}/>}
        //     </div>
        // );
    };

    getDataForEditOperationElements = () => {
        const { sortedData, newEntities, excludeColumns, canAddElements } = getOperationFormElementsListGridProperties({
            ...this.props,
            listParams: {
                limit: this.props.operationFormElementsData.limit,
                page: this.props.operationFormElementsData.page,
                sortBy: this.props.operationFormElementsData.sortBy,
                sortOrder: this.props.operationFormElementsData.sortOrder,
            },
        });

        const filtersExcludeFields = [
            'search',
            'problem' /*, !this.props.elementsFilters?.selectedIds ? 'selectedIds' : undefined*/,
        ].filter((item) => item) as string[];

        return {
            excludeColumns: [...(excludeColumns || []), 'id', 'leftoverInstanceCount'],
            filtersExcludeFields,
            data: sortedData,
            columns: getOperationFormElementsListColumns(4),
            canGenerateAct: false,
            canGenerateEstimate: false,
            filteredCount: newEntities ? newEntities.length : 0,
            filtersCurrentValues: {
                ...this.props.operationFormElementsData.filters /*, selectedIds: this.props.elementsFilters?.selectedIds*/,
            },
        };
    };

    operationProblemModalOnOk = () => {
        void this.createOperation();
        this.setState({ problemModalIsShowing: false });
    };

    operationProblemModalOnCancel = () => {
        this.setState({ problemModalIsShowing: false });
    };

    render() {
        console.log('ElementsListEdit render()', this.props);
        const data = this.getDataForEditOperationElements();
        const { errType, problems, operationWarningMessage } = getOperationProblems({
            elements: this.props.elements.entities,
            operationTypeCode: this.props.operationTypeCode,
            operationCorrectionStateCode: this.props.operationCorrectionStateCode,
            projectTemplate: this.props.projectTemplate,
            elementsCount: this.props.elementsCount,
            shiftCountRoundingType: this.props.shiftCountRoundingType,
            operationShiftCountWarningsEnabled: this.props.operationShiftCountWarningsEnabled,
        });

        return this.initialValues ? (
            <Spin spinning={this.state.loading} delay={this.state.loading ? 0 : undefined}>
                {/*<PromptRedirect onRedirect={()=>{*/}
                {/*    this.props.fullResetOperation();*/}
                {/*}} />*/}
                <Card bordered={false} className={'rr-operationForm-elementsList'}>
                    <Grid
                        convertSortBy={(id) => {
                            return sortByForElements(id as any);
                        }}
                        filtersData={filters}
                        filtersInitialValues={this.initialValues}
                        filtersCurrentValues={data.filtersCurrentValues}
                        filtersDefaultValues={initialParamsState}
                        filtersGetFiltersFormRef={this.getFiltersForm}
                        filtersOnChange={this.onFiltersChange}
                        filtersResetFiltersCb={this.resetFilters}
                        filtersExcludeFields={data.filtersExcludeFields}
                        filtersRightBlockExtraContent={this.renderFiltersRightExtraBlock()}
                        excludeColumns={data.excludeColumns}
                        ref={(ref) => {
                            if (!this.grid) this.grid = ref;
                        }}
                        exportBlock={<><ToggleScanSoundButton /><SetOperationShiftCountEnabledPopover /></>}
                        onSortedChange={this.onSortedChange}
                        onPageChanged={this.onPageChanged}
                        onPageSizeChanged={this.onPageSizeChanged}
                        onSelectionChanged={this.onSelectionChanged}
                        filtered={data.filteredCount}
                        pageSize={this.props.operationFormElementsData.limit}
                        currentPage={this.props.operationFormElementsData.page}
                        columns={data.columns}
                        data={data.data}
                        indexFieldName={'id'}
                        actionButtons={this.getActionButtons()}
                        //categoriesFilter
                        //categoriesComponent={GridCategoriesTree}
                        //categoriesType={'PRODUCT'}
                        //categoriesFilterOffsetBottom={131}
                        // categoriesSelectedKeys={
                        //     this.props.operationFormElementsData.filters?.categoryIds
                        // }
                        // categoriesOnSelect={this.onCategoriesTreeChanged}
                        entityType={'element'}
                        rowPopoverComponent={undefined}
                        defaultSorted={this.props.operationFormElementsData.sortBy}
                        defaultSortDesc={this.props.operationFormElementsData.sortOrder === 'DESC'}
                        //hideArchive={this.props.pageParams.hideInactive}
                        gridName={this.props.gridName}
                        onPageSelect={[10, 20, 50, 100, 200, 500]}
                        bottomPaginationRightExtraContent={this.renderSaveOperationButton(errType, problems, operationWarningMessage, true)}
                        pinned={{ right: ['actions'] }}
                    />
                </Card>

                <OperationBottomBlock />

                <OperationProblemModal
                    errors={problems}
                    visible={this.state.problemModalIsShowing}
                    type={errType}
                    isCorrection={this.props.operationTypeCode === OperationTypeCodeEnum.CORRECT}
                    onOk={this.operationProblemModalOnOk}
                    onCancel={this.operationProblemModalOnCancel}
                />
            </Spin>
        ) : null;
    }
}

const mapStateToProps = (storeState: IRootState) => {
    let pageParams = getStateFromPath(storeState.router.location.search),
        categoryIds = pageParams['categoryIds'] || undefined;

    return {
        operation: storeState.operation.entity,
        locationSearchParams: storeState.router.location.search,
        locationPathname: storeState.router.location.pathname,
        categoryIds: categoryIds,
        operationTypeCode: storeState.operationForm.typeCode,
        projectStateCode:
            storeState.operation.entity?.projectStateCode ||
            storeState.operation.entity?.subrentStateCode ||
            storeState.project.entity?.stateCode ||
            storeState.subrent.entity?.stateCode,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        currentOperationUUID: storeState.operationForm.currentOperationUUID,

        finalTotalPrice: storeState.operationForm.finalTotalPrice,
        finalTotalPriceWithTaxes: storeState.operationForm.finalTotalPriceWithTaxes,
        elements: storeState.operationForm.elements,
        entities: storeState.operationForm.elements.entities,
        projectTemplate: storeState.operationForm.projectTemplate,
        elementIsNowEditing: storeState.operationForm.elementIsNowEditing,
        operationCorrectionStateCode: storeState.operationForm.targetStateCode,
        shiftCountRoundingType: storeState.businessAccountPreferences.preferences?.shiftCountRoundingType,
        elementsCount: storeState.operationForm.elementsCount,
        elementsFilters: storeState.operationForm.elements.filters,
        operationFormElementsData: storeState.operationForm.elements,
        elementsPageParams: storeState.elements.params,
        isSimpleOrder: storeState.operationForm.isSimpleOrder || false,
        projectId: storeState.operationForm.projectId,
        operationShiftCountWarningsEnabled: storeState.operationForm.operationShiftCountWarningsEnabled,
    };
};

const mapDispatchToProps = {
    push,
    fullResetOperation,
    removeConcurrentOperation,
    createEditOperation,
    resetOperation,
    setOperationElementsFilters,
    setElementsListParams,
    clearElements,
    resetSelectedIds,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const ElementsListEdit = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    injectIntl(Component, { forwardRef: true })
);
