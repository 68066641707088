import React, {ReactNode, useState} from "react";
import {Icon} from "antd";
import {SubrentRecord, SubrentStateCodeEnum} from "../../../../../server";
import {BaseEntityMovePopover} from "./baseEntityMovePopover";
import {LocalizationEnum} from "../../../../../localization";
import {useLocalize} from "../../../../../core/hooks/useLocalize";
import {IconCrosshairsSolid} from "../../../../../components/icons";
import {SubrentSelect} from "../../../../../components/v2/select/subrentSelect/subrentSelect";
import {
    SubrentShippingStateBadge
} from "../../../subrent/shippings/components/SubrentShippingBadge/SubrentShippingBadge";
import {EntityState} from "./selectProjectPopover";

interface SelectSubrentPopoverProps<EntityRecord> {
    closeParentElement: () => void;
    projectId?: number;
    copy?: boolean;
    onOkCallback: (targetProject: SubrentRecord) => void;
    allowInProject: (record:SubrentRecord, entity:EntityRecord)=>boolean;
    selectedEntities:EntityRecord[];
    children: ReactNode;
}

const SameProjectIcon = <Icon style={{ fontSize: 20, marginRight: 8 }} component={IconCrosshairsSolid} />;
const SameProjectCopyIcon = <Icon style={{ fontSize: 20, marginRight: 8, color: '#00afee' }} component={IconCrosshairsSolid} />;

export const SelectSubrentPopover = <EntityRecord,>(props:SelectSubrentPopoverProps<EntityRecord>) => {
    const {children, allowInProject, selectedEntities, projectId, onOkCallback, copy} = props;
    const L = useLocalize();
    const [selectedProject, setSelectedProject] = useState<SubrentRecord | undefined>();

    return (
        <BaseEntityMovePopover
            select={
                <SubrentSelect
                    style={{width: '100%', maxWidth: 314}}
                    className={'rr-select-gray'}
                    placeholder={L(LocalizationEnum.PAGE__ELEMENTS__POPUP_NOTIFICATIONS__PLACEHOLDER__SELECT_PROJECT)}
                    openOnMount={true}
                    onDataChange={(data) => {
                        setSelectedProject(data?.[0]);
                    }}
                    stateCode={[SubrentStateCodeEnum.DRAFT, SubrentStateCodeEnum.FINISHED, SubrentStateCodeEnum.INPROGRESS]}
                    value={selectedProject?.id}
                    getOptionProps={(record) => {
                        let disabled = selectedEntities.some((entity)=>!allowInProject(record, entity));
                        const isSameProject = record.id === projectId;
                        if(!copy && isSameProject) disabled = true;
                        const label = (
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {isSameProject && (copy ? SameProjectCopyIcon : SameProjectIcon)}
                                {disabled && !isSameProject && <SubrentShippingStateBadge style={{marginRight: 5}} type={record.stateCode} dot hideLabel />}
                                <span>{record.shortName} <span style={{fontWeight: 200}}>{record.supplierShortName}</span></span>
                            </div>
                        );
                        return {
                            label,
                            disabled
                        }
                    }}
                />
            }
            onOkCallback={onOkCallback}
            onClose={()=>{
                setSelectedProject(undefined);
            }}
            selectedRecord={selectedProject}>
            {children}
        </BaseEntityMovePopover>
    );
}

export const isAllowedInSubrent = (subrent:SubrentRecord, s:EntityState) => {
    const subrentState = subrent.stateCode;
    if(subrentState === SubrentStateCodeEnum.DRAFT){
        return !s.isActive && !s.isFinished;
    }else if(subrentState === SubrentStateCodeEnum.INPROGRESS){
        return true;
    }else if(subrentState === SubrentStateCodeEnum.FINISHED){
        return !s.isActive;
    }else if(subrentState === SubrentStateCodeEnum.CANCELED){
        return s.isCanceled;
    }
    return false;
}