import React, { FC, useState } from 'react';
import { DataDisplayBlock, DataDisplayItemType } from '../../../../../../../../components/dataDisplay';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { ProjectInfoRead, ProjectStateCodeEnum } from '../../../../../../../../server';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { logisticsModuleEnabledSelector } from '../../../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { LocationDrawer } from '../LocationDrawer';
import { DateUtils } from '../../../../../../../../core/utils/dateUtils';
import { DateIntervalHighLight } from '../../../../../../../../components/dataDisplay/dataDisplayItemProps';

interface ProjectPageDescriptionPeriodAndLocationBlockProps {
    data: ProjectInfoRead;
}

export const ProjectPageDescriptionPeriodAndLocationBlock: FC<ProjectPageDescriptionPeriodAndLocationBlockProps> = ({ data }) => {
    const logisticsModuleEnabled = useAppSelector(logisticsModuleEnabledSelector);
    const [locationId, setLocationId] = useState<number | undefined>();

    return (
        <div style={{ marginLeft: -8, marginRight: -8 }}>
            <DataDisplayBlock
                data={[
                    [
                        {
                            label: 'Период работ',
                            type: DataDisplayItemType.DateWithTimeInterval,
                            value: {
                                from: data.startDate,
                                to: data.targetFinishDate,
                            },
                            highlight: [
                                (data.stateCode === ProjectStateCodeEnum.DRAFT ||
                                    data.stateCode === ProjectStateCodeEnum.COORDINATION ||
                                    data.stateCode === ProjectStateCodeEnum.BOOKED) &&
                                DateUtils.isPast(data.startDate)
                                    ? 'start'
                                    : undefined,
                                [
                                    ProjectStateCodeEnum.DRAFT,
                                    ProjectStateCodeEnum.COORDINATION,
                                    ProjectStateCodeEnum.INPROGRESS,
                                    ProjectStateCodeEnum.BOOKED,
                                    ProjectStateCodeEnum.RENTED,
                                ].includes(data.stateCode) && DateUtils.isPast(data.targetFinishDate)
                                    ? 'end'
                                    : undefined,
                            ].filter((value) => value !== undefined) as DateIntervalHighLight,
                            emptyValueText: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                        },
                    ],
                    logisticsModuleEnabled
                        ? [
                              {
                                  label: 'Локация',
                                  type: DataDisplayItemType.Drawer,
                                  value: data.locationName
                                      ? {
                                            text: data.locationName,
                                            onClick: () => {
                                                //if(data.locationId) onLocationClick(data.locationId);
                                                setLocationId(data.locationId);
                                            },
                                        }
                                      : undefined,
                                  emptyValueText: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                              },
                          ]
                        : undefined,
                    data.stateCode === ProjectStateCodeEnum.FINISHED && [
                        {
                            label: localize(LocalizationEnum.ASPECT__FIELDS__PROJECT__REAL_FINISH_DATE),
                            type: DataDisplayItemType.DateWithTime,
                            value: data.realFinishDate,
                            emptyValueText: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                        },
                    ],
                ]}
            />
            {locationId && (
                <LocationDrawer
                    id={locationId}
                    onClose={() => {
                        setLocationId(undefined);
                    }}
                />
            )}
        </div>
    );
};
