import React, { FC } from 'react';
import { EntityIndicatorsBlock, EntityIndicatorsBlockProps } from './entityIndicatorsBlock';
import { SystemIcon, SystemIconType } from '../v2/systemIcon/systemIcon';
import { CurrentRentIndicatorsTab } from './currentRentIndicatorsTab';
import { CurrentRentIndicatorsEntity, CurrentRentIndicatorsEntityType } from './currentRentIndicatorsBlock';
import classNames from 'classnames';

export interface EntityRentIndicatorsBlockProps {
    entityType: CurrentRentIndicatorsEntityType;
    entity: CurrentRentIndicatorsEntity;
    storageKey: EntityIndicatorsBlockProps['storageKey'];
    costOfRent?: number;
    className?: string;
}

export const EntityRentIndicatorsBlock: FC<EntityRentIndicatorsBlockProps> = ({ entityType, entity, storageKey, costOfRent, ...props }) => {
    const { rentIndicators } = entity;
    let problem: 'error' | 'warning' | undefined;
    let className: string | undefined;

    if (rentIndicators?.problemsAndWarnings.returnDelay.elementsCount || rentIndicators?.problemsAndWarnings.bookShortage.elementsCount) {
        problem = 'error';
        className = 'tab-header-hard-problem';
    } else if (
        rentIndicators?.problemsAndWarnings.giveawayDelay.elementsCount ||
        rentIndicators?.problemsAndWarnings.orderShortage.elementsCount
    ) {
        problem = 'warning';
        className = 'tab-header-problem';
    } else {
        className = 'tab-header-not-problem';
    }

    return (
        <EntityIndicatorsBlock
            title={'Аренда'}
            icon={<SystemIcon type={SystemIconType.project} />}
            problem={problem}
            className={classNames(className, props.className)}
            storageKey={storageKey}
            stateCode={'stateCode' in entity ? entity.stateCode : undefined}
        >
            <CurrentRentIndicatorsTab entity={entity} entityType={entityType} costOfRent={costOfRent} />
        </EntityIndicatorsBlock>
    );
};
