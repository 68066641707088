import { formatMoney } from '../../shared/util/formatMoney';
import classNames from 'classnames';

import './MoneyValue.less';
import { useAppSelector } from '../../store/hooks';
import { IRootState } from '../../shared/reducers';

interface Props {
    value: number | null | undefined;
    withCurrencySign?: boolean;
    muted?: boolean;
    highlighted?: boolean;
    checkIncludeBooked?: boolean;
}

const MoneyValue = ({ value, withCurrencySign = true, muted, highlighted, checkIncludeBooked }: Props) => {
    const content = formatMoney(value, withCurrencySign);

    const includeBooked = useAppSelector(
        (state: IRootState) => state.businessAccountPreferences.preferences?.includeBookedElementsInProjectSum
    );

    return value !== null && value !== undefined ? (
        <span
            className={classNames(
                'rr-money-value',
                (muted || value === 0 || (checkIncludeBooked && !includeBooked)) && 'rr-money-value-muted',
                highlighted && 'rr-money-value-highlighted'
            )}
        >
            {content}
        </span>
    ) : null;
};
export default MoneyValue;
