import React from 'react';
import {BasePage, IBasePageProps} from '../../../components/page/BasePage';
import {modalShow} from "../../../core/modals/modals";
import {RangePicker} from "../../../components/v2/calendar/rangePicker/rangePicker";
//import {Picker0} from "./rangePickerNew/picker/picker";
import moment from "moment";
import {DateTimePicker} from "../../../components/dateTimePicker/dateTimePicker";
import {PricingSchemeSelect} from "../../../components/v2/select/pricingSchemeSelect/pricingSchemeSelect";
import {SoundType} from "../../../../soundEffectsPlayer/soundEffectsPlayer";
import {soundEffectsPlayer} from "../../../../index";

interface DevPageProps extends IBasePageProps {
}

interface State {
    dates:any;
}



export class DevPage extends BasePage<DevPageProps, State> {

    constructor(props) {
        super(props);

        this.state = {
            dates: [moment('2024-07-20'), moment('2024-07-29')]
        };
    }

    renderContent() {

        //const { MonthPicker, RangePicker } = DatePicker;

        return (
            <>

                <button onClick={() => {
                    soundEffectsPlayer.playOrder(SoundType.scanKitAddedSuccess);
                    soundEffectsPlayer.playOrder(SoundType.scanInstanceAddedSuccess);
                }}>test
                </button>

                <button onClick={() => {
                    soundEffectsPlayer.play(SoundType.scanInstanceAddedSuccess);
                }}>{SoundType.scanInstanceAddedSuccess}</button>

                <button onClick={() => {
                    soundEffectsPlayer.play(SoundType.scanKitAddedSuccess);
                }}>{SoundType.scanKitAddedSuccess}</button>

                <button onClick={() => {
                    soundEffectsPlayer.play(SoundType.scanError);
                }}>{SoundType.scanError}</button>

                <button onClick={() => {
                    soundEffectsPlayer.play(SoundType.scanInstanceAlreadyAddedError);
                }}>{SoundType.scanInstanceAlreadyAddedError}</button>

                {/*<UserSelect />*/}

                <div>
                    <div>
                        <span>Статус</span>
                    </div>
                    <div>
                        <span style={{fontWeight: 600, letterSpacing: -.5}}>Статус</span>
                    </div>
                </div>

                <div>
                    <div>
                        <span>WWWWWWWWWWWW</span>
                    </div>
                    <div>
                        <span style={{fontWeight: 600, letterSpacing: -.5}}>WWWWWWWWWWWW</span>
                    </div>
                </div>

                <button onClick={() => {
                    modalShow('location.view:1');
                }}>location
                </button>

                <button onClick={() => {
                    modalShow('inventoryMovement.view:17476120');
                }}>inventoryMovement
                </button>

                <RangePicker disabled={true} pricingScheme={undefined}/>

                <RangePicker
                    //disabled={true}
                    //placeholder={['Дата выдачи', 'Дата возврата']}
                    onChange={(dates) => {
                        console.log('onChange', dates);
                        this.setState({dates: dates})
                    }}
                    value={this.state.dates}
                    showTime={true}
                    allowClear={true}
                    pricingScheme={undefined}
                />

                <DateTimePicker/>

                {/*<RangeCalendar*/}
                {/*    value={this.state.dates}*/}
                {/*    onChange={(dates)=>{*/}
                {/*        this.setState({dates: dates})*/}
                {/*    }}*/}
                {/*/>*/}

                <RangePicker pricingScheme={undefined}/>

                <PricingSchemeSelect style={{width: 300}} value={14403799}/>

                {/*<Picker0 calendar={<div style={{backgroundColor: '#FFFFCC', width: 500, height: 500}}>XXXZZZYYY</div>}>*/}
                {/*    <span>526</span>*/}
                {/*</Picker0>*/}

                {/*<RangePicker*/}

            </>
        );
    }
}
