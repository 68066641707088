import {useCallback} from 'react';
import {ProductValue} from '../../../../add-kit-popover';
import {ProductCompositionColumnsObj} from '../../columns/productCompositionColumns.types';
import {isDefined} from "../../../../../../../../shared/util/utils";

export const useAddProducts = (editData: unknown[], setEditData: (editData: ProductCompositionColumnsObj[]) => void) => {
    return useCallback(
        async (newProducts: ProductValue[] | undefined) => {
            if (newProducts === null || !Array.isArray(newProducts)) return;

            const newId = Math.min(0, ...editData.map(item=>(item as ProductCompositionColumnsObj).newId).filter(id=>isDefined(id)) as number[]) - 1;

            const gridItems: ProductCompositionColumnsObj[] = newProducts.map((product) => ({
                discount: 0,
                externalCode: product.externalCode!,
                finalInstancePrice: product.pricePerShift,
                finalTotalPrice: product.pricePerShift,
                id: newId + product.id,
                image: product.mainImage,
                instanceCount: 1,
                instanceTrackingTypeCode: product.instanceTrackingTypeCode!,
                linksProductVariant: product.nomenclatureEntityTypeCode === 'VARIANT',
                main: false,
                memberOrder: editData.length + 1,
                pricePerShift: product.pricePerShift,
                productId: product.productId,
                productShortName: product.productShortName,
                productStateCode: product.stateCode,
                requiredInKit: true,
                variantId: product.variantId,
                variantName: product.variantName,
                newId
            }));

            setEditData([...(editData ?? []), ...gridItems] as ProductCompositionColumnsObj[]);
        },
        [editData, setEditData]
    );
};
