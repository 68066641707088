import { FormFieldsGroup, FormItemType, IFormField } from '../../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum, localize } from '../../../../../localization';
import React, { ReactNode } from 'react';
import { CounterpartyActivityTypeCodeEnum, InstanceStateCodeEnum } from '../../../../../server';
import debounce from 'lodash/debounce';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { validateField } from '../../../../../shared/util/validateField';
import { EntityRemoteSelect } from '../../../../../components/select/EntityRemoteSelect';
import { createHiddenField } from '../../../../../components/modalForm/utils';
import { getStoreState } from '../../../../../../index';
import { canViewFinancialData } from '../../../../../shared/util/permissionUtils';
import {
    ProductVariantSelectAndCreate,
    ProductVariantSelectAndCreateProps,
} from '../../../../../components/v2/select/productVariantSelect/productVariantSelectAndCreate';
import { subrentModuleEnabledSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';

export const getInstanceStateValueByCode = (code: InstanceStateCodeEnum) => {
    if (code === InstanceStateCodeEnum.NEW)
        return (
            <div style={{ display: 'flex' }}>
                <div className="product-instance-popover-status">
                    <div className="rr-product-instance-circle-NEW"></div>
                </div>
                {localize(LocalizationEnum.ASPECT__STATE_CODE__INSTANCE__NOT_IN_STOCK)}
            </div>
        );
    else if (code === InstanceStateCodeEnum.ACTIVE)
        return (
            <div style={{ display: 'flex' }}>
                <div className="product-instance-popover-status">
                    <div className="rr-product-instance-circle-ACTIVE"></div>
                </div>
                {localize(LocalizationEnum.ASPECT__STATE_CODE__INSTANCE__ACTIVE)}
            </div>
        );
    else if (code === InstanceStateCodeEnum.UNDERMAINTENANCE)
        return (
            <div style={{ display: 'flex' }}>
                <div className="product-instance-popover-status">
                    <div className="rr-product-instance-circle-UNDERMAINTENANCE"></div>
                </div>
                {localize(LocalizationEnum.ASPECT__STATE_CODE__INSTANCE__UNDERMAINTENANCE)}
            </div>
        );
    else if (code === InstanceStateCodeEnum.SUPPLIERACTIVE)
        return (
            <div style={{ display: 'flex' }}>
                <div className="product-instance-popover-status">
                    <div className="rr-product-instance-circle-SUPPLIER_ACTIVE"></div>
                </div>
                Активный чужой
            </div>
        );
    else if (code === InstanceStateCodeEnum.SUPPLIERDECOMMISSIONED)
        return (
            <div style={{ display: 'flex' }}>
                <div className="product-instance-popover-status">
                    <div className="rr-product-instance-circle-SUPPLIER_DECOMMISSIONED"></div>
                </div>
                Списан чужой
            </div>
        );
    else if (code === InstanceStateCodeEnum.DECOMMISSIONED)
        return (
            <div style={{ display: 'flex' }}>
                <div className="product-instance-popover-status">
                    <div className="rr-product-instance-circle-DECOMMISSIONED"></div>
                </div>
                Списан
            </div>
        );
    else return code;
};

export const codeOrNameField = {
    label: LocalizationEnum.ASPECT__FIELDS__INSTANCE__CODE_OR_NAME,
    id: 'nameOrCode' as const,
    type: FormItemType.String,
    style: { minWidth: 120 },
    className: 'rr-select-gray',
    maxLength: 30,
    required: true,
    validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
        const ignoreCurrentId = form.getFieldValue('instanceEdit') ? form.getFieldValue('instanceId') : undefined;
        validateField(
            'instance',
            fieldName,
            value,
            form.getFieldValue('productId'),
            ignoreCurrentId,
            'Наименование уже использовано в продукте',
            cb
        );
    }, 500),
} satisfies IFormField;

export const variantField = {
    label: LocalizationEnum.ASPECT__ENTITY_TYPE__VARIANT,
    id: 'listVariants' as const,
    type: FormItemType.Component,
    required: true,
    component: ProductVariantSelectAndCreate,
    componentProps: (form: WrappedFormUtils): ProductVariantSelectAndCreateProps => ({
        productId: form.getFieldValue('productId'),
        onDataChange: (data) => {
            if (data && data[0]) {
                form.setFieldsValue({ variantBusinessVersion: data[0].businessVersion });
            }
        },
    }),
    // component: EntityRemoteSelect,
    // componentProps: (form: WrappedFormUtils) => ({
    //     operationName: 'listProductVariants',
    //     nameField: 'name',
    //     sortBy: 'lastActivityDate',
    //     getEntityOperationName: 'getProductVariantById',
    //     sortOrder: 'DESC',
    //     productId: form.getFieldValue('productId'),
    //     renderer: (data, index) => {
    //         return (
    //             <Select.Option key={data['id']}>
    //                 {data['data']['standard']}
    //                 {data['name']}
    //             </Select.Option>
    //         );
    //     },
    // }),
    visible: (getFieldValue) => getFieldValue('variants'),
} satisfies IFormField;

export const barCodeField = {
    label: 'Штриховой код',
    id: 'barcode' as const,
    type: FormItemType.String,
    maxLength: 30,
    validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
        const ignoreCurrentId = form.getFieldValue('instanceEdit') ? form.getFieldValue('instanceId') : undefined;
        validateField('instance', 'barcode', value, undefined, ignoreCurrentId, 'Штриховой код уже использован', cb);
    }, 500),
    validationRules: [
        {
            // TODO Нормальное сообщение об ошибке
            pattern: '^[0-9a-zA-Z-_]+$',
            message: 'Поле должно содержать только символы: A-Z, a-z, 0-9, - и _',
        },
    ],
    infoMessage: 'Допустимы символы: A-Z, a-z, 0-9, - и _',
} satisfies IFormField;

export const stateCodeField = {
    label: LocalizationEnum.ASPECT__FIELDS__INSTANCE__INVENTORY_STATE,
    id: 'stateCode' as const,
    type: FormItemType.Select,
    defaultValue: InstanceStateCodeEnum.ACTIVE,
    required: true,
    values: () => {
        const subrentModuleEnabled = subrentModuleEnabledSelector(getStoreState());
        const items: { value: InstanceStateCodeEnum; name: ReactNode }[] = [
            {
                value: InstanceStateCodeEnum.NEW,
                name: getInstanceStateValueByCode(InstanceStateCodeEnum.NEW),
            },
            {
                value: InstanceStateCodeEnum.ACTIVE,
                name: getInstanceStateValueByCode(InstanceStateCodeEnum.ACTIVE),
            },
            {
                value: InstanceStateCodeEnum.UNDERMAINTENANCE,
                name: getInstanceStateValueByCode(InstanceStateCodeEnum.UNDERMAINTENANCE),
            },
        ];
        if (subrentModuleEnabled) {
            items.push({
                value: InstanceStateCodeEnum.SUPPLIERACTIVE,
                name: getInstanceStateValueByCode(InstanceStateCodeEnum.SUPPLIERACTIVE),
            });
        }
        return items;
    },
    disabled: (getFieldValue) => !!getFieldValue('id'),
    validationFunction: (fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
        cb(value === '' ? LocalizationEnum.PAGE__USER_PROFILE__FORM__NOTIFICATION_SCHEME__VALIDATION__FIELD_IS_EMPTY : undefined);
    },
} satisfies IFormField;

// dynamic form fields
export const formFields = [
    {
        fields: [
            createHiddenField('id'),
            createHiddenField('productId'),
            createHiddenField('instanceId'),
            createHiddenField('instanceEdit'),
            createHiddenField('variants'),
            createHiddenField('variantBusinessVersion'),
            codeOrNameField,
            variantField,
            stateCodeField,
            barCodeField,
            {
                label: 'Владелец',
                id: 'supplierId' as const,
                type: FormItemType.Component,
                required: true,
                component: EntityRemoteSelect,
                componentProps: (param) => {
                    return {
                        operationName: 'listRenters',
                        nameField: 'shortName',
                        filters: [
                            'stateCode;IN;NEW;ACTIVE',
                            `activityTypeCode;IN;${CounterpartyActivityTypeCodeEnum.SUPPLIERONLY};${CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER}`,
                            'archive;EQ;false',
                        ],
                    };
                },
                placeholder: 'Выберите владельца',
                visible: (getFieldValue) => {
                    const stateCode: InstanceStateCodeEnum | undefined = getFieldValue('stateCode');
                    return stateCode === InstanceStateCodeEnum.SUPPLIERACTIVE || stateCode === InstanceStateCodeEnum.SUPPLIERDECOMMISSIONED;
                },
                disabled: (getFieldValue) => !!getFieldValue('id'),
            },
            {
                label: 'Продавец',
                id: 'sellerId' as const,
                type: FormItemType.Component,
                required: !true,
                component: EntityRemoteSelect,
                componentProps: (param) => {
                    return {
                        operationName: 'listRenters',
                        nameField: 'shortName',
                        filters: ['archive;EQ;false'],
                    };
                },
                placeholder: 'Выберите продавца',
                visible: (getFieldValue) => {
                    const stateCode: InstanceStateCodeEnum | undefined = getFieldValue('stateCode');
                    return stateCode !== InstanceStateCodeEnum.SUPPLIERACTIVE && stateCode !== InstanceStateCodeEnum.SUPPLIERDECOMMISSIONED;
                },
            },
            {
                label: 'Серийный номер',
                id: 'serialNumber' as const,
                type: FormItemType.String,
                maxLength: 30,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('instanceEdit') ? form.getFieldValue('instanceId') : undefined;

                    validateField('instance', fieldName, value, undefined, ignoreCurrentId, 'Серийный номер уже использован', cb);
                }, 500),
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__INSTANCE__INVENTORY_NUMBER,
                id: 'inventoryNumber' as const,
                type: FormItemType.String,
                maxLength: 30,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('instanceEdit') ? form.getFieldValue('instanceId') : undefined;

                    validateField(
                        'instance',
                        fieldName,
                        value,
                        undefined,
                        ignoreCurrentId,
                        'Официальный инвентарный номер уже использован',
                        cb
                    );
                }, 500),
            },
            {
                label: LocalizationEnum.PAGE__CARD_POPUP__CARRYING_VALUE,
                id: 'carryingValue' as const,
                type: FormItemType.Money,
                step: 50,
                minLength: 0,
                validationFunction: (fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    cb(value >= 10000000000 ? localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__PRICE_TOO_HIGH) : undefined);
                },
                visible: (form) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p);
                },
            },
            {
                label: LocalizationEnum.PAGE__PRODUCTS__PURCHASE_PRICE,
                id: 'purchaseCost' as const,
                type: FormItemType.Money,
                step: 50,
                minLength: 0,
                defaultValue: undefined,
                validationFunction: (fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    cb(value >= 10000000000 ? localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__PRICE_TOO_HIGH) : undefined);
                },
                visible: () => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p);
                },
            },
            {
                label: LocalizationEnum.PAGE__PRODUCTS__CONDITION_DESCRIPTION,
                id: 'stateDescription' as const,
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment' as const,
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
] satisfies FormFieldsGroup[];
