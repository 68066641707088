import { CounterpartyActivityTypeCodeEnum, RenterSourceCodeEnum, RenterStateCodeEnum } from '../../../../../../server';
import { FormFieldsGroup, FormItemType } from '../../../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { FiltersDateRangePicker } from '../../../../../../components/datePicker/FiltersDateRangePicker';
import React from 'react';
import { ProblemsFilter, ProblemsFilterProps } from '../../../../../../components/select/problemsFilter';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { legalCountriesCodes, legalCountriesNamesMap } from '../../../../legalDetails/data/legalDetailsData';
import { SliderRangeWithManualInput } from '../../../../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import { canViewFinancialDataSelector } from '../../../../../../shared/reducers/permissions.reducer';
import { getStoreState } from '../../../../../../../index';
import { ContactSelect } from '../../../../../../components/v2/select/contactSelect/contactSelect';

export const counterpartiesFilters: FormFieldsGroup[] = [
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'status',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                values: [
                    { name: localize(LocalizationEnum.ASPECT__STATE_CODE__RENTER__NEW), value: RenterStateCodeEnum.NEW },
                    { name: localize(LocalizationEnum.ASPECT__STATE_CODE__RENTER__ACTIVE), value: RenterStateCodeEnum.ACTIVE },
                    { name: localize(LocalizationEnum.ASPECT__STATE_CODE__RENTER__BLOCKED), value: RenterStateCodeEnum.BLOCKED },
                ].map((item) => {
                    return {
                        name: (
                            <>
                                <div className={`rr-dot rr-status-bg-` + item.value} style={{ marginRight: '10px' }}></div>{' '}
                                <span className="calendar-multi-select">{item.name}</span>{' '}
                            </>
                        ),
                        value: item.value,
                    };
                }),
            },
            {
                label: 'Вид деятельности',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'activityTypeCode',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                values: [
                    { name: 'Арендатор', value: CounterpartyActivityTypeCodeEnum.RENTERONLY },
                    { name: 'Поставщик', value: CounterpartyActivityTypeCodeEnum.SUPPLIERONLY },
                    { name: 'Арендатор и поставщик', value: CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER },
                ],
            },
            {
                label: 'Источник',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'sourceCode',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                values: [
                    { name: 'Витрина', value: RenterSourceCodeEnum.STOREFRONT },
                    { name: 'Система', value: RenterSourceCodeEnum.INTERNAL },
                ],
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__HIDE_ARCHIVE,
                id: 'hideArchive',
                type: FormItemType.Switch,
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FILTERS__PROBLEM,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'problem',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: ProblemsFilter,
                componentProps: (form: WrappedFormUtils): ProblemsFilterProps => ({
                    typeStatuses: 'all',
                }),
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__LAST_ACTIVITY_DATE,
                placeholder: ['Любая дата', 'Любая дата'],
                id: 'lastActivityDate',
                type: FormItemType.Component,
                className: 'rr-select-gray',
                component: FiltersDateRangePicker,
            },
            {
                label: 'Страна',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__FAMALE_GENDER,
                id: 'country',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                values: legalCountriesCodes.map((code) => ({
                    name: legalCountriesNamesMap[code],
                    value: code,
                })),
            },
            // {
            //     label: LocalizationEnum.ASPECT__FIELDS__COMMON__TYPE,
            //     placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
            //     id: 'typeCode',
            //     type: FormItemType.Select,
            //     style: { minWidth: 120 },
            //     values: [
            //         { name: localize(LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__LEGAL_ENTITY), value: 'LEGAL_ENTITY' },
            //         {
            //             name: localize(LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__INDIVIDUAL_ENTREPRENEUR),
            //             value: 'INDIVIDUAL_ENTERPRENEUR',
            //         },
            //         { name: localize(LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__NATURAL_PERSON), value: 'NATURAL_PERSON' },
            //     ],
            // },
            {
                label: 'Долг арендатора',
                id: 'debtOfRenter',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: {
                    formatStyle: 'currency',
                    min: Number.MIN_SAFE_INTEGER,
                    max: Number.MAX_SAFE_INTEGER,
                    step: 1,
                },
                visible: () => canViewFinancialDataSelector(getStoreState()),
            },
            {
                label: 'Долг перед поставщиком',
                id: 'debtToSupplier',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: {
                    formatStyle: 'currency',
                    min: Number.MIN_SAFE_INTEGER,
                    max: Number.MAX_SAFE_INTEGER,
                    step: 1,
                },
                visible: () => canViewFinancialDataSelector(getStoreState()),
            },
            {
                label: 'Контакт',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'contactId',
                type: FormItemType.Component,
                component: ContactSelect,
                style: { minWidth: 220 },
            },
        ],
    },
];
