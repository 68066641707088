import { Button, Icon } from 'antd';
import { DownloadDocumentPopover } from '../../DownloadDocumentPopover';
import { IconBarcodeSolid } from '../../../../icons';
import { BadgeDocumentOnDownload, availableFileTypes } from './utils/data';
import { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import {
    DocumentTemplateRecord,
    DocumentTypeCodeEnum,
    FileTypeCodeEnum,
    NomenclatureEntityTypeCodeEnum,
    serverApi,
} from '../../../../../server';
import { getCurrentTimezoneUTCOffset } from '../../../../../utils/timeUtils/getCurrentTimezoneUTCOffset';
import { pdfDataToPrint } from '../../../../../shared/util/downloadDataFromExcel';
import { showNotification } from '../../../../notification/showNotification';
import { useAppSelector } from '../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import './ExportBarcodePopover.less';
import { useDocumentLocalStorage } from '../../hooks/useDocumentLocalStorage';
import { useListDocumentTemplatesQuery } from '../../../../../modules/main/settings/documentTemplates2/documentTemplatesApi';

interface Props {
    nomenclatureType: NomenclatureEntityTypeCodeEnum;
    nomenclatureId: number;
}

const ExportBarcodePopover = ({ nomenclatureType, nomenclatureId }: Props) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const defaultTemplate = useAppSelector((state) => state.businessAccountPreferences.preferences?.defaultBadgeTemplate);
    const [shouldOpen, setShouldOpen] = useState(false);
    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [availableTemplates, setAvailableTemplates] = useState<DocumentTemplateRecord[]>([]);
    const [documentsLocalStorage, setDocumentsLocalStorage] = useDocumentLocalStorage('badge', undefined);

    const { data } = useListDocumentTemplatesQuery(
        {
            businessAccountId,
            limit: 100,
            startFrom: 0,
            filters: [`documentType;EQ;${DocumentTypeCodeEnum.BADGE}`],
            showHidden: false,
        },
        { refetchOnMountOrArgChange: true }
    );

    useEffect(() => {
        setAvailableTemplates(data?.records ?? []);
    }, [data]);

    useEffect(() => {
        const defaultTemplateAvailable = !!defaultTemplate && availableTemplates.some((t) => t.id === defaultTemplate.id);

        if (defaultTemplateAvailable && !documentsLocalStorage.template) {
            setDocumentsLocalStorage({
                ...documentsLocalStorage,
                template: { key: defaultTemplate.id, label: defaultTemplate.name, common: defaultTemplate.isCommon },
            });
        }
    }, [availableTemplates, defaultTemplate, documentsLocalStorage, setDocumentsLocalStorage]);

    const onDownload: BadgeDocumentOnDownload = useCallback(
        async ({ templateId, isCommonTemplate }) => {
            try {
                const res: AxiosResponse<Blob> = await serverApi.generateBadgePdf(
                    businessAccountId,
                    nomenclatureType,
                    nomenclatureId,
                    getCurrentTimezoneUTCOffset(),
                    isCommonTemplate,
                    templateId || 0,
                    { responseType: 'blob' }
                );
                pdfDataToPrint(res.data);
            } catch (error) {
                showNotification('error', 'Ошибка');
            }
        },
        [businessAccountId, nomenclatureType, nomenclatureId]
    );

    return (
        <DownloadDocumentPopover
            documentType={DocumentTypeCodeEnum.BADGE}
            onDownload={onDownload}
            availableFileTypes={[...availableFileTypes]}
            storageKey="badge"
            withTemplate
            title={
                <>
                    <Icon style={{ fontSize: 20, marginRight: 10 }} component={IconBarcodeSolid} />
                    <div>Печать этикетки</div>
                </>
            }
            outerVisible={shouldOpen}
            loadTemplatesOnMount
        >
            <Button
                type="link"
                style={{ margin: 0, padding: 0, minHeight: 'auto', fontSize: 24, verticalAlign: 'middle', marginRight: 10 }}
                onClick={(e) => {
                    e.stopPropagation();
                    setShouldOpen(false);
                    if (!timer) {
                        setTimer(
                            setTimeout(() => {
                                setShouldOpen(true);
                                setTimer(undefined);
                            }, 300)
                        );
                    }
                }}
                onDoubleClick={() => {
                    if (timer) {
                        clearInterval(timer);
                    }
                    setTimer(undefined);

                    let template: { id: number; common: boolean } | undefined = undefined;

                    const LSTemplateAvailable =
                        !!documentsLocalStorage.template &&
                        availableTemplates.some((t) => t.id.toString() === documentsLocalStorage.template.key);
                    const defaultTemplateAvailable = !!defaultTemplate && availableTemplates.some((t) => t.id === defaultTemplate.id);

                    if (LSTemplateAvailable) {
                        template = { id: documentsLocalStorage.template.key, common: documentsLocalStorage.template.common };
                    } else if (defaultTemplateAvailable) {
                        template = { id: defaultTemplate.id!, common: !!defaultTemplate.isCommon };
                    } else {
                        setShouldOpen(true);
                    }

                    if (template) {
                        onDownload({
                            templateId: template.id,
                            isCommonTemplate: template.common,
                            fileType: FileTypeCodeEnum.PDFTYPE,
                            options: [],
                        });
                    }
                }}
            >
                <Icon
                    className="rr-barcode-print-icon rr-icon-link"
                    component={IconBarcodeSolid}
                    style={{
                        fontSize: 24,
                    }}
                />
            </Button>
        </DownloadDocumentPopover>
    );
};
export default ExportBarcodePopover;
