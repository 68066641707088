import { ColumnTypes, TableColumn } from '../../../../../../components/grid/Table';
import { LoadingTypeCodeEnum, VehicleRecord, VehicleTypeCodeEnum } from '../../../../../../server';
import { GridColumnCreator } from '../../../../../../components/grid/gridColumnCreator';
import { vehicleBodyTypeMap, vehicleLoadingTypeMap, vehicleTypeMap } from '../utils/enumMappers';
import { toOptionalFixed } from '../../../../../../shared/util/utils5';
import { MetricUtils } from '../../../../../../core/utils/metricUtils';
import './vehiclesColumns.less';

const brandAndModelSizes = GridColumnCreator.getColumnSizes(50);
const loadingTypesSizes = GridColumnCreator.getColumnSizes(25);

export const vehiclesColumns: TableColumn<VehicleRecord>[] = [
    GridColumnCreator.createImageColumn<VehicleRecord>({
        dataIndex: 'image',
    }),
    GridColumnCreator.createShortNameColumn<VehicleRecord>(
        'vehicle',
        (row) => ({
            baId: row.businessAccountId,
            id: row.id,
            name: row.shortName,
        }),
        {
            minWidth: 170,
        }
    ),
    {
        title: 'Гос. номер',
        dataIndex: 'officialNumber',
        type: ColumnTypes.String,
        minWidth: 130,
        dontHide: true,
    },
    {
        title: 'Тип',
        dataIndex: 'type',
        type: ColumnTypes.CustomRender,
        dontHide: true,
        resizable: false,
        minWidth: 120,
        render: (value?: VehicleTypeCodeEnum) => {
            return value ? vehicleTypeMap[value] : undefined;
        },
    },
    {
        title: 'Марка и модель',
        dataIndex: 'brandAndModel',
        minWidth: 150,
        width: brandAndModelSizes.width,
        maxWidth: brandAndModelSizes.max,
        type: ColumnTypes.String,
    },
    {
        title: 'Грузоподъемность, т',
        dataIndex: 'loadCapacity',
        type: ColumnTypes.CustomRender,
        resizable: false,
        minWidth: 200,
        render: (value: number, row: VehicleRecord) => {
            return value ? toOptionalFixed(MetricUtils.fromGramsToTons(value), 2) : undefined;
        },
    },
    {
        title: 'Объем, м³',
        dataIndex: 'volume',
        type: ColumnTypes.CustomRender,
        resizable: false,
        minWidth: 120,
        render: (value: number, row: VehicleRecord) => {
            const processedValue = toOptionalFixed(MetricUtils.fromCubicMillimetersToCubicMeters(value), 2);
            if (!Number(processedValue)) {
                return undefined;
            }
            return processedValue;
        },
    },
    {
        title: 'Тип погрузки',
        dataIndex: 'loadingTypes',
        type: ColumnTypes.CustomRender,
        minWidth: 170,
        width: loadingTypesSizes.width,
        maxWidth: loadingTypesSizes.max,
        className: 'vehicles-list_column-loading-types',
        render: (values: LoadingTypeCodeEnum[], row: VehicleRecord) => {
            return values?.length > 0 ? values.map((value) => vehicleLoadingTypeMap[value]).join(', ') : undefined;
        },
    },
    {
        title: 'Тип кузова',
        dataIndex: 'bodyType',
        type: ColumnTypes.CustomRender,
        resizable: false,
        minWidth: 150,
        render: (value?: VehicleTypeCodeEnum) => {
            return value ? vehicleBodyTypeMap[value] : undefined;
        },
    },
    GridColumnCreator.createReferenceCountColumn<VehicleRecord>({
        title: 'Кол-во перевозок',
        dataIndex: 'transportationCount',
        minWidth: 120,
    }),
    GridColumnCreator.createAttachmentsCountColumn<VehicleRecord>({
        title: 'Число вложений',
        dataIndex: 'attachmentCount',
        minWidth: 120,
    }),
    GridColumnCreator.createUserColumn<VehicleRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.lastUpdateAuthorId,
            name: row.lastUpdateAuthorShortName,
        }),
        {
            title: 'Автор изменения',
            dataIndex: 'lastUpdateAuthorShortName',
            minWidth: 150,
        }
    ),
    GridColumnCreator.createLastUpdateDateColumn<VehicleRecord>({
        title: 'Дата изменения',
        dataIndex: 'lastUpdateDate',
    }),
    GridColumnCreator.createActionsColumn<VehicleRecord>({ id: 'actions' }),
];
