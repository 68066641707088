import React, { FC, ReactNode } from 'react';
import { ProjectInfoRead, ProjectStateCodeEnum, ProjectTypeCodeEnum, TaxBaseTypeCodeEnum } from '../../../../../../../../server';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store/hooks';
import { canViewFinancialDataSelector } from '../../../../../../../../shared/reducers/permissions.reducer';
import { Card, Col, Icon, Row } from 'antd';
import { Avatar } from '../../../../../../../../components/avatar/avatar';
import { IconBriefcase, IconCoinsSolid, IconMoneyCheckAltSolid } from '../../../../../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { FormValue } from '../../../../../../../../components';
import { getProjectSourceByCode } from '../../../../../../../../shared/util/utils4';
import { formatPercents } from '../../../../../../../../shared/util/formatPercents';
import { Link, useParams } from 'react-router-dom';
import { getBusinessAccountId } from '../../../../../../../../../index';
import { printUserName } from '../../../../../../../../shared/util/utils';
import { formatMoney } from '../../../../../../../../shared/util/formatMoney';
import { LinkToPayments } from '../../../../../../../../components/link/toPayments/LinkToPayments';
import { FormValueMoneyProblem } from '../../../../../../../../components/formValue/FormValueMoneyProblem';
import { ProjectStateBadge } from '../../../../components/ProjectStateBadge/ProjectStateBadge';
import { OfferStateBadge } from '../../../../../../offers/components/OfferStateBadge/OfferStateBadge';
import { defaultTaxCalculationTypeTitle } from '../../../../../../settings/businessAccountPreferences/businessAccountPreferencesEditModalFieldsData';
import { projectsPageUrlRoute } from '../../../../../projectsPage';

import { ProjectsPageTabsEnum } from '../../../../../../../../shared/constants/tabEnums';
import { SystemIcon, SystemIconType } from '../../../../../../../../components/v2/systemIcon/systemIcon';
import { LinkButton2 } from '../../../../../../../../components/buttons/linkButton2/linkButton2';
import { push } from 'connected-react-router';
import classNames from 'classnames';

interface ProjectPageDescriptionLeftBlockProps {
    data: ProjectInfoRead;
}

export const ProjectPageDescriptionLeftBlock: FC<ProjectPageDescriptionLeftBlockProps> = ({ data }) => {
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const businessAccountPreferences = useAppSelector((state) => state.businessAccountPreferences.preferences);
    const dispatch = useAppDispatch();
    const { id, businessAccountId } = useParams<{ id: string; businessAccountId: string }>();

    const { taxRate } = data;
    const taxType: string = defaultTaxCalculationTypeTitle[data?.taxBaseType ?? TaxBaseTypeCodeEnum.COSTBEFORETAX];

    const payHandler = () => {
        dispatch(push(`/${businessAccountId}/projects/production/${id}/pay/?tab=description`));
    };

    const isOffer = data.projectType === ProjectTypeCodeEnum.OFFER;
    let status: ReactNode = null;
    let avatarColor: string | undefined;
    let avatarIcon: ReactNode = null;
    if (!isOffer) {
        if (
            data.stateCode === ProjectStateCodeEnum.DRAFT ||
            data.stateCode === ProjectStateCodeEnum.INPROGRESS ||
            data.stateCode === ProjectStateCodeEnum.FINISHED ||
            data.stateCode === ProjectStateCodeEnum.CANCELED
        ) {
            status = <ProjectStateBadge type={data.stateCode} />;
        }
        avatarColor = '#90dcff';
        avatarIcon = <Icon component={IconBriefcase} />;
    } else {
        if (
            data.stateCode === ProjectStateCodeEnum.DRAFT ||
            data.stateCode === ProjectStateCodeEnum.CANCELED ||
            data.stateCode === ProjectStateCodeEnum.APPROVED
        ) {
            status = <OfferStateBadge type={data.stateCode} />;
        }
        avatarColor = '#95f6dc';
        avatarIcon = <Icon component={IconMoneyCheckAltSolid} />;
    }

    return (
        <Col xxl={6} xl={8} lg={10} span={24} style={{ marginBottom: 32 }}>
            <Card bordered={false} className={'rr-card-without-padding'}>
                <div>
                    <div style={{ padding: 32, paddingTop: 30 }}>
                        <div style={{ textAlign: 'center', marginBottom: 28 }}>
                            <Avatar className={'rr-avatar-medium-110'} style={{ backgroundColor: avatarColor }}>
                                {avatarIcon}
                            </Avatar>
                        </div>

                        <div style={{ marginBottom: 9 }}>{status}</div>

                        <Row>
                            <Col className="rr-form-item" style={{ marginBottom: 29 }}>
                                <div className="rr-label">{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE)}</div>
                                <h3>
                                    <FormValue
                                        style={{ wordBreak: 'break-word' }}
                                        value={data.shortName}
                                        emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                    />
                                </h3>
                            </Col>
                            {isOffer && (
                                <Col className={'rr-form-item'}>
                                    <div className="rr-label">Проект</div>
                                    <div className="h5 rr-value">
                                        <Link
                                            to={`/${getBusinessAccountId()}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.PROJECTS}/${
                                                data.mainProjectId
                                            }`}
                                        >
                                            <FormValue value={data.mainProjectShortName} />
                                        </Link>
                                    </div>
                                </Col>
                            )}
                            {isOffer && canViewFinancialData && (
                                <Col className={'rr-form-item'}>
                                    <div className="rr-label">Включена в стоимость проекта</div>
                                    <div className="rr-value">
                                        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                            <Icon
                                                style={{ color: data.partOfThePrice ? '#fc8c2f' : '#AAA', fontSize: 21, marginRight: 10 }}
                                                component={IconCoinsSolid}
                                            />
                                            <FormValue value={data.partOfThePrice ? 'Да' : undefined} emptyText={'Нет'} />
                                        </div>
                                    </div>
                                </Col>
                            )}
                            {!isOffer && (
                                <Col className={'rr-form-item'}>
                                    <div className="rr-label">Источник</div>
                                    <div className="rr-value">
                                        <FormValue value={getProjectSourceByCode(data.sourceCode)} emptyText={'Создан в системе'} />
                                    </div>
                                </Col>
                            )}
                            {!isOffer && canViewFinancialData && (
                                <Col className="rr-form-item">
                                    <div className="rr-label">Скидка по умолчанию</div>
                                    {data.hasOwnDefaultDiscount ? (
                                        <div className="rr-value">
                                            <FormValue
                                                originalValue={data.defaultDiscount}
                                                value={
                                                    data.defaultDiscount !== undefined && data.defaultDiscount !== null
                                                        ? data.defaultDiscount + '%'
                                                        : null
                                                }
                                                emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="rr-value">
                                                <FormValue
                                                    originalValue={data.renterDefaultDiscount}
                                                    value={
                                                        data.renterDefaultDiscount !== undefined && data.renterDefaultDiscount !== null
                                                            ? data.renterDefaultDiscount + '%'
                                                            : null
                                                    }
                                                    emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                                />
                                            </div>
                                            <span style={{ fontSize: '11px', color: 'rgb(170, 170, 170)' }}>
                                                ({localize(LocalizationEnum.PAGE__PROJECTS__FIELD_VALUE_BY_RENTER)})
                                            </span>{' '}
                                        </>
                                    )}
                                </Col>
                            )}
                            {!isOffer && canViewFinancialData && (
                                <Col className={'rr-form-item'}>
                                    <div className="rr-label">{localize(LocalizationEnum.PAGE__PROJECTS__CARD__TAXES_SUM)}</div>
                                    <div className="rr-value">
                                        <FormValue
                                            value={taxRate === 0 ? undefined : formatPercents(taxRate)}
                                            emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_CHARGED)}
                                            originalValue={140}
                                        />
                                    </div>
                                    {businessAccountPreferences && taxRate !== 0 ? (
                                        <span className={'rr-value-signature'}>{taxType}</span>
                                    ) : null}
                                </Col>
                            )}

                            {!isOffer && (
                                <Col className="rr-form-item">
                                    <div className="rr-label">{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__ASSIGNEE)}</div>
                                    {data.hasOwnAssignee ? (
                                        data.assigneeFullName ? (
                                            <div className="h5 rr-value">
                                                <Link to={`/${getBusinessAccountId()}/users/${data.assigneeId}`}>
                                                    <FormValue
                                                        value={printUserName(data.assigneeFullName)}
                                                        emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED)}
                                                    />
                                                </Link>
                                            </div>
                                        ) : (
                                            <div className="rr-value">
                                                <FormValue
                                                    value={printUserName(data.assigneeFullName)}
                                                    emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED)}
                                                />
                                            </div>
                                        )
                                    ) : (
                                        <>
                                            {data.renterAssigneeFullName ? (
                                                <div className="h5 rr-value">
                                                    <Link to={`/${getBusinessAccountId()}/users/${data.renterAssigneeId}`}>
                                                        <FormValue
                                                            value={printUserName(data.renterAssigneeFullName)}
                                                            emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED)}
                                                        />
                                                    </Link>
                                                </div>
                                            ) : (
                                                <div className="rr-value">
                                                    <FormValue
                                                        value={printUserName(data.renterAssigneeFullName)}
                                                        emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED)}
                                                    />
                                                </div>
                                            )}
                                            <span style={{ fontSize: '11px', color: 'rgb(170, 170, 170)' }}>
                                                ({localize(LocalizationEnum.PAGE__PROJECTS__FIELD_VALUE_BY_RENTER)})
                                            </span>
                                        </>
                                    )}
                                </Col>
                            )}

                            {isOffer && (
                                <Col className="rr-form-item">
                                    <div className="rr-label">{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__ASSIGNEE)}</div>
                                    <div className="h5 rr-value">
                                        {data.assigneeFullName && data.assigneeId ? (
                                            <Link to={`/${getBusinessAccountId()}/users/${data.assigneeId}`}>
                                                <FormValue value={printUserName(data.assigneeFullName)} />
                                            </Link>
                                        ) : (
                                            <FormValue
                                                value={undefined}
                                                emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED)}
                                            />
                                        )}
                                    </div>
                                    {!data.hasOwnAssignee && (
                                        <span style={{ fontSize: '11px', color: 'rgb(170, 170, 170)' }}>(По проекту)</span>
                                    )}
                                </Col>
                            )}
                        </Row>
                    </div>

                    {!isOffer && canViewFinancialData && (
                        <Row style={{ borderTop: '1px solid #eaeaea' }}>
                            <div style={{ padding: 32, paddingTop: 30 }}>
                                <Col className={'rr-form-item'}>
                                    <div className="rr-label">Итого</div>
                                    <div className={classNames('rr-value', data.sumToBePaidAfterTaxes === 0 && 'rr-0-value')}>
                                        {formatMoney(data.sumToBePaidAfterTaxes)}
                                    </div>
                                </Col>
                                <Col className={'rr-form-item'}>
                                    <div className="rr-label">Принято оплаты</div>
                                    <div className={classNames('rr-value', data.acceptedPaymentsSum === 0 && 'rr-0-value')}>
                                        {formatMoney(data.acceptedPaymentsSum)}
                                        <LinkToPayments paymentsCount={data.acceptedPaymentsCount} params={{ projectId: data.id }} />
                                    </div>
                                </Col>
                                <Col className="rr-form-item">
                                    <div className="rr-label">Долг арендатора</div>
                                    {data.debtSum === 0 ? (
                                        <div
                                            style={{
                                                color: 'rgb(52, 191, 163)',
                                                fontWeight: 400,
                                                fontSize: '18px',
                                            }}
                                        >
                                            {localize(LocalizationEnum.ASPECT__GLOBAL__NO)}
                                        </div>
                                    ) : (
                                        <div
                                            className={classNames(
                                                `rr-value`,
                                                data.stateCode === ProjectStateCodeEnum.FINISHED && 'rr-danger',
                                                (data.stateCode === ProjectStateCodeEnum.DRAFT ||
                                                    data.stateCode === ProjectStateCodeEnum.CANCELED) &&
                                                    'rr-0-value'
                                            )}
                                        >
                                            <FormValueMoneyProblem withOverpay={true} value={data.debtSum} />
                                        </div>
                                    )}
                                    {!!data.debtSum && data.stateCode === ProjectStateCodeEnum.DRAFT && (
                                        <div className="rr-value-hint">(Не учитывается, черновик)</div>
                                    )}
                                    {!!data.debtSum && data.stateCode === ProjectStateCodeEnum.CANCELED && (
                                        <div className="rr-value-hint">(Не учитывается, отменен)</div>
                                    )}
                                    {data.stateCode !== ProjectStateCodeEnum.DRAFT && data.stateCode !== ProjectStateCodeEnum.CANCELED && (
                                        <LinkButton2
                                            style={{ marginTop: 12 }}
                                            icon={<SystemIcon style={{ fontSize: 21 }} type={SystemIconType.financial} />}
                                            label={localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__ACCEPT_PAYMENT)}
                                            onClick={payHandler}
                                        />
                                    )}
                                </Col>
                            </div>
                        </Row>
                    )}
                </div>
            </Card>
        </Col>
    );
};
