import React, { MouseEvent } from 'react';
import { Affix, Icon, Tree } from 'antd';
import { IRootState } from '../../../shared/reducers';
import { renderTreeNodes } from '../../../shared/util/renderTreeNodes';
import { connect } from 'react-redux';
import { AntTreeNodeSelectedEvent } from 'antd/lib/tree';
import { IconAngleLeft, IconMinusSquare } from '../../icons';
import { EmptyCategories, EmptyCategoriesProps } from '../../emptyCategories/EmptyCategories';
import { LocalizationEnum, localize } from '../../../localization';
import { findParentCategoriesById } from '../../../shared/util/utils';
import { SelectScrollController } from '../../controllers/scroll/SelectScrollController/SelectScrollController';
import { getGridCategoriesStorageData } from '../../../components/grid/utils';
import { ProductCategory, RenterCategory } from '../../../shared/reducers/entities.reducer';

import { GridName } from '../../grid/utils/types';

interface IProps extends StateProps, Pick<EmptyCategoriesProps, 'categoriesType'> {
    selectedKeys?: string[];
    onSelect?: (selectedKeys: string[] | undefined, e: AntTreeNodeSelectedEvent | MouseEvent | undefined) => void;
    onCollapse?: () => void;
    onExpand?: (expandedKeys: string[]) => void;
    defaultExpandedKeys?: string[];
    offsetBottom: number;
    gridName: GridName;
    width?: number;
}

interface IState {
    maxH?: number;
    affixed?: boolean;
    expandedKeys: string[];
}

class Component extends React.Component<IProps, IState> {
    public static defaultProps = {
        offsetBottom: 0,
    };

    isCategoriesExpandedOnLoad = false;

    constructor(props: IProps) {
        super(props);

        const { gridName } = this.props;

        this.state = {
            maxH: window.innerHeight,
            affixed: false,
            expandedKeys: gridName ? getGridCategoriesStorageData(gridName)?.expandedIds ?? [] : [],
        };
    }

    componentDidMount(): void {
        window.addEventListener('resize', this.onResize);
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.onResize);
        window.removeEventListener('scroll', this.onScroll);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        const { categories, defaultExpandedKeys, selectedKeys } = this.props;

        if (selectedKeys && categories && !this.isCategoriesExpandedOnLoad) {
            const keysFromSelectedKeysPaths: number[] = selectedKeys.flatMap((id) => {
                const productCategories = findParentCategoriesById(categories, +id);
                return productCategories?.map((item) => item.key) ?? [];
            });

            const updatedDefaultExpandedKeys: string[] = [...(defaultExpandedKeys ?? []), ...keysFromSelectedKeysPaths.map(String)];

            this.isCategoriesExpandedOnLoad = true;
            this.setState({
                expandedKeys: Array.from(new Set(updatedDefaultExpandedKeys.concat(this.state.expandedKeys))),
            });
        }
    }

    onResize = () => {
        this.resize();
    };

    onScroll = () => {
        this.resize();
    };

    resize = () => {
        let maxH = window.innerHeight;

        this.setState({
            maxH: maxH,
        });
    };

    render() {
        const { categories, categoriesType = 'RENTER', selectedKeys, width } = this.props;

        const isWithoutCategories = Boolean(selectedKeys?.find((key) => key === '-1'));

        return categories ? (
            <>
                <div className={'rr-grid-categories-header'}>
                    <Icon
                        component={IconAngleLeft}
                        className={'rr-grid-categories-collapse-icon'}
                        style={{ opacity: !selectedKeys || selectedKeys.length === 0 ? undefined : 0.3 }}
                        onClick={
                            !selectedKeys || selectedKeys.length === 0
                                ? () => {
                                      if (this.props.onCollapse) this.props.onCollapse();
                                  }
                                : undefined
                        }
                    />
                    <span>
                        {width && width < 180 ? 'Кат.' : localize(LocalizationEnum.ASPECT__MAIN_MENU__CATEGORIES)}
                        {selectedKeys && selectedKeys.length > 0 ? (
                            <>
                                : {selectedKeys.length}{' '}
                                <a
                                    onClick={() => {
                                        this.props.onSelect?.(undefined, undefined);
                                    }}
                                >
                                    <Icon theme={'filled'} type={'close-circle1'} />
                                </a>
                            </>
                        ) : this.state.expandedKeys && this.state.expandedKeys.length > 0 ? (
                            <div
                                className={'collapse-icon-container'}
                                onClick={() => {
                                    this.props.onExpand?.([]);
                                    this.setState({ expandedKeys: [] });
                                }}
                            >
                                <Icon className={'collapse-icon'} component={IconMinusSquare} />
                            </div>
                        ) : undefined}
                    </span>
                </div>
                <Affix
                    style={{ zIndex: 2 }}
                    onChange={(affixed) => {
                        this.setState({
                            affixed: affixed,
                        });
                    }}
                >
                    <div style={{ marginLeft: -62, paddingLeft: 62, backgroundColor: '#f0f2f5' }}>
                        <div style={{ marginLeft: -32, paddingLeft: 32, backgroundColor: '#ffffff' }}>
                            <div
                                style={{
                                    padding: 20,
                                    backgroundColor: '#f8f9fa',
                                    flex: 1,
                                    overflowY: 'auto',
                                    maxHeight: this.state.maxH,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 16,
                                }}
                            >
                                {categories.length > 0 ? (
                                    <>
                                        <SelectScrollController gridName={this.props.gridName}>
                                            {({ onPageChange }) => (
                                                <Tree
                                                    onExpand={(expandedKeys: string[]) => {
                                                        if (this.props.onExpand) this.props.onExpand(expandedKeys);
                                                        this.setState({ expandedKeys: expandedKeys });
                                                    }}
                                                    expandedKeys={this.state.expandedKeys}
                                                    selectedKeys={selectedKeys || []}
                                                    onSelect={(selectedKeys, e) => {
                                                        if (this.props.onSelect) {
                                                            onPageChange();
                                                            this.props.onSelect(selectedKeys, e);
                                                        }
                                                    }}
                                                    showLine
                                                    multiple
                                                >
                                                    {renderTreeNodes(categories)}
                                                </Tree>
                                            )}
                                        </SelectScrollController>

                                        <span
                                            onClick={(event: MouseEvent) => {
                                                if (this.props.onSelect) {
                                                    let newKeys = [...(this.props.selectedKeys ?? []), '-1'];

                                                    if (isWithoutCategories) {
                                                        newKeys = newKeys.filter((key) => key !== '-1');
                                                    }

                                                    this.props.onSelect?.(newKeys, event);
                                                }
                                            }}
                                            style={{
                                                ...(isWithoutCategories
                                                    ? {
                                                          color: '#383F77',
                                                          fontWeight: 'bold',
                                                      }
                                                    : {}),
                                                cursor: 'pointer',
                                                userSelect: 'none',
                                                transitionDuration: '0.3s',
                                                transitionTimingFunction: 'ease',
                                            }}
                                        >
                                            Без категории
                                        </span>
                                    </>
                                ) : (
                                    <EmptyCategories categoriesType={categoriesType} />
                                )}
                            </div>
                        </div>
                    </div>
                </Affix>
                <div style={{ maxHeight: 67, flex: '1 1 auto' }}></div>
            </>
        ) : (
            <EmptyCategories categoriesType={categoriesType} />
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: Pick<EmptyCategoriesProps, 'categoriesType'>) => {
    const { categoriesType } = ownProps;
    const categoriesEntity = categoriesType === 'RENTER' ? 'renters' : 'products';

    return {
        categories: storeState.entities.categories[categoriesEntity] as ProductCategory[] | RenterCategory[] | null | undefined,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

export const GridCategoriesTree = connect(mapStateToProps)(Component);
