import {Action} from "redux";
import {ThunkAction} from "redux-thunk";
import {IRootState} from "./index";
import {
    showBarcodeScannerErrorNotification,
    showBarcodeScannerSuccessNotification
} from "../util/scanNotificationsUtils";
import {NomenclatureEntityTypeCodeEnum} from "../../server";
import {barcodeScannerSoundEffectsEnabledSelector} from "./userSettings/userSettings.selectors";
import {SoundType} from "../../../soundEffectsPlayer/soundEffectsPlayer";
import {soundEffectsPlayer} from "../../../index";

enum ACTION_TYPES {
    SCAN_ERROR_ADD = 'scanner/SCAN_ERROR_ADD',
    SCAN_ERROR_RESET = 'scanner/SCAN_ERROR_RESET',
    SET_INSTANCES_SCANNED_COUNT = 'scanner/SET_INSTANCES_SCANNED_COUNT',
    SET_NOMENCLATURES_SCANNED_COUNT = 'scanner/SET_NOMENCLATURES_SCANNED_COUNT',
}

const initialState: ScannerState = {
    errors: [],
    addedNomenclaturesCount: 0,
    addedInstancesCount: 0,
    addedNomenclaturesKeyIndex: 0,
    addedInstancesCountKeyIndex: 0
};

export interface ScannerState {
    errors:ScanError[];
    addedNomenclaturesCount: number;
    addedInstancesCount: number;
    addedNomenclaturesKeyIndex: number;
    addedInstancesCountKeyIndex: number;
}

export enum ScanErrorType {
    /** Не удалось загрузить данные */
    LoadingError = 'LoadingError',
    /** Штриховой код не найден в системе */
    CodeNotFound = 'CodeNotFound',
    /** Сканирование на вкладке "Добавлено" поддерживает только замену анонимных экземпляров именованными */
    ScanningOnAddedTabError = 'ScanningOnAddedTabError',
    /** Тип операции не поддерживает добавление новой номенклатуры в обязательства */
    ScanningOnAddedTabErrorWhenCantAddingNewNomenclatures = 'ScanningOnAddedTabErrorWhenCantAddingNewNomenclatures',

    /** Вся найденная в текущей выборке номенклатура XXX:YYY уже добавлена */
    AllFoundNomenclaturesInCurrentSelectionAlreadyAdded = 'AllFoundNomenclaturesInCurrentSelectionAlreadyAdded',
    /** Номенклатура XXX:YYY не найдена в текущей выборке */
    NomenclatureNotFoundInCurrentSelection = 'NomenclatureNotFoundInCurrentSelection',
    /** Номенклатура XXX:YYY в неактивном статусе */
    NomenclatureInInactiveStatus = 'NomenclatureInInactiveStatus',
    /** Номенклатура XXX:YYY недоступна */
    NomenclatureNotAvailable = 'NomenclatureNotAvailable',
    /** Номенклатура XXX с анонимным экз. не найдена */
    NomenclatureWithAnonymousInstanceNotFound = 'NomenclatureWithAnonymousInstanceNotFound',
    /** Номенклатура XXX не найдена */
    NomenclatureNotFound = 'NomenclatureNotFound',
    /** Экземпляр ZZZ уже добавлен */
    InstanceAlreadyAdded = 'InstanceAlreadyAdded',
    /** Экземляр ZZZ и его номенклатура XXX:YYY не найдены в проекте */
    InstanceAndNomenclatureNotFoundInProject = 'InstanceAndNomenclatureNotFoundInProject',

    /** Добавление продукта XXX невозможно, отсканируйте нужный вариант */
    ProductAddingNotPossible = 'ProductAddingNotPossible'
}

export enum ScanSuccessMessageType {
    /** Экземпляр успешно добавлен */
    InstanceAdded = 'InstanceAdded',
    /** Номенклатура успешно добавлена */
    NomenclatureAdded = 'NomenclatureAdded'
}

export type ScanError = ScanErrorSimple | ScanErrorCodeNotFound | ScanErrorProductError | ScanErrorNomenclatureError | ScanErrorNomenclatureError | ScanErrorInstanceError | ScanErrorInstanceWithNomenclatureError;

export interface ScanErrorSimple {
    type: ScanErrorType.LoadingError | ScanErrorType.ScanningOnAddedTabError | ScanErrorType.ScanningOnAddedTabErrorWhenCantAddingNewNomenclatures;
}

export interface ScanErrorCodeNotFound {
    type: ScanErrorType.CodeNotFound;
    code: string;
}

export interface ScanErrorProductError {
    type: ScanErrorType.ProductAddingNotPossible | ScanErrorType.NomenclatureWithAnonymousInstanceNotFound | ScanErrorType.NomenclatureNotFound;
    productName: string;
}

export interface ScanErrorNomenclatureError {
    type: ScanErrorType.AllFoundNomenclaturesInCurrentSelectionAlreadyAdded | ScanErrorType.NomenclatureNotFoundInCurrentSelection | ScanErrorType.NomenclatureNotFoundInCurrentSelection | ScanErrorType.NomenclatureInInactiveStatus | ScanErrorType.NomenclatureNotAvailable;
    productName: string;
    variantName?: string;
}

export interface ScanErrorInstanceError {
    type: ScanErrorType.InstanceAlreadyAdded;
    instanceName: string;
}

export interface ScanErrorInstanceWithNomenclatureError {
    type: ScanErrorType.InstanceAndNomenclatureNotFoundInProject;
    instanceName: string;
    productName: string;
    variantName?: string;
}

export default (state: ScannerState = initialState, action: ScannerAction): ScannerState => {
    switch (action.type) {

        case ACTION_TYPES.SCAN_ERROR_ADD: {
            return {
                ...state,
                errors: [...state.errors, action.payload.error]
            };
        }
        case ACTION_TYPES.SCAN_ERROR_RESET: {
            return {
                ...state,
                errors: []
            };
        }
        case ACTION_TYPES.SET_INSTANCES_SCANNED_COUNT: {
            return {
                ...state,
                addedInstancesCount: action.payload,
                addedInstancesCountKeyIndex: state.addedInstancesCountKeyIndex + (action.payload === 0 ? 1 : 0)
            };
        }
        case ACTION_TYPES.SET_NOMENCLATURES_SCANNED_COUNT: {
            return {
                ...state,
                addedNomenclaturesCount: action.payload,
                addedNomenclaturesKeyIndex: state.addedNomenclaturesKeyIndex + (action.payload === 0 ? 1 : 0)
            };
        }

        default:
            return state;
    }
};

type ScannerAction = ShowScanErrorAction | ResetScanErrorAction | SetCountAction;

interface ShowScanErrorAction extends Action {
    type: ACTION_TYPES.SCAN_ERROR_ADD;
    payload: {
        error: ScanError;
    };
}

interface ResetScanErrorAction extends Action {
    type: ACTION_TYPES.SCAN_ERROR_RESET;
}

interface SetCountAction extends Action {
    type: ACTION_TYPES.SET_INSTANCES_SCANNED_COUNT|ACTION_TYPES.SET_NOMENCLATURES_SCANNED_COUNT;
    payload: number;
}

/** Показать сообщение об ошибке сканирования */
export const showScanErrorNotification = (error: ScanError): ThunkAction<any, IRootState, any, ShowScanErrorAction|ResetScanErrorAction> => {
    return async (dispatch, getState: () => IRootState) => {
        dispatch({type: ACTION_TYPES.SCAN_ERROR_ADD, payload: {error}});
        if(barcodeScannerSoundEffectsEnabledSelector(getState())){
            if(error.type === ScanErrorType.InstanceAlreadyAdded || error.type === ScanErrorType.AllFoundNomenclaturesInCurrentSelectionAlreadyAdded){
                // Экземпляр уже добавлен - тут два коротких на одной ноте ре-ре
                soundEffectsPlayer.play(SoundType.scanInstanceAlreadyAddedError);
            }else{
                // Штрихкод не найден ИЛИ подходящая номенклатура не найдена - негативный звук базовый какой-то вниз, типа ре-до
                soundEffectsPlayer.play(SoundType.scanError);
            }
        }
        const errors = getState().scanner.errors;
        if(errors.length > 0){
            showBarcodeScannerErrorNotification(errors, ()=>{
                dispatch(resetScanError());
            });
        }
    };
};

export const resetScanError = ():ResetScanErrorAction => (
    {type: ACTION_TYPES.SCAN_ERROR_RESET}
);

/** Показать сообщение об успешном сканировании */
export const showScanInstanceSuccessNotification = (): ThunkAction<any, IRootState, any, SetCountAction> => {
    return async (dispatch, getState: () => IRootState) => {
        dispatch({type: ACTION_TYPES.SET_INSTANCES_SCANNED_COUNT, payload: ++getState().scanner.addedInstancesCount});
        if(barcodeScannerSoundEffectsEnabledSelector(getState())){
            soundEffectsPlayer.play(SoundType.scanInstanceAddedSuccess);
        }
        showBarcodeScannerSuccessNotification(ScanSuccessMessageType.InstanceAdded, getState().scanner.addedInstancesCount, 'barcodeInstanceAddedSuccessNotification' + getState().scanner.addedInstancesCountKeyIndex, ()=>{
            dispatch({type: ACTION_TYPES.SET_INSTANCES_SCANNED_COUNT, payload: 0});
        });
    };
};

/** Показать сообщение об успешном сканировании */
export const showScanNomenclatureSuccessNotification = (nomenclatureType: NomenclatureEntityTypeCodeEnum.KIT | NomenclatureEntityTypeCodeEnum.VARIANT | NomenclatureEntityTypeCodeEnum.PRODUCT, playSound: boolean): ThunkAction<any, IRootState, any, SetCountAction> => {
    return async (dispatch, getState: () => IRootState) => {
        dispatch({type: ACTION_TYPES.SET_NOMENCLATURES_SCANNED_COUNT, payload: ++getState().scanner.addedNomenclaturesCount});
        if(barcodeScannerSoundEffectsEnabledSelector(getState())){
            soundEffectsPlayer.play(nomenclatureType === NomenclatureEntityTypeCodeEnum.KIT ? SoundType.scanKitAddedSuccess : SoundType.scanInstanceAddedSuccess);
        }
        showBarcodeScannerSuccessNotification(ScanSuccessMessageType.NomenclatureAdded, getState().scanner.addedNomenclaturesCount, 'barcodeNomenclatureAddedSuccessNotification' + getState().scanner.addedNomenclaturesKeyIndex, ()=>{
            dispatch({type: ACTION_TYPES.SET_NOMENCLATURES_SCANNED_COUNT, payload: 0});
        });
    };
};
