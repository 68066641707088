import { LocalizationEnum } from '../../../../../../../localization';
import { FormFieldsGroup, FormItemType } from '../../../../../../../components/dynamicForm/DynamicForm';
import {EntityRemoteSelect, EntityRemoteSelectProps} from '../../../../../../../components/select/EntityRemoteSelect';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { FormUtils, TypedFormField } from '../../../../../../../core/utils/formUtils';
import { AccessibilityMapFilters } from './accessibilityMapCalendarFilters.types';
import {
    InstanceRecord,
    InstanceTrackingTypeCodeEnum, KitRecord,
    LocationRecord,
    ProductRecord,
    serverApi, VariantRecord
} from '../../../../../../../server';
import { createHiddenField } from '../../../../../../../components/modalForm/utils';
import {
    createProjectFilters
} from "../../../../../inventory/products/modal/components/ProductCreateModal/ProductCreateModalData";
import {createLocationModalFilters} from "../../../../../logistics/tabs/locations/modal/create/LocationCreateModalData";
import {ServerUtils} from "../../../../../../../core/utils/serverUtils";

export const accessibilityMapCalendarFilters: FormFieldsGroup[] = FormUtils.typedFormFields<AccessibilityMapFilters>([
    {
        fields: [
            createHiddenField('renterId') as TypedFormField<AccessibilityMapFilters>,
            createHiddenField('projectId') as TypedFormField<AccessibilityMapFilters>,
            createHiddenField('subrentId') as TypedFormField<AccessibilityMapFilters>,
            createHiddenField('hide') as TypedFormField<AccessibilityMapFilters>,
            createHiddenField('productInstanceTrackingTypeCode') as TypedFormField<AccessibilityMapFilters>,
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__KIT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A11,
                id: 'kitId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                className: 'rr-select-gray',
                componentProps: (form: WrappedFormUtils):EntityRemoteSelectProps => ({
                    operationName: 'listKits',
                    nameField: 'shortName',
                    getEntityOperationName: 'getKitById',
                    sortBy: 'lastActivityDate',
                    sortOrder: 'DESC',
                    showSearch: true,
                    filters: ServerUtils.createRequestFilters<KitRecord>([
                        ['archive', 'EQ', false]
                    ])
                }),
                getInitialValue: (value) => {
                    return value;
                },
                onChange: async (value: any, form) => {
                    form.setFieldsValue({
                        productId: undefined,
                        variantId: undefined,
                        instanceId: undefined,
                        productInstanceTrackingTypeCode: undefined,
                    });
                },
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__PRODUCT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A11,
                id: 'productId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                className: 'rr-select-gray',
                componentProps: (form: WrappedFormUtils):EntityRemoteSelectProps => ({
                    operationName: 'listProducts',
                    nameField: 'shortName',
                    getEntityOperationName: 'getProductById',
                    sortBy: 'lastActivityDate',
                    sortOrder: 'DESC',
                    showSearch: true,
                    filters: ServerUtils.createRequestFilters<ProductRecord>([
                        ['archive', 'EQ', false]
                    ])
                }),
                getInitialValue: (value) => {
                    return value;
                },
                onChange: async (value: any, form) => {
                    form.setFieldsValue({
                        variantId: undefined,
                        instanceId: undefined,
                        kitId: undefined,
                        productInstanceTrackingTypeCode: value?.data?.instanceTrackingTypeCode,
                    });
                },
            },
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__VARIANT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A12,
                id: 'variantId',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: EntityRemoteSelect,
                className: 'rr-select-gray',
                componentProps: (form: WrappedFormUtils):EntityRemoteSelectProps => {
                    return {
                        operationName: 'listProductVariants',
                        nameField: 'name',
                        sortBy: 'lastActivityDate',
                        getEntityOperationName: 'getProductVariantById',
                        sortOrder: 'DESC',
                        productId: form.getFieldValue('productId')
                            ? form.getFieldValue('productId').key || form.getFieldValue('productId')
                            : 1,
                        showSearch: true,
                        filters: ServerUtils.createRequestFilters<VariantRecord>([
                            ['archive', 'EQ', false]
                        ])
                    };
                },
                getInitialValue: (value) => {
                    return value;
                },
                visible: (getFieldValue, form?: WrappedFormUtils, currentValues?) => {
                    const instanceTrackingTypeCode = getFieldValue('productInstanceTrackingTypeCode');

                    return (
                        currentValues?.variantId ||
                        getFieldValue('variantId') ||
                        instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK ||
                        instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
                    );
                },
                onChange: (value: any, form) => {
                    form.setFieldsValue({ instanceId: undefined });
                },
            },
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__INSTANCE,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A13,
                id: 'instanceId',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: EntityRemoteSelect,
                className: 'rr-select-gray',
                componentProps: (form: WrappedFormUtils):EntityRemoteSelectProps => {
                    const variantId = form.getFieldValue('variantId')
                        ? form.getFieldValue('variantId').key || form.getFieldValue('variantId')
                        : undefined;
                    return {
                        operationName: 'listProductInstances',
                        nameField: 'nameOrCode',
                        sortBy: 'lastActivityDate',
                        getEntityOperationName: 'getProductInstanceById',
                        sortOrder: 'DESC',
                        productId: form.getFieldValue('productId')
                            ? form.getFieldValue('productId').key || form.getFieldValue('productId')
                            : 1,
                        showSearch: true,
                        filters: ServerUtils.createRequestFilters<InstanceRecord>([
                            variantId ? ['variantId', 'EQ', variantId] : undefined
                        ])
                    };
                },
                getInitialValue: (value) => {
                    return value;
                },
                visible: (getFieldValue, form?: WrappedFormUtils, currentValues?) => {
                    let instanceTrackingTypeCode = getFieldValue('productInstanceTrackingTypeCode');

                    return (
                        currentValues?.instanceId ||
                        getFieldValue('instanceId') ||
                        instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                        instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
                    );
                },
                onChange: (value: any, form) => {
                    if (!form.getFieldValue('variantId') && value && value.data && value.data.variantId) {
                        form.setFieldsValue({ variantId: value.data.variantId });
                    }
                },
            },
        ],
    },
]);


//serverApi.listProductVariants