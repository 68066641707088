import { FormFieldsGroup, FormItemType } from '../../../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { FormUtils, TypedFormField } from '../../../../../../core/utils/formUtils';
import { ContactInfoCreate } from '../../../../../../server';
import React from 'react';
import debounce from 'lodash/debounce';
import { validateField } from '../../../../../../shared/util/validateField';
import { createHiddenField } from '../../../../../../components/modalForm/utils';
import { SocialNetworksEditor } from './components/socialNetworksEditor/socialNetworksEditor';

export const formFields: FormFieldsGroup[] = FormUtils.typedFormFields<ContactInfoCreate>([
    {
        fields: [
            createHiddenField('id') as TypedFormField<ContactInfoCreate>,
            createHiddenField('isEdit') as TypedFormField<ContactInfoCreate>,
            createHiddenField('isUser') as TypedFormField<ContactInfoCreate>,
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__GLOBAL__LASTNAME,
                id: 'lastName',
                type: FormItemType.String,
                maxLength: 60,
                required: true,
                disabled: (getFieldValue) => {
                    return getFieldValue('isUser');
                },
            },
            {
                label: LocalizationEnum.ASPECT__GLOBAL__FIRSTNAME,
                id: 'firstName',
                type: FormItemType.String,
                maxLength: 60,
                required: true,
                disabled: (getFieldValue) => {
                    return getFieldValue('isUser');
                },
            },
            {
                label: LocalizationEnum.ASPECT__GLOBAL__SECONDNAME,
                id: 'secondName',
                type: FormItemType.String,
                maxLength: 60,
                disabled: (getFieldValue) => {
                    return getFieldValue('isUser');
                },
            },
            {
                label: 'Основная должность',
                id: 'primaryPosition',
                type: FormItemType.String,
                maxLength: 60,
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__IMAGE,
                id: 'mainImage',
                type: FormItemType.UploadImage,
                imagesMaxCount: 1,
                infoMessage: localize(LocalizationEnum.ASPECT__FORMS__IMAGE_FORMAT_RESCTRICTIONS_STRING),
            },
            {
                label: LocalizationEnum.ASPECT__GLOBAL__PHONE_NUMBER,
                id: 'phoneNumber',
                type: FormItemType.String,
                maxLength: 60,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form) => {
                    const ignoreCurrentId = form.getFieldValue('isEdit') ? form.getFieldValue('id') : undefined;
                    validateField('contact', fieldName, value, undefined, ignoreCurrentId, 'Этот телефон уже используется', cb);
                }, 500),
                disabled: (getFieldValue) => {
                    return getFieldValue('isUser');
                },
            },
            {
                label: 'E-Mail',
                id: 'email',
                type: FormItemType.String,
                maxLength: 60,
                validationRules: [
                    {
                        pattern: '^.+@.+\\..{2,}$',
                        message: localize(LocalizationEnum.PAGE__RENTERS__FORM__VALIDATION_MESSAGE_EMAIL),
                    },
                ],
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form) => {
                    const ignoreCurrentId = form.getFieldValue('isEdit') ? form.getFieldValue('id') : undefined;
                    validateField('contact', fieldName, value, undefined, ignoreCurrentId, 'Этот E-Mail уже используется', cb);
                }, 500),
                disabled: (getFieldValue) => {
                    return getFieldValue('isUser');
                },
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__PASSPORT_NUMBER,
                id: 'passport',
                type: FormItemType.String,
                infoMessage: (
                    <>
                        {localize(LocalizationEnum.ASPECT__GLOBAL__EXAMPLE)}
                        {': 1234567890'}
                    </>
                ),
                validationRules: [
                    {
                        // TODO Нормальное сообщение об ошибке
                        pattern: '^\\d{10}$',
                        message: localize(LocalizationEnum.PAGE__RENTERS__FORM__VALIATION_MESSAGE_PASPORT),
                    },
                ],
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form) => {
                    const ignoreCurrentId = form.getFieldValue('isEdit') ? form.getFieldValue('id') : undefined;
                    validateField('contact', fieldName, value, undefined, ignoreCurrentId, 'Серия и номер паспорта уже использованы', cb);
                }, 500),
            },
        ],
    },
    {
        fields: [
            {
                label: 'Ссылка на соцсеть или сайт',
                id: 'socialNetworks',
                type: FormItemType.Component,
                component: SocialNetworksEditor,
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
]);
