import { Button, Checkbox, Icon, Popover } from 'antd';
import RoundButton from '../button/roundButton';
import { IconCompressArrowsAltSolid2 } from '../icons';
import ContactsDetailedSelect from '../v2/detailedSelect/ContactsDetailedSelect';
import { ContactRecord } from '../../server';
import { useCallback, useState } from 'react';
import { WarningsList } from '../text/WarningsList/WarningsList';
import { useCombineContactsMutation } from '../../modules/main/renters/tabs/contacts/api/contactsApi';
import { useLocalStorageJoinContactData } from './useLocalStorageJoinContactData';

import './JoinContactAction.less';

interface Props {
    selectedRecords: ContactRecord[];
    clearSelection: () => void;
}

const JoinContactAction = ({ selectedRecords, clearSelection }: Props) => {
    const [correctId, setCorrectId] = useState<number>();
    const [selectedKeyFromStorage, setSelectedKeyAndSaveToStorage] = useLocalStorageJoinContactData();
    const [complementCorrectContact, setComplementCorrectContact] = useState(selectedKeyFromStorage);

    const crewMembersAmount = selectedRecords.filter((r) => !!r.crewMemberId).length;
    const usersAmount = selectedRecords.filter((r) => !!r.userId).length;

    const [combineContacts] = useCombineContactsMutation();

    const combineHandler = useCallback(() => {
        combineContacts({
            complementCorrectContact,
            combibeInfo: {
                correctContactId: correctId!,
                contactIdsToCombine: selectedRecords.filter((r) => r.id !== correctId).map((r) => r.id),
            },
        });
        clearSelection();
    }, [clearSelection, combineContacts, complementCorrectContact, correctId, selectedRecords]);

    return (
        <Popover
            overlayClassName={'grid-additional-actions-popover-container rr-join-contact-popover'}
            autoAdjustOverflow
            arrowPointAtCenter
            placement="bottomLeft"
            trigger="click"
            destroyTooltipOnHide
            overlayStyle={{ width: 285 }}
            content={
                <>
                    <div className={'rr-grid-actions-popover-header'}>
                        <Icon component={IconCompressArrowsAltSolid2} style={{ marginRight: 8, fontSize: 22 }} />
                        Объединить контакты ({selectedRecords.length})
                    </div>
                    <div className={'rr-grid-actions-popover-content'}>
                        <div className="joinContacts">
                            <span>Верный контакт</span>
                            <ContactsDetailedSelect
                                id="contacts"
                                placeholder="Выберите контакт"
                                value={correctId ? [correctId] : []}
                                onChange={(data) => setCorrectId(Array.isArray(data) ? data[0] : data)}
                                records={selectedRecords}
                                getPopupContainer={() => document.querySelector('body') as HTMLElement}
                                closeDrawerOnEdit
                            />
                            <span className="rr-grid-actions-popover-hint">
                                Остальные выбранные будут всюду заменены верным, а сами удалены
                            </span>
                            <Checkbox
                                disabled={!correctId}
                                checked={complementCorrectContact}
                                onChange={(e) => {
                                    setSelectedKeyAndSaveToStorage(e.target.checked);
                                    setComplementCorrectContact(e.target.checked);
                                }}
                            >
                                Дополнить данные
                            </Checkbox>
                            <span className="rr-grid-actions-popover-hint">
                                У верного будут дополнены: пустые поля, вложения, соц. сети и ссылки{' '}
                            </span>
                            {(crewMembersAmount > 1 || usersAmount > 1) && (
                                <WarningsList
                                    danger
                                    warnings={['Невозможно один контакт назначить двум пользователям системы или проектным работникам']}
                                />
                            )}
                            {usersAmount === 1 && crewMembersAmount <= 1 && (
                                <WarningsList
                                    warnings={[
                                        'Выбран контакт, относящийся к пользователю системы, ФИО, почта и телефон будут взяты из данных пользователя',
                                    ]}
                                />
                            )}
                        </div>
                        <div className={'category-action-block'}>
                            <Button
                                className={'action join'}
                                block
                                disabled={!correctId || crewMembersAmount > 1 || usersAmount > 1}
                                onClick={combineHandler}
                            >
                                <Icon className="icon" component={IconCompressArrowsAltSolid2} />
                                <span>Объединить</span>
                            </Button>
                        </div>
                    </div>
                </>
            }
        >
            <RoundButton colorScheme="join">
                <Icon component={IconCompressArrowsAltSolid2} />
                Объединить
            </RoundButton>
        </Popover>
    );
};
export default JoinContactAction;
