import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Icon, Modal, Row } from 'antd';
import { DynamicForm, FormValue } from '../../../../../components';
import { formatMoney } from '../../../../../shared/util/formatMoney';
import { useSelector } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { IconStepBackwardSolid } from '../../../../../components/icons';
import { IRootState } from '../../../../../shared/reducers';
import { Alert } from '../../../../../components/alert/alert';
import { LocalizationEnum, localize } from '../../../../../localization';
import { FormValueMoneyProblem } from '../../../../../components/formValue/FormValueMoneyProblem';
import { CollapsibleBlock } from '../../../../../components/v2/collapsibleBlock/CollapsibleBlock';
import { FormItemType } from '../../../../../components/dynamicForm/DynamicForm';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import {
    PaymentInfoCreate,
    PaymentInfoRead,
    PaymentInfoUpdate,
    PaymentMethodRequirementTypeCodeEnum,
    PaymentStateCodeEnum,
    ProjectInfoRead,
    serverApi,
    SubrentInfoRead,
} from '../../../../../server';
import { showNotification } from '../../../../../components/notification/showNotification';
import { getServerError } from '../../../../../shared/util/utils';
import './paymentCreateModal.less';
import { loadEntity as loadSubrent } from '../../../subrent/shippings/reducers/subrent.reducer';
import { loadEntity as loadProject } from '../../../projects/production/reducers/project.reducer';
import { paymentsUtils } from '../../utils/paymentsUtils';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { updatePaymentModalIndicatorsCollapsed } from '../../../../../shared/reducers/userSettings/userSettings.reducer';
import { NumberInputMoney } from '../../../../../components/numberInputs/numberInputMoney';
import { PaymentMethodSelect } from '../../../../../components/v2/select/paymentMethodSelect/paymentMethodSelect';
import { useLazyGetDefaultPaymentMethodIdQuery } from '../../../settings/paymentMethods/api/paymentMethods.api';
import { preferencesPaymentMethodRequirementTypeSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { paymentsApi } from '../../api/payments.api';
import { loadSimpleOrder, setProjectPledge } from '../../../projects/simpleOrders/reducers/simpleOrder.reducer';
import { useUpdatePledgeMutation } from '../../../projects/api/projects.api';

interface Props {
    project?: ProjectInfoRead | null;
    subrent?: SubrentInfoRead | null;
    paymentId?: number;
}

interface DebtAfterSum1Props {
    value: number;
    onChange: (value: number | undefined) => void;
    onPayAll: () => void;
    disabled?: boolean;
    max?: number;
    payAllDisabled?: boolean;
}

export const DebtAfterSum1 = ({ value, onChange, onPayAll, disabled, max, payAllDisabled }: DebtAfterSum1Props) => {
    return (
        <div style={{ display: 'flex' }} className="rr-project-pay-modal">
            <NumberInputMoney
                step={50}
                value={value || 0}
                onChange={onChange}
                min={0}
                max={max ? max / 100 : undefined}
                onBlur={() => {
                    if (!value) onChange(0);
                }}
                disabled={disabled}
            />
            <Button
                type="primary"
                style={{
                    marginLeft: 8,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#34BFA3',
                }}
                onClick={onPayAll}
                disabled={payAllDisabled || disabled}
            >
                <Icon
                    style={{
                        fontSize: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transform: 'rotate(90deg)',
                    }}
                    component={IconStepBackwardSolid}
                />
            </Button>
        </div>
    );
};

const DebtAfterSum = (props: { value: number }) => {
    const v = props.value;
    return (
        <div>
            {v === 0 ? (
                <span
                    style={{
                        color: 'rgb(52, 191, 163)',
                        fontWeight: 400,
                        fontSize: 18,
                    }}
                >
                    {localize(LocalizationEnum.ASPECT__GLOBAL__NO)}
                </span>
            ) : (
                <div className="rr-value rr-danger" style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormValueMoneyProblem withOverpay={true} value={v} overpayBelow={true} />
                </div>
            )}
        </div>
    );
};

export const PaymentCreateModal = (props: Props) => {
    const dispatch = useAppDispatch();
    const indicatorsCollapsed = useAppSelector((state) => state.userSettings.paymentModalIndicatorsCollapsed);
    const setIndicatorsCollapsed = (collapsed: boolean) => dispatch(updatePaymentModalIndicatorsCollapsed(collapsed));
    const [buttonSpin, setButtonSpin] = useState(false);
    const [err, setErr] = useState<any>(undefined);
    const businessAccountId = useSelector((state: IRootState) => state.businessAccount.entity!.id);
    const locationSearch = useSelector((state: IRootState) => state.router.location.search);
    const form = useRef<WrappedFormUtils>();
    const [visible, setVisible] = useState(Boolean(props.project || props.subrent));
    const [project, setProject] = useState(props.project as undefined | null | ProjectInfoRead);
    const [subrent, setSubrent] = useState(props.subrent as undefined | null | SubrentInfoRead);
    const [payment, setPayment] = useState<PaymentInfoRead | null>(null);
    const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState<number | undefined>();
    const paymentMethodRequirementType = useAppSelector(preferencesPaymentMethodRequirementTypeSelector);
    const [getDefaultPaymentMethodId] = useLazyGetDefaultPaymentMethodIdQuery();

    const pledge = useAppSelector((state: IRootState) => state.simpleOrder.pledge);

    const pledgeData = pledge && pledge.audits && pledge.audits.length ? pledge.audits.at(-1) : undefined;

    const [updatePledge] = useUpdatePledgeMutation();

    const _getDefaultPaymentMethodId = () => {
        getDefaultPaymentMethodId({ businessAccountId })
            .unwrap()
            .then((id) => {
                if (id) {
                    setDefaultPaymentMethodId(id);
                }
            });
    };

    useEffect(() => {
        if (defaultPaymentMethodId) {
            if (!payment) form.current?.setFieldsValue({ paymentMethodId: defaultPaymentMethodId });
        }
    }, [defaultPaymentMethodId]);

    useEffect(() => {
        if (!project && !subrent && props.paymentId) {
            serverApi.getPaymentById(businessAccountId, props.paymentId).then((paymentResp) => {
                if (paymentResp.data.projectId) {
                    serverApi.getProjectById(businessAccountId, paymentResp.data.projectId).then((projectResp) => {
                        setPayment(paymentResp.data);
                        setProject(projectResp.data);
                        setVisible(true);
                        _getDefaultPaymentMethodId();
                    });
                } else if (paymentResp.data.subrentId) {
                    serverApi.getSubrentById(businessAccountId, paymentResp.data.subrentId).then((subrentResp) => {
                        setPayment(paymentResp.data);
                        setSubrent(subrentResp.data);
                        setVisible(true);
                        _getDefaultPaymentMethodId();
                    });
                }
            });
        } else {
            _getDefaultPaymentMethodId();
        }
    }, []);

    const handleOk = () => {
        if (form.current) {
            form.current.validateFieldsAndScroll({ scroll: { offsetTop: 72 } }, async (err, values) => {
                if (!err) {
                    setButtonSpin(true);
                    if (props.paymentId && payment && payment.id) {
                        // редактирование
                        let info: PaymentInfoUpdate = {
                            businessVersion: payment.businessVersion,
                            amount: values.amount,
                            date: values.date,
                            comment: values.comment || undefined,
                            incoming: values.incoming,
                            paymentMethodId: values.paymentMethodId,
                            isPledgePayment: !!pledge, // TODO
                        };
                        try {
                            await serverApi.updatePaymentById(businessAccountId, payment.id, info);
                            showNotification('success', 'Платеж изменен');
                            closeModal();
                            dispatch(paymentsApi.util?.invalidateTags(['PaymentsList', 'Payment']));
                        } catch (e) {
                            showNotification('error', 'Платеж не изменен');
                            setButtonSpin(false);
                            setErr(getServerError(e));
                        }
                    } else {
                        // Создание
                        let info: PaymentInfoCreate = {
                            amount: values.amount,
                            stateCode: values.stateCode,
                            projectId: project?.id,
                            subrentId: subrent?.id,
                            date: values.date,
                            comment: values.comment,
                            incoming: pledge ? true : values.incoming,
                            paymentMethodId: pledge ? undefined : values.paymentMethodId,
                            isPledgePayment: !!pledge, // TODO
                        };
                        try {
                            await serverApi.createPayment(businessAccountId, info);
                            showNotification('success', 'Платеж создан');
                            closeModal();
                            dispatch(paymentsApi.util?.invalidateTags(['PaymentsList', 'Payment']));

                            if (project) {
                                if (project.isSimpleOrder) dispatch(loadSimpleOrder(businessAccountId, project.id));
                                else dispatch(loadProject(businessAccountId, project.id));
                            } else if (subrent) {
                                dispatch(loadSubrent(businessAccountId, subrent.id));
                            }
                        } catch (e) {
                            showNotification('error', 'Платеж не создан');
                            setButtonSpin(false);
                            setErr(getServerError(e));
                        }
                        if (project && pledge && pledgeData && values.amount > 0) {
                            updatePledge({
                                pledgeId: pledge.id,
                                projectId: project?.id,
                                data: {
                                    sum: pledgeData.sum - values.amount,
                                    description: pledgeData.description,
                                },
                            }).then(() => {
                                if (project.isSimpleOrder) dispatch(loadSimpleOrder(businessAccountId, project.id));
                            });
                        }
                    }
                }
            });
        }
    };

    const closeModal = () => {
        setVisible(false);
        dispatch(setProjectPledge(null));
        if (props.paymentId && payment && payment.id) {
            dispatch(push('/' + businessAccountId + '/history/payments/' + locationSearch));
        } else {
            if (project) {
                if (!project.isSimpleOrder)
                    dispatch(push('/' + businessAccountId + '/projects/production/' + project.id + '/?tab=description'));
                else dispatch(replace('/' + businessAccountId + '/projects/simpleOrders/' + project.id + '?tab=description'));
            } else if (subrent) {
                dispatch(push('/' + businessAccountId + '/subrent/shippings/' + subrent.id + '/?tab=description'));
            }
        }
    };

    const handleCancel = (e) => {
        closeModal();
    };

    const onPayAll = () => {
        if (project) {
            let amount =
                project.debtSum +
                (payment && payment.stateCode === PaymentStateCodeEnum.DONE ? getAmount(payment.amount, payment.incoming) : 0);
            let incoming = form.current?.getFieldValue('incoming');
            if (amount > 0) {
                incoming = true;
            } else if (amount < 0) {
                incoming = false;
                amount = Math.abs(amount);
            }
            form.current?.setFieldsValue({
                amount: amount,
                debtAfterSum: getDebtAfterSum(amount, incoming),
                incoming,
            });
            form.current?.validateFields(['amount']);
        } else if (subrent) {
            let amount =
                subrent.debtToSupplierSum +
                (payment && payment.stateCode === PaymentStateCodeEnum.DONE ? getAmount(payment.amount, payment.incoming) : 0);
            let incoming = form.current?.getFieldValue('incoming');
            if (amount > 0) {
                incoming = false;
            } else if (amount < 0) {
                incoming = true;
                amount = Math.abs(amount);
            }
            form.current?.setFieldsValue({
                amount: amount,
                debtAfterSum: getDebtAfterSum(amount, incoming),
                incoming,
            });
            form.current?.validateFields(['amount']);
        }
    };

    const getDebtAfterSum = (amount: number, incoming: boolean): number => {
        const prevAmount = payment && payment.stateCode === PaymentStateCodeEnum.DONE ? getAmount(payment.amount, payment.incoming) : 0;
        const currentAmount = getAmount(amount, incoming);
        if (project) {
            return payment ? project.debtSum + prevAmount - currentAmount : project.debtSum - currentAmount;
        } else if (subrent) {
            return payment ? subrent.debtToSupplierSum + prevAmount - currentAmount : subrent.debtToSupplierSum - currentAmount;
        } else return 0;
    };

    const getAmount = (amount: number, incoming: boolean | undefined): number => {
        let multiplier = 1;
        if (incoming !== undefined && ((project && !incoming) || (subrent && incoming))) multiplier = -1;
        return amount * multiplier;
    };

    let v1;
    let v2;
    let v3;
    let v4;

    const incoming = form.current?.getFieldValue('incoming') || (payment ? payment.incoming : undefined);
    if (project) {
        const payCount = form.current?.getFieldValue('amount') || (payment ? payment.amount : 0) || 0;
        v1 = project.actualSumAfterTaxes;
        v2 = project.acceptedPaymentsSum;
        v3 = project.debtSum;
        v4 = getDebtAfterSum(payCount, incoming);
    } else if (subrent) {
        const payCount = form.current?.getFieldValue('amount') || (payment ? payment.amount : 0) || 0;
        v1 = subrent.sum;
        v2 = subrent.payedToSupplierSum;
        v3 = subrent.debtToSupplierSum;
        v4 = getDebtAfterSum(payCount, incoming);
    }

    const initialValues = {
        ...payment,
        amount: pledgeData ? Math.min(pledgeData.sum, project?.debtSum ?? pledgeData.sum) : payment ? payment.amount : 0,
        debtAfterSum: v4,
        incoming: pledge ? true : payment ? payment.incoming : true,
    };

    return visible ? (
        <div>
            <Modal
                title={pledge ? 'Создание платежа из залога' : props.paymentId ? 'Редактирование платежа' : 'Создание платежа'}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                className="rr-modal-pay"
                width={800}
                footer={[
                    <Button key="back" onClick={handleCancel} className="ant-btn rr-btn-default">
                        {localize(LocalizationEnum.ASPECT__GLOBAL__CANCEL)}
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk} loading={buttonSpin}>
                        {localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
                    </Button>,
                ]}
            >
                {err ? (
                    <div style={{ margin: '27px 37px' }}>
                        <Alert
                            message={localize(LocalizationEnum.ASPECT__GLOBAL__ERROR)}
                            description={
                                <>
                                    <div style={{ fontWeight: 700 }}> {err.title}</div>
                                    {err.message}
                                </>
                            }
                            type="error"
                            showIcon
                            closable
                            style={{ marginBottom: '36px' }}
                        />
                    </div>
                ) : null}

                <CollapsibleBlock
                    style={{ borderBottom: '1px solid #eaeaea' }}
                    title={`Показатели ${project ? 'проекта' : 'поставки'} до оплаты`}
                    collapsed={indicatorsCollapsed}
                    onCollapse={(collapsed) => setIndicatorsCollapsed(collapsed)}
                >
                    <Row>
                        <Col span={8}>
                            <div className="rr-label">
                                {localize(
                                    project
                                        ? LocalizationEnum.PAGE__PROJECTS__CARD__FIELD__COST_AFTER_TAXES
                                        : LocalizationEnum.PAGE__SHIPPINGS__CARD__FIELD__COST
                                )}
                            </div>
                            <div className="rr-value">
                                <FormValue value={formatMoney(v1)} originalValue={140} />
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="rr-label">{localize(LocalizationEnum.PAGE__PROJECTS__CARD__PAYMENTS_ACCEPTED_SUM)}</div>
                            <div className="rr-value">
                                <FormValue value={formatMoney(v2)} originalValue={140} />
                            </div>
                        </Col>
                        <Col span={8} style={{ paddingLeft: 11 }}>
                            <div className="rr-label">{localize(LocalizationEnum.PAGE__PROJECTS__CARD__DEBT_SUM)}</div>
                            {v3 === 0 ? (
                                <span style={{ color: 'rgb(52, 191, 163)', fontWeight: 400, fontSize: '18px' }}>
                                    {localize(LocalizationEnum.ASPECT__GLOBAL__NO)}
                                </span>
                            ) : (
                                <div className="rr-value rr-danger" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <FormValueMoneyProblem withOverpay={true} value={v3} overpayBelow={true} />
                                </div>
                            )}
                        </Col>
                        {!!pledgeData && (
                            <Col style={{ marginTop: 20 }} span={8}>
                                <div className="rr-label">Залог</div>
                                <div className="rr-value">
                                    <FormValue value={formatMoney(pledgeData.sum)} />
                                </div>
                            </Col>
                        )}
                    </Row>
                </CollapsibleBlock>

                <Row>
                    <Col span={24} style={{ paddingLeft: 27, paddingRight: 27, marginTop: -12 }}>
                        <DynamicForm
                            initialValues={initialValues}
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                            ref={form as any}
                            data={[
                                {
                                    fields: [
                                        {
                                            id: 'id',
                                            type: FormItemType.Hidden,
                                        },
                                        {
                                            id: 'stateCode',
                                            type: FormItemType.Hidden,
                                        },
                                        {
                                            label: LocalizationEnum.PAGE__PROJECTS__MODAL__PAYMENT__PAYMENT_SUM,
                                            id: 'amount',
                                            type: FormItemType.Component,
                                            colSpan: 16,
                                            component: DebtAfterSum1,
                                            componentProps: {
                                                onPayAll: onPayAll,
                                                max: pledgeData
                                                    ? Math.min(pledgeData.sum, project ? project.debtSum : pledgeData.sum)
                                                    : undefined,
                                                disabled: pledgeData && project ? project.debtSum <= 0 : undefined,
                                                payAllDisabled: pledgeData && project && project.debtSum > pledgeData.sum,
                                            } as DebtAfterSum1Props,
                                            defaultValue: 0,
                                            onChange: (amount) => {
                                                const incoming = form.current?.getFieldValue('incoming');
                                                form.current?.setFieldsValue({
                                                    debtAfterSum: getDebtAfterSum(amount, incoming),
                                                });
                                            },
                                        },
                                        {
                                            label: LocalizationEnum.PAGE__PROJECTS__MODAL__PAYMENT__DEBT_AFTER_SUM,
                                            id: 'debtAfterSum',
                                            type: FormItemType.Component,
                                            colSpan: 8,
                                            component: DebtAfterSum,
                                        },
                                        {
                                            label: 'Направление',
                                            id: 'incoming',
                                            type: pledge ? FormItemType.Hidden : FormItemType.RadioGroup,
                                            colSpan: 24,
                                            values: [
                                                {
                                                    name: 'Входящий',
                                                    value: true,
                                                },
                                                {
                                                    name: 'Исходящий',
                                                    value: false,
                                                },
                                            ],
                                            defaultValue: pledge
                                                ? true
                                                : payment
                                                ? payment.incoming
                                                : project
                                                ? true
                                                : subrent
                                                ? false
                                                : undefined,
                                            onChange: (incoming: boolean) => {
                                                let amount = form.current?.getFieldValue('amount') || 0;
                                                form.current?.setFieldsValue({
                                                    debtAfterSum: getDebtAfterSum(amount, incoming),
                                                });
                                            },
                                        },
                                        {
                                            label: 'Статус',
                                            placeholder: 'Не выбран',
                                            id: 'stateCode',
                                            type: pledge ? FormItemType.Hidden : FormItemType.Select,
                                            colSpan: 24,
                                            required: true,
                                            values: (form: WrappedFormUtils) => {
                                                return paymentsUtils.getCreationStateCodes().map((code) => ({
                                                    name: (
                                                        <>
                                                            <div
                                                                className={`rr-dot rr-status-bg-payment-` + code}
                                                                style={{ marginRight: '10px' }}
                                                            ></div>
                                                            {paymentsUtils.getPaymentStateByCode(code)}
                                                        </>
                                                    ),
                                                    value: code,
                                                }));
                                            },
                                            visible: (v) => v('id') === undefined,
                                            defaultValue: PaymentStateCodeEnum.DONE,
                                            style: { width: 260 },
                                        },
                                        {
                                            label: 'Дата',
                                            id: 'date',
                                            type: pledge ? FormItemType.Hidden : FormItemType.DateTime,
                                            colSpan: 24,
                                            required: true,
                                            defaultValue: moment(),
                                            style: { width: 260 },
                                        },
                                        {
                                            label: 'Способ оплаты',
                                            id: 'paymentMethodId',
                                            type: pledge ? FormItemType.Hidden : FormItemType.Component,
                                            colSpan: 24,
                                            component: PaymentMethodSelect,
                                            placeholder: 'Не выбран',
                                            required: (getFieldValue) => {
                                                let required = false;

                                                if (paymentMethodRequirementType === PaymentMethodRequirementTypeCodeEnum.REQUIRED)
                                                    required = true;
                                                else if (
                                                    paymentMethodRequirementType ===
                                                        PaymentMethodRequirementTypeCodeEnum.REQUIREDINSTATUSDONE &&
                                                    getFieldValue('stateCode') === PaymentStateCodeEnum.DONE
                                                )
                                                    required = true;
                                                return required;
                                            },
                                        },
                                        {
                                            label: 'Комментарий',
                                            id: 'comment',
                                            type: FormItemType.Text,
                                            colSpan: 24,
                                        },
                                    ],
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </Modal>
        </div>
    ) : null;
};
