import React, { FC } from 'react';
import { Checkbox, Switch } from 'antd';
import { isDefined } from '../../../../../../shared/util/utils';

export interface YYYProps {
    label: string;
    checked?: boolean;
    checkboxLabel?: string;
    checkboxChecked?: boolean;
    onChange: (a?: boolean, b?: boolean) => void;
    last?: boolean;
}

const YYY: FC<YYYProps> = (props) => {
    const w = 64;

    return (
        <div style={{ lineHeight: 1 }}>
            <div style={{ display: 'flex', lineHeight: '28px' }}>
                <div style={{ width: w }}>
                    <Switch
                        {...(props.checked !== undefined ? { checked: props.checked } : {})}
                        onChange={(checked) => {
                            props.onChange?.(checked, props.checkboxChecked);
                        }}
                    />
                </div>
                <div style={{ fontWeight: 700 }}>{props.label}</div>
            </div>
            {props.checkboxLabel && (
                <div style={{ display: 'flex', marginTop: 8, marginBottom: props.last ? undefined : 32 }}>
                    <div style={{ width: w }}></div>
                    <div>
                        <Checkbox
                            {...(props.checkboxChecked !== undefined ? { checked: props.checkboxChecked } : {})}
                            onChange={(e) => {
                                //console.log('AAA', e.target.checked);
                                props.onChange?.(props.checked, e.target.checked);
                            }}
                        >
                            {props.checkboxLabel}
                        </Checkbox>
                    </div>
                </div>
            )}
        </div>
    );
};

interface V {
    copyRentElements?: boolean;
    copyWorkPlannings?: boolean;
    copyExpenses?: boolean;

    saveCrewMembers?: boolean;
    saveTrackedInstances?: boolean;
}

interface CopyProjectParamsBlockProps {
    value?: V;
    onChange: (value: V) => void;
}

export const CopyProjectParamsBlock: FC<CopyProjectParamsBlockProps> = ({ value, onChange }) => {
    const onChange1 = (value: V) => {
        //console.log('VVV', value);
        onChange(value);
    };

    const last = !isDefined(value?.copyWorkPlannings) && !isDefined(value?.copyExpenses);

    return (
        <>
            {isDefined(value?.copyRentElements) && (
                <YYY
                    checked={value?.copyRentElements}
                    checkboxChecked={value?.saveTrackedInstances}
                    label={'Копировать обязательства'}
                    checkboxLabel={'С экземплярами'}
                    onChange={(switchChecked, checkboxChecked) => {
                        onChange1({
                            ...value,
                            copyRentElements: switchChecked,
                            saveTrackedInstances: switchChecked === false ? false : checkboxChecked,
                        });
                    }}
                    last={last}
                />
            )}
            {isDefined(value?.copyWorkPlannings) && (
                <YYY
                    checked={value?.copyWorkPlannings}
                    checkboxChecked={value?.saveCrewMembers}
                    label={'Копировать работы'}
                    checkboxLabel={'С работниками'}
                    onChange={(switchChecked, checkboxChecked) => {
                        onChange1({
                            ...value,
                            copyWorkPlannings: switchChecked,
                            saveCrewMembers: switchChecked === false ? false : checkboxChecked,
                        });
                    }}
                />
            )}
            {isDefined(value?.copyExpenses) && (
                <YYY
                    checked={value?.copyExpenses}
                    label={'Копировать затраты'}
                    onChange={(switchChecked, checkboxChecked) => {
                        onChange1({
                            ...value,
                            copyExpenses: switchChecked,
                        });
                    }}
                />
            )}
        </>
    );
};
