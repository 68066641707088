import { useState } from 'react';
import { UserRecord } from '../../../server';
import DetailedOption, { DetailedOptionProps } from './DetailedOption';
import { ContactDrawer } from '../../../modules/main/renters/tabs/contacts/drawer/components/ContactDrawer/ContactDrawer';
import { IDetailedSelectColumn } from './DetailedSelect';

type UserDetailedOptionProps = Omit<DetailedOptionProps<UserRecord>, 'details' | 'columns'> & { columns?: IDetailedSelectColumn<UserRecord>[] };

const UserDetailedOption = ({
    columns = [
        {
            key: 'fullName',
            width: 240,
            render: (v) => {
                let res = '';
                if (v && typeof v === 'object' && 'lastname' in v) {
                    res += v.lastname;
                }

                if (v && typeof v === 'object' && 'firstname' in v) {
                    res += ` ${v.firstname}`;
                }
                return res;
            },
        },
        { key: 'phoneNumber', width: 150 },
        { key: 'email', width: 200, secondary: true },
    ],
    ...props
}: UserDetailedOptionProps) => {
    const [selectedId, setSelectedId] = useState<number | null>(null);

    return (
        <div
            onClick={(e) => {
                if (selectedId) {
                    e.stopPropagation();
                }
            }}
        >
            <DetailedOption
                {...props}
                columns={columns}
                details={{
                    text: 'Смотреть',
                    getKey: (user) => user.contactId,
                    clickHandler: (id) => setSelectedId(props.item.contactId ?? null),
                }}
            />
            {selectedId && <ContactDrawer contactId={selectedId} onClose={() => setSelectedId(null)} />}
        </div>
    );
};
export default UserDetailedOption;
