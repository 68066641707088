import React, { FC, useCallback } from 'react';
import { logisticsPageUrlRoute } from '../../../data/tabs';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { VehiclesPageFiltersDescription } from '../filters/vehiclesFiltersDescription';
import { EntityList } from '../../../../../../components/page/entityGrid/components/ui/EntityList/EntityList';
import { vehiclesColumns } from '../columns/vehiclesColumns';
import { vehiclesFilters } from '../filters/vehiclesFilters';
import { useListVehiclesQuery, vehiclesApi } from '../api/vehicles.api';
import { vehicleEntitySelector } from '../reducers/vehicle/vehicle.selectors';
import { useActionsButtons, useVehicleEntityActions } from '../hooks/VehiclesListHooks';
import { useSelectedRecords } from '../../../../../../components/page/entityGrid/hooks/useSelectedRecords';
import { ExcelExportTemplateTypeCodeEnum, VehicleNotFinancialFieldsTypeCode, VehicleRecord } from '../../../../../../server';
import { useEntityGridContext } from '../../../../../../components/page/entityGrid/components/context/GridRefContext';
import { useEntitySelection } from '../../../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import { VehicleCreateModalWrapper } from '../modal/create/VehicleCreateModalWrapper';
import { VehicleEditModalWrapper } from '../modal/edit/VehicleEditModalWrapper';
import { clearVehicle } from '../reducers/vehicle/vehicle.reducer';
import { VehicleDrawerWrapper } from '../drawer/components/wrappers/VehicleDrawerWrapper';
import { withEntityListProviders } from '../../../../../../components/page/entityGrid/components/decorators/withEntityListProviders';
import { EntityGridRootPath } from '../../../../../../components/page/entityGrid/types/params';
import {
    usePageURLParamsFromDescription,
    useQueryParamsWithoutModalParams,
} from '../../../../../../components/page/entityGrid/hooks/useParams';

import { LogisticsPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import { ExportOffloadingPopover } from '../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover';
import { OffloadingDocumentOnDownload } from '../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data';
import { downloadOffloading } from '../../../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading';
import { useIntl } from 'react-intl';
import { canViewFinancialDataSelector } from '../../../../../../shared/reducers/permissions.reducer';
import { VehiclesUtils } from '../api/vehicles.api.utils';

interface VehiclesListComponentProps {
    gridName: LogisticsPageTabsEnum;
}

const VehiclesListComponent: FC<VehiclesListComponentProps> = (props) => {
    const { gridName } = props;
    const location = useLocation();
    const intl = useIntl();
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const currentEntity = useAppSelector(vehicleEntitySelector);
    const gridRef = useEntityGridContext();
    const entitySelection = useEntitySelection();

    const pageParamsObject = usePageURLParamsFromDescription({
        pageParamsDescription: VehiclesPageFiltersDescription,
        gridName,
    });
    const { pageParams, getSearchParams } = pageParamsObject;
    const urlSearchParams = getSearchParams(pageParams);
    const queryParams = useQueryParamsWithoutModalParams(pageParams);
    console.log(queryParams);

    const queryData = useListVehiclesQuery(
        {
            businessAccountId,
            params: queryParams,
        },
        {
            skip: !location.pathname.includes(gridName),
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const rootPath = `/${businessAccountId}/${logisticsPageUrlRoute}/${gridName}` satisfies EntityGridRootPath;
    const entityActions = useVehicleEntityActions({
        rootPath,
        urlSearchParams,
    });

    const selectedRecords = useSelectedRecords<VehicleRecord>(entitySelection, queryData.data?.entitiesData.records);
    const { buttons: entityActionsButtons, isLoading: isEntityActionsButtonsLoading } = useActionsButtons({
        selectedRecords,
        gridRef,
    });

    const onOffloadingDownload: OffloadingDocumentOnDownload = useCallback(
        async ({ fileType, options }) => {
            const { sortOrder, sortBy, search } = pageParams;
            const filters = VehiclesUtils.createRequestFilters(pageParams);

            await downloadOffloading({
                businessAccountId,
                canViewFinancialData,
                exportTemplateType: ExcelExportTemplateTypeCodeEnum.VEHICLETEMPLATE,
                fileName: 'Экспорт транспорта',
                fileType,
                filters,
                financialFieldsEnum: VehicleNotFinancialFieldsTypeCode,
                grid: gridRef.current,
                intl,
                notFinancialFieldsEnum: VehicleNotFinancialFieldsTypeCode,
                selection: entitySelection,
                values: options,
                sortOrder,
                sortBy,
                search,
                excludeColumns: undefined,
            });
        },
        [businessAccountId, canViewFinancialData, entitySelection, gridRef, intl, pageParams]
    );

    return (
        <EntityList
            pageParamsObject={pageParamsObject}
            gridProps={{
                static: {
                    columns: vehiclesColumns,
                    filtersData: vehiclesFilters,
                    pageParamsDescription: VehiclesPageFiltersDescription,
                },
                metaData: {
                    entityType: 'vehicle',
                    gridName,
                    rootPath,
                },
                queryData,
                entitiesLoading: entityActions.isLoading || isEntityActionsButtonsLoading,
                entityActions,
                actionButtons: entityActionsButtons,
                exportBlock: <ExportOffloadingPopover storageKey={'vehiclesOffloading'} onDownload={onOffloadingDownload} />,
                pinned: { right: ['actions'] },
            }}
            modalProps={{
                currentEntity,
                modals: {
                    create: {
                        component: VehicleCreateModalWrapper,
                    },
                    edit: {
                        component: VehicleEditModalWrapper,
                    },
                },
                entityLoadActions: {
                    loadEntity: vehiclesApi.endpoints.vehicleById.initiate,
                    clearEntity: clearVehicle,
                },
            }}
            drawerProps={{
                drawer: VehicleDrawerWrapper,
                entityActions,
            }}
        />
    );
};

export const VehiclesList = withEntityListProviders(VehiclesListComponent);
