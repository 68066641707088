import {Popover} from "antd";
import React, {ReactNode, useState} from "react";
import {KitComponentsPopoverContent} from "./kitComponentsPopoverContent";

interface KitComponentsPopoverProps {
    children: ReactNode;
    kitId: number;
}

export const KitComponentsPopover = (props: KitComponentsPopoverProps) => {

    const [, setDataLoaded] = useState(false);

    return (
        <Popover
            overlayClassName={'rr-PricingScheme-price__popover'}
            onVisibleChange={(visible) => {
                if (!visible) setDataLoaded(false);
            }}
            destroyTooltipOnHide={true}
            trigger={'click'}
            placement={'right'}
            content={
                <KitComponentsPopoverContent kitId={props.kitId} onDataLoaded={() => {
                    setDataLoaded(true);
                }}/>
            }
        >
            {props.children}
        </Popover>
    );
}
