import React from "react";
import ReactQuill from "react-quill";
import * as Quill from "quill";
import 'react-quill/dist/quill.snow.css';
import './RichTextEditor.less';
import MagicUrl from 'quill-magic-url'

interface IProps {
    value?: string | Quill.Delta;
    onChange?: (
        content: string,
    ) => void;
}

let Block = (Quill as any).import("blots/block");
Block.tagName = 'DIV';
(Quill as any).register(Block, true);
(Quill as any).register('modules/magicUrl', MagicUrl);

let Link = (Quill as any).import('formats/link');

class CustomLink extends Link {

    static sanitize(url) {
        let value = super.sanitize(url);
        if (value) {
            for (let i = 0; i < CustomLink.PROTOCOL_WHITELIST.length; i++)
                if(value.startsWith(CustomLink.PROTOCOL_WHITELIST[i]))
                    return value;
            return `http://${value}`;
        }
        return value;
    }

    static create(value) {
        const node = super.create(value);
        node.setAttribute('target', '_blank');
        return node;
    }

}
(Quill as any).register(CustomLink);

const SnowTheme = (Quill as any).import("themes/snow");
const Delta = (Quill as any).import("delta");

/**
 * Extended snow theme for custom 'link tooltip'
 */
class ExtendSnowTheme extends SnowTheme {

    private tooltipModified:boolean;

    constructor(quill, options) {
        super(quill, options);
        // flag that ensure only create/add our custom elements once
        this.tooltipModified = false;
        // listener for adding our custom input for link 'text'
        quill.on("selection-change", (range) => {
            if (this.tooltipModified) return;
            // mark flag
            this.tooltipModified = true;
            let tooltip = quill.theme.tooltip;
            let index; // link start index
            let length; // link text length
            let linkValue; // link href value
            let linkText;
            let opened = false;

            setInterval(() => {
                if(tooltip.root.classList.contains("ql-hidden") && opened){
                    opened = false;
                    setTimeout(()=>{
                        linkText = linkValue = index = length = undefined;
                    }, 100);
                }
            }, 500);

            const save = () => {
                let delta = new Delta()
                    .retain(index)
                    .delete(length)
                    .insert(linkText, { link: linkValue });
                quill.updateContents(delta);
                linkText = linkValue = index = length = undefined;
            };

            // create input element
            const input = document.createElement("input");
            input.type = "text";
            input.placeholder = "Введите текст";
            input.addEventListener("input", (e) => {
                linkText = (e.target as any).value;
            });
            input.addEventListener("keydown", (e) => {
                if (e.key === "Enter") {
                    save();
                    tooltip.root.classList.add("ql-hidden");
                }
            });

            // create input label
            const label = document.createElement("span");
            label.textContent = "Текст: ";

            // modify tooltip root to replace psuedo elements
            const textInputContainer = document.createElement("div");
            textInputContainer.classList.add("link-text-container");
            textInputContainer.append(label, input);
            tooltip.root.insertBefore(textInputContainer, tooltip.root.firstChild);
            const linkInputLabel = document.createElement("span");

            linkInputLabel.classList.add("link-input-label");
            linkInputLabel.textContent = "URL: ";
            tooltip.root.insertBefore(linkInputLabel, tooltip.textbox);

            // Modify original link textbox
            const textbox = tooltip.textbox;
            textbox.setAttribute('data-link', 'https://rentrabb.it');
            textbox.addEventListener("keydown", (e) => {
                if (e.key === "Enter") save();
            });

            const onLinkInputInput = (e) => {
                linkValue = e.target.value;
            };

            const onLinkInputFocus = (e) => {
                const selectedRange = quill.selection.savedRange;
                if(selectedRange && selectedRange.length && linkText === undefined){
                    opened = true;
                    const selectedLinkText = quill.getText(selectedRange.index, selectedRange.length);
                    linkText = selectedLinkText;
                    index = selectedRange.index;
                    length = selectedRange.length;
                    input.value = selectedLinkText;
                }
                linkValue = e.target.value;
            };

            textbox.addEventListener("focus", onLinkInputFocus);
            textbox.addEventListener("input", onLinkInputInput);

            // Hack 'ql-action' button
            const actionBtn = tooltip.root.querySelector(".ql-action");
            actionBtn.addEventListener("click", () => {
                if(tooltip.root.classList.contains("ql-editing") && !tooltip.root.classList.contains("ql-hidden")){
                    if(tooltip.linkRange){
                        index = tooltip.linkRange.index;
                        length = tooltip.linkRange.length;
                        linkText = quill.getText(index, length);
                        input.value = linkText;
                    }
                }
                // Когда новая ссылка создается или редактирование существующей
                else if(tooltip.root.classList.contains("ql-hidden") && tooltip.root.classList.contains("ql-editing")){
                    save();
                }
            });
        });
    }
}

(Quill as any).register("themes/snow", ExtendSnowTheme);

export class RichTextEditor extends React.PureComponent<IProps> {

     onChange = (value: string) => {
         if(this.props.onChange) this.props.onChange(value);
     };

     render() {
         const modules = {
             toolbar: [
                 [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                 ['bold', 'italic', 'underline', 'strike'],
                 [{ 'color': [] }, { 'background': [] }],
                 [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' }, { 'align': [] }],
                 ['link'],
                 [],
                 ['clean']
             ],
             magicUrl: true,
         };
         const formats = [
             'background', 'bold', 'color', 'font', 'code', 'italic', 'link', 'size', 'strike', 'script', 'underline', 'blockquote', 'header', 'indent', 'list', 'align', 'direction', 'code-block',
         ];

        return (
            <ReactQuill
                modules={modules}
                formats={formats}
                className={'rr-rich-text-editor'}
                theme="snow" value={this.props.value || ''}
                onChange={this.props.onChange} />
        );
     }
}

// https://codesandbox.io/p/sandbox/react-quill-link-tooltip-text-edit-oosket?file=%2Fsrc%2Feditor.js%3A99%2C1-99%2C48