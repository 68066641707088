import React, { CSSProperties, FC, ReactNode } from 'react';
import { Icon } from 'antd';
import { IconExclamationSolid, IconExclamationTriangleSolid } from '../../icons';
import './WarningsList.less';
import classNames from 'classnames';

interface WarningsListProps {
    style?: CSSProperties;
    warnings: (string | ReactNode)[];
    danger?: boolean;
}

export const WarningsList: FC<WarningsListProps> = (props) => {
    const { warnings, danger } = props;

    return (
        <div className={'warning-list'} style={props.style}>
            <span className={'title'}>
                {danger ? `Проблем${warnings.length > 1 ? 'ы' : 'а'}:` : `Предупреждени${warnings.length > 1 ? 'я' : 'е'}:`}
            </span>
            <div className={classNames('warnings', danger && 'warnings--danger')}>
                {warnings.map((warning, index) => (
                    <span key={index} className={'warning-item'}>
                        <Icon component={danger ? IconExclamationSolid : IconExclamationTriangleSolid} />
                        <span>{warning}</span>
                    </span>
                ))}
            </div>
        </div>
    );
};
