import { PageURLParamDescriptionObject, PageURLParamDescriptions } from '../../../../../../core/utils/descriptions';
import { ListVehiclesFilters } from '../api/vehicles.api.types';
import { LoadingTypeCodeEnum, VehicleTypeCodeEnum } from '../../../../../../server';

export const VehiclesPageFiltersDescription: PageURLParamDescriptionObject<
    ListVehiclesFilters & {
        type?: VehicleTypeCodeEnum;
    }
> = {
    search: PageURLParamDescriptions.searchParam(),
    hideArchive: PageURLParamDescriptions.switchParam,
    loadCapacity: PageURLParamDescriptions.numberInterval,
    volume: PageURLParamDescriptions.numberInterval,
    type: PageURLParamDescriptions.enumSelectParam(VehicleTypeCodeEnum),
    loadingTypes: PageURLParamDescriptions.arrayOfEnumParam(LoadingTypeCodeEnum),
    length: PageURLParamDescriptions.numberInterval,
    width: PageURLParamDescriptions.numberInterval,
    height: PageURLParamDescriptions.numberInterval,
    contactId: PageURLParamDescriptions.numberParam,
};
