import {Select} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {LocalizationEnum, localize} from "../../localization";
import {NumberInput} from "../numberInputs";
import {isDefined} from "../../shared/util/utils";

export interface TimeDurationInputProps {
    value?: number;
    onChange?: (value?:number)=>void;
    min?: number;
    max?: number;
    disabled?: boolean;
}

export enum TimeUnit {
    Minute = 1,
    Hour = 60,
    Day = 60*24
}

const unitByValue = (value?:number) =>{
    let unit:TimeUnit;
    if(isDefined(value) && value > 0){
        if(value % (60 * 24) === 0) unit = TimeUnit.Day;
        else if(value % 60 === 0) unit = TimeUnit.Hour;
        else unit = TimeUnit.Minute;
    }else{
        unit = TimeUnit.Hour;
    }
    return unit;
}

export const TimeDurationInput = (props:TimeDurationInputProps) => {
    const [value, setValue] = useState<number|undefined>();
    const [unit, setUnit] = useState<TimeUnit>(TimeUnit.Hour);
    const [changedManually, setChangedManually] = useState(false);

    useEffect(() => {
        if(changedManually){
            let v:number|undefined = isDefined(props.value) ? props.value / unit : undefined;
            setChangedManually(false);
            setValue(v);
        }else{
            if(props.value !== (isDefined(value) ? value*unit : undefined)){
                const _unit = unitByValue(props.value);
                let v:number|undefined = isDefined(props.value) ? props.value / _unit : undefined;
                setValue(v);
                setUnit(_unit);
            }
        }
    }, [props.value, unit, value, setValue, setUnit, setChangedManually]);

    const onInputChange = useCallback((value?:number) => {
        console.log('onInputChange', value);
        let v = value !== undefined ? (value * (unit||0)) : undefined;
        setChangedManually(true);
        props.onChange?.(v);
    }, [setValue, unit, props.onChange]);

    const onSelectChange = useCallback((unit:TimeUnit) => {
        let v = value !== undefined ? (value * unit) : undefined;
        setChangedManually(true);
        setUnit(unit);
        props.onChange?.(v);
    }, [value, props.onChange, setChangedManually]);

    return (
        <div style={{display: 'flex', gap: 16}}>
            <NumberInput onChange={onInputChange} value={value} min={props.min} max={props.max} disabled={props.disabled} precision={0} />
            <Select onChange={onSelectChange} value={unit} style={{width: 'unset', minWidth: 100}} disabled={props.disabled}>
                <Select.Option value={TimeUnit.Minute}>{localize(LocalizationEnum.ASPECT__TIME__MINUTE, undefined, {value: value})}</Select.Option>
                <Select.Option value={TimeUnit.Hour}>{localize(LocalizationEnum.ASPECT__TIME__HOUR, undefined, {value: value})}</Select.Option>
                <Select.Option value={TimeUnit.Day}>{localize(LocalizationEnum.ASPECT__TIME__DAY, undefined, {value: value})}</Select.Option>
            </Select>
        </div>
    );
}
