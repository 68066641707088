import { ReactNode, useEffect, useState } from 'react';
import { LocationRecord } from '../../../server';
import { businessAccountIdSelector } from '../../../shared/reducers/system.reducer';
import { useAppSelector } from '../../../store/hooks';
import DetailedSelect, { IDetailedSelectColumn } from './DetailedSelect';
import { useDebounce } from '../../../core/hooks/useDebounce';
import { BaseSelectOptionProps } from '../select/baseSelect';
import { useLazyListLocationsQuery, useListLocationsQuery } from '../../../modules/main/logistics/tabs/locations/api/locations.api';
import { useLocationsActions } from '../../../modules/main/logistics/tabs/transportations/hooks/usePushToPages';
import { LocationDrawer } from '../../../modules/main/logistics/tabs/locations/drawer/LocationDrawer';

interface Filters {
    hideArchive?: boolean;
}
export interface LocationDetailedSelectProps<T extends {}> {
    id: string;
    columns?: IDetailedSelectColumn<T>[];
    placeholder?: ReactNode;
    value: number[];
    multiple?: boolean;
    onChange: (value: number | number[]) => void;
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
    getOptionProps?: (record: T) => BaseSelectOptionProps;
    getOptionPrefix?: (record: T) => ReactNode | null;
    onDataChange?: (data: T[]) => void;
    filters?: Filters;
    allowClear?: boolean;
    disabled?: boolean;
    records?: LocationRecord[];
    closeDrawerOnEdit?: boolean;
}

const LocationDetailedSelect = ({
    id,
    placeholder = 'Выберите локацию',
    multiple,
    value,
    onChange,
    getPopupContainer,
    getOptionProps,
    onDataChange,
    columns,
    filters,
    getOptionPrefix,
    allowClear,
    disabled,
    records,
    closeDrawerOnEdit,
}: LocationDetailedSelectProps<LocationRecord>) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const [selectedId, setSelectedId] = useState<number>();
    const [search, setSearch] = useState<string>();
    const debouncedSearchString = useDebounce<string | undefined>(search, 200);

    const { data, isLoading, refetch } = useListLocationsQuery(
        {
            businessAccountId,
            params: {
                search: debouncedSearchString ? debouncedSearchString : undefined,
                sortBy: 'lastUpdateDate',
                sortOrder: 'DESC',
                limit: 100,
                page: 1,
            },
        },
        { refetchOnMountOrArgChange: true, skip: !!records }
    );

    const { edit: editAction, delete: deleteAction, archive: archiveAction, isLoading: isEntityActionsLoading } = useLocationsActions();

    const [getData] = useLazyListLocationsQuery();

    useEffect(() => {
        getData({ businessAccountId, params: { limit: 100, page: 1, sortBy: 'lastUpdateDate', sortOrder: 'DESC' } }).then((res) => {
            const records = res.data?.entitiesData.records || [];
            const selectedData = records.filter((r) => value.includes(r.id));
            onDataChange?.(selectedData);
        });
    }, [businessAccountId, getData, JSON.stringify(value)]);

    const allItems = records ?? data?.entitiesData.records ?? [];

    return (
        <>
            <DetailedSelect<LocationRecord>
                allowClear={allowClear === false ? false : true}
                id={id}
                placeholder={placeholder}
                items={allItems}
                columns={
                    columns ?? [
                        {
                            key: 'shortName',
                            width: 200,
                        },
                        { key: 'address', width: 320 },
                    ]
                }
                getKey={(item) => item.id}
                value={value}
                onChange={(value) => {
                    onChange?.(value);
                    const v = Array.isArray(value) ? value : [value];
                    const selectedData = records ?? data?.entitiesData.records ?? [];
                    onDataChange?.(selectedData.filter((r) => v.includes(r.id)));
                }}
                onSearch={(value: string) => setSearch(value)}
                details={{ text: 'Смотреть', clickHandler: (id: number | undefined) => setSelectedId(id) }}
                loading={isLoading}
                selectedId={selectedId}
                getPopupContainer={getPopupContainer}
                multiple={multiple}
                getOptionProps={getOptionProps}
                hideArchive={filters?.hideArchive}
                getOptionPrefix={getOptionPrefix}
                disabled={disabled}
            />
            {selectedId && (
                <LocationDrawer
                    locationId={selectedId}
                    onClose={() => setSelectedId(undefined)}
                    archiveLocation={archiveAction}
                    deleteLocation={deleteAction}
                    editLocation={(location) => {
                        if (closeDrawerOnEdit) {
                            setSelectedId(undefined);
                        }
                        editAction(location);
                    }}
                    isEntityActionsLoading={isEntityActionsLoading}
                    refetchList={refetch}
                />
            )}
        </>
    );
};
export default LocationDetailedSelect;
