import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError } from 'axios';
import { DeleteLocationArgs, DeleteLocationsArgs, ListLocationsArgs, ListLocationsData, TransitLocationsArgs } from './locations.api.types';
import { GetEntityByIdArgs } from '../../../../../../components/page/entityGrid/types/api';
import { LocationInfoRead, serverApi } from '../../../../../../server';
import { showNotification } from '../../../../../../components/notification/showNotification';
import { axiosBaseQuery } from '../../../../../../store/rtqQueryApi';
import { IRootState } from '../../../../../../shared/reducers';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { LocationsUtils } from './locations.api.utils';

export const getLocationByIdQueryFunction = async (args: GetEntityByIdArgs) => {
    const { businessAccountId, entityId } = args;

    try {
        const { data: location } = await serverApi.getLocationById(businessAccountId, entityId);
        return { data: location };
    } catch (error: unknown) {
        showNotification('error', 'Не удалось загрузить локацию');
        const errorMessage = error instanceof Error ? error.message : undefined;
        throw new Error(errorMessage);
    }
};

export const locationsApi = createApi({
    reducerPath: 'locationsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['LocationsList', 'Location'],
    endpoints: (builder) => ({
        listLocations: builder.query<ListLocationsData, ListLocationsArgs>({
            queryFn: async ({ businessAccountId, params }) => {
                const startFrom = ((params?.page ?? 1) - 1) * (params?.limit ?? 1);
                const filters: string[] = LocationsUtils.createRequestFilters(params);

                try {
                    const { data: locations } = await serverApi.listLocations(
                        businessAccountId,
                        params.search,
                        params.limit,
                        startFrom,
                        params.sortBy || undefined,
                        params.sortOrder,
                        filters
                    );
                    return {
                        data: {
                            entitiesData: locations,
                        },
                    };
                } catch (e) {
                    return {
                        error: e as AxiosError,
                    };
                }
            },
            providesTags: ['LocationsList'],
        }),
        locationById: builder.query<LocationInfoRead, GetEntityByIdArgs>({
            queryFn: getLocationByIdQueryFunction,
            providesTags: ['Location'],
        }),
        archiveLocations: builder.mutation<void, TransitLocationsArgs>({
            queryFn: async (args, { getState }) => {
                const { workflowDirectiveBulk } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.transitLocationWorkflowBulk(businessAccountId, workflowDirectiveBulk);
                    const notificationMessage =
                        workflowDirectiveBulk.directives.length > 1 ? 'Локации успешно изменены' : 'Локация успешно изменена';
                    showNotification('success', notificationMessage);

                    return { data: undefined };
                } catch (error: unknown) {
                    const notificationMessage =
                        workflowDirectiveBulk.directives.length > 1 ? 'Не удалось изменить локации' : 'Не удалось изменить локацию';
                    showNotification('error', notificationMessage);

                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['LocationsList', 'Location'],
        }),
        deleteLocations: builder.mutation<void, DeleteLocationsArgs>({
            queryFn: async (args, { getState }) => {
                const { versionedEntityObjList } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.deleteLocations(businessAccountId, versionedEntityObjList);
                    const notificationMessage =
                        versionedEntityObjList.entities.length > 1 ? 'Локации успешно удалены' : 'Локация успешно удалена';
                    showNotification('success', notificationMessage);

                    return { data: undefined };
                } catch (error: unknown) {
                    const notificationMessage =
                        versionedEntityObjList.entities.length > 1 ? 'Не удалось удалить локации' : 'Не удалось удалить локацию';
                    showNotification('error', notificationMessage);

                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['LocationsList', 'Location'],
        }),
        deleteLocation: builder.mutation<void, DeleteLocationArgs>({
            queryFn: async (args, { getState }) => {
                const { id, businessVersionObj } = args;
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    await serverApi.deleteLocationById(businessAccountId, id, businessVersionObj);
                    showNotification('success', 'Локация удалена');

                    return { data: undefined };
                } catch (error: unknown) {
                    showNotification('error', 'Локация не удалена');

                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['LocationsList', 'Location'],
        }),
    }),
});

export const {
    useListLocationsQuery,
    useLazyListLocationsQuery,
    useLocationByIdQuery,
    useLazyLocationByIdQuery,
    useArchiveLocationsMutation,
    useDeleteLocationsMutation,
    useDeleteLocationMutation,
} = locationsApi;
