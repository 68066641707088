import React, {FC, ReactNode, useState} from 'react';
import {ChangeElementsParamPopover} from "../changeElementsParamPopover";
import {useAppDispatch, useAppSelector} from "../../../../../../../store/hooks";
import {isDefined} from "../../../../../../../shared/util/utils";
import {Popover} from "./popover";
import {DateTimePicker} from "../../../../../../../components/dateTimePicker/dateTimePicker";
import {reloadAllTimetables, setDatesForSelectedIds} from "../../../../reducers/operationForm.reducer";
import moment, {Moment} from "moment";
import {RangePicker} from "../../../../../../../components/v2/calendar/rangePicker/rangePicker";

interface ChangeElementsDateParamPopoverProps1 {
    mode: 'startDate' | 'endDate' | 'range';
    selectedIds: number[];
    onClose?: ()=>void;
}

export const ChangeElementsDateParamPopover: FC<ChangeElementsDateParamPopoverProps1> = ({children, mode, selectedIds, onClose}) => {

    return (
        <ChangeElementsParamPopover
            component={ChangeElementsShiftCountPopoverContent}
            componentProps={{mode, onClose }}
            selectedIds={selectedIds}
        >
            {children}
        </ChangeElementsParamPopover>
    );
};

interface ChangeElementsDateParamPopoverProps {
    mode?: 'startDate' | 'endDate' | 'range';
    selectedIds: number[];
    onClose: () => void;
}

export const ChangeElementsShiftCountPopoverContent: FC<ChangeElementsDateParamPopoverProps> = ({children, selectedIds, onClose, mode}) => {
    const dispatch = useAppDispatch();
    const elements = useAppSelector((state) => state.operationForm.elements.entities);
    const selectedElements = elements.filter((el) => selectedIds.includes(el.id));
    let defaultValue: [Date | undefined, Date | undefined] | undefined;

    let label: ReactNode;
    if (mode === 'startDate') {
        label = 'Начало';
        defaultValue = selectedElements.every((el) => el.rentPeriodStartDate.valueOf() === selectedElements[0].rentPeriodStartDate.valueOf()) ? [selectedElements[0].rentPeriodStartDate, undefined] : undefined;
    } else if (mode === 'endDate') {
        label = 'Завершение';
        defaultValue = selectedElements.every((el) => el.rentPeriodEndDate.valueOf() === selectedElements[0].rentPeriodEndDate.valueOf()) ? [undefined, selectedElements[0].rentPeriodEndDate] : undefined;
    } else if (mode === 'range') {
        label = 'Период';
        const stD = selectedElements.every((el) => el.rentPeriodStartDate.valueOf() === selectedElements[0].rentPeriodStartDate.valueOf()) ? selectedElements[0].rentPeriodStartDate : undefined;
        const eD = selectedElements.every((el) => el.rentPeriodEndDate.valueOf() === selectedElements[0].rentPeriodEndDate.valueOf()) ? selectedElements[0].rentPeriodEndDate : undefined;
        if(stD && eD) defaultValue = [stD, eD];
        else defaultValue = undefined;
    }

    const [value, setValue] = useState<[Date | undefined, Date | undefined] | undefined>(defaultValue);
    const canApply = isDefined(value);

    let v: Moment | [Moment, Moment] | undefined;

    if (value) {
        if (mode === "range") {
            v = [moment(value[0]), moment(value[1])];
        } else if (mode === 'startDate') {
            v = moment(value[0]);
        } else if (mode === 'endDate') {
            v = moment(value[1]);
        }
    }

    return (
        <Popover
            label={label}
            input={
                mode === "range" ? <RangePicker placeholder={['Начало', 'Завершение']} showTime={true} value={v as any} onChange={(v) => {
                    if (v && v[0] && v[1]) {
                        setValue([v[0].toDate(), v[1].toDate()]);
                    } else {
                        setValue(undefined);
                    }
                    // TODO XXX
                }} pricingScheme={undefined}/> : <DateTimePicker value={v as any} onChange={(v) => {
                    //
                    if (v) {
                        if (mode === 'startDate') {
                            setValue([v.toDate(), undefined]);
                        } else if (mode === 'endDate') {
                            setValue([undefined, v.toDate()]);
                        }
                    } else {
                        setValue(undefined);
                    }

                }}/>
            }
            canApply={canApply}
            onApply={() => {
                if (isDefined(value)) {
                    onClose?.();
                    dispatch(setDatesForSelectedIds(selectedIds, value));
                    dispatch(reloadAllTimetables());
                }
            }}
            onCancel={() => {
                onClose?.();
            }}
        />
    );
};
