import { TransportationInfoRead } from '../../../../../../../server';
import { useAppSelector } from '../../../../../../../store/hooks';
import {
    DataDisplayCounterpartyProps,
    DataDisplayDateProps,
    DataDisplayDateWithTimeProps,
    DataDisplayDrawerProps,
    DataDisplayItemProps,
    DataDisplayStringProps,
} from '../../../../../../../components/dataDisplay/dataDisplayItemProps';
import { DataDisplayItemType } from '../../../../../../../components/dataDisplay';
import { useMemo } from 'react';
import moment from 'moment/moment';
import { DrawerEntityId } from '../../hooks/useIdForDrawer';
import {printUserName} from "../../../../../../../shared/util/utils";

export const useDisplayItems = (props: {
    transportation?: TransportationInfoRead;
    setLocationIdForDrawer: (entityId: DrawerEntityId) => void;
    setContactIdForDrawer: (entityId: DrawerEntityId) => void;
    setVehicleIdForDrawer: (entityId: DrawerEntityId) => void;
}) => {
    const { transportation, setLocationIdForDrawer, setContactIdForDrawer, setVehicleIdForDrawer } = props;

    const businessAccountShortName = useAppSelector((state) => state.businessAccountContacts.entity?.shortName);

    const dateTimeOfLoading: DataDisplayDateWithTimeProps = useMemo(
        () => ({
            label: 'Дата и время погрузки',
            type: DataDisplayItemType.DateWithTime,
            value: transportation?.dateTimeOfLoading,
            utc: true,
        }),
        [transportation?.dateTimeOfLoading]
    );

    const loadingLocation: DataDisplayDrawerProps = useMemo(
        () => ({
            label: 'Адрес места погрузки',
            type: DataDisplayItemType.Drawer,
            value: transportation?.loadingLocation
                ? {
                      text: transportation.loadingLocation?.shortName,
                      onClick: () => {
                          setLocationIdForDrawer(transportation.loadingLocation.id);
                      },
                  }
                : undefined,
        }),
        [setLocationIdForDrawer, transportation?.loadingLocation]
    );

    const shipper: DataDisplayCounterpartyProps = useMemo(
        () => ({
            id: 'shipper',
            label: 'Грузоотправитель',
            type: DataDisplayItemType.Counterparty,
            value: transportation &&
                transportation.shipper && {
                    baId: transportation.businessAccountId,
                    id: transportation.shipper.id,
                    name: transportation.shipper.shortName,
                },
            emptyValueText: businessAccountShortName,
        }),
        [businessAccountShortName, transportation]
    );

    const receiver: DataDisplayDrawerProps = useMemo(
        () => ({
            label: 'Лицо, осуществляющее погрузку',
            type: DataDisplayItemType.Drawer,
            value: transportation?.receiver
                ? {
                      text: printUserName(transportation.receiver.fullName),
                      onClick: () => {
                          setContactIdForDrawer(transportation.receiver!.contactId);
                      },
                  }
                : undefined,
        }),
        [setContactIdForDrawer, transportation?.receiver]
    );

    const dateTimeOfUnloading: DataDisplayDateWithTimeProps = useMemo(
        () => ({
            label: 'Дата и время выгрузки',
            type: DataDisplayItemType.DateWithTime,
            value: transportation?.dateTimeOfUnloading,
            utc: true,
        }),
        [transportation?.dateTimeOfUnloading]
    );

    const consignee: DataDisplayCounterpartyProps = useMemo(
        () => ({
            id: 'consignee',
            label: 'Грузополучатель',
            type: DataDisplayItemType.Counterparty,
            value: transportation &&
                transportation?.consignee && {
                    baId: transportation.businessAccountId,
                    id: transportation.consignee.id,
                    name: transportation.consignee.shortName,
                },
            emptyValueText: businessAccountShortName,
        }),
        [businessAccountShortName, transportation]
    );

    const unloadingLocation: DataDisplayDrawerProps = useMemo(
        () => ({
            label: 'Адрес места выгрузки',
            type: DataDisplayItemType.Drawer,
            value: transportation?.unloadingLocation
                ? {
                      text: transportation.unloadingLocation?.shortName,
                      onClick: () => {
                          setLocationIdForDrawer(transportation.unloadingLocation.id);
                      },
                  }
                : undefined,
        }),
        [setLocationIdForDrawer, transportation?.unloadingLocation]
    );

    const recipient: DataDisplayDrawerProps = useMemo(
        () => ({
            label: 'Контакт получателя',
            type: DataDisplayItemType.Drawer,
            value: transportation?.recipient
                ? {
                      text: printUserName(transportation.recipient.fullName),
                      onClick: () => {
                          setContactIdForDrawer(transportation.recipient!.contactId);
                      },
                  }
                : undefined,
        }),
        [setContactIdForDrawer, transportation?.recipient]
    );

    const cargoDeliveryLocation: DataDisplayDrawerProps = useMemo(
        () => ({
            label: 'Адрес доставки',
            type: DataDisplayItemType.Drawer,
            value: transportation?.cargoDeliveryLocation
                ? {
                      text: transportation.cargoDeliveryLocation.shortName,
                      onClick: () => {
                          setLocationIdForDrawer(transportation.cargoDeliveryLocation!.id);
                      },
                  }
                : undefined,
        }),
        [setLocationIdForDrawer, transportation?.cargoDeliveryLocation]
    );

    const car: DataDisplayDrawerProps = useMemo(
        () => ({
            label: 'Автомобиль',
            type: DataDisplayItemType.Drawer,
            value: transportation?.car
                ? {
                      text: transportation.car.shortName,
                      onClick: () => {
                          setVehicleIdForDrawer(transportation.car?.id);
                      },
                  }
                : undefined,
        }),
        [setVehicleIdForDrawer, transportation?.car]
    );

    const truck: DataDisplayDrawerProps = useMemo(
        () => ({
            label: 'Прицеп',
            type: DataDisplayItemType.Drawer,
            value: transportation?.trailer
                ? {
                      text: transportation.trailer.shortName,
                      onClick: () => {
                          setVehicleIdForDrawer(transportation.trailer!.id);
                      },
                  }
                : undefined,
        }),
        [setVehicleIdForDrawer, transportation?.trailer]
    );

    const drivers: DataDisplayDrawerProps = useMemo(
        () => ({
            label: 'Водители',
            type: DataDisplayItemType.Drawer,
            value:
                transportation?.drivers && transportation.drivers.length > 0
                    ? transportation?.drivers?.map((driver) => ({
                          text: printUserName(driver.fullName),
                          onClick: () => {
                              setContactIdForDrawer(driver.contactId);
                          },
                      }))
                    : undefined,
        }),
        [setContactIdForDrawer, transportation?.drivers]
    );

    const cargo: DataDisplayStringProps = useMemo(
        () => ({
            label: 'Груз',
            type: DataDisplayItemType.String,
            value: transportation?.cargo,
        }),
        [transportation?.cargo]
    );

    const requestDate: DataDisplayDateProps = useMemo(
        () => ({
            label: 'Дата заявки (Заказа)',
            type: DataDisplayItemType.Date,
            value: transportation?.requestDate ? moment(transportation.requestDate).toDate() : undefined,
            utc: true,
        }),
        [transportation?.requestDate]
    );

    const transportationDate: DataDisplayDateProps = useMemo(
        () => ({
            label: 'Дата транспортной накладной',
            type: DataDisplayItemType.Date,
            value: transportation?.transportationDate ? moment(transportation.transportationDate).toDate() : undefined,
            utc: true,
        }),
        [transportation?.transportationDate]
    );

    return useMemo(() => {
        return {
            dateTimeOfLoading,
            shipper,
            loadingLocation,
            receiver,
            dateTimeOfUnloading,
            consignee,
            unloadingLocation,
            recipient,
            cargoDeliveryLocation,
            car,
            truck,
            drivers,
            cargo,
            requestDate,
            transportationDate,
        };
    }, [
        car,
        cargo,
        cargoDeliveryLocation,
        consignee,
        dateTimeOfLoading,
        dateTimeOfUnloading,
        drivers,
        loadingLocation,
        receiver,
        recipient,
        requestDate,
        shipper,
        transportationDate,
        truck,
        unloadingLocation,
    ]) satisfies Record<string, DataDisplayItemProps>;
};
