import { LocationInfoRead } from '../../../../../../../server';
import { IModalFormFilters } from '../../../../../../../components/modalForm/components/Header/components/ModalFormFilters/ModalFormFilters';
import { ModalFormDisplayData } from '../../../../../../../components/modalForm/components/Header/types/displayData';
import {
    allFieldsDisplayGroup,
    mainFieldsDisplayGroup,
} from '../../../../../../../components/modalForm/components/Header/data/displayData';

const filtersData: ModalFormDisplayData<keyof LocationInfoRead | 'coordinates'> = [
    allFieldsDisplayGroup,
    {
        ...mainFieldsDisplayGroup,
        fieldsIds: [['shortName', 'address', 'coordinates']],
    },
];

export const createLocationModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'MAIN',
};

export const editLocationModalFilters: IModalFormFilters = {
    data: filtersData,
    defaultValue: 'ALL',
};
