import { combineReducers } from 'redux';
import { reducer as oidc, UserState } from 'redux-oidc';
import { connectRouter, RouterState } from 'connected-react-router';
import system, { SystemState } from './system.reducer';
import scanner, { ScannerState } from './scanner.reducer';
import businessAccount, { BusinessAccountState } from './businessAccount.reducer';
import businessAccountIndicators, { BusinessAccountIndicatorsState } from './businessAccountIndicators.reducer';
import businessAccountPreferences, { BusinessAccountPreferencesState } from './businessAccountPreferences.reducer';
import businessAccountContacts, { BusinessAccountContactsState } from './businessAccountContacts.reducer';
import businessAccounts, { BusinessAccountsState } from './businessAccounts.reducer';
import entities, { EntitiesState } from './entities.reducer';

import dashboard_left, { RentElementsState as RentElementsStateLeft } from '../../modules/main/dashboard/reducers/dashboards.reducer';
import dashboard_right, {
    RentElementsState as RentElementsStateRight,
} from '../../modules/main/dashboard/reducers/dashboards.reducer_right';

import renters, { RentersState } from '../../modules/main/renters/reducers/renters/renters.reducer';
import renter, { RenterState } from '../../modules/main/renters/reducers/renters/renter.reducer';
import contact from '../../modules/main/renters/tabs/contacts/reducers/contact/contact.reducer';
import categories, { CategoriesState } from '../../modules/main/categories/reducers/categories.reducer';
import userSettings, { UserSettingsState } from './userSettings/userSettings.reducer';

import changeInstanceStatus from '../../modules/main/inventory/instances/reducers/product-instanсe.reducer';
import productInstance from '../../modules/main/inventory/instances/reducers/product-instanсe.reducer';
import conditionProductInstance from '../../modules/main/inventory/instances/reducers/product-instanсe.reducer';
import listProductVariants, {
    changeInstanceStatusState,
    conditionProductInstanceState,
    listProductVariantsState,
    productInstanceState,
} from '../../modules/main/inventory/instances/reducers/product-instanсe.reducer';
import elementsCalendar, { ElementsCalendarState } from '../../modules/main/calendar/tabs/elements/reducers/elementsCalendar.reducer';
import activityFramesCalendar, {
    ActivityFramesCalendarState,
} from '../../modules/main/calendar/tabs/activityFrames/reducers/activityFramesCalendar.reducer';
import workPlanningsCalendar, {
    WorkPlanningsCalendarState,
} from '../../modules/main/calendar/tabs/workPlannings/reducers/workPlanningsCalendar.reducer';

import productsInstancesList, {
    productsInstancesListState,
} from '../../modules/main/inventory/instances/reducers/product-instances.reducer';

import history_elements, {
    RentElementsState as CompositionOperations,
} from '../../modules/main/history-elements/reducers/history-elements.reducer';
import userProfilePopover, { UserProfilePopoverState } from '../../components/userPopover/reducers/userPopover.reducer';
import products, { ProductsState } from '../../modules/main/inventory/products/reducers/products.reducer';
import product, { ProductState } from '../../modules/main/inventory/products/reducers/product.reducer';

import kits, { KitsState } from '../../modules/main/inventory/kits/reducers/kits.reducers';
import kit, { KitState } from '../../modules/main/inventory/kits/reducers/kit.reducer';

import components, { ComponentsState } from '../../modules/main/inventory/kits/reducers/components.reducer';

import productVariants, { ProductVariantsState } from '../../modules/main/inventory/variants/reducers/product-variants.reducer';
import productVariant, { ProductVariantState } from '../../modules/main/inventory/variants/reducers/product-variant.reducer';
import projects, { ProjectsState } from '../../modules/main/projects/production/reducers/projects.reducer';
import project, { ProjectState } from '../../modules/main/projects/production/reducers/project.reducer';
import offers, { OffersState } from '../../modules/main/offers/reducers/offers/offers.reducer';
import offer, { OfferState } from '../../modules/main/offers/reducers/offer/offer.reducer';
import simpleOrders, { SimpleOrdersState } from '../../modules/main/projects/simpleOrders/reducers/simpleOrders.reducer';
import simpleOrder, { SimpleOrderState } from '../../modules/main/projects/simpleOrders/reducers/simpleOrder.reducer';
import templates, { TemplatesState } from '../../modules/main/projects/templates/reducers/templates.reducer';
import template, { TemplateState } from '../../modules/main/projects/templates/reducers/template.reducer';

import subrents, { SubrentsState } from '../../modules/main/subrent/shippings/reducers/subrents.reducer';
import subrent, { SubrentState } from '../../modules/main/subrent/shippings/reducers/subrent.reducer';

import userProfile, { UserProfileState } from '../../modules/main/userProfile/reducers/user-profile.reducer';

import { TransportationState } from '../../modules/main/logistics/tabs/transportations/reducers/transportation/transportation.types';
import transportation from '../../modules/main/logistics/tabs/transportations/reducers/transportation/transportation.reducer';
import expenses from '../../modules/main/expenses/reducers/expenses/expenses.reducer';
import expense from '../../modules/main/expenses/reducers/expense/expense.reducer';
import paymentMethods from '../../modules/main/settings/paymentMethods/reducers/paymentMethods/paymentMethods.reducer';
import paymentMethod from '../../modules/main/settings/paymentMethods/reducers/paymentMethod/paymentMethod.reducer';
import workPlanning from '../../modules/main/workPlannings/reducers/workPlanning/workPlanning.reducer';
import location from '../../modules/main/logistics/tabs/locations/reducers/location/location.reducer';
import vehicle from '../../modules/main/logistics/tabs/vehicles/reducers/vehicle/vehicle.reducer';

import profession from '../../modules/main/crew/tabs/professions/reducers/profession/profession.reducer';

import crewMembers from '../../modules/main/crew/tabs/crewMembers/reducers/crewMembers/crewMembers.reducer';
import crewMember from '../../modules/main/crew/tabs/crewMembers/reducers/crewMember/crewMember.reducer';

import systemOptions, { SystemOptionsState } from '../../modules/main/settings/systemOptions/reducers/systemOptions.reducer';
import operations, { OperationsState } from '../../modules/main/operations/reducers/operations.reducer';
import operation, { OperationState } from '../../modules/main/operations/reducers/operation.reducer';
import elements, { RentElementsState } from '../../modules/main/elements/reducers/elements.reducer';
import simpleOrderElements, { RentElementsState1 } from '../../modules/main/elements/reducers/simpleOrderElements.reducer';
import element, { RentElementState } from '../../modules/main/elements/reducers/element.reducer';
import operationForm, { OperationFormState } from '../../modules/main/operationForm/reducers/operationForm.reducer';

import lifecycleEvents from '../../modules/main/history/tabs/lifecycleEvents/reducers/lifecycleEvents.reducer';
import { ListLifecycleEventsState } from '../../modules/main/history/tabs/lifecycleEvents/reducers/lifecycleEvents.types';

import usersBA, { usersBAState } from '../../modules/main/settings/users/reducers/users.reducer';
import userBA, { UserStateBA } from '../../modules/main/settings/users/reducers/user.reducer';

import customFields, { CustomFieldsState } from '../../modules/main/settings/customFields/reducers/customFields.reducer';
import customField, { CustomFieldState } from '../../modules/main/settings/customFields/reducers/customField.reducer';

import documentTemplates, {
    DocumentTemplatesState,
} from '../../modules/main/settings/documentTemplates/reducers/documentTemplates.reducer';
import documentTemplate, { DocumentTemplateState } from '../../modules/main/settings/documentTemplates/reducers/documentTemplate.reducer';

import pricingSchemes, { PricingSchemesState } from '../../modules/main/settings/pricingSchemes/reducers/pricingSchemes.reducer';
import pricingScheme, { PricingSchemeState } from '../../modules/main/settings/pricingSchemes/reducers/pricingScheme.reducer';

import analyticsList, { AnalyticsListState } from '../../modules/main/analytics/reducers/analytics.reducer';
import report, { ReportState } from '../../modules/main/analytics/reducers/report.reducer';
import permissions, { PermissionsState } from './permissions.reducer';

import { contactsApi } from '../../modules/main/renters/tabs/contacts/api/contactsApi';
import globalBusinessAccounts, {
    GlobalBusinessAccountsState,
} from '../../modules/main/businessAccounts/reducers/globalBusinessAccounts.reducer';
import payments, { PaymentsState } from '../../modules/main/payments/reducers/payments.reducer';
import { documentTemplatesApi } from '../../modules/main/settings/documentTemplates2/documentTemplatesApi';
import { inventoryMovementsApi } from '../../modules/main/inventoryMovements/api/inventoryMovementsApi';
import { VehicleState } from '../../modules/main/logistics/tabs/vehicles/reducers/vehicle/vehicle.types';
import { ExpensesState } from '../../modules/main/expenses/reducers/expenses/expenses.types';
import { ExpenseState } from '../../modules/main/expenses/reducers/expense/expense.types';

import { PaymentMethodsState } from '../../modules/main/settings/paymentMethods/reducers/paymentMethods/paymentMethods.types';
import { PaymentMethodState } from '../../modules/main/settings/paymentMethods/reducers/paymentMethod/paymentMethod.types';

import { WorkPlanningState } from '../../modules/main/workPlannings/reducers/workPlanning/workPlanning.types';
import { elementsApi } from '../../modules/main/elements/api/elements.api';
import { ProfessionState } from '../../modules/main/crew/tabs/professions/reducers/profession/types';
import { CrewMembersState } from '../../modules/main/crew/tabs/crewMembers/reducers/crewMembers/types';
import { CrewMemberState } from '../../modules/main/crew/tabs/crewMembers/reducers/crewMember/types';
import { expensesApi } from '../../modules/main/expenses/api/expenses.api';
import { crewMembersApi } from '../../modules/main/crew/tabs/crewMembers/api/crewMembers.api';
import { professionsApi } from '../../modules/main/crew/tabs/professions/api/professions.api';
import { ContactState } from '../../modules/main/renters/tabs/contacts/reducers/contact/types';
import { LocationState } from '../../modules/main/logistics/tabs/locations/reducers/location/types';
import { locationsApi } from '../../modules/main/logistics/tabs/locations/api/locations.api';
import { transportationsApi } from '../../modules/main/logistics/tabs/transportations/api/transportations.api';
import { vehiclesApi } from '../../modules/main/logistics/tabs/vehicles/api/vehicles.api';
import { lifecycleEventsApi } from '../../modules/main/history/tabs/lifecycleEvents/api/lifecycleEvents.api';
import { workPlanningsApi } from '../../modules/main/workPlannings/api/workPlannings.api';
import { tagsApi } from '../../components/select/TagSelect/api/tags.api';
import { operationFormApi } from '../../modules/main/operationForm/api/operationForm.api';
import { productVariantsApi } from '../../modules/main/inventory/variants/api/productVariants.api';
import { productInstancesApi } from '../../modules/main/inventory/instances/api/productInstances.api';
import { activityFramesCalendarApi } from '../../modules/main/calendar/tabs/activityFrames/api/activityFramesCalendar.api';
import { elementsCalendarApi } from '../../modules/main/calendar/tabs/elements/api/elementsCalendar.api';
import { workPlanningsCalendarApi } from '../../modules/main/calendar/tabs/workPlannings/api/workPlanningsCalendar.api';
import { productsApi } from '../../modules/main/inventory/products/api/products.api';
import { offersApi } from '../../modules/main/offers/api/offers.api';
import { paymentMethodsApi } from '../../modules/main/settings/paymentMethods/api/paymentMethods.api';
import { kitsApi } from '../../modules/main/inventory/kits/api/kits.api';
import { dashboardApi } from '../../modules/main/dashboard/api/rent/dashboardApi';
import { legalDetailsApi } from '../../modules/main/legalDetails/api/legalDetails.api';
import { paymentsApi } from '../../modules/main/payments/api/payments.api';
import { summaryScheduleCalendarApi } from '../../modules/main/calendar/tabs/summarySchedule/api/summaryScheduleCalendarApi';
import { addressApi } from '../../modules/main/address/api/address.api';
import { customFieldsApi } from '../../modules/main/settings/customFields/customFieldsApi';
import { usersApi } from '../../modules/main/settings/users/api/usersApi';
import { categoriesApi } from '../../modules/main/categories/api/categoriesApi';
import { projectsApi } from '../../modules/main/projects/api/projects.api';

export interface IRootState {
    readonly oidc: UserState;
    readonly router: RouterState;
    readonly system: SystemState;
    readonly scanner: ScannerState;
    readonly businessAccount: BusinessAccountState;
    readonly businessAccountIndicators: BusinessAccountIndicatorsState;
    readonly businessAccountPreferences: BusinessAccountPreferencesState;
    readonly permissions: PermissionsState;
    readonly businessAccountContacts: BusinessAccountContactsState;
    readonly businessAccounts: BusinessAccountsState;
    readonly entities: EntitiesState;
    readonly dashboard_left: RentElementsStateLeft;
    readonly dashboard_right: RentElementsStateRight;
    readonly renters: RentersState;
    readonly renter: RenterState;
    readonly contact: ContactState;
    readonly userSettings: UserSettingsState;
    readonly productInstance: productInstanceState;
    readonly conditionProductInstance: conditionProductInstanceState;
    readonly changeInstanceStatus: changeInstanceStatusState;
    readonly listProductVariants: listProductVariantsState;
    readonly elementsCalendar: ElementsCalendarState;
    readonly activityFramesCalendar: ActivityFramesCalendarState;
    readonly workPlanningsCalendar: WorkPlanningsCalendarState;
    readonly productsInstancesList: productsInstancesListState;
    readonly history_elements: CompositionOperations;
    readonly categories: CategoriesState;
    readonly products: ProductsState;
    readonly kits: KitsState;
    readonly kit: KitState;
    readonly components: ComponentsState;
    readonly product: ProductState;
    readonly productVariants: ProductVariantsState;
    readonly productVariant: ProductVariantState;
    readonly projects: ProjectsState;
    readonly project: ProjectState;
    readonly offers: OffersState;
    readonly offer: OfferState;
    readonly simpleOrders: SimpleOrdersState;
    readonly simpleOrder: SimpleOrderState;
    readonly lifecycleEvents: ListLifecycleEventsState;

    readonly profession: ProfessionState;

    readonly crewMembers: CrewMembersState;
    readonly crewMember: CrewMemberState;

    readonly subrents: SubrentsState;
    readonly subrent: SubrentState;

    readonly templates: TemplatesState;
    readonly template: TemplateState;

    readonly transportation: TransportationState;

    readonly expense: ExpenseState;
    readonly expenses: ExpensesState;

    readonly paymentMethod: PaymentMethodState;
    readonly paymentMethods: PaymentMethodsState;

    readonly workPlanning: WorkPlanningState;

    readonly location: LocationState;

    readonly vehicle: VehicleState;

    readonly operations: OperationsState;
    readonly operation: OperationState;
    readonly elements: RentElementsState;
    readonly simpleOrderElements: RentElementsState1;
    readonly element: RentElementState;
    readonly operationForm: OperationFormState;
    readonly userProfile: UserProfileState;

    readonly userProfilePopover: UserProfilePopoverState;
    readonly systemOptions: SystemOptionsState;

    // Пользователи бизнес-аккаунта
    readonly usersBA: usersBAState;
    readonly userBA: UserStateBA;

    // Кастомные поля
    readonly customFields: CustomFieldsState;
    readonly customField: CustomFieldState;

    readonly documentTemplates: DocumentTemplatesState;
    readonly documentTemplate: DocumentTemplateState;

    // Ценовые схемы
    readonly pricingSchemes: PricingSchemesState;
    readonly pricingScheme: PricingSchemeState;

    // Аналитика
    readonly analyticsList: AnalyticsListState;
    readonly report: ReportState;

    readonly globalBusinessAccounts: GlobalBusinessAccountsState;
    readonly payments: PaymentsState;
}

const rootReducer = (history) =>
    combineReducers({
        [documentTemplatesApi.reducerPath]: documentTemplatesApi.reducer,
        [customFieldsApi.reducerPath]: customFieldsApi.reducer,
        [inventoryMovementsApi.reducerPath]: inventoryMovementsApi.reducer,
        [productsApi.reducerPath]: productsApi.reducer,
        [kitsApi.reducerPath]: kitsApi.reducer,
        [contactsApi.reducerPath]: contactsApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [locationsApi.reducerPath]: locationsApi.reducer,
        [dashboardApi.reducerPath]: dashboardApi.reducer,
        [vehiclesApi.reducerPath]: vehiclesApi.reducer,
        [paymentsApi.reducerPath]: paymentsApi.reducer,
        [transportationsApi.reducerPath]: transportationsApi.reducer,
        [expensesApi.reducerPath]: expensesApi.reducer,
        [elementsApi.reducerPath]: elementsApi.reducer,
        [professionsApi.reducerPath]: professionsApi.reducer,
        [crewMembersApi.reducerPath]: crewMembersApi.reducer,
        [lifecycleEventsApi.reducerPath]: lifecycleEventsApi.reducer,
        [tagsApi.reducerPath]: tagsApi.reducer,
        [workPlanningsApi.reducerPath]: workPlanningsApi.reducer,
        [operationFormApi.reducerPath]: operationFormApi.reducer,
        [elementsCalendarApi.reducerPath]: elementsCalendarApi.reducer,
        [productVariantsApi.reducerPath]: productVariantsApi.reducer,
        [productInstancesApi.reducerPath]: productInstancesApi.reducer,
        [activityFramesCalendarApi.reducerPath]: activityFramesCalendarApi.reducer,
        [summaryScheduleCalendarApi.reducerPath]: summaryScheduleCalendarApi.reducer,
        [workPlanningsCalendarApi.reducerPath]: workPlanningsCalendarApi.reducer,
        [offersApi.reducerPath]: offersApi.reducer,
        [paymentMethodsApi.reducerPath]: paymentMethodsApi.reducer,
        [legalDetailsApi.reducerPath]: legalDetailsApi.reducer,
        [addressApi.reducerPath]: addressApi.reducer,
        [categoriesApi.reducerPath]: categoriesApi.reducer,
        [projectsApi.reducerPath]: projectsApi.reducer,
        oidc,
        router: connectRouter(history),
        system,
        scanner,
        businessAccount,
        businessAccountIndicators,
        businessAccountPreferences,
        permissions,
        businessAccountContacts,
        businessAccounts,
        entities,

        dashboard_left,
        dashboard_right,

        renters,
        renter,
        contact,
        userSettings,
        history_elements,

        productInstance,
        conditionProductInstance,
        changeInstanceStatus,
        listProductVariants,
        elementsCalendar,
        activityFramesCalendar,
        workPlanningsCalendar,
        productsInstancesList,
        categories,
        products,
        product,
        kits,
        kit,
        components,
        productVariants,
        productVariant,
        projects,
        subrent,
        subrents,
        templates,
        template,
        project,
        offers,
        offer,
        simpleOrders,
        simpleOrder,
        lifecycleEvents,

        profession,

        crewMembers,
        crewMember,

        transportation,
        expense,
        expenses,
        paymentMethod,
        paymentMethods,
        workPlanning,
        location,
        vehicle,

        operations,
        operation,
        elements,
        simpleOrderElements,
        element,
        operationForm,
        userProfile,

        userProfilePopover,
        systemOptions,

        // Пользователи бизнес-аккаунта
        usersBA,
        userBA,

        // Кастомные поля
        customFields,
        customField,

        documentTemplates,
        documentTemplate,

        pricingSchemes,
        pricingScheme,

        //Аналитика
        analyticsList,
        report,

        globalBusinessAccounts,
        payments,
    });

export default rootReducer;
