import React, { ReactNode } from 'react';
import { ActionTypeEnum } from '../../../core/types/ActionTypeEnum';
import { IconsGroup } from '../gridActionsContainer/IconsGroup';
import { SystemIcon, SystemIconType } from '../systemIcon/systemIcon';
import { ActionsPopoverV2 } from '../actionsPopover/ActionsPopoverV2';
import { Icon } from 'antd';
import { IconCheck, IconCubesSolid, IconTrashRestoreSolid } from '../../icons';
import { InventoryMovementIcon } from '../../../modules/main/inventoryMovements/components/inventoryMovementIcon';
import { InventoryMovementEventTypeCodeEnum } from '../../../server';
import { ElementsActionPopover } from '../../../modules/main/elements/elements-action-popover';

export type Action = { type: ActionTypeEnum; disabled?: boolean } | ActionTypeEnum | undefined | null | false;

interface GridRowActionsProps {
    mainActions?: Action[];
    additionalActions?: Action[];
    onAction?: (type: ActionTypeEnum) => void;
    rowData?: object;
    onElementAction?: Function;
}

export const getIconByActionType = (type: ActionTypeEnum, onClick?: (e) => void): ReactNode => {
    if (type === ActionTypeEnum.EDIT_ENTITY) {
        return <SystemIcon type={SystemIconType.edit} onClick={onClick} />;
    } else if (type === ActionTypeEnum.DELETE_ENTITY) {
        return <SystemIcon type={SystemIconType.delete} onClick={onClick} />;
    } else if (type === ActionTypeEnum.PAYMENT_DELETE) {
        return <SystemIcon type={SystemIconType.delete} onClick={onClick} />;
    } else if (type === ActionTypeEnum.PAYMENT_RECOVER) {
        return <Icon component={IconTrashRestoreSolid} style={{ fontSize: 21, color: '#34BFA3' }} onClick={onClick} />;
    } else if (type === ActionTypeEnum.PAYMENT_MAKE) {
        return <Icon component={IconCheck} style={{ fontSize: 21, color: '#34BFA3' }} onClick={onClick} />;
    } else if (type === ActionTypeEnum.REPLACE_ANONYMOUS_INSTANCES_WITH_AVAILABLE__IN_RENT_ELEMENT) {
        return <Icon component={IconCubesSolid} style={{ fontSize: 21, color: '#34BFA3' }} onClick={onClick} />;
    } else if (type === ActionTypeEnum.CREATE_DAMAGE_INVENTORY_MOVEMENT) {
        return <InventoryMovementIcon code={InventoryMovementEventTypeCodeEnum.DAMAGE} onClick={onClick} />;
    } else if (type === ActionTypeEnum.CREATE_LOSS_INVENTORY_MOVEMENT) {
        return <InventoryMovementIcon code={InventoryMovementEventTypeCodeEnum.LOSS} onClick={onClick} />;
    } else if (type === ActionTypeEnum.ELEMENT_ACTIONS) {
        return <SystemIcon type={SystemIconType.rentElementActions} onClick={onClick} />;
    } else return null;
};

export const getLabelByActionType = (type: ActionTypeEnum): ReactNode => {
    if (type === ActionTypeEnum.EDIT_ENTITY) {
        return 'Редактировать';
    } else if (type === ActionTypeEnum.PAYMENT_DELETE) {
        return 'Удалить';
    } else if (type === ActionTypeEnum.PAYMENT_RECOVER) {
        return 'Восстановить';
    } else if (type === ActionTypeEnum.PAYMENT_MAKE) {
        return 'Провести оплату';
    } else if (type === ActionTypeEnum.REPLACE_ANONYMOUS_INSTANCES_WITH_AVAILABLE__IN_RENT_ELEMENT) {
        return 'Заменить доступными';
    } else if (type === ActionTypeEnum.CREATE_DAMAGE_INVENTORY_MOVEMENT) {
        return 'Повреждение';
    } else if (type === ActionTypeEnum.CREATE_LOSS_INVENTORY_MOVEMENT) {
        return 'Потеря';
    } else if (type === ActionTypeEnum.ELEMENT_ACTIONS) {
        return 'Создать операцию';
    } else return null;
};

export const GridRowActions = (props: GridRowActionsProps): any => {
    // Когда передается список для иконок, там если передать undefined, отображается пустое место, сделано специально

    const mainIcons: ReactNode[] = [];
    if (props.mainActions && props.mainActions.length > 0) {
        props.mainActions.forEach((item) => {
            // TODO тут еще заблокировано может быть
            let actionType = item && typeof item === 'object' && 'type' in item ? item.type : item;
            let actionDisabled = item && typeof item === 'object' && 'disabled' in item ? item.disabled : false;
            mainIcons.push(actionType ? getIconByActionType(actionType) : undefined);
        });
    }

    if (props.additionalActions && props.additionalActions.length > 0) {
        let additionalActions = props.additionalActions.filter((item) => item);
        const additionalActionsDisabled =
            additionalActions.length === 0 ||
            additionalActions.every((item) => item && typeof item === 'object' && 'disabled' in item && item.disabled === true);
        mainIcons.push(
            !additionalActionsDisabled ? (
                <GridRowActions1
                    actions={additionalActions}
                    rowData={props.rowData}
                    onAction={(actionType) => props.onAction && props.onAction(actionType)}
                    onElementAction={props.onElementAction}
                >
                    <SystemIcon type={SystemIconType.otherActions} style={{ fontSize: 28 }} onClick={(e) => e.stopPropagation()} />
                </GridRowActions1>
            ) : (
                <SystemIcon
                    type={SystemIconType.otherActions}
                    style={{ fontSize: 28, cursor: 'default', opacity: 0.4 }}
                    onClick={(e) => e.stopPropagation()}
                />
            )
        );
    }

    return <IconsGroup items={mainIcons}></IconsGroup>;
};

interface GridRowActionsProps1 {
    actions: Action[];
    onAction?: (type: ActionTypeEnum) => void;
    onElementAction?: Function;
    children: ReactNode;
    rowData?: object;
}

export const GridRowActions1 = (props: GridRowActionsProps1) => {
    let actions = props.actions.filter((item) => item);

    return (
        <ActionsPopoverV2
            items={actions.map((item) => {
                let actionType = item && typeof item === 'object' && 'type' in item ? item.type : item;
                let actionDisabled = item && typeof item === 'object' && 'disabled' in item ? item.disabled : false;
                return actionType
                    ? {
                          icon: getIconByActionType(actionType),
                          type: actionType,
                          title: getLabelByActionType(actionType),
                          disabled: actionDisabled,
                          onClick: (e) => {
                              e.stopPropagation();
                              if (actionType && actionType !== ActionTypeEnum.ELEMENT_ACTIONS) props.onAction && props.onAction(actionType);
                          },
                          wrapperComponent:
                              actionType === ActionTypeEnum.ELEMENT_ACTIONS && props.rowData && props.onElementAction
                                  ? ElementsActionPopover
                                  : undefined,
                          wrapperComponentProps:
                              actionType === ActionTypeEnum.ELEMENT_ACTIONS && props.rowData && props.onElementAction
                                  ? {
                                        disabled: actionDisabled,
                                        onAction: props.onElementAction,
                                        record: props.rowData,
                                    }
                                  : undefined,
                      }
                    : undefined;
            })}
        >
            {props.children}
        </ActionsPopoverV2>
    );
};
