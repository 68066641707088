import React, { FC } from 'react';
import { DataDisplayBlock, DataDisplayItemType, DataDisplayState } from '../../../../../../components/dataDisplay';
import { Col, Icon, Row } from 'antd';
import { LocalizationEnum } from '../../../../../../localization';
import { formatPercents } from '../../../../../../shared/util/formatPercents';
import { useLocalize } from '../../../../../../core/hooks/useLocalize';
import { defaultTaxCalculationTypeTitle } from '../../../../settings/businessAccountPreferences/businessAccountPreferencesEditModalFieldsData';
import {
    OperationTypeCodeEnum,
    PledgeInfoRead,
    ProjectInfoRead,
    ProjectStateCodeEnum,
    TaxBaseTypeCodeEnum,
} from '../../../../../../server';
import { IconCashRegisterSolid } from '../../../../../../components/icons';
import { LabelValueTable } from '../labelValue/labelValueTable';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { push } from 'connected-react-router';
import { canViewFinancialDataSelector } from '../../../../../../shared/reducers/permissions.reducer';
import { LinkButton2 } from '../../../../../../components/buttons/linkButton2/linkButton2';
import { LinkToPayments } from '../../../../../../components/link/toPayments/LinkToPayments';
import { isDefined } from '../../../../../../shared/util/utils';
import {
    ProjectPageDescriptionAttachmentsBlock,
    ProjectPageDescriptionContractBlock,
} from '../../../../projects/production/page/tabs/description/blocks';
import { loadSimpleOrder } from '../../../../projects/simpleOrders/reducers/simpleOrder.reducer';
import './simpleOrderPageBottomBlock.less';
import { expensesModuleEnabledSelector } from '../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { IRootState } from '../../../../../../shared/reducers';
import PledgeData from '../../../../projects/production/components/Pledge/PledgeData';

interface SimpleOrderPageBottomBlockProps {
    comment?: string;
    taxRate: number;
    taxBaseType?: TaxBaseTypeCodeEnum;
    taxAmount: number;

    totalPrice: number; // Итого
    acceptedPaymentsSum: number; // Принято оплаты
    acceptedPaymentsCount?: number;
    debtSum: number; // Долг
    costOfRent: number; // Стоимость аренды
    costOfExpenses: number; // Стоимость затрат

    orderId?: number;
    orderEntity?: ProjectInfoRead;
    pledge?: PledgeInfoRead;
    state: ProjectStateCodeEnum | undefined;
}

export const SimpleOrderPageBottomBlock: FC<SimpleOrderPageBottomBlockProps> = ({ orderEntity, ...props }) => {
    const L = useLocalize();
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const expensesModuleEnabled = useAppSelector(expensesModuleEnabledSelector);
    const includeBooked = useAppSelector(
        (state: IRootState) => state.businessAccountPreferences.preferences?.includeBookedElementsInProjectSum
    );
    const taxType: string = defaultTaxCalculationTypeTitle[props?.taxBaseType ?? TaxBaseTypeCodeEnum.COSTBEFORETAX];
    const typeCode = useAppSelector((state) => state.operationForm.typeCode);
    const controlsDisabled = typeCode === OperationTypeCodeEnum.EDIT;

    return (
        <div className={'rr-simpleOrderPage-bottomBlock'}>
            <Row gutter={0} type={'flex'}>
                <Col span={24} xxl={12} className={'rr-simpleOrderPage-bottomBlock-contract'}>
                    <div style={{ marginLeft: 8, marginRight: 8 }}>
                        {orderEntity && (
                            <ProjectPageDescriptionContractBlock
                                data={orderEntity}
                                updateParentEntity={() => {
                                    if (props.orderId) {
                                        dispatch(loadSimpleOrder(businessAccountId, props.orderId));
                                    }
                                }}
                            />
                        )}
                        {orderEntity && (
                            <ProjectPageDescriptionAttachmentsBlock
                                data={orderEntity}
                                updateParentEntity={() => {
                                    if (props.orderId) {
                                        dispatch(loadSimpleOrder(businessAccountId, props.orderId));
                                    }
                                }}
                            />
                        )}
                    </div>
                </Col>
                <Col span={24} lg={6} className={'rr-simpleOrderPage-bottomBlock-left'}>
                    <PledgeData
                        data={props.pledge}
                        debtSum={props.debtSum}
                        state={props.state}
                        className="rr-simpleOrderPage-bottomBlock-padding rr-dd-b rr-dd-b-bb"
                        isSimpleOrder={true}
                    />
                    {canViewFinancialData && (
                        <DataDisplayBlock
                            className="rr-simpleOrderPage-bottomBlock-padding"
                            data={[
                                [
                                    {
                                        type: DataDisplayItemType.String,
                                        label: L(LocalizationEnum.PAGE__PROJECTS__CARD__TAXES_SUM),
                                        emptyValueText: L(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_CHARGED),
                                        value: props.taxRate === 0 ? undefined : formatPercents(props.taxRate),
                                        hint: taxType,
                                    },
                                ],
                                [
                                    {
                                        type: DataDisplayItemType.Money,
                                        label: 'Сумма налога',
                                        value: props.taxAmount,
                                    },
                                ],
                            ]}
                            paddings={false}
                        />
                    )}
                </Col>
                {canViewFinancialData && (
                    <Col span={24} lg={6} className={'rr-simpleOrderPage-bottomBlock-price'}>
                        <LabelValueTable
                            data={[
                                expensesModuleEnabled &&
                                    props.orderId && {
                                        label: 'Стоимость аренды',
                                        value: props.costOfRent,
                                    },
                                expensesModuleEnabled &&
                                    props.orderId && {
                                        label: 'Стоимость затрат',
                                        value: props.costOfExpenses,
                                    },
                                {
                                    label: L(LocalizationEnum.ASPECT__GLOBAL__IN_TOTAL),
                                    value: props.totalPrice,
                                },
                                props.orderId && {
                                    label: 'Принято оплаты',
                                    value: props.acceptedPaymentsSum,
                                    extra: isDefined(props.acceptedPaymentsCount) ? (
                                        <div style={{ marginTop: -6 }}>
                                            <LinkToPayments
                                                paymentsCount={props.acceptedPaymentsCount}
                                                params={{ projectId: props.orderId }}
                                            />
                                        </div>
                                    ) : undefined,
                                },
                                props.orderId && {
                                    label: L(LocalizationEnum.PAGE__PROJECTS__CARD__DEBT_SUM),
                                    value: props.debtSum,
                                    zeroText: 'Нет',
                                    description:
                                        props.debtSum < 0 ? (
                                            <span style={{ fontWeight: 400, fontSize: 15, marginLeft: 4 }}>(Переплата)</span>
                                        ) : undefined,
                                    state:
                                        props.debtSum < 0
                                            ? DataDisplayState.Problem
                                            : orderEntity?.stateCode === ProjectStateCodeEnum.DRAFT ||
                                              orderEntity?.stateCode === ProjectStateCodeEnum.CANCELED ||
                                              (orderEntity?.stateCode === ProjectStateCodeEnum.BOOKED && !includeBooked)
                                            ? DataDisplayState.Muted
                                            : props.debtSum === 0
                                            ? DataDisplayState.Success
                                            : orderEntity?.stateCode === ProjectStateCodeEnum.FINISHED
                                            ? DataDisplayState.Problem
                                            : undefined,
                                    extra: (
                                        <>
                                            {orderEntity?.stateCode === ProjectStateCodeEnum.DRAFT && !!props.debtSum ? (
                                                <div className="rr-value-hint">(Не учитывается, черновик)</div>
                                            ) : null}
                                            {orderEntity?.stateCode === ProjectStateCodeEnum.CANCELED && !!props.debtSum ? (
                                                <div className="rr-value-hint">(Не учитывается, отменен)</div>
                                            ) : null}
                                            {orderEntity?.stateCode === ProjectStateCodeEnum.BOOKED && !includeBooked ? (
                                                <div className="rr-value-hint">(Не учитывается, статус бронь)</div>
                                            ) : null}
                                            {/*{orderEntity?.debtSum && orderEntity.debtSum < 0 ? (*/}
                                            {/*    <div className="rr-value-hint" style={{ color: '#f4516c' }}>*/}
                                            {/*        (Переплата)*/}
                                            {/*    </div>*/}
                                            {/*) : null}*/}
                                        </>
                                    ),
                                },
                            ]}
                        />
                        {props.orderId && props.state !== ProjectStateCodeEnum.CANCELED && props.state !== ProjectStateCodeEnum.DRAFT && (
                            <div style={{ marginTop: 30, textAlign: 'right' }}>
                                <LinkButton2
                                    disabled={controlsDisabled}
                                    icon={<Icon style={{ color: '#ffcd43', fontSize: 21 }} component={IconCashRegisterSolid} />}
                                    label={L(LocalizationEnum.PAGE__PROJECTS__ACTIONS__ACCEPT_PAYMENT)}
                                    onClick={() => {
                                        dispatch(push(`/${businessAccountId}/projects/simpleOrders/${props.orderId}/pay/?tab=description`));
                                    }}
                                />
                            </div>
                        )}
                    </Col>
                )}
            </Row>
        </div>
    );
};
