import React, { ReactNode } from 'react';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { DataDisplayItemType } from './dataDisplayItemType';
import { IconEnvelope, IconHourglassSolid, IconPhoneSolid } from '../icons';
import { DataDisplayItemProps, DrawerValue } from './dataDisplayItemProps';
import './dataDisplay.less';
import moment from 'moment';
import { printUserName } from '../../shared/util/utils';
import { formatMoney } from '../../shared/util/formatMoney';
import { InstancesWidget } from '../../modules/main/elements/components/ElementPopover/instances-widget/InstancesWidget';
import { DataDisplayState } from './dataDisplayState';
import { RichTextView } from '../richTextView/RichTextView';
import { PricingSchemePricePopoverWrapper } from '../../modules/main/crew/tabs/professions/columns/components/PricingSchemePricePopoverWrapper';
import { convertMinutesToHoursMinString } from '../../shared/util/getDateString';
import { useSelector } from 'react-redux';
import { IRootState } from '../../shared/reducers';
import { PricingSchemePricePopover } from '../../modules/main/settings/pricingSchemes/pricingSchemePricePopover';
import { PricingSchemeMaps } from '../../core/utils/pricingSchemeUtils';
import FormValue from '../formValue/FormValue';
import { useStateBadge } from '../v2/stateBadge/stateBadge';
import { RentStateCodeEnum, SocialNetworkTypeCodeEnum } from '../../server';
import { Color } from '../../core/types/color';
import { InlineMoneyInput } from '../inlineInputs/InlineMoneyInput/InlineMoneyInput';
import { ContactMaps } from '../../modules/main/renters/tabs/contacts/utils/maps';
import InlineTextarea from '../inlineInputs/InlineTextarea/InlineTextarea';

export const ITEM_CLASSNAME = 'rr-dd-i';
export const LABEL_CLASSNAME = 'rr-dd-i-l';
export const EMPTY_VALUE_CLASSNAME = 'rr-dd-i-ev';
export const VALUE_CLASSNAME = 'rr-dd-i-v';
export const HINT_CLASSNAME = 'rr-dd-i-h';

export const DataDisplayItem = (props: DataDisplayItemProps) => {
    const defaultShiftLengthInMinutes = useSelector(
        (state: IRootState) => state.businessAccountPreferences.preferences?.shiftLengthInMinutes
    );

    const stateBadge = useStateBadge();
    const createRentStateBadge = (type: RentStateCodeEnum.DRAFT) => {
        return stateBadge({
            type: type,
            dot: true,
            style: { display: 'inline-flex', marginBottom: 1 },
            hideLabel: true,
            data: { [RentStateCodeEnum.DRAFT]: { name: '', color: Color.WhiteGrey1 } },
        });
    };

    const renderMoney = (
        price: number,
        rentType?: RentStateCodeEnum.DRAFT,
        editable?: boolean,
        onChange?: (value?: number) => void,
        onBeforeBlur?: (value: number | undefined) => Promise<boolean> | void,
        max?: number
    ) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {rentType && createRentStateBadge(rentType)}
            <span>
                {editable ? (
                    <InlineMoneyInput
                        value={price}
                        onChange={(v) => {
                            onChange?.(v);
                        }}
                        onBeforeBlur={onBeforeBlur}
                        max={max}
                    />
                ) : (
                    formatMoney(price)
                )}
            </span>
        </div>
    );

    let label = props.label;
    const hint = props.hint;
    let extraClassNames: string[] = [];
    let value: ReactNode;
    let themeClassName = props.theme && `${ITEM_CLASSNAME}-${props.theme}`;

    if (props.type === DataDisplayItemType.String) {
        if (props.editable) {
            value = (
                <InlineTextarea
                    value={props.value}
                    onBeforeBlur={props.onBeforeBlur}
                    emptyValueText={props.emptyText}
                    emptyValueClassName={EMPTY_VALUE_CLASSNAME}
                    className="rr-dd-i-v"
                    isError={props.isError}
                    maxLength={props.maxLength}
                />
            );
        } else if (props.value !== undefined && props.value !== null && props.value !== '') {
            extraClassNames = props.extraClassNames ?? [VALUE_CLASSNAME];
            value = props.value;
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = props.emptyValueText || 'Не указано';
        }
    } else if (props.type === DataDisplayItemType.Number) {
        if (props.value !== undefined && props.value !== null && props.value !== 0) {
            extraClassNames = [VALUE_CLASSNAME];
            value = props.value;
        } else if (props.value === 0) {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = props.value;
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = props.emptyValueText || 'Не указано';
        }
    } else if (props.type === DataDisplayItemType.Boolean) {
        if (props.value === true) {
            extraClassNames = [VALUE_CLASSNAME];
            value = props.values[1];
        } else if (props.value === false) {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = props.values[0];
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = props.emptyValueText || 'Не указано';
        }
    } else if (props.type === DataDisplayItemType.User) {
        if (props.value && props.value.id && props.value.baId && props.value.name) {
            extraClassNames = [VALUE_CLASSNAME];
            value = <Link to={`/${props.value.baId}/users/${props.value.id}`}>{printUserName(props.value.name)}</Link>;
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = 'Не указан'; // Не указано
        }
    } else if (props.type === DataDisplayItemType.Comment) {
        if (props.value) {
            extraClassNames = [VALUE_CLASSNAME, 'rr-dd-i-v-comment'];
            value = props.value;
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = 'Не указан'; // Не указано
        }
    } else if (props.type === DataDisplayItemType.EMail) {
        if (props.value) {
            extraClassNames = [VALUE_CLASSNAME, 'rr-dd-iv-email'];
            value = (
                <a href={'mailto:' + props.value}>
                    <span>{props.value}</span> <Icon className={'rr-dd-iv-email-icon'} component={IconEnvelope} />
                </a>
            );
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = 'Не указан'; // Не указано
        }
    } else if (props.type === DataDisplayItemType.Phone) {
        if (props.value) {
            extraClassNames = [VALUE_CLASSNAME, 'rr-dd-iv-phone'];
            value = (
                <a href={'tel:' + props.value}>
                    <span>{props.value}</span>
                    <Icon className={'rr-dd-iv-phone-icon'} component={IconPhoneSolid} />
                </a>
            );
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = 'Не указан'; // Не указано
        }
    } else if (props.type === DataDisplayItemType.Date) {
        if (props.value) {
            extraClassNames = [VALUE_CLASSNAME];
            const d = props.utc ? moment.utc(props.value) : moment(props.value);
            const date = d.format('DD MMMM YYYY');
            value = date;
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = 'Не указана'; // Не указано
        }
    } else if (props.type === DataDisplayItemType.DateWithTime) {
        if (props.value) {
            extraClassNames = [VALUE_CLASSNAME];
            const d = props.utc ? moment.utc(props.value) : moment(props.value);
            const date = d.format('DD MMMM YYYY');
            const time = d.format('HH:mm');
            value = (
                <>
                    {date} <span style={{ fontWeight: 500 }}>{time}</span>
                </>
            );
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = 'Не указана'; // Не указано
        }
    } else if (props.type === DataDisplayItemType.DateWithTimeInterval) {
        if (props.value && (props.value.from || props.value.to)) {
            extraClassNames = [VALUE_CLASSNAME];

            const printDate = (d: Date | string): ReactNode => {
                const _d = moment(d);
                const date = _d.format('DD.MM.YYYY');
                const time = _d.format('HH:mm');
                return (
                    <span>
                        {date} <span style={{ fontWeight: 500 }}>{time}</span>
                    </span>
                );
            };

            let from: ReactNode, to: ReactNode;
            if (props.value.from)
                from = (
                    <span
                        style={{ fontWeight: 'bold' }}
                        className={props.highlight && props.highlight.includes('start') ? 'rr-date-value-highlighted' : undefined}
                    >
                        {printDate(props.value.from)}
                    </span>
                );
            else from = <span className={EMPTY_VALUE_CLASSNAME}>{props.emptyValueText || 'Не указано'}</span>;

            if (props.value.to)
                to = (
                    <span
                        style={{ fontWeight: 'bold' }}
                        className={props.highlight && props.highlight.includes('end') ? 'rr-date-value-highlighted' : undefined}
                    >
                        {printDate(props.value.to)}
                    </span>
                );
            else to = <span className={EMPTY_VALUE_CLASSNAME}>{props.emptyValueText || 'Не указано'}</span>;

            value = (
                <>
                    {from} <span style={{ marginTop: -1, verticalAlign: 'top', display: 'inline-block' }}>→</span> {to}
                </>
            );
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = props.emptyValueText || 'Не указан';
        }
    } else if (props.type === DataDisplayItemType.Money) {
        if (props.value !== undefined && props.value !== null && props.value !== 0) {
            extraClassNames = [VALUE_CLASSNAME];
            value = renderMoney(props.value, props.rentType, props.editable, props.onChange, props.onBeforeBlur, props.max);
        } else if (props.value === 0) {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = renderMoney(props.value, props.rentType, props.editable, props.onChange, props.onBeforeBlur, props.max);
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = props.emptyValueText || 'Не указана'; // Не указано
        }
    } else if (props.type === DataDisplayItemType.Price) {
        let pricingSchemeEl: ReactNode = null;
        const { pricingSchemeData } = props;
        if (pricingSchemeData) {
            const { pricingScheme } = pricingSchemeData;
            pricingSchemeEl = (
                <PricingSchemePricePopover
                    data={{
                        id: pricingScheme.id,
                        typeCode: pricingScheme?.typeCode,
                        label: pricingScheme.name || '',
                        price: pricingSchemeData.basePrice,
                        steps: pricingScheme.steps,
                        shiftCount: pricingSchemeData.shiftCount,
                        roundingDirection: pricingScheme.roundingDirection,
                        roundingLevel: pricingScheme.roundingLevel,
                    }}
                >
                    <Icon
                        style={{
                            fontSize: 22,
                            color: '#3d64d4',
                            marginRight: 4,
                            marginTop: -5,
                            verticalAlign: 'middle',
                        }}
                        component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[pricingScheme.typeCode]}
                    />
                </PricingSchemePricePopover>
            );
        }

        if (props.value !== undefined && props.value !== null && props.value !== 0) {
            extraClassNames = [VALUE_CLASSNAME];
            value = (
                <>
                    {pricingSchemeEl}
                    {formatMoney(props.value)}
                </>
            );
        } else if (props.value === 0) {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = (
                <>
                    {pricingSchemeEl}
                    {formatMoney(props.value)}
                </>
            );
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = props.emptyValueText || 'Не указана'; // Не указано
        }
    } else if (props.type === DataDisplayItemType.Counterparty) {
        if (props.value) {
            extraClassNames = [VALUE_CLASSNAME];
            value = <Link to={`/${props.value.baId}/crm/counterparties/${props.value.id}`}>{props.value.name}</Link>;
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = props.emptyValueText || 'Не указан'; // Не указано
        }
    } else if (props.type === DataDisplayItemType.Project) {
        if (props.value) {
            extraClassNames = [VALUE_CLASSNAME];
            value = (
                <Link onClick={props.value.onClick} to={`/${props.value.baId}/projects/production/${props.value.id}`}>
                    {props.value.name}
                </Link>
            );
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = 'Не указан'; // Не указано
        }
    } else if (props.type === DataDisplayItemType.Product) {
        if (props.value) {
            extraClassNames = [VALUE_CLASSNAME];
            value = (
                <>
                    <Link to={`/${props.value.baId}/inventory/products/${props.value.productId}`}>
                        {props.value.productName}
                        {props.value.variantId && ': '}
                        {props.value.variantId && <span style={{ fontWeight: 500 }}>{props.value.variantName}</span>}
                    </Link>
                </>
            );
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = 'Не указан'; // Не указано
        }
    } else if (props.type === DataDisplayItemType.InstancesList) {
        if (props.value && props.value.length > 0) {
            extraClassNames = [VALUE_CLASSNAME, 'rr-dd-i-v-instances-list'];
            value = props.value.map((instance, index) => {
                return <InstancesWidget key={index} entity={instance} />;
            }); // TODO Красиво выводить
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = 'Нет'; // Не указаны
        }
    } else if (props.type === DataDisplayItemType.IconText) {
        if (props.value) {
            extraClassNames = [VALUE_CLASSNAME];
            value = (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 6, fontSize: 21, lineHeight: '21px' }}>{props.value.icon}</span>
                    <span>{props.value.text}</span>
                </div>
            );
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = 'Нет'; // Не указаны
        }
    } else if (props.type === DataDisplayItemType.Link) {
        if (props.value && props.value.text) {
            extraClassNames = [VALUE_CLASSNAME];
            if (props.value.isRoute) {
                value = (
                    <Link onClick={props.value.onClick} to={props.value.link}>
                        {props.value.text}
                    </Link>
                );
            } else {
                value = (
                    <a onClick={props.value.onClick} target="_blank" href={props.value.link} rel="noreferrer">
                        {props.value.text}
                    </a>
                );
            }
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = props.emptyValueText || 'Не указано';
        }
    } else if (props.type === DataDisplayItemType.SocialNetworkLink) {
        if (props.value?.value) {
            extraClassNames = [VALUE_CLASSNAME];
            const iconObj = ContactMaps.socialNetworkIconsMap[props.value.type];
            let link = '';
            let userName = props.value.value;

            if (props.value.type === SocialNetworkTypeCodeEnum.VK) link = 'https://vk.com/' + props.value.value;
            if (props.value.type === SocialNetworkTypeCodeEnum.TELEGRAM) link = 'https://t.me/' + props.value.value;
            if (props.value.type === SocialNetworkTypeCodeEnum.INSTAGRAM) link = 'https://instagram.com/' + props.value.value;
            if (props.value.type === SocialNetworkTypeCodeEnum.FACEBOOK) link = 'https://fb.com/' + props.value.value;
            if (props.value.type === SocialNetworkTypeCodeEnum.INTERNETPAGE) {
                link = props.value.value;
                if (!link.includes('//')) link = '//' + link;
                userName = props.value.value;
                if (userName.includes('//')) {
                    userName = userName.split('//')[1];
                }
            }

            value = (
                <a target="_blank" href={link} rel="noreferrer">
                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <Icon
                            className={classNames('rr-dd-i-v-sn-icon', 'rr-dd-i-v-sn-icon-' + iconObj.className)}
                            style={{ marginRight: 6 }}
                            component={iconObj.iconComponent}
                        />
                        <span>{userName}</span>
                    </div>
                </a>
            );
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = props.emptyValueText || 'Не указано';
        }
    } else if (props.type === DataDisplayItemType.Drawer) {
        if (props.value) {
            const DrawerLinkComponent = ({ text, onClick }: DrawerValue) => {
                return (
                    <a
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onClick();
                        }}
                    >
                        {text}
                    </a>
                );
            };

            extraClassNames = [VALUE_CLASSNAME];

            if (Array.isArray(props.value)) {
                if (props.value.length > 0) {
                    value = (
                        <>
                            {props.value.map(({ text, onClick }, index, array) => (
                                <span>
                                    <DrawerLinkComponent text={text} onClick={onClick} />
                                    {index !== array.length - 1 ? ', ' : undefined}
                                </span>
                            ))}
                        </>
                    );
                } else {
                    extraClassNames = [EMPTY_VALUE_CLASSNAME];
                    value = 'Не указано';
                }
            } else {
                value = <DrawerLinkComponent text={props.value.text} onClick={props.value.onClick} />;
            }
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = 'Не указано';
        }
    } else if (props.type === DataDisplayItemType.RichText) {
        if (props.value !== undefined && props.value !== null && props.value !== '') {
            extraClassNames = [VALUE_CLASSNAME];
            value = (
                <div className={'rr-text'}>
                    <FormValue
                        value={
                            <RichTextView
                                value={props.value}
                                style={{
                                    fontSize: 14,
                                    color: '#575962',
                                }}
                            />
                        }
                    />
                </div>
            );
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = props.emptyValueText || 'Не указано';
        }
    } else if (props.type === DataDisplayItemType.PricingScheme) {
        const { pricingScheme, pricePerShift } = props;

        if (pricingScheme != null) {
            extraClassNames = [VALUE_CLASSNAME];
            value = (
                <>
                    <PricingSchemePricePopoverWrapper pricingSchemeId={pricingScheme.id} value={pricePerShift || 0} />
                    {pricingScheme.name}
                </>
            );
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = props.emptyValueText || 'Не указано';
        }
    } else if (props.type === DataDisplayItemType.ShiftDuration) {
        const { durationInMinutes, hasOwnShiftLength } = props;

        if (durationInMinutes != null && hasOwnShiftLength != null) {
            extraClassNames = [VALUE_CLASSNAME];
            value = (
                <>
                    <div>
                        <Icon
                            component={IconHourglassSolid}
                            style={{
                                fontSize: 20,
                                marginRight: 6,
                                marginTop: 2,
                                marginLeft: -4,
                                verticalAlign: 'top',
                                color: hasOwnShiftLength ? '#00afee' : '#aaaaaa',
                            }}
                        />
                        <span style={hasOwnShiftLength ? {} : { fontWeight: 400, color: '#AAAAAA' }}>
                            {convertMinutesToHoursMinString(hasOwnShiftLength ? durationInMinutes : defaultShiftLengthInMinutes)}
                        </span>
                    </div>
                    {!hasOwnShiftLength && (
                        <div
                            style={{
                                fontSize: 11,
                                color: '#aaaaaa',
                                fontWeight: 'normal',
                            }}
                        >
                            (По компании)
                        </div>
                    )}
                </>
            );
        } else {
            extraClassNames = [EMPTY_VALUE_CLASSNAME];
            value = props.emptyValueText || 'Не указано';
        }
    } else if (props.type === DataDisplayItemType.Custom) {
        value = props.value;
    }

    if (props.state) {
        let stateCode;
        if (props.state === DataDisplayState.Problem) stateCode = 'problem';
        else if (props.state === DataDisplayState.Warning) stateCode = 'warning';
        else if (props.state === DataDisplayState.Success) stateCode = 'success';
        if (stateCode) extraClassNames.push(`rr-dd-i-v-${stateCode}`);
    }

    return (
        <div className={classNames(ITEM_CLASSNAME, themeClassName, props.paddings !== false && `${ITEM_CLASSNAME}-p`)} style={props.style}>
            {label ? <div className={classNames(LABEL_CLASSNAME, props.labelClassName)}>{label}</div> : undefined}
            <div className={classNames(extraClassNames, props.valueClassName)}>{value}</div>
            {hint && <span className={HINT_CLASSNAME}>{hint}</span>}
        </div>
    );
};
