export const isLatitude = (value: number | string) => {
    if (isNaN(+value)) return false;
    const num = +value;
    return isFinite(num) && Math.abs(num) <= 90;
};

export const isLongitude = (value: number | string) => {
    if (isNaN(+value)) return false;
    const num = +value;
    return isFinite(num) && Math.abs(num) <= 180;
};
