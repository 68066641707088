import React from 'react';
import { FormFieldsGroup, FormItemType, IFormField, SelectItem } from '../../../../components/dynamicForm/DynamicForm';
import { EntityRemoteSelect } from '../../../../components/select/EntityRemoteSelect';
import debounce from 'lodash/debounce';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { validateField } from '../../../../shared/util/validateField';
import {
    ContactLinkObjWrite,
    ContractValidationTypeCodeEnum,
    CounterpartyActivityTypeCodeEnum,
    ProjectStateCodeEnum,
    RenterStateCodeEnum,
    serverApi,
    SubrentStateCodeEnum,
    UserRecord,
} from '../../../../server';
import { getBusinessAccountId, getStoreState } from '../../../../../index';
import { LocalizationEnum, localize } from '../../../../localization';
import { printUserName } from '../../../../shared/util/utils';
import { Dot } from '../../../../components/dot/dot';
import { toNumber } from '../../../../shared/util/utils5';
import { createHiddenField } from '../../../../components/modalForm/utils';
import {
    CounterpartySelectAndCreate,
    CounterpartySelectAndCreateProps,
} from '../../../../components/v2/select/counterpartySelect/counterpartySelectAndCreate';
import {ContactData, ContactsEditor, ContactsEditorProps} from "../../../../components/contactsEditor/contactsEditor";
import { RangePickerProps } from '../../../../components/v2/calendar/rangePicker/rangePicker';
import { CopyProjectParamsBlock } from '../../projects/production/components/copyProjectParamsBlock/copyProjectParamsBlock';
import { contractValidationTypeSelector } from '../../../../shared/reducers/businessAccountPreferences.reducer';
import { Select } from 'antd';
import UserDetailedOption from '../../../../components/v2/detailedSelect/UserDetailedOption';

export const shortTitleField: IFormField = {
    label: LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE,
    id: 'shortName',
    type: FormItemType.String,
    required: true,
    maxLength: 50,
    validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
        let supplierId = toNumber(form.getFieldValue('supplierId'));
        if (supplierId) {
            const ignoreCurrentId = form.getFieldValue('projectEdit') ? form.getFieldValue('id') : undefined;
            validateField(
                'subrent',
                fieldName,
                value,
                supplierId,
                ignoreCurrentId,
                'Краткое наименование уже используется в текущем арендаторе',
                cb
            );
        } else cb();
    }, 500),
};

export const supplierField: IFormField = {
    label: 'Поставщик',
    id: 'supplierId',
    type: FormItemType.Component,
    required: true,
    component: CounterpartySelectAndCreate,
    disabled: (getFieldValue) => !!getFieldValue('id'),
    componentProps: (form: WrappedFormUtils): CounterpartySelectAndCreateProps => {
        return {
            counterpartyType: 'supplier',
            filters: {
                activityTypeCode: [CounterpartyActivityTypeCodeEnum.SUPPLIERONLY, CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER],
                archive: false,
            },
            isItemDisabled: (record) => {
                const creation = !form.getFieldValue('id');
                const projectStateCode:ProjectStateCodeEnum|undefined = form.getFieldValue('stateCode');
                const disabledStates:RenterStateCodeEnum[] = [];

                if(projectStateCode === ProjectStateCodeEnum.INPROGRESS || projectStateCode === ProjectStateCodeEnum.FINISHED || (projectStateCode === ProjectStateCodeEnum.CANCELED && creation)){
                    disabledStates.push(RenterStateCodeEnum.NEW);
                }
                if(creation && (projectStateCode === ProjectStateCodeEnum.FINISHED || projectStateCode === ProjectStateCodeEnum.CANCELED)){
                    disabledStates.push(RenterStateCodeEnum.ACTIVE);
                }
                if(projectStateCode === ProjectStateCodeEnum.INPROGRESS || (creation && (projectStateCode === ProjectStateCodeEnum.DRAFT || projectStateCode === ProjectStateCodeEnum.FINISHED || projectStateCode === ProjectStateCodeEnum.CANCELED))){
                    disabledStates.push(RenterStateCodeEnum.BLOCKED);
                }
                return disabledStates.includes(record.stateCode);
            },
            onDataChange: async (data) => {
                const value = data?.[0];
                let supplierId = value?.id;

                form.setFieldsValue({ supplierStateCode: value?.stateCode });

                if (value?.stateCode === RenterStateCodeEnum.NEW) {
                    form.setFieldsValue({ stateCode: ProjectStateCodeEnum.DRAFT });
                }

                let counterpartyContactLinks;

                if (supplierId) {
                    let renter = await serverApi.getRenterById(getBusinessAccountId(), supplierId);
                    await form.setFieldsValue({ renterDefaultDiscount: renter.data.defaultDiscount });

                    counterpartyContactLinks = renter.data.contactLinks;

                    if (value) {
                        if (form.getFieldValue('useRenterDefaultDiscount')) {
                            await form.setFieldsValue({ defaultDiscount: form.getFieldValue('renterDefaultDiscount') });
                        }

                        form.validateFields(['shortName']);
                        form.validateFields(['fullName']);

                        if (form.getFieldValue('useAssignee')) {
                            if (renter.data.assigneeFullName) {
                                await form.setFieldsValue({
                                    assigneeId: {
                                        key: renter.data.id,
                                        label: renter.data.assigneeFullName
                                            ? renter.data.assigneeFullName.lastname +
                                              (renter.data.assigneeFullName.firstname ? ' ' + renter.data.assigneeFullName.firstname : '')
                                            : '',
                                    },
                                });
                            } else {
                                await form.setFieldsValue({
                                    assigneeId: undefined,
                                });
                            }
                        }
                    }
                } else {
                    if (form.getFieldValue('useAssignee')) {
                        await form.setFieldsValue({
                            assigneeId: undefined,
                        });
                    }
                }

                form.setFieldsValue({counterpartyContactLinks: counterpartyContactLinks});
                if(form.getFieldValue('hasOwnContacts')){
                    form.setFieldsValue({contactLinks: counterpartyContactLinks});
                }

            },
        };
    },
    placeholder: 'Выберите поставщика',
};

export const statusField: IFormField = {
    label: LocalizationEnum.ASPECT__FILTERS__STATUS,
    placeholder: localize(LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER),
    id: 'stateCode',
    type: FormItemType.Select,
    defaultValue: SubrentStateCodeEnum.INPROGRESS,
    required: true,
    values: (form: WrappedFormUtils) => {
        let values: SelectItem[] = [];
        let supplierStateCode: string = form.getFieldValue('supplierStateCode');

        if (!form.getFieldValue('id')) {
            // Это создание
            const contractValidationType = contractValidationTypeSelector(getStoreState());
            if (
                contractValidationType === ContractValidationTypeCodeEnum.ONLYFILE ||
                contractValidationType === ContractValidationTypeCodeEnum.ALLFIELDS
            ) {
                values = [
                    {
                        name: 'Черновик',
                        value: SubrentStateCodeEnum.DRAFT,
                        disabled: false,
                    },
                ];
            } else {
                values = [
                    {
                        name: 'Черновик',
                        value: SubrentStateCodeEnum.DRAFT,
                        disabled: false,
                    },
                    {
                        name: localize(LocalizationEnum.ASPECT__STATE_CODE__PROJECT__INPROGRESS),
                        value: SubrentStateCodeEnum.INPROGRESS,
                        disabled: supplierStateCode === RenterStateCodeEnum.NEW,
                    },
                ];
            }
        } else if (form.getFieldValue('id')) {
            // Это редактирование
            values = [
                {
                    name: 'Черновик',
                    value: SubrentStateCodeEnum.DRAFT,
                    disabled: true,
                },
                {
                    name: localize(LocalizationEnum.ASPECT__STATE_CODE__PROJECT__INPROGRESS),
                    value: SubrentStateCodeEnum.INPROGRESS,
                    disabled: true,
                },
                {
                    name: localize(LocalizationEnum.ASPECT__STATE_CODE__PROJECT__FINISHED),
                    value: SubrentStateCodeEnum.FINISHED,
                    disabled: true,
                },
                {
                    name: 'Отменена',
                    value: SubrentStateCodeEnum.CANCELED,
                    disabled: true,
                },
            ];
        }

        return values.map((item) => ({
            ...item,
            name: (
                <>
                    <Dot className={`rr-status-bg-` + item.value} style={{ marginRight: 10 }} /> {item.name}
                </>
            ),
        }));
    },
    disabled: (getFieldValue) => !!getFieldValue('id'), // disabled: (getFieldValue) => !(!getFieldValue('id') && !getFieldValue('createFromOperationForm'))
    // onChange: (value: any, form: WrappedFormUtils) => {
    //     form.setFieldsValue({supplierId: undefined});
    // }
};

export const workPeriodField: IFormField = {
    label: 'Период работ',
    id: 'dates',
    type: FormItemType.DateRangePicker,
    componentProps: (): RangePickerProps => ({
        placeholder: ['Начало', 'Завершение'],
        style: { width: 360 },
        pricingScheme: undefined,
        showClearButtonOnRight: true
    }),
};

export const bySupplierField: IFormField = {
    label: 'По поставщику',
    id: 'useAssignee',
    type: FormItemType.Switch,
    defaultValue: true,
    labelCol: { span: 5 },
    wrapperCol: { span: 12 },
    onChange: async (value: any, form: WrappedFormUtils) => {
        if (value) {
            if (form.getFieldValue('renterDefaultAssignee')) {
                form.setFieldsValue({ assigneeId: form.getFieldValue('renterDefaultAssignee') });
            } else {
                if (form.getFieldValue('supplierId')) {
                    let supplierId = form.getFieldValue('supplierId');
                    let renter = await serverApi.getRenterById(getBusinessAccountId(), supplierId);

                    if (renter.data.assigneeFullName) {
                        form.setFieldsValue({
                            assigneeId: {
                                key: renter.data.id,
                                label: printUserName(renter.data.assigneeFullName),
                            },
                        });
                    } else {
                        form.setFieldsValue({ assigneeId: undefined });
                    }
                } else {
                    form.setFieldsValue({ assigneeId: undefined });
                }
            }
        } else {
            if (form.getFieldValue('defaultAssignee')) {
                form.setFieldsValue({ assigneeId: form.getFieldValue('defaultAssignee') });
            }
        }
    },
};

export const assigneeIdField: IFormField = {
    id: 'assigneeId',
    type: FormItemType.Component,
    component: EntityRemoteSelect,
    disabled: (getFieldValue) => getFieldValue('useAssignee') === true,
    componentProps: {
        className: 'rr-select-detailed',
        operationName: 'listUsersSimple',
        nameField: 'shortName',
        renderer: (option: { id: number; name: string; data: UserRecord }) => {
            return (
                <Select.Option key={option.id}>
                    <UserDetailedOption item={option.data} getKey={(item) => item.id} />
                </Select.Option>
            );
        },
    },
    placeholder: localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED),
};

// dynamic form fields
export const formFields: FormFieldsGroup[] = [
    {
        fields: [
            {
                id: 'copyParams',
                type: FormItemType.Component,
                component: CopyProjectParamsBlock,
            },
        ],
        visible: (form) => {
            return form.getFieldValue('copyMode') === true;
        },
    },
    {
        fields: [
            {
                label: LocalizationEnum.PAGE__SHIPPINGS__FORM__FIELD__TEMPLATE,
                id: 'template',
                type: FormItemType.String,
                disabled: true,
                visible: (getFieldValue) => getFieldValue('fromTemplate') === true,
            },
        ],
        visible: (form) => {
            return form.getFieldValue('fromTemplate') === true;
        },
    },
    {
        fields: [
            createHiddenField('copyMode'),
            createHiddenField('id'),
            createHiddenField('supplierStateCode'),
            createHiddenField('fromTemplate'),
            createHiddenField('projectEdit'),
            createHiddenField('createFromOperationForm'),
            shortTitleField,
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__FULL_NAME,
                id: 'fullName',
                type: FormItemType.String,
                required: false,
                maxLength: 255,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    let supplierId = toNumber(form.getFieldValue('supplierId'));
                    if (supplierId) {
                        const ignoreCurrentId = form.getFieldValue('projectEdit') ? form.getFieldValue('id') : undefined;
                        validateField(
                            'subrent',
                            fieldName,
                            supplierId ? value : undefined,
                            supplierId,
                            ignoreCurrentId,
                            'Полное наименование уже используется в текущем арендаторе',
                            cb
                        );
                    } else cb();
                }, 500),
            },
            supplierField,
            statusField,
            workPeriodField,
        ],
    },
    {
        title: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__ASSIGNEE),
        fields: [
            createHiddenField('renterDefaultAssignee'),
            createHiddenField('defaultAssignee'),
            createHiddenField('_defaultAssignee'),
            bySupplierField,
            assigneeIdField,
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION,
                id: 'description',
                type: FormItemType.RichText,
                validationRules: [
                    {
                        max: 5000,
                        message: (
                            <>
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} 5000{' '}
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__INCLUDING_FORMATTING_TAGS)}
                            </>
                        ),
                    },
                ],
            },
        ],
    },
    {
        fields: [
            createHiddenField('counterpartyContactLinks'),
            createHiddenField('__tmpContactLinks'),
            {
                label: 'Контакты по поставщику',
                id: 'hasOwnContacts',
                type: FormItemType.Switch,
                labelCol: { span: 7 },
                wrapperCol: { span: 12 },
                defaultValue: true,
                onChange: async (value: any, form: WrappedFormUtils) => {
                    if(value){
                        // Контакты по арендатору
                        form.setFieldsValue({__tmpContactLinks: form.getFieldValue('contactLinks')});
                        form.setFieldsValue({contactLinks: form.getFieldValue('counterpartyContactLinks')});
                    }else{
                        // Собственные котакты
                        let tmpContactLinks:ContactData[]|undefined = form.getFieldValue('__tmpContactLinks');
                        if(!tmpContactLinks || tmpContactLinks.length === 0 ) tmpContactLinks = [{
                            position: '',
                            isMainContact: true
                        }];
                        form.setFieldsValue({contactLinks: tmpContactLinks});
                    }
                },
            },
            {
                id: 'contactLinks',
                type: FormItemType.Component,
                component: ContactsEditor,
                componentProps: (form: WrappedFormUtils):Partial<ContactsEditorProps>=>{
                    const hasOwnContacts = form.getFieldValue('hasOwnContacts');
                    let counterpartyContactLinks:ContactData[]|undefined = form.getFieldValue('counterpartyContactLinks');
                    return {
                        withMainContact: true,
                        disabled: !!hasOwnContacts,
                        required: !hasOwnContacts,
                        counterpartyContactsIds: counterpartyContactLinks?.filter(link=>link.contactId).map((link)=>link.contactId) as (number[]|undefined)
                    };
                },
                validationRules: [
                    {
                        validator: (rule, value: ContactLinkObjWrite[] | undefined, cb, values, s, x) => {
                            if(values && !values.hasOwnContacts){
                                if(!value || value.length === 0) cb('У поставки должен быть хотя бы один контакт');
                                else if(value && value.some((item) => item.isMainContact && !item.contactId)) cb('Основной контакт не выбран');
                                else if(value && value.every((item) => !item.isMainContact)) cb('Основной контакт не выбран');
                                else if(value && value.some((item) => !item.contactId && item.position)) cb('Контакт не выбран');
                                else cb();
                            }else cb();

                        },
                    },
                ],
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
];
