import {useLocalize} from "../../../../../core/hooks/useLocalize";
import React, {ReactNode, useCallback, useState} from "react";
import {Popover} from "../../../../../components/popover/Popover";
import {Button} from "antd";

interface BaseEntityMovePopupProps<SelectedRecord> {
    children: ReactNode;
    select:ReactNode;
    selectedRecord?:SelectedRecord;
    onOkCallback: (target: SelectedRecord) => void;
    onClose:()=>void;
}

export const BaseEntityMovePopover = <SelectedRecord,>(props:BaseEntityMovePopupProps<SelectedRecord>) => {
    const {children, select, onOkCallback, selectedRecord} = props;
    const [visible, setVisible] = useState(false);

    const onVisibleChange = useCallback((visible: boolean) => {
        setVisible(visible);
        if (!visible) props.onClose();
    }, [setVisible, props.onClose]);

    const onOk = useCallback(() => {
        if (selectedRecord) onOkCallback(selectedRecord);
        onVisibleChange(false);
    }, [selectedRecord, onOkCallback, onVisibleChange]);

    return (
        <Popover
            visible={visible}
            onVisibleChange={onVisibleChange}
            headerClassName={'select-project-popover-header'}
            overlayClassName={'select-project-popover-overlay rr-operationForm-kit-add-element-popover'}
            header={
                visible && <>
                    {select}
                    <Button className={'rr-btn-blue'} style={{marginLeft: 8}} onClick={onOk}
                            disabled={!selectedRecord}>
                        OK
                    </Button>
                </>
            }
        >
            {children}
        </Popover>
    );
}