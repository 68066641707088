/**
 *
 */

/**
 * Ширина бокового меню в px
 */
export const MENU_WIDTH:number = 240;


/**
 * Ширина свернутого бокового меню в px
 */
export const MENU_COLLAPSED_WIDTH:number = 80;


/**
 * Высота хэдера в px
 */
export const HEADER_HEIGHT:number = 70;


/**
 * Высота футера в px
 */
export const FOOTER_HEIGHT:number = 70;


/**
 * Интервал проверки данных БА в мсек
 */
export const UPDATE_DATA_INTERVAL_MSEC:number = 2 * 1000;

/**
 * Интервал обновления индикаторов БА в мсек
 */
export const GET_BUSINESS_ACCOUNT_INDICATORS_INTERVAL_MSEC:number = .5 * (60 * 1000);


/**
 * Интервал обновления БА в мсек
 */
export const GET_BUSINESS_ACCOUNT_INFO_INTERVAL_MSEC:number = 30 * (60 * 1000);


/**
 * Интервал обновления прав текущего пользователя в мсек
 */
export const GET_PERMISSIONS_INTERVAL_MSEC:number = 30 * (60 * 1000);

/**
 * Интервал обновления ценовых схем в мсек
 */
export const GET_PRICING_SCHEMES_INTERVAL_MSEC:number = 30 * (60 * 1000);

/**
 * Интервал обновления настроек БА в мсек
 */
export const GET_BUSINESS_ACCOUNT_PREFERENCES_INTERVAL_MSEC:number = 30 * (60 * 1000);

/**
 * Интервал обновления контактов БА в мсек
 */
export const GET_BUSINESS_ACCOUNT_CONTACTS_INTERVAL_MSEC:number = 30 * (60 * 1000);

/**
 * Интервал обновления данных БА для дашборда в мсек
 */
export const GET_BUSINESS_ACCOUNT_DASHBOARD_DATA_INTERVAL_MSEC:number = 5 * (60 * 1000);

/**
 * Дефолтная локаль
 */
export const DEFAULT_LOCALE:string = 'ru_RU'//'en_US';

/**
 * Задержка до показа подсказок с помощью
 */
export const HELP_TOOLTIPS_SHOW_DELAY_SEC:number = 1;

/**
 * Ширина колонки с проблемами
 */
export const GRID_PROBLEM_COLUMN_WIDTH:number = 58;

/**
 * Ширина колонки с картинками
 */
export const GRID_IMAGE_COLUMN_WIDTH: number = 56;

/**
 * Ширина колонки с действиями
 */
export const GRID_ACTIONS_COLUMN_WIDTH: number = 64;

/**
 * Ширина колонки с действиями
 */
export const GRID_ACTIONS_COLUMN_ITEM_WIDTH: number = 45;

export const getActionsColumnWidth = (columns: number, columnWidth:number = GRID_ACTIONS_COLUMN_ITEM_WIDTH) => {
    return columnWidth * columns + 12;
}

/**
 * Ширина maxWidth
 */
export const MAX_WIDTH = (minWidth: number): number => {
    return minWidth * 2;
};


export const MAX_DEEP_CATEGORIES: number = 5;

/**
 * Максимальный размер вложения в байтах
 */
export const MAX_ATTACHMENT_FILE_SIZE_LIMIT_IN_BYTES: number = 10 * 1024 * 1024;

/**
 * Лимит на число вложений сущности
 */
export const MAX_ATTACHMENTS_LIMIT_FOR_ENTITY: number = 10;

/**
 * Лимит на единоразовую загрузку.
 */
export const MAX_ATTACHMENTS_LIMIT_FOR_UPLOADING: number = 10;

/**
 * Максимальный размер вложения в байтах
 */
export const MAX_DOCUMENT_TEMPLATE_FILE_SIZE_LIMIT_IN_BYTES: number = 10 * 1024 * 1024;

/**
 * Интервал обновления конкурентных операций
 */
export const GET_CONCURRENT_OPERATIONS_INTERVAL_MSEC: number = 20 * (1000);

export const RR_OFFER_AGREEMENT_LINK = 'https://rentrabb.it/upload/iblock/bf6/o7zlf92d34pix72qa18og7bz1tqdslti/%D0%9B%D0%B8%D1%86%D0%B5%D0%BD%D0%B7%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D0%B9_%D0%B4%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80_%D0%BE%D1%84%D0%B5%D1%80%D1%82%D0%B0_%D1%80%D0%B5%D0%B4_23_07_2021%D0%B3_.pdf';

export const NOMENCLATURES_TO_LOAD_BATCH_SIZE = 10;

export const MAXIMUM_OF_IDS_FOR_ONE_NOMENCLATURES_ON_INTERVAL_REQUEST = 50;

export const DEFAULT_NUMBER_OF_ENTITIES_ON_PAGE = 20;

export const DEFAULT_ON_PAGE_SELECT_VALUES = [10, 20, 50, 100, 200];
