import { PageURLParamDescriptionObject, PageURLParamDescriptions } from '../../../../../core/utils/descriptions';
import { KitsListFilters } from '../api/kits.api.types';
import { NomenclatureStateCodeEnum } from '../../../../../server';
import { ProblemEnum } from '../../../../../types';

export const kitsPageFiltersDescription: Required<PageURLParamDescriptionObject<KitsListFilters>> = {
    search: PageURLParamDescriptions.searchParam(),
    status: PageURLParamDescriptions.enumSelectParam(NomenclatureStateCodeEnum),
    finalTotalPrice: PageURLParamDescriptions.numberInterval,
    totalDiscount: PageURLParamDescriptions.numberInterval,
    delay: PageURLParamDescriptions.booleanParam,
    shortage: PageURLParamDescriptions.booleanParam,
    hideArchive: PageURLParamDescriptions.booleanParam,
    categoryIds: PageURLParamDescriptions.numbersSelectParam,
    problem: PageURLParamDescriptions.enumSelectParam(ProblemEnum),
    productId: PageURLParamDescriptions.numberParam,
    variantId: PageURLParamDescriptions.numberParam,
    contactId: PageURLParamDescriptions.numberParam,
};
