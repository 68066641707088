import {FAILURE, REQUEST, SUCCESS} from '../../../../shared/reducers/action-type.util';
import {RentElementListAggregations, RentElementRecordList, serverApi, ServerError,} from '../../../../server';
import {showNotification} from '../../../../components/notification/showNotification';
import {IRootState} from '../../../../shared/reducers';
import {getServerError, getStateFromPath2,} from '../../../../shared/util/utils';
import {LocalizationEnum, localizeIntl} from '../../../../localization';
import {downloadDataFromExcel} from '../../../../shared/util/downloadDataFromExcel';
import {convertRentElementRecordToRentElementsGridItem, RentElementsGridItem} from '../../../../types';
import {getCurrentTimezoneUTCOffset} from '../../../../utils/timeUtils/getCurrentTimezoneUTCOffset';
import {gridDataChangedSignal} from '../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';
import {LOCATION_CHANGE} from "connected-react-router";

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'simpleOrderElements/LOAD_ENTITIES',
    EXPORT_EXCEL: 'simpleOrderElements/EXPORT_EXCEL',
    RESET: 'simpleOrderElements/RESET',
    CLEAR_ELEMENTS: 'simpleOrderElements/CLEAR_ELEMENTS',
    SET_PARAMS: 'simpleOrderElements/SET_PARAMS'
};

export const initialParamsState = {
    sortBy: undefined as string | undefined,
    sortOrder: undefined as 'ASC' | 'DESC' | undefined,
    projectId: undefined as number | undefined
};

const initialState = {
    loading: true,
    loadingError: undefined as undefined | ServerError,
    entities: null as Array<RentElementsGridItem> | null,
    aggregations: undefined as RentElementListAggregations | undefined,
    totalCount: 0,
    filteredCount: 0,
    params: {
        ...initialParamsState,
    },
};

// Reducer

export type RentElementsState1 = Readonly<typeof initialState>;

export default (state: RentElementsState1 = initialState, action): RentElementsState1 => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);

            const regexp =
                /\/(\d+)\/(projects\/simpleOrders)\/(\d+)/;

            if (regexp.test(pathName) && params.tab !== 'operations') {
                let arr = regexp.exec(pathName);
                if (arr) {
                    params.projectId = arr[3];
                }

                return {
                    ...state,
                    params: {
                        ...state.params,
                        projectId: params.projectId !== undefined ? params.projectId : initialParamsState.projectId,
                    },
                };
            }
            return state;

        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            console.dir(action.payload);
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            const entities: RentElementsGridItem[] = (action.payload.data as RentElementRecordList).records.map((item) => {
                return convertRentElementRecordToRentElementsGridItem(item);
            });
            const aggregations = (action.payload.data as RentElementRecordList).aggregations;

            return {
                ...state,
                loading: false,
                entities,
                aggregations,
                totalCount: (action.payload.data as RentElementRecordList).listAttributes.filteredCount,
                filteredCount: (action.payload.data as RentElementRecordList).listAttributes.filteredCount,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        case ACTION_TYPES.CLEAR_ELEMENTS:
            return {
                ...state,
                entities: null
            };
        case ACTION_TYPES.SET_PARAMS:
            return {
                ...state,
                params: {
                    ...state.params,
                    sortBy: action.payload.sortBy,
                    sortOrder: action.payload.sortOrder
                }
            };
        default:
            return state;
    }
};

// Actions

// Загрузить список обязательств
export const simpleOrderLoadEntities = (intl, businessAccountId: number, projectId?:number) => {
    return (dispatch, getState) => {
        let state: RentElementsState1 = (getState() as IRootState).simpleOrderElements;
        let params = state.params;
        const sortBy = params.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : params.sortBy;
        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload:serverApi.listProjectElements(
                businessAccountId,
                projectId || params.projectId || 0,
                undefined,
                0,
                undefined,
                sortBy,
                params.sortOrder,
                undefined,
                true,
                undefined,
                undefined
            ),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__ELEMENTS__POPUP_NOTIFICATIONS__GET_LIST_FAILED));
            } else {
            }
        });
    };
};

// Экспорт в эксель
export const exportExcelSimpleOrderList = (
    intl,
    businessAccountId: number,
    visibleColumns: string[],
    expandKits: boolean,
    mnemo: string
) => {
    return (dispatch, getState) => {
        let state: RentElementsState1 = (getState() as IRootState).simpleOrderElements;
        let params = state.params;
        const sortBy = params.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : params.sortBy;
        return dispatch({
            type: ACTION_TYPES.EXPORT_EXCEL,
            payload: serverApi.exportProjectElementsList(
                businessAccountId,
                params.projectId || 0,
                visibleColumns,
                expandKits,
                getCurrentTimezoneUTCOffset(),
                undefined,
                0,
                undefined,
                sortBy,
                params.sortOrder,
                undefined,
                { responseType: 'blob' }
            )
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__EXCEL_EXPORT__EXPORT_TO_EXCEL_FAILED));
            } else {
                let fileName =
                        (localizeIntl(intl, LocalizationEnum.ASPECT__EXCEL_EXPORT__EXCEL_FILE_NAME_PROJECT_ELEMENTS) as string) +
                        ' ' +
                        mnemo;
                downloadDataFromExcel(result.action.payload.data, fileName);
            }
        });
    };
};

export const resetSimpleOrderListElements = () => ({
    type: ACTION_TYPES.RESET,
});

export const clearSimpleOrderListElements = () => ({
    type: ACTION_TYPES.CLEAR_ELEMENTS,
});

interface SetParamsPayload {
    sortOrder? :'ASC' | 'DESC' | undefined;
    sortBy?: string;
}

export const setSimpleOrderListParams = (params: SetParamsPayload) => ({
    type: ACTION_TYPES.SET_PARAMS,
    payload: params
});
