import { Marker, useLeaflet } from 'react-leaflet';
import { LocationRecord } from '../../../../../../server';
import { icon, latLngBounds } from 'leaflet';
import { useEffect } from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { IRootState } from '../../../../../../shared/reducers';
import marker from './pins/map-pin.svg';
import markerGray from './pins/map-pin-gray.svg';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

interface Props {
    data: LocationRecord[];
    onMarkerClick: (id: number) => void;
}

export const MAP_PIN = icon({
    iconUrl: marker,
    iconSize: [24, 36],
    iconAnchor: [12, 36],
    shadowUrl: iconShadow,
});

export const MAP_PIN_GRAY = icon({
    iconUrl: markerGray,
    iconSize: [24, 36],
    iconAnchor: [12, 36],
    shadowUrl: iconShadow,
});

const LocationMarkers = ({ data, onMarkerClick }: Props) => {
    const leaflet = useLeaflet();
    const viewPort = useAppSelector((store: IRootState) => store.userSettings.mapViewPort);

    useEffect(() => {
        const markerBounds = latLngBounds([]);
        data.filter((l) => l.latitude && l.longitude).forEach((loc) => markerBounds.extend([loc.latitude!, loc.longitude!]));
        if (markerBounds.isValid() && !viewPort) {
            leaflet.map?.fitBounds(markerBounds, { padding: [10, 10] });
        }
    }, [data, leaflet.map, viewPort]);

    return (
        <>
            {data
                .filter((l) => l.latitude && l.longitude)
                .map((loc) => (
                    <Marker
                        id={loc.id}
                        position={[loc.latitude!, loc.longitude!]}
                        icon={loc.archive ? MAP_PIN_GRAY : MAP_PIN}
                        onclick={() => {
                            onMarkerClick(loc.id);
                        }}
                    />
                ))}
        </>
    );
};
export default LocationMarkers;
