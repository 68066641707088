import React, { FC, memo, useState } from 'react';
import { useAppSelector } from '../../../../../../../store/hooks';
import { useSetStatusForTransportationsMutation, useTransportationByIdQuery } from '../../api/transportations.api';
import { EntityDrawer } from '../../../../../../../components/v2/entityDrawer/EntityDrawer';
import { AttachmentParentEntityTypeCodeEnum, DocumentTypeCodeEnum, TransportationInfoRead } from '../../../../../../../server';
import { DataDisplayBlock, DataDisplayItemType } from '../../../../../../../components/dataDisplay';
import { AttachmentsBlock } from '../../../../../../../components/attachmentsBlock/attachmentsBlock';
import { TransportationDrawerHeader } from './TransportationDrawerHeader';
import { DownloadDocumentButton } from '../../../../../../../components/buttons/downloadDocumentButton/downloadDocumentButton';
import { DownloadDocumentUtils } from '../../../../../../../core/utils/downloadDocumentUtils/downloadDocumentUtils';
import { showNotification } from '../../../../../../../components/notification/showNotification';
import { ContactDrawer } from '../../../../../renters/tabs/contacts/drawer/components/ContactDrawer/ContactDrawer';
import { VehicleDrawer } from '../../../vehicles/drawer/components/VehicleDrawer';
import { LocationDrawer } from '../../../locations/drawer/LocationDrawer';
import { useDisplayItems } from '../hooks/useDisplayItems';
import { useDocumentPopoverWarnings } from '../hooks/useDocumentPopoverWarnings';
import { useIdForDrawer } from '../../hooks/useIdForDrawer';
import { usePushToPages } from '../../hooks/usePushToPages';
import { EntityActionsModalOptions } from '../../../../../../../components/page/entityGrid/hooks/useRowActions';
import { businessAccountIdSelector } from '../../../../../../../shared/reducers/system.reducer';
import { ExportBillOfLadingPopover } from '../../../../../../../components/exportPopovers/downloadDocument/instances/ExportBillOfLadingPopover/ExportBillOfLadingPopover';
import { useSetStatusForTransportationsCallback } from '../../hooks/useSetStatus';
import {printUserName} from "../../../../../../../shared/util/utils";

export interface TransportationDrawerProps {
    transportationId: number;
    onClose: () => void;
    refetchList?: () => void;
    editVehicle?: (transportation: TransportationInfoRead) => void;
    deleteVehicle?: (transportation: TransportationInfoRead) => Promise<void>;
    copyVehicle?: (entity: TransportationInfoRead, options?: EntityActionsModalOptions) => void;
    isEntityActionsLoading?: boolean;
}

export const TransportationDrawer: FC<TransportationDrawerProps> = memo((props) => {
    const { transportationId, onClose, refetchList, editVehicle, deleteVehicle, copyVehicle, isEntityActionsLoading } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const [actionPopoverVisible, setActionPopoverVisible] = useState(false);
    const [locationIdForDrawer, setLocationIdForDrawer, clearLocationIdForDrawer] = useIdForDrawer();
    const [contactIdForDrawer, setContactIdForDrawer, clearContactIdForDrawer] = useIdForDrawer();
    const [vehicleIdForDrawer, setVehicleIdForDrawer, clearVehicleIdForDrawer] = useIdForDrawer();

    const {
        data: transportation,
        error,
        isFetching,
        isLoading: isEntityLoading,
        refetch,
    } = useTransportationByIdQuery(
        {
            businessAccountId: businessAccountId,
            entityId: transportationId,
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const [setStatusForTransportations, { isLoading: isSetStatusForTransportationsLoading }] = useSetStatusForTransportationsMutation();

    const { locationActions, contactActions, vehiclesActions } = usePushToPages();
    const displayItems = useDisplayItems({
        transportation: transportation,
        setLocationIdForDrawer: setLocationIdForDrawer,
        setContactIdForDrawer: setContactIdForDrawer,
        setVehicleIdForDrawer: setVehicleIdForDrawer,
    });

    const documentPopoverWarnings: string[] = useDocumentPopoverWarnings(displayItems);

    const setStatusForTransportationsCallback = useSetStatusForTransportationsCallback(
        transportation ? [transportation] : [],
        undefined,
        setStatusForTransportations
    );

    const isLoading = isEntityLoading || isEntityActionsLoading || isSetStatusForTransportationsLoading;

    return (
        <EntityDrawer
            title={
                <TransportationDrawerHeader
                    transportation={transportation}
                    actionPopoverVisible={actionPopoverVisible}
                    setActionPopoverVisible={setActionPopoverVisible}
                    onClose={onClose}
                    editVehicle={editVehicle}
                    deleteVehicle={deleteVehicle}
                    copyVehicle={copyVehicle}
                    setStatusForTransportationsCallback={setStatusForTransportationsCallback}
                    isLoading={isLoading || isFetching}
                />
            }
            onClose={onClose}
            error={error}
            isLoading={isLoading}
            isFetching={isFetching}
        >
            {transportation && (
                <>
                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    label: 'Проект',
                                    type: DataDisplayItemType.Project,
                                    value: transportation.project
                                        ? {
                                              baId: businessAccountId,
                                              id: transportation.project?.id,
                                              name: transportation.project?.shortName,
                                              onClick: () => {
                                                  onClose();
                                              },
                                          }
                                        : undefined,
                                },
                            ],
                        ]}
                    />{' '}
                    <DataDisplayBlock
                        data={[
                            [displayItems.dateTimeOfLoading, displayItems.shipper],
                            [displayItems.loadingLocation, displayItems.receiver],
                        ]}
                    />
                    <DataDisplayBlock
                        data={[
                            [displayItems.dateTimeOfUnloading, displayItems.consignee],
                            [displayItems.unloadingLocation, displayItems.recipient],
                            [displayItems.cargoDeliveryLocation],
                        ]}
                    />
                    <DataDisplayBlock data={[[displayItems.car, displayItems.truck], [displayItems.drivers], [displayItems.cargo]]} />
                    <DataDisplayBlock
                        data={[
                            [displayItems.requestDate, displayItems.transportationDate],
                            [
                                {
                                    type: DataDisplayItemType.Custom,
                                    value: null,
                                },
                                {
                                    type: DataDisplayItemType.Custom,
                                    value: (
                                        <ExportBillOfLadingPopover
                                            onDownload={async ({ fileType, templateId, isCommonTemplate, legalDetailId }) => {
                                                await DownloadDocumentUtils.billOfLadingGenerateAndDownload(
                                                    {
                                                        businessAccountId: transportation.businessAccountId,
                                                        documentTemplateId: templateId!,
                                                        common: isCommonTemplate!,
                                                        id: transportation.id,
                                                        legalDetailId,
                                                    },
                                                    [
                                                        'ТН',
                                                        transportation.project?.shortName,
                                                        printUserName(transportation.drivers?.[0]?.fullName),
                                                        transportation?.car?.officialNumberPrepared,
                                                    ]
                                                        .filter((item) => item)
                                                        .join(' '),
                                                    fileType
                                                ).catch((e) => {
                                                    showNotification('error', 'Ошибка генерации файла');
                                                });
                                            }}
                                            warnings={documentPopoverWarnings}
                                            getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                                        >
                                            <DownloadDocumentButton documentType={DocumentTypeCodeEnum.BILLOFLADING} />
                                        </ExportBillOfLadingPopover>
                                    ),
                                },
                            ],
                        ]}
                    />
                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    type: DataDisplayItemType.Custom,
                                    value: (
                                        <AttachmentsBlock
                                            attachments={transportation.attachments?.records ?? []}
                                            parentEntityId={transportation.id!}
                                            parentEntityTypeCode={AttachmentParentEntityTypeCodeEnum.TRANSPORTATION}
                                            updateParentEntity={() => {
                                                refetch();
                                                if (refetchList) refetchList();
                                            }}
                                        />
                                    ),
                                },
                            ],
                        ]}
                        paddings={false}
                    />
                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    label: 'Дата последнего изменения',
                                    type: DataDisplayItemType.DateWithTime,
                                    value: transportation.lastUpdateDate,
                                },
                                {
                                    label: 'Автор последнего изменения',
                                    type: DataDisplayItemType.User,
                                    value: transportation?.lastUpdateAuthorFullName
                                        ? {
                                              name: transportation.lastUpdateAuthorFullName,
                                              id: transportation.lastUpdateAuthorId,
                                              baId: transportation.businessAccountId,
                                          }
                                        : undefined,
                                },
                            ],
                        ]}
                    />
                    <DataDisplayBlock
                        data={[
                            [
                                {
                                    label: 'Комментарий',
                                    type: DataDisplayItemType.Comment,
                                    value: transportation.comment,
                                },
                            ],
                        ]}
                    />
                </>
            )}
            {/* Drawers */}
            {contactIdForDrawer ? (
                <ContactDrawer
                    contactId={contactIdForDrawer}
                    onClose={clearContactIdForDrawer}
                    editContact={contactActions.edit}
                    archiveContact={contactActions.archive}
                    deleteContact={contactActions.delete}
                    isEntityActionsLoading={contactActions.isLoading}
                    refetchList={refetchList}
                />
            ) : undefined}
            {vehicleIdForDrawer ? (
                <VehicleDrawer
                    vehicleId={vehicleIdForDrawer}
                    onClose={clearVehicleIdForDrawer}
                    editVehicle={vehiclesActions.edit}
                    deleteVehicle={vehiclesActions.delete}
                    archiveVehicle={vehiclesActions.archive}
                    isEntityActionsLoading={vehiclesActions.isLoading}
                    refetchEntitiesList={refetchList}
                />
            ) : undefined}
            {locationIdForDrawer ? (
                <LocationDrawer
                    locationId={locationIdForDrawer}
                    onClose={clearLocationIdForDrawer}
                    editLocation={locationActions.edit}
                    deleteLocation={locationActions.delete}
                    archiveLocation={locationActions.archive}
                    isEntityActionsLoading={locationActions.isLoading}
                    refetchList={refetchList}
                />
            ) : undefined}
        </EntityDrawer>
    );
});
