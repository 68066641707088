import React from 'react';
import ActionsPopover from '../../actionsPopover/ActionsPopover';
import { EntityActionType, EntityType, GridRecord } from '../../../../index';

export const tableCellActionsRenderer = (
    value: any,
    rowData: GridRecord,
    onAction: (entity: any, actionType: EntityActionType) => void,
    entityType: EntityType
) => {
    return <ActionsPopover entity={rowData || {}} onAction={onAction} entityType={entityType} />;
};
