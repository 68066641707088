import { ColumnTypes, TableColumn } from '../../../../components/grid/Table';
import { Link } from 'react-router-dom';
import React from 'react';
import { TemplateRecord } from '../../../../server/api';
import { LocalizationEnum, localize } from '../../../../localization';
import { GRID_ACTIONS_COLUMN_WIDTH } from '../../../../config/constants';
import DashboardDate from '../../../../components/dashboardDate/dashboardDate';
import { SystemIcon, SystemIconType } from '../../../../components/v2/systemIcon/systemIcon';

// GRID COLUMNS
const templatesColumns: TableColumn<TemplateRecord>[] = [
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NAME),
        dataIndex: 'shortName',
        type: ColumnTypes.CustomRender,
        minWidth: 160,
        resizable: true,
        render: (value: any, rowData: TemplateRecord) => {
            return (
                <div className={'rr-grid-shortName-cell'} title={rowData.shortName}>
                    <Link to={`/${rowData.businessAccountId}/projects/templates/${rowData.id}`}>{rowData.shortName}</Link>
                </div>
            );
        },
        dontHide: true,
    },
    {
        title: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DEFAULT_DISCOUNT),
        dataIndex: 'defaultDiscount',
        type: ColumnTypes.Percents,
        minWidth: 120,
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__SUM),
        dataIndex: 'rentElementsSum',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 180,
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIVITY),
        dataIndex: 'lastActivityDate',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        resizable: false,
        render: (value: Date, rowData: TemplateRecord) => {
            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    {
        title: <SystemIcon type={SystemIconType.actions} style={{ display: 'block' }} />,
        type: ColumnTypes.Actions,
        id: 'actions',
        width: GRID_ACTIONS_COLUMN_WIDTH,
        maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
        minWidth: GRID_ACTIONS_COLUMN_WIDTH,
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
    },
];

export default templatesColumns;
