import React, { CSSProperties } from 'react';
import { formatMoney } from '../../shared/util/formatMoney';

interface IProps {
    value: any;
    style?: CSSProperties;
    onClick?: any;
    className?: string;
    withOverpay: boolean;
    overpayBelow?: boolean;
}

export const FormValueMoneyProblem = (props: IProps) => {
    const isOverpay = props.value < 0 && props.withOverpay;
    return (
        <>
            <span
                onClick={props.onClick}
                style={{
                    ...props.style,
                    color: isOverpay ? '#f4516c' : props.style?.color,
                    fontWeight: isOverpay ? 700 : props.style?.fontWeight,
                }}
                className={props.className ? props.className : ''}
            >
                {formatMoney(props.value)}
            </span>
            {isOverpay ? (
                <span
                    style={{
                        fontSize: 15,
                        verticalAlign: 'text-bottom',
                        marginLeft: !props.overpayBelow ? 8 : 0,
                        lineHeight: props.overpayBelow ? 1.5 : undefined,
                        fontWeight: 400,
                        color: '#f4516c',
                    }}
                >
                    (Переплата)
                </span>
            ) : null}
        </>
    );
};
