import React, { FC } from 'react';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { ProjectInfoRead } from '../../../../../../../../server';
import { Col, Row } from 'antd';
import { FormValue } from '../../../../../../../../components';
import { RichTextView } from '../../../../../../../../components/richTextView/RichTextView';

interface ProjectPageDescriptionDescriptionBlockProps {
    data: ProjectInfoRead;
}

export const ProjectPageDescriptionDescriptionBlock: FC<ProjectPageDescriptionDescriptionBlockProps> = ({ data }) => {
    return (
        <Row style={{ padding: '32px 24px' }}>
            <Col span={24} className={'rr-form-item'}>
                <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}</div>
                <div className={'rr-text'}>
                    <FormValue
                        value={data.description && <RichTextView value={data.description} />}
                        emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                    />
                </div>
            </Col>
        </Row>
    );
};
