import { ColumnTypes, TableColumn } from '../../../../../components/grid/Table';
import React from 'react';
import { LocalizationEnum, localize } from '../../../../../localization';
import '../product-page-instances.less';
import { Icon } from 'antd';
import { IconPencil } from '../../../../../components/icons';
import { InstanceRecord, InstanceStateCodeEnum, NomenclatureEntityTypeCodeEnum } from '../../../../../server/api';
import ProductPageInstancesActionPopover from '../product-page-instances-action-popover';
import DashboardDate from '../../../../../components/dashboardDate/dashboardDate';
import { GRID_ACTIONS_COLUMN_WIDTH } from '../../../../../config/constants';
import { SystemIcon, SystemIconType } from '../../../../../components/v2/systemIcon/systemIcon';
import { GridColumnCreator } from '../../../../../components/grid/gridColumnCreator';

const editIcon = () => (
    <SystemIcon
        type={SystemIconType.otherActions}
        style={{ fontSize: 28 }}
        className={'rr-grid-table-actions-icon'}
        onClick={(e) => e.stopPropagation()}
    />
);

const columns: TableColumn<InstanceRecord>[] = [
    GridColumnCreator.createProblemSeverityColumn(),
    GridColumnCreator.createShortNameColumn<InstanceRecord>(
        'product-instances',
        (row) => ({
            name: row.nameOrCode,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__FIELDS__INSTANCE__CODE_OR_NAME),
            dataIndex: 'nameOrCode',
        }
    ),
    GridColumnCreator.createBarCodeColumn(
        (row) => {
            return {
                nomenclatureId: row.id,
                nomenclatureType: NomenclatureEntityTypeCodeEnum.INSTANCE,
                barcodeString: row.barcode,
            };
        },
        {
            dataIndex: 'barcode',
        }
    ),
    GridColumnCreator.createSerialNumberColumn(),
    GridColumnCreator.createInventoryNumberColumn(),
    GridColumnCreator.createProductVariantColumn<InstanceRecord>((row) => ({
        productId: row.productId,
        productName: row.productShortName,
        variantId: row.variantId,
        variantName: row.variantName,
    })),
    GridColumnCreator.createStatusColumn({
        title: localize(LocalizationEnum.ASPECT__FIELDS__INSTANCE__INVENTORY_STATE),
        minWidth: 160,
        maxWidth: 220,
    }),
    {
        title: 'Субаренда',
        dataIndex: 'warehousePresenceCounters.subrentedInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        resizable: false,
        render: (value: number) => <span style={{ color: value === 0 ? '#cccccc' : undefined }}>{value}</span>,
    },
    {
        title: 'Аренда',
        dataIndex: 'warehousePresenceCounters.rentedInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        resizable: false,
        render: (value: number) => <span style={{ color: value === 0 ? '#cccccc' : undefined }}>{value}</span>,
    },
    {
        title: 'На складе',
        dataIndex: 'warehousePresenceCounters.warehouseInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        resizable: false,
        render: (value: number) => <span style={{ color: value === 0 ? '#cccccc' : undefined }}>{value}</span>,
    },
    GridColumnCreator.createCounterpartyColumn<InstanceRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.supplierId,
            name: row.supplierShortName,
            showCurrentBusinessAccountShortNameIfNotSet: true,
        }),
        {
            title: 'Владелец',
            dataIndex: 'supplierShortName',
            defaultHidden: true,
        }
    ),
    GridColumnCreator.createCounterpartyColumn<InstanceRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.sellerId,
            name: row.sellerShortName,
        }),
        {
            title: 'Продавец',
            dataIndex: 'sellerShortName',
            defaultHidden: true,
        }
    ),
    {
        title: 'Последнее обслуживание',
        dataIndex: 'lastMaintenanceEndDate',
        minWidth: 150,
        type: ColumnTypes.CustomRender,
        resizable: false,
        render: (value: Date, rowData: InstanceRecord) => {
            if (rowData.stateCode === InstanceStateCodeEnum.UNDERMAINTENANCE) return 'Производится сейчас';

            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIVITY),
        dataIndex: 'lastActivityDate',
        minWidth: 140,
        type: ColumnTypes.CustomRender,
        resizable: false,
        render: (value: Date, rowData: InstanceRecord) => {
            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    {
        title: (
            <Icon title={LocalizationEnum.ASPECT__GRID__COLUMN__ACTIONS} style={{ fontSize: 21, paddingTop: 2 }} component={IconPencil} />
        ),
        id: 'actions',
        type: ColumnTypes.CustomRender,
        resizable: false,
        sortable: false,
        minWidth: GRID_ACTIONS_COLUMN_WIDTH,
        width: GRID_ACTIONS_COLUMN_WIDTH,
        maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
        className: 'actions',
        headerClassName: 'actions',
        render: (value: any, rowData: InstanceRecord, onAction: Function) => {
            return (
                <ProductPageInstancesActionPopover disabled={!rowData.availableTransitionCodes} record={rowData} onAction={onAction}>
                    {editIcon}
                </ProductPageInstancesActionPopover>
            );
        },
    },
];

export default columns;
