import { ColumnTypes, TableColumn } from '../../../../components/grid/Table';
import React from 'react';
import { NomenclatureEntityTypeCodeEnum, VariantRecord } from '../../../../server/api';
import { LocalizationEnum, localize } from '../../../../localization';
import { tableCellMoneyRenderer } from '../../../../components/grid/renderers/tableCellMoneyRenderer';
import { GRID_ACTIONS_COLUMN_WIDTH } from '../../../../config/constants';
import DashboardDate from '../../../../components/dashboardDate/dashboardDate';
import './product-page-variants-columns.less';
import { Link } from 'react-router-dom';
import { SystemIcon, SystemIconType } from '../../../../components/v2/systemIcon/systemIcon';
import { GridColumnCreator } from '../../../../components/grid/gridColumnCreator';

// GRID COLUMNS
export const columns: TableColumn<VariantRecord>[] = [
    GridColumnCreator.createProblemSeverityColumn(),
    GridColumnCreator.createImageColumn(),
    GridColumnCreator.createShortNameColumn<VariantRecord>(
        'variant',
        (row) => ({
            name: row.name,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NAME),
            dataIndex: 'name',
        }
    ),
    GridColumnCreator.createBarCodeColumn(
        (row) => {
            return {
                nomenclatureId: row.id,
                nomenclatureType: NomenclatureEntityTypeCodeEnum.VARIANT,
                barcodeString: row.barcode,
            };
        },
        {
            dataIndex: 'barcode',
        }
    ),
    GridColumnCreator.createExternalCodeColumn<VariantRecord>(NomenclatureEntityTypeCodeEnum.VARIANT, (row) => ({
        code: row.externalCode,
        hasOwnCode: row.hasOwnExternalCode,
    })),
    {
        title: 'Инвентарь',
        dataIndex: 'warehousePresenceCounters.stockInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        //maxWidth: MAX_WIDTH(120),
        resizable: false,
        render: (value: any, rowData: VariantRecord) => {
            if (rowData.tracked) {
                return (
                    <div className={'rr-grid-text-cell-center'} onClick={(e) => e.stopPropagation()}>
                        <Link
                            to={`/${rowData.businessAccountId}/inventory/products/${rowData.productId}?tab=instances&variantId=${rowData.id}&hideArchive=false`}
                            style={{ color: value === 0 ? '#cccccc' : 'unset' }}
                        >
                            {value}
                        </Link>
                    </div>
                );
            }
            return <span style={{ color: value === 0 ? '#cccccc' : undefined }}>{value}</span>;
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__MAINTENANCE),
        dataIndex: 'warehousePresenceCounters.underMaintenanceInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        //maxWidth: MAX_WIDTH(160),
        resizable: false,
        render: (value: any, rowData: VariantRecord) => {
            if (rowData.tracked) {
                return (
                    <div className={'rr-grid-text-cell-center'} onClick={(e) => e.stopPropagation()}>
                        <Link
                            to={`/${rowData.businessAccountId}/inventory/products/${rowData.productId}?tab=instances&variantId=${rowData.id}&stateCode=ACTIVE&hideArchive=false`}
                            style={{ color: value === 0 ? '#cccccc' : 'unset' }}
                        >
                            {value}
                        </Link>
                    </div>
                );
            }
            return <span style={{ color: value === 0 ? '#cccccc' : undefined }}>{value}</span>;
        },
    },
    {
        title: 'Субаренда',
        dataIndex: 'warehousePresenceCounters.subrentedInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        //maxWidth: MAX_WIDTH(120),
        resizable: false,
        render: (value: number) => <span style={{ color: value === 0 ? '#cccccc' : undefined }}>{value}</span>,
    },
    {
        title: 'Аренда',
        dataIndex: 'warehousePresenceCounters.rentedInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        //maxWidth: MAX_WIDTH(120),
        resizable: false,
        render: (value: number) => <span style={{ color: value === 0 ? '#cccccc' : undefined }}>{value}</span>,
    },
    {
        title: 'На складе',
        dataIndex: 'warehousePresenceCounters.warehouseInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        //maxWidth: MAX_WIDTH(80),
        sortable: true,
        resizable: false,
        render: (value: number) => <span style={{ color: value === 0 ? '#cccccc' : undefined }}>{value}</span>,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__PRICE_PER_SHIFT),
        dataIndex: 'pricePerShift',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        //maxWidth: MAX_WIDTH(120),
        resizable: false,
        render: (value: any, rowData: VariantRecord) => {
            return <span style={{ color: !rowData.hasOwnPricePerShift ? '#cccccc' : '' }}>{tableCellMoneyRenderer(value, rowData)}</span>;
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIVITY),
        dataIndex: 'lastActivityDate',
        minWidth: 140,
        //maxWidth: MAX_WIDTH(140),
        type: ColumnTypes.CustomRender,
        resizable: false,
        render: (value: Date, rowData: VariantRecord) => {
            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    {
        title: <SystemIcon type={SystemIconType.actions} style={{ display: 'block' }} />,
        type: ColumnTypes.Actions,
        id: 'actions',
        minWidth: GRID_ACTIONS_COLUMN_WIDTH,
        width: GRID_ACTIONS_COLUMN_WIDTH,
        maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
    },
];
