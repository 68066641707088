import React, { FC } from 'react';
import { DataDisplayBlock, DataDisplayItemType } from '../../../../../../../../components/dataDisplay';
import { LocalizationEnum } from '../../../../../../../../localization';
import { DataDisplayItemTheme } from '../../../../../../../../components/dataDisplay/dataDisplayItemProps';
import { ProjectInfoRead, RentStateCodeEnum } from '../../../../../../../../server';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { crewModuleEnabledSelector } from '../../../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { canViewFinancialDataSelector } from '../../../../../../../../shared/reducers/permissions.reducer';
import classNames from 'classnames';
import { CollapsibleBlock } from '../../../../../../../../components/v2/collapsibleBlock/CollapsibleBlock';
import { Icon } from 'antd';
import { IconMoneyCheckAltSolid } from '../../../../../../../../components/icons';
import './projectPageDescriptionOfferPriceBlock.less';
import { useLocalize } from '../../../../../../../../core/hooks/useLocalize';

interface ProjectPageDescriptionPriceOfferBlockProps {
    data: ProjectInfoRead;
}

export const ProjectPageDescriptionPriceOfferBlock: FC<ProjectPageDescriptionPriceOfferBlockProps> = ({ data }) => {
    const crewModuleEnabled = useAppSelector(crewModuleEnabledSelector);
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const L = useLocalize();

    const notIncludedInPrice = !data.partOfThePrice;

    return canViewFinancialData ? (
        <CollapsibleBlock
            collapsed={false}
            title={'Стоимость сметы'}
            icon={<Icon component={IconMoneyCheckAltSolid} />}
            className={classNames('rr-offer-price-block')}
            style={{ marginLeft: -8, marginRight: -8 }}
            disabled={true}
        >
            <div style={{ borderBottom: '1px solid #eaeaea' }}>
                <DataDisplayBlock
                    data={[
                        [
                            {
                                label: 'Стоимость аренды',
                                type: DataDisplayItemType.Money,
                                value: data.costOfRent,
                                rentType: RentStateCodeEnum.DRAFT,
                                emptyValueText: 'Нет',
                            },
                        ],
                        //TODO
                        crewModuleEnabled
                            ? [
                                  {
                                      label: 'Стоимость работ',
                                      type: DataDisplayItemType.Money,
                                      value: data.costOfWork,
                                      rentType: RentStateCodeEnum.DRAFT,
                                      emptyValueText: L(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                                  },
                                  {
                                      label: 'Стоимость затрат',
                                      type: DataDisplayItemType.Money,
                                      value: data.costOfExpenses,
                                      rentType: RentStateCodeEnum.DRAFT,
                                      emptyValueText: L(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                                      style: { paddingLeft: 0 },
                                  },
                              ]
                            : [
                                  {
                                      label: 'Стоимость затрат',
                                      type: DataDisplayItemType.Money,
                                      value: data.costOfExpenses,
                                      emptyValueText: L(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                                  },
                              ],
                    ]}
                />
            </div>
            <div>
                <DataDisplayBlock
                    data={[
                        [
                            {
                                label: 'Итого до налогов',
                                type: DataDisplayItemType.Money,
                                value: data.actualSumBeforeTaxes,
                                emptyValueText: L(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                            },
                            {
                                label: 'Итоговая стоимость сметы',
                                type: DataDisplayItemType.Money,
                                value: data.actualSumAfterTaxes,
                                emptyValueText: L(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                                theme: !notIncludedInPrice ? DataDisplayItemTheme.DARK : DataDisplayItemTheme.GREY,
                                style: { marginLeft: -32 },
                                hint: notIncludedInPrice ? '(Не включено в стоимость проекта)' : undefined,
                            },
                        ],
                    ]}
                />
            </div>
        </CollapsibleBlock>
    ) : (
        <div style={{ marginLeft: 32, marginTop: 20, fontSize: 18, color: '#aaaaaa' }}>Финансовая информация скрыта</div>
    );
};
