import React from 'react';
import { ProductCalendarPopover } from '../../../dev/OperationFormProductCalendarPopover';
import { TimetableTypeCodeEnum } from '../../../../../server';
import { Col, Icon } from 'antd';
import { InventoryCurrentStateBlockProps } from './InventoryCurrentStateBlock';
import classNames from 'classnames';
import { IconProps } from 'antd/lib/icon';

interface InventoryCurrentStateBlockItemProps
    extends Pick<InventoryCurrentStateBlockProps, 'phoneMode' | 'productId' | 'variantId' | 'instanceId' | 'kitId'> {
    title: string;
    typeCode: TimetableTypeCodeEnum;
    isEmpty?: boolean;
    icon: IconProps['component'];
    iconClass: string;
    countElement?: JSX.Element;
    scheduleCountElement?: JSX.Element;
    isLast?: boolean;
    style?: React.CSSProperties;
}

export const InventoryCurrentStateBlockItem: React.FC<InventoryCurrentStateBlockItemProps> = ({
    title,
    phoneMode,
    productId,
    variantId,
    instanceId,
    kitId,
    isEmpty,
    icon,
    iconClass,
    typeCode,
    countElement,
    scheduleCountElement,
    isLast = false,
    style,
}) => {
    return (
        <div
            className={classNames({
                'rr-product-page__main-indicators-block-item1-container-offset': !isLast,
            })}
            style={style}
        >
            <Col
                span={phoneMode ? 8 : 24}
                className={'rr-product-page__main-indicators-block-item1'}
                style={{
                    marginBottom: isLast || scheduleCountElement ? undefined : 17,
                    padding: 0,
                }}
            >
                <>
                    <div className={'rr-product-page__main-indicators-block-item1-label'}>{title}</div>
                    <ProductCalendarPopover
                        kitId={kitId}
                        productId={productId}
                        variantId={variantId}
                        instanceId={instanceId}
                        typeCode={typeCode}
                    >
                        <div
                            style={{ display: 'flex', alignItems: 'center', pointerEvents: isEmpty ? 'none' : undefined }}
                            className={'rr-product-page__main-indicators-link'}
                        >
                            <Icon
                                component={icon}
                                className={classNames(
                                    'rr-product-page__main-indicators-block-item1-icon',
                                    isEmpty ? 'rr-product-page__main-indicators-block-item1-icon-empty' : iconClass
                                )}
                            />
                            <span
                                className="rr-product-page__main-indicators-block-item1-value"
                                style={{ color: isEmpty ? '#abacb0' : undefined }}
                            >
                                {isEmpty ? '?' : countElement}
                            </span>
                        </div>
                    </ProductCalendarPopover>
                </>
            </Col>
            {!isEmpty && scheduleCountElement && (
                <div className={'rr-product-page__main-indicators-block-item1-schedule'}>
                    По расписанию: <span>{scheduleCountElement}</span>
                </div>
            )}
        </div>
    );
};
