import { KitsListFilters } from './kits.api.types';
import { ServerUtils } from '../../../../../core/utils/serverUtils';
import { KitRecord } from '../../../../../server';
import { MoneyUtils } from '../../../../../core/utils/moneyUtils';
import { ProblemEnum } from '../../../../../types';
import { getStringServerProblem } from '../../../../../shared/util/utils';
import { IntervalFiltersFormatsMap, IntervalFiltersMetricsMap } from '../../../../../components/page/entityGrid/types/api';

export abstract class KitsApiUtils {
    static getRequestFilters = (params: KitsListFilters, subrentModuleEnabled: boolean | undefined) => {
        const { categoryIds, totalDiscount, finalTotalPrice, hideArchive, problem, productId, status, variantId, contactId } = params;

        const mainFilters = ServerUtils.createRequestFilters<KitRecord & { contactIds: number }>([
            status && ['stateCode', 'EQ', status],
            hideArchive && ['archive', 'EQ', false],
            categoryIds && ['categoryIds', 'IN', Array.isArray(categoryIds) ? categoryIds : [categoryIds]],
            finalTotalPrice?.[0] && ['finalTotalPrice', 'GE', MoneyUtils.toCents(finalTotalPrice[0])],
            finalTotalPrice?.[1] && ['finalTotalPrice', 'GE', MoneyUtils.toCents(finalTotalPrice[1])],
            totalDiscount?.[0] && ['totalDiscount', 'GE', MoneyUtils.toCents(totalDiscount[0])],
            totalDiscount?.[1] && ['totalDiscount', 'GE', MoneyUtils.toCents(totalDiscount[1])],
            productId && ['productIds', 'IN', [productId]],
            variantId && ['variantIds', 'IN', [variantId]],
            contactId && ['contactIds', 'EQ', contactId],
        ]);

        if (
            problem &&
            (subrentModuleEnabled ||
                (problem !== ProblemEnum.SUBRENT_SHIPMENT_DELAY && problem !== ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY))
        ) {
            mainFilters.push(getStringServerProblem(problem));
        }

        return mainFilters;
    };
    static productsIntervalMapFunction: IntervalFiltersMetricsMap<KitRecord> = {
        finalTotalPrice: MoneyUtils.toWhole,
        totalDiscount: (value) => value,
    };
    static productsIntervalFormatStyleMap: IntervalFiltersFormatsMap<KitRecord> = {
        finalTotalPrice: 'currency',
        totalDiscount: 'percents',
    };
}
