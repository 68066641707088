export enum Color {
    /** Статус активный, Возврат, Создать новую сущнусть */
    Green = 'green',

    /** Черновик */
    WhiteGrey1 = 'white-grey-1', // TODO Нужно переименовать будет в WhiteGrey1Dashed

    /** Много где используется, Черновик рамка, цвет шрифта */
    Grey1 = 'grey-1',

    /** Статус заблокирован, недоступен, Отмена, На обслуживании */
    Grey2 = 'grey-2',

    /** В архив */
    Grey3 = 'grey-3',

    /** Фиолетовый, наш основной */
    Purple1 = 'purple-1',

    /** Еще один фиолетовый */
    Purple2 = 'purple-2',

    /** Бронирование / Забронирован */
    Purple3 = 'purple-3',

    /** Еще один фиолетовый (Например кнопка редактирования в гриде) */
    Purple4 = 'purple-4',

    /** Статус новый, Заявка / В заявке */
    Azure = 'azure',

    /** Статус новый, Заявка / В заявке */
    Orange1 = 'orange-1',
    Orange3 = 'orange-3',

    /** Включить в стоимость */
    WhiteOrange1Solid = 'white-orange-1-solid',

    /** Исключить из стоимости */
    WhiteGrey2Solid = 'white-grey-2-solid',

    /** Из архива Кнопка */
    Cyan = 'cyan',

    /**  */
    Red1 = 'red-1',

    /**  */
    Blue1 = 'blue-1',

    /**  */
    Blue2 = 'blue-2',

    /**  */
    Brown = 'brown',

    /** #e83e8c, Розовый, Корректировка */
    Pink1 = 'pink-1',
}
