import {isNaN} from "lodash";

export enum SoundType {
    scanError = 'scanError',
    scanInstanceAlreadyAddedError = 'scanInstanceAlreadyAddedError',
    scanInstanceAddedSuccess = 'scanInstanceAddedSuccess',
    scanKitAddedSuccess = 'scanKitAddedSuccess',
}

const FIRST_SKIP_TIME = 1000;
const SKIP_TIME = 300;

class Sound {

    public isPlaying = false;
    public isStopping = false;
    private sound:HTMLAudioElement;

    private onStop?:Function;

    constructor(audio:HTMLAudioElement, onStop) {
        this.sound = audio;
        this.onStop = onStop;
        this.sound.addEventListener('ended', this.onEnd);
    }

    private onEnd = ()=> {
        this.isPlaying = false;
        this.isStopping = false;
        this.onStop?.();
    }

    public stop () {
        this.isStopping = true;
        const skipTime = isNaN(this.sound.duration) ? FIRST_SKIP_TIME : SKIP_TIME;
        //const intervalId = setInterval(()=>{
        //if(this.sound.readyState === 4 && !isNaN(this.sound.duration)) {
        if (this.sound.currentTime + skipTime / 1000 < this.sound.duration) {
            setTimeout(() => {
                if (!this.sound.ended){
                    this.sound.removeEventListener('ended', this.onEnd);
                    this.sound.currentTime = this.sound.duration;
                    this.isPlaying = false;
                    this.isStopping = false;
                    this.onStop?.();
                }
            }, skipTime);
        }
        //clearInterval(intervalId);
        //}
        //}, 10);
    };

    public play () {
        this.isPlaying = true;
        void this.sound.play();
    };
}

export class SoundEffectsPlayer {

    public data:{ [key: string]: string; } = {};

    public registerSound(path:string, name:string) {
        fetch(path)
            .then(function(response) {return response.blob()})
            .then((blob)=> {
                this.data[name] = URL.createObjectURL(blob);
            });
    }

    private sounds:Sound[] = [];

    public playOrder(name:string) {
        const a = new Audio(this.data[name]);
        const audio = new Sound(a, () =>{
            const index = this.sounds.indexOf(audio);
            this.sounds.splice(index, 1);
            this.playNext();
        });
        this.sounds.push(audio);
        this.playNext();
    }

    public play(name:string) {
        const a = new Audio(this.data[name]);
        void a.play();
    }

    private playNext () {
        if(this.sounds.length > 0){
            if(!this.sounds[0].isPlaying) this.sounds[0].play();
            if(this.sounds.length > 1){
                if(this.sounds[0].isPlaying && !this.sounds[0].isStopping) this.sounds[0].stop();
            }
        }
    }
}
