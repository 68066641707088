import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import React, { ComponentType, CSSProperties, FC, memo, ReactNode } from 'react';
import { useAppSelector } from '../../../../../../../store/hooks';
import { canViewFinancialDataSelector } from '../../../../../../../shared/reducers/permissions.reducer';
import { DashboardWidgetHeaderIconBlock } from '../../components/header/components/IconBlock/DashboardWidgetHeaderIconBlock';
import { IconTags } from '../../../../../../../components/icons';
import classNames from 'classnames';
import { DashboardWidgetLoader } from '../../components/loader/DashboardWidgetLoader/DashboardWidgetLoader';
import { DashboardWidgetBaseCurrency } from '../../components/currency/DashboardWidgetBaseCurrency/DashboardWidgetBaseCurrency';

interface DashboardWidgetBaseProps {
    color: string;
    elementsCount: number;
    elementsIcons: [ComponentType, ComponentType];
    fill?: boolean;
    icon: ComponentType;
    instancesCount: number;
    sum: number;
    title: string | ReactNode;
    url: string;
    loading: boolean | undefined;
    style?: CSSProperties;
    XXL?: boolean;
}

export const DashboardWidgetBase: FC<DashboardWidgetBaseProps> = memo((props) => {
    const { color, elementsCount, elementsIcons, fill, icon, instancesCount, sum, title, url, loading } = props;
    const isCanViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    const isIconBlockDisabled = elementsCount === 0 || (elementsCount[0] === 0 && elementsCount[1] === 0);

    if (loading) return <DashboardWidgetLoader />;

    const contentWidget = (
        <div style={{ display: 'flex', flexGrow: 1, ...props.style }}>
            <DashboardWidgetHeaderIconBlock color={color} isDisabled={isIconBlockDisabled} icon={icon} fill={fill} />
            <div
                style={{
                    color: 'rgb(87, 89, 98)',
                    height: isCanViewFinancialData ? undefined : 108,
                }}
                className="rr-dashboard-info-panel-div"
            >
                <div>{title}</div>
                <DashboardWidgetBaseCurrency elementsCount={elementsCount} sum={sum} />

                <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 15 }}>
                    <div className={'rr-dashboard-info-panel-flex-block'} style={{ display: 'flex', flexWrap: 'nowrap' }}>
                        <Icon
                            component={elementsCount ? elementsIcons[0] : IconTags}
                            className={'rr-dashboard-info-panel-icon'}
                            style={{ color: `${elementsCount === 0 ? '#aaaaaa' : 'rgb(87, 89, 98)'}` }}
                        />
                        <span
                            className={classNames({
                                'rr-dashboard-info-panel-right-span-1': elementsCount === 0,
                                'rr-dashboard-info-panel-right-span-2': elementsCount !== 0,
                            })}
                        >
                            {elementsCount}{' '}
                        </span>
                    </div>
                    <div className={'rr-dashboard-info-panel-flex-block'} style={{ display: 'flex', flexWrap: 'nowrap' }}>
                        <Icon
                            component={elementsIcons[1]}
                            className="left-block-icon rr-dashboard-info-panel-icon"
                            style={{ marginTop: 1 }}
                        />
                        <span
                            style={{
                                fontSize: '18px',
                                color: `${instancesCount === 0 ? '#aaaaaa' : 'rgb(170, 170, 170)'}`,
                            }}
                        >
                            {instancesCount}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <Link to={url} className={'rr-dashboard-info-panel-link-1'} style={props.XXL ? { marginBottom: 0, height: '100%' } : undefined}>
            {contentWidget}
        </Link>
    );
});
