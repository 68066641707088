import { ColumnTypes, TableColumn } from '../../../components/grid/Table';
import { Icon } from 'antd';
import { IconBell, IconCrosshairsSolid, IconPencil } from '../../../components/icons';
import { Link } from 'react-router-dom';
import {
    InstanceTrackingTypeCodeEnum,
    InventoryMovementEventTypeCodeEnum,
    NomenclatureEntityTypeCodeEnum,
    OperationInfoRead,
    RentElementResolutionCodeEnum,
    RentStateCodeEnum,
} from '../../../server';
import { EntityType, getStoreState } from '../../../../index';
import { LocalizationEnum, localize } from '../../../localization';
import { getActionsColumnWidth, GRID_IMAGE_COLUMN_WIDTH, GRID_PROBLEM_COLUMN_WIDTH, MAX_WIDTH } from '../../../config/constants';
import DashboardDate from '../../../components/dashboardDate/dashboardDate';
import { ProductVariantPopover } from '../inventory/variants/components/productVariantPopover/productVariantPopover';
import { HelpTooltip } from '../../../components/helpTooltip/HelpTooltip';
import { RentElementsGridItem } from '../../../types';
import { tableCellProductNameRenderer } from '../../../components/grid/renderers/tableCellProductNameRenderer';
import { CurrentIndicatorsProblemIcons } from '../../../components/currentRentIndicators/currentIndicatorsProblemIcons';
import { tableCellStatusRenderer } from '../../../components/grid/renderers/tableCellStatusRenderer';
import { InstancePropertiesEntityRecordCustom } from '../operationForm/reducers/operationForm.reducer';
import { PricingSchemePricePopover } from '../settings/pricingSchemes/pricingSchemePricePopover';
import { tableCellMoneyRenderer } from '../../../components/grid/renderers/tableCellMoneyRenderer';
import { tableCellShiftCountRenderer } from '../../../components/grid/renderers/tableCellShiftCountRenderer';
import { GridRowActions } from '../../../components/v2/gridActions/gridActions';
import { ActionTypeEnum } from '../../../core/types/ActionTypeEnum';
import { RentElementResolutionIcon } from './rentElementResolutionIcon';
import { isReturnOperation } from '../operationForm/utils/utils';
import { PricingSchemeMaps } from '../../../core/utils/pricingSchemeUtils';
import { GridColumnCreator } from '../../../components/grid/gridColumnCreator';
import { TableCellAvatarRenderer } from '../../../components/grid/renderers/tableCellAvatarRenderer';
import {isDefined} from "../../../shared/util/utils";

// const editIcon = () => <SystemIcon type={SystemIconType.rentElementActions} onClick={(e) => e.stopPropagation()} />;

// const editIconDisabled = () => (
//     <SystemIcon type={SystemIconType.rentElementActions} style={{ opacity: 0.4, cursor: 'default' }} onClick={(e) => e.stopPropagation()} />
// );

const tagsSizes = GridColumnCreator.getColumnSizes(50);

// GRID COLUMNS
export const getElementColumns = (actionCount: number, withActions: boolean = true): TableColumn<any>[] => {
    const columns: TableColumn<any>[] = [
        {
            title: <Icon style={{ fontSize: 21, paddingTop: 3 }} component={IconBell} />,
            dataIndex: 'problemSeverity' as any,
            type: ColumnTypes.CustomRender,
            minWidth: GRID_PROBLEM_COLUMN_WIDTH,
            width: GRID_PROBLEM_COLUMN_WIDTH,
            maxWidth: GRID_PROBLEM_COLUMN_WIDTH,
            resizable: false,
            sortable: true,
            dontHide: true,
            render: (value: Date, rowData: RentElementsGridItem) => {
                return (
                    <CurrentIndicatorsProblemIcons
                        problemsAndWarnings={rowData?.problemsAndWarnings}
                        entityType={'elements'}
                        typeRent={'all'}
                        id={rowData.id}
                        entity={rowData}
                    />
                );
            },
        },
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NUMBER),
            dataIndex: 'numberInActivityFrame',
            type: ColumnTypes.CustomRender,
            minWidth: 100,
            maxWidth: 100,
            resizable: false,
            defaultHidden: true,
            render: (value: any, rowData: RentElementsGridItem) => {
                let numberClass = rowData.nomenclatureEntityTypeCode !== NomenclatureEntityTypeCodeEnum.KIT ? ' app-link ' : ' ';
                numberClass +=
                    rowData.nomenclatureEntityTypeCode !==
                    NomenclatureEntityTypeCodeEnum.KIT /*&& !rowData.problemsAndWarnings?.anyDelay && !rowData.problemsAndWarnings?.anyShortage*/
                        ? ' main-value '
                        : ' ';
                return <strong className={numberClass}>#{rowData.numberInActivityFrame}</strong>;
            },
        },
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__IMAGE),
            dataIndex: 'image',
            type: ColumnTypes.CustomRender,
            width: GRID_IMAGE_COLUMN_WIDTH,
            maxWidth: GRID_IMAGE_COLUMN_WIDTH,
            minWidth: GRID_IMAGE_COLUMN_WIDTH,
            sortable: false,
            resizable: false,
            render: (value, rowData: RentElementsGridItem) => {
                return (
                    <div>
                        <TableCellAvatarRenderer value={value} rowData={rowData} />
                    </div>
                );
            },
        },
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NAME),
            dataIndex: 'nomenclatureShortName',
            type: ColumnTypes.CustomRender,
            ...GridColumnCreator.getColumnSizesForEntity('product'),
            resizable: true,
            render: (value: any, rowData: RentElementsGridItem) => {
                return tableCellProductNameRenderer(value, rowData);
            },
            dontHide: true,
            expandable: true,
        },
        {
            title: localize(LocalizationEnum.ASPECT__ENTITY_TYPE__VARIANT),
            dataIndex: 'variantName',
            type: ColumnTypes.CustomRender,
            ...GridColumnCreator.getColumnSizesForEntity('variant'),
            resizable: true,
            render: (value: any, rowData: RentElementsGridItem) => {
                return rowData.variantId ? (
                    <div className={'rr-grid-shortName-cell'} title={rowData.variantName} onClick={(e) => e.stopPropagation()}>
                        <ProductVariantPopover variantId={rowData.variantId} productId={rowData.productId}>
                            <span className="popover-header-name grid-column-variant">{value}</span>
                        </ProductVariantPopover>
                    </div>
                ) : null;
            },
        },
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__QUANTITY),
            dataIndex: 'instanceCount',
            type: ColumnTypes.CustomRender,
            minWidth: 100,
            resizable: false,
            render: (value: any, rowData: RentElementsGridItem) => {
                const anonymousInstanceCount =
                    rowData.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                    rowData.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
                        ? rowData.anonymousInstanceCount
                        : undefined;
                return (
                    <div className={' rr-grid-instanceCount-cell'}>
                        {value}
                        {anonymousInstanceCount !== undefined && anonymousInstanceCount > 0 ? (
                            <span style={{ fontWeight: rowData.instanceCount === 0 ? 400 : 500, opacity: 0.5 }}>
                                ({anonymousInstanceCount}?)
                            </span>
                        ) : null}
                    </div>
                );
            },
        },
        {
            title: 'Кол-во чужих',
            dataIndex: 'subrentedInstanceCount',
            type: ColumnTypes.CustomRender,
            minWidth: 100,
            resizable: false,
            defaultHidden: true,
            render: (value: any, rowData: RentElementsGridItem, onAction, entityType: EntityType) => {
                if (value) return value;
                else return '';
            },
        },
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__EXTERNAL_CODE),
            dataIndex: 'externalCode',
            type: ColumnTypes.CustomRender,
            minWidth: 110,
            resizable: true,
            defaultHidden: true,
            render: (value: any, rowData: InstancePropertiesEntityRecordCustom) => {
                return rowData.externalCode ? <div className={'rr-grid-text-cell-center'}>{rowData.externalCode}</div> : null;
            },
        },
        {
            title: 'Цена экз./см.',
            dataIndex: 'effectivePrice',
            type: ColumnTypes.CustomRender,
            className: 'money',
            minWidth: 140,
            resizable: true,
            render: (value: any, rowData: RentElementsGridItem): any => {
                let pricingScheme = rowData.effectivePricingScheme;

                return (
                    <>
                        {pricingScheme ? (
                            <PricingSchemePricePopover
                                data={{
                                    id: pricingScheme.id,
                                    typeCode: pricingScheme.typeCode,
                                    label: pricingScheme.name,
                                    price: rowData.basePriceAtTheTimeOfCreation,
                                    steps: pricingScheme.steps,
                                    shiftCount: rowData.rentTerms.shiftCount,
                                    roundingDirection: pricingScheme.roundingDirection,
                                    roundingLevel: pricingScheme.roundingLevel,
                                }}
                            >
                                <Icon
                                    onClick={(e) => e.stopPropagation()}
                                    className={'rr-grid-pricingScheme-icon'}
                                    component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[pricingScheme.typeCode]}
                                />
                            </PricingSchemePricePopover>
                        ) : null}
                        {tableCellMoneyRenderer(rowData.effectivePrice, rowData)}
                    </>
                );
            },
        },
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__STATUS),
            dataIndex: 'stateCode',
            type: ColumnTypes.CustomRender,
            minWidth: 130,
            maxWidth: MAX_WIDTH(130),
            resizable: false,
            render: (value: any, rowData: RentElementsGridItem, onAction, entityType: EntityType) => {
                return value ? (
                    tableCellStatusRenderer(value, rowData, entityType)
                ) : (
                    <span style={{ color: '#575962', opacity: 0.4 }}>{localize(LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__OTHER)}</span>
                );
            },
        },
        {
            title: <span title={'Резолюция'}>Резол.</span>,
            dataIndex: 'resolutionCodes',
            type: ColumnTypes.CustomRender,
            minWidth: 80,
            resizable: false,
            render: (value: any, rowData: RentElementsGridItem, onAction, entityType: EntityType) => {
                return rowData.resolutionCodes?.map((code, index, arr) => {
                    let eventType: InventoryMovementEventTypeCodeEnum | undefined;
                    if (code === RentElementResolutionCodeEnum.LOSTDURINGRENT) eventType = InventoryMovementEventTypeCodeEnum.LOSS;
                    else if (code === RentElementResolutionCodeEnum.RETURNEDBROKEN) eventType = InventoryMovementEventTypeCodeEnum.DAMAGE;
                    return (
                        <Link
                            style={index < arr.length - 1 ? { marginRight: 10 } : undefined}
                            to={`/${rowData.businessAccountId}/history/inventoryMovements?productId=${rowData.productId}${
                                rowData.variantId ? '&variantId=' + rowData.variantId : ''
                            }&eventType=${eventType}`}
                        >
                            <RentElementResolutionIcon code={code} />
                        </Link>
                    );
                });
            },
        },
        {
            title: 'Теги',
            dataIndex: 'tagsString',
            type: ColumnTypes.CustomRender,
            minWidth: tagsSizes.min,
            width: tagsSizes.width,
            maxWidth: tagsSizes.max,
            resizable: true,
            render: (value: string) => {
                return (
                    <div className={'rr-grid-shortName-cell rr-grid-text-cell-left'} title={value}>
                        <span>{value}</span>
                    </div>
                );
            },
        },
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__BEGIN),
            dataIndex: 'rentTerms.rentPeriodStartDate',
            type: ColumnTypes.DateTime,
            minWidth: 130,
            resizable: false,
        },
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__END_DATE),
            dataIndex: 'rentTerms.rentPeriodEndDate',
            type: ColumnTypes.DateTime,
            minWidth: 130,
            resizable: false,
        },
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__SHIFT_COUNT),
            dataIndex: 'rentTerms.shifts.shiftCount',
            type: ColumnTypes.CustomRender,
            minWidth: 100,
            resizable: false,
            className: 'shiftCount',
            render: (value: any, rowData: RentElementsGridItem): any => {
                if (rowData.kitId)
                    return rowData.rentTerms.shiftCountMin !== rowData.rentTerms.shiftCountMax ? (
                        <>
                            {tableCellShiftCountRenderer(rowData.rentTerms.shiftCountMin)}-
                            {tableCellShiftCountRenderer(rowData.rentTerms.shiftCountMax)}
                        </>
                    ) : (
                        tableCellShiftCountRenderer(rowData.rentTerms.shiftCount)
                    );
                else return tableCellShiftCountRenderer(rowData.rentTerms.shiftCount);
            },
        },
        {
            title: 'Отработанные смены',
            dataIndex: 'subrentWorkedShifts.shiftCount',
            type: ColumnTypes.CustomRender,
            minWidth: 150,
            resizable: false,
            defaultHidden: true,
            className: 'shiftCount',
            render: (value: any, rowData: RentElementsGridItem): any => {
                if (rowData.kitId)
                    return rowData.subrentWorkedShifts?.shiftCountMin !== rowData.subrentWorkedShifts?.shiftCountMax ? (
                        <>
                            {tableCellShiftCountRenderer(rowData.subrentWorkedShifts?.shiftCountMin)}-
                            {tableCellShiftCountRenderer(rowData.subrentWorkedShifts?.shiftCountMax)}
                        </>
                    ) : (
                        tableCellShiftCountRenderer(rowData.subrentWorkedShifts?.shiftCount)
                    );
                else {
                    return tableCellShiftCountRenderer(rowData.subrentWorkedShifts?.shiftCount);
                }
            },
        },
        {
            title: 'Календарные смены',
            dataIndex: 'calendarShifts.shiftCount',
            type: ColumnTypes.CustomRender,
            minWidth: 140,
            resizable: false,
            className: 'shiftCount',
            render: (value: any, rowData: RentElementsGridItem): any => {
                if (rowData.kitId)
                    return (
                        <span style={rowData.rentTerms.shiftCount !== rowData.calendarShifts?.shiftCount ? undefined : { color: '#ccc' }}>
                            {rowData.calendarShifts?.shiftCountMin !== rowData.calendarShifts?.shiftCountMax ? (
                                <>
                                    {tableCellShiftCountRenderer(rowData.calendarShifts?.shiftCountMin)}-
                                    {tableCellShiftCountRenderer(rowData.calendarShifts?.shiftCountMax)}
                                </>
                            ) : (
                                tableCellShiftCountRenderer(rowData.calendarShifts?.shiftCount)
                            )}
                        </span>
                    );
                else {
                    return (
                        <span style={rowData.rentTerms.shiftCount !== rowData.calendarShifts?.shiftCount ? undefined : { color: '#ccc' }}>
                            {tableCellShiftCountRenderer(rowData.calendarShifts?.shiftCount)}
                        </span>
                    );
                }
            },
        },
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__DISCOUNT),
            dataIndex: 'rentTerms.discount',
            type: ColumnTypes.Percents,
            minWidth: 100,
            resizable: false,
        },
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__COST_INSTANCE_PER_SHIFT),
            dataIndex: 'finalInstancePrice',
            type: ColumnTypes.Money,
            className: 'money',
            minWidth: 140,
            resizable: false,
        },
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__FINAL_TOTAL_PRICE),
            dataIndex: 'finalTotalPrice',
            type: ColumnTypes.Money,
            className: 'money',
            minWidth: 140,
            resizable: false,
        },
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIVITY),
            dataIndex: 'lastActivityDate',
            type: ColumnTypes.CustomRender,
            minWidth: 130,
            resizable: false,
            render: (value: any, rowData: RentElementsGridItem, onAction) => {
                let entity: OperationInfoRead | undefined = getStoreState().operation.entity;

                // let valueDate = moment(value).format('DD.MM.YYYY HH:mm');
                return entity && entity.id !== rowData.lastOperationId ? (
                    <HelpTooltip content={LocalizationEnum.PAGE__ELEMENTS__TOOLTIP__CURRENT_OPERATION}>
                        <span>
                            <Link
                                onClick={(e) => e.stopPropagation()}
                                to={`/${rowData.businessAccountId}/history/operations/${rowData.lastOperationId}?tab=description`}
                            >
                                <DashboardDate value={rowData.lastActivityDate} days={7} />
                            </Link>
                        </span>
                    </HelpTooltip>
                ) : (
                    <HelpTooltip content={LocalizationEnum.PAGE__ELEMENTS__TOOLTIP__CURRENT_OPERATION}>
                        <span className={entity ? `actionColumHover rr-elements-grid-activity-bold` : ``}>
                            {' '}
                            <Link
                                onClick={(e) => e.stopPropagation()}
                                to={`/${rowData.businessAccountId}/history/operations/${rowData.lastOperationId}?tab=description`}
                                className="rr-elements-grid-activity-link"
                            >
                                <span>
                                    {entity ? <Icon component={IconCrosshairsSolid} className="rr-elements-grid-activity-icon" style={{verticalAlign: 'text-bottom'}} /> : null}
                                    <DashboardDate value={rowData.lastActivityDate} days={7} />
                                </span>
                            </Link>
                        </span>
                    </HelpTooltip>
                );
            },
        },
    ];

    if (withActions) {
        columns.push({
            title: (
                <Icon
                    title={LocalizationEnum.ASPECT__GRID__COLUMN__ACTIONS}
                    style={{ fontSize: 21, paddingTop: 2 }}
                    component={IconPencil}
                />
            ),
            dataIndex: '',
            type: ColumnTypes.CustomRender,
            width: getActionsColumnWidth(actionCount),
            minWidth: getActionsColumnWidth(actionCount),
            maxWidth: getActionsColumnWidth(actionCount),
            resizable: false,
            sortable: false,
            id: 'actions',
            className: 'actions',
            headerClassName: 'actions',
            render: (value: any, rowData: RentElementsGridItem, onAction: Function) => {
                const disabled = !!rowData.kitId || (rowData.stateCode !== RentStateCodeEnum.RENT && !isReturnOperation(rowData.stateCode));
                const isProject = isDefined(rowData.projectId) && !rowData.projectIsOffer && !rowData.projectIsSimpleOrder;

                return (
                    <GridRowActions
                        onAction={(type) => {
                            onAction(rowData, type);
                        }}
                        onElementAction={onAction}
                        rowData={rowData}
                        additionalActions={[
                            {
                                type: ActionTypeEnum.ELEMENT_ACTIONS,
                                disabled: !rowData.availableTransitionCodes || rowData.availableTransitionCodes.length === 0,
                            },
                            isProject && { type: ActionTypeEnum.CREATE_DAMAGE_INVENTORY_MOVEMENT, disabled },
                            isProject && { type: ActionTypeEnum.CREATE_LOSS_INVENTORY_MOVEMENT, disabled },
                        ]}
                    />
                );
            },
        });
    }

    return columns;
};
