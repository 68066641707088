import React from 'react';
import { Icon } from 'antd';
import { RoundButton } from '../index';
import { IconEllipsisHSolid } from '../icons';
import { ButtonProps } from 'antd/lib/button/button';
import classNames from 'classnames';

interface IProps extends ButtonProps {}

export const AdditionalEntityActionsButton = ({ className, ...props }: IProps) => (
    <span style={{ height: 40, display: 'inline-block' }}>
        <RoundButton colorScheme={'default'} className={classNames('button-three-points', className)} {...props}>
            <Icon component={IconEllipsisHSolid} style={{ fontSize: '36px' }} />
        </RoundButton>
    </span>
);
