import {CreateExpenseArgs, ExpenseState, UpdateExpenseArgs} from './expense.types';
import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit';
import {serverApi} from '../../../../../server';
import {showNotification} from '../../../../../components/notification/showNotification';
import {goBack, push} from 'connected-react-router';
import {expensesApi, getExpenseByIdQueryFunction} from '../../api/expenses.api';
import {getServerError} from '../../../../../shared/util/utils';
import {ParamsUtils} from '../../../../../core/utils/paramsUtils';
import {IRootState} from "../../../../../shared/reducers";
import {loadEntity} from "../../../projects/production/reducers/project.reducer";
import {loadOffer} from "../../../offers/reducers/offer/offer.reducer";
import {AppDispatch, GetState} from "../../../../../../index";
import {loadSimpleOrder} from "../../../projects/simpleOrders/reducers/simpleOrder.reducer";

export const updateProjectEntity = (getState:GetState, dispatch:AppDispatch) => {
    // Обновить карточку Проекта
    const project = getState().project.entity;
    if(project) dispatch(loadEntity(project.businessAccountId, project.id));

    // Обновить карточку сметы
    const offer = (getState() as IRootState).offer.entity;
    if(offer) dispatch(loadOffer({businessAccountId: offer.businessAccountId, entityId: offer.id}));

    // Обновить карточку заказа
    const simpleOrder = (getState() as IRootState).simpleOrder.entity;
    if(simpleOrder) dispatch(loadSimpleOrder(simpleOrder.businessAccountId, simpleOrder.id));
};

const ACTION_TYPES = {
    CREATE_EXPENSE: 'expense/CREATE_EXPENSE',
    UPDATE_EXPENSE: 'expense/UPDATE_EXPENSE',
    LOAD_EXPENSE: 'expense/LOAD_EXPENSE',
};

export const initialState: ExpenseState = {
    loading: false,
    loadingError: undefined,
    updating: false,
    updatingError: undefined,
    entity: null,
};

const createExpenseThunk = createAsyncThunk(ACTION_TYPES.CREATE_EXPENSE, async (args: CreateExpenseArgs) => {
    const { businessAccountId, expenseInfoCreate } = args;

    try {
        return await serverApi.createExpense(businessAccountId, expenseInfoCreate);
    } catch (error: unknown) {
        const errorMessage = getServerError(error);
        throw new Error(errorMessage?.message);
    }
});
export const createExpense = (args: CreateExpenseArgs, rootPath: string, urlSearchParams?: URLSearchParams) => (dispatch, getState) => {
    return dispatch(createExpenseThunk(args))
        .unwrap()
        .then(() => {
            showNotification('success', 'Затрата создана');
            let path = rootPath;
            if (urlSearchParams) {
                path += `/?${ParamsUtils.deleteDrawerParams(urlSearchParams).toString()}`;
            }
            dispatch(push(path));
            dispatch(expensesApi.util?.invalidateTags(['ExpensesList', 'Expense']));
            updateProjectEntity(getState, dispatch);
        })
        .catch(() => {
            showNotification('error', 'Не удалось создать затрату');
        });
};

export const updateExpenseThunk = createAsyncThunk(ACTION_TYPES.UPDATE_EXPENSE, async (args: UpdateExpenseArgs) => {
    const { businessAccountId, id, expenseInfoUpdate } = args;

    try {
        return await serverApi.updateExpense(businessAccountId, id, expenseInfoUpdate);
    } catch (error: unknown) {
        const errorMessage = getServerError(error);
        throw new Error(errorMessage?.message);
    }
});
export const updateExpense = (args: UpdateExpenseArgs) => (dispatch, getState) => {
    return dispatch(updateExpenseThunk(args))
        .unwrap()
        .then(() => {
            showNotification('success', 'Затрата обновлена');
            dispatch(goBack());
            dispatch(expensesApi.util?.invalidateTags(['ExpensesList', 'Expense']));
            updateProjectEntity(getState, dispatch);
        })
        .catch(() => {
            showNotification('error', 'Не удалось обновить затрату');
        });
};

export const loadExpense = createAsyncThunk(ACTION_TYPES.CREATE_EXPENSE, getExpenseByIdQueryFunction);

const expenseSlice = createSlice({
    name: 'expense',
    initialState,
    reducers: {
        clearExpense: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isAnyOf(loadExpense.pending, updateExpenseThunk.pending, createExpenseThunk.pending), (state, action) => {
                state.updating = true;
                state.updatingError = undefined;
            })
            .addMatcher(isAnyOf(loadExpense.fulfilled, updateExpenseThunk.fulfilled, createExpenseThunk.fulfilled), (state, action) => {
                state.updating = false;
                state.entity = action.payload.data;
            })
            .addMatcher(isAnyOf(loadExpense.rejected, updateExpenseThunk.rejected, createExpenseThunk.rejected), (state, action) => {
                state.updating = false;
                state.updatingError = action.error;
            });
    },
});

export const { clearExpense } = expenseSlice.actions;
export default expenseSlice.reducer;
