import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { OperationTypeCodeEnum, ProjectInfoRead } from '../../../server';
import { Location } from 'history';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { GridName } from '../../../components/grid/utils/types';
import { IDownloadDocumentParamsItem } from '../../../components/exportPopovers/downloadDocument/DownloadDocumentPopover';
import { ElementsListEdit } from './elementsListEdit';
import { ElementsList, ElementsListRef } from './elements-list';
import { reset as resetElements } from './reducers/elements.reducer';
import { reset as resetElement } from './reducers/element.reducer';

export interface ElementsListProps extends StateProps, DispatchProps, WrappedComponentProps {
    location: Location;
    parentId: number;
    parentType: 'project' | 'operation' | 'projectTemplate' | 'shipping';
    operationEntityType?: 'project' | 'projectTemplate' | 'shipping';
    visible?: boolean;
    gridName: GridName;
    excludeColumns?: string[];
    excludeFields?: string[];
    parentProjectEntity?: ProjectInfoRead;
    exportActOptions?: IDownloadDocumentParamsItem[];
    exportEstimateOptions?: IDownloadDocumentParamsItem[];
    updateParentEntity: () => void;
    projectIdForOperation?: number;
    projectIsSimpleOrderForOperation?: boolean;
}

class Component extends React.Component<ElementsListProps> {
    static defaultProps = {
        visible: true,
    };

    private elementsListRef: ElementsListRef | undefined;

    constructor(props: ElementsListProps) {
        super(props);
    }

    componentWillUnmount = () => {
        this.props.resetElements();
        this.props.resetElement();
    };

    public loadDataAndSetFilterValues = () => {
        if (this.elementsListRef && this.elementsListRef.loadDataAndSetFilterValues) {
            this.elementsListRef.loadDataAndSetFilterValues();
        }
    };

    render() {
        const { operationTypeCode, operationFormProjectId, parentId, parentType, projectIdForOperation } = this.props;
        let elementsIsNowEditing =
            parentType !== 'operation' && operationTypeCode === OperationTypeCodeEnum.EDIT && operationFormProjectId === parentId;
        if (
            parentType === 'operation' &&
            operationTypeCode === OperationTypeCodeEnum.EDIT &&
            operationFormProjectId === projectIdForOperation
        )
            elementsIsNowEditing = true;

        return (
            <>
                {elementsIsNowEditing ? (
                    <ElementsListEdit
                        ref={(ref) => {
                            this.elementsListRef = ref;
                        }}
                        {...this.props}
                    />
                ) : (
                    <ElementsList
                        {...this.props}
                        ref={(ref) => {
                            this.elementsListRef = ref;
                        }}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        operationTypeCode: storeState.operationForm.typeCode,
        operationFormProjectId: storeState.operationForm.projectId,
    };
};

const mapDispatchToProps = {
    resetElements,
    resetElement,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const ElementsListWrapper = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    injectIntl(Component, { forwardRef: true })
);
