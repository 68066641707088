import React from 'react';
import { Popover } from 'antd';
import { PricingLadderStepObj, PricingSchemeTypeCodeEnum, RoundingDirectionCodeEnum, RoundingLevelCodeEnum } from '../../../../server';
import PricingSchemePopoverContent from './pricingSchemePopoverContent';
import { TooltipTrigger } from 'antd/lib/tooltip';
import { PricingSchemePricePopoverContent } from './pricingSchemePricePopoverContent';
import './pricingSchemePricePopover.less';
import { joinClasses } from '../../../../shared/util/utils';

interface IProps {
    data: PricingSchemePopoverData;
    trigger?: TooltipTrigger;
}

interface IState {
    opened: boolean;
    visible: boolean;
    dataLoaded: boolean;
}

export interface PricingSchemePopoverData {
    id: number;
    label: string;
    price: number;
    typeCode?: PricingSchemeTypeCodeEnum;
    steps?: PricingLadderStepObj[];
    shiftCount?: number;
    roundingLevel: RoundingLevelCodeEnum | undefined;
    roundingDirection: RoundingDirectionCodeEnum | undefined;
}

export class PricingSchemePricePopover extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            opened: false,
            visible: false,
            dataLoaded: false,
        };
    }

    render() {
        return (
            <Popover
                overlayClassName={joinClasses(
                    'rr-PricingScheme-price__popover',
                    this.state.dataLoaded ? 'rr-PricingScheme-price__popover-full' : undefined
                )}
                visible={this.state.visible}
                onVisibleChange={(v) => {
                    this.setState({ visible: v });
                    if (!v) {
                        this.setState({ opened: false, dataLoaded: false });
                    }
                }}
                destroyTooltipOnHide={true}
                trigger={this.props.trigger || 'click'}
                placement={'leftTop'}
                content={
                    <div onClick={(e) => e.stopPropagation()}>
                        {
                            <>
                                {!this.state.dataLoaded ? (
                                    <PricingSchemePricePopoverContent
                                        data={this.props.data}
                                        showMore={() => {
                                            this.setState({ opened: true });
                                        }}
                                    />
                                ) : null}
                                {this.state.opened ? (
                                    <PricingSchemePopoverContent
                                        style={{ display: this.state.dataLoaded ? 'block' : 'none', width: 570 }}
                                        record={{ id: this.props.data.id, name: this.props.data.label, typeCode: this.props.data.typeCode }}
                                        closeCb={() => {
                                            this.setState({ opened: false, visible: false });
                                        }}
                                        onDataChangedCb={() => {
                                            this.setState({ dataLoaded: true }, () => {
                                                this.forceUpdate();
                                            });
                                        }}
                                    ></PricingSchemePopoverContent>
                                ) : null}
                            </>
                        }
                    </div>
                }
            >
                {this.props.children}
            </Popover>
        );
    }
}
