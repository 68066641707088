import { LocationsList } from './list/LocationsList';
import LocationsMap from './map/LocationsMap';
import ViewSwitcher from './components/ViewSwitcher';
import { LogisticsPageTabsEnum } from '../../../../../shared/constants/tabEnums';
import { useHistory } from 'react-router-dom';
import { getStateFromPath2 } from '../../../../../shared/util/utils';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { IRootState } from '../../../../../shared/reducers';
import { updateLocationViewSettings } from '../../../../../shared/reducers/userSettings/userSettings.reducer';

export type LocationViewType = 'list' | 'map';

const LocationsTab = () => {
    const dispatch = useAppDispatch();
    const viewType = useAppSelector((state: IRootState) => state.userSettings.locationViewType);
    const history = useHistory();

    const viewChangeHandler = useCallback(
        (view: LocationViewType) => {
            const prevParams = getStateFromPath2(history.location.search);
            const searchParams = new URLSearchParams({ ...prevParams, viewType: view });

            history.replace({ search: searchParams.toString() });
            dispatch(updateLocationViewSettings({ locationViewType: view }));
        },
        [history, dispatch]
    );

    useEffect(() => {
        viewChangeHandler(viewType);
    }, [viewChangeHandler, viewType]);

    return viewType === 'list' ? (
        <LocationsList
            gridName={LogisticsPageTabsEnum.LOCATIONS}
            switcher={<ViewSwitcher value={viewType} onChange={viewChangeHandler} style={{ marginRight: 20 }} />}
        />
    ) : (
        <LocationsMap switcher={<ViewSwitcher value={viewType} onChange={viewChangeHandler} style={{ marginBottom: 20 }} />} />
    );
};
export default LocationsTab;
