import { tableCellDateTimeRenderer } from '../grid/renderers/tableCellDateTimeRenderer';
import './DateValue.less';

interface Props {
    value: any;
    highlighted?: boolean;
    format?: string;
}

const DateValue = ({ value, highlighted, format = 'DD.MM.YY HH:mm' }: Props) => {
    const result = tableCellDateTimeRenderer(value, undefined, format);
    return highlighted ? <span className="rr-date-value-highlighted">{result}</span> : <>{result}</>;
};

export default DateValue;
