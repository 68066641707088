import React from 'react';
import { LocalizationEnum, localize } from '../../../../../localization';
import { IconExcel } from '../../../../icons';
import { Icon } from 'antd';
import { DownloadDocumentPopover, IDownloadDocumentParamsItem } from '../../DownloadDocumentPopover';
import { availableFileTypes, ExportToExcellOnDownload } from './utils/data';

export interface ExportToExcellPopoverProps {
    onDownload: ExportToExcellOnDownload;
    options: IDownloadDocumentParamsItem[];
    storageKey: string;
    getFilters: (freeItems: boolean) => string[];
}

export class ExportToExcellPopover extends React.Component<ExportToExcellPopoverProps> {
    public static defaultProps = {
        withoutAllColumnsExport: false,
    };

    render() {
        const { storageKey, children, options, getFilters } = this.props;

        return (
            <DownloadDocumentPopover
                getFilters={getFilters}
                availableFileTypes={[...availableFileTypes]}
                storageKey={storageKey}
                options={options}
                checkBeforeDownload
                title={
                    <>
                        <Icon style={{ fontSize: 20, marginRight: 10 }} component={IconExcel} />
                        <div>{localize(LocalizationEnum.ASPECT__EXCEL_EXPORT__POPUP_LABEL)}</div>
                    </>
                }
                onDownload={this.props.onDownload}
                isCompact
            >
                {children ? (
                    children
                ) : (
                    <span className={'rr-grid-export-button'} style={{ marginRight: 17, marginLeft: 14 }}>
                        <Icon component={IconExcel} />
                    </span>
                )}
            </DownloadDocumentPopover>
        );
    }
}
