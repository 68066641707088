import React from 'react';
import {BaseSelect, BaseSelectBaseProps, BaseSelectProps, BaseSelectValue} from '../baseSelect';
import {ProjectRecord, ProjectStateCodeEnum, serverApi, SubrentRecord, SubrentStateCodeEnum} from '../../../../server';
import { ServerUtils } from '../../../../core/utils/serverUtils';
import { useAppSelector } from '../../../../store/hooks';

export interface SubrentSelectProps extends BaseSelectBaseProps<SubrentRecord> {
    getOptionProps?: BaseSelectProps<SubrentRecord>['getOptionProps'];
    supplierId?: number;
    stateCode?: SubrentStateCodeEnum[];
}

const SubrentSelectFC = ({ supplierId, stateCode, ...props }: SubrentSelectProps) => {
    const businessAccountId = useAppSelector((state) => state.system.businessAccountId);

    const getEntitiesById = async (id: BaseSelectValue) => {
        const ids = Array.isArray(id) ? id.join(';') : id;
        const response = await serverApi.listSubrents(businessAccountId, undefined, 0, [`id;IN;${ids}`]);
        return response.data.records;
    };

    const getEntitiesList = async (startFrom: number, limit: number, search?: string) => {
        let filters = ServerUtils.createRequestFilters<ProjectRecord>([
            supplierId ? ['renterId', 'EQ', supplierId] : undefined,
            stateCode ? ['stateCode', 'IN', stateCode] : undefined,
        ]);
        const response = await serverApi.listSubrents(businessAccountId, limit, startFrom, filters, 'lastActivityDate', 'DESC', search);
        return response.data.records;
    };

    return (
        <BaseSelect
            {...props}
            fetchRecordsById={getEntitiesById}
            fetchRecords={getEntitiesList}
            getRecordId={(record) => record.id}
            getOptionProps={props.getOptionProps || ((record) => ({
                label: record.shortName,
                children: record.shortName,
            }))}
        />
    );
};

export class SubrentSelect extends React.PureComponent<SubrentSelectProps> {
    render() {
        return <SubrentSelectFC {...this.props} />;
    }
}
