import React, { ReactNode, useCallback, useState } from 'react';
import { Button, Popover } from 'antd';
import './ActionsPopover.less';
import { PopoverProps } from 'antd/lib/popover';
import { AbstractTooltipProps } from 'antd/lib/tooltip';
import { ActionTypeEnum } from '../../../core/types/ActionTypeEnum';

interface ActionsPopoverProps {
    children: ReactNode;
    title?: ReactNode;
    icon?: ReactNode;
    items: (ActionItem | undefined | null | false)[];
    getPopupContainer?: PopoverProps['getPopupContainer'];
    align?: AbstractTooltipProps['align'];
}

export interface ActionItem {
    disabled?: boolean;
    title?: React.ReactNode;
    type?: ActionTypeEnum;
    icon?: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    wrapperComponent?: any;
    wrapperComponentProps?: any;
}

export const ActionsPopoverV2 = ({ children, icon, title, items, ...props }: ActionsPopoverProps) => {
    const [visible, setVisible] = useState(false);

    const visibleChangeHandler = useCallback((visible: boolean) => {
        setVisible(visible);
    }, []);

    return (
        <Popover
            visible={visible}
            onVisibleChange={visibleChangeHandler}
            overlayClassName={'rr-actions-popover'}
            autoAdjustOverflow
            //arrowPointAtCenter
            placement="bottomLeft"
            trigger="click"
            align={props.align /*{offset:[0, -10]}*/} // Закомментить  autoAdjustOverflow и arrowPointAtCenter
            getPopupContainer={props.getPopupContainer} // getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
            content={
                <>
                    <div className={'rr-actions-popover-header'}>
                        {(icon || title) && (
                            <div>
                                {icon && <div style={{ marginRight: 12, lineHeight: 0 }}>{icon}</div>}
                                {title}
                            </div>
                        )}
                    </div>
                    <div className={'rr-actions-popover-content'}>
                        {items
                            .filter((item) => item)
                            .map((item, index) => {
                                if (item) {
                                    const button = (
                                        <Button
                                            key={index}
                                            disabled={item.disabled}
                                            block
                                            onClick={(e) => {
                                                //e.stopPropagation(); // Если нужно сделать stopPropagation, то это можно сделать в onClick, куда теперь передается event
                                                if (item.type !== ActionTypeEnum.ELEMENT_ACTIONS) {
                                                    setVisible(false);
                                                }
                                                if (item.onClick) item.onClick(e);
                                            }}
                                        >
                                            {item.icon}
                                            {item.title}
                                        </Button>
                                    );
                                    if (item.wrapperComponent) {
                                        return React.createElement(
                                            item.wrapperComponent,
                                            item.wrapperComponentProps,
                                            item.type === ActionTypeEnum.ELEMENT_ACTIONS ? () => button : button
                                        );
                                    } else return button;
                                } else return null;
                            })}
                    </div>
                </>
            }
        >
            {children}
        </Popover>
    );
};
