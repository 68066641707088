import React, { FC, memo } from 'react';
import { Card, Col, Row } from 'antd';
import { useAppSelector } from '../../../../../store/hooks';
import { canViewFinancialDataSelector } from '../../../../../shared/reducers/permissions.reducer';
import { DashboardRentDelayWidget } from './components/widgets/delay/DashboardRentDelayWidget';
import { DashboardRentShortageWidget } from './components/widgets/shortage/DashboardRentShortageWidget';
import { DashboardRentDebtWidget } from './components/widgets/debt/DashboardRentDebtWidget';
import { DashboardRentOrderedWidget } from './components/widgets/ordered/DashboardRentOrderedWidget';
import { DashboardRentBookedWidget } from './components/widgets/booked/DashboardRentBookedWidget';
import { DashboardRentRentedWidget } from './components/widgets/rented/DashboardRentRentedWidget';
import { DashboardData } from '../../types/DashboardData';
import { DashboardRentLeftList } from '../../list/rent/left/DashboardRentLeftList';
import { DashboardRentRightList } from '../../list/rent/right/DashboardRentRightList';

interface DashboardRentProps {
    dashboardData: DashboardData;
    responsive: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

export const DashboardRent: FC<DashboardRentProps> = memo((props) => {
    const { dashboardData, responsive } = props;
    const isCanViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    const XXL = responsive === 'xxl';

    const DelayWidget = (
        <DashboardRentDelayWidget
            dashboardData={dashboardData}
            style={{ width: '100%', marginBottom: responsive !== 'xl' && responsive !== 'xxl' ? 20 : undefined }}
            XXL={XXL}
        />
    );
    const ShortageWidget = (
        <DashboardRentShortageWidget
            dashboardData={dashboardData}
            style={{ width: '100%', marginBottom: responsive !== 'xl' && responsive !== 'xxl' ? 20 : undefined }}
            XXL={XXL}
        />
    );
    const DebtWidget = (
        <DashboardRentDebtWidget
            dashboardData={dashboardData}
            style={{ width: '100%', marginBottom: responsive !== 'xl' && responsive !== 'xxl' ? 20 : undefined }}
            XXL={XXL}
        />
    );
    const OrderedWidget = (
        <DashboardRentOrderedWidget
            dashboardData={dashboardData}
            style={{ width: '100%', marginBottom: responsive !== 'xl' && responsive !== 'xxl' ? 20 : undefined }}
            XXL={XXL}
        />
    );
    const BookedWidget = (
        <DashboardRentBookedWidget
            dashboardData={dashboardData}
            style={{ width: '100%', marginBottom: responsive !== 'xl' && responsive !== 'xxl' ? 20 : undefined }}
            XXL={XXL}
        />
    );
    const RentedWidget = (
        <DashboardRentRentedWidget
            dashboardData={dashboardData}
            style={{ width: '100%', marginBottom: responsive !== 'xl' && responsive !== 'xxl' ? 20 : undefined }}
            XXL={XXL}
        />
    );

    const LeftTable = <DashboardRentLeftList dashboardData={dashboardData} />;
    const RightTable = <DashboardRentRightList dashboardData={dashboardData} />;

    return responsive === 'xs' || responsive === 'sm' || responsive === 'md' || responsive === 'lg' ? (
        <div>
            <Row type="flex" justify="space-between" gutter={20}>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    {DelayWidget}
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    {ShortageWidget}
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }} style={{ height: !isCanViewFinancialData ? 138 : undefined }}>
                    {isCanViewFinancialData && DebtWidget}
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    {OrderedWidget}
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    {BookedWidget}
                </Col>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    {RentedWidget}
                </Col>
            </Row>
            <Card bodyStyle={{ padding: '0px', paddingTop: '25px', paddingBottom: '36px' }}>{LeftTable}</Card>
            <Card bodyStyle={{ padding: '0px', paddingTop: '25px', marginTop: 30, paddingBottom: '36px' }}>{RightTable}</Card>
        </div>
    ) : (
        <div>
            <Row type="flex" align="stretch" style={{ marginBottom: 30, gap: 32 }}>
                <div className="rr-widget-panel-xl">
                    {DelayWidget}
                    {ShortageWidget}
                    {isCanViewFinancialData && DebtWidget}
                </div>
                <div className="rr-widget-panel-xl">
                    {OrderedWidget}
                    {BookedWidget}
                    {RentedWidget}
                </div>
            </Row>

            <Row type="flex" gutter={32} justify="space-between">
                <Card style={{ width: 'calc(50% - 16px)' }} bodyStyle={{ padding: '0px', paddingTop: '25px', paddingBottom: '36px' }}>
                    {LeftTable}
                </Card>
                <Card style={{ width: 'calc(50% - 16px)' }} bodyStyle={{ padding: '0px', paddingTop: '25px', paddingBottom: '36px' }}>
                    {RightTable}
                </Card>
            </Row>
        </div>
    );
});
