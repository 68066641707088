import { FC } from 'react';

interface Props {
    hasCount: boolean;
}

export const DashboardWidgetHeaderRedLine: FC<Props> = ({ hasCount }) => {
    return (
        <div
            style={{
                backgroundColor: hasCount ? 'rgb(244, 81, 108)' : '#C4C5D6',
                width: 12,
            }}
        />
    );
};
