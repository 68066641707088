import { useCallback } from 'react';
import { LocalStorageUtils } from '../../../../../../core/utils/localStorageUtils';
import { IRootState } from '../../../../../../shared/reducers';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { useAppSelector } from '../../../../../../store/hooks';
import { CALENDAR_SIDEBAR_WIDTH_MEDIUM } from '../../../components/Calendar/utils/data/calendarConstants';

export const useLocalStorageCalendarData = () => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const email = useAppSelector((state: IRootState) => state.oidc.user?.profile.email);
    const storageItemKey = LocalStorageUtils.getKey('calendarSidebarWidth', businessAccountId, email);
    const width = localStorage.getItem(storageItemKey);
    const sidebarWidth = width && !isNaN(+width) ? +width : CALENDAR_SIDEBAR_WIDTH_MEDIUM;

    const setSidebarWidth = useCallback(
        (value: number) => {
            //console.log(value);
            localStorage.setItem(storageItemKey, value.toString());
        },
        [storageItemKey]
    );

    return [sidebarWidth, setSidebarWidth] as [number, (value: number) => void];
};
