import React, { FC, memo } from 'react';
import { inventoryPageUrlRoute } from '../../inventory-page';
import { EntityList } from '../../../../../components/page/entityGrid/components/ui/EntityList/EntityList';
import { kitsListColumns } from '../columns/kitsPageColumns';
import { kitsPageFilters } from '../filters/kitsPageFilters';
import { kitsPageFiltersDescription } from '../filters/kitsPageFilters.description';
import { EntityGridRootPath } from '../../../../../components/page/entityGrid/types/params';
import { useAppSelector } from '../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { withEntityListProviders } from '../../../../../components/page/entityGrid/components/decorators/withEntityListProviders';
import { useActionsButtons } from '../hooks/useActionsButtons';
import { useSelectedRecords } from '../../../../../components/page/entityGrid/hooks/useSelectedRecords';
import { KitRecord } from '../../../../../server';
import {
    usePageURLParamsFromDescription,
    useQueryParamsWithoutModalParams,
} from '../../../../../components/page/entityGrid/hooks/useParams';
import { useListKitsAvailableFiltersQuery, useListKitsQuery } from '../api/kits.api';
import { useIntl } from 'react-intl';
import { useEntitySelection } from '../../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import { useCategoriesProps } from '../hooks/useCategoriesProps';
import { useExcludeColumnsAndFields } from '../hooks/useExcludeColumnsAndFields';
import { useEntityActions } from '../hooks/useEntityActions';
import { useCustomFieldsData } from '../../../../../components/page/entityGrid/hooks/useCustomFieldsData';
import { InventoryPageTabsEnum } from '../../../../../shared/constants/tabEnums';
import { useExportBlock } from '../hooks/useExportBlock';

interface KitsListProps {}

const gridName = InventoryPageTabsEnum.KITS;

export const KitsListComponent: FC<KitsListProps> = memo(() => {
    const intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const entitiesLoading = useAppSelector((store) => store.kits.loading);
    const entitySelection = useEntitySelection();

    const { data: { customFieldMarkers, availableFiltersValues } = {}, isLoading: isAvailableFilterLoading } =
        useListKitsAvailableFiltersQuery(undefined);

    const { customFiltersRequestFilters, actualPageParamsDescription } = useCustomFieldsData(
        customFieldMarkers,
        kitsPageFiltersDescription
    );

    const pageParamsObject = usePageURLParamsFromDescription({
        pageParamsDescription: actualPageParamsDescription,
        gridName,
        initialValues: {
            //sortBy: 'lastActivityDate',
            //sortOrder: 'DESC',
        },
        hiddenParams: ['productInstanceTrackingTypeCode'],
    });

    const { pageParams } = pageParamsObject;

    const queryParams = useQueryParamsWithoutModalParams(pageParams);

    const queryData = useListKitsQuery(
        {
            intl,
            params: queryParams,
            customFiltersRequestFilters,
            availableFiltersValues,
            customFieldMarkers,
        },
        {
            skip: isAvailableFilterLoading,
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const categoriesProps = useCategoriesProps(pageParamsObject);

    const { records } = queryData.data?.entitiesData ?? {};
    const selectedRecords = useSelectedRecords<KitRecord>(entitySelection, records);
    const actionButtons = useActionsButtons(records, selectedRecords, pageParams);

    const rootPath = `/${businessAccountId}/${inventoryPageUrlRoute}/${gridName}` satisfies EntityGridRootPath;

    const { excludeColumns, filtersExcludeFields } = useExcludeColumnsAndFields();

    const entityActions = useEntityActions(rootPath);

    const exportBlock = useExportBlock(pageParams);

    return (
        <EntityList
            pageParamsObject={pageParamsObject}
            gridProps={{
                static: {
                    columns: kitsListColumns,
                    filtersData: kitsPageFilters,
                    pageParamsDescription: actualPageParamsDescription,
                },
                metaData: {
                    rootPath,
                    entityType: 'kits-product',
                    gridName,
                },
                categoriesProps,
                queryData,
                entityActions,
                entitiesLoading,
                actionButtons,
                hideArchive: pageParams.hideArchive,
                excludeColumns,
                filtersExcludeFields,
                customFieldsInsertIndex: 5,
                exportBlock,
                pinned: { right: ['actions'] },
            }}
        />
    );
});

export const KitsList: FC<KitsListProps> = withEntityListProviders(KitsListComponent);
