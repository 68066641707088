import React, { FC, useEffect } from 'react';
import { Col, Row, Tabs } from 'antd';
import { InstanceRecord, InstanceStateCodeEnum, NomenclatureEntityTypeCodeEnum } from '../../../../../../server';
import { PopoverHeader } from '../../../../../../components/gridRowPopover/PopoverHeader';
import { Link } from 'react-router-dom';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { EntityActionType, getBusinessAccountId } from '../../../../../../../index';
import { ProductVariantPopover } from '../../../variants/components/productVariantPopover/productVariantPopover';
import { FormValue, Spin } from '../../../../../../components';
import { InventoryCurrentStateBlock } from '../../../components/InventoryCurrentStateBlock/InventoryCurrentStateBlock';
import { IconTagSolid } from '../../../../../../components/icons';
import { CurrentIndicatorsProblemIcons } from '../../../../../../components/currentRentIndicators/currentIndicatorsProblemIcons';
import { CurrentRentIndicatorsTab } from '../../../../../../components/currentRentIndicators/currentRentIndicatorsTab';
import { CurrentSubrentIndicatorsTab } from '../../../../../../components/currentRentIndicators/currentSubrentIndicatorsTab';
import { formatMoney } from '../../../../../../shared/util/formatMoney';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { subrentModuleEnabledSelector } from '../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { useIntl } from 'react-intl';
import { showNotification } from '../../../../../../components/notification/showNotification';
import { useGetProductInstanceByIdQuery } from '../../api/productInstances.api';
import { canViewFinancialDataSelector } from '../../../../../../shared/reducers/permissions.reducer';
import { getPathFromState } from '../../../../../../shared/util/utils';
import { showConfirm2 } from '../../../../../../components/confirm/showConfirm';
import { transitProductInstanceWorkflowBulk } from '../../reducers/product-instanсe.reducer';
import { push } from 'connected-react-router';
import { deleteEntities } from '../../reducers/product-instances.reducer';
import './productInstancePopoverContent.less';
import { formatDate } from '../../../../../../shared/util/formatDate';
import { getGridStorageData } from '../../../../../../components/grid/utils';
import ExportBarcodePopover from '../../../../../../components/exportPopovers/downloadDocument/instances/ExportBadgePopover/ExportBarcodePopover';

interface ProductVariantPopoverContentProps {
    instanceId: number;
    onClose: () => void;
    onLinkClick: () => void;
    onCloseButtonClick?: () => void;
}

export const ProductInstancePopoverContent: FC<ProductVariantPopoverContentProps> = ({
    onLinkClick,
    onClose,
    onCloseButtonClick,
    ...props
}) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const { data: entity, error } = useGetProductInstanceByIdQuery(
        { businessAccountId, id: props.instanceId },
        { refetchOnMountOrArgChange: true } // TODO сделать норм инвалидацию кэша, что б какждый раз не делался запрос
    );

    useEffect(() => {
        if (error) {
            showNotification('error', 'Не удалось загрузить экземпляр');
            // Тут если ошибка, то остаются спиннеры крутящиеся, по этому пока скрываю поповер при ошибке
            onClose?.();
        }
    }, [error, onClose]);

    const onAction = async (item, action: EntityActionType) => {
        onLinkClick();

        const gridData = getGridStorageData('product-instances');

        if (entity) {
            if (action === 'edit') {
                const path = getPathFromState(`/${businessAccountId}/inventory/products/${entity.productId}/editInstance`, '', {
                    id: entity.id,
                    tab: 'instances',
                    ...gridData.filters,
                    ...gridData.params,
                });
                dispatch(push(path));
            } else if (action === 'delete') {
                let yes = await showConfirm2({ intl: intl, title: 'Удалить экземпляр безвозвратно?' });
                if (yes) {
                    dispatch(
                        deleteEntities(intl, businessAccountId, entity.productId, [
                            { id: entity.id, businessVersion: entity.businessVersion },
                        ])
                    );
                }
            } else if (action === 'copy') {
                const path = getPathFromState(`/${businessAccountId}/inventory/products/${entity.productId}/addInstanceCopy`, '', {
                    id: entity.id,
                    tab: 'instances',
                    ...gridData.filters,
                    ...gridData.params,
                });
                dispatch(push(path));
            } else {
                const path = getPathFromState(`/${businessAccountId}/inventory/products/${entity.productId}`, '', {
                    id: entity.id,
                    tab: 'instances',
                    ...gridData.filters,
                    ...gridData.params,
                });
                dispatch(push(path));
                /*
                тут конфликтовали #1880 и #1884
                На всякий случай тут версия из #1884
                const path = getPathFromState(`/${businessAccountId}/inventory/products/${entity.productId}`, location.search, {
                    tab: 'instances',
                });*/
                dispatch(push(path));
                dispatch(
                    transitProductInstanceWorkflowBulk(intl, businessAccountId, entity.productId, [
                        {
                            id: entity.id,
                            businessVersion: entity.businessVersion,
                            transitionCode: action,
                        },
                    ])
                );
            }
        }
    };

    const { TabPane } = Tabs;
    let rentProblemColor;
    let subrentProblemColor;
    let returnDelayCount,
        bookShortageCount,
        giveawayDelayCount,
        orderShortageCount,
        countProblems = 0;

    if (entity) {
        returnDelayCount = entity.rentIndicators?.problemsAndWarnings.returnDelay.elementsCount;
        bookShortageCount = entity.rentIndicators?.problemsAndWarnings.bookShortage.elementsCount;
        giveawayDelayCount = entity.rentIndicators?.problemsAndWarnings.giveawayDelay.elementsCount;
        orderShortageCount = entity.rentIndicators?.problemsAndWarnings.orderShortage.elementsCount;

        if (returnDelayCount) countProblems++;
        if (bookShortageCount) countProblems++;
        if (giveawayDelayCount) countProblems++;
        if (orderShortageCount) countProblems++;

        if (returnDelayCount || bookShortageCount) {
            rentProblemColor = '#F4516c';
        } else if (giveawayDelayCount || orderShortageCount) {
            rentProblemColor = '#FC8C2F';
        }

        if (entity.rentIndicators?.problemsAndWarnings.subrentShipmentDelay.elementsCount) {
            subrentProblemColor = '#F4516c';
        } else if (entity.rentIndicators?.problemsAndWarnings.subrentReturnToShipperDelay.elementsCount) {
            subrentProblemColor = '#FC8C2F';
        }
    }

    let isSupplier =
        entity &&
        (entity.stateCode === InstanceStateCodeEnum.SUPPLIERACTIVE || entity.stateCode === InstanceStateCodeEnum.SUPPLIERDECOMMISSIONED);

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <PopoverHeader
                businessAccountId={businessAccountId}
                title={<Link to={`/${businessAccountId}/inventory/products/${entity?.productId}`}>{entity?.nameOrCode}</Link>}
                closeCb={() => {
                    onClose();
                    onCloseButtonClick?.();
                }}
                entityType={'product-instances'}
                entity={(entity || {}) as InstanceRecord} // TODO тут нужно понять что туда надо передавать
                onAction={onAction}
                closeElementPopup={() => onLinkClick()}
            />
            <div className={`tab-popap-instance`}>
                <div className="rr-rent-indicators">
                    <Tabs defaultActiveKey="1" type="line">
                        <TabPane
                            tab={
                                <div style={{ height: '60px', display: 'flex', alignItems: 'center' }}>
                                    <div style={{ paddingBottom: '2px' }}>
                                        {localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}
                                    </div>
                                </div>
                            }
                            key="1"
                        >
                            <Row type={'flex'} style={{ height: '100%' }}>
                                <Col span={14} style={{ height: '100%', padding: 32 }}>
                                    <Row>
                                        <Col className="rr-form-item" style={{ paddingRight: '5px' }}>
                                            <div className="rr-label">{localize(LocalizationEnum.ASPECT__GLOBAL__NOMENCLATURE)}</div>

                                            {entity ? (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div className={'rr-value'}>
                                                        <Link
                                                            className="tab-popap-instance-product-value"
                                                            to={`/${getBusinessAccountId()}/inventory/products/${
                                                                entity ? entity.productId : null
                                                            }?tab=description`}
                                                            onClick={() => onLinkClick()}
                                                        >
                                                            {entity ? entity.productShortName : null}
                                                        </Link>
                                                    </div>
                                                    {entity.variantId ? (
                                                        <>
                                                            :
                                                            <ProductVariantPopover
                                                                productId={entity.productId}
                                                                variantId={entity.variantId}
                                                                onLinkClick={() => {
                                                                    onClose();
                                                                }}
                                                            >
                                                                <span
                                                                    className="popover-header-name"
                                                                    style={{ marginLeft: 5, marginBottom: -1 }}
                                                                >
                                                                    {entity.variantName}
                                                                </span>
                                                            </ProductVariantPopover>
                                                        </>
                                                    ) : null}
                                                    {/*{entity ? (entity.variantName ? ':' : null) : null}*/}
                                                    {/*<div style={{ marginLeft: '5px', marginBottom: '-1px' }}>*/}
                                                    {/*    {entity ? (*/}
                                                    {/*        entity.variantName ? (*/}
                                                    {/*            <>*/}
                                                    {/*                /!*TODO Тут непонятная хрень*!/*/}
                                                    {/*                <ProductVariantPopover*/}
                                                    {/*                    placement={'right'}*/}
                                                    {/*                    record={entity?.variantId as any}*/}
                                                    {/*                    variantId={entity?.variantId}*/}
                                                    {/*                    productId={entity?.productId}*/}
                                                    {/*                    closeElementPopup={this.props.closeCb}*/}
                                                    {/*                    productShortName={entity?.productShortName || ''}*/}
                                                    {/*                >*/}
                                                    {/*                    {() => this.renderVariantPopup(entity!.variantName)}*/}
                                                    {/*                </ProductVariantPopover>*/}
                                                    {/*            </>*/}
                                                    {/*        ) : null*/}
                                                    {/*    ) : null}*/}
                                                    {/*</div>*/}
                                                </div>
                                            ) : (
                                                <Spin />
                                            )}
                                        </Col>
                                        {isSupplier && entity?.supplierId && (
                                            <Col className="rr-form-item">
                                                <div className="rr-label">Владелец</div>
                                                <div className={'rr-value'}>
                                                    <Link to={`/${businessAccountId}/crm/counterparties/${entity?.supplierId}`}>
                                                        {entity?.supplierShortName}
                                                    </Link>
                                                </div>
                                            </Col>
                                        )}
                                        {!isSupplier && entity?.sellerId && (
                                            <Col className="rr-form-item">
                                                <div className="rr-label">Продавец</div>
                                                <div className={'rr-value'}>
                                                    <Link to={`/${businessAccountId}/crm/counterparties/${entity?.sellerId}`}>
                                                        {entity?.sellerShortName}
                                                    </Link>
                                                </div>
                                            </Col>
                                        )}
                                        {!isSupplier && (
                                            <Col className="rr-form-item">
                                                <div className="rr-label">
                                                    {localize(LocalizationEnum.ASPECT__FIELDS__INSTANCE__LAST_MAINENANCE)}
                                                </div>
                                                {entity ? (
                                                    <div className={'rr-value'}>
                                                        {entity.stateCode === InstanceStateCodeEnum.UNDERMAINTENANCE ? (
                                                            localize(LocalizationEnum.ASPECT__FIELDS__INSTANCE__IN_PROGRESS_NOW)
                                                        ) : entity.lastMaintenanceEndDate ? (
                                                            formatDate(entity.lastMaintenanceEndDate, 'D MMMM YYYY HH:mm')
                                                        ) : (
                                                            <span className="rr-empty-value">
                                                                {localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NEVER_OCCURED)}
                                                            </span>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <Spin />
                                                )}
                                            </Col>
                                        )}
                                        <Col className="rr-form-item">
                                            <div className="rr-label">{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT)}</div>
                                            {entity ? (
                                                <div className={'rr-text'}>
                                                    {entity ? (
                                                        entity.comment ? (
                                                            <span>{entity.comment}</span>
                                                        ) : (
                                                            <span className="rr-empty-value">
                                                                {localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                                                            </span>
                                                        )
                                                    ) : null}
                                                </div>
                                            ) : (
                                                <Spin />
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={10} style={{ height: '100%', padding: 32, borderLeft: '1px solid #e8e8e8' }}>
                                    <Row>
                                        <Col>
                                            {entity && (
                                                <InventoryCurrentStateBlock
                                                    stockInstanceCount={entity.warehousePresenceCounters.stockInstanceCount}
                                                    underMaintenanceInstanceCount={
                                                        entity.warehousePresenceCounters.underMaintenanceInstanceCount
                                                    }
                                                    rentedInstanceCount={entity?.warehousePresenceCounters.rentedInstanceCount}
                                                    subrentedInstanceCount={entity?.warehousePresenceCounters.subrentedInstanceCount}
                                                    availableInstanceCount={entity.timetableStackCounters.availableInstanceCount}
                                                    warehouseInstanceCount={entity?.warehousePresenceCounters.warehouseInstanceCount}
                                                    phoneMode={false}
                                                    inventoryIcon={IconTagSolid}
                                                    variantId={entity.variantId}
                                                    productId={entity.productId}
                                                    instanceId={entity.id}
                                                    instanceStateCode={entity.stateCode}
                                                    timetableStackCounters={entity.timetableStackCounters}
                                                    utfIcons={true}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </TabPane>

                        {entity && (!entity.supplierId || entity?.stateCode === InstanceStateCodeEnum.SUPPLIERACTIVE) && (
                            <TabPane
                                tab={
                                    <div
                                        style={{
                                            color: rentProblemColor,
                                            height: '60px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CurrentIndicatorsProblemIcons
                                            problemsAndWarnings={entity?.rentIndicators.problemsAndWarnings}
                                            entityType={'product-instances'}
                                            typeRent={'rent'}
                                            id={entity?.id}
                                            entity={entity}
                                            isPopup={true}
                                        />
                                        <span>Аренда</span>
                                    </div>
                                }
                                key="2"
                            >
                                <Row className="tab-popap-instance-row" onClick={() => onLinkClick()}>
                                    {entity ? <CurrentRentIndicatorsTab entity={entity} entityType={'product-instance'} /> : null}
                                </Row>
                            </TabPane>
                        )}

                        {subrentModuleEnabled && entity && entity.supplierId && (
                            <TabPane
                                tab={
                                    <div
                                        style={{
                                            color: subrentProblemColor,
                                            height: '60px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CurrentIndicatorsProblemIcons
                                            problemsAndWarnings={entity?.rentIndicators.problemsAndWarnings}
                                            entityType={'product-instances'}
                                            typeRent={'subrent'}
                                            id={entity?.id}
                                            entity={entity}
                                            isPopup={true}
                                        />
                                        <span>Субаренда</span>
                                    </div>
                                }
                                key="3"
                            >
                                <Row className="tab-popap-instance-row" onClick={() => onLinkClick()}>
                                    {entity ? <CurrentSubrentIndicatorsTab entity={entity} entityType={'product-instance'} /> : null}
                                </Row>
                            </TabPane>
                        )}
                        <TabPane
                            tab={
                                <div style={{ height: '60px', display: 'flex', alignItems: 'center' }}>
                                    <span>{localize(LocalizationEnum.ASPECT__GLOBAL__EXTRA_FEATURES)}</span>
                                </div>
                            }
                            key="4"
                        >
                            <Row className="tab-popap-instance-row">
                                <Col>
                                    <Row>
                                        <Col className="rr-form-item" span={12}>
                                            <div className="rr-label">Штриховой код</div>
                                            <div className="rr-value">
                                                <FormValue
                                                    value={
                                                        entity?.barcode ? (
                                                            <>
                                                                <ExportBarcodePopover
                                                                    nomenclatureType={NomenclatureEntityTypeCodeEnum.INSTANCE}
                                                                    nomenclatureId={entity.id}
                                                                />
                                                                {entity.barcode}
                                                            </>
                                                        ) : undefined
                                                    }
                                                    emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="rr-form-item" span={12}>
                                            <div className="rr-label">
                                                {localize(LocalizationEnum.ASPECT__FIELDS__INSTANCE__INVENTORY_NUMBER)}
                                            </div>
                                            <div className="rr-value">
                                                <FormValue
                                                    value={entity && entity.inventoryNumber !== null ? entity.inventoryNumber : undefined}
                                                    emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    {canViewFinancialData && (
                                        <Row>
                                            <Col className="rr-form-item" span={12}>
                                                <div className="rr-label">{localize(LocalizationEnum.PAGE__PRODUCTS__PURCHASE_PRICE)}</div>
                                                <div className="rr-value">
                                                    <FormValue
                                                        value={
                                                            entity && entity.purchaseCost !== null
                                                                ? formatMoney(entity.purchaseCost)
                                                                : undefined
                                                        }
                                                        emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__F)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="rr-form-item" span={12}>
                                                <div className="rr-label">
                                                    {localize(LocalizationEnum.PAGE__CARD_POPUP__CARRYING_VALUE)}
                                                </div>
                                                <div className="rr-value">
                                                    <FormValue
                                                        value={
                                                            entity && entity.carryingValue !== null
                                                                ? formatMoney(entity.carryingValue)
                                                                : undefined
                                                        }
                                                        emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__F)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col className={'rr-form-item'}>
                                            <div className={'rr-label'}>
                                                {localize(LocalizationEnum.PAGE__PRODUCTS__CONDITION_DESCRIPTION)}
                                            </div>
                                            <div className={'rr-text'}>
                                                <FormValue
                                                    value={entity && entity.stateDescription !== null ? entity.stateDescription : undefined}
                                                    emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};
