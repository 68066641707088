import React, { FC, useCallback } from 'react';
import { Icon } from 'antd';
import { IconExcel } from '../../../../../../../../components/icons';
import './ExcelButton.less';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { exportExcel } from '../../../../../tabs/elements/reducers/elementsCalendar.reducer';
import { subrentModuleEnabledSelector } from '../../../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { ElementsCalendarFilters } from '../../../../../tabs/elements/filters/elementsCalendarFilters.types';
import { CalendarURLParams } from '../../../types/params';
import { LocalizationEnum, localize } from '../../../../../../../../localization';
import { ExportToExcellPopover } from '../../../../../../../../components/exportPopovers/downloadDocument/instances/ExportToExcellPopover/ExportToExcellPopover';
import { ElementsCalendarSortValue } from '../../../../../tabs/elements/utils/data/groupsData';
import {
    ExportToExcellOnDownload,
    ExportToExcellPopoverOptions,
} from '../../../../../../../../components/exportPopovers/downloadDocument/instances/ExportToExcellPopover/utils/data';
import { ElementsCalendarApiUtils } from '../../../../../tabs/elements/api/elementsCalendar.api.utils';

interface ExcelButtonProps {
    storageKey: string;
    params: CalendarURLParams<ElementsCalendarFilters, ElementsCalendarSortValue>;
}

export const ExcelButton: FC<ExcelButtonProps> = (props) => {
    const { storageKey, params } = props;
    const intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);

    const onDownload: ExportToExcellOnDownload = useCallback(
        async ({ options }) => {
            await exportExcel(
                intl,
                businessAccountId,
                params,
                options.includes(ExportToExcellPopoverOptions.EXPAND_KITS),
                Boolean(subrentModuleEnabled)
            );
        },
        [businessAccountId, intl, params, subrentModuleEnabled]
    );

    return (
        <ExportToExcellPopover
            storageKey={storageKey}
            onDownload={onDownload}
            getFilters={(v) => ElementsCalendarApiUtils.getFilters(params, subrentModuleEnabled)}
            options={[
                {
                    key: ExportToExcellPopoverOptions.EXPAND_KITS,
                    label: localize(LocalizationEnum.ASPECT__EXCEL_EXPORT__SELECT_OPTION__INCLUDE_COMPONENTS),
                },
            ]}
        >
            <Icon className={'excel-icon'} component={IconExcel} />
        </ExportToExcellPopover>
    );
};
