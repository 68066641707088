import React, {ReactNode, useState} from 'react';
import { Upload } from 'antd';
import { RcCustomRequestOptions, UploadFile } from 'antd/lib/upload/interface';
import {AttachmentDocumentTypeCodeEnum, AttachmentParentEntityTypeCodeEnum} from '../../server';
import axios from 'axios';
import { getUserAccessToken } from '../../../index';
import { API_SERVER_BASE_PATH } from '../../config/config';
import { formatBytes } from '../../shared/util/formatBytes';
import {
    MAX_ATTACHMENT_FILE_SIZE_LIMIT_IN_BYTES,
    MAX_ATTACHMENTS_LIMIT_FOR_ENTITY,
    MAX_ATTACHMENTS_LIMIT_FOR_UPLOADING,
} from '../../config/constants';
import { showNotification } from '../notification/showNotification';
import { useIntl } from 'react-intl';
import { LocalizationEnum, localizeIntl } from '../../localization';
import { AddButton } from '../buttons/addButton/addButton';
import { businessAccountIdSelector } from '../../shared/reducers/system.reducer';
import { useAppSelector } from '../../store/hooks';

interface AttachmentsUploaderProps {
    onChange?: (files: UploadFile[]) => void;
    onComplete?: (files: UploadFile[]) => void;
    parentEntityId: number;
    parentEntityTypeCode: AttachmentParentEntityTypeCodeEnum;
    disabled?: boolean;
    attachmentsCount: number;
    children?: ReactNode;
    multiple?: boolean;
    accept?: string;
    documentType?: AttachmentDocumentTypeCodeEnum;
}

export const AttachmentsUploader = (props: AttachmentsUploaderProps) => {
    let intl = useIntl();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const uploadFile = async ({ onSuccess, onError, file, onProgress, ...options }: RcCustomRequestOptions) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append(
            'attachmentInfoCreate',
            new Blob(
                [
                    JSON.stringify({
                        parentEntityId: props.parentEntityId,
                        parentEntityTypeCode: props.parentEntityTypeCode,
                        financial: false,
                        documentType: props.documentType
                    }),
                ],
                {
                    type: 'application/json',
                }
            )
        );

        let res;
        try {
            res = await axios.post(`${API_SERVER_BASE_PATH}/businessAccounts/${businessAccountId}/attachments`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: 'Bearer ' + getUserAccessToken(),
                },
                onUploadProgress: (event) => {
                    const percent = Math.floor((event.loaded / event.total) * 100);
                    onProgress({ percent }, file);
                },
            });
            onSuccess(res.data, file);
        } catch (err: any) {
            console.dir(err);
            const errMessage = err?.response?.data?.message || err?.message || 'Ошибка';
            onError(new Error(errMessage));
        }
    };

    const beforeUpload = (file: UploadFile, filelist: UploadFile[]) => {
        const maxSize = MAX_ATTACHMENT_FILE_SIZE_LIMIT_IN_BYTES;

        if (file === filelist[0]) {
            filelist.forEach((file) => {
                const sizeError = file.size > maxSize;
                if (sizeError) {
                    file.status = 'error';
                    file.error = new Error(
                        `Вложение имеет недопустимый объем в байтах! Объем - ${formatBytes(
                            file.size,
                            2
                        )}, максимально допустимый - ${formatBytes(maxSize, 2)}`
                    );
                }else if(file.size === 0){
                    file.status = 'error';
                    file.error = new Error(`Вложение имеет нулевой размер`);
                }
            });

            filelist
                .filter((file) => file.status !== 'error')
                .forEach((file, index, arr) => {
                    const canLoadNFiles = Math.min(
                        MAX_ATTACHMENTS_LIMIT_FOR_ENTITY - props.attachmentsCount,
                        MAX_ATTACHMENTS_LIMIT_FOR_UPLOADING
                    );
                    if (index >= canLoadNFiles) file.status = 'removed';
                });

            const notLoadedFiles = filelist.filter((file) => file.status === 'removed').length;
            if (notLoadedFiles > 0) {
                showNotification(
                    'error',
                    <>
                        Превышен лимит на максимальное число вложений, {notLoadedFiles}{' '}
                        {localizeIntl(intl, LocalizationEnum.ASPECT__PLURAL__FILE_WILL_NOT_BE_UPLOADED_MESSAGE, undefined, {
                            value: notLoadedFiles,
                        })}
                    </>
                );
            }
        }
        return !(file.status === 'error') && !(file.status === 'removed');
    };

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <Upload
                onChange={(param) => {
                    let fileList = param.fileList;
                    setFileList(fileList);
                    if (props.onChange) props.onChange(fileList);
                    if (fileList.every((file) => file.status !== 'uploading' && file.status !== undefined)) {
                        if (props.onComplete) props.onComplete(fileList);
                    }
                }}
                multiple={props.multiple !== undefined ? props.multiple : true}
                showUploadList={false}
                customRequest={uploadFile}
                beforeUpload={beforeUpload}
                fileList={fileList}
                accept={props.accept}
            >
                {props.children ? props.children : <AddButton disabled={props.disabled} />}
            </Upload>
        </div>
    );
};
