import React from 'react';
import { LocationSelectProps } from './locationSelect';
import { SelectAndCreate } from '../selectAndCreate';
import { LocationCreateModalNew } from '../../../../modules/main/logistics/tabs/locations/modal/create/LocationCreateModalNew';
import LocationDetailedSelect from '../../detailedSelect/LocationDetailedSelect';
import { IDetailedSelectColumn } from '../../detailedSelect/DetailedSelect';
import { LocationRecord } from '../../../../server';

export interface LocationSelectAndCreateProps extends LocationSelectProps {
    columns?: IDetailedSelectColumn<LocationRecord>[];
}

export class LocationSelectAndCreate extends React.Component<LocationSelectAndCreateProps> {
    render() {
        const { props } = this;
        return (
            <SelectAndCreate
                select={() => (
                    <LocationDetailedSelect
                        value={props.value ? (Array.isArray(props.value) ? (props.value as number[]) : [props.value as number]) : []}
                        getPopupContainer={props.getPopupContainer}
                        multiple={props.multiple}
                        id="location"
                        onChange={(v) => {
                            props.onChange?.(v);
                        }}
                        onDataChange={props.onDataChange}
                        columns={props.columns}
                        // getOptionProps={props.getOptionProps}
                        // filters={props.filters}
                        // getOptionPrefix={props.getOptionPrefix}
                        allowClear={props.allowClear}
                        disabled={props.disabled}
                        placeholder={props.placeholder}
                    />
                )}
                modal={({ close }) => {
                    return (
                        <LocationCreateModalNew
                            onSuccess={(data) => {
                                props.onChange?.(data.id);
                                close();
                            }}
                            onCancel={() => {
                                close();
                            }}
                        />
                    );
                }}
                disabled={props.disabled}
            />
        );
    }
}
