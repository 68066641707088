import { ProjectRecord } from '../../../../server';
import React, { useCallback } from 'react';
import { _Grid } from '../../../../components/grid/Grid';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { includeOrExcludeOfferInProjectPriceBulk } from '../reducers/offers/offers.reducer';
import {loadEntity} from "../../projects/production/reducers/project.reducer";

export const useIncludeOrExcludeOfferInProjectPriceForOffersCallback = (
    selectedRecords: ProjectRecord[],
    gridRef?: React.RefObject<_Grid>
) => {
    const dispatch = useAppDispatch();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const parentProject = useAppSelector(state=>state.project.entity);

    return useCallback(async () => {
        const versionedEntityObjList = selectedRecords.map(({ id, businessVersion }) => ({ id, businessVersion }));
        if (gridRef != null) {
            gridRef.current?.clearSelection();
        }
        await dispatch(
            includeOrExcludeOfferInProjectPriceBulk({
                businessAccountId,
                include: selectedRecords[0].partOfThePrice !== true,
                versionedEntityObjList: {
                    entities: versionedEntityObjList,
                },
            })
        );
        if(parentProject) dispatch(loadEntity(businessAccountId, parentProject.id));
    }, [businessAccountId, dispatch, gridRef, selectedRecords, parentProject]);
};
