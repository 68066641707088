import { useCallback, useMemo } from 'react';
import { LocalStorageUtils } from '../../core/utils/localStorageUtils';
import { IRootState } from '../../shared/reducers';
import { businessAccountIdSelector } from '../../shared/reducers/system.reducer';
import { useAppSelector } from '../../store/hooks';

export const useLocalStorageJoinContactData = () => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const email = useAppSelector((state: IRootState) => state.oidc.user?.profile.email);
    const storageItemKey = LocalStorageUtils.getKey('complementCorrectContact', businessAccountId, email);
    const record = localStorage.getItem(storageItemKey) || 'true';

    const complementCorrectContact = useMemo(() => {
        return record === 'true';
    }, [record]);

    const setComplementCorrectContact = useCallback(
        (value: boolean) => {
            //console.log(value);
            if (value) {
                localStorage.setItem(storageItemKey, 'true');
            } else {
                localStorage.setItem(storageItemKey, 'false');
            }
        },
        [storageItemKey]
    );

    return [complementCorrectContact, setComplementCorrectContact] as [boolean, (value: boolean) => void];
};
