import {
    addElementToElementsFromScan,
    addElementToElementsFromScan1,
    canAddNewProductsToOperation,
    prepareDataForAddingKitToElements,
} from '../utils/utils';
import {
    BarcodeEntityObjEntityTypeCodeEnum,
    BarcodeNomenclatureInOperationFormObj,
    InstanceInfoRead,
    InstanceTrackingTypeCodeEnum,
    KitInfoRead,
    NomenclatureEntityTypeCodeEnum,
    NomenclatureRecord,
    NomenclatureStateCodeEnum,
    OperationTypeCodeEnum,
    ProductInfoRead,
    serverApi,
    VariantInfoRead,
} from '../../../../server';
import {AxiosResponse} from 'axios';
import {showNotification} from '../../../../components/notification/showNotification';
import {OperationFormUtils} from '../utils/operationFormUtils';
import {getStore, getStoreState} from '../../../../../index';
import {isDefined} from '../../../../shared/util/utils';
import {RentElementsGridItem} from '../../../../types';
import {
    addNewOperationElementFromEquipment,
    addNewOperationElementFromEquipment1,
    OperationElement,
    RentElementsGridItemCustom,
    TimeTable,
    updateAnyTimetables,
} from '../reducers/operationForm.reducer';
import {ScanErrorType, showScanErrorNotification} from '../../../../shared/reducers/scanner.reducer';
import {
    findTimeTableById,
    loadNomenclaturesOnIntervalsIfNeed,
    NomenclatureOnInterval
} from '../utils/nomenclaturesUtils';
import moment from 'moment';
import {OperationModuleTabsEnum} from '../../../../shared/constants/tabEnums';

export interface ScanFnProps {
    tab: OperationModuleTabsEnum;
    operationTypeCode: OperationTypeCodeEnum | undefined;
    code: string;
    businessAccountId: number;
    instanseAddedShowMessage: () => void;
    nomenclatureAddedShowMessage: (nomenclatureType: NomenclatureEntityTypeCodeEnum.KIT | NomenclatureEntityTypeCodeEnum.VARIANT | NomenclatureEntityTypeCodeEnum.PRODUCT, playSound: boolean) => void;
    equipmentFilteredEntities: Array<RentElementsGridItemCustom> | null;
    elements: OperationElement[];
    timeTables: TimeTable[];
    rentPeriodStartDate: Date | undefined;
    rentPeriodEndDate: Date | undefined;
    addInstanceFromScanner: (
        instanceId: number,
        entity: InstanceInfoRead,
        //records: (NomenclatureRecord | InstanceRecord)[] | undefined,
        nomenclature: NomenclatureRecord
    ) => void;
    addKitMembersWhenInstanceScan?: boolean;
}

export const scan = async (params: ScanFnProps) => {
    const { tab, operationTypeCode, code, businessAccountId } = params;
    if (
        (tab === OperationModuleTabsEnum.PRODUCTS && canAddNewProductsToOperation(operationTypeCode)) ||
        tab === OperationModuleTabsEnum.EQUIPMENT ||
        tab === OperationModuleTabsEnum.ELEMENTS
    ) {
        let res: AxiosResponse<BarcodeNomenclatureInOperationFormObj> | undefined;
        try {
            res = await serverApi.getNomenclatureInOperationFormByBarcode(businessAccountId, code, true);
        } catch (e: any) {
            if (e?.response?.status === 404) {
                //showBarcodeScannerNotification('error', 'Штриховой код не найден в системе', undefined, null);
                getStore().dispatch(
                    showScanErrorNotification({
                        type: ScanErrorType.CodeNotFound,
                        code,
                    })
                );
            } else {
                //showBarcodeScannerNotification('error', 'Не удалось загрузить данные', undefined, null);
                getStore().dispatch(
                    showScanErrorNotification({
                        type: ScanErrorType.LoadingError,
                    })
                );
            }
        }
        const nomenclatureData = res?.data;

        if (nomenclatureData) {
            const p: Props = {
                ...params,
                data: nomenclatureData,
            };
            try {
                if (tab === OperationModuleTabsEnum.PRODUCTS) {
                    await scanOnProductsTab(p);
                } else if (tab === OperationModuleTabsEnum.EQUIPMENT) {
                    await scanOnEquipmentTab(p);
                } else if (tab === OperationModuleTabsEnum.ELEMENTS) {
                    await scanOnElementsTab(p);
                }
            } catch (error) {
                showNotification('error', 'Ошибка');
                console.error(error);
            }
        }
    }
};

interface Props extends ScanFnProps {
    data: BarcodeNomenclatureInOperationFormObj;
}

const scanOnProductsTab = async ({
    data,
    elements,
    businessAccountId,
    nomenclatureAddedShowMessage,
    instanseAddedShowMessage,
    timeTables,
    rentPeriodStartDate,
    rentPeriodEndDate,
    addInstanceFromScanner,
}: Props) => {
    if (data.barcodeEntityObj.entityTypeCode === BarcodeEntityObjEntityTypeCodeEnum.INSTANCE) {
        // Можем добавить экземпляр
        /*let instanceEntity = data.barcodeEntityObj.entityInfoRead as InstanceInfoRead;
        if(!rentPeriodStartDate) rentPeriodStartDate = new Date();
        if(!rentPeriodEndDate) rentPeriodEndDate = new Date();

        let instanceTT = findTimeTable(timeTables, {
            instanceId: instanceEntity.id,
            productId: instanceEntity.productId,
            variantId: instanceEntity.variantId
        });

        let request_instanceTT:AxiosResponse<InstanceRecordList>|undefined;
        let records:(NomenclatureRecord | InstanceRecord)[]|undefined = [];

        if(!instanceTT || instanceTT.timetableVersion !== data.instance?.timetableVersion){
            request_instanceTT = await serverApi.listInstancesOnInterval(
                businessAccountId,
                rentPeriodStartDate.getTime() - 365 * 1 * 24 * 60 * 60 * 1000,
                rentPeriodEndDate.getTime() + 365 * 2 * 24 * 60 * 60 * 1000,
                {
                    possibleDelayedRentElementIds: undefined,
                    listFilters: [`id;IN;${instanceEntity.id}`]
                },
                1,
                0,
                undefined,
                undefined,
                undefined,
                [TimetableTypeCodeEnum.ORDERAVAILABLE, TimetableTypeCodeEnum.AVAILABLE, TimetableTypeCodeEnum.STOCK, TimetableTypeCodeEnum.ORDER]
            );
            if(request_instanceTT.data.records) records.push(...request_instanceTT.data.records);
        }

        let productsResponseTT:AxiosResponse<NomenclatureRecordList>|undefined;
        let filters = [`productId;IN;${instanceEntity.productId}`];
        if(instanceEntity.variantId) filters.push(`variantId;IN;${instanceEntity.variantId}`);

        productsResponseTT = await serverApi.listNomenclatureOnInterval(
            businessAccountId,
            rentPeriodStartDate.getTime() - 365 * 1 * 24 * 60 * 60 * 1000,
            rentPeriodEndDate.getTime() + 365 * 2 * 24 * 60 * 60 * 1000,
            {
                possibleDelayedRentElementIds: undefined,
                listFilters: filters
            },
            1,
            undefined,
            undefined,
            undefined,
            undefined,
            [TimetableTypeCodeEnum.ORDERAVAILABLE, TimetableTypeCodeEnum.AVAILABLE, TimetableTypeCodeEnum.STOCK, TimetableTypeCodeEnum.ORDER]
        );
        if(productsResponseTT.data.records) records.push(...productsResponseTT.data.records);

        if(OperationFormUtils.findInstanceInElements(instanceEntity, getStoreState().operationForm)){
            //showBarcodeScannerNotification('error', <>Экземпляр <span style={{fontWeight: 800}}>{instanceEntity.nameOrCode}</span> уже добавлен</>, undefined, null);
            getStore().dispatch(showScanErrorNotification({
                type: ScanErrorType.InstanceAlreadyAdded,
                instanceName: instanceEntity.nameOrCode
            }));
        }else{
            await addInstanceFromScanner(data.barcodeEntityObj.id, data.barcodeEntityObj.entityInfoRead as InstanceInfoRead, records, productsResponseTT.data.records[0]);
            instanseAddedShowMessage();
        }*/

        let instanceEntity = data.barcodeEntityObj.entityInfoRead as InstanceInfoRead;

        if (OperationFormUtils.findInstanceInElements(instanceEntity, getStoreState().operationForm)) {
            //showBarcodeScannerNotification('error', <>Экземпляр <span style={{fontWeight: 800}}>{instanceEntity.nameOrCode}</span> уже добавлен</>, undefined, null);
            getStore().dispatch(
                showScanErrorNotification({
                    type: ScanErrorType.InstanceAlreadyAdded,
                    instanceName: instanceEntity.nameOrCode,
                })
            );
        } else {
            if (!rentPeriodStartDate) rentPeriodStartDate = new Date();
            if (!rentPeriodEndDate) rentPeriodEndDate = new Date();

            const from = rentPeriodStartDate.getTime();
            const until = rentPeriodEndDate.getTime();
            const nomenclatureId = instanceEntity.variantId || instanceEntity.productId;
            const nomenclatureType = instanceEntity.variantId
                ? NomenclatureEntityTypeCodeEnum.VARIANT
                : NomenclatureEntityTypeCodeEnum.PRODUCT;
            const nomenclatures: NomenclatureOnInterval[] = [
                { id: nomenclatureId, type: nomenclatureType, from, until },
                { id: instanceEntity.id, type: NomenclatureEntityTypeCodeEnum.INSTANCE, from, until },
            ];

            const data1 = await loadNomenclaturesOnIntervalsIfNeed(nomenclatures);
            getStore().dispatch(updateAnyTimetables(data1.timetables, data1.elementsDelayedReturnDates));
            const productNomenclature = findTimeTableById(nomenclatureId, nomenclatureType, getStore().getState().operationForm.timeTables);
            if (productNomenclature?.nomenclature) {
                await addInstanceFromScanner(
                    data.barcodeEntityObj.id,
                    data.barcodeEntityObj.entityInfoRead as InstanceInfoRead,
                    productNomenclature.nomenclature as NomenclatureRecord
                );
                instanseAddedShowMessage();
            } else {
                throw new Error('Номенклатура не найдена');
            }
        }
    } else {
        let nomenclatureName: string | undefined;
        let variantName: string | undefined;

        if (data.barcodeEntityObj.entityTypeCode === BarcodeEntityObjEntityTypeCodeEnum.VARIANT) {
            nomenclatureName = (data.barcodeEntityObj.entityInfoRead as VariantInfoRead).productShortName;
            variantName = (data.barcodeEntityObj.entityInfoRead as VariantInfoRead).name;
        } else if (data.barcodeEntityObj.entityTypeCode === BarcodeEntityObjEntityTypeCodeEnum.PRODUCT) {
            nomenclatureName = (data.barcodeEntityObj.entityInfoRead as ProductInfoRead).shortName;
        } else if (data.barcodeEntityObj.entityTypeCode === BarcodeEntityObjEntityTypeCodeEnum.KIT) {
            nomenclatureName = (data.barcodeEntityObj.entityInfoRead as KitInfoRead).shortName;
        }

        //console.log('SCAN', data.barcodeEntityObj.entityTypeCode, data);
        if (
            data.barcodeEntityObj.entityInfoRead &&
            data.barcodeEntityObj.entityInfoRead['stateCode'] === NomenclatureStateCodeEnum.NOTAVAILABLE
        ) {
            //showBarcodeScannerNotification('error', <>Номенклатура <span style={{fontWeight: 800}}>{nomenclatureName}{variantName ? ': ' + variantName : null}</span> недоступна</>, undefined,null);
            getStore().dispatch(
                showScanErrorNotification({
                    type: ScanErrorType.NomenclatureNotAvailable,
                    productName: nomenclatureName || '',
                    variantName: variantName,
                })
            );
            return;
        } else if (
            data.barcodeEntityObj.entityInfoRead &&
            data.barcodeEntityObj.entityInfoRead['stateCode'] === NomenclatureStateCodeEnum.NEW
        ) {
            //showBarcodeScannerNotification('error', <>Номенклатура <span style={{fontWeight: 800}}>{nomenclatureName}{variantName ? ': ' + variantName : null}</span> в неактивном статусе</>, undefined,null);
            getStore().dispatch(
                showScanErrorNotification({
                    type: ScanErrorType.NomenclatureInInactiveStatus,
                    productName: nomenclatureName || '',
                    variantName: variantName,
                })
            );
            return;
        }
        let productId = data.product?.id;
        let variantId = data.variant?.id;
        let kitId = data.kit?.id;
        let timetableVersion = data.kit?.timetableVersion || data.variant?.timetableVersion || data.product?.timetableVersion || 0;
        let businessVersion = data.barcodeEntityObj.entityInfoRead?.businessVersion;
        let nomenclature;

        // Если сканируем продукт с вариантами, то типа ошибка, нужно сканировать Вариант
        if (productId && !variantId) {
            let entity = data.barcodeEntityObj?.entityInfoRead as ProductInfoRead;
            if (
                entity.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK ||
                entity.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
            ) {
                //showBarcodeScannerNotification('error', <>Добавление продукта <span style={{fontWeight: 800}}>{nomenclatureName}</span> невозможно, отсканируйте нужный вариант</>, undefined, null);
                getStore().dispatch(
                    showScanErrorNotification({
                        type: ScanErrorType.ProductAddingNotPossible,
                        productName: nomenclatureName || '',
                    })
                );
                return;
            }
        }

        if (!nomenclature) {
            nomenclature = {
                productId: productId,
                variantId: variantId,
                kitId: kitId,
                timetableVersion: timetableVersion,
                businessVersion: businessVersion,
            };
        }
        //await OperationFormUtils.addItemFromProducts(nomenclature);
        //try{
        await OperationFormUtils.addItemFromProducts(nomenclature);
        nomenclatureAddedShowMessage(variantId ? NomenclatureEntityTypeCodeEnum.VARIANT : (kitId ? NomenclatureEntityTypeCodeEnum.KIT : NomenclatureEntityTypeCodeEnum.PRODUCT), true);
        //}catch (error){
        //    showNotification('error', 'Ошибка');
        //    console.error(error);
        //}
    }
};

const scanOnEquipmentTab = async ({
    data,
    equipmentFilteredEntities,
    elements,
    instanseAddedShowMessage,
    nomenclatureAddedShowMessage,
    addKitMembersWhenInstanceScan,
}: Props) => {
    if (data.barcodeEntityObj.entityTypeCode === BarcodeEntityObjEntityTypeCodeEnum.INSTANCE) {
        let instanceEntity = data.barcodeEntityObj.entityInfoRead as InstanceInfoRead;
        let equipmentElement: RentElementsGridItem | undefined;
        equipmentFilteredEntities?.forEach((el) => {
            if (el.subRows && el.subRows.length > 0) {
                el.subRows.forEach((el1) => {
                    if (el1.instanceIds && el1.instanceIds.includes(instanceEntity.id)) equipmentElement = el1;
                });
            } else {
                if (el.instanceIds && el.instanceIds.includes(instanceEntity.id)) equipmentElement = el;
            }
        });

        if (
            equipmentElement &&
            elements.find((el) => el.id === equipmentElement?.id && el.instanceIds?.includes(instanceEntity.id) && el.isCancelled !== true)
        ) {
            equipmentElement = undefined;
        }

        if (OperationFormUtils.findInstanceInElements(instanceEntity, getStoreState().operationForm, false)) {
            //showBarcodeScannerNotification('error', <>Экземпляр <span style={{fontWeight: 800}}>{instanceEntity.nameOrCode}</span> уже добавлен</>, undefined, null);
                getStore().dispatch(
                    showScanErrorNotification({
                        type: ScanErrorType.InstanceAlreadyAdded,
                        instanceName: instanceEntity.nameOrCode,
                    })
                );
        } else {
            // Это если не найден по id, то ищем по id продукта и id варианта
            if (!equipmentElement) {
                let rows = OperationFormUtils.flatElementsList(equipmentFilteredEntities || []).filter((el, index) => {
                    let row;
                    if (isDefined(instanceEntity.variantId)) {
                        if (el.productId === instanceEntity.productId && el.variantId === instanceEntity.variantId) row = el;
                    } else {
                        if (el.productId === instanceEntity.productId && !isDefined(el.variantId)) row = el;
                    }
                    if (row) {
                        let addedEl = elements.find((el) => el.id === row.id && el.isCancelled !== true);
                        if (addedEl && addedEl.anonymousInstanceCountOriginal && addedEl.anonymousInstanceCountOriginal > 0) return true;
                        else if (!addedEl && row.anonymousInstanceCount > 0) return true;
                    }
                    return false;
                });
                if (rows.length > 0) {
                    let rowsAdded = 0;
                    for (let i = 0; i < rows.length; i++) {
                        let addedEl = elements.find((el) => el.id === rows[i].id && el.isCancelled !== true);
                        if (!addedEl) {
                            equipmentElement = rows[i];
                            i = rows.length;
                        } else {
                            if (addedEl.instanceCount < addedEl.instanceCountOriginal || addedEl.anonymousInstanceCount) {
                                equipmentElement = rows[i];
                                i = rows.length;
                            } else {
                                rowsAdded++;
                            }
                        }
                    }
                    if (rowsAdded === rows.length) {
                        //showBarcodeScannerNotification('error', <>Вся найденная в текущей выборке номенклатура <span style={{fontWeight: 800}}>{instanceEntity.productShortName}{instanceEntity.variantName ? ': ' + instanceEntity.variantName : null}</span> уже добавлена</>, undefined, null);
                        getStore().dispatch(
                            showScanErrorNotification({
                                type: ScanErrorType.AllFoundNomenclaturesInCurrentSelectionAlreadyAdded,
                                productName: instanceEntity.productShortName,
                                variantName: instanceEntity.variantName,
                            })
                        );
                        return;
                    }
                }
                if (!equipmentElement) {
                    // showBarcodeScannerNotification('error', <>Экземляр <span
                    //     style={{fontWeight: 800}}>{instanceEntity.nameOrCode}</span> и его
                    //     номенклатура <span
                    //         style={{fontWeight: 800}}>{instanceEntity.productShortName}{instanceEntity.variantName ? ':' + instanceEntity.variantName : null}</span> не
                    //     найдены в проекте</>, undefined, null);
                    getStore().dispatch(
                        showScanErrorNotification({
                            type: ScanErrorType.InstanceAndNomenclatureNotFoundInProject,
                            productName: instanceEntity.productShortName,
                            variantName: instanceEntity.variantName,
                            instanceName: instanceEntity.nameOrCode,
                        })
                    );
                    return;
                }
            }
            // Найден элемент в котором есть такой экземпляр
            // Элемент уже добавлен: он есть в Добавлено
            let addedEl = elements.find((el) => el.id === equipmentElement?.id && el.isCancelled !== true);
            let addedCancelledEl = elements.find((el) => el.id === equipmentElement?.id && el.isCancelled === true);
            if (addedEl) {
                if (addedEl.instanceCount === addedEl.instanceCountOriginal && !addedEl.anonymousInstanceCount) {
                    // showBarcodeScannerNotification('error', <>Вся найденная в текущей выборке
                    //     номенклатура <span
                    //         style={{fontWeight: 800}}>{instanceEntity.productShortName}{instanceEntity.variantName ? ': ' + instanceEntity.variantName : null}</span> уже
                    //     добавлена</>, undefined, null);
                    getStore().dispatch(
                        showScanErrorNotification({
                            type: ScanErrorType.AllFoundNomenclaturesInCurrentSelectionAlreadyAdded,
                            productName: instanceEntity.productShortName,
                            variantName: instanceEntity.variantName,
                        })
                    );
                    return;
                }
                if (addedEl.instanceIds?.includes(instanceEntity.id)) {
                    // Если есть такой, то ошибка
                    // showBarcodeScannerNotification('error', <>Экземпляр <span
                    //     style={{fontWeight: 800}}>{instanceEntity.nameOrCode}</span> уже
                    //     добавлен</>, undefined, null);
                    getStore().dispatch(
                        showScanErrorNotification({
                            type: ScanErrorType.InstanceAlreadyAdded,
                            instanceName: instanceEntity.nameOrCode,
                        })
                    );
                } else {
                    // Есть такое обязательство, просто нужно добавить экземпляр, и мб загрузить его карту, если нет
                    // ДОБАВЛЕНИЕ
                    addElementToElementsFromScan1(
                        equipmentElement,
                        instanceEntity.id,
                        () => {
                            instanseAddedShowMessage();
                        },
                        true
                    );
                }
            } else if (!addedCancelledEl) {
                // Элемент не добавлен, нужно его добавить и загрузить карты
                // ДОБАВЛЕНИЕ
                if (addKitMembersWhenInstanceScan && equipmentElement.parent) {
                    // Новый режим
                    try {
                        await addInstanceWithKit(equipmentElement, instanceEntity.id, () => {
                            instanseAddedShowMessage();
                            nomenclatureAddedShowMessage(NomenclatureEntityTypeCodeEnum.KIT, false);
                        });
                    } catch (e) {
                        showNotification('error', 'Ошибка');
                    }
                } else {
                    addElementToElementsFromScan(
                        equipmentElement,
                        instanceEntity.id,
                        () => {
                            instanseAddedShowMessage();
                        },
                        true
                    );
                }
            } else if (addedCancelledEl) {
                // showBarcodeScannerNotification('error', <>Экземляр <span
                //     style={{fontWeight: 800}}>{instanceEntity.nameOrCode}</span> и его
                //     номенклатура <span
                //         style={{fontWeight: 800}}>{instanceEntity.productShortName}{instanceEntity.variantName ? ':' + instanceEntity.variantName : null}</span> не
                //     найдены в проекте</>, undefined, null);
                getStore().dispatch(
                    showScanErrorNotification({
                        type: ScanErrorType.InstanceAndNomenclatureNotFoundInProject,
                        productName: instanceEntity.productShortName,
                        variantName: instanceEntity.variantName,
                        instanceName: instanceEntity.nameOrCode,
                    })
                );
                return;
            }
        }
    } else {
        let productId = data.product?.id;
        let variantId = data.variant?.id;
        let kitId = data.kit?.id;
        let nomenclatureName = '';
        let nomenclatureVariantName = '';

        if (data.barcodeEntityObj.entityTypeCode === BarcodeEntityObjEntityTypeCodeEnum.PRODUCT) {
            nomenclatureName = (data.barcodeEntityObj.entityInfoRead as ProductInfoRead).shortName;
        } else if (data.barcodeEntityObj.entityTypeCode === BarcodeEntityObjEntityTypeCodeEnum.VARIANT) {
            nomenclatureName = (data.barcodeEntityObj.entityInfoRead as VariantInfoRead).productShortName;
            nomenclatureVariantName = (data.barcodeEntityObj.entityInfoRead as VariantInfoRead).name;
        } else if (data.barcodeEntityObj.entityTypeCode === BarcodeEntityObjEntityTypeCodeEnum.KIT) {
            nomenclatureName = (data.barcodeEntityObj.entityInfoRead as KitInfoRead).shortName;
        }

        // Если сканируем продукт с вариантами, то типа ошибка, нужно сканировать Вариант
        if (productId && !variantId) {
            let entity = data.barcodeEntityObj?.entityInfoRead as ProductInfoRead;
            if (
                entity.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK ||
                entity.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
            ) {
                //showBarcodeScannerNotification('error', <>Добавление продукта <span style={{fontWeight: 800}}>{nomenclatureName}</span> невозможно, отсканируйте нужный вариант</>, undefined, null);
                getStore().dispatch(
                    showScanErrorNotification({
                        type: ScanErrorType.ProductAddingNotPossible,
                        productName: nomenclatureName,
                    })
                );
                return;
            }
        }

        //// Ищим элемент, который нужно добавить, по продукту, варианту, набору
        let fElement:RentElementsGridItem|undefined;
        let eqElement:RentElementsGridItem|undefined;

        let allElements: RentElementsGridItem[] = OperationFormUtils.flatElementsList(equipmentFilteredEntities || []);
        allElements.reverse();

        let maxAdded = false;

        allElements.forEach((el) => {
            if (!fElement) {
                if (data.barcodeEntityObj.entityTypeCode) {
                    let fEl:RentElementsGridItem|undefined;
                    if (el.productId === productId && !variantId && !el.variantId) {
                        fEl = el;
                    } else if (el.productId === productId && variantId && el.variantId === variantId) {
                        fEl = el;
                    } else if (kitId && el.kitId === kitId) {
                        fEl = el;
                    }
                    if (fEl) {
                        let addedEl = elements.find((el) => el.id === fEl?.id && el.isCancelled !== true);
                        let addedCancelledEl = elements.find((el) => el.id === fEl?.id && el.isCancelled === true);
                        if (addedEl) nomenclatureName = addedEl.productShortName;
                        if (addedEl) {
                            if (addedEl.instanceCount < addedEl.instanceCountOriginal) {
                                fElement = el;
                                maxAdded = false;
                            } else {
                                if(addedEl.kitId && fEl.subRows){
                                    let addedCount = 0;
                                    fEl.subRows.forEach((e)=>{
                                        const el = elements.find((el)=>el.id===e.id);
                                        if(el && el.instanceCountOriginal >= el.instanceCount) addedCount += 1;
                                    });
                                    if(fEl.subRows.length === addedCount){
                                        maxAdded = true;
                                    }else{
                                        fElement = el;
                                        maxAdded = false;
                                    }
                                }else{
                                    maxAdded = true;
                                }
                            }
                        }
                        if (!addedEl && !addedCancelledEl) {
                            eqElement = el;
                        }
                    }
                }
            }
        });

        if (fElement) {

            addElementToElementsFromScan1(
                fElement,
                undefined,
                () => {
                    if(fElement) nomenclatureAddedShowMessage(fElement.variantId ? NomenclatureEntityTypeCodeEnum.VARIANT : (fElement.kitId ? NomenclatureEntityTypeCodeEnum.KIT : NomenclatureEntityTypeCodeEnum.PRODUCT), true);
                },
                undefined,
                true
            );
        } else if (eqElement) {
            addElementToElementsFromScan(
                eqElement,
                undefined,
                () => {
                    if(eqElement) nomenclatureAddedShowMessage(eqElement.variantId ? NomenclatureEntityTypeCodeEnum.VARIANT : (eqElement.kitId ? NomenclatureEntityTypeCodeEnum.KIT : NomenclatureEntityTypeCodeEnum.PRODUCT), true);
                },
                true
            );
        } else {
            if (maxAdded) {
                //showBarcodeScannerNotification('error', <>Вся найденная в текущей выборке номенклатура <span style={{fontWeight: 800}}>{nomenclatureName}{nomenclatureVariantName ? ': ' + nomenclatureVariantName : null}</span> уже добавлена</>, undefined, null);
                getStore().dispatch(
                    showScanErrorNotification({
                        type: ScanErrorType.AllFoundNomenclaturesInCurrentSelectionAlreadyAdded,
                        productName: nomenclatureName,
                        variantName: nomenclatureVariantName,
                    })
                );
            } else {
                //showBarcodeScannerNotification('error', <>Номенклатура <span style={{fontWeight: 800}}>{nomenclatureName}{nomenclatureVariantName ? ': ' + nomenclatureVariantName : null}</span> не найдена в текущей выборке</>, undefined,null);
                getStore().dispatch(
                    showScanErrorNotification({
                        type: ScanErrorType.NomenclatureNotFoundInCurrentSelection,
                        productName: nomenclatureName,
                        variantName: nomenclatureVariantName,
                    })
                );
            }
        }
    }
};

const scanOnElementsTab = async ({ data, instanseAddedShowMessage }: Props) => {
    if (data.barcodeEntityObj.entityTypeCode === BarcodeEntityObjEntityTypeCodeEnum.INSTANCE) {
        let instanceEntity = data.barcodeEntityObj.entityInfoRead as InstanceInfoRead;
        OperationFormUtils.addInstanceFromELementsTab(instanceEntity, () => {
            instanseAddedShowMessage();
        });
    } else {
        //showBarcodeScannerNotification('error', <>Сканирование на вкладке "Добавлено" поддерживает только замену анонимных экземпляров именованными</>, undefined, null);
        getStore().dispatch(
            showScanErrorNotification({
                type: ScanErrorType.ScanningOnAddedTabError,
            })
        );
    }
};

const addInstanceWithKit = async (equipmentElement: RentElementsGridItem, instanceId: number, succesCb?: () => void) => {
    try {
        if (equipmentElement.parent) {
            const data = await prepareDataForAddingKitToElements(equipmentElement.parent, true);
            //const records:(NomenclatureRecord|InstanceRecord)[] = [...data.records];
            const el = data.elements.find((e) => e.id === equipmentElement.id);
            //let instRec:InstanceRecord|undefined;
            // if(el){
            //     el.instanceIds = [instanceId];
            //     if(el.anonymousInstanceCount > 0) el.anonymousInstanceCount -= 1;
            //     instRec = await loadInstanceTimetableIfNotFound({
            //         instanceId: instanceId,
            //         productId: el.productId,
            //         variantId: el.variantId,
            //         startDate: el.rentPeriodStartDate,
            //         endDate: el.rentPeriodEndDate
            //     });
            //     if(instRec) records.push(instRec);
            // }

            if(el){
                el.instanceIds = [instanceId];
                if (el.anonymousInstanceCount > 0) el.anonymousInstanceCount -= 1;
            }
            getStore().dispatch(addNewOperationElementFromEquipment(data.elements, [] /*records*/));
            if (el) {
                const res = await loadNomenclaturesOnIntervalsIfNeed([
                    {
                        id: instanceId,
                        type: NomenclatureEntityTypeCodeEnum.INSTANCE,
                        from: moment(el.rentPeriodStartDate).valueOf(),
                        until: moment(el.rentPeriodEndDate).valueOf(),
                    },
                ]);
                getStore().dispatch(updateAnyTimetables(res.timetables, res.elementsDelayedReturnDates));
            }
            getStore().dispatch(addNewOperationElementFromEquipment1(data.elements, [] /*records*/));
            succesCb?.();
        }
    } catch (e) {
        showNotification('error', 'Ошибка');
    }
};
