import { Icon, Popover } from 'antd';
import { PledgeAuditInfoRead } from '../../../../../../server';
import { declOfNum } from '../../../../../../components/link/toPayments/LinkToPayments';
import { IconHistorySolid } from '../../../../../../components/icons';
import './PledgeHistoryPopover.less';
import { formatMoney } from '../../../../../../shared/util/formatMoney';
import { formatDate } from '../../../../../../shared/util/formatDate';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { canViewFinancialDataSelector } from '../../../../../../shared/reducers/permissions.reducer';

interface Props {
    items: PledgeAuditInfoRead[] | undefined;
}

const PledgeHistoryPopover = ({ items }: Props) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    return !items?.length ? null : (
        <Popover
            trigger="click"
            placement="left"
            content={
                <div className={'rr-simple-popover-content'}>
                    <div className="rr-simple-popover-header">
                        <Icon style={{ color: '#34bfa3' }} component={IconHistorySolid} />
                        <span>Изменения залога</span>
                    </div>
                    <ul className="rr-simple-popover-list">
                        {[...items]
                            .sort((a, b) => new Date(b.auditDate).getTime() - new Date(a.auditDate).getTime())
                            .map((item, i) => (
                                <li key={item.auditDate.toString()} className="rr-simple-popover-list-item">
                                    <div className="rr-pledge-popover-audit-info">
                                        <span>{formatDate(new Date(item.auditDate), 'D.MM.YY HH:mm')}</span>
                                        <span>
                                            <Link to={`/${businessAccountId}/users/${item.auditAuthorId}`} className="rr-link">
                                                {item.auditAuthorShortName}
                                            </Link>
                                        </span>
                                        {canViewFinancialData && <span style={{ textAlign: 'end' }}>{formatMoney(item.sum)}</span>}
                                    </div>
                                    {!!item.description && <div className="rr-pledge-popover-audit-description">{item.description}</div>}
                                    {!canViewFinancialData && !item.description && (
                                        <div className="rr-pledge-popover-audit-description rr-value-muted">Нет</div>
                                    )}
                                </li>
                            ))}
                    </ul>
                </div>
            }
        >
            <span className="rr-link rr-simple-popver-button">
                {items.length} {declOfNum(items.length, ['изменений', 'изменение', 'изменения'])}
            </span>
        </Popover>
    );
};
export default PledgeHistoryPopover;
