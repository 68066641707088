import React from 'react';
import { EntityActionType, EntityType } from '../../../../index';
import { TableColumn } from '../Table';
import {
    ColumnDef,
    ColumnPinningState,
    ExpandedState,
    getCoreRowModel,
    getExpandedRowModel,
    SortingState,
    Table,
    useReactTable,
} from '@tanstack/react-table';
import { ReactTableTable } from './ReactTableTable';
import { ReactTableRowSelectCheckbox } from './ReactTableRowSelectCheckbox';
import { usePrevious } from './ReactTable_utils';
import { Icon } from 'antd';
import { IconAngleDown, IconAngleUp } from '../../icons';
import { ReactTableController } from './ReactTableController';
import get from 'get-value';
import { isDefined } from '../../../shared/util/utils';
import { GridStorageData } from '../utils';
import { ReactTableRowRender } from './ReactTableRowRender';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { updateGridSettings } from '../../../shared/reducers/userSettings/userSettings.reducer';
import { DndProps } from './ReactTableBody';

interface IProps<T extends object> {
    columns: Array<TableColumn<T>>;
    data: any[] | null;
    name: string;
    excludeColumns: Array<string>;
    rowPopoverComponent?: any;
    rowPopoverComponentProps?: any;
    entityType: EntityType;
    selectable: boolean;
    defaultSortDesc?: boolean;
    defaultSorted?: string;
    rowId: string;
    readyCB?: any;
    pinned?: ColumnPinningState;

    onColumnRemove?(id?: string): void;

    onRowAction?(entity: any, action: string, instanceCount?: number): void;
    onRowEditAction?(entity: any, action: EntityActionType, instanceCount?: number): void;
    onCellAction?(dataIndex: string, record: Record<string, any>): void;

    onRowClick?(entity: any): void;

    onSelectionChanged?(selection: any[]): void;

    getRowProps?(state, rowInfo, column): void;

    onSortedChange?(id: string, desc: boolean): void;

    getUniqId?(data: any): string;

    alwaysRedraw: boolean;

    page: number;
    onPage: number;
    selectWidth?: number;

    dndProps?: DndProps;

    sortingDisabled?: boolean;
}

export function ReactTable<T extends object>(props: IProps<T>) {
    const gridData:GridStorageData|undefined = useAppSelector((state) => state.userSettings.gridSettings[props.name]);
    const dispatch = useAppDispatch();

    const getColumns = (cols: TableColumn<T>[]) => {
        let expandable = !!props.data?.find((item) => item.subRows);

        let columns: ColumnDef<any>[] = cols
            .filter((item) => !item.dataIndex || props.excludeColumns.indexOf(item.dataIndex as string) === -1)
            .map((item, index) => {
                let key = item.dataIndex || '-' + index;
                let column = {
                    accessorFn: (originalRow: any, index: number) => {
                        return get(originalRow, key as string);
                    },
                    id: item.id ?? (key as string),
                    cell: ReactTableRowRender,
                    header: item.title ? (props) => item.title : undefined,
                    columnType: item.type,
                    columnCustomRenderer: item.render,
                    onRowEditAction: props.onRowEditAction,
                    onRowAction: props.onRowAction,
                    onCellAction: props.onCellAction,
                    entityType: props.entityType,
                    rowPopoverComponent: props.rowPopoverComponent,
                    rowPopoverComponentProps: props.rowPopoverComponentProps,
                    enableResizing: item.resizable,

                    className: item.className,
                    headerClassName: item.headerClassName,

                    enableSorting: item.sortable !== false,
                    enableMultiSort: false,
                    sortUndefined: false as any,

                    removable: item.removable,

                    meta: {
                        columnType: item.type,
                        columnCustomRenderer: item.render,
                        onRowAction: props.onRowAction,
                        onCellAction: props.onCellAction,
                        entityType: props.entityType,
                        rowPopoverComponent: props.rowPopoverComponent,
                        rowPopoverComponentProps: props.rowPopoverComponentProps,
                    },
                };
                if (item.width !== undefined && item.width > 0) column['size'] = item.width;
                if (item.minWidth !== undefined) column['minSize'] = item.minWidth;
                if (item.maxWidth !== undefined) column['maxSize'] = item.maxWidth;
                if (item.width === undefined && item.minWidth !== undefined) column['size'] = item.minWidth;

                if (
                    gridData &&
                    key &&
                    gridData.columns &&
                    gridData.columns[key as string] !== undefined &&
                    gridData.columns[key as string].width !== undefined
                ) {
                    column['size'] = gridData.columns[key as string].width;
                }

                return column;
            });

        if (expandable) {
            columns.unshift({
                id: 'expanded',
                enableResizing: false,
                minSize: 34,
                size: 34,
                maxSize: 34,
                header: undefined,
                cell: ({ row }) => {
                    return row.getCanExpand() ? (
                        <div>
                            <div style={{ fontSize: 24, cursor: 'pointer' }} onClick={row.getToggleExpandedHandler()}>
                                {row.getIsExpanded() ? <Icon component={IconAngleUp} /> : <Icon component={IconAngleDown} />}
                            </div>
                        </div>
                    ) : null;
                },
            });
        }

        if (props.selectable) {
            columns.unshift({
                id: 'select',
                enableResizing: false,
                enableSorting: false,
                header: ({ table }) => {
                    return (
                        <ReactTableRowSelectCheckbox
                            {...{
                                checked: table.getIsAllRowsSelected(),
                                indeterminate: table.getIsSomeRowsSelected(),
                                onChange: table.getToggleAllRowsSelectedHandler(),
                            }}
                        />
                    );
                },
                cell: ({ row }) => {
                    return (
                        <ReactTableRowSelectCheckbox
                            {...{
                                checked: row.getIsSelected(), //row.getCanExpand() ? row.getIsAllSubRowsSelected() : row.getIsSelected(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    );
                },
                size: props.selectWidth,
                minSize: props.selectWidth,
                maxSize: props.selectWidth,
            });
        }

        return columns;
    };

    /////////////////////////
    const prevPage = usePrevious(props.page);
    React.useEffect(() => {
        if (prevPage != props.page) {
            table.resetRowSelection();
            table.resetExpanded();
        }
    }, [props.page]);

    const prevOnPage = usePrevious(props.onPage);
    React.useEffect(() => {
        if (prevOnPage != props.onPage) {
            table.resetRowSelection();
            table.resetExpanded();
        }
    }, [props.onPage]);
    /////////////////////////

    // Сортировка ///////////
    const [sorting, setSorting] = React.useState<SortingState>(
        props.defaultSorted && isDefined(props.defaultSortDesc)
            ? [
                  {
                      id: props.defaultSorted,
                      desc: props.defaultSortDesc,
                  },
              ]
            : []
    );

    React.useEffect(() => {
        setSorting(
            props.defaultSorted && isDefined(props.defaultSortDesc)
                ? [
                      {
                          id: props.defaultSorted,
                          desc: props.defaultSortDesc,
                      },
                  ]
                : []
        );
    }, [props.defaultSorted, props.defaultSortDesc]);

    const prevSorting = usePrevious(sorting);
    //
    React.useEffect(() => {
        if (prevSorting != undefined && JSON.stringify(prevSorting) !== JSON.stringify(sorting)) {
            if (props.onSortedChange && sorting && sorting.length > 0) {
                props.onSortedChange(sorting[0].id, sorting[0].desc);
                table.resetRowSelection();
                table.resetExpanded();
            }
        }
    }, [sorting, prevSorting]);
    // Сортировка ///////////

    // Выбранные строки ///////////
    const [rowSelection, setRowSelection] = React.useState({});
    const prevRowSelection = usePrevious(rowSelection);

    const [columnSizing, setColumnSizing] = React.useState({});

    React.useEffect(() => {
        if (prevRowSelection != undefined && JSON.stringify(prevRowSelection) !== JSON.stringify(rowSelection)) {
            if (props.onSelectionChanged) {
                const selectedIds = Object.keys(rowSelection);
                const _ids: number[] = [];
                selectedIds.forEach((id) => {
                    const ids = id.split('.');
                    if (ids[0]) {
                        let item = data[ids[0]];
                        if (item && !ids[1]) {
                            if (!item.subRows || item.subRows.length === 0) _ids.push(item.id);
                        } else {
                            if (item?.subRows) {
                                if (item.subRows[ids[1]] && item.subRows[ids[1]].id) _ids.push(item.subRows[ids[1]].id);
                            }
                        }
                    }
                });
                props.onSelectionChanged(_ids);
            }
        }
    }, [prevRowSelection, rowSelection]);
    // Выбранные строки ///////////

    // Группы открытые ///////////
    const [expanded, setExpanded] = React.useState<ExpandedState>({});
    //
    const columns = React.useMemo<ColumnDef<any>[]>(() => {
        return getColumns(props.columns);
    }, [props.columns]);
    // Группы открытые ///////////

    const getData = () => {
        return props.data || [];
    };

    const data = getData();

    ///Pinning
    const [columnPinning, setColumnPinning] = React.useState<ColumnPinningState>(props.pinned ?? {});

    // Инициализация
    React.useEffect(() => {
        if (props.readyCB) {
            props.readyCB(new ReactTableController(table));
        }
    }, []);

    const table: Table<any> = useReactTable({
        data,
        columns,
        enableRowSelection: props.selectable,

        // Состояние
        state: {
            rowSelection,
            sorting,
            expanded,
            columnSizing,
            columnPinning,
        },

        // Сортировка
        manualSorting: true,
        enableSorting: props.sortingDisabled === true ? false : true,
        enableSortingRemoval: false,
        onSortingChange: setSorting,
        onColumnPinningChange: setColumnPinning,
        getCoreRowModel: getCoreRowModel(),

        // Выбор строк
        onRowSelectionChange: setRowSelection,
        enableSubRowSelection: true,
        enableMultiRowSelection: true,

        // Вложенные строки
        onExpandedChange: setExpanded,
        getSubRows: (row) => row.subRows,
        getExpandedRowModel: getExpandedRowModel(),

        // Ресайз
        enableColumnResizing: true,
        columnResizeMode: 'onEnd',
        onColumnSizingChange: (f: any) => {
            let columnSizing = f(table.getState().columnSizing);
            setColumnSizing(columnSizing);
            const columns = gridData?.columns ? { ...gridData.columns } : {};
            for (let k in columnSizing) {
                columns[k] = columns[k] ? { ...columns[k] } : {};
                columns[k].width = columnSizing[k];
            }
            dispatch(updateGridSettings({ [props.name]: { ...gridData, columns } }));
        },

        enablePinning: true,
    });

    return (
        <ReactTableTable
            resizeMode
            table={table}
            getRowProps={props.getRowProps}
            onColumnRemove={props.onColumnRemove}
            getUniqId={props.getUniqId}
            alwaysRedraw={props.alwaysRedraw}
            onRowClick={props.onRowClick}
            dndProps={props.dndProps}
        />
    );
}
