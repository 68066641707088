import sortBy from "lodash/sortBy";
import {OperationElement} from "../../modules/main/operationForm/reducers/operationForm.reducer";
import {isDefined} from "./utils";

const sortByFieldNameIgnoreCasing = (row, fieldName:string) => {
    return fieldName ? sortBy(row, [row=>typeof row[fieldName] === 'string' ? row[fieldName].toLowerCase() : row[fieldName]]) : row;
}

export const sortOperationElements = (operationElements:OperationElement[], fieldName:string) => {
    const notKitMembers =  operationElements.filter((el)=>el.parentId === undefined);
    const kitMembers =  operationElements.filter((el)=>el.parentId !== undefined);
    const filtered:OperationElement[] = sortByFieldNameIgnoreCasing(notKitMembers, fieldName);
    return [...filtered, ...kitMembers];
}

export const sortCopiedKits = (filtered:OperationElement[]) => {
    const copiedKits = sortBy(filtered.filter(el=>isDefined(el.kitCopyId)), 'id').reverse();
    copiedKits.forEach(el=>{
        filtered.splice(filtered.indexOf(el), 1);
        const index = filtered.findIndex(e=>e.id===el.kitCopyId);
        filtered.splice(index+1, 0, el);
    });
    console.log('copiedKits', copiedKits, filtered)
    return filtered;
}