import React from 'react';
import { Card, Col, Icon, Row } from 'antd';
import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { AbstractEntityInfoCard, FormValue } from '../../../../components';
import { Status } from '../../../../components/status/status';
import { printUserName } from '../../../../shared/util/utils';
import { IconDollySolid } from '../../../../components/icons';
import { CurrentRentIndicatorsBlock } from '../../../../components/currentRentIndicators/currentRentIndicatorsBlock';
import moment from 'moment';
import { getBusinessAccountId } from '../../../../../index';
import { Link } from 'react-router-dom';
import { LocalizationEnum, localize } from '../../../../localization';
import { AttachmentParentEntityTypeCodeEnum, RentStateCodeEnum, SubrentStateCodeEnum } from '../../../../server';
import { RichTextView } from '../../../../components/richTextView/RichTextView';
import { Avatar } from '../../../../components/avatar/avatar';
import { getGridStorageData, setGridStorageDataFilters } from '../../../../components/grid/utils';
import { formatMoney } from '../../../../shared/util/formatMoney';
import { FormValueMoneyProblem } from '../../../../components/formValue/FormValueMoneyProblem';
import { canViewFinancialData } from '../../../../shared/util/permissionUtils';
import { LinkToPayments } from '../../../../components/link/toPayments/LinkToPayments';
import { AttachmentsBlock } from '../../../../components/attachmentsBlock/attachmentsBlock';
import { loadEntity } from './reducers/subrent.reducer';
import { DataDisplayBlock, DataDisplayItemType } from '../../../../components/dataDisplay';
import { LinkButton2 } from '../../../../components/buttons/linkButton2/linkButton2';
import { SystemIcon, SystemIconType } from '../../../../components/v2/systemIcon/systemIcon';
import { push } from 'connected-react-router';
import { SubrentShippingPageDescriptionContactsBlock } from './subrentShippingPageDescriptionContactsBlock';
import classNames from 'classnames';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { DateIntervalHighLight } from '../../../../components/dataDisplay/dataDisplayItemProps';

interface IProps extends StateProps, DispatchProps {}

const GRID_NAME: string = 'project-description';

class _SubrentShippingPageDescriptionTabPane extends React.PureComponent<IProps> {
    state = {
        collapsed: [],
    };

    componentDidMount = async () => {
        let collapsed = await getGridStorageData(GRID_NAME);

        if (collapsed.filters?.collapsedIndicators && collapsed.filters?.collapsedIndicators.length > 0) {
            await this.setState({
                collapsed: collapsed.filters.collapsedIndicators,
            });
        } else {
            await this.setState({
                collapsed: collapsed.filters?.collapsedIndicators,
            });
        }

        console.log('ProjectPageDescriptionTabPane componentDidMount()');
    };

    componentWillUnmount = () => {
        console.log('ProjectPageDescriptionTabPane componentWillUnmount()');
    };

    onPay = () => {
        this.props.push(`/${this.props.businessAccountId}/subrent/shippings/${this.props.data?.id}/pay/?tab=description`);
    };

    onCollapsedIndicators = (key) => {
        setGridStorageDataFilters(GRID_NAME, { collapsedIndicators: key });
        if (key && key.length > 0) {
            this.setState({
                collapsed: key,
            });
        } else {
            this.setState({
                collapsed: key,
            });
        }
    };

    render() {
        console.log('SubrentPageDescriptionTabPane render()');

        let { data, canViewFinancialData } = this.props;
        console.log(data);

        // TODO мб как-то по другому обнулять часы/минуты/секунды/мсек
        let targetFinishDate = data && data.targetFinishDate ? moment(data.targetFinishDate) : null;
        let realFinishDate = data && data.realFinishDate ? moment(data.realFinishDate) : null;

        if (targetFinishDate) targetFinishDate.hours(0).minutes(0).seconds(0).milliseconds(0);
        if (realFinishDate) realFinishDate.hours(0).minutes(0).seconds(0).milliseconds(0);

        let paidSum = !data ? 0 : data.payedToSupplierSum;
        let debtSum = data ? data.debtToSupplierSum : 0;

        return data ? (
            <Row gutter={32}>
                {/* Левый блок */}
                <Col xxl={6} xl={8} lg={10} span={24} style={{ marginBottom: 32 }}>
                    <Card bordered={false} className={'rr-card-without-padding'}>
                        <div>
                            <div style={{ padding: 32, paddingTop: 30 }}>
                                <div style={{ textAlign: 'center', marginBottom: 28 }}>
                                    <Avatar className={'rr-avatar-medium-110'} style={{ backgroundColor: '#90dcff' }}>
                                        <Icon component={IconDollySolid} />
                                    </Avatar>
                                </div>

                                <div style={{ marginBottom: 9 }}>
                                    <Status entityType={'subrent'} code={data.stateCode} />
                                </div>

                                <Row>
                                    <Col className="rr-form-item" style={{ marginBottom: 29 }}>
                                        <div className="rr-label">{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE)}</div>
                                        <h3>
                                            <FormValue
                                                style={{ wordBreak: 'break-word' }}
                                                value={data.shortName}
                                                emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                            />
                                        </h3>
                                    </Col>
                                    {/*<Col className={'rr-form-item'}>*/}
                                    {/*    <div className='rr-label'>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__CODE)}</div>*/}
                                    {/*    <div className='rr-value'><FormValue value={data.mnemoKey} emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}/></div>*/}
                                    {/*</Col>*/}
                                    <Col className="rr-form-item">
                                        <div className="rr-label">{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__ASSIGNEE)}</div>
                                        {data.hasOwnAssignee ? (
                                            data.assigneeFullName ? (
                                                <div className="h5 rr-value">
                                                    <Link to={`/${getBusinessAccountId()}/users/${data.assigneeId}`}>
                                                        <FormValue
                                                            value={printUserName(data.assigneeFullName)}
                                                            emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED)}
                                                        />
                                                    </Link>
                                                </div>
                                            ) : (
                                                <div className="rr-value">
                                                    <FormValue
                                                        value={printUserName(data.assigneeFullName)}
                                                        emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED)}
                                                    />
                                                </div>
                                            )
                                        ) : (
                                            <>
                                                {data.assigneeFullName ? (
                                                    <div className="h5 AbstractEntityInfoLink">
                                                        <Link to={`/${getBusinessAccountId()}/userProfile`}>
                                                            <FormValue
                                                                value={printUserName(data.assigneeFullName)}
                                                                emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED)}
                                                            />
                                                        </Link>
                                                    </div>
                                                ) : (
                                                    <div className="rr-value">
                                                        <FormValue
                                                            value={printUserName(data.assigneeFullName)}
                                                            emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED)}
                                                        />
                                                    </div>
                                                )}
                                                <span style={{ fontSize: '11px', color: 'rgb(170, 170, 170)' }}>(По поставщику)</span>
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </div>

                            {canViewFinancialData && (
                                <Row style={{ borderTop: '1px solid #eaeaea' }}>
                                    <div style={{ padding: 32, paddingTop: 30 }}>
                                        <Col className="rr-form-item">
                                            <div className="rr-label">Произведено оплаты</div>
                                            <div className={classNames(`rr-value`, paidSum === 0 && 'rr-0-value')}>
                                                <FormValue value={formatMoney(paidSum)} />
                                                <LinkToPayments
                                                    paymentsCount={data.paymentsToSupplierCount}
                                                    params={{ subrentId: data.id }}
                                                />
                                            </div>
                                        </Col>

                                        <Col className="rr-form-item">
                                            <div className="rr-label">Долг перед поставщиком</div>
                                            <div className={`rr-value`}>
                                                {debtSum === 0 ? (
                                                    <div
                                                        style={{
                                                            color: 'rgb(52, 191, 163)',
                                                            fontWeight: 400,
                                                            fontSize: 18,
                                                        }}
                                                    >
                                                        {localize(LocalizationEnum.ASPECT__GLOBAL__NO)}
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div
                                                            className={classNames(
                                                                `rr-value`,
                                                                (data.stateCode === SubrentStateCodeEnum.DRAFT ||
                                                                    data.stateCode === SubrentStateCodeEnum.CANCELED) &&
                                                                    'rr-0-value',
                                                                data.stateCode === SubrentStateCodeEnum.FINISHED && 'rr-danger'
                                                            )}
                                                        >
                                                            <FormValueMoneyProblem withOverpay={true} value={debtSum} />
                                                        </div>
                                                        {!!debtSum && data.stateCode === SubrentStateCodeEnum.DRAFT && (
                                                            <div className="rr-value-hint">(Не учитывается, черновик)</div>
                                                        )}
                                                        {!!debtSum && data.stateCode === SubrentStateCodeEnum.CANCELED && (
                                                            <div className="rr-value-hint">(Не учитывается, отменен)</div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            {data.stateCode !== SubrentStateCodeEnum.DRAFT &&
                                                data.stateCode !== SubrentStateCodeEnum.CANCELED && (
                                                    <LinkButton2
                                                        icon={<SystemIcon type={SystemIconType.financial} style={{ fontSize: 21 }} />}
                                                        label="Произвести оплату"
                                                        style={{ marginTop: 12 }}
                                                        onClick={this.onPay}
                                                    />
                                                )}
                                        </Col>
                                    </div>
                                </Row>
                            )}
                        </div>
                    </Card>
                </Col>

                {/* Правый блок */}
                <Col xxl={18} xl={16} lg={14} span={24}>
                    <Card bordered={false} className={'rr-card-without-padding'}>
                        <Row style={{ marginLeft: 8, marginRight: 8 }}>
                            <Col>
                                <Row gutter={16} type={'flex'}>
                                    <Col xxl={12} xl={24} lg={24} span={24} className={'rr-page-renter-block-with-right-border'}>
                                        <Row gutter={16} style={{ padding: 0, borderBottom: '1px solid #eaeaea' }}>
                                            <CurrentRentIndicatorsBlock
                                                typeRent={'subrent'}
                                                entityType={'shipping'}
                                                entity={data}
                                                onCollapsed={this.onCollapsedIndicators}
                                                collapsed={this.state.collapsed}
                                                subrentModuleEnabled={true}
                                            />
                                        </Row>

                                        <div
                                            style={{
                                                marginLeft: -8,
                                                marginRight: -8,
                                            }}
                                        >
                                            <DataDisplayBlock
                                                data={[
                                                    data.stateCode === SubrentStateCodeEnum.DRAFT ||
                                                    data.stateCode === SubrentStateCodeEnum.CANCELED
                                                        ? [
                                                              {
                                                                  label: 'Стоимость',
                                                                  type: DataDisplayItemType.Money,
                                                                  rentType:
                                                                      data.stateCode === SubrentStateCodeEnum.DRAFT
                                                                          ? RentStateCodeEnum.DRAFT
                                                                          : undefined,
                                                                  value: data.sum,
                                                                  emptyValueText: localize(
                                                                      LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED
                                                                  ),
                                                              },
                                                          ]
                                                        : undefined,
                                                    [
                                                        {
                                                            label: 'Период работ',
                                                            type: DataDisplayItemType.DateWithTimeInterval,
                                                            value: {
                                                                from: data.startDate,
                                                                to: data.targetFinishDate,
                                                            },
                                                            highlight: [
                                                                data.stateCode === SubrentStateCodeEnum.DRAFT &&
                                                                DateUtils.isPast(data.startDate)
                                                                    ? 'start'
                                                                    : undefined,
                                                                [SubrentStateCodeEnum.DRAFT, SubrentStateCodeEnum.INPROGRESS].includes(
                                                                    data.stateCode
                                                                ) && DateUtils.isPast(data.targetFinishDate)
                                                                    ? 'end'
                                                                    : undefined,
                                                            ].filter((value) => value !== undefined) as DateIntervalHighLight,
                                                            emptyValueText: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                                                        },
                                                    ],
                                                    data.stateCode === SubrentStateCodeEnum.FINISHED && [
                                                        {
                                                            label: localize(LocalizationEnum.ASPECT__FIELDS__PROJECT__REAL_FINISH_DATE),
                                                            type: DataDisplayItemType.DateWithTime,
                                                            value: data.realFinishDate,
                                                            emptyValueText: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED),
                                                        },
                                                    ],
                                                ]}
                                            />
                                        </div>
                                    </Col>
                                    <Col xxl={12} xl={24} lg={24} span={24} className={'rr-page-renter-block-with-top-border'}>
                                        <SubrentShippingPageDescriptionContactsBlock data={data} />
                                        <Row>
                                            <Col style={{ marginLeft: -8, marginRight: -8, borderBottom: '1px solid #eaeaea' }}>
                                                <AttachmentsBlock
                                                    parentEntityId={data.id}
                                                    parentEntityTypeCode={AttachmentParentEntityTypeCodeEnum.SUBRENT}
                                                    attachments={data.attachments?.records || []}
                                                    updateParentEntity={() => {
                                                        this.props.loadEntity(this.props.businessAccountId, data!.id);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ padding: '32px 24px' }}>
                                            <Col span={24} className={'rr-form-item'}>
                                                <div className={'rr-label'}>
                                                    {localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}
                                                </div>
                                                <div className={'rr-text'}>
                                                    <FormValue
                                                        value={data.description && <RichTextView value={data.description} />}
                                                        emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>

                    <AbstractEntityInfoCard leftBlockSpanLG={24} data={data} style={{ marginTop: 32 }} />
                </Col>
            </Row>
        ) : null;
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    data: storeState.subrent.entity,
    businessAccountId: storeState.system.businessAccountId,
    canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
});

const mapDispatchToProps = { loadEntity, push };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const SubrentShippingPageDescriptionTabPane = connect(mapStateToProps, mapDispatchToProps)(_SubrentShippingPageDescriptionTabPane);
