import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../../components/modalForm/ModalFormNEW';
import { connect, ConnectedProps } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IRootState } from '../../../../../../../shared/reducers';
import { ModalActionData } from '../../../../../../../components/modalForm/utils';
import { formFields } from '../LocationModalFields';
import { updateModalFilters } from '../../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { showConfirm } from '../../../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../../../localization';
import { goBack, push } from 'connected-react-router';
import { resetErrors } from '../../../../../renters/reducers/renters/renter.reducer';
import { ContactLinkObjRead, LocationInfoRead, LocationInfoUpdate } from '../../../../../../../server';
import { updateLocation } from '../../reducers/location/location.reducer';
import { LocationFormData } from '../create/LocationCreateModal';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    location: LocationInfoRead;
}

export class _LocationEditModal extends ModalFormNEW<IProps & StoreProps> {
    static defaultProps = {
        formFields: formFields,
    };

    getFormFields = () => this.getFilteredForms(formFields);

    getInitialValues = () => {
        const { location } = this.props;

        const contacts = location.contacts ? [...location.contacts] : [];
        if (contacts.length === 0)
            contacts.push({
                contactId: undefined as unknown,
                position: '',
            } as ContactLinkObjRead);

        let initialValues: LocationFormData = {
            address: location.address,
            contacts,
            comment: location.comment,
            id: location.id,
            isEdit: true,
            coordinates: location.latitude && location.longitude ? [location.latitude, location.longitude] : undefined,
            latitude: location.latitude,
            longitude: location.longitude,
            shortName: location.shortName,
        };

        if (this.props.initialValues) {
            initialValues = {
                ...initialValues,
                ...this.props.initialValues,
            } as LocationFormData;
        }

        return initialValues;
    };

    onOk = (data: unknown) => {
        const formData = data as LocationFormData;

        let { contacts } = formData;
        if (contacts) contacts = contacts.filter((contact) => contact.contactId);
        this.props.updateLocation({
            businessAccountId: this.props.businessAccountId,
            locationId: this.props.location.id,
            locationInfoUpdate: {
                ...this.props.location,
                ...formData,
                latitude: formData.latitude,
                longitude: formData.longitude,
                contacts,
            } as LocationInfoUpdate,
        });
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            this.props.goBack();
            this.props.resetErrors();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.location.updating,
    updatingError: storeState.location.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateModalFilters,
    goBack,
    resetErrors,
    push,
    updateLocation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StoreProps = ConnectedProps<typeof connector>;

export const LocationEditModal = connector(injectIntl(_LocationEditModal));
