import { FC, memo, ReactNode, RefObject, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { Modal } from 'antd';

interface BookmarkProps {
    position: 'top' | 'bottom';
    formWidth: number;
    filterValue: string | undefined;
    modalRef: RefObject<Modal>;
    children: (visible: boolean) => ReactNode;
}

export const Bookmark: FC<BookmarkProps> = memo((props) => {
    const { position, children, filterValue, modalRef, formWidth } = props;
    const [offset, setOffset] = useState<number>(0);
    let [shouldRender, setShouldRender] = useState(false);

    const visible = offset < -100;

    useEffect(() => {
        if (offset < -100) setShouldRender(true);
    }, [offset]);

    const getModalNode = useCallback(() => {
        if (modalRef.current == null) return;
        const modalContainerNode = ReactDOM.findDOMNode(modalRef.current);
        if (modalContainerNode == null) return;
        return modalContainerNode.childNodes[1].childNodes[0] as Element;
    }, [modalRef]);

    const checkBookmarkVisible = useCallback(() => {
        const modalNode = getModalNode();
        if (modalNode == null) return;
        const nodeRect = modalNode.getBoundingClientRect();

        if (position === 'top') {
            const nodeTopOffset = nodeRect.top;
            setOffset(nodeTopOffset);
        }
        if (position === 'bottom') {
            const nodeBottomOffset = document.body.clientHeight - nodeRect.bottom + 24;
            setOffset(nodeBottomOffset);
        }
    }, [getModalNode, position]);

    const offsetBoundary = position === 'top' ? 76 : 79;
    const currentOffset = visible ? 20 : Math.abs(offset) < 56 ? offset + offsetBoundary : 20;
    const offsetStyles = position === 'top' ? { top: currentOffset } : position === 'bottom' ? { bottom: currentOffset } : {};

    (function () {
        if (position === 'bottom' && !visible) {
            const modalNode = getModalNode();
            if (modalNode == null) return;

            const nodeRect = modalNode.getBoundingClientRect();
            const nodeBottomOffset = document.body.clientHeight - nodeRect.bottom + 24;

            if (nodeBottomOffset > offsetBoundary) shouldRender = false;
        }
    })();

    useEffect(() => {
        if (visible) setShouldRender(true);
    }, [visible]);

    const onAnimationEnd = () => {
        if (!visible) setShouldRender(false);
    };

    useEffect(() => {
        checkBookmarkVisible();
    }, [checkBookmarkVisible, filterValue]);

    useEffect(() => {
        window.addEventListener('scroll', () => checkBookmarkVisible(), true);
        window.addEventListener('resize', () => checkBookmarkVisible(), true);

        setTimeout(() => checkBookmarkVisible(), 700);

        return () => {
            window.removeEventListener('scroll', checkBookmarkVisible, true);
            window.removeEventListener('resize', checkBookmarkVisible, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return shouldRender ? (
        <div
            style={{
                animation: `${visible ? 'slideIn' : 'slideOut'} 0.4s forwards`,
                marginLeft: formWidth - 25,
                ...offsetStyles,
            }}
            className={classNames('form-bookmark')}
            onAnimationEnd={onAnimationEnd}
        >
            {children(visible)}
        </div>
    ) : null;
});
