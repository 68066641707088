import { AutoComplete, Icon, Spin } from 'antd';
import { AutoCompleteProps, DataSourceItemObject } from 'antd/lib/auto-complete';
import { SelectValue } from 'antd/lib/select';
import { useEffect, useState } from 'react';
import { useSuggestAddressesQuery } from '../../modules/main/address/api/address.api';
import { useAppSelector } from '../../store/hooks';
import { businessAccountIdSelector } from '../../shared/reducers/system.reducer';
import { useDebounce } from '../../core/hooks/useDebounce';

export type Coords = [number | undefined, number | undefined];
interface Props extends AutoCompleteProps {
    onSuggestChoice?: (coords: Coords) => void;
}

const AddressInput = (props: Props) => {
    const { value, onChange, onSuggestChoice, ...restProps } = props;

    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const [dataSource, setDataSource] = useState<(DataSourceItemObject & { coords: [number | undefined, number | undefined] })[]>([]);
    const [inputValue, setInputValue] = useState<string>(value ? value.toString() : '');
    const debouncedSearchString = useDebounce<string | undefined>(inputValue, 300);

    const { data, isSuccess } = useSuggestAddressesQuery(
        { businessAccountId, addressQuery: debouncedSearchString! },
        { refetchOnMountOrArgChange: true, skip: !debouncedSearchString || debouncedSearchString.length < 3 || !businessAccountId }
    );

    const [searchFinished, setSearchFinished] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            setSearchFinished(true);
        }
    }, [isSuccess]);

    useEffect(() => {
        setInputValue(value?.toString() || '');
    }, [value]);

    useEffect(() => {
        setDataSource(data ? data.map((d) => ({ text: d.fullValue, value: d.fullValue, coords: [d.latitude, d.longitude] })) : []);
        setSearchFinished(true);
    }, [data]);

    const onInputChange = (newValue: SelectValue) => {
        setSearchFinished(false);
        setInputValue(newValue?.toString() || '');
        onChange?.(newValue?.toString() ?? '');
        if (!newValue || newValue?.toString().length < 3) {
            setDataSource([]);
            setSearchFinished(true);
        }
    };

    return (
        <AutoComplete
            {...restProps}
            allowClear={true}
            value={inputValue}
            onChange={onInputChange}
            notFoundContent={
                inputValue.length < 3 ? null : searchFinished ? (
                    <span>Ничего не найдено</span>
                ) : (
                    <Spin indicator={<Icon type="loading" spin />} style={{ width: '100%' }} spinning={true} />
                )
            }
            dataSource={dataSource}
            onSelect={(v) => {
                const choice = dataSource.find((d) => d.value === v);
                if (choice) onSuggestChoice?.(choice.coords);
                onChange?.(v);
            }}
        />
    );
};

export default AddressInput;
