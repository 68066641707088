import React, { CSSProperties } from 'react';
import { Col, Form, Icon, Row, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { ColProps } from 'antd/lib/grid';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { createFormItem } from './formUtils';
import './DynamicForm.less';
import { IntlShape } from 'react-intl';
import { LocalizationEnum } from '../../localization';
import { IconInfoSolid } from '../icons';
import { InfoPopover } from '../infoPopover/InfoPopover';
import classNames from 'classnames';

export interface IFormProps extends FormComponentProps {
    data: FormFieldsGroup[];
    initialValues?: { [k: string]: any };
    labelCol?: ColProps;
    wrapperCol?: ColProps;
    viewMode?: boolean;
    onChange?: (data: any) => void;
    highlightChangedFields?: boolean;
    excludeFields?: string[];
    intl?: IntlShape;
    defaultValues?: { [k: string]: any };
    currentValues?: { [k: string]: any };
    customParams?: { [k: string]: any };
    validateInitialValues?: boolean;
    addField?: (fieldName: string, index: number) => void;
    removeField?: (fieldName: string, index: number) => void;
    otherFiltersCollapsed1?: (otherFiltersCollapsed: boolean | undefined) => void;
    fetchEntityErrorCallback?: (entityId: number) => void;
    rowType?: 'flex';
}

export type FormFieldsGroup = {
    title?: string | React.ReactNode;
    header?: string | React.ReactNode;
    fields: (IFormField | IFormFieldImageUpload)[];
    visible?: (form: WrappedFormUtils) => boolean;
    tooltip?: React.ReactNode;
    helpMessage?: React.ReactNode;
    id?: string;
};

export type SelectItem = {
    name: string | React.ReactNode;
    value: any;
    disabled?: boolean;
};

export type TreeSelectItem = {
    title: string;
    value: any;
    key: string;
    children?: TreeSelectItem[];
    disabled?: boolean;
};

export interface IFormField extends IFormFieldBase {
    label?: string; // Лэйбл
    id: string; // id
    type: FormItemType;
    required?: boolean | ((getFieldValue: Function) => boolean);
    requiredMessage?: string | React.ReactNode;
    visible?: boolean | ((getFieldValue: Function, form?: WrappedFormUtils, currentValues?: Record<string, any>) => boolean);
    disabled?: boolean | ((getFieldValue: Function) => boolean);
    defaultValue?: any;
    values?: (SelectItem | TreeSelectItem | string)[] | Function;
    infoMessage?: string | React.ReactNode;

    maxLength?: number | ((form: WrappedFormUtils) => number);
    minLength?: number | ((form: WrappedFormUtils) => number);
    pattern?: string;

    component?: any;

    step?: number;

    len?: number;
    validationRules?: object[];

    componentProps?: { [k: string]: any };
    validationFunction?: (fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => void;
    validateInitialValue?: boolean | ((form: WrappedFormUtils) => boolean);

    tooltip?: React.ReactNode;
    helpTooltipLocalizationEnum?: LocalizationEnum;

    onChange?: (value: any, form: WrappedFormUtils) => void;

    labelCol?: ColProps;
    wrapperCol?: ColProps;

    customFormItemClassName?: string;
}

export interface IFormFieldBase {
    label?: string; // Лэйбл
    id: string; // id
    placeholder?: any;
    type: FormItemType;
    required?: boolean | ((getFieldValue: Function) => boolean);
    requiredMessage?: string | React.ReactNode;
    visible?: boolean | ((getFieldValue: Function, form?: WrappedFormUtils, currentValues?: Record<string, any>) => boolean);
    disabled?: boolean | ((getFieldValue: Function) => boolean);
    defaultValue?: any;
    values?: (SelectItem | TreeSelectItem | string)[] | Function;
    infoMessage?: string | React.ReactNode;

    colSpan?: number;

    className?: string;
    style?: CSSProperties;

    getInitialValue?: (value) => any;
    normalize?: (value) => Object;
    multipleValues?: boolean;
    defaultHidden?: boolean;
}

export interface IFormFieldImageUpload extends IFormFieldBase {
    type: FormItemType.UploadImage;
    imagesMaxCount: number;
    multiple?: boolean;
}

export enum FormItemType {
    Label = 'label',
    String = 'string',
    //Email = 'email',
    Password = 'password',
    Hidden = 'hidden',
    Text = 'text',
    Address = 'address',
    RichText = 'richText',
    Switch = 'switch',
    SwitchWithLabel = 'switchWithLabel',
    Checkbox = 'checkbox',
    Date = 'date',
    DateTime = 'dateTime',
    Number = 'number',
    Discount = 'discount',
    Tax = 'tax',
    Money = 'money',
    Integer = 'integer',
    UInt = 'uint',
    Float = 'float',
    Select = 'select',
    MultiSelect = 'multiSelect',
    MultiSelectStatus = 'multiSelectStatus',
    RadioGroup = 'radioGroup',
    CheckboxGroup = 'checkboxGroup',
    TreeSelect = 'treeSelect',
    TreeMultiSelect = 'treeMultiSelect',
    UploadImage = 'uploadImage',
    Component = 'component',
    Search = 'search',
    SliderRange = 'sliderRange',
    RangePicker = 'rangePicker',
    DateRangePicker = 'dateRangePicker',
}

class DynamicForm extends React.PureComponent<IFormProps> {
    public static defaultProps = {
        data: [],
        initialValues: {},
        viewMode: false,
        highlightChangedFields: false,
    };

    render() {
        //console.log('DynamicForm render()', this.props.data);

        return (
            <Form layout={'horizontal'} labelCol={this.props.labelCol || { span: 24 }} wrapperCol={this.props.wrapperCol || { span: 24 }}>
                <div>
                    {this.props.data.map((item, index, dataArray) => {
                        let visible = true;
                        if (item.visible && !item.visible(this.props.form)) visible = false;

                        let title;
                        let helpMessage;

                        if (item.helpMessage) {
                            helpMessage = (
                                <InfoPopover content={item.helpMessage}>
                                    <Icon component={IconInfoSolid} />
                                </InfoPopover>
                            );

                            if (item.title == null) {
                                title = (
                                    <div className={classNames('rr-form-group-title', 'rr-form-group-title-info')}>
                                        <div className={'rr-form-group-title-help'}>{helpMessage}</div>
                                    </div>
                                );
                            }
                        }

                        if (item.title && item.tooltip) {
                            title = (
                                <div className={'rr-form-group-title'}>
                                    <div>
                                        <Tooltip mouseEnterDelay={0.6} placement="right" title={item.tooltip}>
                                            <h3>{item.title}</h3>
                                        </Tooltip>
                                    </div>
                                    <div className={'rr-form-group-title-help'}>{helpMessage}</div>
                                </div>
                            );
                        } else if (item.title) {
                            title = (
                                <div className={'rr-form-group-title'}>
                                    <div>
                                        <h3>{item.title}</h3>
                                    </div>
                                    <div className={'rr-form-group-title-help'}>{helpMessage}</div>
                                </div>
                            );
                        }

                        if (visible && item.fields.every((item) => item.type === FormItemType.Hidden)) {
                            return (
                                <React.Fragment key={item.id}>
                                    {item.fields.map((item, index) => {
                                        if (item.type) {
                                            return (
                                                <Col key={item.id} span={item['colSpan']} className={'hidden-form'}>
                                                    {createFormItem(item as IFormField, index, this.props, this)}
                                                </Col>
                                            );
                                        } else return null;
                                    })}
                                </React.Fragment>
                            );
                        }

                        return visible ? (
                            <React.Fragment key={item.id}>
                                {item.header ? <div className={'rr-form-group-header'}>{item.header}</div> : null}
                                <div className={'rr-form-group'} id={item.id}>
                                    {title}
                                    <Row gutter={16} type={this.props.rowType}>
                                        {item.fields.map((item, index) => {
                                            if (item.type) {
                                                return (
                                                    <Col key={item.id} span={item['colSpan']} className={'hidden-form'}>
                                                        {createFormItem(item as IFormField, index, this.props, this)}
                                                    </Col>
                                                );
                                            } else return null;
                                        })}
                                    </Row>
                                </div>
                            </React.Fragment>
                        ) : null;
                    })}
                </div>
            </Form>
        );
    }
}

export default Form.create<IFormProps>()(DynamicForm);
