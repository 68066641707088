import { ColumnTypes, TableColumn } from '../../../../../components/grid/Table';
import React, { CSSProperties } from 'react';
import { InstanceRecord, ProjectRecord, ProjectSourceCodeEnum, ProjectStateCodeEnum } from '../../../../../server';
import { LocalizationEnum, localize } from '../../../../../localization';
import { GRID_ACTIONS_COLUMN_WIDTH } from '../../../../../config/constants';
import DashboardDate from '../../../../../components/dashboardDate/dashboardDate';
import { getProjectSourceByCode } from '../../../../../shared/util/utils4';
import { SystemIcon, SystemIconType } from '../../../../../components/v2/systemIcon/systemIcon';
import { tableCellPercentsRenderer } from '../../../../../components/grid/renderers/tableCellPercentsRenderer';
import { GridColumnCreator } from '../../../../../components/grid/gridColumnCreator';
import MoneyValue from '../../../../../components/moneyValue/MoneyValue';
import DateValue from '../../../../../components/dateValue/DateValue';
import { DateUtils } from '../../../../../core/utils/dateUtils';
import PledgeTableValue from '../../../../../components/pledgeTableValue/PledgeTableValue';

const tagsSizes = GridColumnCreator.getColumnSizes(50);

// GRID COLUMNS
const projectColumns: TableColumn<ProjectRecord>[] = [
    GridColumnCreator.createProblemSeverityColumn(),
    GridColumnCreator.createShortNameColumn<ProjectRecord>('simpleOrder', (row) => ({
        baId: row.businessAccountId,
        id: row.id,
        name: row.shortName,
    })),
    GridColumnCreator.createStatusColumn(),
    GridColumnCreator.createCounterpartyColumn<ProjectRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.renterId,
            name: row.renterShortName,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__RENTER),
            dataIndex: 'renterShortName',
        }
    ),
    {
        title: 'Источник',
        dataIndex: 'sourceCode',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        resizable: false,
        defaultHidden: true,
        render: (value: any, rowData: ProjectRecord) => {
            let style: CSSProperties | undefined;
            if (rowData.sourceCode === ProjectSourceCodeEnum.INTERNAL) style = { color: '#CCCCCC' };
            return <span style={style}>{getProjectSourceByCode(rowData.sourceCode)}</span>;
        },
    },
    GridColumnCreator.createLocationColumn<ProjectRecord>(
        {
            title: 'Локация',
            dataIndex: 'locationName',
            defaultHidden: true,
        },
        true
    ),
    {
        title: 'Теги',
        dataIndex: 'tagsString',
        type: ColumnTypes.CustomRender,
        minWidth: tagsSizes.min,
        width: tagsSizes.width,
        maxWidth: tagsSizes.max,
        resizable: true,
        defaultHidden: true,
        render: (value: string) => {
            return (
                <div className={'rr-grid-shortName-cell rr-grid-text-cell-left'} title={value}>
                    <span>{value}</span>
                </div>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__BEGIN),
        dataIndex: 'startDate',
        type: ColumnTypes.CustomRender,
        minWidth: 140,
        resizable: false,
        render: (value: any, rowData) => (
            <DateValue
                value={value}
                highlighted={
                    DateUtils.isPast(value) &&
                    (rowData.stateCode === ProjectStateCodeEnum.DRAFT ||
                        rowData.stateCode === ProjectStateCodeEnum.COORDINATION ||
                        rowData.stateCode === ProjectStateCodeEnum.BOOKED)
                }
            />
        ),
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__END_DATE),
        dataIndex: 'targetFinishDate', // TODO Тут видимо выводить или одну дату или другую
        type: ColumnTypes.CustomRender,
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
        render: (value: any, rowData) => (
            <DateValue
                value={value}
                highlighted={
                    DateUtils.isPast(value) &&
                    [
                        ProjectStateCodeEnum.DRAFT,
                        ProjectStateCodeEnum.COORDINATION,
                        ProjectStateCodeEnum.INPROGRESS,
                        ProjectStateCodeEnum.BOOKED,
                        ProjectStateCodeEnum.RENTED,
                    ].includes(rowData.stateCode)
                }
            />
        ),
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__DISCOUNT),
        dataIndex: 'defaultDiscount',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        resizable: false,
        render: (value: any, rowData: ProjectRecord) => (
            <span style={{ color: !rowData.hasOwnDefaultDiscount ? '#cccccc' : undefined }}>{tableCellPercentsRenderer(value)}</span>
        ),
    },
    {
        title: 'В заявках',
        dataIndex: 'currentOrderedSum',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
        render: (v, rowData: ProjectRecord) => (
            <MoneyValue
                checkIncludeBooked={rowData.stateCode === ProjectStateCodeEnum.BOOKED}
                value={v}
                muted={rowData.stateCode === ProjectStateCodeEnum.DRAFT || rowData.stateCode === ProjectStateCodeEnum.CANCELED}
            />
        ),
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__BOOKED),
        dataIndex: 'currentBookedSum',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
        render: (v, rowData: ProjectRecord) => (
            <MoneyValue
                checkIncludeBooked={rowData.stateCode === ProjectStateCodeEnum.BOOKED}
                value={v}
                muted={rowData.stateCode === ProjectStateCodeEnum.DRAFT || rowData.stateCode === ProjectStateCodeEnum.CANCELED}
            />
        ),
    },
    {
        title: 'В аренде',
        dataIndex: 'currentRentSum',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        resizable: false,
        render: (v, rowData: ProjectRecord) => (
            <MoneyValue
                checkIncludeBooked={rowData.stateCode === ProjectStateCodeEnum.BOOKED}
                value={v}
                muted={rowData.stateCode === ProjectStateCodeEnum.DRAFT || rowData.stateCode === ProjectStateCodeEnum.CANCELED}
            />
        ),
    },

    {
        title: 'Стоимость аренды',
        dataIndex: 'costOfRent',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
        render: (v, rowData: ProjectRecord) => (
            <MoneyValue
                checkIncludeBooked={rowData.stateCode === ProjectStateCodeEnum.BOOKED}
                value={v}
                muted={rowData.stateCode === ProjectStateCodeEnum.DRAFT || rowData.stateCode === ProjectStateCodeEnum.CANCELED}
            />
        ),
    },
    {
        title: 'Стоимость работ',
        dataIndex: 'costOfWork',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
        render: (v, rowData: ProjectRecord) => (
            <MoneyValue
                checkIncludeBooked={rowData.stateCode === ProjectStateCodeEnum.BOOKED}
                value={v}
                muted={rowData.stateCode === ProjectStateCodeEnum.DRAFT || rowData.stateCode === ProjectStateCodeEnum.CANCELED}
            />
        ),
    },
    {
        title: 'Стоимость затрат',
        dataIndex: 'costOfExpenses',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
        render: (v, rowData: ProjectRecord) => (
            <MoneyValue
                checkIncludeBooked={rowData.stateCode === ProjectStateCodeEnum.BOOKED}
                value={v}
                muted={rowData.stateCode === ProjectStateCodeEnum.DRAFT || rowData.stateCode === ProjectStateCodeEnum.CANCELED}
            />
        ),
    },

    {
        title: 'Итоговая стоимость',
        dataIndex: 'sumToBePaidAfterTaxes',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        resizable: false,
        render: (v, rowData: ProjectRecord) => (
            <MoneyValue
                checkIncludeBooked={rowData.stateCode === ProjectStateCodeEnum.BOOKED}
                value={v}
                muted={rowData.stateCode === ProjectStateCodeEnum.DRAFT || rowData.stateCode === ProjectStateCodeEnum.CANCELED}
            />
        ),
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__DEBT),
        dataIndex: 'debtSum',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        resizable: false,
        render: (v, rowData: ProjectRecord) => (
            <MoneyValue
                checkIncludeBooked={rowData.stateCode === ProjectStateCodeEnum.BOOKED}
                value={v}
                muted={rowData.stateCode === ProjectStateCodeEnum.DRAFT || rowData.stateCode === ProjectStateCodeEnum.CANCELED}
                highlighted={(rowData.stateCode === ProjectStateCodeEnum.FINISHED && v > 0) || v < 0}
            />
        ),
    },
    {
        title: 'Залог',
        dataIndex: 'pledgeSum',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        resizable: false,
        render: (v, rowData: ProjectRecord) => (
            <PledgeTableValue
                value={v}
                description={rowData.pledgeDescription}
                highlighted={rowData.stateCode === ProjectStateCodeEnum.FINISHED}
            />
        ),
    },
    {
        title: 'Вещевой залог',
        dataIndex: 'pledgeDescription',
        type: ColumnTypes.CustomRender,
        minWidth: 140,
        resizable: false,
        render: (v, rowData: ProjectRecord) => (
            <PledgeTableValue value={rowData.pledgeSum} description={v} highlighted={rowData.stateCode === ProjectStateCodeEnum.FINISHED} />
        ),
    },
    GridColumnCreator.createUserColumn<ProjectRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.assigneeId,
            name: row.assigneeShortName,
            hasOwn: row.hasOwnAssignee,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__RESPONSIBLE),
            dataIndex: 'assigneeShortName',
        }
    ),
    GridColumnCreator.createUserColumn<ProjectRecord>(
        (row) => ({
            baId: row.businessAccountId,
            name: row.contactPersonShortName,
            hasOwn: row.hasOwnContactPerson,
        }),
        {
            title: 'Контактное лицо',
            dataIndex: 'contactPersonShortName',
            defaultHidden: true,
        }
    ),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIVITY),
        dataIndex: 'lastActivityDate',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        resizable: false,
        render: (value: Date, rowData: InstanceRecord) => {
            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    {
        title: <SystemIcon type={SystemIconType.actions} style={{ display: 'block' }} />,
        type: ColumnTypes.Actions,
        width: GRID_ACTIONS_COLUMN_WIDTH,
        maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
        minWidth: GRID_ACTIONS_COLUMN_WIDTH,
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
        id: 'actions',
    },
];

export default projectColumns;
