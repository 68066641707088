import React, { useState } from 'react';
import { DocumentTypeCodeEnum, FileTypeCodeEnum, ProblemRentElementField } from '../../../../../server';
import { LinkButton } from '../../../../buttons/linkButton/linkButton';
import { AttachmentIcon } from '../../../../../modules/main/attachments/attachmentIcon';
import { DownloadDocumentPopoverProps, DownloadDocumentTemplateSelectedValue } from '../../DownloadDocumentPopover';
import { DownloadPeriodValues } from '../Period/hooks/useSelectOptions';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { getPeriodValuesByOptionValue } from '../Period/utils/getPeriodValuesByOptionValue';
import moment from 'moment';
import classNames from 'classnames';
import { useLazyCheckDocumentQuery } from '../../../../../modules/main/elements/api/elements.api';
import { getBusinessAccountId } from '../../../../../..';
import { getCurrentTimezoneUTCOffset } from '../../../../../utils/timeUtils/getCurrentTimezoneUTCOffset';
import { useLocation, useParams } from 'react-router-dom';
import ProblemRentElementModal from './ProblemRentElementModal';
import { useAppSelector } from '../../../../../store/hooks';
import {
    elementsCalendarScreenLeft,
    elementsCalendarScreenRight,
} from '../../../../../modules/main/calendar/tabs/elements/reducers/elementsCalendar.reducer.selectors';

interface DownloadDocumentPopoverExportsProps<FileType extends FileTypeCodeEnum, Options extends object> {
    availableFileTypes: FileTypeCodeEnum[];
    withTemplate: boolean | undefined;
    selectedTemplateValue: DownloadDocumentTemplateSelectedValue | undefined;
    selectedLegalDetailValue: number | undefined;
    onDownload: DownloadDocumentPopoverProps<FileType, Options>['onDownload'];
    selectedValues: Options[];
    discount: number | undefined;
    setVisible: (value: boolean) => void;
    periodValue: DownloadPeriodValues | RangePickerValue | undefined;
    setLoading: (value: boolean) => void;
    getFilters: () => string[];
    documentType?: DocumentTypeCodeEnum;
    checkBeforeDownload?: boolean;
}

export const DownloadDocumentPopoverExports = <FileType extends FileTypeCodeEnum, Options extends object>(
    props: DownloadDocumentPopoverExportsProps<FileType, Options>
) => {
    const {
        availableFileTypes,
        discount,
        onDownload,
        selectedValues,
        periodValue,
        selectedTemplateValue,
        setVisible,
        withTemplate,
        selectedLegalDetailValue,
        setLoading,
        getFilters,
        documentType,
        checkBeforeDownload,
    } = props;

    const [fields, setFields] = useState<ProblemRentElementField[]>([]);

    const [checkDocument] = useLazyCheckDocumentQuery();
    const screenLeft = useAppSelector(elementsCalendarScreenLeft);
    const screenRight = useAppSelector(elementsCalendarScreenRight);

    const params = useParams<{ businessAccountId: string; id: string }>();
    const location = useLocation();
    const isOperation = location.pathname.includes('operations');
    const isProject = location.pathname.includes('projects');
    const isCalendar = location.pathname.includes('calendar');

    const getAdditionalFiltersByPeriodValue = (): [string, string] | undefined => {
        if (periodValue == null) return undefined;

        if (Array.isArray(periodValue)) {
            if (periodValue[0] == null || periodValue[1] == null) return undefined;

            return [moment(periodValue[0]).toISOString(), moment(periodValue[1]).toISOString()];
        }

        const dates = getPeriodValuesByOptionValue(periodValue);

        return [moment(dates[0]).toISOString(), moment(dates[1]).toISOString()];
    };

    const downloadDocument = async (fileType: FileType) => {
        const period = getAdditionalFiltersByPeriodValue();

        await onDownload({
            fileType,
            options: selectedValues,
            templateId: selectedTemplateValue?.key,
            isCommonTemplate: selectedTemplateValue?.common,
            discount,
            period,
            legalDetailId: selectedLegalDetailValue,
        });
    };

    const exportDocument = async (fileType: FileType) => {
        const period = getAdditionalFiltersByPeriodValue();
        const filters = getFilters();
        const searchParams = new URLSearchParams(location.search);

        const search = searchParams.get('search');

        setLoading(true);
        if (
            // @ts-ignore
            !selectedValues.includes('expandKits') &&
            (documentType === DocumentTypeCodeEnum.ESTIMATE || documentType === DocumentTypeCodeEnum.ACT || checkBeforeDownload)
        ) {
            const checkResult = await checkDocument({
                businessAccountId: getBusinessAccountId(),
                timezoneOffsetUTC: getCurrentTimezoneUTCOffset(),
                projectId: isProject && params.id ? +params.id : undefined,
                operationId: isOperation && params.id ? +params.id : undefined,
                t0: isCalendar && screenLeft ? new Date(screenLeft) : period ? new Date(period[0]) : undefined,
                tN: isCalendar && screenRight ? new Date(screenRight) : period ? new Date(period[1]) : undefined,
                filters,
                search: search ?? undefined,
            });
            if (checkResult.isSuccess) {
                await downloadDocument(fileType);
            } else if (checkResult.error && 'response' in checkResult.error && checkResult.error.response?.status === 422) {
                setFields(checkResult.error.response.data);
            }
        } else {
            await downloadDocument(fileType);
        }

        setLoading(false);
        setVisible(false);
    };

    const downloadButtonDisabled = withTemplate && !selectedTemplateValue;

    return (
        <>
            <div className={'content'}>
                {availableFileTypes.length > 1 && <span className={'title'}>Выберите формат:</span>}
                <div
                    className={classNames('exports', {
                        compact: true,
                    })}
                >
                    {availableFileTypes.includes(FileTypeCodeEnum.EXCEL) && (
                        <LinkButton
                            block
                            disabled={downloadButtonDisabled}
                            onClick={() => {
                                exportDocument(FileTypeCodeEnum.EXCEL as FileType);
                            }}
                        >
                            <AttachmentIcon type={FileTypeCodeEnum.EXCEL} />
                            Скачать в Excel
                        </LinkButton>
                    )}
                    {availableFileTypes.includes(FileTypeCodeEnum.WORD) && (
                        <LinkButton
                            block
                            disabled={downloadButtonDisabled}
                            onClick={() => {
                                exportDocument(FileTypeCodeEnum.WORD as FileType);
                            }}
                        >
                            <AttachmentIcon type={FileTypeCodeEnum.WORD} />
                            Скачать в Word
                        </LinkButton>
                    )}
                    {availableFileTypes.includes(FileTypeCodeEnum.PDFTYPE) && (
                        <LinkButton
                            block
                            disabled={downloadButtonDisabled}
                            onClick={() => exportDocument(FileTypeCodeEnum.PDFTYPE as FileType)}
                        >
                            <AttachmentIcon type={FileTypeCodeEnum.PDFTYPE} />
                            {documentType === DocumentTypeCodeEnum.BADGE ? 'Печать через PDF' : 'Скачать в PDF'}
                        </LinkButton>
                    )}
                </div>
            </div>
            <ProblemRentElementModal
                fields={fields}
                title="Основной компонент с нулем смен"
                description="Невозможно сохранить стоимость набора, установив смены по основному компоненту, когда их число равно нулю"
                onClose={() => setFields([])}
            />
        </>
    );
};
