import { Icon, Popover, Spin } from 'antd';
import './ContactReferences.less';
import { IconLinkSolid } from '../../../../../../../../components/icons';
import { useState } from 'react';
import { useDecodeLinksQuery } from '../../../api/contactsApi';
import { entitySubtypeCodeMap, entityTypeCodeMap } from '../../../../../../../../localization/utils/entityTypeCodeMap';
import { localize } from '../../../../../../../../localization';
import { EntitySubtypeCodeEnum, EntityTypeCodeEnum } from '../../../../../../../../server';
import { getBusinessAccountId } from '../../../../../../../../..';
import { Link } from 'react-router-dom';

interface Props {
    contactId: number;
    count: number;
}

const getLink = (type: EntityTypeCodeEnum | EntitySubtypeCodeEnum, contactId: number, count: number) => {
    const baId = getBusinessAccountId();
    let to = `/${baId}/`;
    let isLink = false;

    if (type === EntityTypeCodeEnum.RENTER) {
        to += 'crm/counterparties';
        isLink = true;
    }

    if (type === EntitySubtypeCodeEnum.BASEPROJECT) {
        to += 'projects/production';
        isLink = true;
    }

    if (type === EntitySubtypeCodeEnum.SIMPLEORDER) {
        to += 'projects/simpleOrders';
        isLink = true;
    }

    if (type === EntityTypeCodeEnum.SUBRENT) {
        to += 'subrent/shippings';
        isLink = true;
    }

    if (type === EntityTypeCodeEnum.VEHICLE) {
        to += 'logistics/vehicles';
        isLink = true;
    }

    if (type === EntityTypeCodeEnum.LOCATION) {
        to += 'logistics/locations';
        isLink = true;
    }

    if (type === EntityTypeCodeEnum.TRANSPORTATION) {
        to += 'logistics/transportations';
        isLink = true;
    }

    if (type === EntityTypeCodeEnum.PRODUCT || type === EntityTypeCodeEnum.VARIANT) {
        to += 'inventory/products';
        isLink = true;
    }

    if (type === EntityTypeCodeEnum.KIT) {
        to += 'inventory/kits';
        isLink = true;
    }

    return isLink ? (
        <Link to={to + `?contactId=${contactId}`} className="contact-references-value rr-link">
            {count}
        </Link>
    ) : (
        <span className="contact-references-value">{count}</span>
    );
};

const ContactReferences = ({ contactId, count }: Props) => {
    const [open, setOpen] = useState(false);

    const { data, isLoading } = useDecodeLinksQuery(contactId, { skip: !open, refetchOnMountOrArgChange: true });

    return count ? (
        <Popover
            overlayClassName={'rr-grid-actions-popover'}
            autoAdjustOverflow
            arrowPointAtCenter
            placement="right"
            trigger="click"
            getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
            onVisibleChange={(visible) => setOpen(visible)}
            content={
                <Spin indicator={<Icon type="loading" spin />} style={{ width: '100%' }} spinning={isLoading}>
                    <div className={'rr-grid-actions-popover-header'}>
                        <Icon style={{ fontSize: 20, marginRight: 10 }} component={IconLinkSolid} />
                        <div>Ссылки на контакт</div>
                    </div>
                    <ul className="contact-references-content">
                        {data
                            ? data.map((link) => (
                                  <li
                                      key={
                                          (link.entityType ?? '') +
                                          (link.entityType && link.entitySubtype ? '-' : '') +
                                          (link.entitySubtype ?? '')
                                      }
                                      className="contact-references-item"
                                  >
                                      {link.entitySubtype ? (
                                          <span>{entitySubtypeCodeMap[link.entitySubtype]}</span>
                                      ) : (
                                          <span>{localize(entityTypeCodeMap[link.entityType])}</span>
                                      )}
                                      {getLink(link.entityType ?? link.entitySubtype, contactId, link.refCount)}
                                      {/* <span className="contact-references-value">{link.refCount}</span> */}
                                  </li>
                              ))
                            : null}
                    </ul>
                </Spin>
            }
        >
            <div className="contact-references">
                {count} <span className="contact-references-link">Смотреть</span>
            </div>
        </Popover>
    ) : (
        <span className="contact-references rr-0-value">Нет</span>
    );
};
export default ContactReferences;
