import { FormFieldsGroup, FormItemType } from '../../../../../../../components/dynamicForm/DynamicForm';
import { FormUtils } from '../../../../../../../core/utils/formUtils';
import { AccessibilityMapFilters } from '../accessibility/accessibilityMapCalendarFilters.types';
import { LocalizationEnum } from '../../../../../../../localization';
import {EntityRemoteSelect, EntityRemoteSelectProps} from '../../../../../../../components/select/EntityRemoteSelect';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import {
    ProductRecord,
    ProjectRecord,
    ProjectTypeCodeEnum,
    RenterRecord,
    SubrentRecord
} from '../../../../../../../server';
import { subrentModuleEnabledSelector } from '../../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { getStoreState } from '../../../../../../../../index';
import { HideValue } from '../../../elements/filters/elementsCalendarFilters.data';
import {ServerUtils} from "../../../../../../../core/utils/serverUtils";

export const accessibilityElementsCalendarFilters: FormFieldsGroup[] = FormUtils.typedFormFields<AccessibilityMapFilters>([
    {
        fields: [
            {
                label: 'Контрагент',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A8,
                id: 'renterId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils):EntityRemoteSelectProps => ({
                    operationName: 'listRenters',
                    nameField: 'shortName',
                    getEntityOperationName: 'getRenterById',
                    sortBy: 'lastActivityDate',
                    sortOrder: 'DESC',
                    showSearch: true,
                    filters: ServerUtils.createRequestFilters<RenterRecord>([
                        ['archive', 'EQ', false]
                    ])
                }),
                getInitialValue: (value) => {
                    return value;
                },
                onChange: (value: any, form) => {
                    form.setFieldsValue({ projectId: undefined });
                    form.setFieldsValue({ subrentId: undefined });
                },
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__PROJECT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A9,
                id: 'projectId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils):EntityRemoteSelectProps => {
                    let renterId = form.getFieldValue('renterId');
                    if (renterId?.key) renterId = renterId.key;
                    const filters = ServerUtils.createRequestFilters<ProjectRecord>([
                        ['projectType', 'IN', [ProjectTypeCodeEnum.BASE]],
                        renterId ? ['renterId', 'EQ', renterId] : undefined,
                        ['archive', 'EQ', false]
                    ]);

                    return {
                        operationName: 'listProjects',
                        nameField: 'shortName',
                        getEntityOperationName: 'getProjectById',
                        sortBy: 'lastActivityDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters,
                    };
                },
                getInitialValue: (value) => {
                    return value;
                },
                onChange: (value: any, form) => {
                    form.setFieldsValue({
                        subrentId: undefined,
                    });
                },
            },
            {
                label: 'Поставка',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__FAMALE_GENDER,
                id: 'subrentId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils):EntityRemoteSelectProps => {
                    let renterId = form.getFieldValue('renterId');
                    if (renterId?.key) renterId = renterId.key;
                    const filters = ServerUtils.createRequestFilters<SubrentRecord>([
                        renterId ? ['supplierId', 'EQ', renterId]: undefined,
                        ['archive', 'EQ', false]
                    ]);
                    return {
                        operationName: 'listSubrents',
                        nameField: 'shortName',
                        getEntityOperationName: 'getSubrentById',
                        sortBy: 'lastActivityDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters
                    };
                },
                getInitialValue: (value) => {
                    return value;
                },
                onChange: (value: any, form) => {
                    form.setFieldsValue({
                        projectId: undefined,
                    });
                },
                visible: () => {
                    const subrentModuleEnabled = subrentModuleEnabledSelector(getStoreState());
                    return Boolean(subrentModuleEnabled);
                },
            },
        ],
    },
]);

export const accessibilityElementsCalendarHideFilters = FormUtils.typedFormFields<AccessibilityMapFilters>([
    {
        fields: [
            {
                label: 'Скрыть',
                id: 'hide',
                placeholder: 'Не выбрано',
                type: FormItemType.Select,
                style: { minWidth: 160, maxWidth: 260 },
                className: 'rr-select-gray calendar-filters',
                values: () => {
                    const subrentModuleEnabled = subrentModuleEnabledSelector(getStoreState());

                    const values: {
                        name: string | undefined;
                        value: HideValue | undefined;
                    }[] = [
                        { name: 'Неактивные', value: HideValue.INACTIVE },
                        { name: 'Отмененные', value: HideValue.CANCELLED },
                        { name: 'Отмененные и черновики', value: HideValue.CANCELED_DRAFT },
                    ];

                    if (subrentModuleEnabled) {
                        values.push(
                            { name: undefined, value: undefined },
                            { name: 'Все по аренде', value: HideValue.RENT },
                            { name: 'Все по субаренде', value: HideValue.SUBRENT }
                        );
                    }

                    return values;
                },
            },
        ],
    },
]);
