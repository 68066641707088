import {Checkbox} from "antd";
import CheckboxGroup from "antd/lib/checkbox/Group";
import {PaymentMethodRequirementTypeCodeEnum, TypeOfPricingCodeEnum} from "../../../../../server";
import React from "react";

export interface PricingTypeSelectProps {
    value?: TypeOfPricingCodeEnum;
    onChange?: (value?:TypeOfPricingCodeEnum)=>void;
    forSettings?: boolean;
}

export const pricingTypeNamesMap: Record<TypeOfPricingCodeEnum, string> = {
    [TypeOfPricingCodeEnum.STRAIGHT]: 'Основной проект',
    [TypeOfPricingCodeEnum.FROMOFFERS]: 'Сметы проекта',
    [TypeOfPricingCodeEnum.STRAIGHTANDOFFERS]: 'Основной проект и сметы проекта',
};

export const PricingTypeSelectFC = (props: PricingTypeSelectProps) => {
    let v:TypeOfPricingCodeEnum[] = [];
    if(props.value === TypeOfPricingCodeEnum.STRAIGHT) v = [TypeOfPricingCodeEnum.STRAIGHT];
    else if(props.value === TypeOfPricingCodeEnum.FROMOFFERS) v = [TypeOfPricingCodeEnum.FROMOFFERS];
    else if(props.value === TypeOfPricingCodeEnum.STRAIGHTANDOFFERS) v = [TypeOfPricingCodeEnum.STRAIGHT, TypeOfPricingCodeEnum.FROMOFFERS];

    return (
        <div style={{lineHeight: 1, marginTop: props.forSettings ? 9 : undefined}}>
            {!props.forSettings && <div style={{fontSize: 14, fontWeight: 600, marginTop: 10}}>Тип ценообразования</div>}
            {!props.forSettings && <div style={{color: '#AAAAAA', marginTop: 8, marginBottom: 12, fontSize: 11}}>Что входит в стоимость к оплате</div>}
            <CheckboxGroup value={v} onChange={(value)=>{
                let v:TypeOfPricingCodeEnum|undefined;
                if(value.length === 1) v = value[0] as TypeOfPricingCodeEnum;
                else if(value.length == 2) v = TypeOfPricingCodeEnum.STRAIGHTANDOFFERS;
                props.onChange?.(v);
            }}>
                <Checkbox style={{display: 'inline-block', marginRight: 22}} value={TypeOfPricingCodeEnum.STRAIGHT}>{pricingTypeNamesMap[TypeOfPricingCodeEnum.STRAIGHT]}</Checkbox>
                <Checkbox style={{display: 'inline-block'}} value={TypeOfPricingCodeEnum.FROMOFFERS}>{pricingTypeNamesMap[TypeOfPricingCodeEnum.FROMOFFERS]} {!props.forSettings && <span style={{color: '#AAAAAA'}}>(включенные в стоимость)</span>}</Checkbox>
            </CheckboxGroup>
        </div>
    );
}

// Без этого не работает прокрутка при валидации в форме
export class PricingTypeSelect extends React.Component<PricingTypeSelectProps> {
    render() {
        return <PricingTypeSelectFC {...this.props} />;
    }
}
