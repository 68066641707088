import React, { FC } from 'react';
import { EntityRemoteSelect } from '../../../../select/EntityRemoteSelect';
import { DownloadDocumentTemplateSelectedValue, SUPPORTED_SERVER_METHOD } from '../../DownloadDocumentPopover';
import { DocumentTypeCodeEnum } from '../../../../../server';
import { ExportSettings } from '../../../../../shared/reducers/userSettings/userSettings.reducer';

interface DownloadDocumentPopoverTemplateSelectProps {
    documentType?: DocumentTypeCodeEnum;
    method?: SUPPORTED_SERVER_METHOD;
    documentsLocalStorage: ExportSettings;
    setDocumentsLocalStorage: (value: ExportSettings) => void;
    entityRemoteSelectCustomParams?: any;
    templateSelectedValue: DownloadDocumentTemplateSelectedValue | undefined;
    setTemplateSelectedValue: (value: DownloadDocumentTemplateSelectedValue | undefined) => void;
    loadDataOnMount?: boolean;
    onEntityDataLoad?: (data: any) => void;
}

export const DownloadDocumentPopoverTemplateSelect: FC<DownloadDocumentPopoverTemplateSelectProps> = (props) => {
    const {
        documentType,
        method,
        entityRemoteSelectCustomParams,
        documentsLocalStorage,
        setDocumentsLocalStorage,
        templateSelectedValue,
        setTemplateSelectedValue,
        loadDataOnMount,
        onEntityDataLoad,
    } = props;

    const customParams =
        method === SUPPORTED_SERVER_METHOD.LIST_DOCUMENT_TEMPLATES_FOR_REPORT_INSTANCE
            ? { ...entityRemoteSelectCustomParams }
            : {
                  documentType: documentType,
              };

    const onSelectChange = (value) => {
        let newValue = {
            key: value.key,
            label: value.label,
            common: value.data.common,
        };
        setTemplateSelectedValue(value ? newValue : undefined);
        setDocumentsLocalStorage({
            ...documentsLocalStorage,
            template: value ? newValue : undefined,
        });
    };

    const templateSelectFilters: string[] = documentType ? [`documentType;EQ;${documentType}`] : [];

    return (
        <div className={'content'}>
            <div className={'title'} style={{ fontWeight: 400 }}>
                Шаблон
            </div>
            <div>
                <EntityRemoteSelect
                    allowClear={false}
                    style={{ width: '100%' }}
                    placeholder={'Выберите шаблон'}
                    nameField={'shortName'}
                    onChange={onSelectChange}
                    onEntityDataLoad={onEntityDataLoad}
                    loadDataOnMount={loadDataOnMount}
                    className={'rr-select-gray'}
                    showSearch={true}
                    operationName={method ?? SUPPORTED_SERVER_METHOD.LIST_DOCUMENT_TEMPLATES}
                    value={templateSelectedValue}
                    filters={templateSelectFilters}
                    sortOrder={'DESC'}
                    sortBy={'lastUpdateDate'}
                    customParams={customParams}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                />
            </div>
        </div>
    );
};
