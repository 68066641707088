import { FormFieldsGroup, FormItemType, SelectItem } from '../../../../components/dynamicForm/DynamicForm';
import { ProductCategoriesSelect } from '../components/ProductCategoriesSelect/ProductCategoriesSelect';
import { LocalizationEnum, localize } from '../../../../localization';
import debounce from 'lodash/debounce';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { validateField } from '../../../../shared/util/validateField';
import React from 'react';
import { NomenclatureStateCodeEnum } from '../../../../server/api';
import { createHiddenField } from '../../../../components/modalForm/utils';
import { getStoreState } from '../../../../../index';
import { canUpdateFinancialData, canViewFinancialData } from '../../../../shared/util/permissionUtils';
import { PricingSchemeSelect, PricingSchemeSelectProps } from '../../../../components/v2/select/pricingSchemeSelect/pricingSchemeSelect';

// dynamic form fields
export const formFields: FormFieldsGroup[] = [
    {
        fields: [
            createHiddenField('id'),
            createHiddenField('kitId'),
            createHiddenField('kitEdit'),
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE,
                id: 'shortName',
                type: FormItemType.String,
                required: true,
                maxLength: 75,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('kitEdit') ? form.getFieldValue('kitId') : undefined;
                    validateField('kit', fieldName, value, undefined, ignoreCurrentId, 'Краткое наименование набора уже использовано', cb);
                }, 500),
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__FULL_NAME,
                id: 'fullName',
                type: FormItemType.String,
                required: false,
                maxLength: 255,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('kitEdit') ? form.getFieldValue('kitId') : undefined;
                    validateField('kit', fieldName, value, undefined, ignoreCurrentId, 'Полное наименование набора уже использовано', cb);
                }, 500),
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: localize(LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER),
                id: 'stateCode',
                type: FormItemType.Select,
                defaultValue: NomenclatureStateCodeEnum.ACTIVE,
                required: true,
                values: (form: WrappedFormUtils) => {
                    let values: SelectItem[] = [];
                    if (!!form.getFieldValue('kitEdit')) {
                        values = [
                            { name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__NEW), value: NomenclatureStateCodeEnum.NEW },
                            {
                                name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__ACTIVE),
                                value: NomenclatureStateCodeEnum.ACTIVE,
                            },
                            {
                                name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__NOTAVAILABLE),
                                value: NomenclatureStateCodeEnum.NOTAVAILABLE,
                            },
                        ];
                    } else {
                        values = [
                            { name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__NEW), value: NomenclatureStateCodeEnum.NEW },
                            {
                                name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__ACTIVE),
                                value: NomenclatureStateCodeEnum.ACTIVE,
                            },
                        ];
                    }
                    return values.map((item) => {
                        return {
                            name: (
                                <>
                                    <div className={`rr-dot rr-status-bg-` + item.value} style={{ marginRight: '10px' }}></div>{' '}
                                    <span className="calendar-multi-select">{item.name}</span>{' '}
                                </>
                            ),
                            value: item.value,
                        };
                    });
                },
                disabled: (getFieldValue) => !!getFieldValue('kitEdit'),
            },
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__EXTERNAL_CODE,
                id: 'externalCode',
                type: FormItemType.String,
                required: false,
                maxLength: 30,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('kitEdit') ? form.getFieldValue('kitId') : undefined;
                    validateField('kit', fieldName, value, undefined, ignoreCurrentId, 'Артикул набора уже использован', cb);
                }, 500),
            },
            {
                label: 'Штриховой код',
                id: 'barcode',
                type: FormItemType.String,
                maxLength: 30,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('kitEdit') ? form.getFieldValue('kitId') : undefined;
                    validateField('kit', fieldName, value, undefined, ignoreCurrentId, 'Штриховой код уже использован', cb);
                }, 500),
                validationRules: [
                    {
                        // TODO Нормальное сообщение об ошибке
                        pattern: '^[0-9a-zA-Z-_]+$',
                        message: 'Поле должно содержать только символы: A-Z, a-z, 0-9, - и _',
                    },
                ],
                infoMessage: 'Допустимы символы: A-Z, a-z, 0-9, - и _',
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__CATEGORIES,
                id: 'categoryIds',
                type: FormItemType.Component,
                component: ProductCategoriesSelect,
                placeholder: localize(LocalizationEnum.ASPECT__FORMS__PLACEHOLDER__SELECT_CATEGORIES),
            },
            {
                label: 'Ценовая схема',
                placeholder: 'Выберите ценовую схему',
                id: 'pricingScheme',
                type: FormItemType.Component,
                component: PricingSchemeSelect,
                componentProps: (form: WrappedFormUtils): PricingSchemeSelectProps => ({
                    forKit: true,
                    allowClear: false,
                }),
                disabled: () => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
                visible: () => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p);
                },
            },
            {
                label: LocalizationEnum.PAGE__KITS__FORM__HAS_MAIN_MEMBER,
                id: 'mainMemberOptionEnabled',
                type: FormItemType.Switch,
                defaultValue: false,
            },
            createHiddenField('members'),
            {
                label: LocalizationEnum.ASPECT__FORMS__MAIN_IMAGE,
                id: 'mainImage',
                type: FormItemType.UploadImage,
                infoMessage: localize(LocalizationEnum.ASPECT__FORMS__IMAGE_FORMAT_RESCTRICTIONS_STRING),
                imagesMaxCount: 1,
            },
            {
                label: LocalizationEnum.ASPECT__FORMS__EXTRA_IMAGES,
                id: 'otherImages',
                type: FormItemType.UploadImage,
                infoMessage: localize(LocalizationEnum.ASPECT__FORMS__IMAGES_FORMAT_RESCTRICTIONS_STRING),
                imagesMaxCount: 15,
                multiple: true,
                validationRules: [{ min: 1, max: 15, message: 'Число дополнительных изображений не должно превышать 15', type: 'array' }],
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION,
                id: 'description',
                type: FormItemType.RichText,
                validationRules: [
                    {
                        max: 5000,
                        message: (
                            <>
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} 5000{' '}
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__INCLUDING_FORMATTING_TAGS)}
                            </>
                        ),
                    },
                ],
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
];
