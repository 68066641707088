import React, { useCallback, useEffect, useState } from 'react';
import { PricingLadderStepObj } from '../../../../server';
import { Button, Col, Icon, Row } from 'antd';
import sortBy from 'lodash/sortBy';
import './pricingSchemeEditor.less';
import { IconLongArrowAltRightSolid, IconSortAmountDownAltSolid } from '../../../../components/icons';
import RoundButton from '../../../../components/button/roundButton';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { useIntl } from 'react-intl';
import { joinClasses } from '../../../../shared/util/utils';
import { SystemIcon, SystemIconType } from '../../../../components/v2/systemIcon/systemIcon';
import { NumberInputDiscount, NumberInputShiftCount } from '../../../../components/numberInputs';

interface PricingSchemeEditorProps {
    value?: PricingLadderStepObj[];
    onChange: (value: PricingLadderStepObj[] | undefined) => void;
    editing: boolean;
}

export const PricingSchemeEditor = ({ value, onChange, editing }: PricingSchemeEditorProps) => {
    const [validationTimer, setValidationTimer] = useState<NodeJS.Timeout>();
    const [dublicateError, setDublicateError] = useState(false);
    const [dublicateErrorVisible, setDublicateErrorVisible] = useState(false);
    let [steps, setSteps] = useState<PricingLadderStepObj[] | undefined>(
        editing
            ? value
            : [
                  {
                      effectsAfterShiftCount: 0,
                      extraDiscount: 0,
                  },
              ]
    );
    let [shiftCount, setShiftCount] = useState(0 as number | undefined);
    let [discount, setDiscount] = useState(0 as number | undefined);

    useEffect(() => {
        if (editing) {
            setSteps(value);
        }
    }, [value, editing]);

    useEffect(() => {
        setTimeout(() => {
            setDublicateErrorVisible(dublicateError);
        });
    }, [dublicateError]);

    let intl = useIntl();

    let onStartButtonClick = useCallback(() => {
        const newSteps = [
            {
                effectsAfterShiftCount: shiftCount,
                extraDiscount: discount,
            } as PricingLadderStepObj,
        ];
        setSteps(newSteps);
        onChange(newSteps);
    }, [shiftCount, discount, onChange]);

    let onAddButtonClick = useCallback(() => {
        if (!steps?.length) return;
        setDublicateError(false);

        if (validationTimer) {
            clearInterval(validationTimer);
        }

        const sortedSteps: PricingLadderStepObj[] = sortBy([...steps], 'effectsAfterShiftCount');

        const newSteps = [...sortedSteps, { ...sortedSteps.at(-1) } as PricingLadderStepObj];
        setSteps(newSteps);
        onChange(newSteps);
        setValidationTimer(
            setTimeout(() => {
                setDublicateError(true);
            }, 2000)
        );
    }, [steps, validationTimer, onChange]);

    let maxStepCountError = false;
    let duplicateShiftCountError = false;

    let duplicateShiftCountError2 = false;

    let numbers = (steps || []).map((step) => step.effectsAfterShiftCount);
    const duplicates = numbers.filter((number, index, numbers) => {
        return numbers.indexOf(number) !== index;
    });
    if (duplicates.length > 0) {
        duplicateShiftCountError2 = true;
    }

    let canAddStep = true;
    if (shiftCount === undefined) canAddStep = false;
    else {
        if (steps?.find((step) => step.effectsAfterShiftCount === shiftCount)) {
            canAddStep = false;
            duplicateShiftCountError = true;
        }
    }
    if (discount === undefined || discount > 100) canAddStep = false;

    if (steps && steps.length >= 10) {
        canAddStep = false;
        maxStepCountError = true;
    }

    const canSort =
        steps &&
        steps.length > 1 &&
        !steps.every(
            (step, index) =>
                steps &&
                (!steps[index - 1] ||
                    (steps[index - 1] !== undefined &&
                        step.effectsAfterShiftCount &&
                        step.effectsAfterShiftCount >=
                            (steps[index - 1] && steps[index - 1].effectsAfterShiftCount ? steps[index - 1].effectsAfterShiftCount : 0)))
        );

    return (
        <div className={'rr-pricingScheme-editor'}>
            <div className={'rr-pricingScheme-editor-title'}>
                <div className={'rr-pricingScheme-editor-title-title'}>Уровни скидок</div>
                <div className={'rr-pricingScheme-editor-title-sort'}>
                    <Button
                        disabled={!canSort}
                        onClick={() => {
                            let newSteps = sortBy(steps, 'effectsAfterShiftCount');
                            setSteps(newSteps);
                            onChange(newSteps);
                        }}
                        type={'link'}
                    >
                        <Icon style={{ fontSize: 20 }} component={IconSortAmountDownAltSolid} />
                        Упорядочить
                    </Button>
                </div>
            </div>

            {
                <div className={'rr-pricingScheme-editor-steps'}>
                    {!steps || steps.length === 0 ? (
                        <div className={'rr-pricingScheme-editor-steps-info'}>
                            Укажите число смен начиная с которого применяется скидка и ее размер
                        </div>
                    ) : (
                        steps.map((step, index) => {
                            let shiftCountError =
                                steps && steps.filter((s) => s.effectsAfterShiftCount === step.effectsAfterShiftCount).length > 1
                                    ? true
                                    : false;
                            return (
                                <div className={'rr-pricingScheme-editor-step'} key={index}>
                                    <div>{`${index === 0 ? 'Н' : 'н'}ачиная с`}</div>
                                    <div>
                                        <NumberInputShiftCount
                                            onChange={(value) => {
                                                if (steps) {
                                                    step.effectsAfterShiftCount = value || 0;
                                                    let newSteps = [...steps];
                                                    setSteps(newSteps);
                                                    onChange(newSteps);
                                                }
                                            }}
                                            value={step.effectsAfterShiftCount}
                                            className={joinClasses(
                                                'rr-input-number',
                                                shiftCountError ? 'rr-input-number-error' : undefined
                                            )}
                                            style={{ width: 92 }}
                                        />
                                    </div>
                                    <div>
                                        {localizeIntl(intl, LocalizationEnum.ASPECT__PLURAL__FROM_SHIFTS, undefined, {
                                            value: step.effectsAfterShiftCount,
                                        })}
                                    </div>
                                    <div>
                                        <Icon component={IconLongArrowAltRightSolid} />
                                    </div>
                                    <div>Скидка к базовой цене</div>
                                    <div>
                                        <NumberInputDiscount
                                            onChange={(value) => {
                                                if (steps) {
                                                    step.extraDiscount = value || 0;
                                                    let newSteps = [...steps];
                                                    setSteps(newSteps);
                                                    onChange(newSteps);
                                                }
                                            }}
                                            value={step.extraDiscount}
                                            className={'rr-input-number'}
                                            style={{ width: 104 }}
                                        />
                                    </div>
                                    <div>
                                        <SystemIcon
                                            type={SystemIconType.delete}
                                            className={'rr-grid-table-remove-icon'}
                                            onClick={() => {
                                                if (steps) {
                                                    let index = steps.indexOf(step);
                                                    let newSteps = [...steps];
                                                    newSteps.splice(index, 1);
                                                    setSteps(newSteps);
                                                    onChange(newSteps);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
            }
            {
                <>
                    {!steps || steps.length === 0 ? (
                        <Row style={{ marginRight: 40, marginLeft: 40, marginTop: 20 }}>
                            <Col span={8} className="rr-form-item">
                                <div className="rr-label">Смены</div>
                                <div style={{ maxWidth: 190 }}>
                                    <NumberInputShiftCount
                                        value={shiftCount}
                                        onChange={(v) => setShiftCount(v)}
                                        className={duplicateShiftCountError ? 'rr-input-number-error' : undefined}
                                    />
                                </div>
                            </Col>
                            <Col span={8} className="rr-form-item">
                                <div className="rr-label">Скидка</div>
                                <div>
                                    <NumberInputDiscount value={discount} onChange={(v) => setDiscount(v)} />
                                </div>
                            </Col>
                            <Col span={8} style={{ height: '100%' }}>
                                <div className="rr-label">&nbsp;</div>
                                <div style={{ display: 'flex', alignItems: 'end', flexDirection: 'column' }}>
                                    <RoundButton
                                        icon={'plus'}
                                        style={{ borderRadius: 8, width: '100%' }}
                                        colorScheme={'success2'}
                                        onClick={onStartButtonClick}
                                        className="rr-pricingScheme-addButton"
                                        disabled={!canAddStep}
                                    >
                                        Добавить уровень
                                    </RoundButton>
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        <div className="rr-pricingScheme-addBlock">
                            <RoundButton
                                icon={'plus'}
                                style={{ borderRadius: 8, width: '100%' }}
                                colorScheme={'success2'}
                                disabled={maxStepCountError || duplicateShiftCountError2}
                                onClick={onAddButtonClick}
                                className="rr-pricingScheme-addButton"
                            >
                                Добавить уровень
                            </RoundButton>
                            <div className="rr-pricingScheme-errors">
                                {maxStepCountError && <span>Достигнуто максимальное кол-во шагов</span>}
                                {duplicateShiftCountError2 && dublicateError && (
                                    <span style={{ opacity: dublicateErrorVisible ? 1 : 0, transition: 'opacity 1s ease' }}>
                                        Дублируется количество смен!
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                </>
            }
        </div>
    );
};
