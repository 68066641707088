import {
    ImageObj,
    InstanceTrackingTypeCodeEnum,
    NestedRentElementRecord,
    NomenclatureEntityTypeCodeEnum,
    OperationTypeCodeEnum,
    PricingSchemeExternalRepresentationObj,
    ProblemAndWarningRentIndicatorsRecordObj,
    RentElementInfoRead,
    RentElementRecord,
    RentElementResolutionCodeEnum,
    RentStateCodeEnum,
    RentTermsObj,
    ShiftsObj,
} from './server';
import {
    IconBorderStyleSolid,
    IconCheckSquare,
    IconEraser,
    IconHandStopO,
    IconHourglassStart,
    IconMagicSolid,
    IconReply,
    IconStickyNoteO,
    IconTimesCircle,
    IconWrench,
} from './components/icons';
import { LocalizationEnum } from './localization';

export const convertRentElementRecordToRentElementsGridItem = (record: RentElementRecord): RentElementsGridItem => {
    let gridItem: RentElementsGridItem = {
        activityFrameId: record.activityFrameId,
        id: record.id,
        businessVersion: record.businessVersion,
        businessAccountId: record.businessAccountId,
        kitId: record.kitId,
        projectId: record.projectId,
        projectShortName: record.projectShortName,
        subrentId: record.subrentId,
        subrentShortName: record.subrentShortName,
        projectFinished: record.projectFinished,
        templateId: record.templateId,
        templateShortName: record.templateShortName,
        renterId: record.counterpartyId,
        renterShortName: record.counterpartyShortName,
        rentPeriodEndDateMinimum: record.rentPeriodEndDateMinimum,
        rentElementsKitMemberCount: record.rentElementsKitMemberCount,
        shortage: true,
        delay: true,
        problemSeverity: 0,
        ...record.commonFields,
        productShortName: record.commonFields.nomenclatureShortName,
        cancelled:
            record.commonFields.stateCode === RentStateCodeEnum.CANCELED ||
            record.commonFields.stateCode === RentStateCodeEnum.SUBRENTCANCELED,
        calendarShifts: record.commonFields.calendarShifts,
        projectIsOffer: record.projectIsOffer,
    };

    if (record.nestedRentElements) {
        gridItem.subRows = record.nestedRentElements.map((nestedRecord) => {
            return convertNestedRentElementRecordToRentElementsGridItem(record, nestedRecord, {
                parent: gridItem,
            });
        });
    }
    return gridItem;
};

export const convertNestedRentElementRecordToRentElementsGridItem = (
    record: RentElementRecord,
    nestedRecord: NestedRentElementRecord,
    additionalFields?: Partial<RentElementsGridItem>
) => {
    return {
        ...nestedRecord,
        ...additionalFields,
        businessVersion: nestedRecord.businessVersion,
        businessAccountId: record.businessAccountId,
        id: nestedRecord.id,
        rentElementsKitId: record.id,
        nomenclatureShortName: nestedRecord.nomenclatureShortName,
        projectId: record.projectId,
        projectShortName: record.projectShortName,
        projectFinished: record.projectFinished,
        templateId: record.templateId,
        templateShortName: record.templateShortName,
        renterId: record.counterpartyId,
        renterShortName: record.counterpartyShortName,
        subrentId: record.subrentId,
        subrentShortName: record.subrentShortName,
        rentPeriodEndDateMinimum: record.rentPeriodEndDateMinimum,
        commonFields: undefined,
        nestedRentElements: undefined,
        rentElementsKitMemberCount: record.rentElementsKitMemberCount,
        shortage: true,
        delay: true,
        problemSeverity: 0,
        productShortName: nestedRecord.nomenclatureShortName,
        cancelled: nestedRecord.stateCode === RentStateCodeEnum.CANCELED || nestedRecord.stateCode === RentStateCodeEnum.SUBRENTCANCELED,
        calendarShifts: nestedRecord.calendarShifts,
        effectivePricingScheme: nestedRecord.effectivePricingScheme,
    };
};

export const convertRentElementInfoReadToRentElementsGridItem = (record: RentElementInfoRead): RentElementsGridItem => {
    let gridItem: RentElementsGridItem = {
        id: record.id,
        businessVersion: record.businessVersion,
        businessAccountId: record.businessAccountId,
        rentElementsKitId: record.rentElementsKitId,
        kitId: record.kitId,
        kitShortName: record.kitShortName,
        nomenclatureShortName: record.productShortName,
        projectId: record.projectId,
        projectShortName: record.projectShortName,
        templateId: record.templateId,
        templateShortName: record.templateShortName,
        renterId: record.counterpartyId,
        renterShortName: record.counterpartyShortName,
        subrentId: record.subrentId,
        subrentShortName: record.subrentShortName,
        instanceTrackingTypeCode: record.instanceTrackingTypeCode,
        productId: record.productId,
        productShortName: record.productShortName,
        lastUpdateAuthorId: record.lastUpdateAuthorId,
        operationIds: [],
        lastOperationId: 0,
        rentTerms: record.rentTerms,
        calendarShiftCount: record.calendarShiftCount,
        nomenclatureEntityTypeCode: NomenclatureEntityTypeCodeEnum.PRODUCT,
        effectivePrice: record.effectivePrice,
        instanceCount: record.instanceCount,
        anonymousInstanceCount: record.anonymousInstanceCount,
        finalInstancePrice: 0,
        finalTotalPrice: 0,
        stateCode: record.stateCode,
        active: true,
        problemSeverity: 0,
        shortage: record.rentIndicators.problemsAndWarnings.anyShortage,
        shortageInstanceCount: record.shortageInstanceCount,
        rentPeriodEndDateMinimum: new Date(),
        delay: record.rentIndicators.problemsAndWarnings.anyDelay,
        lastActivityDate: new Date(),
        effectivePricingScheme: record.effectivePricingScheme,
        basePriceAtTheTimeOfCreation: record.basePriceAtTheTimeOfCreation,
        resolutionCodes: record.resolutionCodes,
        projectIsOffer: record.projectIsOffer,
        projectIsSimpleOrder: record.projectIsSimpleOrder,
    };
    return gridItem;
};

export type RentElementsGridItem = {
    activityFrameId?: number;
    id: number;
    numberInActivityFrame?: number;
    subRows?: RentElementsGridItem[];
    businessVersion: number;
    businessAccountId: number;
    rentElementsKitId?: number;
    kitId?: number;
    kitShortName?: string;
    nomenclatureShortName: string;
    projectId?: number;
    projectShortName?: string;
    projectFinished?: boolean;
    templateId?: number;
    templateShortName?: string;
    renterId?: number;
    renterShortName?: string;
    rentPeriodEndDateMinimum: Date;
    rentElementsKitMemberCount?: number;
    subrentId?: number;
    subrentShortName?: string;
    // Это из commonFields
    nomenclatureEntityTypeCode: NomenclatureEntityTypeCodeEnum;
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum;
    instanceIds?: Array<number>;
    productId: number;
    productShortName: string;
    variantId?: number;
    variantIds?: Array<number>;
    variantName?: string;
    image?: ImageObj;
    lastUpdateAuthorId: number;
    operationIds: Array<number>;
    lastOperationId: number;
    rentTerms: RentTermsObj;
    calendarShiftCount?: number | undefined;
    effectivePrice: number;
    instanceCount: number;
    anonymousInstanceCount: number;
    finalInstancePrice: number;
    finalTotalPrice: number;
    stateCode: RentStateCodeEnum;
    active: boolean;
    problemSeverity: number;
    shortage: boolean;
    shortageInstanceCount: number;
    instanceWithCurrentElementShortageIds?: Array<number>;
    delay: boolean;
    availableTransitionCodes?: Array<OperationTypeCodeEnum>;
    availableTargetCorrectionStates?: Array<RentStateCodeEnum>;
    lastActivityDate: Date;
    // для набора
    mainKitMember?: boolean;
    parent?: RentElementsGridItem;
    problemsAndWarnings?: ProblemAndWarningRentIndicatorsRecordObj;
    externalCode?: string;
    cancelled?: boolean;
    calendarShifts?: ShiftsObj;
    subrentWorkedShifts?: ShiftsObj;
    effectivePricingScheme?: PricingSchemeExternalRepresentationObj;
    basePriceAtTheTimeOfCreation: number;
    resolutionCodes?: Array<RentElementResolutionCodeEnum>;
    tags?: string[];
    projectIsOffer?: boolean;
    projectIsSimpleOrder?: boolean;
    stateCodeToReturnBeforeCancel?: RentStateCodeEnum;
    stateCodeToReturnBeforeCancelAvailableWithoutCorrection?: boolean;
};

export enum ProblemEnum {
    RETURN_DELAY = 'RETURN_DELAY',
    GIVEAWAY_DELAY = 'GIVEAWAY_DELAY',
    BOOK_SHORTAGE = 'BOOK_SHORTAGE',
    ORDER_SHORTAGE = 'ORDER_SHORTAGE',
    SUBRENT_SHIPMENT_DELAY = 'SUBRENT_SHIPMENT_DELAY',
    SUBRENT_RETURN_TO_SHIPPER_DELAY = 'SUBRENT_RETURN_TO_SHIPPER_DELAY',
    ANY_DELAY = 'ANY_DELAY',
    ANY_SHORTAGE = 'ANY_SHORTAGE',
    ANY_PROBLEM = 'ANY_PROBLEM',
    ANY_PROBLEM_OR_WARNING = 'ANY_PROBLEM_OR_WARNING',
}

export const SUBRENT_STATE_CODES = [
    RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
    RentStateCodeEnum.SUBRENT,
    RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER,
    RentStateCodeEnum.SUBRENTCANCELED,
    RentStateCodeEnum.SUBRENTDRAFT,
];

export const RENT_STATE_CODES = [
    RentStateCodeEnum.ORDERED,
    RentStateCodeEnum.BOOKED,
    RentStateCodeEnum.RENT,
    RentStateCodeEnum.RETURNED,
    RentStateCodeEnum.RETURNEDBROKEN,
    RentStateCodeEnum.LOSTDURINGRENT,
    RentStateCodeEnum.CANCELED,
    RentStateCodeEnum.DRAFT,
    RentStateCodeEnum.MIXED,
];

export const ALL_STATE_CODES = [...RENT_STATE_CODES, ...SUBRENT_STATE_CODES];

export interface IOperationTypeData {
    code: OperationTypeCodeEnum;
    icon: any;
    localizationCode: LocalizationEnum;
    colorScheme: string;
}

export const getOperationTypeDataByCodes = (codes: (OperationTypeCodeEnum | undefined)[]): IOperationTypeData[] => {
    return (codes.filter((code) => code !== undefined) as OperationTypeCodeEnum[])
        .map((code) => getOperationTypeDataByCode(code))
        .filter((item) => item) as IOperationTypeData[];
};

export const getOperationTypeDataByCode = (code: OperationTypeCodeEnum): IOperationTypeData | undefined => {
    return ALL_OPERATION_TYPES.find((type) => type.code === code);
};

/**
 * Все доступные типы операций
 */
export const ALL_OPERATION_TYPES: IOperationTypeData[] = [
    {
        code: OperationTypeCodeEnum.ORDER,
        icon: IconStickyNoteO,
        localizationCode: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__ORDER,
        colorScheme: 'ORDER',
    },
    {
        code: OperationTypeCodeEnum.BOOK,
        icon: IconCheckSquare,
        localizationCode: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__BOOK,
        colorScheme: 'BOOK',
    },
    {
        code: OperationTypeCodeEnum.RENT,
        icon: IconHandStopO,
        localizationCode: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__GIVE_AWAY,
        colorScheme: 'RENT',
    },
    {
        code: OperationTypeCodeEnum.PROLONG,
        icon: IconHourglassStart,
        localizationCode: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__PROLONG,
        colorScheme: 'PROLONG',
    },
    {
        code: OperationTypeCodeEnum.RETURN,
        icon: IconReply,
        localizationCode: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__RETURN,
        colorScheme: 'RETURN',
    },
    {
        code: OperationTypeCodeEnum.RETURNBROKEN,
        icon: IconWrench,
        localizationCode: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__RETURN_BROKEN,
        colorScheme: 'RETURN_BROKEN',
    },
    {
        code: OperationTypeCodeEnum.LOSTNORETURN,
        icon: IconTimesCircle,
        localizationCode: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__LOST_NO_RETURN,
        colorScheme: 'LOST_NO_RETURN',
    },
    {
        code: OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT,
        icon: IconCheckSquare,
        localizationCode: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_BOOK_SHIPMENT,
        colorScheme: OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT,
    },
    {
        code: OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT,
        icon: IconHandStopO,
        localizationCode: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_ACCEPT_SHIPMENT,
        colorScheme: OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT,
    },
    {
        code: OperationTypeCodeEnum.SUBRENTPROLONG,
        icon: IconHourglassStart,
        localizationCode: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_PROLONG,
        colorScheme: OperationTypeCodeEnum.SUBRENTPROLONG,
    },
    {
        code: OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER,
        icon: IconReply,
        localizationCode: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_RETURN_TO_SHIPPER,
        colorScheme: OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER,
    },
    {
        code: OperationTypeCodeEnum.CORRECT,
        icon: IconMagicSolid,
        localizationCode: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__CORRECT,
        colorScheme: 'CORRECT',
    },
    {
        code: OperationTypeCodeEnum.CANCEL,
        icon: IconEraser,
        localizationCode: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__CANCEL,
        colorScheme: 'CANCEL',
    },
    {
        code: OperationTypeCodeEnum.DRAFT,
        icon: IconBorderStyleSolid,
        localizationCode: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__DRAFT,
        colorScheme: 'DRAFT',
    },
    {
        code: OperationTypeCodeEnum.SUBRENTCANCEL,
        icon: IconEraser,
        localizationCode: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_CANCEL,
        colorScheme: OperationTypeCodeEnum.SUBRENTCANCEL,
    },
    {
        code: OperationTypeCodeEnum.SUBRENTDRAFT,
        icon: IconBorderStyleSolid,
        localizationCode: LocalizationEnum.ASPECT__RENT_OPERATION__ACTION__SUBRENT_DRAFT,
        colorScheme: OperationTypeCodeEnum.SUBRENTDRAFT,
    },
];

export interface IRentStateData {
    code: RentStateCodeEnum;
    localizationCode: LocalizationEnum;
    colorScheme: string;
}

export const getRentStateDataByCodes = (codes: RentStateCodeEnum[]): IRentStateData[] => {
    return codes.map((code) => getRentStateDataDataByCode(code)).filter((item) => item) as IRentStateData[];
};

export const getRentStateDataDataByCode = (code: RentStateCodeEnum): IRentStateData | undefined => {
    return ALL_RENT_STATE_CODES.find((type) => type.code === code);
};

//
export const ALL_RENT_STATE_CODES: {
    code: RentStateCodeEnum;
    localizationCode: LocalizationEnum;
    colorScheme: string;
}[] = [
    {
        code: RentStateCodeEnum.ORDERED,
        localizationCode: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__ORDERED,
        colorScheme: RentStateCodeEnum.ORDERED,
    },
    {
        code: RentStateCodeEnum.BOOKED,
        localizationCode: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__BOOKED,
        colorScheme: RentStateCodeEnum.BOOKED,
    },
    {
        code: RentStateCodeEnum.RENT,
        localizationCode: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RENT,
        colorScheme: RentStateCodeEnum.RENT,
    },
    {
        code: RentStateCodeEnum.RETURNED,
        localizationCode: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RETURNED,
        colorScheme: RentStateCodeEnum.RETURNED,
    },
    {
        code: RentStateCodeEnum.RETURNEDBROKEN,
        localizationCode: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__RETURNED_BROKEN,
        colorScheme: RentStateCodeEnum.RETURNEDBROKEN,
    },
    {
        code: RentStateCodeEnum.LOSTDURINGRENT,
        localizationCode: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__LOST_DURING_RENT,
        colorScheme: RentStateCodeEnum.LOSTDURINGRENT,
    },
    {
        code: RentStateCodeEnum.CANCELED,
        localizationCode: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__CANCELED,
        colorScheme: RentStateCodeEnum.CANCELED,
    },
    {
        code: RentStateCodeEnum.DRAFT,
        localizationCode: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__DRAFT,
        colorScheme: RentStateCodeEnum.DRAFT,
    },
    {
        code: RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
        localizationCode: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_SHIPMENT_BOOKED,
        colorScheme: RentStateCodeEnum.SUBRENTSHIPMENTBOOKED,
    },
    {
        code: RentStateCodeEnum.SUBRENT,
        localizationCode: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT,
        colorScheme: RentStateCodeEnum.SUBRENT,
    },
    {
        code: RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER,
        localizationCode: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_RETURNED_TO_SHIPPER,
        colorScheme: RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER,
    },
    {
        code: RentStateCodeEnum.SUBRENTCANCELED,
        localizationCode: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_CANCELED,
        colorScheme: RentStateCodeEnum.SUBRENTCANCELED,
    },
    {
        code: RentStateCodeEnum.SUBRENTDRAFT,
        localizationCode: LocalizationEnum.ASPECT__STATE_CODE__ELEMENT__SUBRENT_DRAFT,
        colorScheme: RentStateCodeEnum.SUBRENTDRAFT,
    },
];
