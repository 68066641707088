import React, { ReactNode } from 'react';
import { DataDisplayState } from '../../../../../../components/dataDisplay';
import classNames from 'classnames';
import FormValue from '../../../../../../components/formValue/FormValue';
import { formatMoney } from '../../../../../../shared/util/formatMoney';

import './labelValueTable.less';

interface LabelValueTableProps {
    data: (LabelValueTableData | boolean | undefined | null | 0 | '')[];
}

interface LabelValueTableData {
    label: ReactNode;
    value: number;
    description?: ReactNode;
    state?: DataDisplayState;
    extra?: ReactNode;
    zeroText?: string;
}

export const LabelValueTable = (props: LabelValueTableProps) => {
    const renderValue = (item: LabelValueTableData) => {
        if (item.zeroText && item.value === 0) return <div style={{ fontWeight: 400, color: '#34bfa3' }}>{item.zeroText}</div>;
        else return <FormValue value={formatMoney(item.value)} originalValue={item.value} />;
    };

    return (
        <div style={{ display: 'table' }}>
            {(props.data.filter((item) => Boolean(item)) as LabelValueTableData[]).map((item, index, arr) => {
                return (
                    <div style={{ display: 'table-row' }}>
                        <div
                            style={{
                                display: 'table-cell',
                                paddingRight: 40,
                                paddingBottom: index < arr.length - 1 ? 8 : undefined,
                                textAlign: 'right',
                            }}
                        >
                            {item.label}
                        </div>
                        <div style={{ display: 'table-cell', textAlign: 'right', paddingBottom: index < arr.length - 1 ? 8 : undefined }}>
                            <div
                                className={classNames(
                                    'rr-value',
                                    item.state === DataDisplayState.Problem && 'rr-dd-i-v-problem',
                                    item.state === DataDisplayState.Success && 'rr-dd-i-v-success',
                                    item.state === DataDisplayState.Muted && 'rr-0-value'
                                )}
                            >
                                {item.description ? (
                                    <div className="lableValueTable-valueDescription">
                                        {renderValue(item)}
                                        {item.description}
                                    </div>
                                ) : (
                                    renderValue(item)
                                )}
                                {item.extra}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
