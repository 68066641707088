import { Icon, Modal } from 'antd';
import { ProblemRentElementField } from '../../../server';
import { Link } from 'react-router-dom';
import { getBusinessAccountId } from '../../../..';
import { IconExclamationSolid } from '../../../components/icons';

interface Props {
    fields: ProblemRentElementField[];
    onClose: () => void;
}
const CatalogPriceUpdateErrorModal = ({ fields, onClose }: Props) => {
    return (
        <Modal
            visible={fields.length > 0}
            onCancel={onClose}
            width={560}
            title={
                <>
                    <Icon
                        style={{ marginRight: 4, fontSize: 32, color: '#f8516c', verticalAlign: 'middle' }}
                        component={IconExclamationSolid}
                    />
                    Компоненты набора не установлены
                </>
            }
            okButtonProps={{ style: { display: 'none' } }}
            cancelText="Oтмена"
            cancelButtonProps={{ type: 'primary' }}
            centered
        >
            {fields.map((field, i) => {
                const { kitShortName, kitId, productShortName, productId, variantShortName, countInstance } = field;
                return (
                    <div style={{ marginBottom: 12 }}>
                        <Link className="rr-link" to={`/${getBusinessAccountId()}/inventory/kits/${kitId}?tab=description`}>
                            <b>{kitShortName}</b>
                        </Link>
                        <Link
                            className="rr-link"
                            style={{ display: 'block', marginLeft: 18 }}
                            to={`/${getBusinessAccountId()}/inventory/products/${productId}?tab=description`}
                        >
                            {countInstance} x <b>{productShortName}</b>
                            {variantShortName ? `: ${variantShortName}` : ''}
                        </Link>
                    </div>
                );
            })}
        </Modal>
    );
};
export default CatalogPriceUpdateErrorModal;
