import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Popover, PopoverProps } from '../../popover/Popover';
import './DownloadDocumentPopover.less';
import { DocumentTypeCodeEnum, FileTypeCodeEnum } from '../../../server';
import Spin from '../../spin/spin';
import { useSelectedValues } from './hooks/useSelectedValues';
import { WarningsList } from '../../text/WarningsList/WarningsList';
import { DownloadDocumentPopoverTemplateSelect } from './components/TemplateSelect/DownloadDocumentPopoverTemplateSelect';
import { DownloadDocumentPopoverDiscountInput } from './components/DiscountInput/DownloadDocumentPopoverDiscountInput';
import { DownloadDocumentPopoverOptions } from './components/Options/DownloadDocumentPopoverOptions';
import { DownloadDocumentPopoverExports } from './components/Exports/DownloadDocumentPopoverExports';
import { useHandleVisibleChange } from './hooks/useHandleVisibleChange';
import { DownloadDocumentPopoverPeriod } from './components/Period/DownloadDocumentPopoverPeriod';
import { DownloadPeriodValues } from './components/Period/hooks/useSelectOptions';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { useDocumentLocalStorage } from './hooks/useDocumentLocalStorage';
import { DownloadDocumentPopoverLegalDetails } from './components/LegalDetails/DownloadDocumentPopoverLegalDetails';
import { useAppSelector } from '../../../store/hooks';
import { useLegalDetailsValue } from './hooks/useLegalDetailsValue';
import { ExportEstimatePopoverOptions } from './instances/ExportEstimatePopover/utils/data';
import { ExportActPopoverOptions } from './instances/ExportActPopover/utils/data';

export enum SUPPORTED_SERVER_METHOD {
    LIST_DOCUMENT_TEMPLATES = 'listDocumentTemplates',
    LIST_DOCUMENT_TEMPLATES_FOR_REPORT_INSTANCE = 'listDocumentTemplatesForReportChartVisualization',
}

export interface IDownloadDocumentParamsItem<Key extends string = string> {
    key: Key;
    label: ReactNode;
    parent?: string | string[];
    defaultSelected?: boolean;
    disabled?: boolean;
}

export interface DownloadDocumentTemplateSelectedValue {
    key;
    label;
    common;
}

export interface DownloadDocumentPopoverProps<FileType extends FileTypeCodeEnum, Options extends Object = Object> {
    title: ReactNode;
    options?: IDownloadDocumentParamsItem[]; // Эта штука позволяет создать галочки
    onDownload: (args: {
        fileType: FileType;
        options: Options[];
        templateId: number | null;
        isCommonTemplate?: boolean;
        discount?: number;
        period?: [string, string];
        legalDetailId?: number;
    }) => Promise<void>; // Это событие нажатия на кнопку скачать. Передаются опции из параметра выше и выбранная сущность
    storageKey: string; // Redux наше всё
    withTemplate?: boolean; // С шаблонами ли?
    withDiscount?: boolean; // Со скидкой ли?
    documentType?: DocumentTypeCodeEnum; // Тип документа - для listDocuments Это когда есть шаблон
    method?: SUPPORTED_SERVER_METHOD; // Метод для EntityRemoteSelect
    entityRemoteSelectCustomParams?: any;
    onVisibleChange?: (visible: boolean) => boolean;
    children: ReactNode;
    availableFileTypes: FileTypeCodeEnum[];
    getPopupContainer?: PopoverProps['getPopupContainer'];
    warnings?: (string | ReactNode)[];
    outerVisible?: boolean;
    showPeriod?: boolean;
    showLegalDetails?: boolean;
    isCompact?: boolean;
    loadTemplatesOnMount?: boolean;
    getFilters?: (freeItems: boolean) => string[];
    defaultSelectedOptions?: string[];
    checkBeforeDownload?: boolean;
}

export const DownloadDocumentPopover = <FileType extends FileTypeCodeEnum, Options extends Object>(
    props: DownloadDocumentPopoverProps<FileType, Options>
) => {
    const {
        availableFileTypes,
        documentType,
        entityRemoteSelectCustomParams,
        getPopupContainer,
        method,
        onDownload,
        onVisibleChange,
        options,
        storageKey,
        title,
        warnings,
        withDiscount,
        withTemplate,
        children,
        outerVisible,
        showPeriod = false,
        showLegalDetails = false,
        isCompact = false,
        loadTemplatesOnMount,
        getFilters,
        defaultSelectedOptions,
        checkBeforeDownload,
    } = props;
    const legalDetails = useAppSelector((store) => store.businessAccount.entity?.legalDetails);

    const [documentsLocalStorage, setDocumentsLocalStorage] = useDocumentLocalStorage(storageKey, entityRemoteSelectCustomParams);

    const [visible, setVisible] = useState(false);
    const [_selectedValues, setSelectedValues] = useState(
        !documentsLocalStorage.params ? [...(defaultSelectedOptions || [])] : documentsLocalStorage.params
    );
    const [templateSelectedValue, setTemplateSelectedValue] = useState<DownloadDocumentTemplateSelectedValue | undefined>(
        documentsLocalStorage.template
    );

    const [legalDetailsValue, setLegalDetailsValue] = useLegalDetailsValue(legalDetails, documentsLocalStorage, showLegalDetails);
    const [discount, setDiscount] = useState(documentsLocalStorage.discount as number | undefined);
    const [periodValue, setPeriodValue] = useState<DownloadPeriodValues | RangePickerValue | undefined>(
        showPeriod ? documentsLocalStorage.period : undefined
    );
    const [loading, setLoading] = useState<boolean>(false);
    const defaultTemplate = useAppSelector((state) => state.businessAccountPreferences.preferences?.defaultBadgeTemplate);

    const { selectedValues, disabledValues, onGroupChange } = useSelectedValues<Options[]>({
        selectedValues: _selectedValues,
        options: options || [],
    });

    useEffect(() => {
        if (documentType === DocumentTypeCodeEnum.BADGE && documentsLocalStorage.template) {
            setTemplateSelectedValue(documentsLocalStorage.template);
        }
    }, [documentType, documentsLocalStorage.template]);

    const freeItemsChecked = useMemo(() => {
        return selectedValues.some(
            //@ts-ignore
            (value) => value === ExportEstimatePopoverOptions.FREE_ITEMS || value === ExportActPopoverOptions.FREE_ITEMS
        );
    }, [selectedValues]);

    const getFiltersCb = useCallback(() => (getFilters ? getFilters(freeItemsChecked) : []), [freeItemsChecked, getFilters]);

    const handleVisibleChange = useHandleVisibleChange({
        documentsLocalStorage,
        entityRemoteSelectCustomParams,
        method,
        onVisibleChange,
        templateSelectedValue,
        setDocumentsLocalStorage,
        setLoading,
        setTemplateSelectedValue,
        setVisible,
        withTemplate,
    });

    const isLegalDetailsVisible = showLegalDetails && legalDetails != null && legalDetails.length > 1;

    useEffect(() => {
        if (outerVisible !== undefined) setVisible(outerVisible);
    }, [outerVisible]);

    const onTemplatesLoad = (data: any[]) => {
        const isLSTemplateValid = data.some((v) => v.id.toString() === documentsLocalStorage.template?.key);
        if (!isLSTemplateValid) {
            setDocumentsLocalStorage({
                ...documentsLocalStorage,
                template: undefined,
            });
            setTemplateSelectedValue(undefined);
        }

        if (documentType === DocumentTypeCodeEnum.BADGE && defaultTemplate) {
            const isDefaultTemplateValid = data.some((v) => v.id === defaultTemplate.id);
            if (!isDefaultTemplateValid) {
                setDocumentsLocalStorage({
                    ...documentsLocalStorage,
                    template: undefined,
                });

                setTemplateSelectedValue(undefined);
            }
        }
    };

    return (
        <Popover
            width={isCompact ? 345 : 320}
            onVisibleChange={handleVisibleChange}
            visible={outerVisible === false ? outerVisible : visible}
            trigger={outerVisible === false ? 'contextMenu' : undefined}
            overlayClassName={'rr-DownloadDocumentPopover'}
            header={title}
            getPopupContainer={getPopupContainer}
            content={
                <Spin spinning={loading}>
                    <div className={'download-document-popover-container'} onClick={(e) => e.stopPropagation()}>
                        {withTemplate && (
                            <DownloadDocumentPopoverTemplateSelect
                                documentType={documentType}
                                method={method}
                                documentsLocalStorage={documentsLocalStorage}
                                setDocumentsLocalStorage={setDocumentsLocalStorage}
                                entityRemoteSelectCustomParams={entityRemoteSelectCustomParams}
                                templateSelectedValue={templateSelectedValue}
                                setTemplateSelectedValue={setTemplateSelectedValue}
                                loadDataOnMount={loadTemplatesOnMount}
                                onEntityDataLoad={onTemplatesLoad}
                            />
                        )}
                        {withDiscount && (
                            <DownloadDocumentPopoverDiscountInput
                                discount={discount}
                                setDiscount={setDiscount}
                                documentsLocalStorage={documentsLocalStorage}
                                setDocumentsLocalStorage={setDocumentsLocalStorage}
                            />
                        )}
                        {options && (
                            <DownloadDocumentPopoverOptions
                                options={options}
                                selectedValues={selectedValues}
                                setSelectedValues={setSelectedValues}
                                disabledValues={disabledValues}
                                documentsLocalStorage={documentsLocalStorage}
                                setDocumentsLocalStorage={setDocumentsLocalStorage}
                                onGroupChange={onGroupChange}
                            />
                        )}
                        {warnings != null && warnings.length > 0 && <WarningsList warnings={warnings} />}
                        {showPeriod && (
                            <DownloadDocumentPopoverPeriod
                                documentsLocalStorage={documentsLocalStorage}
                                setDocumentsLocalStorage={setDocumentsLocalStorage}
                                periodValue={periodValue}
                                setPeriodValue={setPeriodValue}
                            />
                        )}
                        {isLegalDetailsVisible && (
                            <DownloadDocumentPopoverLegalDetails
                                legalDetails={legalDetails}
                                selectedLegalDetailValue={legalDetailsValue}
                                setSelectedLegalDetailValue={setLegalDetailsValue}
                                documentsLocalStorage={documentsLocalStorage}
                                setDocumentsLocalStorage={setDocumentsLocalStorage}
                            />
                        )}
                        <DownloadDocumentPopoverExports
                            availableFileTypes={availableFileTypes}
                            selectedTemplateValue={templateSelectedValue}
                            selectedLegalDetailValue={legalDetailsValue}
                            periodValue={periodValue}
                            onDownload={onDownload}
                            selectedValues={selectedValues}
                            withTemplate={withTemplate}
                            discount={discount}
                            setVisible={setVisible}
                            setLoading={setLoading}
                            getFilters={getFiltersCb}
                            documentType={documentType}
                            checkBeforeDownload={checkBeforeDownload}
                        />
                    </div>
                </Spin>
            }
        >
            {children}
        </Popover>
    );
};
