import React, {ReactNode, useState} from "react";
import {Icon} from "antd";
import {
    ProjectRecord,
    ProjectStateCodeEnum,
    ProjectTypeCodeEnum,
} from "../../../../../server";
import {BaseEntityMovePopover} from "./baseEntityMovePopover";
import {LocalizationEnum} from "../../../../../localization";
import {ProjectStateBadge} from "../../../projects/production/components/ProjectStateBadge/ProjectStateBadge";
import {ProjectSelectFC} from "../../../../../components/v2/select/projectSelect/projectSelect";
import {useLocalize} from "../../../../../core/hooks/useLocalize";
import {IconCrosshairsSolid, IconMoneyCheckAltSolid} from "../../../../../components/icons";
import {ProjectStateCode} from "../../../projects/production/data/projectData";

interface SelectProjectPopoverProps<EntityRecord> {
    closeParentElement: () => void;
    projectId?: number;
    copy?: boolean;
    onOkCallback: (targetProject: ProjectRecord) => void;
    allowInProject: (record:ProjectRecord, entity:EntityRecord)=>boolean;
    selectedEntities:EntityRecord[];
    children: ReactNode;
}

const MoneyIcon = <Icon style={{ fontSize: 20, marginRight: 8, color: 'rgba(0, 0, 0, 0.65)' }} component={IconMoneyCheckAltSolid} />;
const SameProjectIcon = <Icon style={{ fontSize: 20, marginRight: 8 }} component={IconCrosshairsSolid} />;
const SameProjectCopyIcon = <Icon style={{ fontSize: 20, marginRight: 8, color: '#00afee' }} component={IconCrosshairsSolid} />;

export const SelectProjectPopover = <EntityRecord,>(props:SelectProjectPopoverProps<EntityRecord>) => {
    const {children, allowInProject, selectedEntities, projectId, onOkCallback, copy} = props;
    const L = useLocalize();
    const [selectedProject, setSelectedProject] = useState<ProjectRecord | undefined>();

    return (
        <BaseEntityMovePopover
            select={
                <ProjectSelectFC
                    dropdownMatchSelectWidth={false}
                    style={{width: '100%', maxWidth: 314}}
                    className={'rr-select-gray'}
                    placeholder={L(LocalizationEnum.PAGE__ELEMENTS__POPUP_NOTIFICATIONS__PLACEHOLDER__SELECT_PROJECT)}
                    openOnMount={true}
                    onDataChange={(data) => {
                        setSelectedProject(data?.[0]);
                    }}
                    filters={{
                        stateCode: [ProjectStateCodeEnum.DRAFT, ProjectStateCodeEnum.INPROGRESS, ProjectStateCodeEnum.APPROVED, ProjectStateCodeEnum.FINISHED, /*ProjectStateCodeEnum.BOOKED, ProjectStateCodeEnum.RENTED,*/ ProjectStateCodeEnum.COORDINATION],
                        archive: false,
                        isSimpleOrder: false
                    }}
                    value={selectedProject?.id}
                    getOptionProps={(record) => {
                        let disabled = selectedEntities.some((entity)=>!allowInProject(record, entity));
                        const isSameProject = record.id === projectId;
                        if(!copy && isSameProject) disabled = true;
                        const label = (
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {isSameProject && (copy ? SameProjectCopyIcon : SameProjectIcon)}
                                {disabled && !isSameProject && record.projectType !== ProjectTypeCodeEnum.OFFER && <ProjectStateBadge style={{marginRight: 5}} type={record.stateCode as ProjectStateCode} dot hideLabel />}
                                {record.projectType === ProjectTypeCodeEnum.OFFER && MoneyIcon}
                                <span>{record.shortName} <span style={{fontWeight: 200}}>{record.renterShortName}</span></span>
                            </div>
                        );
                        return {
                            label,
                            disabled
                        }
                    }}
                />
            }
            onClose={()=>{
                setSelectedProject(undefined);
            }}
            onOkCallback={onOkCallback}
            selectedRecord={selectedProject}>
            {children}
        </BaseEntityMovePopover>
    );
}

export const isAllowedInProject = (project:ProjectRecord, s:EntityState) => {
    const projectState = project.stateCode;
    if(project.projectType === ProjectTypeCodeEnum.OFFER){
        return !s.isActive && !s.isFinished;
    }else{
        if(projectState === ProjectStateCodeEnum.DRAFT){
            return !s.isActive && !s.isFinished;
        }else if(projectState === ProjectStateCodeEnum.COORDINATION){
            return !s.isInProgress || s.isFinished;
        }else if(projectState === ProjectStateCodeEnum.INPROGRESS || projectState === ProjectStateCodeEnum.BOOKED || projectState === ProjectStateCodeEnum.RENTED){
            return true;
        }else if(projectState === ProjectStateCodeEnum.FINISHED){
            return !s.isActive;
        }else if(projectState === ProjectStateCodeEnum.CANCELED){
            return s.isCanceled;
        }
    }
    return false;
}

export interface EntityState {
    isActive: boolean;
    isInProgress: boolean;
    isFinished: boolean;
    isCanceled: boolean;
}
