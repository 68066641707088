import {RenterStateCodeEnum} from "../../../../../../server";
import {StateBadgeData} from "../../../../../../components/v2/stateBadge/stateBadge";
import {Color} from "../../../../../../core/types/color";

export const counterpartyStatesMap: StateBadgeData<RenterStateCodeEnum> = {
    [RenterStateCodeEnum.NEW]: {
        color: Color.Azure,
        name: 'Новый',
    },
    [RenterStateCodeEnum.ACTIVE]: {
        color: Color.Green,
        name: 'Активный',
    },
    [RenterStateCodeEnum.BLOCKED]: {
        color: Color.Grey2,
        name: 'Заблокирован',
    }
};
