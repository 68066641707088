import { ServerUtils } from '../../../../../../core/utils/serverUtils';
import { ListLocationsArgs } from './locations.api.types';
import { LocationRecord } from '../../../../../../server';

export abstract class LocationsUtils {
    static createRequestFilters = (params: ListLocationsArgs['params']) => {
        return ServerUtils.createRequestFilters<LocationRecord>([
            params.hideArchive ? ['archive', 'EQ', false] : undefined,
            params.id ? ['id', 'EQ', params.id] : undefined,
            params.contactId && ['contactIds', 'EQ', params.contactId],
        ]);
    };
}
