import ReactDOM from 'react-dom';
import React, { createRef, RefObject } from 'react';
import { Button, Icon, Modal } from 'antd';
import DynamicForm, { FormFieldsGroup } from '../dynamicForm/DynamicForm';
import './modalForm.less';
import Spin from '../spin/spin';
import { Alert } from '../alert/alert';
import { ServerError } from '../../server';
import { LocalizationEnum, localize } from '../../localization';
import { IModalFormFilters, ModalFormFilters } from './components/Header/components/ModalFormFilters/ModalFormFilters';
import { ModalFormHeader } from './components/Header/ModalFormHeader';
import { formFieldsGroupsWithFilteredFields, isErrorValidationFieldHidden, ModalActionData, ModalName } from './utils';
import { IRootState } from '../../shared/reducers';
import { updateModalFilters } from '../../shared/reducers/userSettings/userSettings.reducer';
import _ from 'lodash';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { IconEyeSlashSolid } from '../icons';
import classNames from 'classnames';
import { Bookmark } from './components/Bookmark/Bookmark';

export interface ModalFormNEWState {
    isValidating: boolean;
    formValues?: Record<string, any>;
    filtersState?: ModalFormFiltersState;
    modalFilters?: ModalActionData;
}

export interface ModalFormFiltersState {
    data: IModalFormFilters['data'][number];
    value: string;
}

export interface ModalProps<T = any> {
    width?: number;
    onCancel?: () => void;
    onSuccess?: (data: T) => void;
}

export interface ModalFormNEWProps extends DispatchProps, StateProps, ModalProps {
    initialValues?: { [k: string]: any };
    businessAccountId: number;
    title?: string | React.ReactNode;
    formFields?: FormFieldsGroup[];
    updating?: boolean;
    updatingError?: ServerError;
    editMode?: boolean;
    validateAfterCreate?: boolean;
    okButtonText?: string | React.ReactNode;
    validateModal?: boolean;
    zIndex?: number;
    filters?: IModalFormFilters;
    modalName?: ModalName;
}

export class ModalFormNEW<P extends ModalFormNEWProps, S extends ModalFormNEWState = ModalFormNEWState> extends React.PureComponent<P, S> {
    _formRef: WrappedFormUtils<any> | undefined;
    interval;
    isValidationError: boolean = false;
    isFieldsTouched: boolean = false;
    private filteredFormFields: FormFieldsGroup[] | undefined;
    private filterCurrentValue: string | undefined;
    modalRef: RefObject<Modal>;

    constructor(props: P) {
        super(props);

        this.modalRef = createRef();

        const { filters, modalFilters = {} } = this.props;

        let initialState = {
            isValidating: false,
        } as S;

        if (filters) {
            const { defaultValue, data } = filters;
            const currentData = data.find(({ value }) => value === defaultValue)!;
            initialState = {
                ...initialState,
                filtersState: {
                    value: defaultValue,
                    data: currentData,
                },
                modalFilters: modalFilters,
            } as S;
        }

        this.state = initialState;
    }

    componentDidMount() {
        const modalFilters = this.props.modalFilters ?? {};
        const filtersStateValue = modalFilters[String(this.props.modalName ?? '')];

        if (filtersStateValue) {
            this.setState({
                filtersState: {
                    data: this.props.filters?.data.find(({ value }) => value === filtersStateValue),
                    value: filtersStateValue,
                } as S['filtersState'],
            });
        }

        if (this.props.validateAfterCreate) {
            this.setState({
                isValidating: true,
            });
            this.interval = setInterval(() => {
                if (this._formRef) {
                    this._onOk();
                    clearInterval(this.interval);
                }
            }, 500);
        }

        this._componentDidMount();
    }

    _componentDidMount = () => {};

    componentDidUpdate = (prevProps: P, prevState: S) => {
        if (this.props.updatingError && prevProps.updatingError !== this.props.updatingError) {
            this.scrollToTop();
        }

        if (prevState?.filtersState !== this.state?.filtersState) {
            if (this.props.modalName && this.state.filtersState) {
                this.props.updateModalFilters({
                    [this.props.modalName]: this.state.filtersState.value,
                });
            }
        }

        if (!this.isValidationError) {
            if (this.state.formValues && prevState.filtersState?.value !== this.state.filtersState?.value) {
                this._formRef?.validateFields((errors, values) => {
                    const errorsKeys = Object.keys(errors);
                    const newValues = this.state.formValues ?? this?._formRef?.getFieldsValue() ?? {};
                    errorsKeys.forEach((errorKey) => {
                        delete newValues[errorKey];
                    });
                    this._formRef?.setFieldsValue(newValues);
                });
            }
        } else {
            this.isValidationError = false;
        }

        this._componentDidUpdate(prevProps, prevState);
    };

    _componentDidUpdate = (prevProps: P, prevState: S) => {};

    onOk = (data: object, form?: WrappedFormUtils<any>) => {};

    onCancel = (isFieldsTouched: boolean) => {};

    getInitialValues = () => this.props.initialValues;

    getFormFields = () => {
        const { formFields } = this.props;

        if (formFields == null) {
            return undefined;
        }

        return this.getFilteredForms(formFields);
    };

    getFilteredForms = (formFields: FormFieldsGroup[], hideCustomFields: boolean = true): FormFieldsGroup[] => {
        const { data: filtersData, value: filtersValue } = this.state.filtersState ?? {};

        if (this.filteredFormFields && this.filterCurrentValue === filtersValue) {
            const formFieldsCount = formFields.reduce((acc, { fields }) => {
                return acc + fields.length;
            }, 0);
            const filteredFormFields = this.filteredFormFields.reduce((acc, { fields }) => {
                return acc + fields.length;
            }, 0);

            if (formFieldsCount === filteredFormFields) return this.filteredFormFields;
        }

        let filteredFormFields = _.cloneDeep(formFields);

        if (this.props.filters) {
            const filteredFormIds = filtersData?.fieldsIds;

            if (formFields && filteredFormIds && filteredFormIds.length > 0) {
                filteredFormFields = formFieldsGroupsWithFilteredFields(filteredFormFields, filteredFormIds, hideCustomFields);
            }
            this.filterCurrentValue = filtersValue;
        }

        this.filteredFormFields = filteredFormFields;
        return filteredFormFields;
    };

    onChangeForm = () => {
        const mergedFormValues = _.assign(this.state.formValues, this._formRef?.getFieldsValue() ?? {});

        if (this._formRef?.isFieldsTouched()) {
            this.isFieldsTouched = true;
        }

        this.setState({
            formValues: mergedFormValues,
        });
    };

    getError = () => {
        let error;
        if (this.props.updatingError) {
            error = (
                <>
                    <div>
                        <strong>{this.props.updatingError.title}</strong>
                    </div>
                    <div>{this.props.updatingError.message}</div>
                </>
            );
        }
        return error;
    };

    _onOk = async () => {
        this.isValidationError = false;
        if (this._formRef) {
            try {
                this.setState({
                    isValidating: true,
                });
                await this._formRef.validateFieldsAndScroll(
                    {
                        scroll: { offsetTop: 36 },
                    },
                    (err, values) => {
                        this.setState({
                            isValidating: false,
                        });
                        if (!err) {
                            this.onOk(values, this._formRef);
                        } else {
                            this.isValidationError = true;
                            if (!isErrorValidationFieldHidden(err, this.state.filtersState?.data.fieldsIds)) {
                                return;
                            }

                            this.openAllFields(() => {
                                this._formRef?.validateFieldsAndScroll({ scroll: { offsetTop: 36, alignWithTop: true } });
                            });
                        }
                    }
                );
            } catch (e) {
            } finally {
                if (this.state.isValidating) {
                    this.setState({
                        isValidating: false,
                    });
                }
            }
        }
    };

    _onCancel = () => {
        let fields: string[] = [];
        let formFields = this.getFormFields();
        if (formFields) {
            formFields.map((group) => group.fields.map((field) => fields.push(field.id)));
        }
        if (this._formRef) {
            this.onCancel(this.isFieldsTouched);
        }
    };

    getFormRef = (ref) => {
        if (ref && ref.props && ref.props.form) {
            this._formRef = ref.props.form;
            return this._formRef;
        }

        return null;
    };

    addField = (fieldName: string, index: number) => {};

    removeField = (fieldName: string, index: number) => {};

    setFiltersStateValue = (filtersState: ModalFormFiltersState, scrollFunction?: Function) => {
        this.setState({
            filtersState: {
                ...filtersState,
            },
        });

        const defaultScrollFunction = () => {
            this._formRef?.validateFieldsAndScroll({ scroll: { offsetTop: 36 } });
        };

        setTimeout(scrollFunction ?? defaultScrollFunction, 0);
    };

    scrollToTop = () => {
        let node = ReactDOM.findDOMNode(this);
        if (node instanceof Element && (node as Element).getElementsByClassName('ant-modal-wrap ')[0]) {
            (node as Element).getElementsByClassName('ant-modal-wrap ')[0].scrollTop = 0;
        }
    };

    openAllFields = (scrollFunction?: Function) => {
        const noneFiltersStateData = this.props.filters?.data.find(({ value }) => value === 'ALL');
        if (noneFiltersStateData == null) return;

        this.setFiltersStateValue(
            {
                value: 'ALL',
                data: noneFiltersStateData,
            } as ModalFormFiltersState,
            () => {
                scrollFunction?.() && this.scrollToTop();
            }
        );
    };

    render() {
        const error = this.getError(),
            initialValues = this.getInitialValues(),
            formFields = this.getFormFields(),
            { title, updating, filters } = this.props;
        const { filtersState, isValidating } = this.state;

        const filtersValue = filtersState?.value ?? '';
        const isFilterHasSubgroup = Boolean(this.props.filters?.data.find(({ value }) => filtersValue === value)?.subGroup);

        const saveButtonText = this.props.okButtonText
            ? this.props.okButtonText
            : this.props.editMode === false || !initialValues
            ? localize(LocalizationEnum.ASPECT__GLOBAL__ADD)
            : localize(LocalizationEnum.ASPECT__GLOBAL__SAVE);

        const isOkButtonDisabled = updating || isValidating;

        return (
            <Modal
                ref={this.modalRef}
                zIndex={this.props.zIndex}
                visible
                className={classNames('rr-modal', {
                    'fixed-header-height': isFilterHasSubgroup,
                })}
                width={this.props.width ?? 800}
                cancelButtonProps={{ className: 'rr-btn-default' }}
                title={
                    this.props.filters ? (
                        <ModalFormHeader
                            disabled={isOkButtonDisabled}
                            value={filtersValue}
                            setValue={this.setFiltersStateValue}
                            titleNode={title}
                            {...this.props.filters}
                        />
                    ) : (
                        title
                    )
                }
                onOk={this._onOk}
                onCancel={this._onCancel}
                okText={
                    this.props.okButtonText
                        ? this.props.okButtonText
                        : this.props.editMode === false || !initialValues
                        ? localize(LocalizationEnum.ASPECT__GLOBAL__ADD)
                        : localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)
                }
                cancelText={localize(LocalizationEnum.ASPECT__GLOBAL__CANCEL)}
                closable={!!title}
                confirmLoading={updating}
                bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
                footer={
                    <div className={'rr-modal-footer'}>
                        <div className={'hint-container'}>
                            {this.props.filters && filtersState?.value !== 'ALL' ? (
                                <>
                                    <Icon className={'icon'} component={IconEyeSlashSolid} />
                                    <span>Есть скрытые поля.</span>
                                    <a
                                        className={classNames('link', {
                                            disabled: updating,
                                        })}
                                        onClick={() => {
                                            this.openAllFields();
                                        }}
                                    >
                                        Показать всё
                                    </a>
                                </>
                            ) : undefined}
                        </div>
                        <div>
                            <Button type={'link'} onClick={this._onCancel} disabled={updating}>
                                {localize(LocalizationEnum.ASPECT__GLOBAL__CANCEL)}
                            </Button>

                            <Button type={'primary'} onClick={this._onOk} disabled={isOkButtonDisabled}>
                                {saveButtonText}
                            </Button>
                        </div>
                    </div>
                }
            >
                <Spin spinning={updating}>
                    <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                        {error ? (
                            <Alert
                                message={localize(LocalizationEnum.ASPECT__GLOBAL__ERROR)}
                                description={error}
                                type="error"
                                showIcon
                                closable
                                style={{ marginTop: 32 }}
                            />
                        ) : null}
                        <DynamicForm
                            addField={this.addField}
                            removeField={this.removeField}
                            validateInitialValues={this.props.validateAfterCreate}
                            wrappedComponentRef={this.getFormRef}
                            data={formFields}
                            initialValues={initialValues}
                            onChange={this.onChangeForm}
                        />
                        {filters && (
                            <Bookmark
                                position={'top'}
                                modalRef={this.modalRef}
                                filterValue={filtersState?.value}
                                formWidth={this.props.width ?? 800}
                            >
                                {(visible) => (
                                    <ModalFormFilters
                                        disabled={isOkButtonDisabled}
                                        value={filtersValue}
                                        setValue={this.setFiltersStateValue}
                                        data={filters.data}
                                        defaultValue={filters?.defaultValue}
                                        style={{
                                            pointerEvents: !visible ? 'none' : undefined,
                                        }}
                                    />
                                )}
                            </Bookmark>
                        )}
                        <Bookmark
                            position={'bottom'}
                            modalRef={this.modalRef}
                            filterValue={filtersState?.value}
                            formWidth={this.props.width ?? 800}
                        >
                            {(visible) => (
                                <Button type={'primary'} onClick={this._onOk} disabled={!visible || isOkButtonDisabled}>
                                    {saveButtonText}
                                </Button>
                            )}
                        </Bookmark>
                    </div>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = { updateModalFilters };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
