import { AxiosError } from 'axios';
import { serverApi } from '../../../../server';
import { axiosBaseQuery } from '../../../../store/rtqQueryApi';
import { SuggestAddressesArgs } from './types.d';
import { createApi } from '@reduxjs/toolkit/query/react';

export const getAddressSuggestion = async ({ businessAccountId, addressQuery }: SuggestAddressesArgs) => {
    try {
        const data = await serverApi.suggestAddresses(businessAccountId, addressQuery);
        return { data: data.data };
    } catch (error) {
        return {
            error: error as AxiosError,
        };
    }
};

export const addressApi = createApi({
    reducerPath: 'addressApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['AddressSuggest'],
    endpoints: (builder) => ({
        suggestAddresses: builder.query({
            queryFn: getAddressSuggestion,
            providesTags: ['AddressSuggest'],
        }),
    }),
});

export const { useSuggestAddressesQuery, useLazySuggestAddressesQuery } = addressApi;
