import {Switch as SwitchComponent} from "antd";
import {SwitchProps as SwitchComponentProps} from "antd/lib/switch";
import React from "react";

export interface SwitchWithLabelProps extends SwitchComponentProps {
    label: string
}

const SwitchWithLabelFC = ({label, ...rest}:SwitchWithLabelProps) => {
    return (
        <label>
            <div style={{display: 'flex', alignItems: 'center', columnGap: 20}}>
                <SwitchComponent {...rest} /><span>{label}</span>
            </div>
        </label>
    )
}

export class SwitchWithLabel extends React.PureComponent<SwitchWithLabelProps> {
    render() {
        return <SwitchWithLabelFC {...this.props} />;
    }
}