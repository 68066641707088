import { FormFieldsGroup, FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import { FiltersDateRangePicker } from '../../../../components/datePicker/FiltersDateRangePicker';
import React from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { LocalizationEnum } from '../../../../localization';
import { InventoryMovementsUtils } from '../utils/inventoryMovementsUtils';
import { FormUtils, TypedFormField } from '../../../../core/utils/formUtils';
import { ListInventoryMovementsParams } from '../api/inventoryMovementsApi';
import { InventoryMovementIcon } from '../components/inventoryMovementIcon';
import { InstanceSelect, InstanceSelectProps } from '../../../../components/v2/select/instanceSelect/instanceSelect';
import { ProductSelect, ProductSelectProps } from '../../../../components/v2/select/productSelect/productSelect';
import {
    ProductVariantSelect,
    ProductVariantSelectProps,
} from '../../../../components/v2/select/productVariantSelect/productVariantSelect';
import { RenterSelect } from '../../../../components/v2/select/renterSelect/renterSelect';
import { ProjectSelect, ProjectSelectProps } from '../../../../components/v2/select/projectSelect/projectSelect';
import { SimpleSelect, SimpleSelectProps } from '../../../../components/v2/select/simpleSelect/simpleSelect';
import { InstanceTrackingTypeCodeEnum, ProductInfoRead, ProductRecord } from '../../../../server';
import { SliderRangeWithManualInput } from '../../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import { createHiddenField } from '../../../../components/modalForm/utils';

export const inventoryMovementsListFilters: FormFieldsGroup[] = FormUtils.typedFormFields<ListInventoryMovementsParams>([
    {
        fields: [
            createHiddenField('authorId') as TypedFormField<ListInventoryMovementsParams>,
            createHiddenField('screenLeft') as TypedFormField<ListInventoryMovementsParams>,
            {
                id: 'parentEntityType' as any,
                type: FormItemType.Hidden,
            },
            {
                id: 'parentProductId' as any,
                type: FormItemType.Hidden,
            },
            {
                id: 'product' as any,
                type: FormItemType.Hidden,
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__TYPE,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'eventType',
                type: FormItemType.Component,
                style: { minWidth: 120, maxWidth: 340 },
                className: 'rr-select-gray',
                component: SimpleSelect,
                componentProps: (form: WrappedFormUtils): SimpleSelectProps => ({
                    values: InventoryMovementsUtils.getAvailableEventTypeCodes().map((code) => {
                        return {
                            id: code,
                            icon: <InventoryMovementIcon code={code} />,
                            label: InventoryMovementsUtils.getEventTypeByCode(code),
                        };
                    }),
                    multiple: true,
                    showSearch: false,
                    dropdownMatchSelectWidth: false
                }),
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FILTERS__PRODUCT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'productId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: ProductSelect,
                componentProps: (form: WrappedFormUtils): ProductSelectProps => ({
                    onDataChange: (data) => {
                        form.setFieldsValue({ product: data && data[0] ? data[0] : undefined });
                    },
                }),
                onChange: async (value: any, form) => {
                    form.setFieldsValue({ variantId: undefined });
                    form.setFieldsValue({ instanceId: undefined });
                },
                visible: (getFieldValue, form) => {
                    return form?.getFieldValue('parentEntityType') === 'history';
                },
            },
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__VARIANT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'variantId',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: ProductVariantSelect,
                componentProps: (form: WrappedFormUtils): ProductVariantSelectProps => {
                    const product: ProductRecord | ProductInfoRead = form.getFieldValue('product');
                    return {
                        productId: product.id,
                    };
                },
                onChange: (value: any, form) => {
                    if (value) form.setFieldsValue({ instanceId: undefined });
                },
                visible: (getFieldValue) => {
                    const product: ProductRecord | ProductInfoRead | undefined = getFieldValue('product');

                    return (
                        product != null &&
                        (product.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK ||
                            product.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED)
                    );
                },
            },
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__INSTANCE,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'instanceId',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: InstanceSelect,
                componentProps: (form: WrappedFormUtils): InstanceSelectProps => {
                    let productId = form.getFieldValue('parentProductId') || form.getFieldValue('productId');
                    const variantId = form.getFieldValue('variantId');
                    return {
                        productId: productId,
                        variantId: variantId,
                    };
                },
                visible: (getFieldValue) => {
                    const product: ProductRecord | undefined = getFieldValue('product');
                    return (
                        product != null &&
                        (product.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                            product.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED)
                    );
                },
            },
            {
                label: 'Дата с',
                placeholder: ['Любая дата', 'Любая дата'],
                id: 'eventDate',
                type: FormItemType.Component,
                className: 'rr-select-gray',
                component: FiltersDateRangePicker,
            },
            {
                label: 'Итоговая сумма',
                id: 'totalSum',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: {
                    formatStyle: 'currency',
                    min: 0,
                    max: 1000000,
                    step: 50,
                },
            },
            {
                label: 'Арендатор',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'counterpartyId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: RenterSelect,
                onChange: (value: any, form) => {
                    form.setFieldsValue({ projectId: undefined });
                },
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__PROJECT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'projectId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: ProjectSelect,
                componentProps: (form): ProjectSelectProps => {
                    let renterId = form.getFieldValue('counterpartyId');
                    return {
                        filters: {
                            renterId: renterId as any,
                        },
                    };
                },
            },
            {
                label: 'Ответственен арендатор',
                id: 'causedByRenter',
                placeholder: 'Не выбрано',
                type: FormItemType.Select,
                style: { minWidth: 150 },
                values: () => {
                    return [
                        { name: 'Да', value: true },
                        { name: 'Нет', value: false },
                    ];
                },
            },
            {
                label: 'Выведен из строя',
                id: 'outOfOrder',
                placeholder: 'Не выбрано',
                type: FormItemType.Select,
                style: { minWidth: 150 },
                values: () => {
                    return [
                        { name: 'Да', value: true },
                        { name: 'Нет', value: false },
                    ];
                },
            },
        ],
    },
]);
