import React, { CSSProperties } from 'react';
import { Col, Icon, Row, Skeleton } from 'antd';
import { IRootState } from '../../../../shared/reducers';
import { connect } from 'react-redux';
import {
    BusinessAccountPermissionCodeEnum,
    CurrencyCodeEnum,
    GlobalPermissionCodeEnum,
    PricingLadderStepObj,
    PricingSchemeInfoRead,
    PricingSchemeRecord,
    PricingSchemeTypeCodeEnum,
    serverApi,
} from '../../../../server';
import { push } from 'connected-react-router';
import { LocalizationEnum, localize, localizeIntl } from '../../../../localization';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { showNotification } from '../../../../components/notification/showNotification';
import { deleteEntity } from './reducers/pricingScheme.reducer';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { loadEntities } from './reducers/pricingSchemes.reducer';
import { PopoverHeader } from '../../../../components/gridRowPopover/PopoverHeader';
import { Spin } from '../../../../components';
import FormValue from '../../../../components/formValue/FormValue';
import './pricingSchemePopoverContent.less';
import { BaseRecordData } from './pricingSchemePopover';
import { IconLongArrowAltRightSolid } from '../../../../components/icons';
import sortBy from 'lodash/sortBy';
import { canUpdateFinancialData } from '../../../../shared/util/permissionUtils';
import { getPricingSchemeRoundingValues, PricingSchemeUtils } from '../../../../core/utils/pricingSchemeUtils';

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {
    record: BaseRecordData;
    closeCb: () => void;
    onDataChangedCb: () => void;
    style?: CSSProperties;
}

interface IState {
    entity?: PricingSchemeInfoRead;
}

class PricingSchemePopoverContent extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        let res = await serverApi.getPricingSchemeById(this.props.businessAccountId, this.props.record.id);

        this.setState({ entity: res.data }, () => {
            this.props.onDataChangedCb();
        });
    }

    onLinkClicked = () => {
        this.props.closeCb();
    };

    onAction = (item: PricingSchemeRecord, action: string) => {
        this.onLinkClicked();
        if (action === 'edit') {
            this.props.push(`/${this.props.businessAccountId}/settings/pricingSchemes/${item.id}/edit`);
        } else if (action === 'delete') {
            showConfirm(
                this.props.intl,
                localizeIntl(this.props.intl, LocalizationEnum.PAGE__SETTINGS__PRICING_SCHEMES__DELETE_CONFIRM_MESSAGE),
                undefined,
                async () => {
                    let result = await this.props.deleteEntity(this.props.businessAccountId, item.id, item.businessVersion);
                    if (!(result instanceof Error)) {
                        showNotification('success', 'Ценовая схема удалена');
                        this.loadEntities();
                    } else {
                        // TODO Мб норм сообщение об ошибке норм выводить
                        showNotification('error', localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
                    }
                }
            );
        }
    };

    loadEntities = async () => {
        let res = await this.props.loadEntities(this.props.businessAccountId);
        if (res instanceof Error) {
            showNotification(
                'error',
                localizeIntl(this.props.intl, LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__LOAD_ENTITIES_ERROR_MESSAGE)
            );
        }
    };

    renderSteps = (steps: Array<PricingLadderStepObj>) => {
        return (
            <>
                {steps.map((step, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', height: 44 }}>
                        <div
                            style={{
                                width: 140,
                                whiteSpace: 'nowrap',
                                marginLeft: 0,
                            }}
                        >
                            {`${index === 0 ? 'Н' : 'н'}ачиная с`}{' '}
                            <span
                                className={'rr-value'}
                                style={{
                                    marginRight: 5,
                                    marginLeft: 5,
                                }}
                            >
                                {step.effectsAfterShiftCount}
                            </span>{' '}
                            {localizeIntl(this.props.intl, LocalizationEnum.ASPECT__PLURAL__FROM_SHIFTS, undefined, {
                                value: step.effectsAfterShiftCount,
                            })}
                        </div>
                        <div className={'rr-pricingScheme-step-arrow'}>
                            <Icon component={IconLongArrowAltRightSolid} />
                        </div>
                        <div style={{ width: 80 }}>
                            <span className={'rr-value'}>{step.extraDiscount}%</span>
                        </div>
                        <div>скидка к базовой цене{index !== steps.length - 1 ? ',' : ''}</div>
                    </div>
                ))}
            </>
        );
    };

    render() {
        let { record } = this.props;
        let loading = !this.state.entity || this.state.entity.id !== this.props.record.id;
        let steps = sortBy(this.state.entity?.steps || [], 'effectsAfterShiftCount');
        const { typeTitle } = PricingSchemeUtils.getPricingSchemeData(this.state.entity?.typeCode);

        const priceHeader = this.state.entity != null ? `${typeTitle} схема` : 'Уровни цен';
        const priceSubHeader =
            this.state.entity != null
                ? this.state.entity.typeCode === PricingSchemeTypeCodeEnum.LADDER
                    ? 'Общий уровень скидки для всех смен при достижении шага'
                    : this.state.entity.typeCode === PricingSchemeTypeCodeEnum.MULTILEVEL
                    ? 'Отдельный уровень скидки для смен каждого шага'
                    : undefined
                : undefined;

        const entity = {
            ...record,
            typeCode: this.state.entity?.typeCode,
        };

        const rounding =
            this.state.entity?.roundingLevel && this.state.entity?.roundingDirection
                ? `${this.state.entity.roundingLevel}-${this.state.entity.roundingDirection}`
                : undefined;

        return (
            <div className={'pricingScheme-popover-content'} style={this.props.style}>
                <PopoverHeader
                    businessAccountId={this.props.businessAccountId}
                    title={record.name}
                    closeCb={this.props.closeCb}
                    entityType={'pricingScheme'}
                    entity={entity}
                    onAction={this.onAction}
                    closeElementPopup={() => {}}
                    hideActionsButton={
                        !(
                            this.props.userPermissions.includes(GlobalPermissionCodeEnum.UPDATESYSTEMOPTIONS) ||
                            this.props.userPermissions.includes(BusinessAccountPermissionCodeEnum.UPDATESYSTEMOPTIONS) ||
                            canUpdateFinancialData(this.props.userPermissions)
                        )
                    }
                />

                <Spin spinning={loading}>
                    <div>
                        {!loading && this.state.entity ? (
                            <Row>
                                <Col
                                    span={24}
                                    className="rr-form-item"
                                    style={{ padding: 30, paddingTop: 24, paddingBottom: 0, marginBottom: 30 }}
                                >
                                    <div className="rr-label" style={{ fontSize: 20 }}>
                                        {priceHeader}
                                    </div>
                                    {priceSubHeader ? (
                                        <div style={{ fontSize: 12, color: '#bcbcbc', marginBottom: 12 }}>{priceSubHeader}</div>
                                    ) : undefined}
                                    <div>
                                        {steps.length > 0 ? (
                                            this.renderSteps(steps)
                                        ) : (
                                            <span className="rr-label" style={{ fontSize: 18, color: '#aaa' }}>
                                                Уровни скидок не указаны
                                            </span>
                                        )}
                                    </div>
                                </Col>
                                <Col span={24} className="rr-form-item" style={{ borderTop: '1px solid #EAEAEA', padding: 30 }}>
                                    <div className="rr-label">Округление до</div>
                                    <div className={'rr-text'} style={{ marginBottom: 20 }}>
                                        <FormValue
                                            value={
                                                rounding
                                                    ? getPricingSchemeRoundingValues(this.props.currency || CurrencyCodeEnum.RUB).find(
                                                          (option) => option.value === rounding
                                                      )?.name
                                                    : undefined
                                            }
                                            emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__N)}
                                        />
                                    </div>
                                    <div className="rr-label">Комментарий</div>
                                    <div className={'rr-text'}>
                                        <FormValue
                                            value={this.state.entity.comment}
                                            emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <div style={{ margin: 30 }}>
                                <Skeleton active={true}></Skeleton>
                            </div>
                        )}
                    </div>
                </Spin>
            </div>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        businessAccountId: storeState.system.businessAccountId,
        location: storeState.router.location,
        userPermissions: storeState.permissions.permissions,
        currency: storeState.businessAccountPreferences.preferences?.baseCurrency,
    };
};

const mapDispatchToProps = { push, deleteEntity, loadEntities };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PricingSchemePopoverContent));
