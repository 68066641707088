import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../store/rtqQueryApi';
import { BulkCategoryUpdateOrderItem, CategoryTypeCodeEnum, serverApi } from '../../../../server';
import { AxiosError } from 'axios';

export const categoriesApi = createApi({
    reducerPath: 'categoriesApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        bulkUpdateCategoriesOrder: builder.mutation({
            queryFn: async ({
                businessAccountId,
                typeCode,
                categories,
            }: {
                businessAccountId: number;
                typeCode: CategoryTypeCodeEnum;
                categories: BulkCategoryUpdateOrderItem[];
            }) => {
                try {
                    const data = await serverApi.bulkUpdateCategoriesOrder(businessAccountId, typeCode, { rootCategories: categories });
                    return { data };
                } catch (error) {
                    return {
                        error: error as AxiosError,
                    };
                }
            },
        }),
    }),
});

export const { useBulkUpdateCategoriesOrderMutation } = categoriesApi;
