import {KitAddElementPopover} from "../kit-add-element-popover/KitAddElementPopover";
import {Button, Icon, Popover} from "antd";
import {
    IconControlPointDuplicateBlack,
    IconEllipsisHSolid,
    IconSignInAltSolidCustom
} from "../../../../../components/icons";
import React, {useState} from "react";
import {copyKit, OperationElement} from "../../reducers/operationForm.reducer";
import {useAppDispatch, useAppSelector} from "../../../../../store/hooks";
import {canAddNewProductsToOperation2} from "../../utils/utils";

interface OperationFormKitAdditionalActionsPopoverProps {
    rowData: OperationElement;
}

export const OperationFormKitAdditionalActionsPopover = (props: OperationFormKitAdditionalActionsPopoverProps) => {

    const {rowData} = props;
    const [visible, setVisible] = useState(false);
    const dispatch = useAppDispatch();
    const operationForm = useAppSelector((state)=>state.operationForm);
    const canCopy = canAddNewProductsToOperation2(operationForm, rowData);

    return (
        <Popover
            arrowPointAtCenter={true}
            overlayClassName={'rr-actions-popover'}
            placement="bottomRight"
            trigger={'click'}
            overlayStyle={{width: 300}}
            onVisibleChange={(visible) => {
                setVisible(visible);
            }}
            visible={visible}
            content={
                <>
                    <div className={'rr-grid-actions-popover-header1'}></div>
                    <div className={'rr-grid-actions-popover-content'}>
                        <KitAddElementPopover element={rowData}>
                            <Button className={'ant-btn-page-actions'}>
                                <Icon
                                    className={'rr-grid-table-move-from-to-kit-icon'}
                                    style={{cursor: 'pointer'}}
                                    component={IconSignInAltSolidCustom}
                                />
                                Поместить в набор
                            </Button>
                        </KitAddElementPopover>
                        {
                            canCopy && (
                                <Button className={'ant-btn-page-actions'} onClick={() => {
                                    setVisible(false);
                                    dispatch(copyKit(rowData.id));
                                }}>
                                    <Icon
                                        component={IconControlPointDuplicateBlack}
                                        className={'rr-grid-table-plus-icon'}
                                    />
                                    Создать на основе
                                </Button>
                            )
                        }
                    </div>
                </>
            }
        >
            <span>
                <Icon
                    className={'rr-grid-table-edit-icon'}
                    style={
                        rowData.disabled
                            ? {cursor: 'default', fontSize: 30, opacity: 0.4}
                            : {cursor: 'pointer', fontSize: 30}
                    }
                    component={IconEllipsisHSolid}
                />
            </span>
        </Popover>
    );
}
