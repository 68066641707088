import React, {ReactNode, useState} from "react";
import {OperationElement} from "../../../reducers/operationForm.reducer";
import {Popover} from "../../../../../../components/popover/Popover";
import {InlineInstancesSelectPopoverContent} from "./inlineInstancesSelectPopoverContent";

export interface InlineInstancesSelectPopoverProps {
    elementRecord: OperationElement;
    children: ReactNode;
}

export const InlineInstancesSelectPopover = ({elementRecord, children}: InlineInstancesSelectPopoverProps) => {
    const [visible, setVisible] = useState(false);

    return (
        <Popover
            visible={visible}
            onVisibleChange={setVisible}
            headerClassName={'rr-grid-actions-popover-header-container'}
            overlayClassName={'rr-operationForm-kit-add-element-popover rr-operationForm-kit-add-element-popover1'}
            destroyTooltipOnHide
            header={<InlineInstancesSelectPopoverContent elementRecord={elementRecord} onSuccess={()=>setVisible(false)} />}
        >
            {children}
        </Popover>
    );
};
