import { FormFieldsGroup, FormItemType, IFormField } from '../../../../../../components/dynamicForm/DynamicForm';
import { FormUtils, TypedFormField, WrappedFormUtilsTyped } from '../../../../../../core/utils/formUtils';
import { ContactLinkObjWrite, LocationInfoCreate } from '../../../../../../server';
import { LocalizationEnum } from '../../../../../../localization';
import debounce from 'lodash/debounce';
import { validateField } from '../../../../../../shared/util/validateField';
import { ContactsEditor } from '../../../../../../components/contactsEditor/contactsEditor';
import { createHiddenField } from '../../../../../../components/modalForm/utils';
import CoordinatesInput from '../components/CoordinatesInput';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Coords } from '../../../../../../components/addressInput/addressInput';
import { LatLngTuple } from 'leaflet';

export const shortNameField = {
    label: LocalizationEnum.ASPECT__GRID__COLUMN__NAME,
    id: 'shortName' as const,
    type: FormItemType.String,
    required: true,
    maxLength: 60,
    validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtilsTyped<FormValues>) => {
        const ignoreCurrentId = form.getFieldValue('isEdit') ? (form.getFieldValue('id') as number) : undefined;
        validateField('location', fieldName, value, undefined, ignoreCurrentId, 'Наименование уже используется', cb);
    }, 500),
} satisfies IFormField;

export const addressField = {
    label: 'Адрес',
    id: 'address' as const,
    type: FormItemType.Address,
    required: true,
    maxLength: 500,
    componentProps: (form: WrappedFormUtils) => ({
        onSuggestChoice: (coords: Coords) => form.setFieldsValue({ suggestedCoords: coords }),
    }),
} satisfies IFormField;

interface FormValues extends LocationInfoCreate {
    isEdit?: boolean;
    coordinates: LatLngTuple;
    suggestedCoords: [number | undefined, number | undefined];
    latitudeTouched: boolean;
    longitudeTouched: boolean;
}

export const formFields: FormFieldsGroup[] = FormUtils.typedFormFields<FormValues>([
    {
        fields: [createHiddenField('id') as TypedFormField<FormValues>, createHiddenField('5') as TypedFormField<FormValues>],
    },
    {
        fields: [shortNameField, addressField],
    },
    {
        fields: [
            {
                id: 'suggestedCoords',
                type: FormItemType.Hidden,
            },
            {
                id: 'latitudeTouched',
                type: FormItemType.Hidden,
            },
            {
                id: 'longitudeTouched',
                type: FormItemType.Hidden,
            },
            {
                id: 'coordinates',
                type: FormItemType.Component,
                component: CoordinatesInput,
                componentProps: (form: WrappedFormUtils) => {
                    const address = form.getFieldValue('address') as string | undefined;
                    const coordinates = form.getFieldValue('suggestedCoords') as string | undefined;
                    const latitudeTouched = form.getFieldValue('latitudeTouched');
                    const longitudeTouched = form.getFieldValue('longitudeTouched');
                    return { address, coordinates, latitudeTouched, longitudeTouched };
                },
                validateInitialValue: true,

                onChange(value, form) {
                    form.setFieldsValue({
                        latitude: value[0],
                        longitude: value[1],
                        latitudeTouched: true,
                        longitudeTouched: true,
                    });
                },
                validationFunction(fieldName, value, cb, form) {
                    form.setFieldsValue({
                        latitudeTouched: true,
                        longitudeTouched: true,
                    });
                    cb();
                },
            },
            {
                label: 'Широта',
                id: 'latitude',
                type: FormItemType.Hidden,
                defaultValue: '',
                required: true,
                validationFunction(fieldName, value, cb, form) {
                    cb(value > 90 ? 'Недопустимое значение' : undefined);
                },
            },
            {
                label: 'Долгота',
                id: 'longitude',
                type: FormItemType.Hidden,
                defaultValue: '',
                required: true,
                validationFunction(fieldName, value, cb, form) {
                    cb(value > 180 ? 'Недопустимое значение' : undefined);
                },
            },
        ],
    },
    {
        fields: [
            {
                id: 'contacts',
                type: FormItemType.Component,
                component: ContactsEditor,
                componentProps: {
                    selectColumns: [
                        { key: 'personFullName', width: 240 },
                        { key: 'phoneNumber', width: 150 },
                        { key: 'email', width: 200, secondary: true },
                    ],
                },
                validationRules: [
                    {
                        validator: (rule, value: ContactLinkObjWrite[] | undefined, cb) => {
                            if (value && value.some((item) => item.contactId === undefined && item.position)) cb('Контакт не выбран');
                            else cb();
                        },
                    },
                ],
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
]);
