import React from 'react';
import { WorkPlanningsRecord, WorkPlanningsTransitionCodeEnum } from '../../../../../server';
import { WorkPlanningsStateBadge } from '../../components/WorkPlanningsStateBadge/WorkPlanningsStateBadge';
import { tblColumns, TblColumnType } from '../../../../../core/tblColumns/tblColumns';
import { workPlanningActionsMap, WorkPlanningActionType } from '../../data/workPlanningsData';

import { isActionAvailable } from '../../utils/isActionAvailable';

export const workPlanningsColumns = tblColumns<WorkPlanningsRecord, WorkPlanningActionType>([
    {
        type: TblColumnType.ENTITY_NAME,
        title: 'Наименование',
        dataIndex: 'name',
        entityType: 'workPlanning',
        getData: (row) => ({
            name: row.name,
        }),
    },
    {
        type: TblColumnType.CLICK,
        title: 'Специальность',
        dataIndex: 'professionShortName',
    },

    {
        type: TblColumnType.COUNT,
        title: 'Кол-во работников',
        dataIndex: 'numberOfCrew',
        minWidth: 130,
    },

    {
        type: TblColumnType.COUNT,
        title: 'Кол-во вакансий',
        dataIndex: 'numberOfVacancies',
        minWidth: 130,
    },

    {
        type: TblColumnType.STATUS,
        title: 'Статус',
        dataIndex: 'stateCode',
        minWidth: 150,
        render: (row) => <WorkPlanningsStateBadge type={row.stateCode} style={{ width: '100%' }} />,
    },

    {
        type: TblColumnType.PRICE,
        title: 'Цена смены работника',
        dataIndex: 'effectivePrice',
        minWidth: 150,
        getData: (row) => ({
            price: row.effectivePrice,
            pricingSchemeData: row.effectivePricingScheme
                ? {
                      pricingScheme: row.effectivePricingScheme,
                      shiftCount: row.shiftCount,
                      basePrice: row.basePriceAtTheTimeOfCreation,
                  }
                : undefined,
        }),
    },

    {
        type: TblColumnType.DATE,
        title: 'Начало',
        dataIndex: 'startDate',
        minWidth: 140,
    },

    {
        type: TblColumnType.DATE,
        title: 'Завершение',
        dataIndex: 'endDate',
        minWidth: 140,
    },

    {
        type: TblColumnType.SHIFT_COUNT,
        title: 'Смены к оплате',
        dataIndex: 'shiftCount',
        minWidth: 120,
    },

    {
        type: TblColumnType.MONEY,
        title: 'Итоговая стоимость',
        dataIndex: 'finalTotalPrice',
        minWidth: 150,
    },

    {
        type: TblColumnType.LAST_UPDATE_DATE,
        title: 'Дата изменения',
        dataIndex: 'lastUpdateDate',
        minWidth: 150,
    },
    {
        type: TblColumnType.ACTIONS,
        id: 'actions',
        actionsDataMap: workPlanningActionsMap,
        getData: (row) => ({
            actions: [
                WorkPlanningActionType.edit,
                isActionAvailable(row, WorkPlanningsTransitionCodeEnum.TODRAFT, WorkPlanningActionType.toDraft),
                isActionAvailable(row, WorkPlanningsTransitionCodeEnum.PLAN, WorkPlanningActionType.plan),
                isActionAvailable(row, WorkPlanningsTransitionCodeEnum.START, WorkPlanningActionType.start),
                isActionAvailable(row, WorkPlanningsTransitionCodeEnum.FINISH, WorkPlanningActionType.finish),
                isActionAvailable(row, WorkPlanningsTransitionCodeEnum.CANCEL, WorkPlanningActionType.cancel),
            ],
        }),
    },
]);
