import React, {FC, useState} from 'react';
import {Input, Select} from "antd";
import {SocialNetworkLinkAbstractInfo, SocialNetworkTypeCodeEnum} from "../../../../../../../../server";
import {CreateEntityButton} from "../../../../../../../../components/buttons/EntityButton/v1/CreateEntityButton";
import {CustomSelect} from "../../../../../../../../components";
import {SelectProps} from "antd/lib/select";
import {InputProps} from "antd/lib/input/Input";
import {ButtonProps} from "antd/lib/button/button";
import {SystemIcon, SystemIconType} from "../../../../../../../../components/v2/systemIcon/systemIcon";
import {ContactMaps} from "../../../utils/maps";
import "./socialNetworksEditor.less";

interface SiteUrlInputProps {
    value?: SocialNetworkLinkAbstractInfo[];
    onChange: (value: SocialNetworkLinkAbstractInfo[]) => void;
}

const SOCIAL_NET_REGEX_TG = /(?:https?:)?(\/\/)?(?:t(?:elegram)?\.me|telegram\.org)\/(?<username>[a-z0-9\_]{5,32})\/?/;
const SOCIAL_NET_REGEX_VK = /^(?:https?:)?(\/\/)?(?:www\.)?(?:vk\.com)\/(?<username>[a-z0-9A-Z\_\-]{1,32})\/?$/;
const SOCIAL_NET_REGEX_INST = /(?:https?:)?(\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/(?<username>[A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/;
const SOCIAL_NET_REGEX_FB = /(?:https?:)?(\/\/)?(?:www\.)?(?:facebook|fb)\.com\/(?<username>(?![A-z]+\.php)(?!marketplace|gaming|watch|me|messages|help|search|groups)[A-z0-9_\-\.]+)\/?/;
const SITE_REGEX = /^(?<username>((?:https?:)?(\/\/)?(?:www\.)?([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))))$/;///(?<username>(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}))/;

const regexMap: Record<SocialNetworkTypeCodeEnum, RegExp> = {
    [SocialNetworkTypeCodeEnum.TELEGRAM]: SOCIAL_NET_REGEX_TG,
    [SocialNetworkTypeCodeEnum.VK]: SOCIAL_NET_REGEX_VK,
    [SocialNetworkTypeCodeEnum.INSTAGRAM]: SOCIAL_NET_REGEX_INST,
    [SocialNetworkTypeCodeEnum.FACEBOOK]: SOCIAL_NET_REGEX_FB,
    [SocialNetworkTypeCodeEnum.INTERNETPAGE]: SITE_REGEX,
}

/*
http://www.fb.com/vasia +
https://www.fb.com/vasia +
http://fb.com/vasia +
https://fb.com/vasia +
//fb.com/vasia +
www.fb.com/vasia +
fb.com/vasia +

http://www.vk.com/__vasia +
https://www.vk.com/__vasia +
http://vk.com/__vasia +
https://vk.com/__vasia +
//vk.com/__vasia +
www.vk.com/__vasia +
vk.com/__vasia +

http://www.t.me/__vasia +
https://www.t.me/__vasia +
http://t.me/__vasia +
https://t.me/__vasia +
//t.me/__vasia +
www.t.me/__vasia +
t.me/__vasia +

http://www.instagram.com/__vasia +
https://www.instagram.com/__vasia +
http://instagram.com/__vasia +
https://instagram.com/__vasia +
//instagram.com/__vasia +
www.instagram.com/__vasia +
instagram.com/__vasia +
* */

const matchLink = (value: string) => {
    let link: SocialNetworkLinkAbstractInfo | undefined;
    for (let key in regexMap) {
        if (!link) {
            const match = value.match(regexMap[key]);
            if (match) {
                link = {
                    type: key as SocialNetworkTypeCodeEnum,
                    value: match.groups?.['username'] || ''
                }
            }
        }
    }
    return link;
}

export const SocialNetworksEditor: FC<SiteUrlInputProps> = (props) => {
    const [links, setLinks] = useState<SocialNetworkLinkAbstractInfo[]>(props.value || []);
    const [inputValue, setInputValue] = useState('');
    const [currentLink, setCurrentLink] = useState<SocialNetworkLinkAbstractInfo | undefined>();
    const gap = 16;

    const addLink = () => {
        if (currentLink) {
            const newLinks = [currentLink, ...links];
            setLinks(newLinks);
            props.onChange(newLinks);
            setInputValue('');
            setCurrentLink(undefined);
        }
    };

    return (
        <div className={'rr-contactEdit-links'} style={{display: 'flex', flexDirection: 'column', gap: 18}}>
            <div>
                <div style={{display: 'flex', gap}}>
                    <Input onChange={(e) => {
                        const value = e.currentTarget.value;
                        setInputValue(value);
                        const link = matchLink(value);
                        setCurrentLink(link);
                    }} onPressEnter={() => {
                        addLink();
                    }} value={inputValue}/>
                    <CreateEntityButton disabled={!Boolean(currentLink)} onClick={() => {
                        addLink();
                    }}/>
                </div>
                <div className="ant-form-extra">
                    Пример: https://t.me/my_account_name
                </div>
            </div>
            {
                links.map((item, index) => {

                    const onSelectChange: SelectProps['onChange'] = (value) => {
                        const newLinks = [...links];
                        newLinks[index].type = value as SocialNetworkTypeCodeEnum;
                        setLinks(newLinks);
                        props.onChange(newLinks);
                    };

                    const onInputChange: InputProps['onChange'] = (e) => {
                        const newLinks = [...links];
                        const value = e.currentTarget.value;
                        newLinks[index] = {type: newLinks[index].type, value};
                        setLinks(newLinks);
                        props.onChange(newLinks);
                    };

                    const onButtonClick: ButtonProps['onClick'] = (e) => {
                        const newLinks = [...links];
                        newLinks.splice(index, 1);
                        setLinks(newLinks);
                        props.onChange(newLinks);
                    };

                    return (
                        <div style={{display: 'flex', gap}}>
                            <CustomSelect disabled={true} onChange={onSelectChange} value={item.type} style={{minWidth: 200}}>
                                <Select.Option
                                    value={SocialNetworkTypeCodeEnum.TELEGRAM}>{ContactMaps.socialNetworksMap[SocialNetworkTypeCodeEnum.TELEGRAM]}</Select.Option>
                                <Select.Option value={SocialNetworkTypeCodeEnum.VK}>{ContactMaps.socialNetworksMap[SocialNetworkTypeCodeEnum.VK]}</Select.Option>
                                <Select.Option
                                    value={SocialNetworkTypeCodeEnum.FACEBOOK}>{ContactMaps.socialNetworksMap[SocialNetworkTypeCodeEnum.FACEBOOK]}</Select.Option>
                                <Select.Option
                                    value={SocialNetworkTypeCodeEnum.INSTAGRAM}>{ContactMaps.socialNetworksMap[SocialNetworkTypeCodeEnum.INSTAGRAM]}</Select.Option>
                                <Select.Option
                                    value={SocialNetworkTypeCodeEnum.INTERNETPAGE}>{ContactMaps.socialNetworksMap[SocialNetworkTypeCodeEnum.INTERNETPAGE]}</Select.Option>
                            </CustomSelect>
                            <Input value={item.value} onChange={onInputChange}/>
                            <div style={{maxWidth: 43, minWidth: 43, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <SystemIcon type={SystemIconType.cancel} onClick={onButtonClick}/>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};