import React, {FC, useCallback, useState} from 'react';
import {Icon} from "antd";
import {IconCalendar, IconCashRegisterSolid, IconEdit, IconSyncSolid} from "../../../../../../components/icons";
import {RoundButton} from "../../../../../../components";
import {ChangeElementsPricePopover} from "../../elementsList/components/changeElementsPricePopover";
import {RoundButtonGroup} from "../../../../../../components/button/roundButtonGroup";
import {ChangeElementsDateParamPopover} from "../../elementsList/components/popovers/changeElementsDateParamPopover";
import {ChangeElementsShiftCountPopover} from "../../elementsList/components/popovers/changeElementsShiftCountPopover";
import {ChangeElementsDiscountPopover} from "../../elementsList/components/popovers/changeElementsDiscountPopover";
import {useAppDispatch, useAppSelector} from "../../../../../../store/hooks";
import {recalculateShiftCountForSelectedIds} from "../../../reducers/operationForm.reducer";
import {
    ActionButtonsData,
    ActionButtonsGroup
} from "../../../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup";
import {Color} from "../../../../../../core/types/color";
import {OperationFormUtils} from "../../../utils/operationFormUtils";
import {canViewFinancialDataSelector} from "../../../../../../shared/reducers/permissions.reducer";
import CatalogPriceUpdateErrorModal from "../../../../elements/CatalogPriceUpdateErrorModal";
import {ProblemRentElementField} from "../../../../../../server";

interface PropsSelectedElementsActionButtonsBlock {
    selectedIds: number[];
    onApply?: () => void;
    hideDateButtons?: boolean;
    hidePriceButtons?: boolean;
}

export const elementActionButtons: ActionButtonsData<'updatePrices'> = {
    ['updatePrices']: {
        name: 'Обновить цены из каталога',
        icon: <Icon component={IconSyncSolid} />,
        color: Color.Orange3,
    }
};

export const SelectedElementsActionButtonsBlock: FC<PropsSelectedElementsActionButtonsBlock> = ({selectedIds, onApply, hideDateButtons, hidePriceButtons}) => {

    const dispatch = useAppDispatch();

    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    const onCloseCB = useCallback(() => {
        //onApply?.();
    }, [onApply]);

    const showDateButtons = hideDateButtons !== true;
    const showPriceButtons = hidePriceButtons !== true;
    const showShiftCountsButton = true;
    const showRecalculateShiftCountsButton = true;

    const [catalogPriceErrorFields, setCatalogPriceErrorFields] = useState<ProblemRentElementField[]>([]);

    return (
        <>
            {
                showDateButtons && (
                    <RoundButtonGroup>
                        <ChangeElementsDateParamPopover mode={'startDate'} selectedIds={selectedIds} onClose={onCloseCB}>
                            <RoundButton colorScheme={"ACTION_ELEMENTS_SET_DATES"}><Icon component={IconCalendar}/> Начало</RoundButton>
                        </ChangeElementsDateParamPopover>
                        <ChangeElementsDateParamPopover mode={'range'} selectedIds={selectedIds} onClose={onCloseCB}>
                            <RoundButton colorScheme={"ACTION_ELEMENTS_SET_DATES"}>Период</RoundButton>
                        </ChangeElementsDateParamPopover>
                        <ChangeElementsDateParamPopover mode={'endDate'} selectedIds={selectedIds} onClose={onCloseCB}>
                            <RoundButton colorScheme={"ACTION_ELEMENTS_SET_DATES"}>Завершение</RoundButton>
                        </ChangeElementsDateParamPopover>
                    </RoundButtonGroup>
                )
            }

            {
                showShiftCountsButton && (
                    <ChangeElementsShiftCountPopover selectedIds={selectedIds} onClose={onCloseCB} >
                        <RoundButton colorScheme={"ACTION_ELEMENTS_SET_SHIFTS"}>
                            <Icon component={IconEdit}/> Смены
                        </RoundButton>
                    </ChangeElementsShiftCountPopover>
                )
            }

            {
                showPriceButtons && (
                    <RoundButtonGroup>
                        <ChangeElementsDiscountPopover selectedIds={selectedIds} onClose={onCloseCB}>
                            <RoundButton colorScheme={"ACTION_ELEMENTS_SET_PRICE"}>
                                <Icon component={IconCashRegisterSolid}/> Скидка
                            </RoundButton>
                        </ChangeElementsDiscountPopover>
                        <ChangeElementsPricePopover selectedIds={selectedIds} onClose={onCloseCB}>
                            <RoundButton colorScheme={"ACTION_ELEMENTS_SET_PRICE"}>Стоимость</RoundButton>
                        </ChangeElementsPricePopover>
                    </RoundButtonGroup>
                )
            }

            {
                showRecalculateShiftCountsButton && (
                    <RoundButton colorScheme={"ACTION_ELEMENTS_RECALCULATE_SHIFTS"} onClick={() => {
                        dispatch(recalculateShiftCountForSelectedIds(selectedIds));
                        onCloseCB();
                    }}>
                        <Icon component={IconSyncSolid}/> Пересчитать смены
                    </RoundButton>
                )
            }

            <ActionButtonsGroup
                data={elementActionButtons}
                additionalActions={[
                    canViewFinancialData ? 'updatePrices': undefined
                ]}
                onAction={(action)=>{
                    if(action === 'updatePrices'){
                        void OperationFormUtils.updateElementsPriceFromCatalog(selectedIds, (fields)=>{
                            setCatalogPriceErrorFields(fields);
                        });
                    }
                }}
            />

            <CatalogPriceUpdateErrorModal
                fields={catalogPriceErrorFields}
                onClose={() => setCatalogPriceErrorFields([])}
            />

        </>
    );
};
