import React from 'react';
import { FormFieldsGroup, FormItemType, SelectItem } from '../../../../../../../components/dynamicForm/DynamicForm';
import { ProductCategoriesSelect } from '../../../../components/ProductCategoriesSelect/ProductCategoriesSelect';
import debounce from 'lodash/debounce';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { validateField } from '../../../../../../../shared/util/validateField';
import { LocalizationEnum, localize } from '../../../../../../../localization';
import { NomenclatureStateCodeEnum, PricingSchemeRecord } from '../../../../../../../server';
import { getCountriesByCurrentLocale } from '../../../../../../../localization/localizationUtils';
import { getStoreState } from '../../../../../../../../index';
import { EntityRemoteSelect } from '../../../../../../../components/select/EntityRemoteSelect';
import { canUpdateFinancialData, canViewFinancialData } from '../../../../../../../shared/util/permissionUtils';
import { AccountingTypeContent } from './ProductCreateModalFieldsData';
import { createHiddenField } from '../../../../../../../components/modalForm/utils';
import { Icon, Select } from 'antd';
import { PricingSchemeMaps } from '../../../../../../../core/utils/pricingSchemeUtils';
import './ProductCreateModalFields.less';
import {
    PricingSchemeSelect,
    PricingSchemeSelectProps
} from "../../../../../../../components/v2/select/pricingSchemeSelect/pricingSchemeSelect";
import {
    TimeDurationInput,
    TimeDurationInputProps
} from "../../../../../../../components/timeDurationInput/timeDurationInput";

// dynamic form fields
export const formFields: FormFieldsGroup[] = [
    {
        fields: [
            createHiddenField('id'),
            createHiddenField('productEdit'),
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE,
                id: 'shortName',
                required: true,
                type: FormItemType.String,
                maxLength: 75,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('productEdit') ? form.getFieldValue('id') : undefined;
                    validateField(
                        'product',
                        fieldName,
                        value,
                        undefined,
                        ignoreCurrentId,
                        localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__SHORT_NAME_ALREADY_USED),
                        cb
                    );
                }, 500),
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__FULL_NAME,
                id: 'fullName',
                type: FormItemType.String,
                required: false,
                maxLength: 255,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('productEdit') ? form.getFieldValue('id') : undefined;
                    validateField(
                        'product',
                        fieldName,
                        value,
                        undefined,
                        ignoreCurrentId,
                        localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__FULL_NAME_ALREADY_USED),
                        cb
                    );
                }, 500),
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: localize(LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER),
                id: 'stateCode',
                type: FormItemType.Select,
                defaultValue: NomenclatureStateCodeEnum.ACTIVE,
                required: true,
                values: (form: WrappedFormUtils) => {
                    let values: SelectItem[] = [];
                    if (form.getFieldValue('id')) {
                        values = [
                            {
                                name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__NEW),
                                value: NomenclatureStateCodeEnum.NEW,
                            },
                            {
                                name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__ACTIVE),
                                value: NomenclatureStateCodeEnum.ACTIVE,
                            },
                            {
                                name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__NOTAVAILABLE),
                                value: NomenclatureStateCodeEnum.NOTAVAILABLE,
                            },
                        ];
                    } else {
                        values = [
                            {
                                name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__NEW),
                                value: NomenclatureStateCodeEnum.NEW,
                            },
                            {
                                name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__ACTIVE),
                                value: NomenclatureStateCodeEnum.ACTIVE,
                            },
                        ];
                    }

                    return values.map((item) => {
                        return {
                            name: (
                                <>
                                    <div className={`rr-dot rr-status-bg-` + item.value} style={{ marginRight: '10px' }}></div>
                                    <span className="calendar-multi-select">{item.name}</span>{' '}
                                </>
                            ),
                            value: item.value,
                        };
                    });
                },
                disabled: (getFieldValue) => !!getFieldValue('id'),
            },
        ],
    },
    {
        title: 'Тип учета',
        helpMessage: AccountingTypeContent,
        visible(form: WrappedFormUtils): boolean {
            return !form.getFieldValue('id');
        },
        fields: [
            createHiddenField('id'),
            createHiddenField('childVariantCount'),
            createHiddenField('childInstanceCount'),
            {
                label: 'Поддержка вариантов',
                id: 'variant',
                type: FormItemType.Switch,
                defaultValue: false,
                disabled: (getFieldValue) => !!getFieldValue('childVariantCount'),
            },
            {
                label: 'Поддержка именованных экземпляров',
                id: 'tracked',
                type: FormItemType.Switch,
                defaultValue: false,
                disabled: (getFieldValue) => !!getFieldValue('childInstanceCount'),
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__EXTERNAL_CODE,
                id: 'externalCode',
                type: FormItemType.String,
                required: true,
                maxLength: 30,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('productEdit') ? form.getFieldValue('id') : undefined;
                    validateField(
                        'product',
                        fieldName,
                        value,
                        undefined,
                        ignoreCurrentId,
                        localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__EXTERNAL_CODE_ALREADY_USED),
                        cb
                    );
                }, 500),
            },
            {
                label: 'Штриховой код',
                id: 'barcode',
                type: FormItemType.String,
                maxLength: 30,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('productEdit') ? form.getFieldValue('id') : undefined;
                    validateField('product', fieldName, value, undefined, ignoreCurrentId, 'Штриховой код уже использован', cb);
                }, 500),
                validationRules: [
                    {
                        // TODO Нормальное сообщение об ошибке
                        pattern: '^[0-9a-zA-Z-_]+$',
                        message: 'Поле должно содержать только символы: A-Z, a-z, 0-9, - и _',
                    },
                ],
                infoMessage: 'Допустимы символы: A-Z, a-z, 0-9, - и _',
            },
            // TODO тут должен быть какой-то массив стран - Код страны производителя - ISO 3166 / Alpha 2
            {
                label: LocalizationEnum.ASPECT__FIELDS__PRODUCT__COUNTRIES_OF_PRODUCTION,
                id: 'producerCountries',
                type: FormItemType.MultiSelect,
                placeholder: localize(LocalizationEnum.ASPECT__FORMS__PLACEHOLDER__SELECT_COUNTRIES),
                values: getCountriesByCurrentLocale,
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__CATEGORIES,
                id: 'categoryIds',
                type: FormItemType.Component,
                component: ProductCategoriesSelect,
                placeholder: localize(LocalizationEnum.ASPECT__FORMS__PLACEHOLDER__SELECT_CATEGORIES),
            },
            {
                label: LocalizationEnum.ASPECT__FORMS__MAIN_IMAGE,
                id: 'mainImage',
                type: FormItemType.UploadImage,
                infoMessage: localize(LocalizationEnum.ASPECT__FORMS__IMAGE_FORMAT_RESCTRICTIONS_STRING),
                imagesMaxCount: 1,
            },
            {
                label: LocalizationEnum.ASPECT__FORMS__EXTRA_IMAGES,
                id: 'otherImages',
                type: FormItemType.UploadImage,
                infoMessage: localize(LocalizationEnum.ASPECT__FORMS__IMAGES_FORMAT_RESCTRICTIONS_STRING),
                imagesMaxCount: 15,
                multiple: true,
                validationRules: [{ max: 15, message: 'Число дополнительных изображений не должно превышать 15', type: 'array' }],
            },
        ],
    },
    {
        visible: () => {
            const p = getStoreState().permissions.permissions;
            return canViewFinancialData(p);
        },
        fields: [
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__PRICE_PER_SHIFT,
                id: 'pricePerShift',
                type: FormItemType.Money,
                step: 50,
                required: true,
                minLength: 0,
                defaultValue: 0,
                validationFunction: (fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    cb(value >= 10000000000 ? localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__PRICE_TOO_HIGH) : undefined);
                },
                disabled: () => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
            },
            {
                label: 'Ценовая схема',
                placeholder: 'Выберите ценовую схему',
                id: 'pricingScheme',
                type: FormItemType.Component,
                component: PricingSchemeSelect,
                disabled: () => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
                visible: () => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p);
                },
            },
        ],
    },
    {
        visible: () => {
            const p = getStoreState().permissions.permissions;
            return canViewFinancialData(p) && canUpdateFinancialData(p);
        },
        fields: [
            {
                label: LocalizationEnum.PAGE__PRODUCTS__PURCHASE_PRICE,
                id: 'basePurchaseCost',
                type: FormItemType.Money,
                step: 50,
                minLength: 0,
                validationFunction: (fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    cb(value >= 10000000000 ? localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__PRICE_TOO_HIGH) : undefined);
                },
            },
        ],
    },
    {
        fields: [
            createHiddenField('stockInstanceCount', {
                defaultValue: 0,
            }),
            createHiddenField('underMaintenanceInstanceCount', {
                defaultValue: 0,
            }),
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION,
                id: 'description',
                type: FormItemType.RichText,
                validationRules: [
                    {
                        max: 5000,
                        message: (
                            <>
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} 5000{' '}
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__INCLUDING_FORMATTING_TAGS)}
                            </>
                        ),
                    },
                ],
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
    {
        title: 'Длительность смены в минутах',
        fields: [
            createHiddenField('_shiftLengthInMinutes', {
                defaultValue: 0,
            }),
            {
                label: 'По компании',
                id: 'hasOwnShiftLength',
                type: FormItemType.Switch,
                defaultValue: true,
                labelCol: { span: 5 },
                wrapperCol: { span: 12 },
                onChange: (value: any, form: WrappedFormUtils) => {
                    if (value) {
                        form.setFieldsValue({
                            shiftLengthInMinutes: getStoreState().businessAccountPreferences.preferences?.shiftLengthInMinutes,
                            _shiftLengthInMinutes: form.getFieldValue('shiftLengthInMinutes'),
                        });
                    } else {
                        form.setFieldsValue({
                            shiftLengthInMinutes: form.getFieldValue('_shiftLengthInMinutes'),
                        });
                    }
                },
            },
            {
                id: 'shiftLengthInMinutes',
                type: FormItemType.Component,
                required: true,
                //minLength: 1,
                defaultValue: 0,
                component: TimeDurationInput,
                onChange: (value, form) => {
                    form.setFieldsValue({
                        _shiftLengthInMinutes: form.getFieldValue(value),
                    });
                },
                disabled: (getFieldValue) => getFieldValue('hasOwnShiftLength') === true,
                componentProps: ():TimeDurationInputProps=>({
                    min: 1
                })
            },
        ],
    },
    {
        title: 'Резерв времени между обязательствами в минутах',
        fields: [
            createHiddenField('_requiredTimeIndentBetweenElementsInMinutes', {
                defaultValue: 0,
            }),
            {
                label: 'По компании',
                id: 'hasOwnRequiredTimeIndentBetweenElements',
                type: FormItemType.Switch,
                defaultValue: true,
                labelCol: { span: 5 },
                wrapperCol: { span: 12 },
                onChange: (value: any, form: WrappedFormUtils) => {
                    if (value) {
                        form.setFieldsValue({
                            requiredTimeIndentBetweenElementsInMinutes:
                                getStoreState().businessAccountPreferences.preferences?.requiredTimeIndentBetweenElementsInMinutes,
                            _requiredTimeIndentBetweenElementsInMinutes: form.getFieldValue('requiredTimeIndentBetweenElementsInMinutes'),
                        });
                    } else {
                        form.setFieldsValue({
                            requiredTimeIndentBetweenElementsInMinutes: form.getFieldValue('_requiredTimeIndentBetweenElementsInMinutes'),
                        });
                    }
                },
            },
            {
                id: 'requiredTimeIndentBetweenElementsInMinutes',
                type: FormItemType.Integer,
                required: true,
                minLength: 0,
                defaultValue: 0,
                onChange: (value, form) => {
                    form.setFieldsValue({
                        _requiredTimeIndentBetweenElementsInMinutes: form.getFieldValue(value),
                    });
                },
                disabled: (getFieldValue) => getFieldValue('hasOwnRequiredTimeIndentBetweenElements') === true,
            },
        ],
    },
];
