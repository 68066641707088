import { latLngBounds, LatLngTuple } from 'leaflet';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Map, TileLayer, Viewport } from 'react-leaflet';
import { Card, Icon } from 'antd';
import MapFilters, { LocationFilters } from './MapFilters';
import { useListLocationsQuery, useLocationByIdQuery } from '../api/locations.api';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { useHistory } from 'react-router-dom';
import { LocationCreateModal } from '../modal/create/LocationCreateModal';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { goBack } from 'connected-react-router';
import { createLocationModalFilters, editLocationModalFilters } from '../modal/create/LocationCreateModalData';
import './LocationsMap.less';
import LocateButton from './LocateButton';
import LocationMarkers from './LocationMarkers';
import { LocationDrawer } from '../drawer/LocationDrawer';
import { useLocationEntityActions } from '../hooks/useLocationEntityActions';
import { logisticsPageUrlRoute } from '../../../data/tabs';
import { LogisticsPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import { EntityGridRootPath } from '../../../../../../components/page/entityGrid/types/params';
import { LocationEditModal } from '../modal/edit/LocationEditModal';
import { businessAccountAddressSelector } from '../../../../../../shared/reducers/businessAccount.reducer';
import { useSuggestAddressesQuery } from '../../../../address/api/address.api';
import { RoundButton } from '../../../../../../components';
import { IconCompressArrowsAltSolid2 } from '../../../../../../components/icons';
import { IRootState } from '../../../../../../shared/reducers';
import { updateLocationViewSettings } from '../../../../../../shared/reducers/userSettings/userSettings.reducer';

interface Props {
    switcher: ReactNode;
}

export const DEFAULT_MAP_ZOOM = 16;
export const DEFAULT_MAP_CENTER: LatLngTuple = [55.753632, 37.621647];

const LocationsMap = ({ switcher }: Props) => {
    const dispatch = useAppDispatch();
    const addresses = useAppSelector(businessAccountAddressSelector);
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const viewPort = useAppSelector((store: IRootState) => store.userSettings.mapViewPort);
    const [currentFilters, setCurrentFilters] = useState<LocationFilters>({});
    const [activeLocation, setActiveLocation] = useState<number | undefined>();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const history = useHistory();
    const [modal, setModal] = useState<'new' | 'edit' | undefined>();
    const rootPath = `/${businessAccountId}/${logisticsPageUrlRoute}/${LogisticsPageTabsEnum.LOCATIONS}` satisfies EntityGridRootPath;

    const entityActions = useLocationEntityActions({
        rootPath,
        urlSearchParams: new URLSearchParams({ viewType: 'map' }),
    });

    const viewPortChangeHandler = useCallback(
        (vp: Viewport) => {
            dispatch(updateLocationViewSettings({ mapViewPort: vp }));
        },
        [dispatch]
    );

    useEffect(() => {
        const searchParams = new URLSearchParams(history.location.search);
        const modal = searchParams.get('modal');
        const id = searchParams.get('id');
        const view = searchParams.get('view');

        if (modal === 'new' || modal === 'edit') {
            setModal(modal);
        } else {
            setModal(undefined);
        }

        if (id && !isNaN(+id)) {
            setActiveLocation(+id);
        }

        if (view) {
            setDrawerOpen(true);
        }
    }, [history.location.search]);

    const [position, setPosition] = useState<LatLngTuple>(DEFAULT_MAP_CENTER);
    const mapRef = useRef<Map>(null);

    const { data: locations, refetch } = useListLocationsQuery(
        { businessAccountId, params: { ...currentFilters, page: 1, limit: 1000 } },
        { refetchOnMountOrArgChange: true }
    );

    const { data: entity } = useLocationByIdQuery(
        { businessAccountId, entityId: activeLocation! },
        { refetchOnMountOrArgChange: true, skip: !activeLocation }
    );

    const markers = useMemo(() => {
        return locations?.entitiesData.records.filter((loc) => loc.latitude && loc.longitude);
    }, [locations]);

    const { data: addressSuggestions } = useSuggestAddressesQuery(
        { businessAccountId, addressQuery: addresses[0] },
        { refetchOnMountOrArgChange: true, skip: !addresses.length || (markers && markers.length > 0) }
    );

    useEffect(() => {
        if (!markers?.length && addressSuggestions?.[0]?.latitude && addressSuggestions?.[0]?.longitude) {
            setPosition([addressSuggestions[0].latitude, addressSuggestions[0].longitude]);
        } else if (!markers?.length) {
            navigator.geolocation.getCurrentPosition((position) => {
                setPosition([position.coords.latitude, position.coords.longitude]);
            });
        }
    }, [addressSuggestions, markers?.length]);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.leafletElement.locate();
        }
    }, [mapRef]);

    const locateHandler = useCallback(
        (coords: LatLngTuple) => {
            if (mapRef.current) {
                mapRef.current.leafletElement.flyTo(coords, DEFAULT_MAP_ZOOM);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mapRef.current]
    );

    const markerClickHandler = useCallback(
        (id: number) => {
            const newSp = new URLSearchParams(history.location.search);

            newSp.append('id', id.toString());
            newSp.append('view', 'true');
            history.replace({ search: newSp.toString() });
        },
        [history]
    );

    const drawerCloseHandler = useCallback(() => {
        const newSp = new URLSearchParams(history.location.search);
        newSp.delete('view');
        newSp.delete('id');
        history.replace({ search: newSp.toString() });
        setActiveLocation(undefined);
    }, [history]);

    const fitToMapHandler = useCallback(() => {
        const markerBounds = latLngBounds([]);
        (markers ?? []).forEach((loc) => markerBounds.extend([loc.latitude!, loc.longitude!]));
        if (markerBounds.isValid() && mapRef.current) {
            mapRef.current.leafletElement.fitBounds(markerBounds, { padding: [10, 10] });
        }
    }, [markers]);

    return (
        <>
            <Card style={{ zIndex: 1 }}>
                <MapFilters currentFilters={currentFilters} onFiltersChange={setCurrentFilters} />
                {switcher}
                <div className="rr-locationsMap-wrapper">
                    <Map
                        center={viewPort ? viewPort?.center! : markers?.length ? undefined : position}
                        zoom={viewPort ? viewPort.zoom! : markers?.length ? undefined : DEFAULT_MAP_ZOOM}
                        style={{ height: 'calc(100vh - 460px)', minHeight: 500 }}
                        ref={mapRef}
                        zoomControl={false}
                        onViewportChanged={viewPortChangeHandler}
                    >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <LocationMarkers onMarkerClick={markerClickHandler} data={markers ?? []} />
                    </Map>
                    <div className="rr-locationsMap-buttons">
                        {!!markers?.length && (
                            <RoundButton colorScheme="roundIcon" size="default" onClick={fitToMapHandler}>
                                <Icon style={{ fontSize: 16, height: 16 }} component={IconCompressArrowsAltSolid2} />
                            </RoundButton>
                        )}
                        <LocateButton onSuccess={locateHandler} className="rr-locationsMap-locateBtn" />
                    </div>
                </div>
            </Card>
            {modal === 'new' && (
                <LocationCreateModal
                    businessAccountId={businessAccountId}
                    title={'Создание новой локации'}
                    okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                    filters={createLocationModalFilters}
                    modalName={'create-location'}
                    onCancel={() => {
                        dispatch(goBack());
                    }}
                    onSuccess={(data) => {
                        dispatch(goBack());
                    }}
                />
            )}
            {activeLocation && drawerOpen && (
                <LocationDrawer
                    locationId={activeLocation}
                    onClose={drawerCloseHandler}
                    editLocation={entityActions.editActionForDrawer}
                    archiveLocation={entityActions.archiveActionForDrawer}
                    deleteLocation={entityActions.deleteActionForDrawer}
                    refetchList={refetch}
                    isEntityActionsLoading={entityActions.isLoading}
                />
            )}
            {modal === 'edit' && entity && (
                <LocationEditModal
                    businessAccountId={businessAccountId}
                    title={'Редактирование локации'}
                    okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
                    filters={editLocationModalFilters}
                    modalName={'edit-location'}
                    location={entity}
                    onCancel={() => {
                        dispatch(goBack());
                    }}
                    onSuccess={(data) => {
                        dispatch(goBack());
                    }}
                />
            )}
        </>
    );
};

export default LocationsMap;
