import { Icon, Input } from 'antd';
import { LatLngTuple, LeafletMouseEvent } from 'leaflet';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Map, TileLayer, Marker } from 'react-leaflet';
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM } from '../map/LocationsMap';
import './CoordinatesInput.less';
import { LinkButton2 } from '../../../../../../components/buttons/linkButton2/linkButton2';
import LocateButton from '../map/LocateButton';
import { isLatitude, isLongitude } from '../utils';
import classNames from 'classnames';
import { IconClipboard, IconSyncSolid } from '../../../../../../components/icons';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountAddressSelector } from '../../../../../../shared/reducers/businessAccount.reducer';
import { useLazySuggestAddressesQuery, useSuggestAddressesQuery } from '../../../../address/api/address.api';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';

interface IInput {
    value: string;
    error: boolean;
}

export type Coordinates = [number | undefined, number | undefined];

interface Props {
    value?: Coordinates;
    onChange: (value: Coordinates | undefined) => void;
    address?: string;
    latitudeTouched?: boolean;
    longitudeTouched?: boolean;
}

const CoordinatesInput = ({ value, onChange, address, latitudeTouched, longitudeTouched }: Props) => {
    const [mapZoom, setMapZoom] = useState(DEFAULT_MAP_ZOOM);
    const [byAddress, setByAddress] = useState(false);
    const [noAddress, setNoAddress] = useState(false);
    const [clipboardDisabled, setClipboardDisabled] = useState(false);
    const [errorText, setErrorText] = useState('');

    const addresses = useAppSelector(businessAccountAddressSelector);
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const { data: addressSugestion } = useSuggestAddressesQuery(
        { businessAccountId, addressQuery: addresses[0] },
        { refetchOnMountOrArgChange: true, skip: !!value || !addresses.length }
    );

    const [getAddressSuggestion] = useLazySuggestAddressesQuery();

    const [position, setPosition] = useState<LatLngTuple>(value && value[0] && value[1] ? [value[0], value[1]] : DEFAULT_MAP_CENTER);

    useEffect(() => {
        if (!value && addressSugestion?.length && addressSugestion[0].latitude && addressSugestion[0].longitude) {
            setPosition([addressSugestion[0].latitude!, addressSugestion[0].longitude!]);
        } else if (!value) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setPosition([position.coords.latitude, position.coords.longitude]);
                },
                () => setPosition(DEFAULT_MAP_CENTER)
            );
        }
    }, [addressSugestion, value]);

    useEffect(() => {
        setByAddress(false);
        setNoAddress(false);
    }, [address]);

    const mapRef = useRef<Map>(null);

    const [mapPoint, setMapPoint] = useState<LatLngTuple | undefined>(value && value[0] && value[1] ? [value[0], value[1]] : undefined);
    const [latitude, setLattitude] = useState<IInput>({
        value: mapPoint ? mapPoint[0].toString() : '',
        error: mapPoint ? !isLatitude(mapPoint[0]) : false,
    });
    const [longitude, setLongitude] = useState<IInput>({
        value: mapPoint ? mapPoint[1].toString() : '',
        error: mapPoint ? !isLongitude(mapPoint[1]) : false,
    });

    const locateHandler = useCallback(
        (coords: LatLngTuple) => {
            if (mapRef.current) {
                mapRef.current.leafletElement.flyTo(coords, DEFAULT_MAP_ZOOM);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mapRef.current]
    );

    const inputChangeHandler = useCallback((type: 'latitude' | 'longitude', value: string) => {
        if (type === 'latitude') setLattitude({ value, error: !value || !isLatitude(value) });
        if (type === 'longitude') setLongitude({ value, error: !value || !isLongitude(value) });
        setByAddress(false);
        setNoAddress(false);
    }, []);

    const blurHandler = useCallback(() => {
        const point: Coordinates = [latitude.value ? +latitude.value : undefined, longitude.value ? +longitude.value : undefined];
        onChange(point);
    }, [latitude.value, longitude.value, onChange]);

    useEffect(() => {
        const point: Coordinates = [latitude.value ? +latitude.value : undefined, longitude.value ? +longitude.value : undefined];

        if (latitude.value && !latitude.error && longitude.value && !longitude.error) {
            setMapPoint(point as LatLngTuple);
        } else {
            setMapPoint(undefined);
        }
    }, [latitude.error, latitude.value, longitude.error, longitude.value]);

    const coordinatesByAddressHandler = useCallback(() => {
        if (address) {
            getAddressSuggestion({ businessAccountId, addressQuery: address }).then((res) => {
                if (res.status === 'fulfilled' && res.data.length && res.data[0].latitude && res.data[0].longitude) {
                    const point: Coordinates = [res.data[0].latitude, res.data[0].longitude];
                    onChange(point);
                    setByAddress(true);
                } else {
                    setNoAddress(true);
                    setByAddress(true);
                }
            });
        } else {
            setNoAddress(true);
            setByAddress(true);
        }
    }, [address, businessAccountId, getAddressSuggestion, onChange]);

    const mapClickHandler = useCallback(
        (e: LeafletMouseEvent) => {
            onChange([e.latlng.lat, e.latlng.lng]);
            setNoAddress(false);
            setByAddress(false);
        },
        [onChange]
    );

    useEffect(() => {
        if (mapRef.current && value && value[0] && value[1] && isLatitude(value[0]) && isLongitude(value[1]) && position) {
            mapRef.current.leafletElement.flyTo(value as LatLngTuple, mapZoom);
        }
        setLattitude({ value: value?.[0]?.toString() ?? '', error: !value?.[0] || !isLatitude(value[0]) });
        setLongitude({ value: value?.[1]?.toString() ?? '', error: !value?.[1] || !isLongitude(value[1]) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, mapRef]);

    useEffect(() => {
        if (mapPoint && mapRef.current) {
            mapRef.current.leafletElement.flyTo(mapPoint, mapZoom);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapPoint]);

    useEffect(() => {
        navigator.permissions.query({ name: 'clipboard-read' as PermissionName }).then((res) => {
            if (res.state === 'denied') {
                setClipboardDisabled(true);
            }
        });
    }, []);

    const pasteHandler = async () => {
        const text = await navigator.clipboard.readText();
        if (!text) {
            setErrorText('Не обнаружен скопированный текст');
            return;
        }
        const coordinates = text.split(', ');
        const [lat, lng] = coordinates;
        if (lat && lng && coordinates.length === 2 && !isNaN(+lat) && !isNaN(+lng)) {
            inputChangeHandler('latitude', lat);
            inputChangeHandler('longitude', lng);
            onChange([+lat, +lng]);
            setErrorText('');
        } else {
            setErrorText('Cкопировано недопустимое значение! Формат координат "12.345, 45.678"');
        }
    };

    useEffect(() => {
        if (errorText) {
            setTimeout(() => {
                setErrorText('');
            }, 5000);
        }
    }, [errorText]);

    return (
        <div className="rr-coordinates-input">
            <div className=" rr-form-group" style={{ padding: 0, margin: 0, borderBottom: 'none' }}>
                <div className="rr-form-group-title" style={{ marginBottom: 0 }}>
                    <div className="rr-coordinates-header">
                        <h3>Координаты</h3>
                    </div>
                </div>
                <div className="rr-coordinates-actions">
                    <LinkButton2
                        icon={<Icon style={{ fontSize: 21, color: clipboardDisabled ? '#a4a4a4' : '#3d64d4' }} component={IconClipboard} />}
                        label="Вставить"
                        onClick={pasteHandler}
                        className="rr-coordinates-byAddressBtn"
                        disabled={clipboardDisabled}
                    />
                    {address && (
                        <LinkButton2
                            icon={<Icon style={{ fontSize: 21, color: byAddress ? '#a4a4a4' : '#04b0f1' }} component={IconSyncSolid} />}
                            label="По адресу"
                            onClick={coordinatesByAddressHandler}
                            className="rr-coordinates-byAddressBtn"
                            disabled={byAddress}
                        />
                    )}
                </div>
                {noAddress && (
                    <div className="ant-form-explain" style={{ marginTop: 0, color: '#f4516c' }}>
                        Адрес не найден
                    </div>
                )}
                {errorText && (
                    <div className="ant-form-explain" style={{ marginTop: 0, color: '#f4516c' }}>
                        {errorText}
                    </div>
                )}
                <div
                    className={classNames(
                        'ant-row ant-form-item rr-filters-not-selected-filter',
                        latitude.error && latitudeTouched && 'has-error'
                    )}
                >
                    <div className="ant-col ant-form-item-label">
                        <label htmlFor="latitude" className="ant-form-item-required ant-form-item-no-colon" title="">
                            Широта
                        </label>
                    </div>
                    <Input
                        type="number"
                        id="latitude"
                        pattern="^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})?$"
                        value={latitude.value}
                        onChange={(e) => inputChangeHandler('latitude', e.target.value)}
                        onBlur={blurHandler}
                    />
                    {latitude.error && latitudeTouched && (
                        <div className="ant-form-explain">
                            {latitude.value ? 'Недопустимое значение поля' : 'Поле обязательно для заполнения'}
                        </div>
                    )}
                </div>
                <div
                    className={classNames(
                        'ant-row ant-form-item rr-filters-not-selected-filter',
                        longitude.error && longitudeTouched && 'has-error'
                    )}
                >
                    <div className="ant-col ant-form-item-label">
                        <label htmlFor="longitude" className="ant-form-item-required ant-form-item-no-colon" title="">
                            Долгота
                        </label>
                    </div>
                    <Input
                        type="number"
                        id="longitude"
                        pattern="^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})?$"
                        value={longitude.value}
                        onChange={(e) => inputChangeHandler('longitude', e.target.value)}
                        onBlur={blurHandler}
                    />
                    {longitude.error && longitudeTouched && (
                        <div className="ant-form-explain">
                            {longitude.value ? 'Недопустимое значение поля' : 'Поле обязательно для заполнения'}
                        </div>
                    )}
                </div>
            </div>
            <div className="rr-coordinates-map">
                <Map
                    onclick={mapClickHandler}
                    onzoomend={(e) => setMapZoom(e.target._zoom)}
                    center={position}
                    zoom={mapZoom}
                    style={{ height: 300, width: 460 }}
                    ref={mapRef}
                >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    {mapPoint && <Marker position={mapPoint} />}
                </Map>
                <LocateButton className="rr-coordinates-locateBtn" onSuccess={locateHandler} />
            </div>
        </div>
    );
};
export default CoordinatesInput;
