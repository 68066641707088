import { PricingSchemePricePopover } from '../../../../../settings/pricingSchemes/pricingSchemePricePopover';
import { Icon } from 'antd';
import { PricingSchemeMaps } from '../../../../../../../core/utils/pricingSchemeUtils';
import React, { FC, useEffect, useState } from 'react';
import { PricingSchemeExternalRepresentationObj, serverApi } from '../../../../../../../server';
import { businessAccountIdSelector } from '../../../../../../../shared/reducers/system.reducer';
import { useAppSelector } from '../../../../../../../store/hooks';

interface PricingSchemePricePopoverWrapperProps {
    pricingSchemeId?: number;
    pricingScheme?: PricingSchemeExternalRepresentationObj;
    value?: number;
}

export const PricingSchemePricePopoverWrapper: FC<PricingSchemePricePopoverWrapperProps> = ({ pricingSchemeId, value, ...props }) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const [pricingScheme, setPricingScheme] = useState<PricingSchemeExternalRepresentationObj | null>(null);

    useEffect(() => {
        if (!props.pricingScheme && pricingSchemeId) {
            serverApi.getPricingSchemeById(businessAccountId, pricingSchemeId).then(({ data: pricingScheme }) => {
                setPricingScheme(pricingScheme);
            });
        }
    }, [businessAccountId, pricingSchemeId, props.pricingScheme]);

    const scheme = props.pricingScheme || pricingScheme;

    return scheme && value ? (
        <PricingSchemePricePopover
            data={{
                id: scheme.id,
                typeCode: scheme.typeCode,
                label: scheme.name || '',
                price: value,
                steps: scheme.steps || [],
                roundingLevel: scheme.roundingLevel,
                roundingDirection: scheme.roundingDirection,
            }}
        >
            <Icon
                component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[scheme.typeCode]}
                className={'rr-grid-pricingScheme-icon'}
                onClick={(event) => event.stopPropagation()}
            />
        </PricingSchemePricePopover>
    ) : null;
};
