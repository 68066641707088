import React from 'react';
import {Card} from "antd";
import {loadEntities, reset, setPageParams} from "./reducers/history-elements.reducer";
import {connect} from "react-redux";
import {IRootState} from "../../../shared/reducers";
import {push} from 'connected-react-router';
import {FormValue, Spin} from "../../../components";
import {getStateFromPath} from "../../../shared/util/utils";
import isEqual from 'lodash/isEqual';
import columns from "./history-elements-columns";
import {Location} from "history";
import {OperationIndicatorsBlock} from "../../../components/operationIndicatorsBlock/operationIndicatorsBlock";
import './history-elements-list.less';
import moment from "moment";
import {LocalizationEnum, localize} from '../../../localization';
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Grid} from "../../../components/grid/Grid";
import {RentAuditWithPredecessorRecord} from "../../../server/api";
import {canViewFinancialData} from "../../../shared/util/permissionUtils";
import {OperationParamResetButton} from "../operationForm/components/operationParamResetButton/OperationParamResetButton";
import {tableCellPercentsRenderer} from "../../../components/grid/renderers/tableCellPercentsRenderer";

interface IState {
}

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {
    location: Location;
    parentId: number;
    parentType: 'composition';
    visible?: boolean;
    //gridName: GridName;
}

class CompositionOperations extends React.Component<IProps, IState> {

    static defaultProps = {
        visible: true
    };
    firstLoad = false;

    componentDidUpdate = (prevProps: IProps) => {
        if (!this.firstLoad || !isEqual(prevProps.pageParams, this.props.pageParams)) {
            this.firstLoad = true;
            this.loadRentElements();
        }
    };

    shouldComponentUpdate = (nextProps: IProps) => {
        if (nextProps.location.search !== this.props.location.search) {
            if (nextProps.visible) this.props.setPageParams(getStateFromPath(nextProps.location.search, this.props.parentId ? {projectId: this.props.parentId} : undefined));
            return false;
        }
        return true;
    };

    componentDidMount = () => {
        this.props.setPageParams(getStateFromPath(this.props.location.search, this.props.parentId ? {projectId: this.props.parentId} : undefined));
    };

    componentWillUnmount = () => {
        console.log('CompositionOperations componentWillUnmount()');
        this.props.reset();
    };

    loadRentElements = () => {
        this.props.loadEntities(this.props.intl, this.props.businessAccountId, this.props.parentId);
    };

    render() {
        console.log('CompositionOperations render()', this.props);

        let {entities, isLoading, commonRentTerms, canViewFinancialData} = this.props;
        let entetisAudit: Array<RentAuditWithPredecessorRecord> = [];

        // let lockIconDate = false;
        // let lockIconCount = false;
        // let lockIconDiscount = false;

        if (entities !== null && entities !== undefined) {
            entities.map(el => {
                entetisAudit.push(el);
                // if (el.audit && el.audit.rentTerms) {
                //     if (commonRentTerms?.discount !== el.audit.rentTerms.discount) {
                //         lockIconDiscount = true;
                //     }
                //     if (commonRentTerms?.shiftCount !== el.audit.rentTerms.shiftCount) {
                //         lockIconCount = true;
                //     }
                //     if (commonRentTerms?.rentPeriodEndDate !== el.audit.rentTerms.rentPeriodEndDate || commonRentTerms?.rentPeriodStartDate !== el.audit.rentTerms.rentPeriodStartDate) {
                //         lockIconDate = true;
                //     }
                // }
                return entetisAudit;
            });
        }

        let allCount = 0;
        let allSum = 0;
        let allDiscount = 0;

        entetisAudit.map((el) => {
            allCount = allCount + el.audit.instanceCount;
            allSum = allSum + (el.audit.instanceCount * el.audit.rentElementEffectivePrice * el.audit.rentTerms.shiftCount);
            let s = el.audit.instanceCount * el.audit.rentElementEffectivePrice * el.audit.rentTerms.shiftCount;
            allDiscount = allDiscount + ((s * el.audit.rentTerms.discount) / 100);
            return allDiscount;
        });

        const excludeColumns = !canViewFinancialData ? ['audit.rentElementEffectivePrice', 'audit.rentTerms.discount', 'costInstancePerShift', 'allSum'] : undefined;

        return (
            <Spin spinning={isLoading}>
                <Card style={{marginBottom: 30}}>
                    {
                        commonRentTerms ?
                            <>
                                <div className="rr-value" style={{marginTop: '-10px', marginLeft: '10px'}}>
                                    <span>{localize(LocalizationEnum.PAGE__OPERATIONS__CARD__COMMON_RENT_TERMS_ON_CREATION)}</span>
                                </div>
                                <div style={{display: 'flex', marginTop: '20px', marginLeft: '10px'}}>
                                    <div style={{marginRight: '100px'}}>
                                        <div className="rr-label">
                                            <span>{localize(LocalizationEnum.ASPECT__RENT_BUSINESS__RENT_PERIOD)}
                                                <OperationParamResetButton changed={false} style={{marginLeft: 4}} />
                                            </span>
                                        </div>

                                        <div>
                                    <span className="rr-value">
                                    {moment(commonRentTerms.rentPeriodStartDate).format('DD.MM.YYYY')}  </span>
                                            <span className='rr-value-date-time'>
                                    {moment(commonRentTerms.rentPeriodStartDate).format('HH:mm')} </span>
                                            <span className="rr-value">
                                    → {moment(commonRentTerms.rentPeriodEndDate).format('DD.MM.YYYY')}  </span>
                                            <span className='rr-value-date-time'>
                                    {moment(commonRentTerms.rentPeriodEndDate).format('HH:mm')}</span>
                                        </div>
                                    </div>
                                    <div style={{marginRight: '100px'}}>
                                        <div className="rr-label">
                                            <span>{localize(LocalizationEnum.PAGE__OPERATIONS__CARD__SHIFT_COUNT)}
                                                <OperationParamResetButton changed={false} style={{marginLeft: 4}} />
                                            </span>
                                        </div>

                                        <div className="rr-value">
                                            <span> {
                                                <FormValue value={commonRentTerms.shiftCount} emptyText={'Не указаны'} />
                                            }</span>
                                        </div>
                                    </div>
                                    {
                                        canViewFinancialData && (
                                            <div style={{marginRight: '100px'}}>
                                                <div className="rr-label">
                                                    <span
                                                        className="history-elements-lock">{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DISCOUNT)}
                                                        <OperationParamResetButton changed={false} style={{marginLeft: 4}} />
                                                    </span>
                                                </div>
                                                <div className="rr-value">
                                                    <span>{tableCellPercentsRenderer(commonRentTerms.discount)}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>

                            </> : <div style={{height: '89px'}}>
                                <div className="rr-value" style={{marginTop: '-10px', marginLeft: '10px'}}>
                                    <span>{localize(LocalizationEnum.PAGE__OPERATIONS__CARD__COMMON_RENT_TERMS_ON_CREATION)}</span>
                                </div>
                            </div>
                    }
                </Card>

                <Card bordered={false}>
                    <div style={{marginBottom: '20px', marginTop: '-10px'}} className="rr-value">
                        <span>{localize(LocalizationEnum.PAGE__OPERATIONS__CARD__COMPOSITION_ON_CREATION)}</span>
                    </div>
                    <Grid
                        filtered={entetisAudit.length}
                        pageSize={entetisAudit.length}
                        currentPage={this.props.pageParams.page}
                        columns={columns}
                        data={entetisAudit}
                        indexFieldName={'id'}
                        entityType={'element'}
                        //hideTopPaginationInfo={false}
                        selectable={false}
                        hidePageSizeSelect
                        gridName={'operation-composition'/*this.props.gridName*/}
                        excludeColumns={excludeColumns}
                    />
                </Card>

                <OperationIndicatorsBlock showFinancialData={canViewFinancialData} instanceCount={allCount} price={allSum} discount={allDiscount}
                                          finalTotalPrice={allSum - allDiscount}/>
            </Spin>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        entities: storeState.history_elements.entities,
        commonRentTerms: storeState.history_elements.commonRentTerms,
        isLoading: storeState.history_elements.loading,
        locationSearchParams: storeState.router.location.search,
        pageParams: storeState.history_elements.params,
        filteredCount: storeState.history_elements.filteredCount,
        businessAccountId: storeState.system.businessAccountId,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions)
    };
};

const mapDispatchToProps = {loadEntities, reset, push, setPageParams};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CompositionOperations));
