import { Radio } from 'antd';
import { LocationViewType } from '../LocationsTab';
import { CSSProperties } from 'react';

interface Props {
    value: LocationViewType;
    onChange: (value: LocationViewType) => void;
    style?: CSSProperties;
}

const BUTTONS: { label: string; value: LocationViewType }[] = [
    {
        label: 'Список',
        value: 'list',
    },
    {
        label: 'Карта',
        value: 'map',
    },
];

const ViewSwitcher = ({ value, onChange, style }: Props) => {
    return (
        <div className={'radio-buttons-group-container'} style={style}>
            <div className={'radio-buttons-group'}>
                {BUTTONS.map((b) => (
                    <Radio.Button
                        value={b.value}
                        checked={b.value === value}
                        key={b.value}
                        onClick={() => onChange(b.value)}
                        style={{ minWidth: 130 }}
                    >
                        {b.label}
                    </Radio.Button>
                ))}
            </div>
        </div>
    );
};

export default ViewSwitcher;
