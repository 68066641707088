import React, {ReactNode} from 'react';
import { Button, Icon } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import { IconPlus } from '../../../icons';
import './EntityButton.less';
import classNames from 'classnames';

interface CreateEntityButtonProps extends Omit<ButtonProps, 'icon'> {
    icon?: ReactNode;
}

export const CreateEntityButton = ({icon, ...props}: CreateEntityButtonProps) => {
    return (
        <Button {...props} className={classNames('rr-create-entity-btn', props.className)}>
            {icon || <Icon component={IconPlus} />}
        </Button>
    );
};
