import React, { ReactElement } from 'react';
import { ReactTableRow } from './ReactTableRow';
import { Table } from '@tanstack/react-table';
import { DragDropContext, DragDropContextProps, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';

export type DndProps = Omit<DragDropContextProps, 'children'>;

interface IProps {
    table: Table<any>;
    columnsStr: string;

    getUniqId?(data: any): string;

    getRowProps?(state, rowInfo, column): void;

    alwaysRedraw: boolean;
    resizeMode: boolean;

    onRowClick?(entity: any): void;
    dndProps?: DndProps;
}

interface IState {}

const emptyFunction = () => {};

export class ReactTableBody extends React.Component<IProps, IState> {
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        if (this.props.resizeMode && nextProps.resizeMode) return false;
        else if (!this.props.resizeMode && nextProps.resizeMode) return false;
        else return true;
    }

    render() {
        const { table, columnsStr, dndProps } = this.props;

        const tableRows: ReactElement[] = table
            .getExpandedRowModel()
            .rows.filter((row) => row.depth === 0)
            .map((row, index) => {
                let selected: number[] = [];
                if (row.getIsSelected()) {
                    selected.push(row.original.id);
                }
                if (row.subRows) {
                    row.subRows.forEach((subRow) => {
                        if (subRow.getIsSelected()) {
                            selected.push(subRow.original.id);
                        }
                    });
                }

                let uniqId: string = '';
                if (this.props.getUniqId) {
                    uniqId = this.props.getUniqId(row.original);
                } else {
                    if (row.original.id) {
                        uniqId += row.original.id + '_';
                        if (row.original.businessVersion) uniqId += row.original.businessVersion;
                    }
                }

                return (
                    <ReactTableRow
                        getUniqId={this.props.getUniqId}
                        uniqId={uniqId}
                        index={index}
                        row={row}
                        selected={selected.toString()}
                        indeterminate={row.getIsSomeSelected()}
                        expanded={row.getIsExpanded()}
                        columns={columnsStr}
                        getRowProps={this.props.getRowProps}
                        subrow={false}
                        alwaysRedraw={this.props.alwaysRedraw}
                        onRowClick={this.props.onRowClick}
                        isDnd={Boolean(dndProps)}
                    />
                );
            });

        return (
            <div
                className="tbody rt-tbody"
                style={{
                    width: table.getTotalSize(),
                    minWidth: '100%',
                }}
            >
                <DragDropContext onDragEnd={emptyFunction} {...dndProps}>
                    <Droppable droppableId="list">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {tableRows}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }
}
