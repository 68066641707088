import { DataDisplayBlock, DataDisplayItemProps, DataDisplayItemType } from '../../../../../../../../components/dataDisplay';
import classNames from 'classnames';
import { GROUP_ROW_CLASSNAME } from '../../../../../../../../components/dataDisplay/dataDisplayGroup';
import { EMPTY_VALUE_CLASSNAME, ITEM_CLASSNAME, LABEL_CLASSNAME } from '../../../../../../../../components/dataDisplay/dataDisplayItem';
import { CrewMemberLink } from '../CrewMemberLink/CrewMemberLink';
import { ContactMaps } from '../../../utils/maps';
import { PageUtils } from '../../../../../../../../shared/util/pageUtils';
import { CrewMemberUtils } from '../../../../../../crew/tabs/crewMembers/utils/helpers';
import { AttachmentsBlock } from '../../../../../../../../components/attachmentsBlock/attachmentsBlock';
import { AttachmentParentEntityTypeCodeEnum, ContactInfoRead } from '../../../../../../../../server';
import React, { FC } from 'react';
import { useMainBlockItem } from '../../hooks/ContactDrawerHooks';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../../../shared/reducers/system.reducer';
import { DrawerUtils } from '../../../../../../../../shared/util/drawerUtils';
import ContactReferences from '../ContactReferences/ContactReferences';

interface ContactDataDisplayBlocksProps {
    contact: ContactInfoRead;
    refetchContact: () => void;
    refetchList?: () => void;
}

export const ContactDataDisplayBlocks: FC<ContactDataDisplayBlocksProps> = (props) => {
    const { contact, refetchContact, refetchList } = props;

    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const mainBlockItem = useMainBlockItem(contact);

    const { isBusinessAccountRRUser, isCrewMember, crewMemberId, baRoles, crewMemberProfessions, creationAuthorFullName } = contact;

    const authorFullName = creationAuthorFullName ? DrawerUtils.getFullName(creationAuthorFullName) : undefined;

    const isBARolesVisible = isBusinessAccountRRUser && baRoles;
    const isCrewMemberProfessionsVisible = isCrewMember && crewMemberId;

    const crewMemberProfessionsItems = isCrewMemberProfessionsVisible
        ? crewMemberProfessions?.length
            ? crewMemberProfessions
            : ['Проектный работник']
        : [];

    const socialNetworks: DataDisplayItemProps[][] = [];

    let group: DataDisplayItemProps[] = [];
    contact.socialNetworks?.forEach((socialNetwork, index, arr) => {
        group.push({
            label: ContactMaps.socialNetworksMap[socialNetwork.type],
            type: DataDisplayItemType.SocialNetworkLink,
            value: socialNetwork,
        });
        if (group.length === 2 || index === arr.length - 1) {
            socialNetworks.push(group);
            group = [];
        }
    });

    return (
        <>
            <DataDisplayBlock data={mainBlockItem} />

            {isBARolesVisible || isCrewMemberProfessionsVisible ? (
                <DataDisplayBlock
                    data={[
                        [
                            isBARolesVisible
                                ? {
                                      type: DataDisplayItemType.Custom,
                                      value: (
                                          <div className={classNames(GROUP_ROW_CLASSNAME, 'items-gap')}>
                                              <div className={ITEM_CLASSNAME}>
                                                  <div className={classNames(LABEL_CLASSNAME)}>Роль пользователя в системе</div>
                                                  <CrewMemberLink
                                                      items={[ContactMaps.businessAccountRoleMap[baRoles[0]]]}
                                                      link={PageUtils.getPathForPushOnPageTab(
                                                          `/${businessAccountId}/users`,
                                                          String(contact.userId)
                                                      )}
                                                      linkText={'Смотреть профиль'}
                                                  />
                                              </div>
                                          </div>
                                      ),
                                  }
                                : {
                                      label: 'Роль пользователя в системе',
                                      type: DataDisplayItemType.String,
                                      value: undefined,
                                      emptyValueText: 'Не является пользователем',
                                  },
                            isCrewMemberProfessionsVisible
                                ? {
                                      type: DataDisplayItemType.Custom,
                                      value: (
                                          <div className={classNames(GROUP_ROW_CLASSNAME, 'items-gap')}>
                                              <div className={ITEM_CLASSNAME}>
                                                  <div className={classNames(LABEL_CLASSNAME)}>Специальности проектного работника</div>
                                                  <CrewMemberLink
                                                      items={crewMemberProfessionsItems}
                                                      link={PageUtils.getPathForPushOnPageTab(
                                                          CrewMemberUtils.getRootPath(businessAccountId),
                                                          String(crewMemberId)
                                                      )}
                                                      linkText={'Смотреть карточку'}
                                                  />
                                              </div>
                                          </div>
                                      ),
                                  }
                                : {
                                      label: 'Специальности проектного работника',
                                      type: DataDisplayItemType.String,
                                      value: undefined,
                                      emptyValueText: 'Не является работником',
                                  },
                        ],
                        [
                            {
                                type: DataDisplayItemType.Custom,
                                value: (
                                    <div className={classNames(GROUP_ROW_CLASSNAME, 'items-gap')} style={{ marginTop: 10 }}>
                                        <div className={ITEM_CLASSNAME}>
                                            <div className={classNames(LABEL_CLASSNAME)}>Ссылки на контакт</div>
                                            <ContactReferences contactId={contact.id} count={contact.referenceCount} />
                                        </div>
                                    </div>
                                ),
                            },
                        ],
                    ]}
                />
            ) : (
                <DataDisplayBlock
                    data={[
                        [
                            {
                                type: DataDisplayItemType.Custom,
                                value: (
                                    <div className={classNames(GROUP_ROW_CLASSNAME, 'items-gap')}>
                                        <div className={ITEM_CLASSNAME}>
                                            <div className={classNames(LABEL_CLASSNAME)}>Ссылки на контакт</div>
                                            <ContactReferences contactId={contact.id} count={contact.referenceCount} />
                                        </div>
                                    </div>
                                ),
                            },
                            {
                                type: DataDisplayItemType.Custom,
                                value: (
                                    <span
                                        className={EMPTY_VALUE_CLASSNAME}
                                        style={{ marginLeft: -20, whiteSpace: 'normal', display: 'block' }}
                                    >
                                        Не является пользователем системы или проектным работником
                                    </span>
                                ),
                            },
                        ],
                    ]}
                    className={'rr-dd-b-p-s'}
                />
            )}

            <DataDisplayBlock
                data={[
                    [
                        {
                            label: 'Номер паспорта',
                            type: DataDisplayItemType.String,
                            value: contact.passport,
                        },
                    ],
                    ...socialNetworks,
                ]}
            />

            <DataDisplayBlock
                data={[
                    [
                        {
                            type: DataDisplayItemType.Custom,
                            value: (
                                <AttachmentsBlock
                                    attachments={contact.attachments?.records ?? []}
                                    parentEntityId={contact.id!}
                                    parentEntityTypeCode={AttachmentParentEntityTypeCodeEnum.CONTACT}
                                    updateParentEntity={() => {
                                        refetchContact();
                                        if (refetchList) refetchList();
                                    }}
                                />
                            ),
                        },
                    ],
                ]}
                paddings={false}
            />

            <DataDisplayBlock
                data={[
                    [
                        {
                            label: 'Дата последнего изменения',
                            type: DataDisplayItemType.DateWithTime,
                            value: contact.lastUpdateDate,
                        },
                        {
                            label: 'Автор последнего изменения',
                            type: DataDisplayItemType.String,
                            value: authorFullName,
                        },
                    ],
                ]}
            />

            <DataDisplayBlock
                data={[
                    [
                        {
                            label: 'Комментарий',
                            type: DataDisplayItemType.Comment,
                            value: contact.comment,
                        },
                    ],
                ]}
            />
        </>
    );
};
