import React, { FC } from 'react';
import { EntityList } from '../../../../components/page/entityGrid/components/ui/EntityList/EntityList';
import paymentsColumns from '../columns/paymentsListColumns';
import { paymentsFilters } from '../filters/paymentsFilters';
import { HistoryPageTabsEnum } from '../../../../shared/constants/tabEnums';
import { EntityGridRootPath } from '../../../../components/page/entityGrid/types/params';
import { useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { historyPageUrlRoute } from '../../history/HistoryPage';
import { PaymentsFiltersDescription } from '../filters/paymentsFilters.description';
import { usePageURLParamsFromDescription, useQueryParamsWithoutModalParams } from '../../../../components/page/entityGrid/hooks/useParams';
import { useListPaymentsQuery, useTransitWorkflowBulkMutation } from '../api/payments.api';
import { useExportBlock } from '../hooks/useExportBlock';
import { useEntityActions } from '../hooks/useEntityActions';
import { PaymentCreateModal } from '../components/modal/paymentCreateModal';
import { Route } from 'react-router';
import { withEntityListProviders } from '../../../../components/page/entityGrid/components/decorators/withEntityListProviders';
import { subrentModuleEnabledSelector } from '../../../../shared/reducers/businessAccountPreferences.reducer';
import { PaymentDrawerWrapper } from '../components/drawer/wrappers/PaymentDrawerWrapper';

const gridName = HistoryPageTabsEnum.PAYMENTS;

interface PaymentListProps {}

export const PaymentListComponent: FC<PaymentListProps> = (props) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);

    const pageParamsObject = usePageURLParamsFromDescription({
        pageParamsDescription: PaymentsFiltersDescription,
        gridName,
    });

    const { pageParams } = pageParamsObject;
    const queryParams = useQueryParamsWithoutModalParams(pageParams);
    const queryData = useListPaymentsQuery(
        {
            params: queryParams,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const [transitWorkflowBulk, { isLoading: transitWorkflowBulkIsLoading }] = useTransitWorkflowBulkMutation();

    const entitiesLoading = transitWorkflowBulkIsLoading || queryData.isLoading;

    const rootPath = `/${businessAccountId}/${historyPageUrlRoute}/${gridName}` satisfies EntityGridRootPath;

    const exportBlock = useExportBlock(pageParams);

    const entityActions = useEntityActions(rootPath, pageParamsObject, transitWorkflowBulk);

    const filtersExcludeFields = subrentModuleEnabled ? undefined : ['subrentId'];

    return (
        <>
            <EntityList
                pageParamsObject={pageParamsObject}
                gridProps={{
                    static: {
                        columns: paymentsColumns,
                        filtersData: paymentsFilters,
                        pageParamsDescription: PaymentsFiltersDescription,
                    },
                    metaData: {
                        entityType: 'payment',
                        gridName,
                        rootPath,
                    },
                    queryData,
                    entitiesLoading,
                    entityActions,
                    exportBlock,
                    filtersExcludeFields,
                    selectable: false,
                    pinned: { right: ['actions'] },
                }}
                drawerProps={{
                    drawer: PaymentDrawerWrapper,
                    entityActions,
                }}
            />
            <Route
                exact
                path={`/${businessAccountId}/history/payments/:id/edit`}
                render={(props) => {
                    return <PaymentCreateModal paymentId={+props.match.params.id} />;
                }}
            />
        </>
    );
};

export const PaymentsList = withEntityListProviders(PaymentListComponent);
