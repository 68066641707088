import React from 'react';
import { HelpTooltip } from '../../../../../components/helpTooltip/HelpTooltip';
import { LocalizationEnum, localize } from '../../../../../localization';
import { Col, Icon, Row } from 'antd';
import FormValue from '../../../../../components/formValue/FormValue';
import { formatMoney } from '../../../../../shared/util/formatMoney';
import { isDefined } from '../../../../../shared/util/utils';
import './element-popover-footer.less';
import { PricingSchemePricePopover } from '../../../settings/pricingSchemes/pricingSchemePricePopover';
import { PricingSchemeMaps } from '../../../../../core/utils/pricingSchemeUtils';

interface IProps {
    shiftPrice?: number;
    finalTotalPrice?: number;
    effectivePrice?: number;

    basePrice: number;
    shiftCount?: number;
    pricingScheme?: any;
}

export class ElementPopoverFooter extends React.PureComponent<IProps> {
    render() {
        let { shiftPrice, finalTotalPrice, effectivePrice, pricingScheme, basePrice, shiftCount } = this.props;

        return (
            <Row gutter={0} type={'flex'} className={'rr-element-popover-footer'}>
                <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__009}>
                    <Col span={7} className="rr-form-item">
                        <div className="rr-label">{localize(LocalizationEnum.ASPECT__GRID__COLUMN__PRICE_INSTANCE_PER_SHIFT)}</div>
                        <div className="rr-value">
                            {pricingScheme ? (
                                <PricingSchemePricePopover
                                    data={{
                                        id: pricingScheme.id,
                                        typeCode: pricingScheme.typeCode,
                                        label: pricingScheme.name || '',
                                        price: basePrice,
                                        steps: pricingScheme?.steps || [],
                                        shiftCount,
                                        roundingLevel: pricingScheme?.roundingLevel,
                                        roundingDirection: pricingScheme?.roundingDirection,
                                    }}
                                >
                                    <Icon
                                        style={{ fontSize: 22, color: '#3d64d4', marginRight: 4, marginTop: -3, verticalAlign: 'middle' }}
                                        component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[pricingScheme.typeCode]}
                                    />
                                </PricingSchemePricePopover>
                            ) : null}
                            <FormValue
                                value={isDefined(effectivePrice) ? formatMoney(effectivePrice) : undefined}
                                originalValue={effectivePrice}
                                emptyText={'?'}
                            />
                        </div>
                    </Col>
                </HelpTooltip>
                <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__012}>
                    <Col span={7} className="rr-form-item">
                        <div className="rr-label">{localize(LocalizationEnum.ASPECT__GRID__COLUMN__COST_INSTANCE_PER_SHIFT)}</div>
                        <div className="rr-value">
                            <FormValue
                                value={isDefined(shiftPrice) ? formatMoney(shiftPrice) : undefined}
                                originalValue={shiftPrice}
                                emptyText={'?'}
                            />
                        </div>
                    </Col>
                </HelpTooltip>
                <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__014}>
                    <Col span={10} className="rr-form-item">
                        <div className="rr-label">{localize(LocalizationEnum.ASPECT__GRID__COLUMN__FINAL_TOTAL_PRICE)}</div>
                        <div className={'rr-value'}>
                            <FormValue
                                value={isDefined(finalTotalPrice) ? formatMoney(finalTotalPrice) : undefined}
                                originalValue={finalTotalPrice}
                                emptyText={'?'}
                            />
                        </div>
                    </Col>
                </HelpTooltip>
            </Row>
        );
    }
}
