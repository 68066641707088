import React from 'react';
import { Alert, Tabs } from 'antd';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { push } from 'connected-react-router';
import { IRootState } from '../../../../shared/reducers';
import { Spin } from '../../../../components';
import { ProjectPageDescriptionTabPane } from '../../projects/production/page/tabs/description/project-page-description';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { getPathFromState, getShiftCountFromDates, getStateFromPath, isStringInEnum } from '../../../../shared/util/utils';
import { BasePage, IBasePageProps } from '../../../../components/page/BasePage';
import { IPageHeaderProps } from '../../../../components/pageHeader/pageHeader';
import { OperationsList } from '../../operations/operationsList';
import { fullResetOperation, resetOperationForm, startNewOperation } from '../../operationForm/reducers/operationForm.reducer';
import {
    OperationTypeCodeEnum,
    ProjectStateCodeEnum,
    ProjectTransitionCodeEnum,
    ProjectTypeCodeEnum,
    RentActivityFrameTypeCodeEnum,
    RentStateCodeEnum,
} from '../../../../server';
import { LocalizationEnum, localize, localizeIntl } from '../../../../localization';
import { showActualizeDatesConfirm } from '../../../../shared/util/showActualizeDatesConfirm';
import moment from 'moment';
import { getCurrentServerTime } from '../../../../shared/reducers/system.reducer';
import { Moment } from 'moment/moment';
import { canAddNewProductsToOperation } from '../../operationForm/utils/utils';
import { canViewFinancialData } from '../../../../shared/util/permissionUtils';
import { listConcurrentOperationsAndShowConfirm } from '../../operationForm/listConcurrentOperationsAndShowConfirm';
import { getCurrentTabsSelector, saveCurrentTabToStore } from '../../../../shared/reducers/userSettings/userSettings.reducer';
import {
    crewModuleEnabledSelector,
    expensesModuleEnabledSelector,
    offersModuleEnabledSelector,
    statisticsModuleEnabledSelector,
} from '../../../../shared/reducers/businessAccountPreferences.reducer';
import { ExpensesList } from '../../expenses/list/expensesList';
import { getGridStorageData } from '../../../../components/grid/utils';
import { ParamsUtils } from '../../../../core/utils/paramsUtils';
import { WorkPlanningsList } from '../../workPlannings/list/workPlanningsList';
import { ActionButtonsGroup } from '../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup';
import { OfferEditModalWrapper } from '../modal/edit/offerEditModalWrapper';
import { includeOrExcludeOfferInProjectPriceBulk, setStatusForOffers } from '../reducers/offers/offers.reducer';
import { getStore } from '../../../../../index';
import { clearOffer, loadOffer } from '../reducers/offer/offer.reducer';
import { projectsPageUrlRoute } from '../../projects/projectsPage';
import { offerActionButtons, OfferActionType } from '../data/offersData';
import { canUpdateFinancialDataSelector } from '../../../../shared/reducers/permissions.reducer';
import { InfoSelectDropdown } from '../../../../components/pageHeader/components/InfoSelectDropdown/InfoSelectDropdown';
import { InterfaceBlockCode } from '../../../../components/pageHeader/components/InfoSelectDropdown/utils/constants';

import { OfferPageTabsEnum, ProjectPageTabsEnum, ProjectsPageTabsEnum } from '../../../../shared/constants/tabEnums';
import { OfferPageHeaderNavigationBlock } from '../components/HeaderNavigationBlock/OfferPageHeaderNavigationBlock';
import { OfferCreateModalWrapper } from '../modal/create/offerCreateModalWrapper';
import { ElementsListWrapper } from '../../elements/elementsListWrapper';
import { EditOperationHeaderResetButton } from '../../../../components/buttons/editOperationResetButton/editOperationHeaderResetButton';
import {getDefaultRentPeriod} from "../../../../utils/timeUtils/timeUtils";

interface State {
    operationIsLoading: boolean;
    selectTemplatePopoverOpened: boolean;
}

interface OfferPageProps extends StateProps, DispatchProps, IBasePageProps {}

const defaultTab = OfferPageTabsEnum.ELEMENTS;

export enum OfferPageGridNamesEnum {
    OPERATIONS = 'offer-operations',
    ELEMENTS = 'offer-elements',
    EXPENSES = 'offer-expenses',
    WORK_PLANNINGS = 'offer-workPlannings',
}

export const projectGridNamesMap: Record<Exclude<OfferPageTabsEnum, OfferPageTabsEnum.DESCRIPTION>, OfferPageGridNamesEnum> = {
    [OfferPageTabsEnum.OPERATIONS]: OfferPageGridNamesEnum.OPERATIONS,
    [OfferPageTabsEnum.ELEMENTS]: OfferPageGridNamesEnum.ELEMENTS,
    [OfferPageTabsEnum.EXPENSES]: OfferPageGridNamesEnum.EXPENSES,
    [OfferPageTabsEnum.WORK_PLANNINGS]: OfferPageGridNamesEnum.WORK_PLANNINGS,
};

class _OfferPage extends BasePage<OfferPageProps, State> {
    constructor(props: OfferPageProps) {
        super(props);
        this.props.resetOperationForm();

        this.state = {
            operationIsLoading: false,
            selectTemplatePopoverOpened: false,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        void this.loadOffer();
    }

    loadOffer = async () => {
        await getStore().dispatch(loadOffer({ businessAccountId: this.props.businessAccountId, entityId: this.props.entityId }));
    };

    async componentDidUpdate(prevProps: OfferPageProps) {
        if (this.props.tab) saveCurrentTabToStore('offer', this.props.tab);

        if (prevProps.entityId !== this.props.entityId) {
            this.props.clearOffer();
            await getStore().dispatch(loadOffer({ businessAccountId: this.props.businessAccountId, entityId: this.props.entityId }));
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.clearOffer();
    }

    onTabsChange = (key: string) => {
        const tabParams = this.getTabParams(key as OfferPageTabsEnum);

        saveCurrentTabToStore('offer', key);

        if (key === OfferPageTabsEnum.EXPENSES || key === OfferPageTabsEnum.WORK_PLANNINGS) {
            let gridName = projectGridNamesMap[key];
            if (gridName) {
                let gridData = getGridStorageData(gridName);
                this.props.push(
                    getPathFromState(this.props.location.pathname, '', {
                        tab: key,
                        ...gridData.filters,
                        ...gridData.params,
                    })
                );
            }
        } else {
            this.props.push(getPathFromState(this.props.location.pathname, '', { ...tabParams, tab: key }));
        }
    };

    onEditButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/projects/offers/${this.props.entityId}/edit`);
    };

    onCopyButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/projects/offers/${this.props.entityId}/copy`);
    };

    changeStatus = (transitionCode: ProjectTransitionCodeEnum) => {
        let { entity, businessAccountId } = this.props;
        if (entity) {
            getStore().dispatch(
                setStatusForOffers({
                    businessAccountId,
                    workflowDirectiveBulk: {
                        directives: [
                            {
                                id: entity.id,
                                businessVersion: entity.businessVersion,
                                transitionCode,
                            },
                        ],
                    },
                })
            );
        }
    };

    includeOrExcludeOfferInProjectPriceBulk = () => {
        let { entity, businessAccountId } = this.props;
        if (entity) {
            getStore().dispatch(
                includeOrExcludeOfferInProjectPriceBulk({
                    businessAccountId,
                    include: !entity.partOfThePrice,
                    versionedEntityObjList: {
                        entities: [{ id: entity.id, businessVersion: entity.businessVersion }],
                    },
                })
            );
        }
    };

    createNewOperation = async (code: OperationTypeCodeEnum, correctionCode?: RentStateCodeEnum | undefined) => {
        if (this.props.entity) {
            let canCreate = true;
            if (this.props.operationFormMnemoKey) {
                canCreate = await showConfirm(
                    this.props.intl,
                    localizeIntl(this.props.intl, LocalizationEnum.PAGE__OPERATIONS__MODAL__QUESTIONS__CANCEL)
                );
            }

            if (canCreate) {
                try {
                    canCreate = await listConcurrentOperationsAndShowConfirm(
                        this.props.intl,
                        this.props.businessAccountId,
                        this.props.entity.id,
                        RentActivityFrameTypeCodeEnum.PROJECT
                    );
                } catch (e) {
                    return;
                }
            }

            if (canCreate) {
                let defaultStartDate: Moment = moment(getCurrentServerTime()) /*.seconds(0)*/
                    .milliseconds(0);
                let setActualDateRes: { setActualDate: boolean; recalculateShiftCount: boolean } = {
                    setActualDate: false,
                    recalculateShiftCount: false,
                };
                let startD: Moment | undefined;
                let endD: Moment | undefined;
                let discount = this.props.entity.defaultDiscount;
                let shiftCount: number | undefined = undefined;

                if (this.props.entity.startDate && this.props.entity.targetFinishDate) {
                    startD = moment(this.props.entity.startDate).seconds(0).milliseconds(0);
                    endD = moment(this.props.entity.targetFinishDate).seconds(0).milliseconds(0);
                } else {
                    // startD = moment(defaultStartDate);
                    // endD = moment(defaultStartDate).add(7, 'd');
                    const dates = getDefaultRentPeriod(defaultStartDate);
                    startD = dates.startDate;
                    endD = dates.endDate;
                }

                if (code === OperationTypeCodeEnum.RENT) {
                    setActualDateRes = await showActualizeDatesConfirm(this.props.intl, 'Установить текущую дату выдачи?', 'rent');
                    if (setActualDateRes.setActualDate) {
                        startD = moment(defaultStartDate);
                        shiftCount = setActualDateRes.recalculateShiftCount
                            ? getShiftCountFromDates(
                                  undefined,
                                  startD,
                                  endD,
                                  this.props.defaultShiftLengthInMinutes,
                                  this.props.shiftCountRoundingType
                              )
                            : shiftCount;
                    }
                } else if (code === OperationTypeCodeEnum.RETURN) {
                    setActualDateRes = await showActualizeDatesConfirm(this.props.intl, 'Установить текущую дату возврата?', 'return');
                    if (setActualDateRes.setActualDate) {
                        endD = moment(defaultStartDate);
                        shiftCount = setActualDateRes.recalculateShiftCount
                            ? getShiftCountFromDates(
                                  undefined,
                                  startD,
                                  endD,
                                  this.props.defaultShiftLengthInMinutes,
                                  this.props.shiftCountRoundingType
                              )
                            : shiftCount;
                    }
                }

                await this.props.fullResetOperation();

                this.props.startNewOperation(
                    undefined,
                    this.props.entity.taxRate,
                    this.props.entity.taxBaseType,
                    false,
                    this.props.entity.renterId,
                    this.props.entity.renterShortName,
                    this.props.entity.id,
                    this.props.entity.shortName,
                    ProjectTypeCodeEnum.OFFER,
                    code,
                    [],
                    'mnemoKey',
                    !true,
                    discount,
                    correctionCode,
                    undefined,
                    undefined,
                    [],
                    {},
                    setActualDateRes.setActualDate,
                    setActualDateRes.recalculateShiftCount,
                    shiftCount,
                    startD?.toDate(),
                    endD?.toDate(),
                    defaultStartDate.toDate()
                );
                if (canAddNewProductsToOperation(code)) {
                    this.props.push(`/${this.props.businessAccountId}/operation?tab=products`);
                } else {
                    this.props.push(`/${this.props.businessAccountId}/operation?tab=equipment`);
                }
            }
        }
    };

    createExpense = () => {
        const urlSearchParams = ParamsUtils.getURLSearchParams(this.props.location);
        const searchParams = new URLSearchParams(urlSearchParams);
        searchParams.append('modal', 'new');
        searchParams.set('tab', OfferPageTabsEnum.EXPENSES);
        const paramsString = searchParams.toString() ? `?${searchParams.toString()}` : '';
        this.props.push(`/${this.props.businessAccountId}/projects/offers/${this.props.entityId}${paramsString}`);
    };

    createWorkPlanning = () => {
        const urlSearchParams = ParamsUtils.getURLSearchParams(this.props.location);
        const searchParams = new URLSearchParams(urlSearchParams);
        searchParams.append('modal', 'new');
        searchParams.set('tab', OfferPageTabsEnum.WORK_PLANNINGS);
        const paramsString = searchParams.toString() ? `?${searchParams.toString()}` : '';
        this.props.push(`/${this.props.businessAccountId}/projects/offers/${this.props.entityId}${paramsString}`);
    };

    getPageHeaderProps(): IPageHeaderProps {
        let { entity, statisticsModuleEnabled, tab } = this.props;

        return {
            archive: !!(entity && entity.archive),
            name: entity && entity.fullName ? entity.fullName : entity?.shortName,
            shortName: !(entity && entity.fullName),
            businessAccountId: this.props.businessAccountId,
            parentPagePath: entity ? `/${this.props.businessAccountId}/projects/offers` : undefined,
            reportReferenceEntityId: statisticsModuleEnabled ? this.props.entityId : undefined,
            rightBlock: (
                <>
                    {tab === OfferPageTabsEnum.ELEMENTS && <EditOperationHeaderResetButton entityId={entity?.id} />}
                    <InfoSelectDropdown interfaceBlockCode={InterfaceBlockCode.BA_RENT_OFFERS_OFFER_CARD} />
                </>
            ),
            NavigationBlock: <OfferPageHeaderNavigationBlock offer={entity || undefined} />,
        };
    }

    getTabParams(tab: OfferPageTabsEnum) {
        let gridName = projectGridNamesMap[tab];
        if (gridName) {
            const storageData = getGridStorageData(gridName);
            return {
                ...storageData.filters,
                ...storageData.params,
            };
        } else {
            return {};
        }
    }

    get currentTab() {
        return new URLSearchParams(this.props.location.search).get('tab');
    }

    renderContent() {
        let { entity, loading, updating, canViewFinancialData, crewModuleEnabled, expensesModuleEnabled, offersModuleEnabled } = this.props;
        const { currentTab } = this;

        if (currentTab == null) {
            const tab = isStringInEnum(this.props.tabFromStore, OfferPageTabsEnum, defaultTab);
            return (
                <Redirect
                    to={getPathFromState(this.props.location.pathname, '', {
                        ...this.getTabParams(tab),
                        tab: tab,
                    })}
                />
            );
        }

        /**
         * Когда переходим в раздел из меню и вкладка не выбрана
         * ИЛИ Когда открываем урл и там вкладка прописана:
         * Если модуль отключен и вкладку прячем, то делаем редирект на вкладку по умолчанию
         */
        if (
            (currentTab == null &&
                ((this.props.tabFromStore === ProjectPageTabsEnum.EXPENSES && !expensesModuleEnabled) ||
                    (this.props.tabFromStore === OfferPageTabsEnum.WORK_PLANNINGS && !crewModuleEnabled))) ||
            (currentTab != null &&
                ((currentTab === ProjectPageTabsEnum.EXPENSES && !expensesModuleEnabled) ||
                    (currentTab === OfferPageTabsEnum.WORK_PLANNINGS && !crewModuleEnabled)))
        ) {
            return (
                <Redirect
                    to={getPathFromState(this.props.location.pathname, '', {
                        ...this.getTabParams(defaultTab),
                        tab: defaultTab,
                    })}
                />
            );
        }

        // Когда нет параметров в урле и есть сохраненные данные для грида, то редиректим
        if (currentTab === OfferPageTabsEnum.EXPENSES || currentTab === OfferPageTabsEnum.WORK_PLANNINGS) {
            if (
                Array.from(new URLSearchParams(this.props.location.search).keys()).filter((p) => !['tab', 'modal'].includes(p)).length === 0
            ) {
                let gridName = projectGridNamesMap[currentTab];
                if (gridName) {
                    const gridData = getGridStorageData(gridName);
                    if (
                        (gridData.filters && Object.keys(gridData.filters).filter((k) => k !== 'tab' && k !== 'modal').length > 0) ||
                        (gridData.params && Object.keys(gridData.params).length > 0)
                    ) {
                        const redirectPath = getPathFromState(this.props.location.pathname, this.props.location.search, {
                            tab: currentTab,
                            ...gridData.filters,
                            ...gridData.params,
                        });
                        return <Redirect to={redirectPath} />;
                    }
                }
            }
        }

        let err;
        if (this.props.loadingError) {
            err = (
                <>
                    <div>
                        <strong>{this.props.loadingError.title}</strong>
                    </div>
                    <div>{this.props.loadingError.message}</div>
                </>
            );
        }

        if (entity) {
            if (entity.projectType === ProjectTypeCodeEnum.BASE) {
                if (entity.isSimpleOrder) {
                    return (
                        <Redirect
                            to={`/${this.props.businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.SIMPLE_ORDERS}/${entity.id}`}
                        />
                    );
                } else {
                    return (
                        <Redirect
                            to={`/${this.props.businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.PROJECTS}/${entity.id}`}
                        />
                    );
                }
            }
        }

        const offerIsCancelled = entity?.stateCode === ProjectStateCodeEnum.CANCELED;

        return (
            <>
                <Spin spinning={loading || updating || this.state.operationIsLoading}>
                    <div className={'rr-page-renter'} style={{ minHeight: 400 }}>
                        {err ? (
                            <Alert
                                message={localize(LocalizationEnum.ASPECT__GLOBAL__ERROR)}
                                description={err}
                                type="error"
                                showIcon
                                closable
                                style={{ marginBottom: 30 }}
                            />
                        ) : null}

                        {entity ? (
                            <div className="card-container">
                                <Tabs
                                    defaultActiveKey={'description'}
                                    activeKey={this.props.tab}
                                    onChange={this.onTabsChange}
                                    className={'rr-tabs'}
                                    type="line"
                                    animated={false}
                                    tabBarExtraContent={
                                        <ActionButtonsGroup
                                            data={offerActionButtons}
                                            mainActions={[
                                                this.props.tab !== OfferPageTabsEnum.EXPENSES &&
                                                this.props.tab !== OfferPageTabsEnum.WORK_PLANNINGS &&
                                                entity?.stateCode !== ProjectStateCodeEnum.CANCELED
                                                    ? OfferActionType.createDraft
                                                    : undefined,
                                                this.props.tab === OfferPageTabsEnum.EXPENSES
                                                    ? {
                                                          type: OfferActionType.createExpense,
                                                          disabled: offerIsCancelled,
                                                          tooltip: offerIsCancelled ? 'В отмененной смете действие недоступно' : undefined,
                                                      }
                                                    : undefined,
                                                this.props.tab === OfferPageTabsEnum.WORK_PLANNINGS
                                                    ? {
                                                          type: OfferActionType.createWorkPlanning,
                                                          disabled: offerIsCancelled,
                                                          tooltip: offerIsCancelled ? 'В отмененной смете действие недоступно' : undefined,
                                                      }
                                                    : undefined,
                                                entity?.availableTransitionCodes?.includes(ProjectTransitionCodeEnum.APPROVE)
                                                    ? OfferActionType.approve
                                                    : undefined,
                                            ]}
                                            additionalActions={[
                                                OfferActionType.edit,
                                                offersModuleEnabled ? OfferActionType.duplicate : undefined,
                                                entity?.availableTransitionCodes?.includes(ProjectTransitionCodeEnum.TOCOORDINATION)
                                                    ? OfferActionType.toCoordination
                                                    : undefined,
                                                entity?.availableTransitionCodes?.includes(ProjectTransitionCodeEnum.RETURNTODRAFT)
                                                    ? OfferActionType.returnToDraft
                                                    : undefined,
                                                entity?.availableTransitionCodes?.includes(ProjectTransitionCodeEnum.CANCEL)
                                                    ? OfferActionType.cancel
                                                    : undefined,
                                                this.props.canUpdateFinancialData
                                                    ? !entity.partOfThePrice
                                                        ? OfferActionType.includeOfferInProjectPrice
                                                        : OfferActionType.excludeOfferInProjectPrice
                                                    : undefined,
                                            ]}
                                            onAction={(action) => {
                                                if (action === OfferActionType.edit) {
                                                    this.onEditButtonClick();
                                                } else if (action === OfferActionType.duplicate) {
                                                    this.onCopyButtonClick();
                                                } else if (action === OfferActionType.approve) {
                                                    this.changeStatus(ProjectTransitionCodeEnum.APPROVE);
                                                } else if (action === OfferActionType.cancel) {
                                                    this.changeStatus(ProjectTransitionCodeEnum.CANCEL);
                                                } else if (action === OfferActionType.returnToDraft) {
                                                    this.changeStatus(ProjectTransitionCodeEnum.RETURNTODRAFT);
                                                } else if (action === OfferActionType.toCoordination) {
                                                    this.changeStatus(ProjectTransitionCodeEnum.TOCOORDINATION);
                                                } else if (action === OfferActionType.createDraft) {
                                                    this.createNewOperation(OperationTypeCodeEnum.DRAFT);
                                                } else if (action === OfferActionType.createExpense) {
                                                    this.createExpense();
                                                } else if (action === OfferActionType.createWorkPlanning) {
                                                    this.createWorkPlanning();
                                                } else if (
                                                    action === OfferActionType.includeOfferInProjectPrice ||
                                                    action === OfferActionType.excludeOfferInProjectPrice
                                                ) {
                                                    this.includeOrExcludeOfferInProjectPriceBulk();
                                                }
                                            }}
                                        />
                                    }
                                >
                                    <Tabs.TabPane
                                        tab={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}
                                        key={OfferPageTabsEnum.DESCRIPTION}
                                    >
                                        <ProjectPageDescriptionTabPane entity={this.props.entity} isOffer />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane
                                        tab={localize(LocalizationEnum.ASPECT__ENTITY_TYPE__ELEMENTS)}
                                        key={OfferPageTabsEnum.ELEMENTS}
                                        disabled={false}
                                    >
                                        <ElementsListWrapper
                                            gridName={'offer-elements'}
                                            visible={this.props.tab === OfferPageTabsEnum.ELEMENTS}
                                            parentType={'project'}
                                            parentId={entity.id}
                                            location={this.props.location}
                                            parentProjectEntity={entity}
                                            updateParentEntity={() => {
                                                void this.loadOffer();
                                            }}
                                        />
                                    </Tabs.TabPane>
                                    {crewModuleEnabled && (
                                        <Tabs.TabPane tab={'Работы'} key={OfferPageTabsEnum.WORK_PLANNINGS}>
                                            <WorkPlanningsList
                                                gridName={OfferPageGridNamesEnum.WORK_PLANNINGS}
                                                visible={this.props.tab === OfferPageTabsEnum.WORK_PLANNINGS}
                                                projectId={entity.id}
                                                parentProjectEntity={entity}
                                                urlPath={`projects/offers/${this.props.entityId}?tab=workPlannings`}
                                                staticPageParams={{
                                                    projectId: entity.id,
                                                }}
                                                modalProps={{
                                                    initialValues: {
                                                        project: entity.id,
                                                        projectStateCode: entity.stateCode,
                                                    },
                                                }}
                                            />
                                        </Tabs.TabPane>
                                    )}
                                    {canViewFinancialData && expensesModuleEnabled && (
                                        <Tabs.TabPane tab={'Затраты'} key={OfferPageTabsEnum.EXPENSES}>
                                            <ExpensesList
                                                gridName={OfferPageGridNamesEnum.EXPENSES}
                                                visible={this.props.tab === OfferPageTabsEnum.EXPENSES}
                                                parentProjectEntity={entity}
                                                urlPath={`projects/offers/${this.props.entityId}?tab=expenses`}
                                                gridProps={{
                                                    filtersExcludeFields: ['projectId'],
                                                    excludeColumns: ['projectId'],
                                                }}
                                                staticPageParams={{
                                                    projectId: entity.id,
                                                }}
                                            />
                                        </Tabs.TabPane>
                                    )}
                                    <Tabs.TabPane
                                        tab={localize(LocalizationEnum.ASPECT__ENTITY_TYPE__OPERATIONS)}
                                        key={OfferPageTabsEnum.OPERATIONS}
                                        disabled={false}
                                    >
                                        <OperationsList
                                            gridName={'offer-operations'}
                                            location={this.props.location}
                                            projectId={entity.id}
                                            parentEntityType={'project'}
                                        />
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        ) : null}
                    </div>
                </Spin>

                {this.props.editMode && entity ? <OfferEditModalWrapper entity={entity} /> : null}
                {this.props.copyMode && entity ? <OfferCreateModalWrapper entity={entity} /> : null}
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    let searchParams = getStateFromPath(ownProps.location.search);
    return {
        entity: storeState.offer.entity,
        loading: storeState.offer.loading,
        updating: storeState.offer.updating,

        loadingError: storeState.offer.loadingError,
        businessAccountId: +ownProps.match.params['businessAccountId'] || 0,
        entityId: +ownProps.match.params['id'] || 0,
        editMode: !!(storeState.offer.entity && ownProps.match.params['action'] === 'edit'),
        copyMode: !!(storeState.offer.entity && ownProps.match.params['action'] === 'copy'),
        tab:
            searchParams.tab &&
            (searchParams.tab === OfferPageTabsEnum.DESCRIPTION ||
                searchParams.tab === OfferPageTabsEnum.OPERATIONS ||
                searchParams.tab === OfferPageTabsEnum.ELEMENTS ||
                searchParams.tab === OfferPageTabsEnum.EXPENSES ||
                searchParams.tab === OfferPageTabsEnum.WORK_PLANNINGS)
                ? searchParams.tab
                : OfferPageTabsEnum.ELEMENTS,
        operationFormMnemoKey: storeState.operationForm.mnemoKey,
        defaultShiftLengthInMinutes: storeState.businessAccountPreferences.preferences?.shiftLengthInMinutes,
        shiftCountRoundingType: storeState.businessAccountPreferences.preferences?.shiftCountRoundingType,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        canUpdateFinancialData: canUpdateFinancialDataSelector(storeState),
        currentOperationUUID: storeState.operationForm.currentOperationUUID,
        tabFromStore: getCurrentTabsSelector(storeState)?.['offer'],
        crewModuleEnabled: crewModuleEnabledSelector(storeState),
        statisticsModuleEnabled: statisticsModuleEnabledSelector(storeState),
        expensesModuleEnabled: expensesModuleEnabledSelector(storeState),
        offersModuleEnabled: offersModuleEnabledSelector(storeState),
    };
};

const mapDispatchToProps = {
    clearOffer,
    push,
    resetOperationForm,
    startNewOperation,
    fullResetOperation,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OfferPage = connect(mapStateToProps, mapDispatchToProps)(_OfferPage);
