import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../store/rtqQueryApi';
import { ProjectInfoRead, serverApi } from '../../../../server';
import { IRootState } from '../../../../shared/reducers';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { PledgeCreateArgs, PledgeUpdateArgs } from './projects.api.types';
import { showNotification } from '../../../../components/notification/showNotification';
import { AxiosError } from 'axios';

export const projectsApi = createApi({
    reducerPath: 'projectsApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Project', 'Pledge'],
    endpoints: (builder) => ({
        createPledge: builder.mutation<ProjectInfoRead, PledgeCreateArgs>({
            queryFn: async ({ projectId, data }, { getState }) => {
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    const res = await serverApi.createProjectPledge(businessAccountId, projectId, data);
                    showNotification('success', 'Залог создан');
                    return res;
                } catch (error) {
                    showNotification('error', 'Не удалось создать залог');
                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['Pledge', 'Project'],
        }),
        updatePledge: builder.mutation<ProjectInfoRead, PledgeUpdateArgs>({
            queryFn: async ({ data, pledgeId, projectId }, { getState }) => {
                const state = getState() as IRootState;
                const businessAccountId = businessAccountIdSelector(state);

                try {
                    const res = await serverApi.updateProjectPledge(businessAccountId, projectId, pledgeId, data);
                    showNotification('success', 'Залог изменен');
                    return res;
                } catch (error) {
                    showNotification('error', 'Не удалось изменить залог');
                    return { error: error as AxiosError };
                }
            },
            invalidatesTags: ['Pledge', 'Project'],
        }),
    }),
});

export const { useCreatePledgeMutation, useUpdatePledgeMutation } = projectsApi;
