import { ColumnTypes, TableColumn } from './Table';
import { GRID_ACTIONS_COLUMN_WIDTH } from '../../config/constants';
import { SystemIcon, SystemIconType } from '../v2/systemIcon/systemIcon';
import { Column } from '@tanstack/react-table';
import { CSSProperties } from 'react';

/**
 * Создать описание для колонки с действиями
 * @param render
 */
export function createActionsColumn<T extends object>(
    render: (value: any, rowData: T, onAction: (rowData: T, action: string) => void) => void,
    id?: string
): TableColumn<T> {
    return {
        title: <SystemIcon type={SystemIconType.actions} style={{ display: 'block' }} />,
        type: ColumnTypes.CustomRender,
        minWidth: GRID_ACTIONS_COLUMN_WIDTH,
        width: GRID_ACTIONS_COLUMN_WIDTH,
        maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
        render: render,
        id,
    };
}

export const getCommonPinningStyles = <T,>(column: Column<T>): CSSProperties => {
    const isPinned = column.getIsPinned();

    const styles: CSSProperties = {
        left: isPinned === 'left' ? 0 : undefined,
        right: isPinned === 'right' ? 0 : undefined,
        position: isPinned ? 'sticky' : 'relative',
        width: column.getSize(),
        zIndex: isPinned ? 1 : 0,
    };

    return styles;
};
