import React from 'react';
import {Card} from "antd";
import {connect} from "react-redux";
import {IRootState} from "../../../../../shared/reducers";
import {Spin} from "../../../../../components";
import {columns} from "./OperationFormEquipmentListColumns";
import {
    initialProductsParamsState1,
    loadElementsForProject,
    RentElementsGridItemCustom,
    setElementsListParams1
} from "../../reducers/operationForm.reducer";
import {getGridStorageData, setGridStorageDataFilters} from "../../../../../components/grid/utils";
import './OperationFormEquipmentList.less';
import {Grid} from "../../../../../components/grid/Grid";
import {OperationFormEquipmentListFilters} from "./OperationFormEquipmentListFilters";
import {RentElementsGridItem} from "../../../../../types";
import {isDefined} from "../../../../../shared/util/utils";
import {ToggleScanSoundButton} from "../../../../../components/buttons/toggleScanSoundButton/toggleScanSoundButton";

interface IProps extends StateProps, DispatchProps {
    visible: boolean;
}

const GRID_NAME = 'operationForm-equipment';

class Component extends React.Component<IProps> {

    private inited: boolean = false;
    private initialValues;
    private fromFilters: boolean = false;
    private filtersForm;

    constructor(props: IProps) {
        super(props);
    }

    shouldComponentUpdate(nextProps:IProps, nextState) {
        if(!nextProps.visible) return false;
        return true;
    }

    componentDidMount = () => {
        console.log('OperationFormEquipmentList componentDidMount()');
        this.inited = true;

        let rentOrSubrent: 'rent' | 'subrent' | 'all' = 'all';
        let parentEntityType;

        if (this.props.isSubrent) {
            rentOrSubrent = 'subrent';
            parentEntityType = 'shipping';
        } else if (!this.props.isSubrent) {
            rentOrSubrent = 'rent';
            parentEntityType = 'project';
        }

        this.initialValues = {...this.props.pageParams, rentOrSubrent, parentEntityType};

        let gridData = getGridStorageData(GRID_NAME);
        this.props.setElementsListParams1({
            limit: gridData.params?.limit,
            sortOrder: gridData.params?.sortOrder,
            sortBy: gridData.params?.sortBy,
            hide: gridData.filters?.hide,
            startDate: gridData.filters?.startDate || [null, null],
            endDate: gridData.filters?.endDate || [null, null],
            typeCode: gridData.filters?.typeCode,
            problem: gridData.filters?.problem
        });
        try{
            this.props.loadElementsForProject(this.props.operationProjectId || 0, this.props.isSubrent ? 'shipping' : (this.props.projectTemplate ? 'projectTemplate' : 'project'));
        }catch (e){}
    };

    componentDidUpdate(prevProps: IProps) {
        let neEq = JSON.stringify(prevProps.pageParams) !== JSON.stringify(this.props.pageParams);
        if (neEq) {
            if (!this.fromFilters) {
                this.filtersForm.setFieldsValue({...initialProductsParamsState1, ...this.props.pageParams});
            }
            this.fromFilters = false;
        }
    };

    onPageSizeChanged = (size) => {
        this.props.setElementsListParams1({
            limit: size,
            page: 1
        });
    };

    onSortedChange = (id: string, desc: boolean) => {
        this.props.setElementsListParams1({
            sortBy: id,
            sortOrder: desc ? 'DESC' : 'ASC'
        });
    };

    onPageChanged = (page: number) => {
        this.props.setElementsListParams1({
            page: page
        });
    };

    onFiltersChange = data => {
        this.fromFilters = true;
        this.updateFilters(data);
    };

    updateFilters = data => {
        data.page = 1;
        this.props.setElementsListParams1(data);
        setGridStorageDataFilters(GRID_NAME, data);
    };

    resetFilters = () => {
        this.updateFilters({
            hide: undefined,
            problem: undefined,
            typeCode: undefined,
            startDate: [undefined, undefined],
            endDate: [undefined, undefined],
        });
    };

    getFiltersForm = ref => {
        return (this.filtersForm = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    render() {
        console.log('OperationFormEquipmentList render()', this.props);
        let {filteredEntities, elements} = this.props;
        let gridData = getGridStorageData(GRID_NAME);
        let _sortOrder = gridData.params?.sortOrder || elements.params.sortOrder;
        let _sortBy = gridData.params?.sortBy || elements.params.sortBy || '';
        let startFrom = ((elements.params.page || 0) - 1) * (elements.params.limit || 0);
        let newEntities: RentElementsGridItemCustom[] = filteredEntities ? [...filteredEntities] : [];
        //
        //
        newEntities = newEntities.map((e) => {

            let ok = false;
            let leftoverInstanceCount:undefined|number = undefined;

            if(e.subRows){
                let withoutLeftover = 0;
                e.subRows.forEach((e)=>{
                    let element = this.props.elements1.find((el)=>el.id === e.id);
                    if(element && element.leftoverInstanceCount <= 0) withoutLeftover++;
                });
                ok = withoutLeftover > 0 && withoutLeftover === e.subRows.length;
            }else{
                if(this.props.elements1 && this.props.elements1.length > 0){
                    let element = this.props.elements1.find((el)=>el.id === e.id);
                    if(element){
                        leftoverInstanceCount = element.leftoverInstanceCount;
                        ok = isDefined(leftoverInstanceCount) && leftoverInstanceCount <= 0;
                    }
                }
            }

            e['allAdded'] = ok ? 1 : 0;

            let instanceCount:number|undefined = 0;
            if(this.props.elements1){
                let element = this.props.elements1.find((el)=>el.id === e.id);
                if(element){
                    instanceCount = element.instanceCount;
                }
            }
            e['added'] = instanceCount;

            //let isCancelled:boolean|undefined = false;
            //let anonymousInstanceCount:number|undefined;
            //let availableAnonymousInstanceCount = e.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED || e.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ? e.anonymousInstanceCount : undefined;

            // if(elements){
            //     let element = elements.find((el)=>el.id === rowData.id);
            //     if(element){
            //         instanceCount = element.instanceCount;
            //
            //         anonymousInstanceCount = element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED || element.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ? element.anonymousInstanceCount : undefined;
            //
            //         if(!canAddNewProductsToOperation(typeCode) && element.leftoverInstanceCount === 0){
            //             plusButtonDisabled = true;
            //         }
            //
            //         isCancelled = element.isCancelled;
            //     }
            // }

            //state.equipment.entities?.forEach((item)=>{
                //     let element = state.elements.entities.find((el)=>el.id === item.id);
                //     if(element){
                //         item.isCancelled = element.isCancelled;
                //     }
                // });

                //let instanceCount:number|undefined = 0;
                // if(this.props.elements1){
                //     let element = this.props.elements1.find((el)=>el.id === e.id);
                //     if(element){
                //         e.isCancelled = element.isCancelled;
                //     }
                // }

            return e;
        });

        let sortedData:RentElementsGridItem[] = newEntities;
        sortedData = sortedData.slice(startFrom, startFrom + (elements.params.limit || 0));

        let filtersCurrentValues = {...this.props.pageParams};

        let excludeColumns;

        if(this.props.projectTemplate){
            excludeColumns = ['rentTerms.rentPeriodStartDate', 'rentTerms.rentPeriodEndDate'];
        }

        return (
            <Spin spinning={this.props.loading}>
                <Card bordered={false} className={'rr-operationForm-elementsList'}>
                    <Grid
                        filtersData={OperationFormEquipmentListFilters}
                        filtersCurrentValues={filtersCurrentValues}
                        filtersDefaultValues={{
                           ...initialProductsParamsState1,
                        }}
                        filtersInitialValues={this.initialValues}
                        filtersGetFiltersFormRef={this.getFiltersForm}
                        filtersOnChange={this.onFiltersChange}
                        filtersResetFiltersCb={this.resetFilters}
                        exportBlock={<ToggleScanSoundButton />}
                        onSortedChange={this.onSortedChange}
                        onPageChanged={this.onPageChanged}
                        onPageSizeChanged={this.onPageSizeChanged}
                        filtered={newEntities ? newEntities.length : 0}
                        pageSize={elements.params.limit}
                        currentPage={elements.params.page}
                        columns={columns}
                        data={sortedData}
                        indexFieldName={'id'}
                        selectable={false}
                        defaultSorted={_sortBy}
                        defaultSortDesc={_sortOrder === 'DESC'}
                        entityType={'element'}
                        gridName={GRID_NAME}
                        onPageSelect={[10, 20, 50, 100, 200, 500]}
                        alwaysRedraw={false}
                        excludeColumns={excludeColumns}
                        pinned={{ right: ['actions'] }}
                    />
                </Card>
            </Spin>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        loading: storeState.operationForm.equipment.loading,
        pageParams: storeState.operationForm.equipment.params,
        elements: storeState.operationForm.equipment,
        filteredEntities: storeState.operationForm.equipment.filteredEntities,
        operationTypeCode: storeState.operationForm.typeCode,
        operationCorrectionStateCode: storeState.operationForm.targetStateCode,
        projectTemplate: storeState.operationForm.projectTemplate,

        isSubrent: storeState.operationForm.isSubrent,
        operationProjectId: storeState.operationForm.projectId,
        elements1: storeState.operationForm.elements.entities,
    };
};

const mapDispatchToProps = {setElementsListParams1, loadElementsForProject};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OperationFormEquipmentList = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
