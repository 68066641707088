import React, { ReactNode } from 'react';
import { ContactSelectProps } from './contactSelect';
import { SelectAndCreate } from '../selectAndCreate';
import { ContactCreateModalNew } from '../../../../modules/main/renters/tabs/contacts/modal/create/ContactCreateModalNew';
import ContactsDetailedSelect from '../../detailedSelect/ContactsDetailedSelect';
import { IDetailedSelectColumn } from '../../detailedSelect/DetailedSelect';
import { ContactRecord } from '../../../../server';

export interface ContactSelectAndCreateProps extends ContactSelectProps {
    columns?: IDetailedSelectColumn<ContactRecord>[];
    getOptionPrefix?: (record: ContactRecord) => ReactNode | null;
    allowClear?: boolean;
    counterpartyContactsIds?: number[];
}

export class ContactSelectAndCreate extends React.PureComponent<ContactSelectAndCreateProps> {
    render() {
        const { props } = this;
        return (
            <SelectAndCreate
                select={() => (
                    <ContactsDetailedSelect
                        value={props.value ? (Array.isArray(props.value) ? (props.value as number[]) : [props.value as number]) : []}
                        getPopupContainer={props.getPopupContainer}
                        multiple={props.multiple}
                        id="contacts"
                        onChange={(v) => {
                            props.onChange?.(v);
                        }}
                        onDataChange={props.onDataChange}
                        columns={props.columns}
                        getOptionProps={props.getOptionProps}
                        filters={props.filters}
                        getOptionPrefix={props.getOptionPrefix}
                        allowClear={props.allowClear}
                        disabled={props.disabled}
                        placeholder={props.placeholder}
                        counterpartyContactsIds={props.counterpartyContactsIds}
                    />
                )}
                modal={({ close }) => {
                    return (
                        <ContactCreateModalNew
                            onSuccess={(data) => {
                                let value: number | number[] | undefined;
                                if (props.multiple) {
                                    if (props.value) {
                                        if (Array.isArray(props.value)) value = [...props.value.map((item) => +item), data.id];
                                        else value = [+props.value, data.id];
                                    } else {
                                        value = [data.id];
                                    }
                                } else {
                                    value = data.id;
                                }
                                props.onChange?.(value);
                                props.onDataChange?.([data as unknown as ContactRecord]);
                                close();
                            }}
                            onCancel={() => {
                                close();
                            }}
                        />
                    );
                }}
                disabled={props.disabled}
            />
        );
    }
}
