import './locationsColumns.less';
import { LocationRecord } from '../../../../../../server';
import { TableColumn } from '../../../../../../components/grid/Table';
import { GridColumnCreator } from '../../../../../../components/grid/gridColumnCreator';

export const locationsColumns: TableColumn<LocationRecord>[] = [
    GridColumnCreator.createShortNameColumn<LocationRecord>(
        'location',
        (row) => ({
            baId: row.businessAccountId,
            id: row.id,
            name: row.shortName,
        }),
        {
            minWidth: 170,
        }
    ),
    GridColumnCreator.createAddressColumn<LocationRecord>({
        title: 'Адрес',
        dataIndex: 'address',
        minWidth: 100,
    }),
    GridColumnCreator.createReferenceCountColumn<LocationRecord>({
        title: 'Кол-во перевозок',
        dataIndex: 'transportationCount',
        minWidth: 120,
    }),
    GridColumnCreator.createProjectReferenceCountColumn<LocationRecord>(
        (row) => ({
            baId: row.businessAccountId,
            count: row.projectCount,
            params: { locationId: row.id },
        }),
        {
            title: 'Кол-во проектов',
            dataIndex: 'projectCount',
            minWidth: 110,
        }
    ),
    GridColumnCreator.createAttachmentsCountColumn<LocationRecord>({
        title: 'Число вложений',
        dataIndex: 'attachmentCount',
        minWidth: 120,
    }),
    GridColumnCreator.createUserColumn<LocationRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.lastUpdateAuthorId,
            name: row.lastUpdateAuthorShortName,
        }),
        {
            title: 'Автор изменения',
            dataIndex: 'lastUpdateAuthorShortName',
        }
    ),
    GridColumnCreator.createLastUpdateDateColumn<LocationRecord>({
        title: 'Дата изменения',
        dataIndex: 'lastUpdateDate',
        minWidth: 140,
    }),
    GridColumnCreator.createActionsColumn<LocationRecord>({ id: 'actions' }),
];
