import React, {useCallback, useEffect, useState} from "react";
import {SelectValue} from "antd/lib/select";
import {editElementInstances, OperationElement, updateAnyTimetables} from "../../../reducers/operationForm.reducer";
import {Button} from "antd";
import {ProductInstancesSelect} from "../../product-instances-select/ProductInstancesSelect";
import {canAddInstancesToExistedElement} from "../../../utils/utils";
import {useAppSelector} from "../../../../../../store/hooks";
import {
    getDateIntervalsForCalendar,
    loadNomenclaturesOnIntervalsIfNeed,
    NomenclatureOnInterval
} from "../../../utils/nomenclaturesUtils";
import {NomenclatureEntityTypeCodeEnum} from "../../../../../../server";
import {getStore} from "../../../../../../../index";
import {showNotification} from "../../../../../../components/notification/showNotification";
import {instancesSelectOnChangeHandler} from "../../elementCreatePopover/OperationElementCreatePopoverContent";

export interface InlineInstancesSelectPopoverContentProps {
    elementRecord: OperationElement;
    onSuccess?: ()=>void;
}

const updateNomenclatures = async (startDate: Date, endDate: Date, selectedInstances: number[]) => {
    const [from, until] = getDateIntervalsForCalendar(startDate, endDate);
    const nomenclatures: NomenclatureOnInterval[] = [];
    if (selectedInstances) {
        selectedInstances.forEach((id) => {
            nomenclatures.push({id, type: NomenclatureEntityTypeCodeEnum.INSTANCE, from, until});
        });
    }
    const data = await loadNomenclaturesOnIntervalsIfNeed(nomenclatures);
    getStore().dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));
};

export const InlineInstancesSelectPopoverContent = ({
                                                        elementRecord,onSuccess
                                                    }: InlineInstancesSelectPopoverContentProps) => {
    const [loading, setLoading] = useState(false);
    const [tmpData, setTmpData] = useState({selectedInstances: elementRecord.instanceIds||[], anonymousInstanceCount: elementRecord.anonymousInstanceCount, instanceCount: elementRecord.instanceCount});
    const [selectedInstances, setSelectedInstances] = useState<number[]>(elementRecord.instanceIds||[]);
    const [anonymousInstanceCount, setAnonymousInstanceCount] = useState(elementRecord.anonymousInstanceCount);
    const [instanceCount, setInstanceCount] = useState(elementRecord.instanceCount);
    
    const operationTypeCode = useAppSelector(state => state.operationForm.typeCode);
    const operationTypeCorrectionCode = useAppSelector(state => state.operationForm.targetStateCode);
    const requiredTimeIndentBetweenElementsInMinutes = useAppSelector(state => state.businessAccountPreferences.preferences?.requiredTimeIndentBetweenElementsInMinutes) || 0;

    const onOkButtonClick = useCallback(async () => {
        onSuccess?.();
    }, [onSuccess]);

    const setInstances = useCallback(async (selectedInstances: number[], instanceCount: number, anonymousInstanceCount: number) => {
        setSelectedInstances(selectedInstances);
        setInstanceCount(instanceCount);
        setAnonymousInstanceCount(anonymousInstanceCount);
        if (selectedInstances) {
            try {
                await updateNomenclatures(elementRecord.rentPeriodStartDate, elementRecord.rentPeriodEndDate, selectedInstances);
                getStore().dispatch(editElementInstances(elementRecord.id, selectedInstances, instanceCount, anonymousInstanceCount));
            } catch (e) {
                showNotification('error', 'Не удалось изменить экземпляры');
            }
        }
    }, [elementRecord, setSelectedInstances, setInstanceCount, setAnonymousInstanceCount]);

    const onSelectChange = useCallback(async (value: SelectValue, option: React.ReactElement<any> | React.ReactElement<any>[], metaKey: boolean) => {
        const data = instancesSelectOnChangeHandler(value, metaKey, {selectedInstances, instanceCount, anonymousInstanceCount});
        void setInstances(data.selectedInstances, data.instanceCount, data.anonymousInstanceCount);
    }, [setSelectedInstances, selectedInstances, instanceCount, anonymousInstanceCount, elementRecord, setInstances]);

    let includeInstanceIds =
        elementRecord?.instanceIdsOriginal &&
        elementRecord?.id >= 0 &&
        !canAddInstancesToExistedElement(operationTypeCorrectionCode || operationTypeCode)
            ? elementRecord.instanceIdsOriginal
            : undefined;

    useEffect(() => {
        const keyDownHandler = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                void setInstances(tmpData.selectedInstances, tmpData.instanceCount, tmpData.anonymousInstanceCount);
                onSuccess?.();
            }
        };

        document.addEventListener('keydown', keyDownHandler);
        return ()=>{
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [tmpData, setInstances]);

    return (
        <div style={{display: 'inline-flex', width: '100%', alignItems: "center"}}>
            <div style={{flexGrow: 1, lineHeight: 0}}>
                <ProductInstancesSelect
                    elementRecord={elementRecord}
                    variantId={elementRecord.variantId}
                    productId={elementRecord.productId}
                    value={selectedInstances}
                    onChange={onSelectChange}
                    startDate={elementRecord.rentPeriodStartDate.getTime()}
                    endDate={elementRecord.rentPeriodEndDate.getTime()}
                    canUpdate={true}
                    includeIds={includeInstanceIds}
                    copyMode={false}
                    requiredTimeIndentBetweenElementsInMinutes={requiredTimeIndentBetweenElementsInMinutes}
                    openOnMount={true}
                />
            </div>
            <Button
                className={'rr-btn-blue'}
                style={{marginLeft: 8}}
                onClick={onOkButtonClick}
                loading={loading}
            >OK</Button>
        </div>
    );
};
