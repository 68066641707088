import React, {FC} from 'react';
import {
    AttachmentParentEntityTypeCodeEnum,
    ContactLinkObjRead,
    ProjectInfoRead,
    ProjectInfoUpdate
} from "../../../../../../../../server";
import {ContactsBlock} from "../../../../../../../../components/contactsBlock/contactsBlock";
import {useAppDispatch} from "../../../../../../../../store/hooks";
import {deleteContactFromProject} from "../../../../reducers/project.reducer";
import {Col, Row} from "antd";

interface ProjectPageDescriptionContactsBlockProps {
    data: ProjectInfoRead;
}

export const ProjectPageDescriptionContactsBlock: FC<ProjectPageDescriptionContactsBlockProps> = ({data}) => {
    const contactLinks: ContactLinkObjRead[] = data ? (data.hasOwnContacts ? data.contactLinks || [] : data.renterContactLinks || []) : [];
    const dispatch = useAppDispatch();
    const preventDeleteContact = !data.hasOwnContacts;

    return (
        <Row>
            <Col style={{ marginLeft: -8, marginRight: -8, borderBottom: '1px solid #eaeaea' }}>
                <ContactsBlock
                    data={contactLinks}
                    parentEntityId={data.id}
                    parentEntityTypeCode={AttachmentParentEntityTypeCodeEnum.PROJECT}
                    preventDeleteRemainingContact={true}
                    preventDeleteContact={preventDeleteContact}
                    deleteContact={async (contactId) => {
                        await dispatch(deleteContactFromProject(data.businessAccountId, contactId, data as ProjectInfoUpdate));
                    }}
                    contactsFromEntityLabel={!data.hasOwnContacts ? 'по арендатору' : undefined}
                />
            </Col>
        </Row>
    );
};
