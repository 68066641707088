import {InstanceRecord, NomenclatureRecord} from '../../../../../../server';
import {ServerUtils} from '../../../../../../core/utils/serverUtils';
import {SummaryScheduleCalendarFilters} from "../filters/summaryScheduleCalendarFilters";
import {DeepKeys} from "@tanstack/react-table";

export abstract class SummaryScheduleCalendarApiUtils {

    /** Получаем массив фильтров или undefined для запроса к серверу */
    static getInstancesListFilters = (params: SummaryScheduleCalendarFilters) => {
        const {productId, variantId, instanceId} = params;
        const filters = ServerUtils.createRequestFilters<InstanceRecord>([
            productId && ['productId', 'EQ', productId],
            variantId && ['variantId', 'EQ', variantId],
            instanceId && ['id', 'EQ', instanceId]
        ]);
        return filters.length > 0 ? filters : undefined;
    };

    /** Получаем массив фильтров или undefined для запроса к серверу */
    static getNomenclatureListFilters = (params: SummaryScheduleCalendarFilters) => {
        const {categoryIds, productId, kitId, variantId, instanceId} = params;
        const filters = ServerUtils.createRequestFilters<NomenclatureRecord>([
            categoryIds && ['categoryIds', 'IN', Array.isArray(categoryIds) ? categoryIds : [categoryIds]],
            productId && ['productId', 'EQ', productId],
            variantId && ['variantId', 'EQ', variantId],
            kitId && ['kitId', 'EQ', kitId],
            ['archive', 'EQ', false]
        ]);
        return filters.length > 0 ? filters : undefined;
    };

    /** Получаем поле для сортировки в зависиммости от группировки для запроса к серверу */
    static getNomenclatureSortBy = (params: SummaryScheduleCalendarFilters) => {
        let sortBy:DeepKeys<NomenclatureRecord>|undefined;
        if(params.group === 'name') sortBy = 'productShortName';
        else if(params.group === 'category') sortBy = 'mainCategoryName';
        else if(params.group === 'article') sortBy = 'externalCode';
        else if(params.group === 'status') sortBy = 'stateCode';
        else if(params.group === 'type') sortBy = 'nomenclatureEntityTypeCode';
        return sortBy;
    };
}
