import { professionsApi, useListProfessionsQuery } from '../api/professions.api';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { useLocation } from 'react-router-dom';
import { professionsListColumns } from '../columns/professionsListColumns';
import { professionsFilters } from '../filters/professionsFilters';
import { EntityList } from '../../../../../../components/page/entityGrid/components/ui/EntityList/EntityList';
import { useProfessionEntityActions } from '../hooks/useProfessionEntityActions';
import { useProfessionEntityActionsButtons } from '../hooks/useProfessionEntityActionsButtons';
import React, { FC, memo, useCallback } from 'react';
import { useEntityGridContext } from '../../../../../../components/page/entityGrid/components/context/GridRefContext';
import { useEntitySelection } from '../../../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import { useSelectedRecords } from '../../../../../../components/page/entityGrid/hooks/useSelectedRecords';
import { useAppSelector } from '../../../../../../store/hooks';
import { professionEntitySelector } from '../reducers/profession/profession.selectors';
import { ProfessionCreateModalGridWrapper } from '../modal/components/wrappers/ProfessionCreateModalGridWrapper';
import { ProfessionEditModalWrapper } from '../modal/components/wrappers/ProfessionEditModalWrapper';
import { crewPageUrlRoute } from '../../../utils/tabs';
import { ProfessionsPageFiltersDescription } from '../filters/professionsFiltersDescription';
import { clearProfession } from '../reducers/profession/profession.reducer';
import { withEntityListProviders } from '../../../../../../components/page/entityGrid/components/decorators/withEntityListProviders';
import {
    usePageURLParamsFromDescription,
    useQueryParamsWithoutModalParams,
} from '../../../../../../components/page/entityGrid/hooks/useParams';
import { EntityGridRootPath } from '../../../../../../components/page/entityGrid/types/params';
import { ProfessionDrawerWrapper } from '../drawer/components/ProfessionDrawer/wrappers/ProfessionDrawerWrapper';
import { canViewFinancialDataSelector } from '../../../../../../shared/reducers/permissions.reducer';

import { CrewPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import { ExportOffloadingPopover } from '../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover';
import { OffloadingDocumentOnDownload } from '../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data';
import { downloadOffloading } from '../../../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading';
import {
    ExcelExportTemplateTypeCodeEnum,
    ProfessionFinancialFieldsTypeCode,
    ProfessionNotFinancialFieldsTypeCode,
} from '../../../../../../server';
import { useIntl } from 'react-intl';
import { ProfessionsApiUtils } from '../api/professions.api.utils';

interface ProfessionsListComponentProps {
    gridName: CrewPageTabsEnum;
}

const ProfessionsListComponent: FC<ProfessionsListComponentProps> = memo((props) => {
    const { gridName } = props;
    const location = useLocation();
    const intl = useIntl();
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const currentEntity = useAppSelector(professionEntitySelector);
    const gridRef = useEntityGridContext();
    const entitySelection = useEntitySelection();

    const pageParamsObject = usePageURLParamsFromDescription({
        pageParamsDescription: ProfessionsPageFiltersDescription,
        gridName,
    });
    const { pageParams, getSearchParams } = pageParamsObject;
    const urlSearchParams = getSearchParams(pageParams);
    const queryParams = useQueryParamsWithoutModalParams(pageParams);
    const queryData = useListProfessionsQuery(
        {
            businessAccountId: businessAccountId,
            params: queryParams,
        },
        {
            skip: !location.pathname.includes(gridName),
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const rootPath = `/${businessAccountId}/${crewPageUrlRoute}/${gridName}` satisfies EntityGridRootPath;

    const entityActions = useProfessionEntityActions({
        rootPath,
        urlSearchParams,
    });

    const selectedRecords = useSelectedRecords(entitySelection, queryData.data?.entitiesData.records);
    const { entityActionsButtons, loading: isEntityActionsButtonsLoading } = useProfessionEntityActionsButtons({
        selectedRecords,
        gridRef,
    });

    const excludeColumns = canViewFinancialData ? undefined : ['pricePerShift'];

    const onOffloadingDownload: OffloadingDocumentOnDownload = useCallback(
        async ({ fileType, options }) => {
            const { sortOrder, sortBy, search } = pageParams;
            const filters = ProfessionsApiUtils.createRequestFilters(pageParams);

            await downloadOffloading({
                businessAccountId,
                canViewFinancialData,
                exportTemplateType: ExcelExportTemplateTypeCodeEnum.PROFESSIONTEMPLATE,
                fileName: 'Экспорт специальностей',
                fileType,
                filters,
                financialFieldsEnum: ProfessionFinancialFieldsTypeCode,
                grid: gridRef.current,
                intl,
                notFinancialFieldsEnum: ProfessionNotFinancialFieldsTypeCode,
                selection: entitySelection,
                values: options,
                sortOrder,
                sortBy,
                search,
                excludeColumns: undefined,
            });
        },
        [businessAccountId, canViewFinancialData, entitySelection, gridRef, intl, pageParams]
    );

    return (
        <EntityList
            pageParamsObject={pageParamsObject}
            gridProps={{
                static: {
                    columns: professionsListColumns,
                    filtersData: professionsFilters,
                    pageParamsDescription: ProfessionsPageFiltersDescription,
                },
                metaData: {
                    entityType: 'profession',
                    gridName,
                    rootPath,
                },
                queryData,
                entitiesLoading: isEntityActionsButtonsLoading || entityActions.isLoading,
                entityActions,
                actionButtons: entityActionsButtons,
                excludeColumns,
                exportBlock: <ExportOffloadingPopover storageKey={'professionsOffloading'} onDownload={onOffloadingDownload} />,
                pinned: { right: ['actions'] },
            }}
            modalProps={{
                currentEntity,
                modals: {
                    create: {
                        component: ProfessionCreateModalGridWrapper,
                    },
                    edit: {
                        component: ProfessionEditModalWrapper,
                    },
                },
                entityLoadActions: {
                    loadEntity: professionsApi.endpoints.professionById.initiate,
                    clearEntity: clearProfession,
                },
            }}
            drawerProps={{
                drawer: ProfessionDrawerWrapper,
                entityActions: entityActions,
            }}
        />
    );
});

export const ProfessionsList = withEntityListProviders(ProfessionsListComponent);
