import React, { FC, useCallback } from 'react';
import { logisticsPageUrlRoute } from '../../../data/tabs';
import { ListTransportationsQueryParams } from '../api/transportations.api.types';
import { EntityList } from '../../../../../../components/page/entityGrid/components/ui/EntityList/EntityList';
import { transportationsColumns } from '../columns/transportationsColumns';
import { transportationsFilters } from '../filters/transportationsFilters';
import { TransportationsPageFiltersDescription } from '../filters/transportationsFiltersDescription';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { transportationsApi, useListTransportationsQuery } from '../api/transportations.api';
import { transportationEntitySelector } from '../reducers/transportation/transportation.selectors';
import { useTransportationsEntityActions } from '../hooks/useTransportationsEntityActions';
import { useSelectedRecords } from '../../../../../../components/page/entityGrid/hooks/useSelectedRecords';
import { useEntityGridContext } from '../../../../../../components/page/entityGrid/components/context/GridRefContext';
import { useEntitySelection } from '../../../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import { TransportationCreateModalWrapper } from '../modal/components/create/TransportationCreateModalWrapper';
import { TransportationEditModalWrapper } from '../modal/components/edit/TransportationEditModalWrapper';
import { clearTransportation } from '../reducers/transportation/transportation.reducer';
import { ModalFormNEWProps } from '../../../../../../components/modalForm/ModalFormNEW';
import { TransportationDrawerWrapper } from '../drawer/components/wrappers/TransportationDrawerWrapper';
import { TransportationAdditionalModals } from '../modal/components/additional/TransportationAdditionalModals';
import { usePushToPages } from '../hooks/usePushToPages';
import { useEntityActionsButtons } from '../hooks/useEntityActionsButtons';
import { useIdForDrawer } from '../hooks/useIdForDrawer';
import { useOnCellAction } from '../hooks/useOnCellAction';
import { GridProps } from '../../../../../../components/grid/Grid';
import { withEntityListProviders } from '../../../../../../components/page/entityGrid/components/decorators/withEntityListProviders';
import { ExcelExportTemplateTypeCodeEnum, TransportationNotFinancialFieldsTypeCode, TransportationRecord } from '../../../../../../server';
import { EntityGridRootPath } from '../../../../../../components/page/entityGrid/types/params';
import {
    usePageURLParamsFromDescription,
    useQueryParamsWithoutModalParams,
} from '../../../../../../components/page/entityGrid/hooks/useParams';
import { ProjectPageGridNamesEnum } from '../../../../projects/production/page/projectPage';
import { ExportOffloadingPopover } from '../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover';
import { downloadOffloading } from '../../../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading';
import { TransportationsUtils } from '../api/transportations.api.utils';
import { useIntl } from 'react-intl';
import { canViewFinancialDataSelector } from '../../../../../../shared/reducers/permissions.reducer';

import { LogisticsPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import { OffloadingDocumentOnDownload } from '../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data';

interface TransportationsListProps {
    gridName: LogisticsPageTabsEnum | ProjectPageGridNamesEnum.TRANSPORTATIONS;
    urlPath: `${typeof logisticsPageUrlRoute}/${LogisticsPageTabsEnum}` | `projects/production/${number}?tab=transportations`;
    staticPageParams?: Partial<ListTransportationsQueryParams>;
    projectId?: number;
    visible?: boolean;
    gridProps?: Partial<GridProps>;
    modalProps?: Partial<ModalFormNEWProps>;
}

const TransportationsListComponent: FC<TransportationsListProps> = (props) => {
    const { gridName, urlPath, staticPageParams, projectId, visible, gridProps, modalProps } = props;
    const intl = useIntl();
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const currentEntity = useAppSelector(transportationEntitySelector);
    const gridRef = useEntityGridContext();
    const entitySelection = useEntitySelection();

    const pageParamsObject = usePageURLParamsFromDescription({
        pageParamsDescription: TransportationsPageFiltersDescription,
        gridName,
        skipLocationChange: !visible,
        // initialValues: {
        //     sortBy: 'lastUpdateDate',
        //     sortOrder: 'DESC',
        // },
    });

    const { pageParams, getSearchParams } = pageParamsObject;
    const urlSearchParams = getSearchParams(pageParams);
    const queryParams = useQueryParamsWithoutModalParams(pageParams);
    const queryData = useListTransportationsQuery(
        {
            businessAccountId,
            params: {
                ...queryParams,
                ...staticPageParams,
            },
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );
    const { refetch: refetchList } = queryData;

    let rootPath = `/${businessAccountId}/${urlPath}` as EntityGridRootPath;
    if (projectId) {
        rootPath = rootPath.split('?')[0] as EntityGridRootPath;
    }

    const entityActions = useTransportationsEntityActions({
        rootPath,
        urlSearchParams,
    });

    const selectedRecords = useSelectedRecords<TransportationRecord>(entitySelection, queryData.data?.entitiesData.records);
    const { buttons: entityActionsButtons, isLoading: isEntityActionsButtonsLoading } = useEntityActionsButtons({
        selectedRecords,
        gridRef,
    });

    const [locationIdForDrawer, setLocationIdForDrawer, clearLocationIdForDrawer] = useIdForDrawer();
    const [contactIdForDrawer, _, clearContactIdForDrawer] = useIdForDrawer();
    const [vehicleIdForDrawer, setVehicleIdForDrawer, clearVehicleIdForDrawer] = useIdForDrawer();
    const { locationActions, contactActions, vehiclesActions } = usePushToPages();

    const onCellAction = useOnCellAction({
        setLocationId: setLocationIdForDrawer,
        setVehicleId: setVehicleIdForDrawer,
    });

    const onOffloadingDownload: OffloadingDocumentOnDownload = useCallback(
        async ({ fileType, options }) => {
            const { sortOrder, sortBy } = pageParams;
            const filters = TransportationsUtils.createRequestFilters({
                ...pageParams,
                projectId: projectId ?? pageParams.projectId,
            });

            await downloadOffloading({
                businessAccountId,
                canViewFinancialData,
                exportTemplateType: ExcelExportTemplateTypeCodeEnum.TRANSPORTATIONTEMPLATE,
                fileName: 'Экспорт перевозок',
                fileType,
                filters,
                financialFieldsEnum: TransportationNotFinancialFieldsTypeCode,
                grid: gridRef.current,
                intl,
                notFinancialFieldsEnum: TransportationNotFinancialFieldsTypeCode,
                selection: entitySelection,
                values: options,
                sortOrder,
                sortBy,
                excludeColumns: projectId == null ? undefined : ['projectName'],
            });
        },
        [businessAccountId, canViewFinancialData, entitySelection, gridRef, intl, pageParams, projectId]
    );

    const isListOnProjectPage = Boolean(projectId) && visible;

    return (
        <>
            <EntityList
                pageParamsObject={pageParamsObject}
                gridProps={{
                    static: {
                        columns: transportationsColumns,
                        filtersData: transportationsFilters,
                        pageParamsDescription: TransportationsPageFiltersDescription,
                    },
                    metaData: {
                        entityType: 'transportation',
                        gridName,
                        rootPath: rootPath,
                    },
                    queryData,
                    entitiesLoading: isEntityActionsButtonsLoading || entityActions.isLoading,
                    entityActions,
                    actionButtons: entityActionsButtons,
                    onCellAction,
                    filtersExcludeFields: isListOnProjectPage ? undefined : ['tab'],
                    exportBlock: <ExportOffloadingPopover storageKey={'transportationsOffloading'} onDownload={onOffloadingDownload} />,
                    pinned: { right: ['actions'] },
                    ...gridProps,
                }}
                modalProps={{
                    currentEntity,
                    modals: {
                        create: {
                            component: TransportationCreateModalWrapper,
                            props: modalProps,
                        },
                        edit: {
                            component: TransportationEditModalWrapper,
                        },
                    },
                    entityLoadActions: {
                        loadEntity: transportationsApi.endpoints.transportationById.initiate,
                        clearEntity: clearTransportation,
                    },
                }}
                drawerProps={{
                    drawer: TransportationDrawerWrapper,
                    entityActions,
                }}
            />
            <TransportationAdditionalModals
                refetchList={refetchList}
                contactIdForDrawer={contactIdForDrawer}
                contactActions={contactActions}
                clearContactIdForDrawer={clearContactIdForDrawer}
                vehicleIdForDrawer={vehicleIdForDrawer}
                vehiclesActions={vehiclesActions}
                clearVehicleIdForDrawer={clearVehicleIdForDrawer}
                locationIdForDrawer={locationIdForDrawer}
                locationActions={locationActions}
                clearLocationIdForDrawer={clearLocationIdForDrawer}
            />
        </>
    );
};

export const TransportationsList = withEntityListProviders(TransportationsListComponent);
