import React, { useCallback, useMemo } from 'react';
import { useEntitySelection } from '../../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import {
    ExcelExportTemplateTypeCodeEnum,
    FileTypeCodeEnum,
    NomenclatureFinancialFieldsTypeCode, NomenclatureNotFinancialFieldsTypeCode, ProductRecord
} from '../../../../../server';
import { ProductsApiUtils } from '../api/products.api.utils';
import { DownloadDocumentUtils } from '../../../../../core/utils/downloadDocumentUtils/downloadDocumentUtils';
import { showNotification } from '../../../../../components/notification/showNotification';
import { useAppSelector } from '../../../../../store/hooks';
import { subrentModuleEnabledSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { ProductsListQueryParams } from '../api/products.api.types';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { ExportProductsInventoryListPopover } from '../../../../../components/exportPopovers/downloadDocument/instances/ExportProductsInventoryListPopover/ExportProductsInventoryListPopover';
import { ExportProductsPriceListPopover } from '../../../../../components/exportPopovers/downloadDocument/instances/ExportProductsPriceListPopover/ExportProductsPriceListPopover';
import {
    ExportProductsInventoryListPopoverOptions,
    ProductsInventoryListOnDownload,
} from '../../../../../components/exportPopovers/downloadDocument/instances/ExportProductsInventoryListPopover/utils/data';
import {
    ExportProductsPriceListPopoverOptions,
    ProductsPriceListOnDownload,
} from '../../../../../components/exportPopovers/downloadDocument/instances/ExportProductsPriceListPopover/utils/data';
import {ExportOffloadingPopover} from "../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover";
import {OffloadingDocumentOnDownload} from "../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data";
import {downloadOffloading} from "../../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading";
import {useIntl} from "react-intl";
import {canViewFinancialDataSelector} from "../../../../../shared/reducers/permissions.reducer";
import {useEntityGridContext} from "../../../../../components/page/entityGrid/components/context/GridRefContext";
import {DeepKeys} from "@tanstack/react-table";

const productsGridColumnsMap:Partial<Record<DeepKeys<ProductRecord>, NomenclatureNotFinancialFieldsTypeCode>> = {
    'warehousePresenceCounters.stockInstanceCount': NomenclatureNotFinancialFieldsTypeCode.StockInstanceCount,
    'warehousePresenceCounters.underMaintenanceInstanceCount': NomenclatureNotFinancialFieldsTypeCode.UnderMaintenanceInstanceCount,
    'warehousePresenceCounters.subrentedInstanceCount': NomenclatureNotFinancialFieldsTypeCode.SubrentedInstanceCount,
    'warehousePresenceCounters.rentedInstanceCount': NomenclatureNotFinancialFieldsTypeCode.RentedInstanceCount,
    'warehousePresenceCounters.warehouseInstanceCount': NomenclatureNotFinancialFieldsTypeCode.WarehouseInstanceCount
};

export const useExportBlock = (pageParams: ProductsListQueryParams) => {
    const intl = useIntl();
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);
    const entitySelection = useEntitySelection();
    const gridRef = useEntityGridContext();

    const getSelectedIdsFilterForDocumentGeneration = useCallback(() => {
        const ids = entitySelection.map((id) => {
            return `PRODUCT_${id}`;
        });

        return ids.length > 0 ? `uuid;IN;${ids.join(';')}` : '';
    }, [entitySelection]);

    const onDownloadProductsPriceList: ProductsPriceListOnDownload = useCallback(
        async ({ fileType, options, templateId, isCommonTemplate, discount, legalDetailId }) => {
            let filters: string[] = ProductsApiUtils.createEntityRequestFilters(pageParams, subrentModuleEnabled);
            const selectedIdsFilter = getSelectedIdsFilterForDocumentGeneration();
            if (selectedIdsFilter) filters.push(selectedIdsFilter);
            const sortBy = pageParams.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : pageParams.sortBy;
            await DownloadDocumentUtils.nomenclaturePricelistGenerateAndDownload(
                {
                    businessAccountId: businessAccountId,
                    common: Boolean(isCommonTemplate),
                    documentTemplateId: templateId!,
                    appliedDiscount: discount ?? 0,
                    showDiscount: options && options.includes(ExportProductsPriceListPopoverOptions.SHOW_DISCOUNT),
                    showPricingSchemes: options && options.includes(ExportProductsPriceListPopoverOptions.SHOW_PRICING_SCHEMES),
                    showDescription: options && options.includes(ExportProductsPriceListPopoverOptions.SHOW_DESCRIPTION),
                    showCount: options && options.includes(ExportProductsPriceListPopoverOptions.SHOW_COUNT),
                    groupByCategories: options && options.includes(ExportProductsPriceListPopoverOptions.GROUP),
                    sortBy: sortBy,
                    sortOrder: pageParams.sortOrder,
                    search: pageParams.search,
                    filters: filters,
                    legalDetailId,
                },
                `Прайс-лист`,
                fileType as FileTypeCodeEnum.EXCEL | FileTypeCodeEnum.PDFTYPE
            ).catch((e) => {
                showNotification('error', 'Ошибка генерации файла');
            });
        },
        [businessAccountId, getSelectedIdsFilterForDocumentGeneration, pageParams, subrentModuleEnabled]
    );

    const onDownloadProductsInventoryList: ProductsInventoryListOnDownload = useCallback(
        async ({ fileType, options, templateId, isCommonTemplate, legalDetailId }) => {
            const sortBy = pageParams.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : pageParams.sortBy;
            let filters: string[] = ProductsApiUtils.createEntityRequestFilters(pageParams, subrentModuleEnabled);
            const selectedIdsFilter = getSelectedIdsFilterForDocumentGeneration();
            if (selectedIdsFilter) filters.push(selectedIdsFilter);
            await DownloadDocumentUtils.productsInventoryListGenerateAndDownload(
                {
                    businessAccountId: businessAccountId,
                    common: Boolean(isCommonTemplate),
                    documentTemplateId: templateId!,
                    showBarcode: options?.includes(ExportProductsInventoryListPopoverOptions.SHOW_BAR_CODE),
                    expandInstances: options?.includes(ExportProductsInventoryListPopoverOptions.WITH_INSTANCES),
                    showPurchaseCost: options?.includes(ExportProductsInventoryListPopoverOptions.SHOW_PURCHASE_COST),
                    showCarryingValue: options?.includes(ExportProductsInventoryListPopoverOptions.SHOW_CARRYING_VALUE),
                    expandInstanceStateDescription: options?.includes(ExportProductsInventoryListPopoverOptions.EXPAND_INSTANCE),
                    showColumnActualAvailability: options?.includes(ExportProductsInventoryListPopoverOptions.RECONCILIATION_COLUMNS),
                    showColumnConvergence: options?.includes(ExportProductsInventoryListPopoverOptions.RECONCILIATION_COLUMNS),
                    showColumnNotes: options?.includes(ExportProductsInventoryListPopoverOptions.RECONCILIATION_COLUMNS),
                    includeSubrentedInstances: options?.includes(ExportProductsInventoryListPopoverOptions.INCLUDE_SUBRENTED_INSTANCES),
                    groupByCategories: options?.includes(ExportProductsInventoryListPopoverOptions.GROUP_BY_CATEGORIES),
                    withSupplier: options?.includes(ExportProductsInventoryListPopoverOptions.WITH_SUPPLIER),
                    withSerialNumber: options?.includes(ExportProductsInventoryListPopoverOptions.WITH_SERIAL_NUMBER),
                    filters: filters,
                    sortBy: sortBy,
                    sortOrder: pageParams.sortOrder,
                    search: pageParams.search,
                    legalDetailId,
                },
                `Инвентарный лист`,
                fileType as FileTypeCodeEnum.EXCEL | FileTypeCodeEnum.PDFTYPE
            ).catch((e) => {
                showNotification('error', 'Ошибка генерации файла');
            });
        },
        [businessAccountId, getSelectedIdsFilterForDocumentGeneration, pageParams, subrentModuleEnabled]
    );

    const onOffloadingDownload: OffloadingDocumentOnDownload = useCallback(
        async ({ fileType, options }) => {
            const { sortOrder, sortBy, search } = pageParams;
            const filters = ProductsApiUtils.createEntityRequestFilters(pageParams, subrentModuleEnabled);

            await downloadOffloading({
                businessAccountId,
                canViewFinancialData,
                exportTemplateType: ExcelExportTemplateTypeCodeEnum.NOMENCLATURETEMPLATE,
                fileName: 'Экспорт продуктов',
                fileType,
                filters,
                financialFieldsEnum: NomenclatureFinancialFieldsTypeCode,
                grid: gridRef.current,
                intl,
                notFinancialFieldsEnum: NomenclatureNotFinancialFieldsTypeCode,
                selection: entitySelection,
                values: options,
                sortOrder,
                sortBy,
                search,
                excludeColumns: undefined,
                columnsMap: productsGridColumnsMap
            });
        },
        [businessAccountId, canViewFinancialData, entitySelection, gridRef, intl, pageParams]
    );

    return useMemo(
        () => (
            <>
                <ExportOffloadingPopover storageKey={'productsOffloading'} onDownload={onOffloadingDownload} />
                <ExportProductsPriceListPopover storageKey={'productsPriceList'} onDownload={onDownloadProductsPriceList} />
                <ExportProductsInventoryListPopover onDownload={onDownloadProductsInventoryList} />
            </>
        ),
        [onDownloadProductsInventoryList, onDownloadProductsPriceList]
    );
};
