import React from 'react';

export const IconSignInAltSolidCustom = () => (
    <svg viewBox="0 0 32 32" width="1em" height="1em" fill="currentColor">
    <g>
        <g transform="translate(16.000000, 16.000000) rotate(-270.000000) translate(-16.000000, -16.000000) translate(4.000000, 4.000000)">
            <path d="M12,0 C6.421875,0 1.742188,3.832031 0.40625,9 L2.46875,9 C3.746094,4.945313 7.53125,2 12,2 C17.515625,2 22,6.484375 22,12 C22,17.515625 17.515625,22 12,22 C7.53125,22 3.746094,19.054688 2.46875,15 L0.40625,15 C1.742188,20.167969 6.421875,24 12,24 C18.617188,24 24,18.617188 24,12 C24,5.382813 18.617188,0 12,0 Z M11.34375,7.28125 L9.90625,8.71875 L12.1875,11 L0,11 L0,13 L12.1875,13 L9.90625,15.28125 L11.34375,16.71875 L15.34375,12.71875 L16.03125,12 L15.34375,11.28125 L11.34375,7.28125 Z"></path>
        </g>
    </g>
</svg>
);
