import classNames from 'classnames';
import { ReactNode, useCallback, useRef, useState } from 'react';

import './ResizablePanels.less';

interface Props {
    leftPanel: {
        element: ReactNode | null;
        width: number;
        min?: number;
        max?: number;
    };
    rightPanel: ReactNode | null;
    className?: string;
    onResize?: (width: number) => void;
}

const ResizablePanels = ({ leftPanel, rightPanel, className, onResize }: Props) => {
    const [leftWidth, setLeftWidth] = useState(leftPanel.width);
    const containerRef = useRef<HTMLDivElement>(null);
    const [resizing, setResizing] = useState(false);

    const handleMouseMove = useCallback(
        (event: MouseEvent) => {
            event.preventDefault();
            setLeftWidth((prev) => {
                let width = prev + event.movementX;
                if (leftPanel.min && width < leftPanel.min) {
                    width = leftPanel.min;
                }

                if (leftPanel.max && width > leftPanel.max) {
                    width = leftPanel.max;
                }

                if (width < 0) width = 0;

                if (containerRef.current && width > containerRef.current.getBoundingClientRect().width) {
                    width = containerRef.current.getBoundingClientRect().width;
                }

                onResize?.(width);
                return width;
            });
        },
        [leftPanel.max, leftPanel.min, onResize]
    );

    const handleMouseDown = () => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp, { once: true });
        setResizing(true);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        setResizing(false);
    };

    return leftPanel.element ? (
        <div className={classNames('rr-resizable-panels', className)} ref={containerRef}>
            <div style={{ width: leftWidth }}>{leftPanel.element}</div>
            <span
                style={{ left: leftWidth - 2 }}
                className={classNames('rr-resizable-panels-handle', resizing && 'rr-resizable-panels-handle-resizing')}
                onMouseDown={handleMouseDown}
            />
            <div style={{ width: `calc(100% - ${leftWidth}px)` }}>{rightPanel}</div>
        </div>
    ) : (
        <>{rightPanel}</>
    );
};
export default ResizablePanels;
