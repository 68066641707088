import React from 'react';
import { Icon } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import classNames from 'classnames';
import { IconClipboardListSolid, IconStampSolid } from '../../icons';
import { RoundButton } from '../../index';
import { DocumentTypeCodeEnum } from '../../../server';
import './downloadDocumentButton.less';

interface DownloadDocumentButtonProps {
    disabled?: ButtonProps['disabled'];
    style?: ButtonProps['style'];
    className?: ButtonProps['className'];
    onClick?: ButtonProps['onClick'];
    documentType: DocumentTypeCodeEnum.BILLOFLADING | DocumentTypeCodeEnum.CONTRACT;
}

const DocumentTypes = {
    [DocumentTypeCodeEnum.CONTRACT]: {
        icon: <Icon component={IconStampSolid} />,
        label: 'Сгенерировать договор',
    },
    [DocumentTypeCodeEnum.BILLOFLADING]: {
        icon: <Icon component={IconClipboardListSolid} />,
        label: 'Транспортная накладная',
    },
};

export const DownloadDocumentButton = ({ documentType, style, className, disabled, onClick }: DownloadDocumentButtonProps) => {
    const params = DocumentTypes[documentType];
    return (
        <span>
            <RoundButton
                colorScheme={'successSecondary'}
                style={style}
                className={classNames('rr-download-document-button', 'rr-project-page-description--export-contract-button', className)}
                disabled={disabled}
                onClick={onClick}
            >
                {params.icon}
                {params.label}
            </RoundButton>
        </span>
    );
};

export const DownloadContractButton = ({ documentType, style, className, disabled, onClick }: DownloadDocumentButtonProps) => {
    const params = DocumentTypes[documentType];
    return (
        <div
            className={classNames('rr-download-contract-button', disabled && 'rr-download-contract-button-disabled', className)}
            style={style} onClick={disabled ? (e)=>e.stopPropagation() : onClick}>
            {params.icon}
            <div style={{marginLeft: 7}}>{params.label}</div>
        </div>
    );
};