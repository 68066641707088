import { FormFieldsGroup, FormItemType } from '../../../../../../components/dynamicForm/DynamicForm';
import { FormUtils } from '../../../../../../core/utils/formUtils';
import { ListLocationsParams } from '../api/locations.api.types';
import { LocalizationEnum } from '../../../../../../localization';
import { ContactSelect } from '../../../../../../components/v2/select/contactSelect/contactSelect';

export const locationsFilters: FormFieldsGroup[] = FormUtils.typedFormFields<ListLocationsParams>([
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: 'Контакт',
                id: 'contactId',
                type: FormItemType.Component,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                component: ContactSelect,
                style: { minWidth: 220 },
                className: 'rr-select-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__HIDE_ARCHIVE,
                id: 'hideArchive',
                type: FormItemType.Switch,
            },
        ],
    },
]);
