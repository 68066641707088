import React from 'react';
import { TreeSelectProps } from 'antd/lib/tree-select';
import { ProductCategory, RenterCategory } from '../../../shared/reducers/entities.reducer';
import { MAX_DEEP_CATEGORIES } from '../../../config/constants';
import { TreeSelect } from 'antd';
import { TreeNodeNormal } from 'antd/es/tree-select/interface';
import './category-block-select.less';

interface IProps extends TreeSelectProps<string | number | Array<any>> {
    onChange?: (value: any, label: any, extra: any) => void;
    value?: string | number | Array<any>;
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
    categories: ProductCategory[] | RenterCategory[];
    multiple?: boolean;
    elementId?: number;
    id?: string;
}

type ProcessedCategory = IProps['categories'][number] & {
    disabled: boolean;
    children: ProcessedCategory[];
};

const mapProductsCategoriesToTreeNodeNormal = (categories: ProcessedCategory[]): TreeNodeNormal[] => {
    return categories.map(({ key, value, title, children, disabled }) => {
        return {
            key: String(key),
            value,
            title,
            disabled,
            children: mapProductsCategoriesToTreeNodeNormal(children),
        };
    });
};

const getLevelSuffix = (number: number): string => {
    if (number % 10 === 1 && number % 100 !== 11) {
        return 'уровень';
    } else if (2 <= number % 10 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
        return 'уровня';
    } else {
        return 'уровней';
    }
};

export class CategoryBlockSelect extends React.Component<IProps> {
    render() {
        const {
            onChange,
            value,
            id,
            getPopupContainer,
            categories,
            multiple,
            elementId,
            ...treeSelectProps
        } = this.props;

        const getDepth = (elementId: number | undefined) => {
            let deep = 1;
            let deepArr: number[] = [];

            function getArrayDepth(obj: IProps['categories']) {
                deepArr.push(deep);

                for (let i = 0; i < obj.length; i++) {
                    if (i === 0) {
                        deep++;
                    }
                    getArrayDepth(obj[i].children);
                }
            }

            let element: IProps['categories'][number];

            const getElementById = (elementId: number | undefined, categories: ProductCategory[]) => {
                categories.forEach((child) => {
                    if (elementId === child.key) {
                        element = child;
                        return;
                    }
                    getElementById(elementId, child.children);
                });
            };
            getElementById(elementId, categories);

            if (element! && element.children && element.children.length > 0) {
                getArrayDepth(element.children);
            }
            return Math.max(...deepArr);
        };

        const cloneArray = (
            arr: IProps['categories'],
            disableFrom: number | undefined = undefined,
            disable = false,
            level = 1,
            targetElementDepth = 0
        ): ProcessedCategory[] => {
            return arr.map((c) => {
                let disabled =
                    (disableFrom !== undefined && c.value === disableFrom) ||
                    disable ||
                    level + depth > MAX_DEEP_CATEGORIES;
                let children = c.children
                    ? cloneArray(c.children, disableFrom, disabled, level + 1, targetElementDepth)
                    : [];
                //console.log('====', c.title, level, targetElementDepth);
                return {
                    ...c,
                    children,
                    disabled,
                    title: c.title,
                };
            });
        };

        const elementDepth = getDepth(elementId);
        const depth = !isFinite(elementDepth) ? 1 : elementDepth;

        const processedCategories = cloneArray(categories, elementId, false, 1, depth);
        const treeData: TreeNodeNormal[] = mapProductsCategoriesToTreeNodeNormal(processedCategories);

        return (
            <>
                <TreeSelect
                    getPopupContainer={getPopupContainer}
                    showSearch
                    treeData={treeData}
                    treeNodeFilterProp={'title'}
                    style={{ width: '100%' }}
                    value={value}
                    allowClear
                    treeDefaultExpandAll
                    multiple={multiple !== false}
                    onChange={onChange}
                    treeIcon
                    className={'rr-base-categories-select'}
                    {...treeSelectProps}
                />
                <div className={'rr-base-categories-select_parent-category-description'}>
                    Максимальная вложенность - {MAX_DEEP_CATEGORIES} {getLevelSuffix(MAX_DEEP_CATEGORIES)}
                </div>
            </>
        );
    }
}
