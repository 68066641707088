import React from 'react';
import { Checkbox, Icon } from 'antd';
import { tableCellStringRenderer } from './renderers/tableCellStringRenderer';
import { tableCellDateRenderer } from './renderers/tableCellDateRenderer';
import { tableCellMoneyRenderer } from './renderers/tableCellMoneyRenderer';
import { tableCellDateTimeRenderer } from './renderers/tableCellDateTimeRenderer';
import { TableCellAvatarRendererWrapper } from './renderers/tableCellAvatarRendererWrapper';
import { tableCellActionsRenderer } from './renderers/tableCellActionsRenderer';
import { tableCellStatusRenderer } from './renderers/tableCellStatusRenderer';
import { tableCellTextRenderer } from './renderers/tableCellTextRenderer';
import { tableCellPercentsRenderer } from './renderers/tableCellPercentsRenderer';
import { tableCellOperationRenderer } from './renderers/tableCellOperationRenderer';
import { tableCellBooleanRenderer } from './renderers/tableCellBooleanRenderer';
import ReactTable from '../reactTable6/react-table';
import '../reactTable6/react-table.css';
import selectTableHOC from '../reactTable6/hoc/selectTable';
//import withDraggableColumns from 'react-table-hoc-draggable-columns';
//import 'react-table-hoc-draggable-columns/dist/styles.css';
import './Table.less';
import { IconAngleUp, IconClose } from '../icons';
import { EntityType } from '../../../index';
import { getGridStorageData } from './utils'; // , setGridStorageData
import { tableCellPhoneRenderer } from './renderers/tableCellPhoneRenderer';
import { tableCellProblemRenderer } from './renderers/tableCellProblemRenderer';
import { DeepKeys } from '@tanstack/react-table';

type State = {
    columns: Array<any>;
    selection: Array<any>;
    selectAll: boolean;
    someSelected: boolean;
    popupVisible: boolean;
};

type Props<T extends object> = {
    columns: Array<TableColumn<T>>;
    excludeColumns: Array<string>;
    data: any[] | null;
    selectable: boolean;
    indexFieldName?: string;
    onSortedChange?(id: string, desc: boolean): void;
    onSelectionChanged?(selection: any[]): void;
    getRowProps?(state, rowInfo, column): void;
    onRowAction?(entity: any, action: string, instanceCount?: number): void;
    onColumnRemove?(id?: string): void;
    entityType: EntityType;
    rowPopoverComponent?: any;
    rowPopoverComponentProps?: any;
    defaultSortDesc?: boolean;
    defaultSorted?: string;
    selectWidth?: number;
    dynamicColumns?: boolean;
    name: string;
};

export enum ColumnTypes {
    Date = 'date',
    DateTime = 'dateTime',
    String = 'string',
    Text = 'text',
    CustomRender = 'render',
    Image = 'image',
    Money = 'money',
    Actions = 'actions',
    Status = 'status',
    Problem3 = 'problem3',
    Percents = 'percents',
    Operation = 'operation',
    Boolean = 'boolean',
    Phone = 'phone',
    Click = 'click',
}

export type TableColumn<T extends object> = {
    id?: string;
    title?: React.ReactNode;
    type?: ColumnTypes;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    resizable?: boolean;
    sortable?: boolean;
    dataIndex?: DeepKeys<T>;
    render?: Function;
    className?: string | Function;
    headerClassName?: string;
    removable?: boolean;
    dontHide?: boolean;
    defaultHidden?: boolean;
    draggable?: boolean;
    expandable?: boolean;
    showInMenuWhenExcluded?: boolean;
};

const SelectTable = selectTableHOC(ReactTable);
const NormalTable = ReactTable;

export class Table<T extends object> extends React.Component<Props<T>, State> {
    public static defaultProps = {
        indexFieldName: 'id',
        excludeColumns: [],
        defaultSortDesc: false,
    };

    constructor(props) {
        super(props);

        let columns = this.props.dynamicColumns ? [] : this.getColumns();

        this.state = {
            columns: columns,
            selection: [],
            selectAll: false,
            someSelected: false,
            popupVisible: false,
        };
    }

    getRenderer = (columnType: ColumnTypes, renderFn) => {
        let render;

        if (columnType === ColumnTypes.CustomRender && renderFn)
            render = this._customCellRenderer(renderFn, this.props.onRowAction, this.props.entityType, true);
        else if (columnType === ColumnTypes.Date) render = this._customCellRenderer(tableCellDateRenderer);
        else if (columnType === ColumnTypes.DateTime) render = this._customCellRenderer(tableCellDateTimeRenderer);
        else if (columnType === ColumnTypes.Image) render = this._customCellRenderer(TableCellAvatarRendererWrapper);
        else if (columnType === ColumnTypes.Money) render = this._customCellRenderer(tableCellMoneyRenderer);
        else if (columnType === ColumnTypes.Actions)
            render = this._customCellRenderer(tableCellActionsRenderer, this.props.onRowAction, this.props.entityType, false);
        else if (columnType === ColumnTypes.Status) render = this._customCellRenderer(tableCellStatusRenderer, this.props.entityType);
        else if (columnType === ColumnTypes.Operation) render = this._customCellRenderer(tableCellOperationRenderer);
        else if (columnType === ColumnTypes.Text) render = this._customCellRenderer(tableCellTextRenderer);
        else if (columnType === ColumnTypes.Boolean) render = this._customCellRenderer(tableCellBooleanRenderer);
        else if (columnType === ColumnTypes.Problem3)
            render = this._customCellRenderer(tableCellProblemRenderer, undefined, this.props.entityType);
        else if (columnType === ColumnTypes.Percents) render = this._customCellRenderer(tableCellPercentsRenderer);
        else if (columnType === ColumnTypes.Phone) render = this._customCellRenderer(tableCellPhoneRenderer);
        else render = this._customCellRenderer(tableCellStringRenderer);
        return render;
    };

    getColumns = () => {
        const gridData = getGridStorageData(this.props.name);

        let columns = this.props.columns
            .filter((item) => !item.dataIndex || this.props.excludeColumns.indexOf(item.dataIndex as string) === -1)
            .map((item) => {
                let columnType: ColumnTypes | undefined = item.type;
                if (columnType === undefined) columnType = ColumnTypes.String;
                let render;
                //
                // const instanceTrackingTypeCode:any = this.props.data;
                //
                // const isVariant = instanceTrackingTypeCode  ?  instanceTrackingTypeCode.length > 0 ? instanceTrackingTypeCode[0].instanceTrackingTypeCode ? (instanceTrackingTypeCode[0].instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK || instanceTrackingTypeCode[0].instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED) : false : false : false;

                /*if(columnType === ColumnTypes.CustomRender && item.render) render = this._customCellRenderer(item.render, this.props.onRowAction, this.props.entityType, true);
            else if(columnType === ColumnTypes.Date) render = this._customCellRenderer(tableCellDateRenderer);
            else if(columnType === ColumnTypes.DateTime) render = this._customCellRenderer(tableCellDateTimeRenderer);
            else if(columnType === ColumnTypes.Image) render = this._customCellRenderer(tableCellAvatarRenderer);
            else if(columnType === ColumnTypes.Money) render = this._customCellRenderer(tableCellMoneyRenderer);
            else if(columnType === ColumnTypes.Actions)  render = this._customCellRenderer(tableCellActionsRenderer, this.props.onRowAction, this.props.entityType, false);
            else if(columnType === ColumnTypes.Status) render = this._customCellRenderer(tableCellStatusRenderer, this.props.entityType);
            else if(columnType === ColumnTypes.Operation) render = this._customCellRenderer(tableCellOperationRenderer);
            else if(columnType === ColumnTypes.Text) render = this._customCellRenderer(tableCellTextRenderer);
            else if(columnType === ColumnTypes.Boolean) render = this._customCellRenderer(tableCellBooleanRenderer);
            else if(columnType === ColumnTypes.Percents) render = this._customCellRenderer(tableCellPercentsRenderer);
            else render = this._customCellRenderer(tableCellStringRenderer);*/

                render = this.getRenderer(columnType, item.render);

                let column = {
                    Header: (
                        <div
                            className={`rr-grid-table-header-cell`}
                            style={{ marginLeft: (item.sortable !== false ? 14 + 2 : 0) + (item.removable ? 21 : 0) }}
                        >
                            <div>{item.title}</div>
                            {item.sortable !== false ? (
                                <div>
                                    <Icon component={IconAngleUp} className={'rr-grid-sort-icon'} />
                                </div>
                            ) : null}
                            {item.removable ? (
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (this.props.onColumnRemove) {
                                            this.props.onColumnRemove(item.dataIndex as string);
                                        }
                                    }}
                                >
                                    <Icon className={'rr-grid-table-remove-icon'} component={IconClose} />
                                </div>
                            ) : null}
                        </div>
                    ),
                    accessor: item.dataIndex,
                    resizable: item.resizable,
                    sortable: item.sortable,
                    className: item.className,
                    Cell: render,
                };
                if (item.width !== undefined && item.width > 0) column['width'] = item.width;
                if (item.minWidth !== undefined) column['minWidth'] = item.minWidth;
                if (item.maxWidth !== undefined) column['maxWidth'] = item.maxWidth;

                if (item.dataIndex && gridData.columns && gridData.columns[item.dataIndex as string] !== undefined) {
                    column['width'] = gridData.columns[item.dataIndex as string].width;
                }

                return column;
            });
        return columns;
    };

    _customCellRenderer(renderer: Function, param1: any = undefined, param2: any = undefined, withPopover: boolean = true) {
        return (value, h) => {
            return withPopover && this.props.rowPopoverComponent ? (
                React.createElement(
                    this.props.rowPopoverComponent,
                    {
                        record: value.original,
                        ...this.props.rowPopoverComponentProps,
                    },
                    // TODO Как-то оптимизировать нужно
                    () => <div>{renderer(value.value, value.original, param1, param2)}</div>
                )
            ) : (
                <div>
                    <div>{renderer(value.value, value.original, param1, param2)}</div>
                </div>
            );
        };
    }

    toggleSelection = (key, shift, row) => {
        //console.log('toggleSelection', key, shift, row);
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
        } else {
            selection.push(key);
        }

        let someSelected = !!(selection.length > 0 && this.props.data && selection.length !== this.props.data.length);
        let selectAll = selection.length > 0;
        this.setState({ selection, someSelected, selectAll });
        if (this.props.onSelectionChanged) this.props.onSelectionChanged(selection);
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll && !this.state.someSelected ? false : true;
        let someSelected = false;
        const selection: string[] = [];
        if (selectAll) {
            if (this.props.data) {
                this.props.data.forEach((item) => {
                    if (this.props.indexFieldName) selection.push(item[this.props.indexFieldName]);
                });
            }
        }
        this.setState({ selectAll, selection, someSelected });
        if (this.props.onSelectionChanged) this.props.onSelectionChanged(selection);
    };

    clearSelection = () => {
        let someSelected = false;
        let selectAll = false;
        let selection = [];
        this.setState({ selectAll, selection, someSelected });
        if (this.props.onSelectionChanged) this.props.onSelectionChanged(selection);
    };

    isSelected = (key) => {
        return this.state.selection.includes(key);
    };

    _onSortedChange = (values) => {
        if (this.props.onSortedChange && values && values.length > 0) {
            this.props.onSortedChange(values[0].id, values[0].desc);
        }
    };

    _getSelectInputComponent = (props) => {
        return React.createElement(Checkbox, {
            checked: props.checked,
            className: 'rr-grid-select-checkbox',
            onChange: function onClick(e) {
                let shiftKey; // = e.shiftKey;
                e.stopPropagation();
                props.onClick(props.id, shiftKey, props.row);
            },
        });
    };

    _getSelectAllInputComponent = (props) => {
        return React.createElement(Checkbox, {
            checked: props.checked,
            className: 'rr-grid-select-checkbox',
            indeterminate: this.state.someSelected,
            onChange: function onClick(e) {
                let shiftKey; // = e.shiftKey;
                e.stopPropagation();
                props.onClick(props.id, shiftKey, props.row);
            },
        });
    };

    _getRowProps = (state, rowInfo, column) => {
        let obj = this.props.getRowProps ? this.props.getRowProps(state, rowInfo, column) : {};
        obj['onClick'] = (e) => {
            if (e.target.type !== 'checkbox' && e.altKey) {
                this.toggleSelection(rowInfo.original.id, undefined, undefined);
            }
        };
        return obj;
    };

    onResizedChange = (newResized, event) => {
        const gridData = getGridStorageData(this.props.name);
        const columns = gridData.columns || {};

        if (newResized && newResized.length) {
            newResized.forEach((item) => {
                if (!columns[item.id]) columns[item.id] = {};
                columns[item.id].width = item.value;
            });
        }
        gridData.columns = columns;
        //setGridStorageData(this.props.name, gridData);
    };

    onDraggedColumnChange = (columns) => {
        //console.log('DDD', columns);
        let keys = columns.map((column) => column.accessor);
        console.log(keys);
    };

    render() {
        const { toggleSelection, toggleAll, isSelected } = this;
        const extraProps = { isSelected, toggleAll, toggleSelection };

        let columns = this.props.dynamicColumns ? this.getColumns() : this.state.columns;

        const draggableColumns = this.props.columns
            .filter((column) => column.dataIndex && column.draggable !== false)
            .map((column) => column.dataIndex);

        return React.createElement(this.props.selectable ? SelectTable : NormalTable, {
            draggableColumns: {
                mode: 'reorder',
                draggable: draggableColumns,
                enableColumnWideDrag: !true,
                onDraggedColumnChange: this.onDraggedColumnChange,
            },
            onResizedChange: this.onResizedChange,
            keyField: this.props.indexFieldName,
            minRows: 0,
            showPagination: false,
            showPageJump: false,
            data: this.props.data || [],
            manual: true,
            columns: columns,
            SelectAllInputComponent: this._getSelectAllInputComponent,
            SelectInputComponent: this._getSelectInputComponent,
            selectWidth: this.props.selectWidth !== undefined ? this.props.selectWidth : 66,
            getTrProps: this._getRowProps,
            noDataText: '',
            onSortedChange: this._onSortedChange,
            selectAll: this.state.selectAll,
            defaultSorted: this.props.defaultSorted ? [{ id: this.props.defaultSorted, desc: this.props.defaultSortDesc }] : [],
            ...extraProps,
        });
    }
}
