import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import React, { ComponentType, CSSProperties, FC, memo, ReactNode } from 'react';
import { canViewFinancialDataSelector } from '../../../../../../../shared/reducers/permissions.reducer';
import { useAppSelector } from '../../../../../../../store/hooks';
import { LocalizationEnum, localize } from '../../../../../../../localization';
import { DashboardWidgetHeaderRedLine } from '../../components/header/components/RedLine/DashboardWidgetHeaderRedLine';
import { formatMoney } from '../../../../../../../shared/util/formatMoney';
import { CurrentCurrency } from '../../../../../../../components/currentCurrency/currentCurrency';
import { DashboardWidgetLoader } from '../../components/loader/DashboardWidgetLoader/DashboardWidgetLoader';

interface DashboardWidgetRedLineProps {
    elementsCount: [number | undefined, number | undefined];
    icons: [ComponentType, ComponentType];
    sums: [number | undefined, number | undefined];
    title: string | ReactNode;
    urls: [string, string];
    loading: boolean | undefined;
    style?: CSSProperties;
    X?: boolean;
}

export const DashboardWidgetRedLine: FC<DashboardWidgetRedLineProps> = memo((props) => {
    const { elementsCount, icons, sums, title, urls, loading } = props;
    const isCanViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    if (loading) {
        return <DashboardWidgetLoader />;
    }

    const contentWidget = (
        <>
            <div style={{ display: 'flex', flexGrow: 1, ...props.style }}>
                <DashboardWidgetHeaderRedLine hasCount={elementsCount.some((c) => c && c > 0)} />
                <div
                    style={{
                        color: 'rgb(87, 89, 98)',
                        // height: isCanViewFinancialData ? undefined : 108,
                    }}
                    className="rr-dashboard-info-panel-div"
                >
                    <div>{title}</div>
                    <div className="left-block">
                        {elementsCount[0] === 0 && elementsCount[1] === 0 ? (
                            <div style={{ color: 'rgb(52, 191, 163)' }}>{localize(LocalizationEnum.ASPECT__GLOBAL__NO)}</div>
                        ) : null}

                        {elementsCount[0] === 0 ? null : (
                            <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                                <Link to={urls[0]} className={'rr-dashboard-info-panel-link'}>
                                    <span>
                                        <Icon
                                            className={'rr-dashboard-info-panel-icon'}
                                            component={icons[0]}
                                            style={{ color: 'rgb(244, 81, 108)' }}
                                        />
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                marginRight: '5px',
                                            }}
                                        >
                                            {elementsCount[0]}
                                        </span>
                                        {isCanViewFinancialData && (
                                            <>
                                                на{' '}
                                                <span style={{ whiteSpace: 'nowrap' }}>
                                                    <span>{formatMoney(sums[0], false)}</span>
                                                    <span
                                                        style={{
                                                            color: `${elementsCount[0] === 0 ? '#aaaaaa' : 'rgb(87, 89, 98)'}`,
                                                        }}
                                                    >
                                                        {' '}
                                                        <CurrentCurrency />
                                                    </span>
                                                </span>
                                            </>
                                        )}
                                    </span>
                                </Link>
                            </div>
                        )}

                        {elementsCount[1] === 0 ? null : (
                            <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                                <Link to={urls[1]} className={'rr-dashboard-info-panel-link'}>
                                    <span>
                                        <Icon
                                            className={'rr-dashboard-info-panel-icon'}
                                            component={icons[1]}
                                            style={{ color: '#FC8C2F' }}
                                        />
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                marginRight: '5px',
                                            }}
                                        >
                                            {elementsCount[1]}
                                        </span>
                                        {isCanViewFinancialData && (
                                            <>
                                                на{' '}
                                                <span style={{ whiteSpace: 'nowrap' }}>
                                                    <span>{formatMoney(sums[1], false)}</span>
                                                    <span
                                                        style={{
                                                            color: `${elementsCount[1] === 0 ? '#aaaaaa' : 'rgb(87, 89, 98)'}`,
                                                        }}
                                                    >
                                                        {' '}
                                                        <CurrentCurrency />
                                                    </span>
                                                </span>
                                            </>
                                        )}
                                    </span>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );

    return (
        <div className={'rr-dashboard-info-panel-link-2'} style={props.X ? { marginBottom: 0, height: '100%' } : undefined}>
            {contentWidget}
        </div>
    );
});
