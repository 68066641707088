import { FAILURE, REQUEST, SUCCESS } from './action-type.util';
import { BusinessAccountInfoRead, serverApi, ServerError } from '../../server';
import { getServerError } from '../util/utils';
import { IRootState } from './index';

export const ACTION_TYPES = {
    GET_BUSINESS_ACCOUNT: 'businessAccount/GET_BUSINESS_ACCOUNT',
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    entity: null as BusinessAccountInfoRead | null,
    loadingLastTime: undefined as undefined | Date,
};

export type BusinessAccountState = Readonly<typeof initialState>;

// Reducer

export default (state: BusinessAccountState = initialState, action): BusinessAccountState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.GET_BUSINESS_ACCOUNT):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
                loadingLastTime: new Date(),
            };

        case FAILURE(ACTION_TYPES.GET_BUSINESS_ACCOUNT):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
                loadingLastTime: undefined,
            };

        case SUCCESS(ACTION_TYPES.GET_BUSINESS_ACCOUNT):
            return {
                ...state,
                entity: action.payload.data,
                loading: false,
                loadingLastTime: new Date(),
            };

        default:
            return state;
    }
};

// Actions

// Получение данных БА
export const getBusinessAccount = (businessAccountId: number, forceLoading: boolean = false) => {
    return (dispatch, getState) => {
        let state: BusinessAccountState = (getState() as IRootState).businessAccount;
        if (forceLoading || !state.loadingLastTime || Date.now() > state.loadingLastTime.getTime() + 10 * 1000) {
            return dispatch({
                type: ACTION_TYPES.GET_BUSINESS_ACCOUNT,
                payload: serverApi.getBusinessAccountById(businessAccountId),
            });
        }
    };
};

export const businessAccountAddressSelector = (state: IRootState) => {
    const ba = state.businessAccount.entity;
    if (!ba) return [];
    const fields = ba.legalDetails?.[0]?.fields;
    if (!fields) return [];
    const legalAddress = fields.find((field) => field.name === 'LEGAL_ADDRESS')?.value;
    const factAddress = fields.find((field) => field.name === 'PHYSICAL_ADDRESS')?.value;

    const res: string[] = [];
    if (factAddress) res.push(factAddress);
    if (legalAddress) res.push(legalAddress);
    return res;
};
