import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import { IRootState } from '../../../shared/reducers';
import { connect } from 'react-redux';
import { resetOperationForm } from '../operationForm/reducers/operationForm.reducer';
import { Tabs } from 'antd';
import { getPathFromState, isStringInEnum } from '../../../shared/util/utils';
import { push, replace } from 'connected-react-router';
import { Link } from 'react-router-dom';
import RoundButton from '../../../components/button/roundButton';
import React from 'react';
import { reset } from '../renters/reducers/renters/renters.reducer';
import { PageUtils } from '../../../shared/util/pageUtils';
import { getCurrentTabsSelector, saveCurrentTabToStore, setBlockedUrl } from '../../../shared/reducers/userSettings/userSettings.reducer';
import { TransportationsList } from './tabs/transportations/list/TransportationsList';
import { VehiclesList } from './tabs/vehicles/list/VehiclesList';
import { Redirect } from 'react-router';
import { getGridStorageData } from '../../../components/grid/utils';
import { defaultTab, logisticsPageUrlRoute, tabToCreateButtonText } from './data/tabs';
import { LocationsList } from './tabs/locations/list/LocationsList';
import { ParamsUtils } from '../../../core/utils/paramsUtils';

import { LogisticsPageTabsEnum } from '../../../shared/constants/tabEnums';
import LocationsTab from './tabs/locations/LocationsTab';

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

class LogisticsPage extends BasePage<IProps> {
    componentDidMount() {
        super.componentDidMount();

        this.props.setBlockedUrl(`/${this.props.businessAccountId}/${logisticsPageUrlRoute}`);
    }

    componentDidUpdate() {
        if (this.currentTab) saveCurrentTabToStore('logistics', this.currentTab);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.setBlockedUrl(undefined);
    }

    onTabChange = (key: string) => {
        const newTabKey = isStringInEnum(key, LogisticsPageTabsEnum, defaultTab);
        const path = PageUtils.getPathForPushOnPageTab(this.urlMainPath, newTabKey);

        saveCurrentTabToStore('logistics', newTabKey);

        this.props.push(path);
    };

    getPathForNewEntity = (tab: string): string => {
        const urlSearchParams = ParamsUtils.getURLSearchParams(this.props.location);
        const searchParams = new URLSearchParams(urlSearchParams);
        searchParams.append('modal', 'new');

        const optionalSlash = this.props.location.pathname.endsWith('/') ? '/' : '';
        const paramsString = searchParams.toString() ? `?${searchParams.toString()}` : '';

        return `${this.urlMainPath}/${tab}${optionalSlash}${paramsString}`;
    };

    get urlMainPath() {
        return `/${this.props.businessAccountId}/${logisticsPageUrlRoute}` as const;
    }

    get currentTab() {
        return this.getCurrentTabFromPathname(logisticsPageUrlRoute, LogisticsPageTabsEnum);
    }

    renderContent(): JSX.Element {
        const { currentTab } = this;

        if (currentTab == null) {
            const tab = isStringInEnum(this.props.tabFromStore, LogisticsPageTabsEnum, defaultTab);
            const gridData = getGridStorageData(tab);
            return (
                <Redirect
                    to={getPathFromState(`${this.urlMainPath}/${tab}`, '', {
                        ...gridData.filters,
                        ...gridData.params,
                    })}
                />
            );
        }

        const currentTabCreateButtonText = tabToCreateButtonText[currentTab];

        return (
            <Tabs
                activeKey={currentTab}
                onChange={this.onTabChange}
                className={'rr-tabs'}
                type="line"
                animated={false}
                destroyInactiveTabPane
                tabBarExtraContent={
                    <Link to={this.getPathForNewEntity(currentTab)}>
                        <RoundButton icon={'plus'} colorScheme={'success'}>
                            {currentTabCreateButtonText}
                        </RoundButton>
                    </Link>
                }
            >
                {
                    <Tabs.TabPane tab={'Перевозки'} key={LogisticsPageTabsEnum.TRANSPORTATIONS}>
                        <TransportationsList
                            gridName={LogisticsPageTabsEnum.TRANSPORTATIONS}
                            urlPath={`${logisticsPageUrlRoute}/${LogisticsPageTabsEnum.TRANSPORTATIONS}`}
                            visible={currentTab === LogisticsPageTabsEnum.TRANSPORTATIONS}
                        />
                    </Tabs.TabPane>
                }
                {
                    <Tabs.TabPane tab={'Локации'} key={LogisticsPageTabsEnum.LOCATIONS}>
                        <LocationsTab />
                    </Tabs.TabPane>
                }
                {
                    <Tabs.TabPane tab={'Транспорт'} key={LogisticsPageTabsEnum.VEHICLES}>
                        <VehiclesList gridName={LogisticsPageTabsEnum.VEHICLES} />
                    </Tabs.TabPane>
                }
            </Tabs>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        businessAccountId: storeState.system.businessAccountId,
        tabFromStore: getCurrentTabsSelector(storeState)?.['logistics'],
    };
};

const mapDispatchToProps = { resetOperationForm, push, reset, replace, setBlockedUrl };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LogisticsPage);
