import {LocalizationEnum} from '../../../../../../localization';
import {FormFieldsGroup, FormItemType} from '../../../../../../components/dynamicForm/DynamicForm';
import {FormUtils} from '../../../../../../core/utils/formUtils';
import {PageURLParamDescriptionObject, PageURLParamDescriptions} from "../../../../../../core/utils/descriptions";
import {CalendarDisplayType} from "../../../components/Calendar/utils/data/calendarConstants";
import {CalendarCapture, CalendarGridParams} from "../../../components/Calendar/types/params";
import {SummaryScheduleCalendarSortValue} from "../data/sortData";
import {CalendarCategoriesSelect, CalendarSelectProps} from "../../../components/CalendarCategoriesSelect";
import {EntityRemoteSelect, EntityRemoteSelectProps} from "../../../../../../components/select/EntityRemoteSelect";
import {WrappedFormUtils} from "antd/lib/form/Form";
import {ServerUtils} from "../../../../../../core/utils/serverUtils";
import {
    InstanceRecord,
    InstanceTrackingTypeCodeEnum,
    KitRecord,
    ProductRecord,
    VariantRecord
} from "../../../../../../server";

export interface SummaryScheduleCalendarFilters extends CalendarGridParams<SummaryScheduleCalendarSortValue> {
    screenLeft?: number;
    screenRight?: number;
    group?: SummaryScheduleCalendarSortValue;
    capture?: CalendarCapture;
    categoryIds?: number[]|number;
    includeRentElements?: boolean;
    productId?: number;
    kitId?: number;
    variantId?: number;
    instanceId?: number;
    productInstanceTrackingTypeCode?: InstanceTrackingTypeCodeEnum;
}


export const summaryScheduleCalendarFiltersDescription: Required<PageURLParamDescriptionObject<SummaryScheduleCalendarFilters>> = {
    screenLeft: PageURLParamDescriptions.numberParam,
    screenRight: PageURLParamDescriptions.numberParam,
    group: PageURLParamDescriptions.searchParam(),
    capture: PageURLParamDescriptions.searchParam(),
    categoryIds: PageURLParamDescriptions.numbersSelectParam,
    displayType: PageURLParamDescriptions.enumSelectParam(CalendarDisplayType),
    includeRentElements: PageURLParamDescriptions.switchParam,
    productId: PageURLParamDescriptions.numberParam,
    kitId: PageURLParamDescriptions.numberParam,
    variantId: PageURLParamDescriptions.numberParam,
    instanceId: PageURLParamDescriptions.numberParam,
    productInstanceTrackingTypeCode: PageURLParamDescriptions.enumSelectParam(InstanceTrackingTypeCodeEnum)
};

export const summaryScheduleCalendarFilters: FormFieldsGroup[] = FormUtils.typedFormFields<SummaryScheduleCalendarFilters>([
    {
        fields: [
            {
                label: '',
                id: 'productInstanceTrackingTypeCode',
                type: FormItemType.Hidden,
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__CATEGORIES,
                id: 'categoryIds',
                type: FormItemType.Component,
                component: CalendarCategoriesSelect,
                componentProps: ():CalendarSelectProps=>({
                    getPopupContainer: () => document.querySelector('body') as HTMLElement
                }),
                placeholder: 'Любые',
                style: { minWidth: 120 },
                className: 'rr-select-gray',
            },
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__KIT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A11,
                id: 'kitId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                className: 'rr-select-gray',
                componentProps: (form: WrappedFormUtils):EntityRemoteSelectProps => {
                    const categoryIds:number[]|undefined = form.getFieldValue('categoryIds');
                    return {
                        operationName: 'listKits',
                        nameField: 'shortName',
                        getEntityOperationName: 'getKitById',
                        sortBy: 'lastActivityDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: ServerUtils.createRequestFilters<KitRecord>([
                            ['archive', 'EQ', false],
                            categoryIds && ['categoryIds', 'IN', categoryIds]
                        ])
                    }
                },
                getInitialValue: (value) => {
                    return value;
                },
                onChange: async (value: any, form) => {
                    form.setFieldsValue({
                        productId: undefined,
                        variantId: undefined,
                        instanceId: undefined,
                        productInstanceTrackingTypeCode: undefined,
                    });
                },
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__PRODUCT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A11,
                id: 'productId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: EntityRemoteSelect,
                className: 'rr-select-gray',
                componentProps: (form: WrappedFormUtils):EntityRemoteSelectProps => {
                    const categoryIds:number[]|undefined = form.getFieldValue('categoryIds');
                    return {
                        operationName: 'listProducts',
                        nameField: 'shortName',
                        getEntityOperationName: 'getProductById',
                        sortBy: 'lastActivityDate',
                        sortOrder: 'DESC',
                        showSearch: true,
                        filters: ServerUtils.createRequestFilters<ProductRecord>([
                            ['archive', 'EQ', false],
                            categoryIds && ['categoryIds', 'IN', categoryIds]
                        ])
                    }
                },
                getInitialValue: (value) => {
                    return value;
                },
                onChange: async (value: any, form) => {
                    form.setFieldsValue({
                        variantId: undefined,
                        instanceId: undefined,
                        kitId: undefined,
                        productInstanceTrackingTypeCode: value?.data?.instanceTrackingTypeCode,
                    });
                },
            },
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__VARIANT,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A12,
                id: 'variantId',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: EntityRemoteSelect,
                className: 'rr-select-gray',
                componentProps: (form: WrappedFormUtils):EntityRemoteSelectProps => {
                    return {
                        operationName: 'listProductVariants',
                        nameField: 'name',
                        sortBy: 'lastActivityDate',
                        getEntityOperationName: 'getProductVariantById',
                        sortOrder: 'DESC',
                        productId: form.getFieldValue('productId')
                            ? form.getFieldValue('productId').key || form.getFieldValue('productId')
                            : 1,
                        showSearch: true,
                        filters: ServerUtils.createRequestFilters<VariantRecord>([
                            ['archive', 'EQ', false]
                        ])
                    };
                },
                getInitialValue: (value) => {
                    return value;
                },
                visible: (getFieldValue, form?: WrappedFormUtils, currentValues?) => {
                    const instanceTrackingTypeCode = getFieldValue('productInstanceTrackingTypeCode');

                    return (
                        currentValues?.variantId ||
                        getFieldValue('variantId') ||
                        instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK ||
                        instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
                    );
                },
                onChange: (value: any, form) => {
                    form.setFieldsValue({ instanceId: undefined });
                },
            },
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__INSTANCE,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                helpTooltipLocalizationEnum: LocalizationEnum.PAGE__CALENDAR__TOOLTIP__A13,
                id: 'instanceId',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: EntityRemoteSelect,
                className: 'rr-select-gray',
                componentProps: (form: WrappedFormUtils):EntityRemoteSelectProps => {
                    const variantId = form.getFieldValue('variantId')
                        ? form.getFieldValue('variantId').key || form.getFieldValue('variantId')
                        : undefined;
                    return {
                        operationName: 'listProductInstances',
                        nameField: 'nameOrCode',
                        sortBy: 'lastActivityDate',
                        getEntityOperationName: 'getProductInstanceById',
                        sortOrder: 'DESC',
                        productId: form.getFieldValue('productId')
                            ? form.getFieldValue('productId').key || form.getFieldValue('productId')
                            : 1,
                        showSearch: true,
                        filters: ServerUtils.createRequestFilters<InstanceRecord>([
                            variantId ? ['variantId', 'EQ', variantId] : undefined
                        ])
                    };
                },
                getInitialValue: (value) => {
                    return value;
                },
                visible: (getFieldValue, form?: WrappedFormUtils, currentValues?) => {
                    let instanceTrackingTypeCode = getFieldValue('productInstanceTrackingTypeCode');

                    return (
                        currentValues?.instanceId ||
                        getFieldValue('instanceId') ||
                        instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                        instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
                    );
                },
                onChange: (value: any, form) => {
                    if (!form.getFieldValue('variantId') && value && value.data && value.data.variantId) {
                        form.setFieldsValue({ variantId: value.data.variantId });
                    }
                },
            },
            {
                label: 'Показывать обязательства',
                id: 'includeRentElements',
                type: FormItemType.Switch,
            },
        ],
    },
]);
