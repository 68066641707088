import {LegalCountryCodeEnum, LegalDetailsFieldTypeCodeEnum, LegalSubjectTypeCodeEnum} from "../../../../server";
import {LocalizationEnum} from "../../../../localization";
import {ActionButtonsData} from "../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup";
import {BasicActionsData} from "../../../../components/v2/actionButtons/actionButton/actionButton";
import {Icon} from "antd";
import {IconPlus} from "../../../../components/icons";
import {Color} from "../../../../core/types/color";
import React from "react";

export enum LegalDetailsActionType {
    add = 'add',
    create = 'create',
    edit = 'edit',
    delete = 'delete'
}

export const legalDetailsActionsMap: ActionButtonsData<LegalDetailsActionType> = {
    [LegalDetailsActionType.add]: {
        icon: <Icon component={IconPlus}/>,
        color: Color.Green,
        name: 'Добавить'
    },
    [LegalDetailsActionType.create]: {
        icon: <Icon component={IconPlus}/>,
        color: Color.Green,
        name: 'Создать'
    },
    [LegalDetailsActionType.edit]: BasicActionsData.editAction,
    [LegalDetailsActionType.delete]: BasicActionsData.deleteAction
};

export const legalCountriesCodes = [
    LegalCountryCodeEnum.RU,
    LegalCountryCodeEnum.KZ,
    LegalCountryCodeEnum.BY,
    LegalCountryCodeEnum.OTHER
];

export const legalCountriesNamesMap: Record<LegalCountryCodeEnum, string> = {
    [LegalCountryCodeEnum.RU]: 'Россия',
    [LegalCountryCodeEnum.KZ]: 'Казахстан',
    [LegalCountryCodeEnum.BY]: 'Белоруссия',
    [LegalCountryCodeEnum.OTHER]: 'Другая',
};

export const legalSubjectNamesMap: Record<LegalSubjectTypeCodeEnum, LocalizationEnum> = {
    [LegalSubjectTypeCodeEnum.RULEGALENTITY]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__LEGAL_ENTITY,
    [LegalSubjectTypeCodeEnum.RUINDIVIDUALENTREPRENEUR]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__INDIVIDUAL_ENTREPRENEUR,
    [LegalSubjectTypeCodeEnum.RUNATURALPERSON]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__NATURAL_PERSON,
    [LegalSubjectTypeCodeEnum.KZLEGALENTITY]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__LEGAL_ENTITY,
    [LegalSubjectTypeCodeEnum.KZNATURALPERSON]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__NATURAL_PERSON,
    [LegalSubjectTypeCodeEnum.KZINDIVIDUALENTREPRENEUR]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__INDIVIDUAL_ENTREPRENEUR,
    [LegalSubjectTypeCodeEnum.BYLEGALENTITY]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__LEGAL_ENTITY,
    [LegalSubjectTypeCodeEnum.BYNATURALPERSON]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__NATURAL_PERSON,
    [LegalSubjectTypeCodeEnum.BYINDIVIDUALENTREPRENEUR]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__INDIVIDUAL_ENTREPRENEUR,
    [LegalSubjectTypeCodeEnum.INTERNATIONALSWIFTLEGALENTITY]: 'Юридическое лицо' as LocalizationEnum,
    [LegalSubjectTypeCodeEnum.INTERNATIONALSWIFTNATURALPERSON]: 'Физическое лицо' as LocalizationEnum,
};

export const legalDetailsFieldNamesMap: Record<LegalDetailsFieldTypeCodeEnum, string> = {
    [LegalDetailsFieldTypeCodeEnum.PHYSICALADDRESS]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__PHYSICAL_ADDRESS,
    [LegalDetailsFieldTypeCodeEnum.BANKNAME]: 'Банк',
    [LegalDetailsFieldTypeCodeEnum.BIC]: 'БИК',
    [LegalDetailsFieldTypeCodeEnum.CORRESPONDENTACCOUNTNUMBER]: 'Корреспондентский счет',
    [LegalDetailsFieldTypeCodeEnum.PAYMENTACCOUNTNUMBER]: 'Расчетный счет',
    [LegalDetailsFieldTypeCodeEnum.INN]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__INN,
    [LegalDetailsFieldTypeCodeEnum.KPP]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__KPP,
    [LegalDetailsFieldTypeCodeEnum.OGRN]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__OGRN,
    [LegalDetailsFieldTypeCodeEnum.LEGALADDRESS]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__LEGAL_ADDRESS,
    [LegalDetailsFieldTypeCodeEnum.REPRESENTEDBYDOCUMENTSTRING]: 'Представитель в графе "В лице..." (родительный падеж)',
    [LegalDetailsFieldTypeCodeEnum.BIRTHDATE]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__BIRTH_DATE,
    [LegalDetailsFieldTypeCodeEnum.PASSPORTNUMBER]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__PASSPORT_NUMBER,
    [LegalDetailsFieldTypeCodeEnum.SEX]: LocalizationEnum.ASPECT__FIELDS__COMMON__GENDER,
    [LegalDetailsFieldTypeCodeEnum.INNIP]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__INNIP,
    [LegalDetailsFieldTypeCodeEnum.OGRNIP]: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__OGRNIP,
    [LegalDetailsFieldTypeCodeEnum.IBAN]: 'IBAN',
    [LegalDetailsFieldTypeCodeEnum.SWIFTBIC]: 'SWIFT BIC',
    [LegalDetailsFieldTypeCodeEnum.BYUNP]: 'УНП',
    [LegalDetailsFieldTypeCodeEnum.BYIDENTIFICATIONPASSPORTNUMBER]: 'Идентификационный номер',
    [LegalDetailsFieldTypeCodeEnum.BYPASSPORTBOOKNUMBER]: 'Номер бланка паспорта',
    [LegalDetailsFieldTypeCodeEnum.KZIIN]: 'Индивидуальный идентификационный номер',
    [LegalDetailsFieldTypeCodeEnum.KZBIN]: 'Бизнес-идентификационный номер',
    [LegalDetailsFieldTypeCodeEnum.KZPASSPORTNUMBER]: 'Номер паспорта',
    [LegalDetailsFieldTypeCodeEnum.KZCERTIFICATENUMBER]: 'Номер удостоверения',
    [LegalDetailsFieldTypeCodeEnum.CITIZENSHIP]: 'Гражданство',
    [LegalDetailsFieldTypeCodeEnum.IDENTITYDOCUMENTNUMBER]: 'Номер документа уд. личность',
    [LegalDetailsFieldTypeCodeEnum.IDENTITYDOCUMENTEXPIRATIONDATE]: 'Срок действия документа, уд. личность',
    [LegalDetailsFieldTypeCodeEnum.IDENTITYDOCUMENTISSUERNAME]: 'Выдан',
    [LegalDetailsFieldTypeCodeEnum.IDENTITYDOCUMENTISSUEDATE]: 'Дата выдачи',
    [LegalDetailsFieldTypeCodeEnum.PASSPORTISSUERCODE]: 'Код подразделения',
    [LegalDetailsFieldTypeCodeEnum.OKOPF]: 'ОКОПФ',
    [LegalDetailsFieldTypeCodeEnum.ACTSONTHEBASISSTRING]: 'Действует на основании ... (родительный падеж)',
};
