import { ColumnTypes, TableColumn } from '../../../../../components/grid/Table';
import { GRID_ACTIONS_COLUMN_WIDTH } from '../../../../../config/constants';
import { KitRecord, NomenclatureEntityTypeCodeEnum } from '../../../../../server/api';
import { LocalizationEnum, localize } from '../../../../../localization';
import { SystemIcon, SystemIconType } from '../../../../../components/v2/systemIcon/systemIcon';
import { GridColumnCreator } from '../../../../../components/grid/gridColumnCreator';
import { TableCellAvatarRenderer } from '../../../../../components/grid/renderers/tableCellAvatarRenderer';
import { PricingSchemePricePopover } from '../../../settings/pricingSchemes/pricingSchemePricePopover';
import { Icon } from 'antd';
import { PricingSchemeMaps } from '../../../../../core/utils/pricingSchemeUtils';
import { tableCellMoneyRenderer } from '../../../../../components/grid/renderers/tableCellMoneyRenderer';
import DashboardDate from '../../../../../components/dashboardDate/dashboardDate';
import { isDefined } from '../../../../../shared/util/utils';

// GRID COLUMNS
export const kitsListColumns: TableColumn<KitRecord>[] = [
    GridColumnCreator.createProblemSeverityColumn(),
    GridColumnCreator.createImageColumn({
        dataIndex: 'mainImage',
        type: ColumnTypes.CustomRender,
        render: (value, rowData: KitRecord) => {
            return <TableCellAvatarRenderer value={value} rowData={rowData} opts={{ highlight: true, isKit: true }} />;
        },
    }),
    GridColumnCreator.createShortNameColumn<KitRecord>(
        'kit',
        (row) => ({
            baId: row.businessAccountId,
            id: row.id,
            name: row.shortName,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NAME),
            dataIndex: 'shortName',
        }
    ),
    GridColumnCreator.createExternalCodeColumn<KitRecord>(NomenclatureEntityTypeCodeEnum.KIT, (row) => ({
        code: row.externalCode,
        nomenclatureId: row.id,
        baId: row.businessAccountId,
    })),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__STATUS),
        dataIndex: 'stateCode',
        type: ColumnTypes.Status,
        minWidth: 120,
        //maxWidth: MAX_WIDTH(100),
        resizable: false,
    },
    {
        title: 'Инвентарь',
        dataIndex: 'stockKitCount',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        resizable: false,
        render: (value: number | null | undefined) => {
            const className = isDefined(value) ? (value === 0 ? 'rr-zero-value' : undefined) : 'rr-problem-value';
            const v = isDefined(value) ? value : '?';
            return <span className={className}>{v}</span>;
        },
    },
    {
        title: 'На складе',
        dataIndex: 'warehouseKitCount',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        resizable: false,
        sortable: true,
        defaultHidden: false,
        render: (value: number | null | undefined) => {
            const className = isDefined(value) ? (value === 0 ? 'rr-zero-value' : undefined) : 'rr-problem-value';
            const v = isDefined(value) ? value : '?';
            return <span className={className}>{v}</span>;
        },
    },
    {
        title: localize(LocalizationEnum.PAGE__KITS__DESCRIPTION_TAB__NUMBER_OF_COMPONENTS),
        dataIndex: 'membersCount',
        type: ColumnTypes.String,
        minWidth: 140,
        //maxWidth: MAX_WIDTH(140),
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.PAGE__OPERATIONS__LIST__GRID__COLUMN__INSTANCE_COUNT),
        dataIndex: 'instancesCount',
        type: ColumnTypes.String,
        minWidth: 140,
        //maxWidth: MAX_WIDTH(140),
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__DISCOUNT),
        dataIndex: 'totalDiscount',
        type: ColumnTypes.Percents,
        minWidth: 100,
        //maxWidth: MAX_WIDTH(100),
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__PRICE_PER_SHIFT),
        dataIndex: 'finalTotalPrice',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        resizable: false,
        render: (value: any, rowData: any) => {
            const { pricingScheme } = rowData;
            return (
                <>
                    {pricingScheme ? (
                        <PricingSchemePricePopover
                            data={{
                                id: pricingScheme.id,
                                typeCode: pricingScheme.typeCode,
                                label: pricingScheme.name || '',
                                price: rowData.finalTotalPrice,
                                steps: pricingScheme.steps || [],
                                roundingDirection: pricingScheme.roundingDirection,
                                roundingLevel: pricingScheme.roundingLevel,
                            }}
                        >
                            <Icon
                                component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[pricingScheme.typeCode]}
                                className={'rr-grid-pricingScheme-icon'}
                            />
                        </PricingSchemePricePopover>
                    ) : null}
                    {tableCellMoneyRenderer(value)}
                </>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIVITY),
        dataIndex: 'lastActivityDate',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        resizable: false,
        render: (value: Date) => {
            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    {
        title: <SystemIcon type={SystemIconType.actions} style={{ display: 'block' }} />,
        type: ColumnTypes.Actions,
        id: 'actions',
        minWidth: GRID_ACTIONS_COLUMN_WIDTH,
        width: GRID_ACTIONS_COLUMN_WIDTH,
        maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
    },
];
