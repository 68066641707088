import { useCallback, useEffect, useRef, useState } from 'react';
import './InlineTextarea.less';
import FormValue from '../../formValue/FormValue';
import classNames from 'classnames';

interface Props {
    value: string | null | undefined;
    emptyValueText?: string;
    emptyValueClassName?: string;
    onBeforeBlur?: (value: string | undefined) => Promise<boolean> | void;
    className?: string;
    isError?: boolean;
    maxLength?: number;
}

const InlineTextarea = ({ value, emptyValueText, emptyValueClassName, className, onBeforeBlur, isError, maxLength }: Props) => {
    const [newValue, setNewValue] = useState(value ?? '');
    const [focused, setFocused] = useState(false);
    const areaRef = useRef<HTMLTextAreaElement>(null);
    const [pressedKey, setPressedKey] = useState<'Escape' | 'Enter' | undefined>();

    const keyDownHandler = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            setPressedKey('Escape');
        } else if (e.key === 'Enter') {
            setPressedKey('Enter');
        } else {
            setPressedKey(undefined);
        }
    }, []);

    const blurHandler = useCallback(async () => {
        if (pressedKey !== 'Escape' && newValue !== value && !(newValue === '' && !value)) {
            await onBeforeBlur?.(newValue.length ? newValue : undefined);
        }
        setFocused(false);
    }, [onBeforeBlur, pressedKey, newValue, value]);

    useEffect(() => {
        if (focused) {
            areaRef.current?.focus();
            areaRef.current?.select();
            document.addEventListener('keydown', keyDownHandler);
        } else {
            document.removeEventListener('keydown', keyDownHandler);
        }
    }, [focused, keyDownHandler]);

    useEffect(() => {
        if (pressedKey === 'Escape') {
            setFocused(false);
            setNewValue(value ?? '');
        } else if (pressedKey === 'Enter') {
            blurHandler();
            setPressedKey(undefined);
        }
    }, [blurHandler, pressedKey, value]);

    useEffect(() => {
        setNewValue(value ?? '');
    }, [value]);

    useEffect(() => {
        if (isError) {
            setNewValue(value ?? '');
        }
    }, [value, isError]);

    return focused ? (
        <div className={classNames(className)}>
            <textarea
                ref={areaRef}
                className="rr-inline-string-input ant-input ant-input-focused"
                value={newValue}
                placeholder={emptyValueText}
                onChange={(e) => setNewValue(e.target.value)}
                onBlur={blurHandler}
                maxLength={maxLength}
            />
        </div>
    ) : (
        <FormValue
            onClick={() => {
                setFocused(true);
            }}
            className={classNames('rr-inline-editable', !value && emptyValueClassName, value && className)}
            value={pressedKey === 'Escape' ? (value?.length ? value : emptyValueText) : newValue.length ? newValue : emptyValueText}
        />
    );
};
export default InlineTextarea;
