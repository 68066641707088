import { Icon } from 'antd';
import { IconBoxSolid } from '../icons';
import MoneyValue from '../moneyValue/MoneyValue';
import { useAppSelector } from '../../store/hooks';
import { canViewFinancialDataSelector } from '../../shared/reducers/permissions.reducer';
import './PledgeTableValue.less';

interface Props {
    value: number | undefined;
    description: string | undefined;
    highlighted?: boolean;
}

const PledgeTableValue = ({ value, description, highlighted }: Props) => {
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    return (
        <div style={{ display: 'flex', alignItems: 'stretch', gap: 4, justifyContent: canViewFinancialData ? 'flex-end' : 'center' }}>
            {description && description.length > 0 && (
                <Icon
                    style={{
                        fontSize: 20,
                        color: highlighted ? undefined : '#343f9a',
                    }}
                    className={highlighted ? 'rr-grid-pledge-value-highlighted-icon' : undefined}
                    component={IconBoxSolid}
                />
            )}
            {canViewFinancialData && <MoneyValue value={value} muted={!value} highlighted={highlighted && !!value} />}
        </div>
    );
};
export default PledgeTableValue;
