import React, { FC, memo, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { calendarPageUrlRoute } from '../../../../calendar/utils/tabs';
import { Avatar } from '../../../../../../components/avatar/avatar';
import { Button, Card, Form, Icon } from 'antd';
import { IconBoxesSolid, IconClockO, IconClose, IconHandStopO } from '../../../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { Spin } from '../../../../../../components';
import { DashboardList } from '../../dashboardsList';
import { useAppSelector } from '../../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../../shared/reducers/system.reducer';
import { useListDashboardQuery } from '../../../api/rent/dashboardApi';
import { useIntl } from 'react-intl';
import { getPathFromState } from '../../../../../../shared/util/utils';
import { getGridStorageData, setGridStorageDataFilters } from '../../../../../../components/grid/utils';
import { DashboardData } from '../../../types/DashboardData';
import { dashboardRentColumnLeft } from '../../../columns/dashboardColumns';
import { canViewFinancialDataSelector } from '../../../../../../shared/reducers/permissions.reducer';
import { PeriodSelect } from '../../../components/PeriodSelect/PeriodSelect';
import { getStylesForBtnError } from './utils/getStylesForBtnError';
import { useDashboardGridState } from '../../../hooks/useDashboardGridState';
import { getStylesForBtnTime } from './utils/getStylesForBtnTime';
import { DashboardApiUtils } from '../../../api/rent/dashboardApiUtils';
import { ServerUtils } from '../../../../../../core/utils/serverUtils';
import { CalendarPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import { GroupedRentElementRecord } from '../../../../../../server';

interface DashboardLeftListProps {
    dashboardData: DashboardData;
}

const dashBoardRentLeftListGridName: string = 'dashboard-elements-booked';

export const DashboardRentLeftList: FC<DashboardLeftListProps> = memo((props) => {
    const { dashboardData } = props;
    const {
        currentDelayedBookedElementsCount,
        currentShortagedBookedElementsCount,
        currentBookedElementsCount: elementsCount,
    } = dashboardData;
    const intl = useIntl();
    const location = useLocation();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const showFinancialData = useAppSelector(canViewFinancialDataSelector);
    const gridData = getGridStorageData(dashBoardRentLeftListGridName);
    const [isErrorButtonSelected, setIsErrorButtonSelected] = useState(gridData.filters?.isBtnError === true);
    const [isTimeButtonSelected, setTimeButtonSelected] = useState(gridData.filters?.isBtnTime === true);

    const [{ page, pageSize, period, sortBy, sortOrder }, { onPageChanged, onPeriodChange, onSetPeriod, onSortedChange }] =
        useDashboardGridState(dashBoardRentLeftListGridName);

    const requestFilters = useMemo(
        () => [
            ...DashboardApiUtils.getCommonFilters(period),
            ...ServerUtils.createRequestFilters([
                ['stateCode', 'EQ', 'BOOKED'],
                isTimeButtonSelected && currentDelayedBookedElementsCount && ['problemsAndWarnings.anyDelay', 'EQ', true],
                isErrorButtonSelected && currentShortagedBookedElementsCount && ['problemsAndWarnings.anyShortage', 'EQ', true],
            ]),
        ],
        [currentDelayedBookedElementsCount, currentShortagedBookedElementsCount, isErrorButtonSelected, isTimeButtonSelected, period]
    );

    const { data: dashboardListData, isLoading } = useListDashboardQuery(
        {
            intl,
            params: {
                pageSize,
                sortOrder,
                sortBy,
                page,
                period,
            },
            isSubrent: false,
            side: 'left',
            filters: requestFilters,
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const onTimeError = () => {
        setGridStorageDataFilters(dashBoardRentLeftListGridName, { isBtnError: !isErrorButtonSelected });
        setIsErrorButtonSelected((prev) => !prev);
        onPeriodChange();
    };

    const onTimeFilter = () => {
        setGridStorageDataFilters(dashBoardRentLeftListGridName, { isBtnTime: !isTimeButtonSelected });
        setTimeButtonSelected((prev) => !prev);
        onPeriodChange();
    };

    const stylesForBtnTime = getStylesForBtnTime(isTimeButtonSelected, currentDelayedBookedElementsCount);
    const stylesForBtnError = getStylesForBtnError(isErrorButtonSelected, currentShortagedBookedElementsCount);

    const excludeColumns: (keyof GroupedRentElementRecord)[] | undefined = showFinancialData ? undefined : ['finalTotalSum'];

    return (
        <Spin spinning={isLoading}>
            <div className={`dashboard-grid-header ${period ? 'dashboard-header-input-select' : 'dashboard-header-input'}`}>
                <Link
                    to={getPathFromState(`/${businessAccountId}/${calendarPageUrlRoute}`, '', {
                        tab: CalendarPageTabsEnum.ELEMENTS,
                        typeCode: 'BOOKED',
                        group: 'startDate',
                        capture: 'all',
                    })}
                >
                    <div className="dashboard-header-avatar-button dashboard-header-avatar-button-left">
                        <Avatar
                            className={'rr-avatar-small-with-shadow rr-operation-avatar-RENT'}
                            size={44}
                            style={{ boxShadow: '0px 0px 0px 5px rgba(252, 140, 47, 0.15)', marginRight: '20px' }}
                        >
                            <Icon component={IconHandStopO} />
                        </Avatar>

                        <div>
                            <div>
                                <strong style={{ fontSize: '20px' }}>{localize(LocalizationEnum.PAGE__DASHBOARD__GIVE_AWAYS)}</strong>
                            </div>
                            <div>
                                {elementsCount} {localize(LocalizationEnum.ASPECT__GLOBAL__TOTAL_NUMBER)}
                            </div>
                        </div>
                    </div>
                </Link>

                <div style={{ display: 'flex', marginTop: '12px' }} className="dashboard-filters-buttons">
                    <Button
                        type="danger"
                        style={stylesForBtnTime}
                        onClick={onTimeFilter}
                        disabled={currentDelayedBookedElementsCount === 0 && !isTimeButtonSelected}
                    >
                        <Icon component={IconClockO} style={{ fontSize: '20px' }} />
                        <span style={{ fontWeight: 600 }}>{currentDelayedBookedElementsCount}</span>
                        {isTimeButtonSelected ? <Icon component={IconClose} /> : null}
                    </Button>
                    <Button
                        type="danger"
                        style={stylesForBtnError}
                        onClick={onTimeError}
                        disabled={currentShortagedBookedElementsCount === 0 && !isErrorButtonSelected}
                    >
                        <Icon component={IconBoxesSolid} style={{ fontSize: '20px' }} />
                        <span style={{ fontWeight: 600 }}>{currentShortagedBookedElementsCount}</span>
                        {isErrorButtonSelected ? <Icon component={IconClose} /> : null}
                    </Button>
                </div>

                <Form.Item style={{ marginRight: '30px', marginTop: '12px' }}>
                    <PeriodSelect period={period} onSetPeriod={onSetPeriod} />
                </Form.Item>
            </div>

            <Card bordered={false} className="grid-dashboard grid-dashboar-left">
                <DashboardList
                    businessAccountId={businessAccountId}
                    columns={dashboardRentColumnLeft}
                    currentPage={page}
                    data={dashboardListData?.records}
                    defaultSortDesc={sortOrder}
                    defaultSorted={sortBy}
                    entities={dashboardListData?.records}
                    excludeColumns={excludeColumns}
                    filtered={dashboardListData?.listAttributes.filteredCount}
                    gridName={dashBoardRentLeftListGridName}
                    location={location}
                    onPageChanged={onPageChanged}
                    onSortedChange={onSortedChange}
                    parentType={'operation'}
                />
            </Card>
        </Spin>
    );
});
