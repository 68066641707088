import React, { FC, ReactNode } from 'react';
import { AccessibilityMapCalendarGroup } from '../../types/accessibilityMapCalendarGroupType';
import { Link } from 'react-router-dom';
import { linkToCalendar } from '../../../../../../../shared/util/createLinkPath';
import { CalendarPageTabsEnum } from '../../../../../../../shared/constants/tabEnums';
import { Icon } from 'antd';
import { IconAngleDown, IconAngleUp, IconDollyFlatbedSolid } from '../../../../../../../components/icons';
import './summaryScheduleCalendarGroupRenderer.less';
import classNames from 'classnames';

interface AccessibilityMapCalendarRendererProps {
    group: AccessibilityMapCalendarGroup;
    onCollapse: (collapse: boolean) => void;
    screenLeft: number;
    screenRight: number;
    width: number;
}

export const SummaryScheduleCalendarGroupRenderer: FC<AccessibilityMapCalendarRendererProps> = (props) => {
    const { group, onCollapse } = props;
    let mainLink: string | undefined;
    let mainName: string | undefined = '';
    let variantName: string | undefined = '';

    if (!group.extraName) {
        if (group.kitId) mainName = group.kitName;
        else if (group.instanceId) mainName = group.instanceName;
        else if (group.productId) {
            mainName = group.productName;
            if (group.variantName) variantName = group.variantName;
        }
    } else {
        mainName = group.extraName;
    }

    mainLink =
        group.kitId || group.productId || group.instanceId || group.variantId
            ? linkToCalendar(
                  {
                      kitId: group.kitId,
                      productId: group.productId,
                      instanceId: group.instanceId,
                      variantId: group.variantId,
                      screenLeft: props.screenLeft,
                      screenRight: props.screenRight,
                  },
                  CalendarPageTabsEnum.ACCESSIBILITY_MAP
              )
            : undefined;

    let icon: ReactNode;

    if (group.kitId) {
        icon = <Icon component={IconDollyFlatbedSolid} />;
    }

    return (
        <div className={'summaryScheduleCalendar-sidebar-group'}>
            <div
                className={classNames('summaryScheduleCalendar-sidebar-group-icon', {
                    'summaryScheduleCalendar-sidebar-group-icon-disabled': group.collapseDisable,
                })}
            >
                {group.collapsible && (
                    <Icon
                        component={!group.collapsed ? IconAngleUp : IconAngleDown}
                        style={{ fontSize: 20 }}
                        onClick={
                            !group.collapseDisable
                                ? (e) => {
                                      onCollapse(!group.collapsed);
                                  }
                                : undefined
                        }
                    />
                )}
            </div>
            <div className={'summaryScheduleCalendar-sidebar-group-title'} style={{ flexGrow: 1, width: props.width }}>
                <div
                    className={
                        group.instanceId || !mainLink
                            ? 'summaryScheduleCalendar-sidebar-group-instance'
                            : 'summaryScheduleCalendar-sidebar-group-nomenclature'
                    }
                >
                    {mainLink ? (
                        <Link title={mainName} to={mainLink}>
                            {icon}
                            {mainName}
                        </Link>
                    ) : (
                        <>
                            {icon}
                            {mainName}
                        </>
                    )}
                </div>
                {group.variantId && (
                    <div className={'summaryScheduleCalendar-sidebar-group-variant'}>
                        {mainLink ? (
                            <Link title={variantName} to={mainLink}>
                                {variantName}
                            </Link>
                        ) : (
                            variantName
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
