import React, { FC } from 'react';
import { RegistrationStepTwoSwitchButtons } from './SwitchButtons/RegistrationStepTwoSwitchButtons';
import { Button } from 'antd';
import { LocalizationEnum, localize } from '../../../../../localization';
import { BusinessAccountModuleCodeEnum } from '../../../../../server';

export type StepTwoRegistrationAnswers = {
    [BusinessAccountModuleCodeEnum.LOGISTICS]: boolean;
    [BusinessAccountModuleCodeEnum.CREW]: boolean;
    [BusinessAccountModuleCodeEnum.SUBRENT]: boolean;
    [BusinessAccountModuleCodeEnum.OFFERS]: boolean;
    [BusinessAccountModuleCodeEnum.PLEDGES]: boolean;
};

interface RegistrationStepTwoProps {
    stepTwoAnswers: StepTwoRegistrationAnswers;
    setStepTwoAnswers: (value: StepTwoRegistrationAnswers) => void;
    onChangeStep: () => void;
}

export const RegistrationStepTwo: FC<RegistrationStepTwoProps> = (props) => {
    const { stepTwoAnswers, setStepTwoAnswers, onChangeStep } = props;

    const updateAnswers = (key: keyof StepTwoRegistrationAnswers) => (value: boolean) => {
        setStepTwoAnswers({
            ...stepTwoAnswers,
            [key]: value,
        });
    };

    return (
        <div
            className="form-wrap"
            style={{
                padding: '36px 112px 56px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <h1
                style={{
                    color: '#5087e0',
                    fontWeight: 400,
                    textAlign: 'center',
                }}
            >
                Расскажите ещё немного о вашей компании:
            </h1>
            <div
                style={{
                    marginTop: 0,
                    marginBottom: 48,
                }}
            >
                <RegistrationStepTwoSwitchButtons
                    title={'Мы сопровождаем оборудование на локации заказчика'}
                    value={stepTwoAnswers[BusinessAccountModuleCodeEnum.LOGISTICS]}
                    setValue={updateAnswers(BusinessAccountModuleCodeEnum.LOGISTICS)}
                />
                <RegistrationStepTwoSwitchButtons
                    title={'Мы хотим планировать работу наших сотрудников на проектах заказчиков'}
                    value={stepTwoAnswers[BusinessAccountModuleCodeEnum.CREW]}
                    setValue={updateAnswers(BusinessAccountModuleCodeEnum.CREW)}
                />
                <RegistrationStepTwoSwitchButtons
                    title={'Мы берем оборудование в субаренду у поставщиков'}
                    value={stepTwoAnswers[BusinessAccountModuleCodeEnum.SUBRENT]}
                    setValue={updateAnswers(BusinessAccountModuleCodeEnum.SUBRENT)}
                />
                <RegistrationStepTwoSwitchButtons
                    title={'Мы ведем учет по нескольким сметам: предварительным, коммерческим и фактическим'}
                    value={stepTwoAnswers[BusinessAccountModuleCodeEnum.OFFERS]}
                    setValue={updateAnswers(BusinessAccountModuleCodeEnum.OFFERS)}
                />
                <RegistrationStepTwoSwitchButtons
                    title={'Мы принимаем денежные или вещевые залоги от клиентов'}
                    value={stepTwoAnswers[BusinessAccountModuleCodeEnum.PLEDGES]}
                    setValue={updateAnswers(BusinessAccountModuleCodeEnum.PLEDGES)}
                />
            </div>
            <Button type="primary" htmlType="submit" className={`btn-green btn-registration`} onClick={() => onChangeStep()}>
                <span>{localize(LocalizationEnum.PAGE__COMPANY_REGISTRATION__BUTTON__CONTINUE)}</span>
            </Button>
        </div>
    );
};
