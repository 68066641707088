import React from 'react';
import { Empty, Icon } from 'antd';
import { IconSitemap } from '../icons';
import { Link } from 'react-router-dom';
import './emptyCategories.less';
import { LocalizationEnum, localize } from '../../localization';
import { Avatar } from '../avatar/avatar';
import { getCategoriesUrlPath } from '../../modules/main/categories/categories-block';
import { getBusinessAccountId } from '../../..';

export interface EmptyCategoriesProps {
    categoriesType: 'PRODUCT' | 'RENTER';
    linkAdd?: boolean;
}

const CategoriesMap: Record<EmptyCategoriesProps['categoriesType'], 'inventory' | 'crm'> = {
    PRODUCT: 'inventory',
    RENTER: 'crm',
};

const Description = (props: EmptyCategoriesProps) => (
    <>
        <div>
            {props.categoriesType === 'PRODUCT'
                ? localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NO_PRODUCT_CATEGORIES_EXIST)
                : localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NO_RENTER_CATEGORIES_EXIST)}
        </div>
        <div>
            <Link to={getCategoriesUrlPath(getBusinessAccountId(), CategoriesMap[props.categoriesType])}>
                {localize(LocalizationEnum.ASPECT__CATEGORY_TREE_FILTER__CREATE_NEW_CATEGORIES_LINK)}
            </Link>
        </div>
    </>
);

export const EmptyCategories = (props: EmptyCategoriesProps) => (
    <Empty
        className={'rr-empty-categories'}
        image={
            <Avatar className={'rr-avatar-medium'}>
                <Icon component={IconSitemap} />
            </Avatar>
        }
        description={<Description {...props} />}
    />
);
