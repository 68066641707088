import { ColumnTypes, TableColumn } from '../../../../../../components/grid/Table';
import { EntityLifecycleEventRecord, EntityLifecycleEventTypeCode, EntityTypeCodeEnum } from '../../../../../../server';
import { GridColumnCreator } from '../../../../../../components/grid/gridColumnCreator';
import { LocalizationEnum, localize } from '../../../../../../localization';
import React from 'react';
import { entityTypeCodeMap } from '../../../../../../localization/utils/entityTypeCodeMap';
import { EventTypeItem } from '../components/EventTypeItem/EventTypeItem';
import { getBusinessAccountId } from '../../../../../../..';
import { Link } from 'react-router-dom';

const smallSizes = GridColumnCreator.getColumnSizes(20);
const mediumSizes = GridColumnCreator.getColumnSizes(60);

const getEntityLink = (id: string | number | undefined, type: EntityTypeCodeEnum | undefined): string | null => {
    if (!id) return null;

    const businessAccountId = getBusinessAccountId();

    if (type === EntityTypeCodeEnum.RENTER) return `/${businessAccountId}/crm/counterparties/${id}?tab=description`;
    if (type === EntityTypeCodeEnum.PROJECT) return `/${businessAccountId}/projects/production/${id}?tab=description`;
    if (type === EntityTypeCodeEnum.TEMPLATE) return `/${businessAccountId}/projects/templates/${id}?tab=description`;
    if (type === EntityTypeCodeEnum.SUBRENT) return `/${businessAccountId}/subrent/shippings/${id}?tab=description`;
    if (type === EntityTypeCodeEnum.OPERATION) return `/${businessAccountId}/history/operations/${id}?tab=description`;
    if (type === EntityTypeCodeEnum.KIT) return `/${businessAccountId}/inventory/kits/${id}?tab=description`;
    if (type === EntityTypeCodeEnum.PRODUCT) return `/${businessAccountId}/inventory/products/${id}?tab=description`;
    if (type === EntityTypeCodeEnum.PROFESSION) return `/${businessAccountId}/crew/professions?id=${id}&view=true`;
    if (type === EntityTypeCodeEnum.CREWMEMBER) return `/${businessAccountId}/crew/crewMembers/${id}?tab=description`;
    if (type === EntityTypeCodeEnum.PAYMENT) return `/${businessAccountId}/history/payments?id=${id}&view=true`;
    if (type === EntityTypeCodeEnum.EXPENSE) return `/${businessAccountId}/history/expenses?id=${id}&view=true`;
    if (type === EntityTypeCodeEnum.CONTACT) return `/${businessAccountId}/crm/contacts?id=${id}&view=true`;
    if (type === EntityTypeCodeEnum.LOCATION) return `/${businessAccountId}/logistics/locations?id=${id}&view=true`;
    if (type === EntityTypeCodeEnum.VEHICLE) return `/${businessAccountId}/logistics/vehicles?id=${id}&view=true`;
    if (type === EntityTypeCodeEnum.TRANSPORTATION) return `/${businessAccountId}/logistics/transportations?id=${id}&view=true`;

    return null;
};

export const lifecycleEventsColumns: TableColumn<EntityLifecycleEventRecord>[] = [
    GridColumnCreator.createShortNameColumn<EntityLifecycleEventRecord>(
        'lifecycle-event',
        (row) => ({
            baId: row.businessAccountId,
            name: row.entityName,
            id: row.entityId,
        }),
        {
            title: 'Наименование сущности',
            dataIndex: 'entityName',
            dontHide: true,
            sortable: false,
            minWidth: 300,
            width: mediumSizes.width,
            maxWidth: mediumSizes.max,
            render: (value, rowData: EntityLifecycleEventRecord) => {
                const link = rowData.deletedEntity ? null : getEntityLink(rowData.entityId, rowData.entityTypeCode);
                const style: React.CSSProperties = {
                    color: rowData.deletedEntity ? '#aaaaaa' : undefined,
                    textDecoration: rowData.deletedEntity ? 'line-through' : undefined,
                };
                const classes =
                    'rr-grid-shortName-cell rr-grid-text-cell-left' +
                    (!link && !rowData.deletedEntity ? ' rr-grid-shortName-cell-gray' : '');

                return link ? (
                    <Link to={link} className={classes + ' rr-link'} style={style}>
                        {value}
                    </Link>
                ) : (
                    <div className={classes} style={style}>
                        {value}
                    </div>
                );
            },
        }
    ),
    {
        title: 'Тип сущности',
        type: ColumnTypes.CustomRender,
        dataIndex: 'entityTypeCode',
        minWidth: 300,
        width: mediumSizes.width,
        maxWidth: mediumSizes.max,
        resizable: true,
        sortable: true,
        render: (code: EntityTypeCodeEnum) => {
            return <span>{localize(entityTypeCodeMap[code])}</span>;
        },
    },
    {
        title: 'Тип действия',
        type: ColumnTypes.CustomRender,
        dataIndex: 'entityLifecycleEventTypeCode',
        minWidth: 300,
        width: mediumSizes.width,
        maxWidth: mediumSizes.max,
        resizable: true,
        sortable: true,
        className: 'rr-grid-text-cell-left',
        render: (code: EntityLifecycleEventTypeCode) => {
            return <EventTypeItem typeCode={code} style={{ justifyContent: 'start' }} />;
        },
    },
    GridColumnCreator.createLastUpdateDateColumn<EntityLifecycleEventRecord>({
        title: 'Дата события',
        dataIndex: 'activityDate',
        minWidth: smallSizes.min,
    }),
    GridColumnCreator.createUserColumn<EntityLifecycleEventRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.activityAuthorId,
            name: row.activityAuthorShortName,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__AUTHOR),
            dataIndex: 'activityAuthorShortName',
            minWidth: 120,
            width: smallSizes.width,
            maxWidth: smallSizes.max,
        }
    ),
];
