import {ExpenseInfoRead, ProjectInfoRead} from '../../../../../server';
import { LocalizationEnum, localize } from '../../../../../localization';
import { ExpenseEditModal } from './expenseEditModal';
import React, {FunctionComponent, memo} from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { EntityGridEditModalWrapper } from '../../../../../components/page/entityGrid/types/modal';
import {EntityGridRootPath} from "../../../../../components/page/entityGrid/types/params";
import {ModalFormNEWProps} from "../../../../../components/modalForm/ModalFormNEW";

export type EditModalWrapperProps<EntityInfoRead extends object> = {
    entity: EntityInfoRead;
    parentProjectEntity?: ProjectInfoRead;
} & Partial<ModalFormNEWProps>;

type EditModalWrapper<EntityInfoRead extends object> = FunctionComponent<EditModalWrapperProps<EntityInfoRead>>;

export const ExpenseEditModalWrapper: EditModalWrapper<ExpenseInfoRead> = memo(
    ({ entity, parentProjectEntity, ...modalProps }) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    return (
        <ExpenseEditModal
            businessAccountId={businessAccountId}
            title={'Редактирование затраты'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
            expense={entity}
            parentProjectEntity={parentProjectEntity}
        />
    );
});
