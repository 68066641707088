import {Checkbox, Icon} from "antd";
import {SimplePopover} from "../../../../../components/v2/simplePopover/simplePopover";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../../../store/hooks";
import {IconBell} from "../../../../../components/icons";
import {
    operationShiftCountWarningsEnabledSelector,
    setOperationShiftCountWarningsEnabled as setOperationShiftCountWarningsEnabled1
} from "../../../operationForm/reducers/operationForm.reducer";

import {setOperationShiftCountWarningsEnabled} from "../../../../../shared/reducers/userSettings/userSettings.reducer";

export const SetOperationShiftCountEnabledPopover = () => {

    const dispatch = useAppDispatch();
    const operationShiftCountWarningsEnabled = useAppSelector(operationShiftCountWarningsEnabledSelector);

    return (
        <SimplePopover content={
            <div style={{padding: 18, width: 300}}>
                <div className={'rr-calendar-popup-header'}>
                    Отображать предупреждения:
                </div>
                <div className={'popover-checkboxes'}>
                    <Checkbox
                        checked={operationShiftCountWarningsEnabled}
                        onChange={(e)=>{
                            dispatch(setOperationShiftCountWarningsEnabled(e.target.checked));
                            dispatch(setOperationShiftCountWarningsEnabled1(e.target.checked))
                        }}
                    >
                        Смены к оплате превышают календарные
                    </Checkbox>
                </div>
            </div>
        } trigger={'click'} placement={'bottomRight'} arrowPointAtCenter={true}>
            <Icon component={IconBell} style={{fontSize: 22, marginRight: 12}} />
        </SimplePopover>
    );
};
