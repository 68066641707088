import { FormFieldsGroup, FormItemType } from '../../../../../../../components/dynamicForm/DynamicForm';
import { FormUtils, TypedFormField, WrappedFormUtilsTyped } from '../../../../../../../core/utils/formUtils';
import { PricingSchemeRecord, ProfessionInfoRead, ProfessionStateCodeEnum } from '../../../../../../../server';
import { LocalizationEnum, localize } from '../../../../../../../localization';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import { getStoreState } from '../../../../../../../../index';
import { canUpdateFinancialData, canViewFinancialData } from '../../../../../../../shared/util/permissionUtils';
import { EntityRemoteSelect } from '../../../../../../../components/select/EntityRemoteSelect';
import { Icon, Select } from 'antd';
import { PricingSchemeMaps } from '../../../../../../../core/utils/pricingSchemeUtils';
import { createHiddenField } from '../../../../../../../components/modalForm/utils';
import debounce from 'lodash/debounce';
import { validateField } from '../../../../../../../shared/util/validateField';
import { professionsAllStateCodes } from '../../data/professionsData';
import { ProfessionStateBadge } from '../../components/professionStateBadge';
import {
    TimeDurationInput,
    TimeDurationInputProps
} from "../../../../../../../components/timeDurationInput/timeDurationInput";

interface FormValues extends ProfessionInfoRead {
    _shiftLengthInMinutes: number;
    isEdit: boolean;
}

export const ProfessionModalFields: FormFieldsGroup[] = FormUtils.typedFormFields<FormValues>([
    {
        fields: [
            createHiddenField('_shiftLengthInMinutes') as TypedFormField<FormValues>,
            createHiddenField('id') as TypedFormField<FormValues>,
            createHiddenField('isEdit') as TypedFormField<FormValues>,
            createHiddenField('fromTemplate') as TypedFormField<FormValues>,
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE,
                id: 'shortName',
                type: FormItemType.String,
                required: true,
                maxLength: 50,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form) => {
                    const ignoreCurrentId = form.getFieldValue('isEdit') ? form.getFieldValue('id') : undefined;

                    validateField(
                        'profession',
                        fieldName,
                        value,
                        undefined,
                        ignoreCurrentId,
                        localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__SHORT_NAME_ALREADY_USED),
                        cb
                    );
                }, 500),
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__FULL_NAME,
                id: 'fullName',
                type: FormItemType.String,
                required: false,
                maxLength: 255,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form) => {
                    const ignoreCurrentId = form.getFieldValue('isEdit') ? form.getFieldValue('id') : undefined;

                    validateField(
                        'profession',
                        fieldName,
                        value,
                        undefined,
                        ignoreCurrentId,
                        'Полное наименование набора уже использовано',
                        cb
                    );
                }, 500),
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: localize(LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER),
                id: 'stateCode',
                type: FormItemType.Select,
                defaultValue: ProfessionStateCodeEnum.ACTIVE,
                required: true,
                values: (form) => {
                    return professionsAllStateCodes.map((code) => ({
                        name: <ProfessionStateBadge type={code} dot={true} />,
                        value: code,
                    }));
                },
                disabled: (getFieldValue) => Boolean(getFieldValue('id')) || Boolean(getFieldValue('fromTemplate')),
            },
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__EXTERNAL_CODE,
                id: 'externalCode',
                type: FormItemType.String,
                required: true,
                maxLength: 30,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtilsTyped<FormValues>) => {
                    const ignoreCurrentId = form.getFieldValue('isEdit') ? (form.getFieldValue('id') as number) : undefined;

                    validateField(
                        'profession',
                        fieldName,
                        value,
                        undefined,
                        ignoreCurrentId,
                        localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__EXTERNAL_CODE_ALREADY_USED),
                        cb
                    );
                }, 500),
            },
            {
                label: LocalizationEnum.ASPECT__FORMS__MAIN_IMAGE,
                id: 'mainImage',
                type: FormItemType.UploadImage,
                infoMessage: localize(LocalizationEnum.ASPECT__FORMS__IMAGE_FORMAT_RESCTRICTIONS_STRING),
                imagesMaxCount: 1,
            },
        ],
    },
    {
        visible: (form) => {
            const p = getStoreState().permissions.permissions;
            return canViewFinancialData(p) && canUpdateFinancialData(p);
        },
        fields: [
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__PRICE_PER_SHIFT,
                id: 'pricePerShift',
                type: FormItemType.Money,
                step: 50,
                required: true,
                minLength: 0,
                defaultValue: 0,
                validationFunction: (fieldName: string, value: any, cb: Function, form) => {
                    cb(value >= 10000000000 ? localize(LocalizationEnum.PAGE__PRODUCTS__FORM__VALIDATION__PRICE_TOO_HIGH) : undefined);
                },
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
            },
            {
                label: 'Ценовая схема',
                placeholder: 'Выберите ценовую схему',
                id: 'pricingScheme',
                type: FormItemType.Component,
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => ({
                    operationName: 'listPricingSchemes',
                    nameField: 'name',
                    getEntityOperationName: 'getPricingSchemeById',
                    sortBy: 'lastUpdateDate',
                    sortOrder: 'DESC',
                    loadDataOnMount: true,
                    className: 'pricing-scheme-field',
                    renderer: ({ data }: { data: PricingSchemeRecord }, index, value) => {
                        return (
                            <Select.Option key={data['id']} className={'pricing-scheme-option'}>
                                <Icon
                                    component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[data.typeCode]}
                                    className={'price-scheme-icon'}
                                />
                                <span>{data['name']}</span>
                            </Select.Option>
                        );
                    },
                }),
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION,
                id: 'description',
                type: FormItemType.RichText,
                validationRules: [
                    {
                        max: 5000,
                        message: (
                            <>
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} 5000{' '}
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__INCLUDING_FORMATTING_TAGS)}
                            </>
                        ),
                    },
                ],
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
    {
        title: 'Длительность смены в минутах',
        fields: [
            {
                label: 'По компании',
                id: 'hasOwnShiftLength',
                type: FormItemType.Switch,
                defaultValue: true,
                labelCol: { span: 5 },
                wrapperCol: { span: 12 },
                onChange: (value: any, form) => {
                    if (value) {
                        form.setFieldsValue({
                            shiftLengthInMinutes: getStoreState().businessAccountPreferences.preferences?.shiftLengthInMinutes,
                            _shiftLengthInMinutes: form.getFieldValue('shiftLengthInMinutes') as number,
                        });
                    } else {
                        form.setFieldsValue({
                            shiftLengthInMinutes: form.getFieldValue('_shiftLengthInMinutes') as number,
                        });
                    }
                },
            },
            {
                id: 'shiftLengthInMinutes',
                type: FormItemType.Component,
                required: true,
                //minLength: 1,
                defaultValue: 0,
                component: TimeDurationInput,
                onChange: (value, form) => {
                    form.setFieldsValue({
                        _shiftLengthInMinutes: form.getFieldValue(value),
                    });
                },
                disabled: (getFieldValue) => getFieldValue('hasOwnShiftLength') === true,
                componentProps: ():TimeDurationInputProps=>({
                    min: 1
                })
            },
        ],
    },
]);
