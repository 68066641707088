import { FormFieldsGroup, FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import {
    BusinessAccountPreferencesInfoRead,
    CurrencyCodeEnum,
    DocumentTypeCodeEnum,
    ShiftCountRoundingTypeCodeEnum,
    TaxBaseTypeCodeEnum,
} from '../../../../server/api';
import currencyName from '../../../../shared/util/currencyName';
import { LocalizationEnum } from '../../../../localization';
import {getShiftCountRoundingTypeByCode} from '../../../../shared/util/utils4';
import { canUpdateFinancialData, canViewFinancialData } from '../../../../shared/util/permissionUtils';
import { getStoreState } from '../../../../../index';
import {
    defaultTaxCalculationTypeTitle,
    DefaultTaxContent, IncludeTaxInExternalExpenseSumEnum,
    includeTaxInExternalExpenseSumTitle
} from './businessAccountPreferencesEditModalFieldsData';
import {
    DocumentTemplateSelect,
    DocumentTemplateSelectProps,
} from '../../../../components/v2/select/documentTemplateSelect/documentTemplateSelect';
import { FormUtils, TypedFormField } from '../../../../core/utils/formUtils';
import {
    crewModuleEnabledSelector,
    offersModuleEnabledSelector
} from '../../../../shared/reducers/businessAccountPreferences.reducer';
import { paymentMethodRequirementTypeNamesMap, paymentMethodsRequirementAllTypes } from '../paymentMethods/data/paymentMethodsData';
import {legalCountriesCodes, legalCountriesNamesMap} from "../../legalDetails/data/legalDetailsData";
import {ContractValidationTypeRadioGroup} from "./components/contractValidationTypeRadioGroup/contractValidationTypeRadioGroup";
import {PricingTypeSelect, PricingTypeSelectProps} from "../../projects/production/modal/pricingTypeSelect";
import {TimeDurationInput, TimeDurationInputProps} from "../../../../components/timeDurationInput/timeDurationInput";
import {TimeStringInput} from "../../../../components/timeStringInput/timeStringInput";

export const defaultProjectTypeOfPricing: TypedFormField<FormValues> = {
    label: 'Тип ценообразования по умолчанию',
    id: 'defaultProjectTypeOfPricing',
    type: FormItemType.Component,
    component: PricingTypeSelect,
    componentProps: ():PricingTypeSelectProps =>({
        forSettings: true
    }),
    values: paymentMethodsRequirementAllTypes.map((code) => ({
        name: paymentMethodRequirementTypeNamesMap[code],
        value: code,
    })),
    visible: () => {
        const p = getStoreState().permissions.permissions;
        const offersModuleEnabled = offersModuleEnabledSelector(getStoreState()) || false;
        return offersModuleEnabled && canViewFinancialData(p);
    },
    required: true
};

export const paymentMethodRequirementTypeField: TypedFormField<FormValues> = {
    label: 'Обязательность способа оплаты в платежах',
    id: 'paymentMethodRequirementType',
    type: FormItemType.RadioGroup,
    values: paymentMethodsRequirementAllTypes.map((code) => ({
        name: paymentMethodRequirementTypeNamesMap[code],
        value: code,
    })),
    visible: () => {
        const p = getStoreState().permissions.permissions;
        return canViewFinancialData(p);
    },
};

export const contractValidationTypeField: TypedFormField<FormValues> = {
    label: 'Контроль актуальности договора',
    id: 'contractValidationType',
    type: FormItemType.Component,
    component: ContractValidationTypeRadioGroup,
    visible: () => {
        const p = getStoreState().permissions.permissions;
        return canViewFinancialData(p);
    },
};

export const defaultTaxCalculationTypeField: TypedFormField<FormValues> = {
    label: 'Тип расчёта налога по умолчанию',
    id: 'taxBaseType',
    type: FormItemType.RadioGroup,
    values: [
        {
            name: defaultTaxCalculationTypeTitle[TaxBaseTypeCodeEnum.COSTBEFORETAX],
            value: TaxBaseTypeCodeEnum.COSTBEFORETAX,
        },
        {
            name: defaultTaxCalculationTypeTitle[TaxBaseTypeCodeEnum.SUMAFTERTAX],
            value: TaxBaseTypeCodeEnum.SUMAFTERTAX,
        },
    ],
    visible: () => {
        const p = getStoreState().permissions.permissions;
        return canViewFinancialData(p);
    },
};
export const includeTaxInExternalExpenseSumField: TypedFormField<FormValues> = {
    label: 'Учет налога во внешней стоимости затрат',
    id: 'includeTaxInExternalExpenseSum',
    type: FormItemType.RadioGroup,
    values: [
        {
            name: includeTaxInExternalExpenseSumTitle[IncludeTaxInExternalExpenseSumEnum.NO],
            value: false
        },
        {
            name: includeTaxInExternalExpenseSumTitle[IncludeTaxInExternalExpenseSumEnum.YES],
            value: true
        },
    ],
    visible: () => {
        const p = getStoreState().permissions.permissions;
        return canViewFinancialData(p);
    },
};

interface FormValues extends BusinessAccountPreferencesInfoRead {
    defaultBadgeTemplateId?: number;
}

// dynamic form fields
export const formFields: FormFieldsGroup[] = FormUtils.typedFormFields<FormValues>([
    {
        fields: [
            {
                label: 'Страна',
                id: 'legalCountry',
                type: FormItemType.Select,
                required: true,
                placeholder: 'Выберите страну',
                values: legalCountriesCodes.map(code=>{
                    return { value: code, name: legalCountriesNamesMap[code] };
                })
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__CURRENCY,
                id: 'baseCurrency',
                type: FormItemType.Select,
                required: true,
                placeholder: 'Выберите валюту',
                values: [
                    { value: CurrencyCodeEnum.RUB, name: currencyName(CurrencyCodeEnum.RUB) },
                    { value: CurrencyCodeEnum.USD, name: currencyName(CurrencyCodeEnum.USD) },
                    { value: CurrencyCodeEnum.EUR, name: currencyName(CurrencyCodeEnum.EUR) },
                    { value: CurrencyCodeEnum.AMD, name: currencyName(CurrencyCodeEnum.AMD) },
                    { value: CurrencyCodeEnum.GEL, name: currencyName(CurrencyCodeEnum.GEL) },
                    { value: CurrencyCodeEnum.BYR, name: currencyName(CurrencyCodeEnum.BYR) },
                    { value: CurrencyCodeEnum.KZT, name: currencyName(CurrencyCodeEnum.KZT) },
                    { value: CurrencyCodeEnum.KGS, name: currencyName(CurrencyCodeEnum.KGS) },
                    { value: CurrencyCodeEnum.UZS, name: currencyName(CurrencyCodeEnum.UZS) },
                    { value: CurrencyCodeEnum.AZN, name: currencyName(CurrencyCodeEnum.AZN) },
                    { value: CurrencyCodeEnum.THB, name: currencyName(CurrencyCodeEnum.THB) },
                    { value: CurrencyCodeEnum.IDR, name: currencyName(CurrencyCodeEnum.IDR) },
                    { value: CurrencyCodeEnum.AED, name: currencyName(CurrencyCodeEnum.AED) },
                    { value: CurrencyCodeEnum.TJS, name: currencyName(CurrencyCodeEnum.TJS) },
                ],
                visible: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p);
                },
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
            },
            {
                label: 'Часовой пояс',
                id: 'timezoneOffsetUTC',
                type: FormItemType.Select,
                required: false,
                placeholder: 'Выберите часовой пояс',
                values: [
                    { value: -720, name: '-12:00' },
                    { value: -660, name: '-11:00' },
                    { value: -600, name: '-10:00' },
                    { value: -540, name: '-9:00' },
                    { value: -480, name: '-8:00' },
                    { value: -420, name: '-7:00' },
                    { value: -360, name: '-6:00' },
                    { value: -300, name: '-5:00' },
                    { value: -240, name: '-4:00' },
                    { value: -180, name: '-3:00' },
                    { value: -120, name: '-2:00' },
                    { value: -60, name: '-1:00' },
                    { value: 0, name: '0:00' },
                    { value: 60, name: '+1:00' },
                    { value: 120, name: '+2:00' },
                    { value: 180, name: '+3:00' },
                    { value: 240, name: '+4:00' },
                    { value: 300, name: '+5:00' },
                    { value: 360, name: '+6:00' },
                    { value: 420, name: '+7:00' },
                    { value: 480, name: '+8:00' },
                    { value: 540, name: '+9:00' },
                    { value: 600, name: '+10:00' },
                    { value: 660, name: '+11:00' },
                    { value: 720, name: '+12:00' },
                    { value: 780, name: '+13:00' },
                    { value: 840, name: '+14:00' },
                ],
            },
            {
                label: 'Длительность смены в минутах',
                id: 'shiftLengthInMinutes',
                type: FormItemType.Component,
                required: true,
                defaultValue: 0,
                component: TimeDurationInput,
                componentProps: ():TimeDurationInputProps=>({
                    min: 1
                })
            },
            {
                label: 'Округление смен',
                id: 'shiftCountRoundingType',
                type: FormItemType.Select,
                required: true,
                placeholder: 'Выберите тип округления смен',
                values: [
                    {
                        value: ShiftCountRoundingTypeCodeEnum.UP,
                        name: getShiftCountRoundingTypeByCode(ShiftCountRoundingTypeCodeEnum.UP),
                    },
                    {
                        value: ShiftCountRoundingTypeCodeEnum.DOWN,
                        name: getShiftCountRoundingTypeByCode(ShiftCountRoundingTypeCodeEnum.DOWN),
                    },
                    {
                        value: ShiftCountRoundingTypeCodeEnum.ROUNDHALFUP,
                        name: getShiftCountRoundingTypeByCode(ShiftCountRoundingTypeCodeEnum.ROUNDHALFUP),
                    },
                    {
                        value: ShiftCountRoundingTypeCodeEnum.FRACTIONSECONDSIGNROUNDHALFUP,
                        name: getShiftCountRoundingTypeByCode(ShiftCountRoundingTypeCodeEnum.FRACTIONSECONDSIGNROUNDHALFUP),
                    },
                ],
            },
            {
                label: 'Резерв времени между обязательствами в минутах',
                id: 'requiredTimeIndentBetweenElementsInMinutes',
                type: FormItemType.Integer,
                required: true,
                minLength: 0,
                defaultValue: 0,
            },
            {
                label: 'Время выдачи по умолчанию',
                id: 'defaultRentTime',
                type: FormItemType.Component,
                component: TimeStringInput
            },
            {
                label: 'Длина периода аренды по умолчанию',
                id: 'defaultRentDuration',
                type: FormItemType.Component,
                component: TimeDurationInput,
                componentProps: ():TimeDurationInputProps=>({
                    min: 1
                })
            },
            {
                label: 'Параллельные операции',
                id: 'allowConcurrentOperations',
                type: FormItemType.RadioGroup,
                required: true,
                values: [
                    {
                        name: 'Разрешены',
                        value: true,
                    },
                    {
                        name: 'Запрещены',
                        value: false,
                    },
                ],
            },
            {
                label: 'Включать бронь в стоимость',
                id: 'includeBookedElementsInProjectSum',
                type: FormItemType.Switch,
                required: true,
                visible: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p);
                },
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
            },
            {
                label: 'Включать запланированные работы в стоимость',
                id: 'includePlannedWorkPlanningsInProjectSum',
                type: FormItemType.Switch,
                required: true,
                visible: () => {
                    return Boolean(crewModuleEnabledSelector(getStoreState()));
                },
            },
            contractValidationTypeField,
        ],
    },
    {
        helpMessage: DefaultTaxContent,
        fields: [
            {
                label: 'Налог по умолчанию',
                id: 'defaultTaxRate',
                type: FormItemType.Tax,
                defaultValue: 0,
                required: true,
            },
            defaultTaxCalculationTypeField,
            includeTaxInExternalExpenseSumField
        ],
    },
    {
        fields: [
            defaultProjectTypeOfPricing,
            paymentMethodRequirementTypeField
        ],
    },
    {
        fields: [
            {
                id: 'defaultBadgeTemplate',
                type: FormItemType.Hidden,
            },
            {
                label: 'Шаблон этикетки',
                id: 'defaultBadgeTemplateId',
                type: FormItemType.Component,
                component: DocumentTemplateSelect,
                placeholder: 'Выберите шаблон этикетки',
                componentProps: (form): DocumentTemplateSelectProps => {
                    return {
                        onDataChange: (record) => {
                            const template = record && record[0];
                            form.setFieldsValue({
                                defaultBadgeTemplate: template && {
                                    id: template.id,
                                    name: template.shortName,
                                    isCommon: template.common,
                                },
                            });
                        },
                        filters: {
                            documentType: [DocumentTypeCodeEnum.BADGE],
                        },
                    };
                },
                required: false,
            },
        ],
    },
]);
