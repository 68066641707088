import { Alert, Icon, Tabs } from 'antd';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { push } from 'connected-react-router';
import { loadEntity, reset, updateEntity } from './reducers/operation.reducer';
import { IRootState } from '../../../shared/reducers';
import { RoundButton, Spin } from '../../../components';
import { IconEdit } from '../../../components/icons';
import { OperationCreateModal } from './operationCreateModal';
import OperationPageDescriptionTabPane from './operationPageDescription';
import { getPathFromState, getStateFromPath, isStringInEnum } from '../../../shared/util/utils';
import { BasePage, IBasePageProps } from '../../../components/page/BasePage';
import { IPageHeaderProps } from '../../../components/pageHeader/pageHeader';
import { ElementsListWrapper } from '../elements/elementsListWrapper';
import CompositionOperations from '../history-elements/history-elements-list';
import { logEvent } from '../../../analytics/analytics';
import { LocalizationEnum, localize } from '../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { resetOperationForm } from '../operationForm/reducers/operationForm.reducer';
import { linkToCalendar } from '../../../shared/util/createLinkPath';
import { GridName } from '../../../components/grid/utils/types';
import { LinkToCalendar } from '../../../components/link/toCalendar/LinkToCalendar';
import { operationExportActOptions, operationExportEstimateOptions } from './data/exportOptions';
import { OperationPageTabsEnum } from '../../../shared/constants/tabEnums';
import { OperationPageHeaderTitle } from './operationPageHeaderTitle';
import { getCurrentTabsSelector, saveCurrentTabToStore } from '../../../shared/reducers/userSettings/userSettings.reducer';
import { getGridStorageData } from '../../../components/grid/utils';

interface IState {}

interface IProps extends StateProps, DispatchProps, IBasePageProps, WrappedComponentProps {}

const defaultTab = OperationPageTabsEnum.DESCRIPTION;
class OperationPage extends BasePage<IProps, IState> {
    private selectedTab: OperationPageTabsEnum = OperationPageTabsEnum.ELEMENTS;
    private tabs = {};

    constructor(props: IProps) {
        super(props);
        this.props.resetOperationForm();
    }

    logViewEvent = () => {
        logEvent({ type: 'view operation', data: { 'entity id': this.props.entityId, tab: this.selectedTab } });
    };

    componentDidMount() {
        super.componentDidMount();
        this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
        this.logViewEvent();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.reset();
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this.props.tab) saveCurrentTabToStore('operation', this.props.tab);

        if (prevProps.entityId !== this.props.entityId) {
            this.props.reset();
            this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
        }
    }

    onEditButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/history/operations/${this.props.entityId}/edit?tab=description`);
    };

    onTabsChange = (key: string) => {
        this.tabs[this.selectedTab] = this.props.location.search;
        this.selectedTab = key as OperationPageTabsEnum;
        let searchString =
            key === OperationPageTabsEnum.ELEMENTS || key === OperationPageTabsEnum.COMPOSITION ? this.tabs[this.selectedTab] || '' : '';

        this.props.push(getPathFromState(this.props.location.pathname, searchString, { tab: key }));
        this.logViewEvent();
        saveCurrentTabToStore('operation', key);
    };

    getPageHeaderProps(): IPageHeaderProps {
        let { entity } = this.props;

        return {
            projectTemplate: !!entity?.templateId,
            projectIsOffer: false,
            businessAccountId: this.props.businessAccountId,
            rightBlock: entity ? (
                <LinkToCalendar
                    calendarPath={linkToCalendar({
                        group: 'project',
                        capture: 'all',
                        operationId: this.props.entityId,
                        renterId: entity.counterpartyId,
                        projectId: entity.projectId,
                        subrentId: entity.subrentId,
                    })}
                />
            ) : undefined,
            parentPagePath: entity ? `/${this.props.businessAccountId}/history/operations` : undefined,
            isLoading: !entity && this.props.loading,
            NavigationBlock: <OperationPageHeaderTitle operation={entity} />,
        };
    }

    getTabParams(tab: OperationPageTabsEnum, gridName: string) {
        if (gridName) {
            const storageData = getGridStorageData(gridName);
            return {
                ...storageData.filters,
                ...storageData.params,
            };
        } else {
            return {};
        }
    }

    get currentTab() {
        return new URLSearchParams(this.props.location.search).get('tab');
    }

    renderContent() {
        let { entity, loading } = this.props;
        let err;
        if (this.props.loadingError) {
            err = (
                <>
                    <div>
                        <strong>{this.props.loadingError.title}</strong>
                    </div>
                    <div>{this.props.loadingError.message}</div>
                </>
            );
        }

        let operationsExludeColumns;
        if (entity && entity.templateId) {
            operationsExludeColumns = [
                'problemSeverity',
                'rentTerms.rentPeriodStartDate',
                'rentTerms.rentPeriodEndDate',
                'subrentedInstanceCount',
            ];
        }

        let operationsExludeFields;
        if (entity && entity.templateId) {
            operationsExludeFields = ['problem', 'startDate', 'endDate'];
        }

        // Шаблон Проект Поставка
        // templateId projectId subrentId
        let operationEntityType: 'projectTemplate'|'project'|'shipping'|undefined;
        let projectIsSimpleOrder: boolean|undefined;
        let operationEntityId:number|undefined;
        let gridName: GridName = 'operation-elements';

        if (entity) {
            if (entity.templateId) {
                operationEntityType = 'projectTemplate';
                gridName = 'projectTemplate-operation-elements';
                operationEntityId = entity.templateId;
            } else if (entity.projectId) {
                operationEntityType = 'project';
                gridName = 'project-operation-elements';
                operationEntityId = entity.projectId;
                projectIsSimpleOrder = entity.projectIsSimpleOrder;
            } else if (entity.subrentId) {
                operationEntityType = 'shipping';
                gridName = 'shipping-operation-elements';
                operationEntityId = entity.subrentId;
            }
        }

        const { currentTab } = this;

        if (currentTab == null) {
            const tab = isStringInEnum(this.props.tabFromStore, OperationPageTabsEnum, defaultTab);
            return (
                <Redirect
                    to={getPathFromState(this.props.location.pathname, '', {
                        ...this.getTabParams(tab, gridName),
                        tab: tab,
                    })}
                />
            );
        }
        return (
            <>
                <Spin spinning={loading}>
                    <div className={'rr-page-renter'} style={{ minHeight: 400 }}>
                        {err ? (
                            <Alert
                                message={localize(LocalizationEnum.ASPECT__GLOBAL__ERROR)}
                                description={err}
                                type="error"
                                showIcon
                                closable
                                style={{ marginBottom: 30 }}
                            />
                        ) : null}
                        {entity ? (
                            <>
                                <div className="card-container">
                                    <Tabs
                                        defaultActiveKey={'description'}
                                        activeKey={this.props.tab}
                                        onChange={this.onTabsChange}
                                        className={'rr-tabs'}
                                        type="line"
                                        animated={false}
                                        tabBarExtraContent={
                                            <RoundButton colorScheme={'default'} onClick={this.onEditButtonClick}>
                                                <Icon component={IconEdit} />
                                                {localize(LocalizationEnum.ASPECT__ACTION__COMMENT, 'span')}
                                            </RoundButton>
                                        }
                                    >
                                        <Tabs.TabPane
                                            tab={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}
                                            key={OperationPageTabsEnum.DESCRIPTION}
                                        >
                                            <OperationPageDescriptionTabPane />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane
                                            tab={'Состав операции при создании'}
                                            key={OperationPageTabsEnum.COMPOSITION}
                                            disabled={false}
                                        >
                                            <CompositionOperations
                                                //gridName={'operation-composition'}
                                                visible={this.props.tab === OperationPageTabsEnum.COMPOSITION}
                                                parentType={'composition'}
                                                parentId={entity.id}
                                                location={this.props.location}
                                            />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab={'Текущее состояние'} key={OperationPageTabsEnum.ELEMENTS} disabled={false}>
                                            <ElementsListWrapper
                                                operationEntityType={operationEntityType}
                                                gridName={gridName}
                                                excludeColumns={operationsExludeColumns}
                                                excludeFields={operationsExludeFields}
                                                visible={this.props.tab === OperationPageTabsEnum.ELEMENTS}
                                                parentType={'operation'}
                                                parentId={entity.id}
                                                location={this.props.location}
                                                exportActOptions={operationExportActOptions}
                                                exportEstimateOptions={operationExportEstimateOptions}
                                                updateParentEntity={() => {
                                                    //
                                                }}
                                                projectIdForOperation={operationEntityId}
                                                projectIsSimpleOrderForOperation={projectIsSimpleOrder}
                                            />
                                        </Tabs.TabPane>
                                    </Tabs>
                                </div>
                            </>
                        ) : null}
                    </div>
                </Spin>
                {this.props.editMode ? (
                    <OperationCreateModal
                        title={localize(LocalizationEnum.PAGE__OPERATIONS__FORM__FORM_TITLE__COMMENT)}
                        businessAccountId={this.props.businessAccountId}
                        initialValues={entity as { [k: string]: any }}
                    />
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    let searchParams = getStateFromPath(ownProps.location.search);
    return {
        entity: storeState.operation.entity,
        loading: storeState.operation.loading,
        loadingError: storeState.operation.loadingError,
        tabFromStore: getCurrentTabsSelector(storeState)?.['operation'],
        businessAccountId: +ownProps.match.params['businessAccountId'] || 0,
        entityId: +ownProps.match.params['id'] || 0,
        editMode: !!(storeState.operation.entity && ownProps.match.params['action'] === 'edit'),
        tab:
            searchParams.tab &&
            (searchParams.tab === OperationPageTabsEnum.DESCRIPTION ||
                searchParams.tab === OperationPageTabsEnum.COMPOSITION ||
                searchParams.tab === OperationPageTabsEnum.ELEMENTS)
                ? searchParams.tab
                : OperationPageTabsEnum.ELEMENTS,
    };
};

const mapDispatchToProps = { loadEntity, updateEntity, reset, push, resetOperationForm };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OperationPage));
