import React, {ReactNode} from "react";
import {Button} from "antd";
import {connect} from "react-redux";
import {IRootState} from "../../../../../shared/reducers";
import {EntityRemoteSelect} from "../../../../../components/select/EntityRemoteSelect";
import {serverApi} from "../../../../../server";
import {Popover} from "../../../../../components/popover/Popover";
import {showNotification} from "../../../../../components/notification/showNotification";

interface IProps extends StateProps, DispatchProps {
    children: ReactNode;
    projectId?: number;
    onVisibleChange: (visible: boolean) => void;
    onClose: () => void;
}

interface IState {
    visible: boolean;
    selectedId?: string;
    loading: boolean;
    selectedValue?: any;
}

class AddProjectPopover extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            loading: false
        };
    }

    handleVisibleChange = (visible: boolean) => {
        this.props.onVisibleChange(visible);
        this.setState(
            {
                visible,
                selectedId: !visible ? undefined : this.state.selectedId,
                selectedValue: !visible ? undefined : this.state.selectedValue
            }
        );
    };

    onOkButtonClick = async e => {
        e.stopPropagation();
        if (this.state.selectedId) {
            await this.setState({
                loading: true
            });
            try {
                await serverApi.addRentElementsFromTemplateToExistingProject(this.props.businessAccountId, this.props.projectId || 0, +this.state.selectedId);
                showNotification('success', 'Обязательства успешно добавлены в проект');
            } catch (e) {
                showNotification('error', 'Не удалось добавить обязательства в проект');
            }
            await this.setState({
                loading: false,
                visible: false,
                selectedId: undefined,
                selectedValue: undefined
            });
            this.props.onVisibleChange(false);
            this.props.onClose();
        }
    };

    onChange = (data) => {
        if (data && data.key) {
            this.setState({selectedId: data.key, selectedValue: data});
        }
    };

    render() {
        console.log(`${this.constructor.name} render()`);
        const {visible} = this.state;

        return (
            <Popover
                visible={visible}
                onVisibleChange={this.handleVisibleChange}
                overlayClassName={'rr-operationForm-kit-add-element-popover'}
                headerClassName={'rr-grid-actions-popover-header-container'}
                header={
                    <>
                        <EntityRemoteSelect
                            style={{width: '100%'}}
                            placeholder={'Выберите шаблон'}
                            nameField={'shortName'}
                            onChange={this.onChange}
                            className={'rr-select-gray'}
                            showSearch={true}
                            operationName={'listTemplates'}
                            filters={[`archive;EQ;false`]}
                            sortOrder={'DESC'}
                            sortBy={'lastActivityDate'}
                            disabled={this.state.loading}
                            value={this.state.selectedValue}
                        />
                        <Button loading={this.state.loading} className={'rr-btn-blue'} style={{marginLeft: 8}}
                                onClick={this.onOkButtonClick} disabled={!this.state.selectedId}>OK</Button>
                    </>
                }>
                {this.props.children}
            </Popover>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    businessAccountId: storeState.system.businessAccountId
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const AddProjectPopover1 = connect(mapStateToProps, mapDispatchToProps)(AddProjectPopover);
