import React, {FC} from 'react';
import {Picker} from "../picker/Picker";
import {
    CounterpartySelectAndCreate
} from "../../../../../../components/v2/select/counterpartySelect/counterpartySelectAndCreate";
import {Moment} from "moment";
import {RangePickerProps} from "../../../../../../components/v2/calendar/rangePicker/rangePicker";
import {useAppSelector} from "../../../../../../store/hooks";
import {LabelValue} from "../labelValue/labelValue";
import {
    CounterpartyActivityTypeCodeEnum,
    OperationTypeCodeEnum,
    ProjectInfoRead,
    ProjectStateCodeEnum,
    RenterStateCodeEnum
} from "../../../../../../server";
import {EditOperationButton} from "../../../../elements/editOperationButton";
import './simpleOrderPageTopBlock.less';
import {ProjectEditBlock} from "./components/projectEditBlock";

interface SimpleOrderPageTopBlockProps {
    dates: [Moment, Moment];
    onDatesChanged: RangePickerProps['onChange'];
    renterId?: number;
    orderId?: number;
    order?: ProjectInfoRead;
    onRenterChange: (id:number, name:string|undefined, discount:number|undefined) => void;
    totalPrice: number;
    debtSum: number;
    isNowEditing: boolean;
}

export const SimpleOrderPageTopBlock: FC<SimpleOrderPageTopBlockProps> = (props) => {
    const typeCode = useAppSelector((state) => state.operationForm.typeCode);
    let controlsDisabled = !Boolean(typeCode) || typeCode !== OperationTypeCodeEnum.EDIT;

    if(props.order){
        controlsDisabled = !props.isNowEditing;
    }

    let disabledCounterpartyStates = props.order && (props.order.stateCode === ProjectStateCodeEnum.FINISHED || props.order.stateCode === ProjectStateCodeEnum.RENTED || props.order.stateCode === ProjectStateCodeEnum.BOOKED) ? [RenterStateCodeEnum.NEW, RenterStateCodeEnum.BLOCKED] : [RenterStateCodeEnum.BLOCKED];
    if(props.order && (props.order.stateCode === ProjectStateCodeEnum.DRAFT || props.order.stateCode === ProjectStateCodeEnum.CANCELED)) disabledCounterpartyStates = [];

    return (
        <div style={{display: 'flex', marginBottom: 36}} className={'rr-simpleOrderPage-topBlock'}>
            <div style={{display: 'flex', flexWrap: 'wrap', flexGrow: 1, rowGap: 10}}>
                <div style={{marginRight: 60}}>
                    <LabelValue label={'Период аренды'}>
                        <Picker disabled={controlsDisabled} value={props.dates} onChange={props.onDatesChanged}/>
                    </LabelValue>
                </div>
                <div>
                    <LabelValue label={'Арендатор'}>
                        <CounterpartySelectAndCreate
                            counterpartyType={'renter'}
                            filters={{
                                activityTypeCode: [CounterpartyActivityTypeCodeEnum.RENTERONLY, CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER]
                            }}
                            isItemDisabled={(record)=>disabledCounterpartyStates.includes(record.stateCode)}
                            disabled={controlsDisabled}
                            allowClear={false}
                            value={props.renterId}
                            placeholder={'Не выбран'}
                            style={{minWidth: 200}}
                            dropdownMatchSelectWidth={false}
                            className={'rr-select-gray rr-simpleOrderPage-topBlock-counterparty-select'}
                            openOnMount={!props.renterId}
                            onDataChange={(record) => {
                                if (record && record[0]) {
                                    if(props.isNowEditing || !props.order) {
                                        props.onRenterChange(record[0].id, record[0].shortName, record[0].defaultDiscount);
                                    }
                                }
                            }}
                            onChange={(value)=>{
                                props.onRenterChange(value as number, undefined, undefined);
                            }}
                        />
                    </LabelValue>
                </div>
            </div>
            <div>
                <ProjectEditBlock
                    editButton={props.orderId ? <EditOperationButton order={props.order} parentType={'project'} parentId={props.orderId} /> : null}
                    actualSumAfterTaxes={props.totalPrice}
                    debtSum={props.debtSum}
                    withoutLabel={false}
                    parentEntityIsCreated={Boolean(props.orderId)}
                    isNowEditing={props.isNowEditing}
                />
            </div>
        </div>
    );
};
