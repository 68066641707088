import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import {
    CurrencyCodeEnum,
    PricingSchemeInfoCreate,
    PricingSchemeInfoUpdate,
    RoundingDirectionCodeEnum,
    RoundingLevelCodeEnum,
} from '../../../../server/api';
import { push } from 'connected-react-router';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { getFormFields } from './pricingSchemeEditModalFields';
import { showNotification } from '../../../../components/notification/showNotification';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../components/modalForm/ModalFormNEW';
import { FormFieldsGroup } from '../../../../components/dynamicForm/DynamicForm';
import { createEntity, loadEntity, reset, resetError, updateEntity } from './reducers/pricingScheme.reducer';
import { IntlShape } from 'react-intl';
import { LocalizationEnum, localizeIntl } from '../../../../localization';
import { loadEntities } from './reducers/pricingSchemes.reducer';
import './pricingSchemeEditModal.less';
import { updateModalFilters } from '../../../../shared/reducers/userSettings/userSettings.reducer';

interface IProps extends ModalFormNEWProps {
    formFields?: FormFieldsGroup[];
    goBackPath: string;
    intl: IntlShape;
    entityId?: number;
}

class PricingSchemeEditModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    _componentDidMount = async () => {
        if (this.props.entityId) this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
    };

    getInitialValues = () => {
        let initialValues: any;
        if (this.props.entity)
            initialValues = {
                ...this.props.entity,
                steps: this.props.entity.steps?.map((step) => ({ ...step })),
                rounding:
                    this.props.entity.roundingLevel && this.props.entity.roundingDirection
                        ? `${this.props.entity.roundingLevel}-${this.props.entity.roundingDirection}`
                        : undefined,
            };
        else initialValues = { steps: [] };
        return initialValues;
    };

    getFormFields = () => {
        return getFormFields(this.props.currency || CurrencyCodeEnum.RUB, !!this.props.entityId);
    };

    onOk = async (data: any) => {
        const roundingLevel =
            data.rounding && data.rounding.split('-').length === 2 ? (data.rounding.split('-')[0] as RoundingLevelCodeEnum) : undefined;
        const roundingDirection =
            data.rounding && data.rounding.split('-').length === 2 ? (data.rounding.split('-')[1] as RoundingDirectionCodeEnum) : undefined;

        if (this.props.entityId) {
            const prevSteps = this.props.entity?.steps;
            const newSteps = data.steps;
            const stepsChanged = JSON.stringify(prevSteps) !== JSON.stringify(newSteps);
            const typeCodeChanged = data.typeCode !== this.props.entity?.typeCode;

            const linkedElementsCount = this.props.entity?.linkedRentElementsAndKitsCount;
            const projectsWithLinkedElementsCount = this.props.entity?.projectWithLinkedRentElementsOrKitsCount;
            const linkedWorkPlanningsCount = this.props.entity?.linkedWorkPlanningsCount;
            const projectsWithLinkedWorkPlanningsCount = this.props.entity?.projectWithLinkedWorkPlanningCount;

            const jsxInfo = [
                linkedElementsCount && (
                    <>
                        <span style={{ fontWeight: 600 }}>{linkedElementsCount}</span>{' '}
                        {localizeIntl(this.props.intl, LocalizationEnum.ASPECT__PLURAL__FOR_ACTIVE, undefined, {
                            value: linkedElementsCount,
                        })}{' '}
                        {localizeIntl(this.props.intl, LocalizationEnum.ASPECT__PLURAL__FOR_ELEMENT, undefined, {
                            value: linkedElementsCount,
                        })}{' '}
                        в <span style={{ fontWeight: 600 }}>{projectsWithLinkedElementsCount}</span>{' '}
                        {localizeIntl(this.props.intl, LocalizationEnum.ASPECT__PLURAL__IN_PROJECT, undefined, {
                            value: projectsWithLinkedElementsCount,
                        })}
                    </>
                ),
                linkedWorkPlanningsCount && (
                    <>
                        <span style={{ fontWeight: 600 }}>{linkedWorkPlanningsCount}</span>{' '}
                        {localizeIntl(this.props.intl, LocalizationEnum.ASPECT__PLURAL__FOR_WORK, undefined, {
                            value: linkedWorkPlanningsCount,
                        })}{' '}
                        в <span style={{ fontWeight: 600 }}>{projectsWithLinkedWorkPlanningsCount}</span>{' '}
                        {localizeIntl(this.props.intl, LocalizationEnum.ASPECT__PLURAL__IN_PROJECT, undefined, {
                            value: projectsWithLinkedWorkPlanningsCount,
                        })}
                    </>
                ),
            ].filter(Boolean);

            const yes =
                jsxInfo.length > 0 && (stepsChanged || typeCodeChanged)
                    ? await showConfirm(
                          this.props.intl,
                          <>Вы уверены, что хотите изменить условия ценовой схемы?</>,
                          <>
                              Это сделает ее неактуальной для {jsxInfo[0]}
                              {jsxInfo[1] && <>, а также для {jsxInfo[1]}</>}. Для этих записей ценовая схема будет отвязана, но текущая
                              цена за смену будет сохранена.
                          </>,
                          undefined,
                          710
                      )
                    : true;
            if (yes) {
                // Редактирование
                let newData: PricingSchemeInfoUpdate = {
                    name: data.name,
                    businessVersion: this.props.entity?.businessVersion,
                    comment: data.comment,
                    steps: data.steps,
                    typeCode: data.typeCode,
                    roundingLevel,
                    roundingDirection,
                };

                let result = await this.props.updateEntity(this.props.businessAccountId, this.props.entityId, newData);
                if (!(result instanceof Error)) {
                    showNotification('success', 'Ценовая схема успешно изменена');
                    this.props.push(this.props.goBackPath);
                    this.props.loadEntities(this.props.businessAccountId);
                    this.props.resetError();
                    this.props.reset();
                } else {
                    // TODO Мб норм сообщение об ошибке норм выводить
                    showNotification('error', localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
                }
            }
        } else {
            // Создание
            let newData: PricingSchemeInfoCreate = {
                name: data.name,
                typeCode: data.typeCode,
                comment: data.comment,
                steps: data.steps,
                roundingLevel,
                roundingDirection,
            };

            let result = await this.props.createEntity(this.props.businessAccountId, newData);
            if (!(result instanceof Error)) {
                showNotification('success', 'Ценовая схема успешно создана');
                this.props.push(this.props.goBackPath);
                this.props.loadEntities(this.props.businessAccountId);
                this.props.resetError();
                this.props.reset();
            } else {
                // TODO Мб норм сообщение об ошибке норм выводить
                showNotification('error', localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            }
        }
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(
                this.props.intl,
                localizeIntl(this.props.intl, LocalizationEnum.PAGE__SETTINGS__CUSTOM_FIELDS__EDIT_CANCEL_CONFIRM_MESSAGE)
            ));
        if (yes) {
            this.props.push(this.props.goBackPath);
            this.props.resetError();
            this.props.reset();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    entity: storeState.pricingScheme.entity,
    updating: storeState.pricingScheme.updating,
    updatingError: storeState.pricingScheme.updatingError,
    businessAccountId: storeState.system.businessAccountId,
    modalFilters: storeState.userSettings.modalFilters,
    currency: storeState.businessAccountPreferences.preferences?.baseCurrency,
});

const mapDispatchToProps = {
    createEntity,
    updateEntity,
    push,
    resetError,
    reset,
    loadEntities,
    loadEntity,
    updateModalFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PricingSchemeEditModal);
