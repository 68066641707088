import { ColumnTypes, TableColumn } from '../../../../../../components/grid/Table';
import { TransportationRecord } from '../../../../../../server';
import { LocalizationEnum, localize } from '../../../../../../localization';
import React from 'react';
import { Link } from 'react-router-dom';
import { GridColumnCreator } from '../../../../../../components/grid/gridColumnCreator';
import { EntityType } from '../../../../../../../index';
import { Status } from '../../../../../../components/status/status';
import { TransportationUtils } from '../utils';
import { MAX_WIDTH } from '../../../../../../config/constants';
import { CargoCounterpartyHandler } from '../components/cargoHandler';
import { ProjectPageTabsEnum } from '../../../../../../shared/constants/tabEnums';

const projectSizes = GridColumnCreator.getColumnSizes(50);
const vehiclesSizes = GridColumnCreator.getColumnSizes(30);

export const transportationsColumns: TableColumn<TransportationRecord>[] = [
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NUMBER),
        dataIndex: 'number',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        resizable: false,
        render: (value: any, rowData: TransportationRecord) => {
            return (
                <a>
                    <strong className={'main-value'}>#{rowData.number}</strong>
                </a>
            );
        },
    },
    {
        title: 'Проект',
        dataIndex: 'projectName',
        type: ColumnTypes.CustomRender,
        minWidth: projectSizes.min,
        width: projectSizes.width,
        maxWidth: projectSizes.max,
        resizable: true,
        render: (value: any, row: TransportationRecord) => {
            const { projectId, projectName, businessAccountId } = row;
            return (
                <div className={'rr-grid-shortName-cell rr-grid-text-cell-left'} style={{ width: 'fit-content' }} title={projectName}>
                    <Link
                        to={`/${businessAccountId}/projects/production/${projectId}?tab=${ProjectPageTabsEnum.ELEMENTS}`}
                        onClick={(event) => event.stopPropagation()}
                    >
                        {projectName}
                    </Link>
                </div>
            );
        },
    },
    GridColumnCreator.createStatusColumn<TransportationRecord>({
        title: 'Статус',
        dataIndex: 'stateCode',
        type: ColumnTypes.CustomRender,
        width: 140,
        maxWidth: MAX_WIDTH(140),
        render: (value: any, rowData: TransportationRecord, onAction, entityType: EntityType) => {
            const customText = TransportationUtils.getActualStateCode(value);

            return value ? <Status entityType={entityType} code={value} style={{ width: '100%' }} customText={customText} /> : null;
        },
    }),
    GridColumnCreator.createUTCDateTimeColumn<TransportationRecord>({
        title: 'Дата и время погрузки',
        dataIndex: 'dateTimeOfLoading',
    }),
    {
        title: 'Грузоотправитель',
        type: ColumnTypes.CustomRender,
        minWidth: 200,
        resizable: true,
        dataIndex: 'shipperId',
        render: (value: any, row: TransportationRecord) => {
            const { shipperId, shipperName } = row;

            return <CargoCounterpartyHandler counterpartyId={shipperId} counterpartyName={shipperName} />;
        },
    },
    GridColumnCreator.createLocationColumn<TransportationRecord>({
        title: 'Адрес погрузки',
        dataIndex: 'loadingLocationName',
    }),
    GridColumnCreator.createUTCDateTimeColumn<TransportationRecord>({
        title: 'Дата и время выгрузки',
        dataIndex: 'dateTimeOfUnloading',
    }),
    {
        title: 'Грузополучатель',
        type: ColumnTypes.CustomRender,
        resizable: true,
        minWidth: 200,
        dataIndex: 'consigneeId',
        render: (value: any, row: TransportationRecord) => {
            const { consigneeId, consigneeName } = row;
            return <CargoCounterpartyHandler counterpartyId={consigneeId} counterpartyName={consigneeName} />;
        },
    },
    GridColumnCreator.createLocationColumn<TransportationRecord>({
        title: 'Адрес выгрузки',
        dataIndex: 'unloadingLocationName',
    }),
    {
        title: 'Автомобиль',
        type: ColumnTypes.Click,
        minWidth: 150,
        width: vehiclesSizes.width,
        maxWidth: vehiclesSizes.max,
        resizable: true,
        dataIndex: 'carName',
        render: (value: any, row: TransportationRecord) => {
            const { carName } = row;

            return (
                <div className={'rr-grid-text-cell-left'} title={carName}>
                    <span>{carName ?? ''}</span>
                </div>
            );
        },
    },
    {
        title: 'Прицеп',
        type: ColumnTypes.Click,
        minWidth: 110,
        width: vehiclesSizes.width,
        maxWidth: vehiclesSizes.max,
        resizable: true,
        dataIndex: 'trailerName',
        render: (value: any, row: TransportationRecord) => {
            const { trailerName } = row;

            return (
                <div className={'rr-grid-text-cell-left'} title={trailerName}>
                    <span>{trailerName ?? ''}</span>
                </div>
            );
        },
    },
    GridColumnCreator.createAttachmentsCountColumn<TransportationRecord>({
        title: 'Число вложений',
        dataIndex: 'attachmentCount',
    }),
    GridColumnCreator.createUserColumn<TransportationRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.lastUpdateAuthorId,
            name: row.lastUpdateAuthorShortName,
        }),
        {
            title: 'Автор изменения',
            dataIndex: 'lastUpdateAuthorShortName',
            minWidth: 150,
        }
    ),
    GridColumnCreator.createLastUpdateDateColumn<TransportationRecord>({
        title: 'Дата изменения',
        dataIndex: 'lastUpdateDate',
    }),
    GridColumnCreator.createActionsColumn<TransportationRecord>({ id: 'actions' }),
];
