import React, {CSSProperties, FC, useEffect, useState} from 'react';
import moment, {Moment} from "moment";
import {Icon} from "antd";
import {IconClockO} from "../icons";
import {TimeRangePickerPanel} from "../timeRangePicker/timeRangePickerPanel";
import {TimeInput} from "./timeInput";
import Trigger from 'rc-trigger';
import './timePicker.less';

interface DateTimePickerFooterProps {
    value?: Moment;
    onChange?: (date: Moment|undefined) => void;
    style?: CSSProperties;
    disabledHours?: number[];
    disabledMinutes?: number[];
    minutesStep?: number;
    disabled?: boolean;
    onTodayButtonClick?:(()=>void)|null;
    canClear?: boolean;
    timeInputStyle?: CSSProperties;
}

export const TimePicker:FC<DateTimePickerFooterProps> = ({onChange, value, style, disabledHours, disabledMinutes, minutesStep, disabled, onTodayButtonClick, canClear, timeInputStyle}) => {

    const [timePickerVisible, setTimePickerVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Moment|undefined>();
    const currentDisplayValue = selectedDate || value || undefined;

    useEffect(()=>{
        setSelectedDate(value);
    }, [value]);

    const currentTimeButtonEnabled = onTodayButtonClick === undefined ? undefined : Boolean(onTodayButtonClick);

    return (
        <Trigger
            getPopupContainer={(triggerNode) => triggerNode.parentNode as any}
            popupTransitionName={'slide-up'}
            destroyPopupOnHide
            stretch={['width']}
            popupVisible={timePickerVisible && !disabled}
            onPopupVisibleChange={(visible) => {
                setTimePickerVisible(visible);
            }}
            popupAlign={{ points: ['tc', 'bc'], offset: [0, -314] }}
            action={['click']}
            popup={
                <TimeRangePickerPanel
                    value={currentDisplayValue}
                    minutesStep={minutesStep || 1}
                    onChange={(value) => {}}
                    onSelect={(value) => {
                        const date = value.milliseconds(0).seconds(0);
                        setSelectedDate(date)
                        onChange?.(date);
                    }}
                    onOk={() => {
                        // клик по кнопке ОК
                        setTimePickerVisible(false);
                    }}
                    onSetCurrentTime={() => {
                        if(onTodayButtonClick){
                            onTodayButtonClick();
                        }else{
                            const curDate = moment().milliseconds(0).seconds(0);
                            const date =
                                selectedDate?.clone().hours(curDate.hours()).minutes(curDate.minutes()) ||
                                curDate;
                            setSelectedDate(date)
                            onChange?.(date);
                        }
                    }}
                    disabledHours={disabledHours}
                    disabledMinutes={disabledMinutes}
                    currentTimeButtonEnabled={currentTimeButtonEnabled}
                />
            }
        >
            <div>
                <span className="ant-calendar-picker-input ant-input rr-time-picker" style={style}>
                    <TimeInput
                        value={currentDisplayValue}
                        onChange={(value) => {
                            setSelectedDate(value)
                            onChange?.(value);
                        }}
                        style={{ paddingRight: 28, ...timeInputStyle }}
                        disabled={disabled}
                    />
                    <span className="ant-time-picker-icon rr-time-picker-icon">
                        <Icon component={IconClockO} onClick={canClear ? (e)=>{
                            e.stopPropagation();
                            setSelectedDate(undefined);
                            onChange?.(undefined);
                            // TODO При наведении на часы нужно показывать иконку крестика для очистки значения
                        } : undefined} />
                    </span>
                </span>
            </div>
        </Trigger>
    );
};


// .ant-time-picker-icon