import { ColumnTypes, TableColumn } from '../../../../../../components/grid/Table';
import React, { CSSProperties } from 'react';
import { InstanceRecord, RenterRecord, RenterSourceCodeEnum } from '../../../../../../server';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { GRID_ACTIONS_COLUMN_WIDTH } from '../../../../../../config/constants';
import DashboardDate from '../../../../../../components/dashboardDate/dashboardDate';
import { getCounterpartyActivityTypeByCode, getCounterpartySourceByCode } from '../../../../../../shared/util/utils4';
import { SystemIcon, SystemIconType } from '../../../../../../components/v2/systemIcon/systemIcon';
import { GridColumnCreator } from '../../../../../../components/grid/gridColumnCreator';
import { legalCountriesNamesMap, legalSubjectNamesMap } from '../../../../legalDetails/data/legalDetailsData';

const counterpartyColumns: TableColumn<RenterRecord>[] = [
    GridColumnCreator.createProblemSeverityColumn(),
    GridColumnCreator.createImageColumn({ defaultHidden: true }),
    GridColumnCreator.createShortNameColumn<RenterRecord>('renter', (row) => ({
        baId: row.businessAccountId,
        id: row.id,
        name: row.shortName,
    })),
    GridColumnCreator.createStatusColumn(),
    {
        title: 'Вид деятельности',
        dataIndex: 'activityTypeCode',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        resizable: true,
        render: (value: any, rowData: RenterRecord) => {
            return getCounterpartyActivityTypeByCode(rowData.activityTypeCode);
        },
    },
    {
        title: 'Источник',
        dataIndex: 'sourceCode',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        resizable: true,
        render: (value: any, rowData: RenterRecord) => {
            let style: CSSProperties | undefined;
            if (rowData.sourceCode === RenterSourceCodeEnum.INTERNAL) style = { color: '#CCCCCC' };
            return <span style={style}>{getCounterpartySourceByCode(rowData.sourceCode)}</span>;
        },
    },
    {
        title: 'Телефон',
        dataIndex: 'phoneNumber',
        type: ColumnTypes.Phone,
        minWidth: 150,
        resizable: true,
    },
    {
        title: localize(LocalizationEnum.ASPECT__FILTERS__TYPE),
        dataIndex: 'typeCode',
        type: ColumnTypes.CustomRender,
        width: 150,
        resizable: false,
        render: (value: any, rowData: RenterRecord) => {
            return localize(legalSubjectNamesMap[value]);
        },
        defaultHidden: true,
    },
    {
        title: 'Страна',
        dataIndex: 'country',
        type: ColumnTypes.CustomRender,
        width: 150,
        resizable: false,
        render: (value: any, rowData: RenterRecord) => {
            return localize(legalCountriesNamesMap[value]);
        },
        defaultHidden: true,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__DISCOUNT),
        dataIndex: 'defaultDiscount',
        type: ColumnTypes.Percents,
        minWidth: 100,
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__BOOKED),
        dataIndex: 'currentBookedSum',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
    },
    {
        title: 'В аренде',
        dataIndex: 'currentRentSum',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__FIELDS__RENTER__PROJECTS_COST),
        dataIndex: 'allProjectsSumAfterTaxes',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
    },
    {
        title: 'Стоимость поставок',
        dataIndex: 'allSubrentsSum',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
    },
    {
        title: 'Долг арендатора',
        dataIndex: 'debtOfRenter',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
    },
    {
        title: 'Долг перед поставщиком',
        dataIndex: 'debtToSupplier',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 140,
        resizable: false,
        defaultHidden: true,
    },
    GridColumnCreator.createUserColumn<RenterRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.assigneeId,
            name: row.assigneeShortName,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__RESPONSIBLE),
            dataIndex: 'assigneeShortName',
        }
    ),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIVITY),
        dataIndex: 'lastActivityDate',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        resizable: false,
        render: (value: Date, rowData: InstanceRecord) => {
            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    {
        title: <SystemIcon type={SystemIconType.actions} style={{ display: 'block' }} />,
        type: ColumnTypes.Actions,
        id: 'actions',
        width: GRID_ACTIONS_COLUMN_WIDTH,
        maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
        minWidth: GRID_ACTIONS_COLUMN_WIDTH,
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
    },
];

export default counterpartyColumns;
