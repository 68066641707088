import {Icon} from "antd";
import {IconVolumeOffSolid, IconVolumeUpSolid} from "../../icons";
import {updateBarcodeScannerSoundEffectsEnabled} from "../../../shared/reducers/userSettings/userSettings.reducer";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {barcodeScannerSoundEffectsEnabledSelector} from "../../../shared/reducers/userSettings/userSettings.selectors";

export const ToggleScanSoundButton = () => {
    const dispatch = useAppDispatch();
    const barcodeScannerSoundEffectsEnabled = useAppSelector(barcodeScannerSoundEffectsEnabledSelector);

    return <Icon component={barcodeScannerSoundEffectsEnabled ? IconVolumeUpSolid : IconVolumeOffSolid} style={{fontSize: 22, color: barcodeScannerSoundEffectsEnabled ? '#21bfa4' : '#abacb0', userSelect: 'none', marginRight: 14}} onClick={()=>{
        dispatch(updateBarcodeScannerSoundEffectsEnabled(!barcodeScannerSoundEffectsEnabled));
    }} />;
}
