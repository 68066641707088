import React, { FC } from 'react';
import { EntityIndicatorsBlock, EntityIndicatorsBlockProps } from './entityIndicatorsBlock';
import { SystemIcon, SystemIconType } from '../v2/systemIcon/systemIcon';
import { CurrentSubrentIndicatorsTab } from './currentSubrentIndicatorsTab';
import { CurrentRentIndicatorsEntity, CurrentRentIndicatorsEntityType } from './currentRentIndicatorsBlock';
import classNames from 'classnames';

export interface EntitySubrentIndicatorsBlockProps {
    entityType: CurrentRentIndicatorsEntityType;
    entity: CurrentRentIndicatorsEntity;
    storageKey: EntityIndicatorsBlockProps['storageKey'];
    className?: string;
}

export const EntitySubrentIndicatorsBlock: FC<EntitySubrentIndicatorsBlockProps> = ({ entityType, entity, storageKey, ...props }) => {
    const { rentIndicators } = entity;
    let problem: 'error' | 'warning' | undefined;
    let className: string | undefined;

    if (entityType !== 'project') {
        if (rentIndicators?.problemsAndWarnings.subrentShipmentDelay.elementsCount) {
            problem = 'error';
            className = 'tab-header-subrent-hard-problem';
        } else if (rentIndicators?.problemsAndWarnings.subrentReturnToShipperDelay.elementsCount) {
            problem = 'warning';
            className = 'tab-header-subrent-problem';
        } else {
            className = 'tab-header-subrent-not-problem';
        }
    }

    return (
        <EntityIndicatorsBlock
            title={'Субаренда'}
            icon={<SystemIcon type={SystemIconType.subrent} />}
            problem={problem}
            className={classNames(className, props.className)}
            storageKey={storageKey}
        >
            <CurrentSubrentIndicatorsTab entity={entity} entityType={entityType} />
        </EntityIndicatorsBlock>
    );
};
