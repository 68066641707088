import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError } from 'axios';
import {
    DocumentTemplateInfoCreate,
    DocumentTemplateInfoRead,
    DocumentTemplateInfoUpdate,
    DocumentTemplateRecordList,
    serverApi,
} from '../../../../server';
import { axiosBaseQuery } from '../../../../store/rtqQueryApi';

const DOCUMENT_TEMPLATE_TAG = 'DOCUMENT_TEMPLATE_TAG' as const;

interface ListDocumentTemplatesParams {
    businessAccountId: number;
    limit?: number;
    startFrom?: number;
    filters?: Array<string>;
    sortBy?: string;
    sortOrder?: 'ASC' | 'DESC';
    search?: string;
    showHidden?: boolean;
}

interface GetDocumentTemplateParams {
    businessAccountId: number;
    id: number;
}

interface CreateDocumentTemplateParams {
    businessAccountId: number;
    file: any;
    data: DocumentTemplateInfoCreate;
    options?: any;
}

interface UpdateDocumentTemplateParams {
    businessAccountId: number;
    id: number;
    file: any;
    ignoreFile: boolean;
    data: DocumentTemplateInfoUpdate;
    options?: any;
}

export const documentTemplatesApi = createApi({
    reducerPath: 'documentTemplatesApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: [DOCUMENT_TEMPLATE_TAG],
    endpoints: (builder) => ({
        listDocumentTemplates: builder.query<DocumentTemplateRecordList, ListDocumentTemplatesParams>({
            queryFn: async (params) => {
                try {
                    const response = await serverApi.listDocumentTemplates(
                        params.businessAccountId,
                        params.limit,
                        params.startFrom,
                        params.filters,
                        params.sortBy,
                        params.sortOrder,
                        params.search,
                        params.showHidden
                    );
                    return { data: response.data };
                } catch (e) {
                    return { error: e as AxiosError };
                }
            },
            providesTags: (result, error, arg) => [
                DOCUMENT_TEMPLATE_TAG,
                ...(result ? result.records.map(({ id }) => ({ type: DOCUMENT_TEMPLATE_TAG, id })) : []),
            ],
            // forceRefetch: ({ currentArg, previousArg , state, endpointState}) => {
            //
            //     console.log('RRR', currentArg, previousArg, state, endpointState);
            //     return !true;//return currentArg !== previousArg
            // },
        }),
        getDocumentTemplate: builder.query<DocumentTemplateInfoRead, GetDocumentTemplateParams>({
            queryFn: async (params) => {
                try {
                    const response = await serverApi.getDocumentTemplateById(params.businessAccountId, params.id);
                    return { data: response.data };
                } catch (e) {
                    return { error: e as AxiosError };
                }
            },
            providesTags: (result, error, arg) => [{ type: DOCUMENT_TEMPLATE_TAG, id: arg.id }],
        }),
        createDocumentTemplate: builder.mutation<DocumentTemplateInfoRead, CreateDocumentTemplateParams>({
            queryFn: async (params) => {
                try {
                    const response = await serverApi.createDocumentTemplate(
                        params.businessAccountId,
                        params.file,
                        params.data,
                        params.options
                    );
                    return { data: response.data };
                } catch (e) {
                    return { error: e as AxiosError };
                }
            },
            invalidatesTags: [DOCUMENT_TEMPLATE_TAG],
        }),
        updateDocumentTemplate: builder.mutation<DocumentTemplateInfoRead, UpdateDocumentTemplateParams>({
            queryFn: async (params) => {
                try {
                    const response = await serverApi.updateDocumentTemplate(
                        params.businessAccountId,
                        params.id,
                        params.file,
                        params.ignoreFile,
                        params.data,
                        params.options
                    );
                    return { data: response.data };
                } catch (e) {
                    return { error: e as AxiosError };
                }
            },
            invalidatesTags: (result, error, arg) => [{ type: DOCUMENT_TEMPLATE_TAG, id: arg.id }],
        }),
    }),
});

export const {
    useListDocumentTemplatesQuery,
    useGetDocumentTemplateQuery,
    useCreateDocumentTemplateMutation,
    useUpdateDocumentTemplateMutation,
} = documentTemplatesApi;
