import React, { FC, memo } from 'react';
import { inventoryPageUrlRoute } from '../../inventory-page';
import { EntityList } from '../../../../../components/page/entityGrid/components/ui/EntityList/EntityList';
import { productsListColumns } from '../columns/productsColumns';
import { productsFilters } from '../filters/productsFilters';
import { useAppSelector } from '../../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { EntityGridRootPath, ListParams } from '../../../../../components/page/entityGrid/types/params';
import { useListAvailableFiltersQuery, useListProductsQuery } from '../api/products.api';
import {
    usePageURLParamsFromDescription,
    useQueryParamsWithoutModalParams,
} from '../../../../../components/page/entityGrid/hooks/useParams';
import { productsFiltersDescription } from '../filters/productsFilters.description';
import { withEntityListProviders } from '../../../../../components/page/entityGrid/components/decorators/withEntityListProviders';
import { useExcludedValues } from '../hooks/useExcludedValues';
import { useExportBlock } from '../hooks/useExportBlock';
import { useCategoriesProps } from '../hooks/useCategoriesProps';
import { useActionsButtons } from '../hooks/actionButtons/useActionsButtons';
import { useSelectedRecords } from '../../../../../components/page/entityGrid/hooks/useSelectedRecords';
import { useEntitySelection } from '../../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import { NomenclatureRecordCustom } from '../api/products.api.types';
import { useProductsEntityActions } from '../hooks/entityActions/useProductsEntityActions';
import { useLocation } from 'react-router-dom';
import { useCustomFieldsData } from '../../../../../components/page/entityGrid/hooks/useCustomFieldsData';
import { useIntl } from 'react-intl';

import { InventoryPageTabsEnum } from '../../../../../shared/constants/tabEnums';

interface ProductsListProps {}

const initialValues: Partial<ListParams> = {
    //sortBy: 'lastActivityDate',
    //sortOrder: 'DESC',
};

const gridName = InventoryPageTabsEnum.PRODUCTS;

const ProductsListComponent: FC<ProductsListProps> = memo(() => {
    const intl = useIntl();
    const location = useLocation();
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const entitySelection = useEntitySelection();
    const entitiesLoading = useAppSelector((store) => store.products.loading);

    const rootPath = `/${businessAccountId}/${inventoryPageUrlRoute}/${gridName}` satisfies EntityGridRootPath;

    const { data: { customFieldMarkers, availableFiltersValues } = {}, isLoading: isAvailableFilterLoading } = useListAvailableFiltersQuery(
        {
            search: location.search,
        }
    );

    const { customFiltersRequestFilters, actualPageParamsDescription } = useCustomFieldsData(
        customFieldMarkers,
        productsFiltersDescription
    );

    const pageParamsObject = usePageURLParamsFromDescription({
        pageParamsDescription: actualPageParamsDescription,
        gridName,
        initialValues,
    });
    const { pageParams } = pageParamsObject;

    const queryParams = useQueryParamsWithoutModalParams(pageParams);

    const queryData = useListProductsQuery(
        {
            intl,
            params: queryParams,
            customFiltersRequestFilters,
            availableFiltersValues,
            customFieldMarkers,
        },
        {
            skip: isAvailableFilterLoading,
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const { excludeColumns, filtersExcludeFields } = useExcludedValues(pageParams);

    const exportBlock = useExportBlock(pageParams);

    const categoriesProps = useCategoriesProps(pageParamsObject);

    const selectedRecords = useSelectedRecords<NomenclatureRecordCustom>(entitySelection, queryData.data?.entitiesData.records);
    const actionButtons = useActionsButtons(selectedRecords, pageParams);

    const entityActions = useProductsEntityActions({
        rootPath,
    });

    return (
        <EntityList
            pageParamsObject={pageParamsObject}
            gridProps={{
                static: {
                    columns: productsListColumns,
                    filtersData: productsFilters,
                    pageParamsDescription: actualPageParamsDescription,
                },
                metaData: {
                    rootPath,
                    entityType: 'product',
                    gridName,
                },
                categoriesProps,
                queryData,
                entityActions,
                entitiesLoading,
                filtersExcludeFields,
                excludeColumns,
                actionButtons,
                hideArchive: pageParams.hideArchive,
                exportBlock,
                customFieldsInsertIndex: 6,
                pinned: { right: ['actions'] },
            }}
        />
    );
});

export const ProductsList: FC<ProductsListProps> = withEntityListProviders(ProductsListComponent);
