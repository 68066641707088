import React from 'react';
import { Link } from 'react-router-dom';
import { GridColumnCreator } from '../../../../../../components/grid/gridColumnCreator';
import { ColumnTypes, TableColumn } from '../../../../../../components/grid/Table';
import { InstanceRecord, ProfessionRecord } from '../../../../../../server';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { tableCellMoneyRenderer } from '../../../../../../components/grid/renderers/tableCellMoneyRenderer';
import DashboardDate from '../../../../../../components/dashboardDate/dashboardDate';
import { PricingSchemePricePopoverWrapper } from './components/PricingSchemePricePopoverWrapper';
import { ProfessionStateBadge } from '../components/professionStateBadge';
import { MAX_WIDTH } from '../../../../../../config/constants';

const externalCodeSizes = GridColumnCreator.getColumnSizes(30);

export const professionsListColumns: TableColumn<ProfessionRecord>[] = [
    GridColumnCreator.createImageColumn<ProfessionRecord>({
        dataIndex: 'mainImage',
    }),
    GridColumnCreator.createShortNameColumn<ProfessionRecord>('profession', (row) => ({
        baId: row.businessAccountId,
        id: row.id,
        name: row.shortName,
    })),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__EXTERNAL_CODE),
        dataIndex: 'externalCode' as any,
        type: ColumnTypes.CustomRender,
        minWidth: externalCodeSizes.min,
        width: externalCodeSizes.width,
        maxWidth: externalCodeSizes.max,
        resizable: true,
        render: (value, row: ProfessionRecord) => {
            const { externalCode: code } = row;
            const link = undefined;

            return (
                <div title={code} className={'rr-grid-text-cell-left'}>
                    {link ? <Link to={link}>{code}</Link> : <span>{code}</span>}
                </div>
            );
        },
    },
    {
        title: 'Статус',
        dataIndex: 'stateCode',
        type: ColumnTypes.CustomRender,
        width: 130,
        maxWidth: MAX_WIDTH(130),
        resizable: false,
        render: (value, row: ProfessionRecord) => <ProfessionStateBadge type={row.stateCode} style={{ width: '100%' }} />,
    },
    {
        title: 'Кол-во сотрудников',
        dataIndex: 'ownActiveCrewMemberCount',
        type: ColumnTypes.String,
        minWidth: 120,
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__PRICE_PER_SHIFT),
        dataIndex: 'pricePerShift',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        resizable: true,
        render: (value: number | undefined, rowData: ProfessionRecord) => {
            const { pricingScheme } = rowData;

            return (
                <>
                    {pricingScheme?.id ? <PricingSchemePricePopoverWrapper pricingSchemeId={pricingScheme.id} value={value} /> : null}
                    {tableCellMoneyRenderer(value)}
                </>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIVITY),
        dataIndex: 'lastActivityDate',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        resizable: false,
        render: (value: Date, rowData: InstanceRecord) => {
            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    GridColumnCreator.createActionsColumn<ProfessionRecord>({ id: 'actions' }),
];
