import React from "react";
import {Icon} from "antd";
import moment from "moment";
import {TimePicker} from "../dateTimePicker/timePicker";
import {IconClose} from "../icons";
import './timeStringInput.less';

interface TimeStringInputProps {
    value?: string;
    onChange?: (value?: string) => void;
}

export const TimeStringInput = (props: TimeStringInputProps) => {

    const v = props.value ? moment(props.value, 'HH:mm:ss') : undefined;

    return (
        <div style={{display: 'inline-flex', alignItems: 'center', gap: 10}}>
            <TimePicker
                value={v}
                onChange={(value) => {
                    props.onChange?.(value ? value.format('HH:mm') : undefined);
                }}
                style={{width: 132}}
                timeInputStyle={{fontSize: 14, fontWeight: 400}}
            />
            {
                props.value && (
                    <Icon className={'rr-time-input-reset-icon'} component={IconClose} onClick={(e)=>{
                        props.onChange?.(undefined);
                    }} />
                )
            }

        </div>
    );
}
