import { NomenclatureStateCodeEnum } from '../../../../../server';
import { FormItemType } from '../../../../../components/dynamicForm/DynamicForm';
import { LocalizationEnum, localize } from '../../../../../localization';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import { ProblemsFilter, ProblemsFilterProps } from '../../../../../components/select/problemsFilter';
import { EntityRemoteSelect } from '../../../../../components/select/EntityRemoteSelect';
import { SliderRangeWithManualInput } from '../../../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import { ContactSelect } from '../../../../../components/v2/select/contactSelect/contactSelect';

export const productsFilters = [
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
                helpTooltip: LocalizationEnum.ASPECT__FIELDS__LEGAL_ATTRIBUTES__LEGAL_ENTITY,
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'status',
                type: FormItemType.Select,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                values: [
                    { name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__NEW), value: NomenclatureStateCodeEnum.NEW },
                    { name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__ACTIVE), value: NomenclatureStateCodeEnum.ACTIVE },
                    {
                        name: localize(LocalizationEnum.ASPECT__STATE_CODE__PRODUCT__NOTAVAILABLE),
                        value: NomenclatureStateCodeEnum.NOTAVAILABLE,
                    },
                ].map((item) => {
                    return {
                        name: (
                            <>
                                <div className={`rr-dot rr-status-bg-` + item.value} style={{ marginRight: '10px' }}></div>{' '}
                                <span className="calendar-multi-select">{item.name}</span>{' '}
                            </>
                        ),
                        value: item.value,
                    };
                }),
            },
            {
                label: 'Скрыть варианты',
                id: 'hideVariants',
                type: FormItemType.Switch,
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__HIDE_ARCHIVE,
                id: 'hideArchive',
                type: FormItemType.Switch,
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FILTERS__PROBLEM,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'problem',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: ProblemsFilter,
                componentProps: (form: WrappedFormUtils): ProblemsFilterProps => ({
                    typeStatuses: 'all',
                }),
            },
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__PRICE_PER_SHIFT,
                id: 'pricePerShift',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: {
                    formatStyle: 'currency',
                    min: 0,
                    max: 1000000,
                    step: 50,
                },
            },
            {
                label: 'Содержит экземпляр',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'instanceId',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: EntityRemoteSelect,
                componentProps: (form: WrappedFormUtils) => {
                    return {
                        operationName: 'listInstances',
                        nameField: 'nameOrCode',
                        sortBy: 'lastActivityDate',
                        getEntityOperationName: 'getInstanceById',
                        sortOrder: 'DESC',
                        showSearch: true,
                    };
                },
            },
            {
                label: 'Контакт',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                id: 'contactId',
                type: FormItemType.Component,
                component: ContactSelect,
                style: { minWidth: 220 },
            },
        ],
    },
];
