import React, { createRef, ReactNode, RefObject } from 'react';
import { Button, Icon, Select } from 'antd';
import { connect } from 'react-redux';
import { IconShapesSolid, IconTags } from '../../../../../components/icons';
import { IRootState } from '../../../../../shared/reducers';
import { EntityRemoteSelect } from '../../../../../components/select/EntityRemoteSelect';
import { CustomFieldRecord } from '../../../../../server';
import { SelectValue } from 'antd/lib/select';
import { Styles } from '../../../../../styles';
import './CustomFieldAddPopover.less';
import { LocalizationEnum, localize } from '../../../../../localization';
import { Popover } from '../../../../../components/popover/Popover';
import ReactDOM from 'react-dom';

interface IProps extends StateProps, DispatchProps {
    children: ReactNode;
    groupId: number;
    onCreate: (groupId: number, field: CustomFieldRecord[]) => void;
    variantFieldIds: number[];
    productFieldIds?: number[];
    showIcons?: boolean;
    forProduct?: boolean;
    multiple?: boolean;
}

interface IState {
    visible: boolean;
    value?: SelectValue;
}

class _CustomFieldAddPopover extends React.Component<IProps, IState> {
    _ref: RefObject<Select>;

    constructor(props) {
        super(props);

        this._ref = createRef();

        this.state = {
            visible: false,
            value: undefined,
        };
    }

    componentDidUpdate(_: IProps, prevState: IState) {
        if (this.state.visible && this.state.visible !== prevState.visible) {
            setTimeout(() => {
                const node = ReactDOM.findDOMNode(this._ref.current);
                if (node != null) {
                    const event = new MouseEvent('click', { bubbles: true });
                    node.dispatchEvent(event);
                }
            }, 250);
        }
    }

    handleVisibleChange = (visible: boolean) => {
        if (!visible) {
            this.setState({ visible, value: undefined });
        } else {
            this.setState({ visible });
        }
    };

    onButtonClick = async (e) => {
        e.stopPropagation();

        let fields: any[] = [];
        if (Array.isArray(this.state.value)) {
            fields = (this.state.value as any[]).map((item) => item.data);
        } else {
            fields = [(this.state.value as any).data];
        }

        if (this.props.onCreate) this.props.onCreate(this.props.groupId, fields);
        this.handleVisibleChange(false);
    };

    render() {
        const { visible } = this.state;

        return (
            <Popover
                visible={visible}
                onVisibleChange={this.handleVisibleChange}
                overlayClassName={'rr-operationForm-kit-add-element-popover'}
                headerClassName={'rr-grid-actions-popover-header-container'}
                destroyTooltipOnHide
                header={
                    <>
                        <EntityRemoteSelect
                            _ref={this._ref}
                            multiple={this.props.multiple}
                            maxTagCount={3}
                            getPopupContainer={(trigger) => trigger.parentNode as any}
                            showSearch
                            placeholder={localize(LocalizationEnum.PAGE__PRODUCTS__SELECT_FIELD_BUTTON)}
                            style={{ width: '100%' }}
                            onChange={(value: SelectValue) => {
                                if (value) this.setState({ value: value });
                                else this.setState({ value: value });
                            }}
                            value={this.state.value}
                            operationName={'listCustomFields'}
                            nameField={'shortName'}
                            renderer={(data, index) => {
                                const disabled =
                                    this.props.variantFieldIds.includes(data['id']) ||
                                    (this.props.forProduct &&
                                        this.props.productFieldIds &&
                                        this.props.productFieldIds.includes(data['id']));
                                return (
                                    <Select.Option disabled={disabled} key={data['id']}>
                                        {this.props.productFieldIds && this.props.productFieldIds.includes(data['id']) ? (
                                            <span className={'rr-customField-select-list-item' + (this.props.forProduct ? '' : ' ')}>
                                                <Icon
                                                    component={IconTags}
                                                    style={{ color: '#525a95' }}
                                                    className={`rr-customField-select-list-item-icon ${Styles.ICON_20}`}
                                                />{' '}
                                                {data['data']['standard']}
                                                {data['name']}
                                            </span>
                                        ) : (
                                            <span className={'rr-customField-select-list-item'}>
                                                {this.props.showIcons && this.props.variantFieldIds.includes(data['id']) ? (
                                                    <>
                                                        <Icon
                                                            component={IconShapesSolid}
                                                            className={`rr-customField-select-list-item-icon ${Styles.ICON_20}`}
                                                        />{' '}
                                                    </>
                                                ) : null}
                                                {data['data']['standard']}
                                                {data['name']}
                                            </span>
                                        )}
                                    </Select.Option>
                                );
                            }}
                        />
                        <Button
                            className={'rr-btn-blue'}
                            style={{ marginLeft: 8 }}
                            onClick={this.onButtonClick}
                            disabled={!this.state.value}
                        >
                            OK
                        </Button>
                    </>
                }
            >
                {this.props.children}
            </Popover>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const CustomFieldAddPopover = connect(mapStateToProps, mapDispatchToProps)(_CustomFieldAddPopover);
