import React, { FC } from 'react';
import { Card, Col, Row } from 'antd';
import { AbstractEntityInfoCard } from '../../../../../../../components';
import { EntityRentIndicatorsBlock } from '../../../../../../../components/currentRentIndicators/entityRentIndicatorsBlock';
import {
    ProjectPageDescriptionAttachmentsBlock,
    ProjectPageDescriptionContractBlock,
    ProjectPageDescriptionDescriptionBlock,
    ProjectPageDescriptionLeftBlock,
    ProjectPageDescriptionPeriodAndLocationBlock,
    ProjectPageDescriptionPriceBlock,
} from './blocks';
import { ProjectInfoRead, ProjectTypeCodeEnum } from '../../../../../../../server';
import { ProjectPageDescriptionPriceOfferBlock } from './blocks/projectPageDescriptionOfferPriceBlock';
import { useAppDispatch } from '../../../../../../../store/hooks';
import { loadEntity } from '../../../reducers/project.reducer';
import { ProjectPageDescriptionContactsBlock } from './blocks/projectPageDescriptionContactsBlock';
import PledgeData from '../../../components/Pledge/PledgeData';

interface ProjectPageDescriptionTabPaneProps {
    entity: ProjectInfoRead | null;
    isOffer?: boolean;
}

export const ProjectPageDescriptionTabPane: FC<ProjectPageDescriptionTabPaneProps> = (props) => {
    const { entity: data } = props;
    const dispatch = useAppDispatch();

    return data ? (
        <Row gutter={32} className={'rr-project-page-description'}>
            {/* Левый блок */}
            <ProjectPageDescriptionLeftBlock data={data} />

            {/* Правый блок блок */}
            <Col xxl={18} xl={16} lg={14} span={24}>
                <Card bordered={false} className={'rr-card-without-padding'}>
                    <Row style={{ marginLeft: 8, marginRight: 8 }}>
                        <Col>
                            <Row gutter={16} type={'flex'}>
                                <Col xxl={12} xl={24} lg={24} span={24} className={'rr-page-renter-block-with-right-border'}>
                                    {data.projectType === ProjectTypeCodeEnum.BASE && (
                                        <Row gutter={16} style={{ padding: '0px', borderBottom: '1px solid #eaeaea' }}>
                                            <EntityRentIndicatorsBlock
                                                entityType={'project'}
                                                entity={data}
                                                storageKey={'project-rent'}
                                                costOfRent={data.costOfRent}
                                            />
                                        </Row>
                                    )}

                                    {data.projectType === ProjectTypeCodeEnum.BASE ? (
                                        <ProjectPageDescriptionPriceBlock data={data} />
                                    ) : (
                                        <ProjectPageDescriptionPriceOfferBlock data={data} />
                                    )}

                                    {data.projectType === ProjectTypeCodeEnum.BASE && (
                                        <ProjectPageDescriptionPeriodAndLocationBlock data={data} />
                                    )}
                                </Col>
                                <Col xxl={12} xl={24} lg={24} span={24} className={'rr-page-renter-block-with-top-border'}>
                                    {data.projectType === ProjectTypeCodeEnum.BASE && (
                                        <ProjectPageDescriptionContractBlock
                                            data={data}
                                            updateParentEntity={() => {
                                                dispatch(loadEntity(data.businessAccountId, data.id));
                                            }}
                                        />
                                    )}
                                    {data.projectType === ProjectTypeCodeEnum.BASE && <ProjectPageDescriptionContactsBlock data={data} />}
                                    <ProjectPageDescriptionAttachmentsBlock
                                        data={data}
                                        updateParentEntity={() => {
                                            dispatch(loadEntity(data.businessAccountId, data.id));
                                        }}
                                    />

                                    {!props.isOffer && (
                                        <PledgeData
                                            data={data.pledge}
                                            debtSum={data.debtSum}
                                            style={{ padding: '32px 24px', borderBottom: '1px solid #eaeaea', margin: '0 -8px' }}
                                            isSimpleOrder={data.isSimpleOrder}
                                        />
                                    )}
                                    <ProjectPageDescriptionDescriptionBlock data={data} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>

                <AbstractEntityInfoCard leftBlockSpanLG={24} data={data} style={{ marginTop: 32 }} />
            </Col>
        </Row>
    ) : null;
};
