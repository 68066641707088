import { ColumnTypes, TableColumn } from '../../../components/grid/Table';
import { Link } from 'react-router-dom';
import { OperationRecord, RentActivityFrameTypeCodeEnum } from '../../../server/api';
import { OperationType } from '../../../components/operationType/operationType';
import { LocalizationEnum, localize } from '../../../localization';
import DashboardDate from '../../../components/dashboardDate/dashboardDate';
import { RentElementsGridItem } from '../../../types';
import { EntityType } from '../../../../index';
import { tableCellStatusRenderer } from '../../../components/grid/renderers/tableCellStatusRenderer';
import { SystemIcon, SystemIconType } from '../../../components/v2/systemIcon/systemIcon';
import { OperationRecordActionsPopover } from './operationRecordActionsPopover';
import { createActionsColumn } from '../../../components/grid/columnUtils';
import { GridColumnCreator } from '../../../components/grid/gridColumnCreator';

export const columns: TableColumn<OperationRecord>[] = [
    GridColumnCreator.createProblemSeverityColumn(),
    {
        title: 'Тип',
        dataIndex: 'typeCode',
        type: ColumnTypes.CustomRender,
        dontHide: true,
        minWidth: 190,
        // maxWidth: MAX_WIDTH(150),
        resizable: false,
        render: (value: any, rowData: OperationRecord) => (
            <div className="rr-grid-shortName-cell">
                <Link to={`/${rowData.businessAccountId}/history/operations/${rowData.id}`}>
                    <OperationType code={value} />
                </Link>
            </div>
        ),
    },
    {
        title: localize(LocalizationEnum.PAGE__OPERATIONS__CARD__TARGET_ELEMENTS_STATE),
        dataIndex: 'targetStateCode',
        type: ColumnTypes.CustomRender,
        minWidth: 170,
        // maxWidth: MAX_WIDTH(110),
        resizable: false,
        render: (value: any, rowData: RentElementsGridItem, onAction, entityType: EntityType) => {
            return value ? tableCellStatusRenderer(value, rowData, entityType) : null;
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__ENTITY_TYPE__ELEMENTS),
        dataIndex: 'operationIndicators.totalElementsCount',
        type: ColumnTypes.CustomRender,
        minWidth: 160,
        //maxWidth: MAX_WIDTH(160),
        resizable: false,
        render: (value: any, rowData: OperationRecord) => (
            <div className={'rr-grid-shortName-cell'} title={rowData.mnemoKey} style={{ display: 'flex', justifyContent: 'center' }}>
                <Link to={`/${rowData.businessAccountId}/history/operations/${rowData.id}?tab=elements`}>{value}</Link>
            </div>
        ),
    },
    GridColumnCreator.createShortNameColumn<OperationRecord>(
        'operation',
        (row) => ({
            baId: row.businessAccountId,
            id: row.id,
            name: row.mnemoKey,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__CODE),
            dataIndex: 'mnemoKey',
            dontHide: false,
            defaultHidden: true,
        }
    ),
    GridColumnCreator.createCounterpartyColumn<OperationRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.counterpartyId,
            name: row.counterpartyShortName,
        }),
        {
            title: 'Контрагент',
            dataIndex: 'counterpartyShortName',
        }
    ),
    GridColumnCreator.createActivityFrameColumn<OperationRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.projectId,
            name: row.projectShortName,
            activityFrameTypeCode: RentActivityFrameTypeCodeEnum.PROJECT,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__PROJECT),
            dataIndex: 'projectShortName',
        }
    ),
    GridColumnCreator.createActivityFrameColumn<OperationRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.subrentId,
            name: row.subrentShortName,
            activityFrameTypeCode: RentActivityFrameTypeCodeEnum.SUBRENT,
        }),
        {
            title: 'Поставка',
            dataIndex: 'subrentShortName',
        }
    ),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__DISCOUNT),
        dataIndex: 'operationIndicators.totalDiscount',
        type: ColumnTypes.Percents,
        minWidth: 90,
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__SUM),
        dataIndex: 'operationIndicators.finalTotalPrice',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 132,
        resizable: false,
    },
    GridColumnCreator.createUserColumn<OperationRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.creationAuthorId,
            name: row.creationAuthorShortName,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__AUTHOR),
            dataIndex: 'creationAuthorShortName',
        }
    ),
    {
        title: localize(LocalizationEnum.PAGE__OPERATIONS__LIST__GRID__COLUMN__CREATION_DATE),
        dataIndex: 'creationDate',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        resizable: true,
        render: (value: Date, rowData: OperationRecord) => {
            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    createActionsColumn<OperationRecord>((value, rowData, onAction) => {
        return (
            <OperationRecordActionsPopover
                onAction={(action) => {
                    onAction(rowData, action);
                }}
            >
                <SystemIcon type={SystemIconType.otherActions} className={'rr-grid-table-otherActions-icon'} />
            </OperationRecordActionsPopover>
        );
    }, 'actions'),
];
