import { ContactRecord } from '../../../../../../server';
import React, { useCallback, useMemo } from 'react';
import every from 'lodash/every';
import RoundButton from '../../../../../../components/button/roundButton';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { Icon } from 'antd';
import { IconArchive, IconLevelUp, IconTrash } from '../../../../../../components/icons';
import { useArchiveContactsMutation, useDeleteContactsMutation } from '../api/contactsApi';
import { _Grid } from '../../../../../../components/grid/Grid';
import JoinContactAction from '../../../../../../components/additionalEntityActions/JoinContactAction';

export const useContactEntityActionsButtons = ({
    selectedRecords,
    gridRef,
}: {
    selectedRecords: ContactRecord[];
    gridRef: React.RefObject<_Grid>;
}) => {
    const [archiveContacts, { isLoading: isArchiveContactsLoading }] = useArchiveContactsMutation();

    const [deleteContacts, { isLoading: isDeleteContactsLoading }] = useDeleteContactsMutation();

    const isLoading = isArchiveContactsLoading || isDeleteContactsLoading;

    const clearSelection = useCallback(() => {
        gridRef?.current?.clearSelection();
    }, [gridRef]);

    const deleteSelectedContacts = useCallback(() => {
        const versionedSelectedEntitiesObjects = selectedRecords.map(({ id, businessVersion }) => {
            return {
                id,
                businessVersion,
            };
        });

        deleteContacts({
            versionedEntityObjList: {
                entities: versionedSelectedEntitiesObjects,
            },
        });

        gridRef?.current?.clearSelection();
    }, [deleteContacts, gridRef, selectedRecords]);

    const archiveSelectedContacts = useCallback(() => {
        archiveContacts({
            workflowDirectiveBulk: {
                directives: selectedRecords.map(({ id, archive, businessVersion }) => {
                    return {
                        id,
                        archive: !archive,
                        businessVersion,
                    };
                }),
            },
        });

        gridRef?.current?.clearSelection();
    }, [archiveContacts, gridRef, selectedRecords]);

    return useMemo(
        () => ({
            buttons: [
                every(selectedRecords, (item: ContactRecord) => !item.archive && item.archivable) ? (
                    <RoundButton
                        key={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                        title={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                        onClick={archiveSelectedContacts}
                        colorScheme={'TO_ARCHIVE'}
                    >
                        <Icon component={IconArchive} />
                        <span>{localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}</span>
                    </RoundButton>
                ) : null,

                every(selectedRecords, (item: ContactRecord) => item.archive) ? (
                    <RoundButton
                        key={LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE}
                        title={LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE}
                        onClick={archiveSelectedContacts}
                        colorScheme={'FROM_ARCHIVE'}
                    >
                        <Icon component={IconLevelUp} />
                        <span>{localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}</span>
                    </RoundButton>
                ) : null,

                every(selectedRecords, (item: ContactRecord) => item.deleteable) ? (
                    <RoundButton
                        key={LocalizationEnum.ASPECT__ACTION__DELETE}
                        title={'Удалить'}
                        onClick={deleteSelectedContacts}
                        colorScheme={'DELETE'}
                    >
                        <Icon component={IconTrash} />
                        <span>{localize(LocalizationEnum.ASPECT__ACTION__DELETE)}</span>
                    </RoundButton>
                ) : null,
                selectedRecords.length > 1 ? <JoinContactAction selectedRecords={selectedRecords} clearSelection={clearSelection} /> : null,
            ],
            isLoading,
        }),
        [archiveSelectedContacts, deleteSelectedContacts, isLoading, selectedRecords]
    );
};
