import { ReactNode, useEffect, useState } from 'react';
import { ContactDrawer } from '../../../modules/main/renters/tabs/contacts/drawer/components/ContactDrawer/ContactDrawer';
import { CrewMemberRecord } from '../../../server';
import { businessAccountIdSelector } from '../../../shared/reducers/system.reducer';
import { useAppSelector } from '../../../store/hooks';
import DetailedSelect from './DetailedSelect';
import { useDebounce } from '../../../core/hooks/useDebounce';
import { useLazyListCrewMembersQuery, useListCrewMembersQuery } from '../../../modules/main/crew/tabs/crewMembers/api/crewMembers.api';
import { BaseSelectOptionProps } from '../select/baseSelect';
import { useContactActionsFromDrawer } from '../../../modules/main/renters/tabs/contacts/hooks/useContactActionsFromDrawer';

interface Filters {
    hideArchive?: boolean;
    professionId?: number;
}

export interface CrewMemberDetailedSelectProps<T extends {}> {
    id: string;
    placeholder?: string;
    value: number[];
    onChange: (value: number[]) => void;
    multiple?: boolean;
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
    getOptionProps?: (record: T) => BaseSelectOptionProps;
    getOptionPrefix?: (record: T) => ReactNode | null;
    onDataChange?: (data: T[]) => void;
    filters?: Filters;
}

const CrewMemberDetailedSelect = ({
    id,
    placeholder = 'Выберите работника',
    value,
    onChange,
    multiple,
    getPopupContainer,
    getOptionProps,
    onDataChange,
    filters,
    getOptionPrefix,
}: CrewMemberDetailedSelectProps<CrewMemberRecord>) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const [selectedId, setSelectedId] = useState<number>();
    const [search, setSearch] = useState<string>();
    const debouncedSearchString = useDebounce<string | undefined>(search, 200);

    const { data, isLoading } = useListCrewMembersQuery(
        {
            businessAccountId,
            params: {
                search: debouncedSearchString ? debouncedSearchString : undefined,
                limit: 100,
                page: 1,
                sortBy: 'lastUpdateDate',
                sortOrder: 'DESC',
                professionId: filters?.professionId,
            },
        },
        { refetchOnMountOrArgChange: true }
    );

    const {
        edit: editAction,
        delete: deleteAction,
        archive: archiveAction,
        isLoading: isEntityActionsLoading,
    } = useContactActionsFromDrawer();

    const [getData] = useLazyListCrewMembersQuery();

    useEffect(() => {
        getData({ businessAccountId, params: { limit: 100, page: 1, sortBy: 'lastUpdateDate', sortOrder: 'DESC' } }).then((res) => {
            const records = res.data?.entitiesData.records || [];
            const selectedData = records.filter((r) => value?.includes(r.id));
            onDataChange?.(selectedData);
        });
    }, [businessAccountId, getData, value]);

    return (
        <>
            <DetailedSelect<CrewMemberRecord>
                allowClear
                id={id}
                placeholder={placeholder}
                items={data?.entitiesData.records ?? []}
                columns={[
                    { key: 'personShortName', width: 240 },
                    { key: 'phoneNumber', width: 150 },
                    { key: 'email', width: 200, secondary: true },
                ]}
                getKey={(item) => item.id}
                value={value}
                onChange={onChange}
                onSearch={(value: string) => setSearch(value)}
                details={{
                    text: 'Смотреть',
                    clickHandler: (id: number | undefined) => setSelectedId(id),
                    getKey: (item) => item.contactId,
                }}
                loading={isLoading}
                selectedId={selectedId}
                multiple={multiple}
                getPopupContainer={getPopupContainer}
                getOptionProps={getOptionProps}
                hideArchive={filters?.hideArchive}
                getOptionPrefix={getOptionPrefix}
            />
            {selectedId && (
                <ContactDrawer
                    contactId={selectedId}
                    onClose={() => setSelectedId(undefined)}
                    archiveContact={archiveAction}
                    deleteContact={deleteAction}
                    editContact={editAction}
                    isEntityActionsLoading={isEntityActionsLoading}
                />
            )}
        </>
    );
};
export default CrewMemberDetailedSelect;
