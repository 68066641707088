import React, { FC, memo, useEffect } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { subrentModuleEnabledSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { Card, Col, Row } from 'antd';
import { canViewFinancialDataSelector } from '../../../../../shared/reducers/permissions.reducer';
import { DashboardSubrentDelayWidget } from './components/widgets/delay/DashboardSubrentDelayWidget';
import { DashboardSubrentDebtWidget } from './components/widgets/debt/DashboardSubrentDebtWidget';
import { DashboardSubrentBookedWidget } from './components/widgets/booked/DashboardSubrentBookedWidget';
import { DashboardSubrentSubrentedWidget } from './components/widgets/subrented/DashboardSubrentSubrentedWidget';
import { DashboardData } from '../../types/DashboardData';
import { DashboardSubrentLeftList } from '../../list/subrent/left/DashboardSubrentLeftList';
import { DashboardSubrentRightList } from '../../list/subrent/right/DashboardSubrentRightList';

interface DashboardSubrentProps {
    dashboardData: DashboardData;
    responsive: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

const MARGIN = {
    xs: 30,
    sm: 30,
    md: 30,
};

export const DashboardSubrent: FC<DashboardSubrentProps> = memo((props) => {
    const { responsive, dashboardData } = props;
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);
    const isCanViewFinancialData = useAppSelector(canViewFinancialDataSelector);

    const DelayWidget = <DashboardSubrentDelayWidget dashboardData={dashboardData} />;
    const DebtWidget = <DashboardSubrentDebtWidget dashboardData={dashboardData} />;
    const BookedWidget = <DashboardSubrentBookedWidget dashboardData={dashboardData} />;
    const SubrentedWidget = <DashboardSubrentSubrentedWidget dashboardData={dashboardData} />;

    const LeftList = <DashboardSubrentLeftList dashboardData={dashboardData} />;
    const RightList = <DashboardSubrentRightList dashboardData={dashboardData} />;

    return subrentModuleEnabled ? (
        <div>
            <Row
                type="flex"
                justify="space-between"
                align="stretch"
                gutter={20}
                style={{ marginBottom: responsive === 'sm' || responsive === 'md' ? undefined : 20 }}
            >
                <Col
                    lg={{ span: 6 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    className="rr-widget-wrapper"
                    style={{ marginBottom: responsive === 'sm' || responsive === 'md' ? 20 : undefined }}
                >
                    {DelayWidget}
                </Col>
                {isCanViewFinancialData && (
                    <Col
                        lg={{ span: 6 }}
                        md={{ span: 12 }}
                        sm={{ span: 24 }}
                        className="rr-widget-wrapper"
                        style={{ marginBottom: responsive === 'sm' || responsive === 'md' ? 20 : undefined }}
                    >
                        {DebtWidget}
                    </Col>
                )}
                <Col
                    lg={{ span: 6 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    className="rr-widget-wrapper"
                    style={{ marginBottom: responsive === 'sm' || responsive === 'md' ? 20 : undefined }}
                >
                    {BookedWidget}
                </Col>
                <Col
                    lg={{ span: 6 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    className="rr-widget-wrapper"
                    style={{ marginBottom: responsive === 'sm' || responsive === 'md' ? 20 : undefined }}
                >
                    {SubrentedWidget}
                </Col>
            </Row>
            <Row type="flex" gutter={20}>
                <Col md={{ span: 24 }} lg={{ span: 12 }}>
                    <Card bodyStyle={{ padding: '0px', paddingTop: '25px', paddingBottom: '36px' }}>{LeftList}</Card>
                </Col>
                <Col
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    style={{
                        marginTop: MARGIN[responsive],
                    }}
                >
                    <Card
                        bodyStyle={{
                            padding: '0px',
                            paddingTop: '25px',
                            paddingBottom: '36px',
                        }}
                    >
                        {RightList}
                    </Card>
                </Col>
            </Row>
        </div>
    ) : null;
});
