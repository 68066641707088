import React, { ReactNode } from 'react';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { push, replace } from 'connected-react-router';
import {
    changeOrderStateByCorrection,
    convertToProject,
    loadSimpleOrder,
    reset,
    setStatusAndArchive,
} from '../reducers/simpleOrder.reducer';
import { IRootState } from '../../../../../shared/reducers';
import { Spin } from '../../../../../components';
import { SimpleOrderEditModal } from '../modal/simpleOrderEditModal';
import { getPathFromState, getStateFromPath, isStringInEnum } from '../../../../../shared/util/utils';
import { BasePage, IBasePageProps } from '../../../../../components/page/BasePage';
import { IPageHeaderProps } from '../../../../../components/pageHeader/pageHeader';
import { OperationsList } from '../../../operations/operationsList';
import getParamsLink from '../../../../../shared/util/getParamsLink';
import {
    createOrEditSimpleOrder,
    resetOperation,
    resetOperationForm,
    updateSimpleOrderState,
} from '../../../operationForm/reducers/operationForm.reducer';
import {
    AttachmentDocumentTypeCodeEnum,
    ContractValidationTypeCodeEnum,
    InstanceTrackingTypeCodeEnum,
    OperationTypeCodeEnum,
    ProjectInfoRead,
    ProjectStateCodeEnum,
    ProjectTransitionCodeEnum,
    ProjectTypeCodeEnum,
    RenterStateCodeEnum,
    RentStateCodeEnum,
} from '../../../../../server';
import { LocalizationEnum, localize } from '../../../../../localization';
import { canUpdateFinancialData, canViewFinancialData } from '../../../../../shared/util/permissionUtils';
import { PaymentCreateModal } from '../../../payments/components/modal/paymentCreateModal';
import { getCurrentTabsSelector, saveCurrentTabToStore } from '../../../../../shared/reducers/userSettings/userSettings.reducer';
import {
    contractValidationTypeSelector,
    expensesModuleEnabledSelector,
    projectsModuleEnabledSelector,
    statisticsModuleEnabledSelector,
} from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { getGridStorageData } from '../../../../../components/grid/utils';
import { ReportReferenceTypeEnum } from '../../../../../components/pageHeader/ReportSelectDropdown';
import { LinkToCalendar } from '../../../../../components/link/toCalendar/LinkToCalendar';
import { CalendarPageTabsEnum, ProjectPageTabsEnum, ProjectsPageTabsEnum } from '../../../../../shared/constants/tabEnums';
import { OrderPageHeaderNavigationBlock } from '../../../operationForm/orderModule/orderPageHeaderNavigationBlock';
import { ProjectStateCode } from '../../production/data/projectData';
import { Action, ActionButtonsGroup } from '../../../../../components/v2/actionButtons/actionButtonsGroup/actionButtonsGroup';
import { simpleOrderActionButtons, SimpleOrderActionType } from '../data/simpleOrderData';
import every from 'lodash/every';
import { getIntl } from '../../../../../App';
import { resetBusinessAccountIndicators } from '../../../../../shared/reducers/businessAccountIndicators.reducer';
import { OrderElements } from '../../../operationForm/orderModule/orderElements';
import { RentElementsGridItem } from '../../../../../types';
import { showNotification } from '../../../../../components/notification/showNotification';
import { showConfirm } from '../../../../../components/confirm/showConfirm';
import { getCurrentServerTime } from '../../../../../shared/reducers/system.reducer';
import moment from 'moment';
import { some } from 'lodash';
import { OrderCancelButton } from '../../../operationForm/orderModule/comonents/orderCancelButton/orderCancelButton';
import { projectsPageUrlRoute } from '../../projectsPage';
import { resetSimpleOrderListElements, simpleOrderLoadEntities } from '../../../elements/reducers/simpleOrderElements.reducer';
import { InterfaceBlockCode } from '../../../../../components/pageHeader/components/InfoSelectDropdown/utils/constants';
import { InfoSelectDropdown } from '../../../../../components/pageHeader/components/InfoSelectDropdown/InfoSelectDropdown';
import { canCreateCorrectionOperationSelector } from '../../../../../shared/reducers/permissions.reducer';
import { printListOfItems } from '../../../../../components/tooltips/tooltipWIthItems/tooltipWIthItems';
import './simpleOrderPage.less';
import { ExpensesList } from '../../../expenses/list/expensesList';
import { ParamsUtils } from '../../../../../core/utils/paramsUtils';
import { expensesApi } from '../../../expenses/api/expenses.api';
import { getStore } from '../../../../../../index';
import { loadEntities as loadOperations } from '../../../operations/reducers/operations.reducer';

interface IState {
    loading: boolean;
}

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

const defaultTab = ProjectPageTabsEnum.ELEMENTS;

export enum SimpleOrderGridNamesEnum {
    OPERATIONS = 'simpleOrder-operations',
    ELEMENTS = 'simpleOrder-elements',
    EXPENSES = 'simpleOrder-expenses',
}

export const projectGridNamesMap: Record<
    Exclude<
        ProjectPageTabsEnum,
        | ProjectPageTabsEnum.DESCRIPTION
        | ProjectPageTabsEnum.OFFERS
        | ProjectPageTabsEnum.STATISTICS
        | ProjectPageTabsEnum.TRANSPORTATIONS
        | ProjectPageTabsEnum.WORK_PLANNINGS
    >,
    SimpleOrderGridNamesEnum
> = {
    [ProjectPageTabsEnum.OPERATIONS]: SimpleOrderGridNamesEnum.OPERATIONS,
    [ProjectPageTabsEnum.ELEMENTS]: SimpleOrderGridNamesEnum.ELEMENTS,
    [ProjectPageTabsEnum.EXPENSES]: SimpleOrderGridNamesEnum.EXPENSES,
};

class SimpleOrderPage extends BasePage<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.props.resetOperationForm();

        this.state = {
            loading: false,
        };
    }

    async componentDidMount() {
        super.componentDidMount();
        await this.props.loadSimpleOrder(this.props.businessAccountId, this.props.entityId);
        if (this.currentTab !== ProjectPageTabsEnum.DESCRIPTION)
            this.props.simpleOrderLoadEntities(this.props.intl, this.props.businessAccountId, this.props.entityId);
    }

    async componentDidUpdate(prevProps: IProps) {
        if (this.props.tab) saveCurrentTabToStore('simpleOrder', this.props.tab);

        if (prevProps.entityId !== this.props.entityId) {
            this.props.reset();
            await this.props.loadSimpleOrder(this.props.businessAccountId, this.props.entityId);
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.reset();
        this.props.resetSimpleOrderListElements();
    }

    onTabsChange = (key: string) => {
        const tabParams = this.getTabParams(key as ProjectPageTabsEnum);
        saveCurrentTabToStore('simpleOrder', key);
        this.props.push(getPathFromState(this.props.location.pathname, '', { ...tabParams, tab: key }));
    };

    changeArchiveState = async (archive: boolean) => {
        let { entity } = this.props;
        let yes = true;
        if (archive) {
            yes = await showConfirm(this.props.intl, 'Отправить заказ в архив?');
        }
        if (yes) {
            this.props.setStatusAndArchive(
                this.props.intl,
                this.props.businessAccountId,
                this.props.entityId,
                entity?.businessVersion!,
                undefined,
                archive
            );
        }
    };

    getPageHeaderProps(): IPageHeaderProps {
        let { entity, statisticsModuleEnabled, orderElementsIsNowEditing, tab } = this.props;

        return {
            businessAccountId: this.props.businessAccountId,
            rightBlock: entity ? (
                <>
                    <LinkToCalendar
                        calendarPath={`/${this.props.businessAccountId}/calendar${getParamsLink(
                            entity,
                            'project'
                        )}&group=project&capture=all&tab=${CalendarPageTabsEnum.ELEMENTS}`}
                    />
                    {orderElementsIsNowEditing && tab === ProjectPageTabsEnum.DESCRIPTION && <OrderCancelButton creationMode={false} />}
                    <InfoSelectDropdown interfaceBlockCode={InterfaceBlockCode.BA_ORDERS_ORDER_CARD} />
                </>
            ) : undefined,
            reportReferenceType: statisticsModuleEnabled ? ReportReferenceTypeEnum.PROJECT : undefined,
            reportReferenceEntityId: statisticsModuleEnabled ? this.props.entityId : undefined,
            NavigationBlock: entity ? (
                <OrderPageHeaderNavigationBlock
                    data={{
                        orderName: entity.shortName,
                        counterpartyId: entity.renterId,
                        counterpartyName: entity.renterShortName,
                        stateCode: entity.stateCode as ProjectStateCode,
                        archive: entity.archive,
                    }}
                />
            ) : undefined,
        };
    }

    getTabParams(tab: ProjectPageTabsEnum) {
        let gridName = projectGridNamesMap[tab];
        if (gridName) {
            const storageData = getGridStorageData(gridName);
            return {
                ...storageData.filters,
                ...storageData.params,
            };
        } else {
            return {};
        }
    }

    get currentTab() {
        return new URLSearchParams(this.props.location.search).get('tab');
    }

    createOperation = async (type: ProjectTransitionCodeEnum) => {
        if (this.props.entity && this.props.elements) {
            this.setState({ loading: true });
            await this.props.setStatusAndArchive(
                this.props.intl,
                this.props.businessAccountId,
                this.props.entity.id,
                this.props.entity.businessVersion,
                type
            );
            this.updateListsData();
            this.setState({ loading: false });
        }
    };

    changeStateByCorrection = async (targetState: ProjectStateCodeEnum) => {
        if (this.props.entity) {
            this.setState({ loading: true });
            await this.props.changeOrderStateByCorrection(this.props.businessAccountId, this.props.entity.id, targetState);
            this.updateListsData();
            this.setState({ loading: false });
        }
    };

    updateListsData = () => {
        this.props.simpleOrderLoadEntities(getIntl(), this.props.businessAccountId, this.props.entity?.id);
        if (this.currentTab === ProjectPageTabsEnum.EXPENSES) getStore().dispatch(expensesApi.util.invalidateTags(['ExpensesList']));
        if (this.currentTab === ProjectPageTabsEnum.OPERATIONS) this.props.loadOperations(this.props.intl, this.props.businessAccountId);
    };

    convertToProject = async () => {
        if (this.props.entity) {
            this.props.convertToProject(this.props.intl, this.props.businessAccountId, this.props.entity.id);
        }
    };

    createExpense = () => {
        const urlSearchParams = ParamsUtils.getURLSearchParams(this.props.location);
        const searchParams = new URLSearchParams(urlSearchParams);
        searchParams.append('modal', 'new');
        searchParams.set('tab', ProjectPageTabsEnum.EXPENSES);
        const paramsString = searchParams.toString() ? `?${searchParams.toString()}` : '';
        this.props.push(`/${this.props.businessAccountId}/projects/simpleOrders/${this.props.entityId}${paramsString}`);
    };

    getActionsButtons = () => {
        const { entity } = this.props;
        const elements = this.props.elements;
        const rentActionsDisabled = this.props.orderElementsIsNowEditing;
        const currentDate = moment(getCurrentServerTime());
        const startDate = entity?.startDate ? moment(entity.startDate) : undefined;
        const targetFinishDate = entity?.targetFinishDate ? moment(entity.targetFinishDate) : undefined;

        const allRows: RentElementsGridItem[] = [];
        elements?.forEach((el) => {
            if (el.kitId && el.subRows && el.subRows.length > 0) {
                el.subRows.forEach((subRowEl) => {
                    allRows.push(subRowEl);
                });
            } else {
                allRows.push(el);
            }
        });

        const actions: Action<SimpleOrderActionType>[] = [];
        const correctionActions: Action<SimpleOrderActionType>[] = [];

        if (this.props.expensesModuleEnabled && this.props.tab === ProjectPageTabsEnum.EXPENSES) {
            const err = entity?.stateCode === ProjectStateCodeEnum.CANCELED ? 'В отмененном заказе действие недоступно' : undefined;
            actions.push({
                type: SimpleOrderActionType.createExpense,
                disabled: !!err,
                tooltip: err,
            });
        }

        if (startDate && targetFinishDate && allRows.length > 0) {
            let bookOperationProblems: ReactNode[] = [];
            let rentOperationProblems: ReactNode[] = [];
            const finishOperationProblems: ReactNode[] = [];
            let correctToRentOperationProblems: ReactNode[] = [];
            let correctToBookOperationProblems: ReactNode[] = [];
            const correctToFinishOperationProblems: ReactNode[] = [];

            // Проблемы Аренда
            if (
                some(
                    allRows.filter((el) => el.stateCode !== RentStateCodeEnum.CANCELED),
                    (item) =>
                        (item.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                            item.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED) &&
                        item.anonymousInstanceCount > 0
                )
            ) {
                const err = 'Требуется выбрать экземпляры';
                rentOperationProblems.push(err);
                correctToRentOperationProblems.push(err);
                correctToFinishOperationProblems.push(err);
            }
            if (
                some(
                    allRows.filter((el) => el.stateCode !== RentStateCodeEnum.CANCELED),
                    (item) => item.shortageInstanceCount > 0 || (item.instanceWithCurrentElementShortageIds || []).length > 0
                )
            ) {
                const err = 'Недостаточно доступных экземпляров для выдачи';
                rentOperationProblems.push(err);
            }
            if (startDate.valueOf() >= currentDate.valueOf()) {
                const err = 'Начало периода аренды должно быть раньше момента выдачи';
                rentOperationProblems.push(err);
                correctToRentOperationProblems.push(err);
            }
            if (startDate.valueOf() >= targetFinishDate.valueOf()) {
                const err = localize(LocalizationEnum.PAGE__SHIPPINGS__FORM__START_DATE_MUST_BE_EARLIER);
                rentOperationProblems.push(err);
                correctToRentOperationProblems.push(err);
            }
            if (
                some(
                    allRows.filter((el) => el.stateCode !== RentStateCodeEnum.CANCELED),
                    (item) => item.shortageInstanceCount > 0 || (item.instanceWithCurrentElementShortageIds || []).length > 0
                )
            ) {
                bookOperationProblems.push('Недостаточно доступных экземпляров для бронирования');
            }

            const contractCount =
                entity?.attachments?.records.filter((attachment) => attachment.documentType === AttachmentDocumentTypeCodeEnum.CONTRACT)
                    .length || 0;
            const contractFileRequired =
                this.props.contractValidationType &&
                [ContractValidationTypeCodeEnum.ONLYFILE, ContractValidationTypeCodeEnum.ALLFIELDS].includes(
                    this.props.contractValidationType
                );
            const contractAllFieldsRequired = this.props.contractValidationType === ContractValidationTypeCodeEnum.ALLFIELDS;

            if (entity && contractAllFieldsRequired) {
                if (
                    !entity.projectContract?.contractDate ||
                    !entity.projectContract.contractNumber ||
                    !entity.projectContract.contractExpirationDate ||
                    (entity.projectContract.contractExpirationDate && !entity.projectContract.isContractExpirationDateValid)
                ) {
                    const err = 'Некорректные даты или номер договора';
                    rentOperationProblems.push(err);
                }
            }

            if (contractFileRequired || contractAllFieldsRequired) {
                if (contractCount === 0) {
                    const err = 'Файл договора не загружен';
                    rentOperationProblems.push(err);
                }
            }

            // Проблемы Завершить
            if (targetFinishDate.valueOf() > currentDate.valueOf()) {
                const err = 'Завершение периода аренды должно быть раньше момента возврата';
                finishOperationProblems.push(err);
                correctToFinishOperationProblems.push(err);
            }
            if (startDate.valueOf() >= targetFinishDate.valueOf()) {
                const err = localize(LocalizationEnum.PAGE__SHIPPINGS__FORM__END_DATE_MUST_BE_LATER);
                finishOperationProblems.push(err);
                correctToFinishOperationProblems.push(err);
            }

            let availableTransitionCodes = [...(this.props.entity?.availableTransitionCodes || [])];
            if (
                this.props.entity?.stateCode !== ProjectStateCodeEnum.FINISHED &&
                this.props.entity?.renterStateCode !== RenterStateCodeEnum.ACTIVE
            ) {
                const err = 'Арендатор в неактивном статусе, действие недоступно';
                if (!availableTransitionCodes.includes(ProjectTransitionCodeEnum.BOOK)) {
                    availableTransitionCodes.push(ProjectTransitionCodeEnum.BOOK);
                    bookOperationProblems.push(err);
                    correctToBookOperationProblems.push(err);
                }
                if (!availableTransitionCodes.includes(ProjectTransitionCodeEnum.RENT)) {
                    availableTransitionCodes.push(ProjectTransitionCodeEnum.RENT);
                    rentOperationProblems.push(err);
                    correctToRentOperationProblems.push(err);
                }
            }

            availableTransitionCodes?.forEach((code) => {
                if (code === ProjectTransitionCodeEnum.CANCEL) {
                    actions.push({
                        type: SimpleOrderActionType.cancel,
                        disabled: rentActionsDisabled,
                    });
                } else if (code === ProjectTransitionCodeEnum.RETURNTODRAFT) {
                    if (every(allRows, (item) => item.availableTransitionCodes?.includes(OperationTypeCodeEnum.DRAFT))) {
                        actions.push({
                            type: SimpleOrderActionType.returnToDraft,
                            disabled: rentActionsDisabled,
                        });
                    }
                } else if (code === ProjectTransitionCodeEnum.BOOK) {
                    actions.push({
                        type: SimpleOrderActionType.book,
                        disabled: rentActionsDisabled || Boolean(bookOperationProblems.length > 0),
                        tooltip: rentActionsDisabled ? undefined : printListOfItems(bookOperationProblems),
                    });
                } else if (code === ProjectTransitionCodeEnum.RENT) {
                    actions.push({
                        type: SimpleOrderActionType.rent,
                        disabled: rentActionsDisabled || Boolean(rentOperationProblems.length > 0),
                        tooltip: rentActionsDisabled ? undefined : printListOfItems(rentOperationProblems),
                    });
                } else if (code === ProjectTransitionCodeEnum.FINISH) {
                    actions.push({
                        type: SimpleOrderActionType.finish,
                        disabled: rentActionsDisabled || Boolean(finishOperationProblems.length > 0),
                        tooltip: rentActionsDisabled ? undefined : printListOfItems(finishOperationProblems),
                    });
                }
            });

            correctionActions.push(SimpleOrderActionType.correctionToDraft);
            correctionActions.push({
                type: SimpleOrderActionType.correctionToBook,
                disabled: rentActionsDisabled || Boolean(correctToBookOperationProblems.length > 0),
                tooltip: rentActionsDisabled ? undefined : printListOfItems(correctToBookOperationProblems),
            });
            correctionActions.push({
                type: SimpleOrderActionType.correctionToRent,
                disabled: rentActionsDisabled || Boolean(correctToRentOperationProblems.length > 0),
                tooltip: rentActionsDisabled ? undefined : printListOfItems(correctToRentOperationProblems),
            });
            correctionActions.push({
                type: SimpleOrderActionType.correctionToFinish,
                disabled: rentActionsDisabled || Boolean(correctToFinishOperationProblems.length > 0),
                tooltip: rentActionsDisabled ? undefined : printListOfItems(correctToFinishOperationProblems),
            });
            correctionActions.push({ type: SimpleOrderActionType.correctionToCancel });
        }

        return (
            <div className={'rr-simpleOrderPage-actions'}>
                <ActionButtonsGroup
                    data={simpleOrderActionButtons}
                    mainActions={actions}
                    additionalActions={[
                        {
                            type: SimpleOrderActionType.edit,
                            disabled: rentActionsDisabled,
                        },

                        !(
                            this.props.entity?.stateCode === ProjectStateCodeEnum.DRAFT ||
                            this.props.entity?.stateCode === ProjectStateCodeEnum.CANCELED
                        )
                            ? {
                                  type: SimpleOrderActionType.pay,
                                  disabled: rentActionsDisabled,
                              }
                            : undefined,
                        this.props.projectsModuleEnabled && {
                            type: SimpleOrderActionType.convertToProject,
                            disabled: rentActionsDisabled,
                        },
                        this.props.canCreateCorrectionOperation && {
                            type: SimpleOrderActionType.correction,
                            disabled: rentActionsDisabled,
                            nestedActions: correctionActions,
                        },
                        this.props.entity?.archivable &&
                            !this.props.entity?.archive && {
                                type: SimpleOrderActionType.toArchive,
                                disabled: rentActionsDisabled,
                            },
                        this.props.entity?.archive && {
                            type: SimpleOrderActionType.fromArchive,
                            disabled: rentActionsDisabled,
                        },
                    ]}
                    onAction={(action) => {
                        if (action === SimpleOrderActionType.edit) {
                            this.props.push(
                                `/${this.props.businessAccountId}/projects/simpleOrders/${this.props.entityId}/edit?tab=description`
                            );
                        } else if (action === SimpleOrderActionType.pay) {
                            this.props.push(
                                `/${this.props.businessAccountId}/projects/simpleOrders/${this.props.entityId}/pay/?tab=description`
                            );
                        } else if (action === SimpleOrderActionType.toArchive) {
                            void this.changeArchiveState(true);
                        } else if (action === SimpleOrderActionType.fromArchive) {
                            void this.changeArchiveState(false);
                        } else if (action === SimpleOrderActionType.finish) {
                            void this.createOperation(ProjectTransitionCodeEnum.FINISH);
                        } else if (action === SimpleOrderActionType.cancel) {
                            void this.createOperation(ProjectTransitionCodeEnum.CANCEL);
                        } else if (action === SimpleOrderActionType.returnToDraft) {
                            void this.createOperation(ProjectTransitionCodeEnum.RETURNTODRAFT);
                        } else if (action === SimpleOrderActionType.book) {
                            void this.createOperation(ProjectTransitionCodeEnum.BOOK);
                        } else if (action === SimpleOrderActionType.rent) {
                            void this.createOperation(ProjectTransitionCodeEnum.RENT);
                        } else if (action === SimpleOrderActionType.convertToProject) {
                            void this.convertToProject();
                        } else if (action === SimpleOrderActionType.createExpense) {
                            void this.createExpense();
                        } else if (action === SimpleOrderActionType.correction) {
                        } else if (action === SimpleOrderActionType.correctionToDraft) {
                            void this.changeStateByCorrection(ProjectStateCodeEnum.DRAFT);
                        } else if (action === SimpleOrderActionType.correctionToBook) {
                            void this.changeStateByCorrection(ProjectStateCodeEnum.BOOKED);
                        } else if (action === SimpleOrderActionType.correctionToRent) {
                            void this.changeStateByCorrection(ProjectStateCodeEnum.RENTED);
                        } else if (action === SimpleOrderActionType.correctionToFinish) {
                            void this.changeStateByCorrection(ProjectStateCodeEnum.FINISHED);
                        } else if (action === SimpleOrderActionType.correctionToCancel) {
                            void this.changeStateByCorrection(ProjectStateCodeEnum.CANCELED);
                        }
                    }}
                />
            </div>
        );
    };

    /**
     * Создание нового заказа или редактирование существующего
     */
    editSimpleOrder = async () => {
        if (this.props.entity) {
            this.setState({ loading: true });
            try {
                const project: ProjectInfoRead = (await this.props.createOrEditSimpleOrder(this.props.entity)) as any;
                if (project) {
                    await this.props.loadSimpleOrder(this.props.businessAccountId, this.props.entityId);
                    this.props.resetOperation();
                    this.props.resetBusinessAccountIndicators();
                    showNotification('success', 'Заказ изменен');
                } else {
                    showNotification('error', 'Заказ не изменен');
                }
            } catch (e) {
                showNotification('error', 'Заказ не изменен');
            }
            this.setState({ loading: false });
        }
    };

    renderContent() {
        let { entity, loading, updating, orderElementsIsNowEditing, expensesModuleEnabled, canViewFinancialData } = this.props;
        const { currentTab } = this;

        if (currentTab == null && !this.props.operationMnemoKey) {
            const tab = isStringInEnum(this.props.tabFromStore, ProjectPageTabsEnum, defaultTab);
            return (
                <Redirect
                    to={getPathFromState(this.props.location.pathname, '', {
                        ...this.getTabParams(tab),
                        tab: tab,
                    })}
                />
            );
        }

        if (entity) {
            if (entity.projectType === ProjectTypeCodeEnum.OFFER) {
                return (
                    <Redirect to={`/${this.props.businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.OFFERS}/${entity.id}`} />
                );
            } else if (!entity.isSimpleOrder) {
                return (
                    <Redirect
                        to={`/${this.props.businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.PROJECTS}/${entity.id}`}
                    />
                );
            }
        }

        /**
         * Когда переходим в раздел из меню и вкладка не выбрана
         * ИЛИ Когда открываем урл и там вкладка прописана:
         * Если модуль отключен и вкладку прячем, то делаем редирект на вкладку по умолчанию
         */
        if (
            (currentTab == null &&
                this.props.tabFromStore === ProjectPageTabsEnum.EXPENSES &&
                (!expensesModuleEnabled || !canViewFinancialData)) ||
            (currentTab != null && currentTab === ProjectPageTabsEnum.EXPENSES && (!expensesModuleEnabled || !canViewFinancialData))
        ) {
            return (
                <Redirect
                    to={getPathFromState(this.props.location.pathname, '', {
                        ...this.getTabParams(defaultTab),
                        tab: defaultTab,
                    })}
                />
            );
        }

        // Когда нет параметров в урле и есть сохраненные данные для грида, то редиректим
        if (currentTab === ProjectPageTabsEnum.OFFERS) {
            if (
                Array.from(new URLSearchParams(this.props.location.search).keys()).filter((p) => !['tab', 'modal'].includes(p)).length === 0
            ) {
                let gridName = projectGridNamesMap[currentTab];
                if (gridName) {
                    const gridData = getGridStorageData(gridName);
                    if (
                        (gridData.filters && Object.keys(gridData.filters).filter((k) => k !== 'tab' && k !== 'modal').length > 0) ||
                        (gridData.params && Object.keys(gridData.params).length > 0)
                    ) {
                        const redirectPath = getPathFromState(this.props.location.pathname, this.props.location.search, {
                            tab: currentTab,
                            ...gridData.filters,
                            ...gridData.params,
                        });
                        return <Redirect to={redirectPath} />;
                    }
                }
            }
        }

        let err;
        if (this.props.loadingError) {
            err = (
                <>
                    <div>
                        <strong>{this.props.loadingError.title}</strong>
                    </div>
                    <div>{this.props.loadingError.message}</div>
                </>
            );
        }

        return (
            <>
                <Spin spinning={loading || updating || this.state.loading}>
                    <div className={'rr-page-renter'} style={{ minHeight: 400 }}>
                        {/*{err ? (*/}
                        {/*    <Alert*/}
                        {/*        message={localize(LocalizationEnum.ASPECT__GLOBAL__ERROR)}*/}
                        {/*        description={err}*/}
                        {/*        type="error"*/}
                        {/*        showIcon*/}
                        {/*        closable*/}
                        {/*        style={{ marginBottom: 30 }}*/}
                        {/*    />*/}
                        {/*) : null}*/}

                        {this.props.entity && (
                            <div className="card-container">
                                <Tabs
                                    defaultActiveKey={'description'}
                                    activeKey={this.props.tab}
                                    onChange={this.onTabsChange}
                                    className={'rr-tabs'}
                                    type="line"
                                    animated={false}
                                    destroyInactiveTabPane
                                    tabBarExtraContent={this.getActionsButtons()}
                                >
                                    <Tabs.TabPane
                                        tab={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}
                                        key={ProjectPageTabsEnum.DESCRIPTION}
                                    >
                                        <OrderElements
                                            orderEntity={entity || undefined}
                                            createOperation={this.editSimpleOrder}
                                            orderElementsIsNowEditing={orderElementsIsNowEditing}
                                        />
                                    </Tabs.TabPane>
                                    {expensesModuleEnabled && canViewFinancialData && (
                                        <Tabs.TabPane tab={'Затраты'} key={ProjectPageTabsEnum.EXPENSES}>
                                            <ExpensesList
                                                gridName={SimpleOrderGridNamesEnum.EXPENSES}
                                                visible={this.props.tab === ProjectPageTabsEnum.EXPENSES}
                                                parentProjectEntity={entity || undefined}
                                                urlPath={`projects/simpleOrders/${this.props.entityId}?tab=expenses`}
                                                gridProps={{
                                                    filtersExcludeFields: ['projectId'],
                                                    excludeColumns: ['projectId', 'state'],
                                                }}
                                                staticPageParams={{
                                                    projectId: entity?.id,
                                                }}
                                            />
                                        </Tabs.TabPane>
                                    )}
                                    <Tabs.TabPane
                                        tab={localize(LocalizationEnum.ASPECT__ENTITY_TYPE__OPERATIONS)}
                                        key={ProjectPageTabsEnum.OPERATIONS}
                                    >
                                        <OperationsList
                                            gridName={'simpleOrder-operations'}
                                            location={this.props.location}
                                            projectId={entity?.id}
                                            parentEntityType={'project'}
                                        />
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        )}
                    </div>
                </Spin>
                {this.props.editMode && (
                    <SimpleOrderEditModal
                        title={'Редактирование заказа'}
                        businessAccountId={this.props.businessAccountId}
                        initialValues={entity || undefined}
                    />
                )}
                {this.props.payMode && <PaymentCreateModal project={entity} />}
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    let searchParams = getStateFromPath(ownProps.location.search);
    const entity = storeState.simpleOrder.entity;

    return {
        entity,
        loading: storeState.simpleOrder.loading,
        updating: storeState.simpleOrder.updating,
        loadingError: storeState.simpleOrder.loadingError,
        businessAccountId: +ownProps.match.params['businessAccountId'] || 0,
        entityId: +ownProps.match.params['id'] || 0,
        editMode: !!(storeState.simpleOrder.entity && ownProps.match.params['action'] === 'edit'),
        payMode: !!(storeState.simpleOrder.entity && ownProps.match.params['action'] === 'pay'),
        tab:
            searchParams.tab &&
            (searchParams.tab === ProjectPageTabsEnum.DESCRIPTION ||
                searchParams.tab === ProjectPageTabsEnum.OPERATIONS ||
                searchParams.tab === ProjectPageTabsEnum.EXPENSES)
                ? searchParams.tab
                : ProjectPageTabsEnum.DESCRIPTION,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        canUpdateFinancialData: canUpdateFinancialData(storeState.permissions.permissions),
        currentOperationUUID: storeState.operationForm.currentOperationUUID,
        tabFromStore: getCurrentTabsSelector(storeState)?.['simpleOrder'],
        statisticsModuleEnabled: statisticsModuleEnabledSelector(storeState),
        projectsModuleEnabled: projectsModuleEnabledSelector(storeState),
        expensesModuleEnabled: expensesModuleEnabledSelector(storeState),
        elements: storeState.simpleOrderElements.entities,
        operationMnemoKey: storeState.operationForm.mnemoKey,
        operationForm: storeState.operationForm,
        canCreateCorrectionOperation: canCreateCorrectionOperationSelector(storeState),
        orderElementsIsNowEditing: Boolean(
            storeState.operationForm.mnemoKey &&
                storeState.operationForm.typeCode === OperationTypeCodeEnum.EDIT &&
                storeState.operationForm.isSimpleOrder &&
                entity &&
                entity.id === storeState.operationForm.projectId
        ),
        contractValidationType: contractValidationTypeSelector(storeState),
    };
};

const mapDispatchToProps = {
    loadSimpleOrder,
    setStatusAndArchive,
    reset,
    push,
    replace,
    resetOperationForm,
    simpleOrderLoadEntities,
    updateSimpleOrderState,
    createOrEditSimpleOrder,
    resetOperation,
    resetBusinessAccountIndicators,
    convertToProject,
    changeOrderStateByCorrection,
    resetSimpleOrderListElements,
    loadOperations,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SimpleOrderPage);
