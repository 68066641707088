import React from 'react';
import { DataDisplayItemProps, DataDisplayItemType } from '../../../../../../../components/dataDisplay';
import { ContactInfoRead } from '../../../../../../../server';
import { Col, Icon, Row } from 'antd';
import { DrawerUtils } from '../../../../../../../shared/util/drawerUtils';
import { DrawerAvatar } from '../../../../../../../components/v2/entityDrawer/components/DrawerAvatar';
import { FormValueItem } from '../../../../../crew/tabs/crewMembers/page/components/tabs/description/components/FormValueItem/FormValueItem';
import { IconEnvelope, IconPhoneSolid } from '../../../../../../../components/icons';

export const useMainBlockItem = (contact?: ContactInfoRead): DataDisplayItemProps[][] => {
    const fullName = contact
        ? DrawerUtils.getFullName({
              secondname: contact.secondName,
              lastname: contact.lastName,
              firstname: contact.firstName,
          })
        : undefined;

    const isAvatarHighlighted = contact?.isBusinessAccountRRUser;

    return [
        [
            {
                type: DataDisplayItemType.Custom,
                value: (
                    <div className={'contact-main-block'}>
                        <div>
                            <DrawerAvatar image={contact?.mainImage} isHighlighted={isAvatarHighlighted} />
                        </div>
                        <Row>
                            <Col className={'rr-form-item'}>
                                <FormValueItem title={'Полное имя'} value={fullName} />
                            </Col>
                            <Col className={'rr-form-item'}>
                                <FormValueItem title={'Основная должность'} value={contact?.primaryPosition} />
                            </Col>
                            <Col className={'rr-form-item'}>
                                <FormValueItem
                                    title={'Номер телефона'}
                                    value={
                                        contact?.phoneNumber ? (
                                            <a className="rr-dd-i-v rr-dd-iv-phone" href={'tel:' + contact.phoneNumber}>
                                                <span>{contact.phoneNumber}</span>
                                                <Icon className={'rr-dd-iv-phone-icon'} component={IconPhoneSolid} />
                                            </a>
                                        ) : undefined
                                    }
                                />
                            </Col>
                            <Col className={'rr-form-item'}>
                                <FormValueItem
                                    title={'Email'}
                                    value={
                                        contact?.email ? (
                                            <a className="rr-dd-i-v rr-dd-iv-email" href={'mailto:' + contact.email}>
                                                <span>{contact?.email}</span>{' '}
                                                <Icon className={'rr-dd-iv-email-icon'} component={IconEnvelope} />
                                            </a>
                                        ) : undefined
                                    }
                                />
                            </Col>
                        </Row>
                    </div>
                ),
            },
        ],
    ];
};
