import { ModalFormNEW, ModalFormNEWProps } from '../../../../../components/modalForm/ModalFormNEW';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ProjectInfoRead, ProjectInfoUpdate } from '../../../../../server';
import { IRootState } from '../../../../../shared/reducers';
import { ModalActionData } from '../../../../../components/modalForm/utils';
import { connect } from 'react-redux';
import { offerModalFormFields } from '../offerModalFormFields';
import { showConfirm } from '../../../../../components/confirm/showConfirm';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { goBack } from 'connected-react-router';
import { updateModalFilters } from '../../../../../shared/reducers/userSettings/userSettings.reducer';
import { updateOffer } from '../../reducers/offer/offer.reducer';
import { OfferCreateModalFormData } from '../create/offerCreateModal.types';
import { getStore } from '../../../../../../index';
import { showNotification } from '../../../../../components/notification/showNotification';

interface IProps extends ModalFormNEWProps, WrappedComponentProps {
    offer: ProjectInfoRead;
}

export class _OfferEditModal extends ModalFormNEW<IProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: offerModalFormFields,
    };

    getInitialValues = () => {
        const { offer } = this.props;

        let initialValues: Partial<OfferCreateModalFormData> = {
            id: offer.id,
            shortName: offer.shortName,
            fullName: offer.fullName,
            renterId: offer.renterId,
            mainProjectId: offer.mainProjectId,
            partOfThePrice: offer.partOfThePrice,
            stateCode: offer.stateCode,
            assigneeId: offer.assigneeId || undefined,
            useMainProjectAssignee: !offer.hasOwnAssignee,
            description: offer.description,
            comment: offer.comment,
        };

        return {
            ...initialValues,
            ...this.props.initialValues,
        };
    };

    onOk = (data: unknown) => {
        const formData = data as OfferCreateModalFormData;

        const offerInfoUpdate: ProjectInfoUpdate = {
            id: formData.id,
            businessVersion: this.props.offer.businessVersion,
            renterId: formData.renterId!,
            shortName: formData.shortName,
            fullName: formData.fullName,
            mainProjectId: formData.mainProjectId,
            partOfThePrice: formData.partOfThePrice,
            projectType: this.props.offer.projectType,
            typeOfPricing: this.props.offer.typeOfPricing,

            assigneeId: formData.assigneeId,
            hasOwnAssignee: !formData.useMainProjectAssignee,
            description: formData.description,
            comment: formData.comment,

            hasOwnContacts: this.props.offer.hasOwnContacts,
            hasOwnDefaultDiscount: this.props.offer.hasOwnDefaultDiscount,
            defaultDiscount: this.props.offer.defaultDiscount,
            taxRate: this.props.offer.taxRate,
            taxBaseType: this.props.offer.taxBaseType,
            projectContract: {} // #2120
        };

        getStore()
            .dispatch(
                updateOffer({
                    businessAccountId: this.props.businessAccountId,
                    id: this.props.offer.id,
                    offerInfoUpdate: offerInfoUpdate,
                })
            )
            .then((offer) => {
                showNotification('success', 'Смета обновлена');
                this.props.goBack();
            })
            .catch((error) => {
                showNotification('error', 'Не удалось обновить смету');
            });
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(this.props.intl, localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)));
        if (yes) {
            this.props.goBack();
            //this.props.clearOffer();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.offer.updating,
    updatingError: storeState.offer.updatingError,
    modalFilters: storeState.userSettings.modalFilters as ModalActionData | undefined,
});

const mapDispatchToProps = {
    updateModalFilters,
    goBack,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OfferEditModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_OfferEditModal));
